import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { SelectWrapper, SelectBase, Arrow, Options, OptionItem } from './styles';
import { useStore } from '../../../stores/useStore';

const CustomSelect = ({ id, className, label, placeholder, options = [], onChange, value, variant = 'gray', id_attribute }) => {
  const {getSingleObj, setDataObjValue} = useStore();
  const obj = getSingleObj(id_attribute);
  console.log("obj", obj);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(obj?.value);
  const selectRef = useRef(null);
  useEffect(() => {
    setSelectedValue(value);
  }, [value]);



  const handleToggle = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    console.log("option", option);
    setSelectedValue(option);
    setIsOpen(false);
    setDataObjValue(id_attribute, option);

    // onChange(option); // Cambiado de option.value a option
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <SelectWrapper ref={selectRef} className={className}>
      <SelectBase onClick={handleToggle} variant={variant}>
        <span>{selectedValue ? selectedValue.label : placeholder}</span>
        <Arrow variant={variant} className="material-symbols-outlined">arrow_drop_down</Arrow>
      </SelectBase>
      <Options open={isOpen}>
        {Array.isArray(obj?.option_list) && obj?.option_list.map((option) => (
          <OptionItem
            key={option}
            onClick={() => handleOptionClick(option)}
            selected={selectedValue && selectedValue === option}
          >
            {option}
          </OptionItem>
        ))}
      </Options>
    </SelectWrapper>
  );
};

CustomSelect.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  variant: PropTypes.oneOf(['gray','pink','gray-light']),
};

export default CustomSelect;
