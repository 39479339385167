import { Container } from "./styles";
import { ProductImage } from "../../atoms/ProductImage/index";
import { Button } from "../../atoms/GeneralButton/index";

export const EditionActiveImage = ({ img, altText, setShowModal }) => {
  return (
    <Container>
      <ProductImage img={img} imageType="main-image" altText={altText} />
      <Button
        buttonType="open-modal-button"
        onClick={() => setShowModal(true)}
      />
    </Container>
  );
};
