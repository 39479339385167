import { Container } from "./styles";
import { Avatar } from "../Avatar";

import removeAsigned from "../../../assets/images/asignationOptions/removeAsigned.svg";
import requesToProvider from "../../../assets/images/asignationOptions/requestToProvider.svg";
import requestToTeam from "../../../assets/images/asignationOptions/requestToTeam.svg";
import availableIcon from "../../../assets/images/asignationOptions/availableIcon.svg";
import { getNewStatus } from "../../../global-files/data";
import { Status } from "../../atoms/Status";

export const AsignationOption = ({
  profileImage,
  asignationType = "available",
  imageType = "profile-image",
  name,
  team,
  onClick,
  btnOnClick,
  active,
  services,
}) => {
  const getStatus = () => {
    let servCad = "";
    services.forEach((srv) => (servCad += srv));

    return getNewStatus(services);
  };

  const iconsAsignation = {
    assigned: (
      <button onClick={btnOnClick}>
        <img src={removeAsigned} alt="remove asigned" />
      </button>
    ),
    provider: <img src={requesToProvider} alt="Solicitar a Proveedor" />,
    team: <img src={requestToTeam} alt="Solicitar a Team" />,
    available: <img src={availableIcon} alt="green circle icon" />,
  };

  return (
    <Container onClick={onClick}>
      <Avatar
        imageType={imageType}
        image={profileImage}
        altText={"profile image"}
      />
      <div className="labels-container">
        <p className="name-label">{name}</p>
        <p className="team-label">{team}</p>
      </div>
      {asignationType && iconsAsignation[asignationType]}
      {services && (
        <div className="status-container">
          <Status statusType={getStatus()} />
        </div>
      )}
      {active && <div className="green-circle"></div>}
    </Container>
  );
};
