export const redirectEdit = (productDetail, setRedirect, addNotification) => {
    const membership = JSON.parse(sessionStorage.getItem("user")).membership;
    const canEdit = membership.planID !== 1;
  
    if (!canEdit) {
      addNotification('error', 'Necesitas aumentar tu plan para editar');
      return;
    }
  
    try {
      sessionStorage.setItem("asignations", JSON.stringify(productDetail.asignations ?? []));
      
      setRedirect(`/EditProduct/${productDetail.id_article}`);
    } catch (error) {
      console.error(error);
    }
  };
  

