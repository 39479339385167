import axios from "axios";

export const fetchDataFromUtils = async (
  groups,
  setUserData,
  setGroupsData,
  setListUsersFilter,
  setGroupsFilter,
  addNotification
) => {
  try {
    const allGroupsResponse = await axios.get(
      `${process.env.REACT_APP_USER_ENDPOINT}?isOnboarding=1&groupsIds=[]`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );

    const allGroupsData = JSON.parse(allGroupsResponse.data.body).data[3];
    const groupsFilterSet = new Set();

    if (allGroupsData && Array.isArray(allGroupsData)) {
      allGroupsData.forEach(({ groups }) => {
        groups.forEach(({ groupId, groupName }) => {
          groupsFilterSet.add(JSON.stringify({
            value: groupId,
            label: groupName || null
          }));
        });
      });
    }

    const groupsFilterArray = Array.from(groupsFilterSet).map(item => JSON.parse(item));
    setGroupsFilter(groupsFilterArray);

    const groupsId = groups.length > 0 ? groups.map(group => group).join(",") : "";
    
    const response = await axios.get(
      `${process.env.REACT_APP_USER_ENDPOINT}?isOnboarding=1&groupsIds=[${groupsId}]`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );

    const onboardingData = JSON.parse(response.data.body).data[3];

    if (onboardingData && Array.isArray(onboardingData)) {
      const userDataArray = [];
      const groupsArray = [];

      onboardingData.forEach(({ idUser, userEmail, userName, groups }) => {
        userDataArray.push({ idUser, userEmail, userName, groups });
        groups.forEach(({ groupId, groupName, userContentGroup }) => {
          if (userContentGroup === 1) {
            groupsArray.push({
              groupId: groupId,
              groupName: groupName || null,
              userContentGroup: userContentGroup || null,
            });
          }
        });
      });

      setUserData(userDataArray);
      setListUsersFilter(onboardingData);
      setGroupsData(groupsArray);
    }
  } catch (error) {
    console.error("Error al recuperar datos de usuarios:", error);
    addNotification('error', "Error al recuperar datos de usuarios.");
  }
};



//Elimina todos los grupos de usuarios seleccionados
export const masiveDeleteGroup = async (userIds, addNotification) => {
  try {
    const body = {
      userIds,
      groupsIds: [],
    };
    const response = await axios.post(
      `${process.env.REACT_APP_USERS_ONBOARDING_ENDPOINT}/delete`,
      body,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    console.log("Borrado correctamente", response.data.body);
    addNotification("success", "Grupo borrado correctamente");
  } catch (error) {
    console.error("Error al eliminar grupos de usuarios:", error);
    addNotification("error", "Error al eliminar grupos de usuarios");
  }
};

export const searchUser = (userName = "", userData, setListUsersFilter, addNotification) => {
  try {
    const tempArray = userData.slice();
    const filteredUsers =
      userName.length > 0
        ? tempArray.filter((item) => {
            return item?.userName
              ?.toLowerCase()
              .includes(userName.toLowerCase());
          })
        : tempArray;

    setListUsersFilter(filteredUsers);
  } catch (error) {
    console.error("Error al buscar usuarios:", error);
    addNotification("error", "Error al buscar usuarios");
  }
};
