import { MainContainer } from "./styles";

export const UPCList = (props) => {
    return (
      <MainContainer>
        <ul>
          {props.upcExisting.map((upc) => (
            <li key={upc}>{upc}</li>
          ))}
        </ul>
        {/* {props.isOrder && (
          <p>Para la orden se utilizarán los UPC ya existentes</p>
        )} */}
      </MainContainer>
    );
  };
  