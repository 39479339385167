import {create} from "zustand";


export const useStore = create((set, get) => ({
    currentData: [],
    articleID: null,

    setCurrentData: (data) => set({ currentData: data }),
    setArticleID: (id) => set({ articleID: id }),
    
    setDataObjValue: (attributeID, value) => {
        const newData = [...get().currentData];
        const index = newData.findIndex((item) => item.id_attribute === attributeID);
        if (index !== -1) {
            newData[index].value = value;
            set({ currentData: newData });
        }
    },

    setDataObjAproval: (attributeID, isApproved, comment) => {
        const newData = [...get().currentData];
        const index = newData.findIndex((item) => item.id_attribute === attributeID);
        if (index !== -1) {
            newData[index].isApproved = isApproved;
            newData[index].comment = comment;
            set({ currentData: newData });
        }
    },

    getSingleObj: (attributeID) => {
        const newData = [...get().currentData];
        const index = newData.findIndex((item) => item.id_attribute === attributeID);
        return newData[index];
    }
}));