import styled, {keyframes} from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  display: grid;
  place-items: center;
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .close-edit-button {
    min-width: 24px;
    height: 24px;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease, opacity 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity:0.4;
    img {
      width: 10px;
      height: 10px;
    }

    &:hover {
      background-color: var(--blue-light);
      -webkit-filter: grayscale(0);
      filter: grayscale(0);
      opacity:1;
    }
  }
  .Modal-edit-group {
    position: absolute;
    top: 10%;
    z-index: 20;
    width: fit-content;
    max-width: 80%;
    min-width: 80px;
    height: fit-content;
    max-height: 100%;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    border: 1px solid var(--gray-light);
    padding: 25px 25px 20px 25px;
    box-shadow: 0px 2px 4px 0px #00000040;
  }

  .title-edit {
    width: 100%;
    font-family: Roboto, sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black);
  }

  .details-edit {
    width: 100%;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--gray-contentoh);
    margin: 5px 0 10px;
  }
  .container-save-add {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .button-group-add {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--pink-contentoh);
    text-transform: capitalize;
    border: none;
    background: transparent;
    border-radius: 20px;
    &:hover {
      background-color: var(--blue-light);
      cursor:pointer;
    }
  }
  .table-groups-edit {
    width: 100%;
    min-width: 288px;
    border: 1px solid var(--gray-light);
    border-radius: 5px;
  }
  .table-groups-max {
    min-width: 288px;
    border: 1px solid var(--gray-light);
    border-radius: 5px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .modal-save {
    .contentModal {
      width: 25%;
    }
  }
`;

export const GroupRow = styled.div`
  width: 100%;
  height: 34px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: none;
  border-bottom: 1px solid var(--gray-light);
  padding: 0 8px 0 0;
  input {
    width:100%;
    min-width: 215px;
    background: transparent;
    color: var(--gray-contentoh);
    border: 0;
    font-size: 12px;
    font-family: Roboto, sans-serif;
    padding: 5px 20px;
    ::placeholder {
      color: #CBCBCB;
    }
  }

  input:focus {
    border: 0;
  }
  &.editing {
    background-color: var(--blue-light); 
    color: black;
    input{
      color: var(--black);
    }
  }

  .save-group-button,
  .edit-group-button,
  .delete-group-button {
    min-width: 24px;
    height: 24px;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease, opacity 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
  
    img {
      width: 12px;
      height: 12px;
    }
  
    &:hover {
      background-color: var(--blue-light);
    }
  }
  .edit-group-button,
  .delete-group-button {
    display: none;
  }

  .visible {
    display: flex;
    animation: ${fadeIn} 0.3s ease-in-out forwards;
  }
`;