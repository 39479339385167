import { Container, ContainerLoading, Img } from "./styles";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faSpinner as iconLoading } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { Tooltip } from "../../atoms/Tooltip";

export const ImageTooltip = (props) => {
  const {
    className, // string
    sizeLoading, // number
    colorLoading, // string
    classNameLoading, // string
    src, // imagen a cargar (string)
    componentDefault, // componente a mostrar en caso de que no cargue src (JSX)
    classNameImg, // string
    classNameTooltip,
    componentTooltip,
    position, // (string) posicion del contenedor tooltip (values en object positions)
  } = props;
  const [imgLoad, setImgLoad] = useState();

  return (
    <Tooltip
      position={position ?? "topCenter"}
      addArrow={false}
      transitionType={"zoom"}
      followCursor={false}
      className={className}
      classNameTooltip={classNameTooltip}
      componentTooltip={imgLoad !== undefined && componentTooltip}
    >
      {src && imgLoad === undefined && (
        <ContainerLoading
          className={classNameLoading}
          sizeLoading={sizeLoading}
          colorLoading={colorLoading}
        >
          <Icon pulse icon={iconLoading} />
        </ContainerLoading>
      )}

      {(!src || imgLoad === false) && componentDefault}

      <Img
        className={classNameImg}
        src={`${src}?${new Date().getMilliseconds()}`}
        onLoad={() => setImgLoad(true)}
        onError={() => setImgLoad(false)}
        show={imgLoad === true}
      />
    </Tooltip>
  );
};
