import styled from "styled-components";
import { GlobalColors, FontFamily } from "../../../global-files/variables";

export const Container = styled.div`
  width: fit-content;
  height: fit-content;
  min-width: min-content;
  min-height: min-content;
  overflow: hidden;
  display: grid;
  place-items: center;
  border-radius: ${({ borderType }) => {
    return borderType?.toLowerCase() === "rectangle"
      ? "10%"
      : borderType?.toLowerCase() === "circle"
      ? "50%"
      : "none";
  }};

  .buttonDownload {
    width: 100%;
    height: 100%;
    .button {
      // tipos de botones fill
      &.fill {
        &.white:hover {
          background-color: white;
        }
        &.whiteS2:hover {
          background-color: white;
        }
        &.pink:hover {
          background-color: #e33aa9;
        }
        &.gray:hover {
          background-color: #e8e5e5;
        }
        &.purple:hover {
          background-color: #f7f7fc;
        }
      }

      // tipos de botones transparent
      &.transparent {
        &.white:hover {
          color: white;
        }
        &.pink:hover {
          color: #e33aa9;
        }
        &.gray:hover {
          .icon,
          .iconLoading {
            color: #7b7979;
          }
        }
        &.purple:hover {
          color: #8a6caa;
        }
        &.black:hover {
          color: #262626;
        }
      }
    }
  }
`;
