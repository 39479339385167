import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--gray-light);
  align-items: center;

  h2 {
    font-family: Roboto, sans-serif;
    font-weight: 600;
    font-size: 15px;
  }

  .phase-sel {
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 300px;
  }

  .text-phase {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    color: var(--gray-contentoh);
    white-space: nowrap;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    h2 {
      font-size: 14px;
    }

    .phase-sel {
      width: 100%;
      justify-content: space-between;
    }

    .text-phase {
      font-size: 11px;
    }
  }

  @media (max-width: 480px) {
    h2 {
      font-size: 13px;
    }

    .phase-sel {
      flex-direction: column;
      gap: 5px;
      align-items: flex-start;
    }

    .text-phase {
      font-size: 10px;
    }
  }
`;
