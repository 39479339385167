import styled from "styled-components";

export const ImageViewer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .main-image-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .main-image {
    max-width: 240px;
    height: auto;
  }

  .thumbnails-container {
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 200px;
    overflow-x: auto;
  }

  .thumbnail {
    width: 50px;
    height: 40px;
    cursor: pointer;
  }

  .thumbnail.active {
    border: 1px solid var(--purple);
    border-radius: 10px;
  }
`;
