import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  font-size: 1rem;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #ddd;
  border-radius: 15px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.015em;
  box-sizing: border-box;
  color: rgb(129, 115, 147);
  overflow: hidden;
`;

export const TableHeader = styled.th`
  padding: 3px 10px;
  border-bottom: 1px solid #ddd;

  &:first-child {
    border-top-left-radius: 15px;
  }

  &:last-child {
    border-top-right-radius: 15px;
  }
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;

  &:last-child td:first-child {
    border-bottom-left-radius: 15px;
  }

  &:last-child td:last-child {
    border-bottom-right-radius: 15px;
  }
`;

export const TableCell = styled.td`
  padding: 5px;
  border: none;
`;
