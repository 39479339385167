import styled from "styled-components";
import { FontFamily, GlobalColors } from "../../../global-files/variables";

export const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;
  padding: 5px;

  .profile-image,
  .medium-image {
    & + * {
      margin-left: 15px;
    }
  }

  .labels-container {
    font-family: ${FontFamily.AvenirNext};
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    .name-label,
    .team-label {
      inline-size: 100px;
      overflow: hidden;
    }

    .name-label {
      color: ${GlobalColors.s5};
    }

    .team-label {
      color: ${GlobalColors.s4};
    }

    & + * {
      margin-left: 11px;
    }
  }

  .status-container {
    & + * {
      margin-left: 10px;
    }
  }

  .green-circle {
    background-color: ${GlobalColors.exported};
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  button {
    background-color: transparent;
    cursor: pointer;
    border: none;
  }

  &:hover {
    background-color: ${GlobalColors.s3};
  }

  & + * {
    margin-top: 10px;
  }
`;
