import { Container, ContainerItemChat, ContainerTooltip } from "./styles";
import { useEffect, useState } from "react";
import { getCustomDate } from "../../../../global-files/utils";
import { faImage as IconImage } from "@fortawesome/free-solid-svg-icons";
import { CustomIcon } from "../../../atoms/CustomIcon";
import { Image } from "../../../atoms/Image";
import { Tooltip } from "../../../atoms/Tooltip";

export const ChatLists = (props) => {
  const {
    companies /* {
        id: { name , src , statusChat , items } ,
        ...
    } */,
    currentCompanyId, // number
    onClickCompany, // (id) => {}
  } = props;
  const [openChats, setOpenChats] = useState([]);
  const [closedChats, setClosedChats] = useState([]);

  useEffect(() => {
    renderChats();
  }, [companies]);

  const renderChats = () => {
    let jsxOpenChats = [];
    let jsxClosedChats = [];

    for (const companyId of Object.keys(companies)) {
      const company = companies[companyId];
      // crear el item chat
      const itemChat = (
        <ContainerItemChat
          key={"itemChat-" + companyId}
          onClick={(event) => {
            onClickCompany && onClickCompany(companyId);
          }}
        >
          {/* logo de la compañia */}
          <Image
            className="img-logoCompany"
            width={"34px"}
            sizeLoading={24}
            colorLoading={undefined}
            src={company.src}
            componentDefault={
              <CustomIcon
                className="icon-logoCompany"
                type={"white"}
                borderType={"circle"}
                size={15}
                icon={IconImage}
              />
            }
          />

          {/* name y date */}
          <div className="container-info">
            <label className="label-name"> {company.name} </label>
            <label className="label-lastDate">
              {getCustomDate(
                new Date(
                  company.items.length ? company.items[0].date : undefined
                )
              )}
            </label>
          </div>

          {/* label interno */}
          <div className="container-indicators">
            {currentCompanyId === companyId && (
              <div className="label-ownCompany">Interno</div>
            )}
          </div>
        </ContainerItemChat>
      );

      // checar si es chat abierto
      if (company.statusChat === "closed") jsxClosedChats.push(itemChat);
      else jsxOpenChats.push(itemChat);
    }
    setOpenChats(jsxOpenChats);
    setClosedChats(jsxClosedChats);
  };

  return (
    <>
      <Container>
        {openChats.length !== 0 && (
          <section>
            <div className="container-headerChats">
              <Tooltip
                className={"tooltip-title"}
                position={"topCenter"}
                addArrow={false}
                transitionType={"zoom"}
                followCursor={false}
                componentTooltip={
                  <ContainerTooltip>
                    <label className="label-title"> Chats abiertos </label>
                    <label className="label-message">{`De acuerdo al estatus 
                  actual del producto, puedes establecer una conversación 
                  con estas compañias`}</label>
                  </ContainerTooltip>
                }
              >
                <label> Chats abiertos </label>
              </Tooltip>
            </div>
            <div className="container-bodyChats">{openChats}</div>
          </section>
        )}

        {closedChats.length !== 0 && (
          <section>
            <div className="container-headerChats">
              <Tooltip
                className={"tooltip-title"}
                position={"topCenter"}
                addArrow={false}
                transitionType={"zoom"}
                followCursor={false}
                componentTooltip={
                  <ContainerTooltip>
                    <label className="label-title"> Chats cerrados </label>
                    <label className="label-message">{`Las conversaciones 
                  con estas compañias han finalizado debido al cambio de 
                  estatus, sigue el flujo de trabajo con tus chats abiertos`}</label>
                  </ContainerTooltip>
                }
              >
                <label> Chats cerrados </label>
              </Tooltip>
            </div>
            <div className="container-bodyChats">{closedChats}</div>
          </section>
        )}
      </Container>
    </>
  );
};
