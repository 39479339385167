import { Container } from "./styles";
import { faArrowDown as IconDownload } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { ButtonV2 } from "../../atoms/ButtonV2";
import { IconFile } from "../../atoms/IconFile";
import axios from "axios";
import { getFileExtension } from "../../../global-files/utils";

export const ButtonDownloadFile = (props) => {
  const {
    id,
    key,
    className,
    type, // white | pink | gray | purple (white -> predeterminado)
    transparent, // boolean
    size, // number ... default (13)
    borderType, // rectangle || circle || (cualquier otro valor -> no borde)
    url, // src del file
    onDownload, // ( {message , details} ) => {}
  } = props;
  const [showDownload, setShowDownload] = useState(false);
  const [loading, setLoading] = useState(false);

  const getSrc = () => {
    const temp = url.split("/");
    return temp[temp.length - 1];
  };

  const getFile = async () => {
    try {
      setLoading(true);
      const responseFile = await axios({
        url: url,
        method: "GET",
        responseType: "blob",
      });
      if (responseFile.status !== 200) {
        setLoading(false);
        onDownload &&
          onDownload({
            message: "No se pudo descargar el archivo",
            details: responseFile.statusText,
          });
        return;
      }
      const newUrl = window.URL.createObjectURL(new Blob([responseFile.data]));
      const link = document.createElement("a");
      link.href = newUrl;
      link.setAttribute("download", getSrc());
      link.style.display = "none";
      document.body.appendChild(link);
      setLoading(false);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      setLoading(false);
      onDownload &&
        onDownload({
          message: "No se pudo descargar el archivo",
          details: err.message,
        });
    }
  };

  return (
    <>
      <Container
        id={id}
        key={key}
        className={className}
        borderType={borderType}
        onMouseEnter={(event) => {
          if (!showDownload) {
            setShowDownload(true);
          }
        }}
        onMouseLeave={(event) => {
          if (!loading) {
            setShowDownload(false);
          }
        }}
      >
        {showDownload ? (
          <ButtonV2
            className={"buttonDownload"}
            type={type}
            transparent={transparent}
            size={size}
            borderType={borderType}
            icon={IconDownload}
            isLoading={loading}
            onClick={async (event) => {
              getFile();
            }}
          />
        ) : (
          <IconFile
            className="iconFile"
            type={type}
            transparent={transparent}
            size={size}
            borderType={borderType}
            ext={getFileExtension(getSrc())}
          />
        )}
      </Container>
    </>
  );
};
