import styled, { css } from "styled-components";
import TooltipMUI from "@mui/material/Tooltip";

const StyledTooltip = styled(({ className, ...props }) => (
  <TooltipMUI {...props} classes={{ tooltip: className}} />
))`
  &.MuiTooltip-tooltip {
    box-shadow: rgba(22, 8, 43, 0.1) 1px 1px 1px;
    font-size: 10px;
    ${({ variant }) =>
      variant === "gray" &&
      css`
        background-color: var(--gray-light);
        color: var(--black);
      `}
    
    ${({ variant }) =>
      variant === "white" &&
      css`
        background-color: var(--white-contentoh);
        color: var(--black);
        border: 1px solid var(--gray);
      `}
    
    ${({ variant }) =>
      variant === "blue-light" &&
      css`
        background-color: var(--blue-light);
        color: var(--white);
      `}
  }

`;

export default StyledTooltip;
