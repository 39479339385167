import { Container } from "./styles";
import { ScreenHeader } from "../../atoms/ScreenHeader/index";
import { Avatar } from "../../atoms/Avatar";
import { Status as StatusTag } from "../../atoms/Status/index";
import { Button } from "../../atoms/GeneralButton";
import { useEffect, useState } from "react";
import { AsignationOption } from "../../atoms/AsignationOption/index";
import { getProfilePicture } from "../../../global-files/data";
import { FinancedCompanies } from "./FinancedCompanies";

export const StatusAsignationInfo = ({
  status = "-",
  imagesSection,
  setImageLayout,
  percent = 0,
  assignationsImages,
  setAssignation,
  isRetailer,
  downloadImages,
  askToDeleteImages,
  id = "default-id",
  onClickSave,
  showSaveButton,
  canAssign,
  version,
  setShowVersionSelector,
  user,
}) => {
  const [showAsignationPanel, setShowAsignationPanel] = useState(false);
  const [layout, setLayout] = useState(false);
  const [assignationType, setAssignationType] = useState("facilitator");
  const isFinanced = FinancedCompanies.includes(user?.id_company);

  const closeAsignations = (e) => {
    if (!e.target.closest("#default-id") && showAsignationPanel) {
      document.removeEventListener("click", closeAsignations, false);
      setShowAsignationPanel(false);
    }
  };
  // const slidefront = [
  //   {
  //     slide: Slide1,
  //     title: "Valida tu producto en cada activo digital",
  //     description:
  //       "Al solicitar a Content-oh! podrás validar la información, imágenes y documentación de tu producto, esto hará más sencillo que puedas aprobar o rechazar el contenido.",
  //   },
  //   {
  //     slide: Slide2,
  //     title: "Aprueba o rechaza con un clic",
  //     description:
  //       "Cuando termines de revisar tu información, elige si aproebas o rechazas, esto enviara una notificiación a nuestro equipo, para continuar o corregir el producto.",
  //   },
  //   {
  //     slide: Slide3,
  //     title: "Estatus de tu producto en el proceso",
  //     description:
  //       "La mayor parte del proceso podrás identificar que estatus tiene tu producto, esto dara visibilidad a todo tu equipo para saber la etapa se encuentra.",
  //   },
  // ];

  useEffect(() => {
    if (showAsignationPanel) {
      document.addEventListener("click", closeAsignations, false);
    }
  }, [showAsignationPanel]);

  return (
    <Container id={id}>
      {/* <SliderToolTip
        infoIcon={InfoIcon}
        slidefront={slidefront}
        iconSize={"medium-image"}
        slidePosition={"bottom-slide"}
      /> */}
      {version && (
        <Button
          buttonType={"general circular-button version-button"}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setShowVersionSelector(true);
          }}
          label={`V${version}`}
        />
      )}
      {/* {status !== "-" && <StatusTag statusType={status} ovalForm={true} />} */}
        <Button
          buttonType={"circular-button save-button"}
          onClick={onClickSave}
        />
      {imagesSection && (
        <div className="images-buttons">
          {showSaveButton && (
            <Button
              buttonType={"circular-button delete-button"}
              onClick={() => {
                askToDeleteImages && askToDeleteImages();
              }}
            />
          )}
          <Button
            buttonType={"circular-button download-button"}
            onClick={() => {
              downloadImages();
            }}
          />
          <Button
            buttonType={layout ? "grid-layout" : "row-layout"}
            onClick={() => {
              setLayout(!layout);
              setImageLayout && setImageLayout(!layout);
            }}
          />
        </div>
      )}
      <div className="assignations-container">
        {assignationsImages?.assignations?.map((assignation, index) => (
          <Avatar
            key={index + "-" + assignation.id}
            imageType={"small-image"}
            image={getProfilePicture(assignation.id, 20, 20)}
            altText="user profile image"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowAsignationPanel(true);
              setAssignationType(assignation?.collaboratorType);
            }}
          />
        ))}
      </div>
      <ScreenHeader
        text={"Asig."}
        color={"#969696"}
        fontFamily={"Avenir Next"}
        headerType={"input-name-header"}
      />
      {showAsignationPanel && canAssign && (
        <div className="asignation-panel">
          <div className="asignation-header">
            <div className="asignation-status">
              <p>Estatus</p>
              <StatusTag statusType={status} ovalForm={true} />
            </div>
            <div className="asignation-percent">
              <p>Estatus</p>
              <p className="percent">{percent}%</p>
            </div>
          </div>
          <div className="asignations-list">
            {assignationsImages?.collaborators !== undefined ? (
              assignationsImages?.collaborators[assignationType]?.map(
                (asignation, index) => (
                  <AsignationOption
                    key={index + "-" + asignation.name}
                    profileImage={getProfilePicture(
                      asignation?.id_user,
                      40,
                      40
                    )}
                    onClick={() => {
                      setAssignation(assignationType, asignation?.id_user);
                    }}
                    name={asignation.name}
                  />
                )
              )
            ) : (
              <p className="no-asignations">Sin personas para asignar</p>
            )}
          </div>
          {isRetailer === 1 && (
            <div className="default-asignations-list">
              <AsignationOption
                asignationType={"provider"}
                name={"Solicitar a"}
                team={"Proveedor"}
              />
              <AsignationOption
                asignationType={"team"}
                name={"Solicitar a"}
                team={"Content-oh!"}
              />
            </div>
          )}
        </div>
      )}
    </Container>
  );
};
