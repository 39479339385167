import styled from "styled-components";

export const ContainerPagination = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 20px;
    gap: 5px;
`;

