import styled from "styled-components";

export const Container = styled.div`
  /* position: relative;
  height: 100%;
  width: 100%; */
`;
export const Observation = styled.div`
  position: relative;
  /* height: 100%;
  width: 100%; */
  z-index: 10000;
`;

// export const Content = styled.div`
//   position: relative;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
//   width: 100%;
//   & > div {
//     width: 30px;
//     height: 20px;
//     font-family: Lato;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 12px;
//     line-height: 20px;
//     text-align: center;
//     border-radius: 3px;
//     color: ${({ cont }) =>
//       cont === "-" || cont === "Pt" ? "#817393" : "#FFF"};
//     background-color: ${({ cont }) => backgroundContent(cont)};
//   }
// `;

export const ServTab = styled.div`
  position: absolute;
  top: 0;
  right: 10%;
  z-index: 2;
  width: 300px;
  max-height: 280px;
  overflow-y: auto;
  box-sizing: border-box;
  background: #281f33;
  padding: 8px;
  white-space: normal;
  font-family: Avenir Next;
  font-style: normal;
  > div {
    background-color: #503d66;
    margin-bottom: 8px;
    color: #f0eef2;
    box-sizing: border-box;
    padding: 8px;
    text-align: left;
    > span {
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
    }
    > p {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
    }
  }
  .flex {
    display: flex;
    width: 100%;
    background-color: #503d6644;
    margin-left: 0px;
    p {
      width: 16%;
      &:first-child {
        width: 50%;
      }
    }
  }
  .title {
    position: sticky;
    top: -9px;
    background-color: #503d66;
  }
  .ignore-background {
    background-color: #1111;
    margin: 0;
  }
`;

export const SaveButton = styled.button`
  position: sticky;
  bottom: 0;
  left: 100%;
  background-color: #fbfbfb;
  border: 2px solid #e33aa9;
  width: 20px;
  height: 20px;
  padding: 0;
  cursor: pointer;
  min-height: initial;
`;
