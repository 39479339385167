import { useState, useEffect } from "react";

export const useCloseModal = (id) => {
  const [showModal, setShowModal] = useState(false);

  const closeModal = (e) => {
    e.stopPropagation();
    if (!e.target.closest(`#${id}`) && showModal) {
      document.removeEventListener("click", closeModal, false);
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (showModal) {
      document.addEventListener("click", closeModal, false);
    }
    return () => {
      document.removeEventListener("click", closeModal, false);
    };
  }, [showModal]);

  return [showModal, setShowModal];
};
