import { MainContainer } from "./styles";

const ModalExport = ({ productsSelected }) => (
  <MainContainer>
    <div className="products-select">
      <p>{productsSelected}</p>
    </div>
    <h1>{productsSelected === 1 ? "Producto Elegido" : "Productos Elegidos"}</h1>
    {/* <h2>Selecciona entregables</h2> */}
    <h2>Cadena: Radioshack</h2>
  </MainContainer>
);

export default ModalExport;
