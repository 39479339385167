import React, { useState, useEffect } from "react";
import HeaderFilter from "../general/HeaderFilter";
import { MainContainer } from "./styles";
import GridView from "../general/GridView";
import { getProducts, validatePassword, changeVisibility, filterProducts } from "./utils";
import useNotifications from "../../hooks/useNotification";
import CustomNotification from "../general/CustomNotification";
import Modal from "../general/Modal";
import { useModal } from "../../hooks/useModal";
import CustomInput from "../general/CustomInput";
const OnboardingProducts = ({ user }) => {
  const [gridView, setGridView] = useState(true);
  const [gridData, setGridData] = useState([]);
  const [productInfo, setProductInfo] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [headersTable, setHeadersTable] = useState([]);
  const [headerMapping, setHeaderMapping] = useState([]);
  const [percentageParams, setPercentageParams] = useState([]);
  const [dataDetailTable, setDataDetailTable] = useState([]);
  const [productCount, setProductCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterLists, setFilterLists] = useState(null);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [wishlistFilter, setWishlistFilter] = useState([]);
  const {
    notifications,
    notificationOpen,
    addNotification,
    deleteNotification,
    setNotificationOpen,
  } = useNotifications();
  const isProvider = user.is_retailer === 0 && user.id_role === 0; 
  const { modalData, openModal, closeModal, handleCancelClick } = useModal();
  const [priceRange, setPriceRange] = useState([0, 100000]);
  const [selectedWishlist, setSelectedWishlist] = useState(null);
  const { filteredGridData, filteredTableData } = filterProducts(
    gridData,
    tableData,
    selectedWishlist,
    priceRange,
    searchValue
  );

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const products = await getProducts();
      setGridData(products.gridData);
      setTableData(products.tableData);
      setProductInfo(products.productInfo);
      setProductCount(products.tableData.length);
      setHeadersTable(products.headersTable);
      setHeaderMapping(products.headerMapping);
      setPercentageParams(products.percentageParams);
      setWishlistFilter(products.wishListFilter);
      setDataDetailTable(products.dataDetailTable);
    } catch (error) {
      console.error("Error al obtener productos:", error);
      addNotification("error", "Error al obtener productos");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleVisibility = (id_article = null, isVisible = null) => {
    if (selectedArticles.length === 0 && id_article === null) {
      addNotification("error", "No hay artículos seleccionados");
      return;
    }
    let articlesToUpdate;
    if (id_article !== null) {
      articlesToUpdate = [
        { id_article, newVisibility: isVisible === 1 ? 0 : 1 },
      ];
    } else {
      articlesToUpdate = selectedArticles.map((article) => ({
        ...article,
        newVisibility: article.isVisible === 1 ? 0 : 1,
      }));
    }

    let passwordValue = "";
    let setPasswordError = false;

    const handlePasswordChange = (e, setError) => {
      passwordValue = e.target.value;
      setPasswordError = setError;
    };

    openModal({
      className: "modal-visibility",
      title: "Cambiar visibilidad",
      text: "Para cambiar la visibilidad ingresa la clave de aprobación",
      customComponent: (
        <CustomInput
          id="onboarding-password"
          type="password"
          placeholder="Clave de aprobación"
          onChange={(e) => handlePasswordChange(e, setPasswordError)}
        />
      ),
      buttons: [
        {
          label: "Cancelar",
          onClick: closeModal,
          className: "btn-cancelar",
        },
        {
          label: "Cambiar",
          onClick: async () => {
            if (validatePassword(passwordValue)) {
              const success = await changeVisibility(
                articlesToUpdate,
                addNotification,
                closeModal
              );
              if (success) {
                fetchProducts();
                setSelectedArticles([]);
              }
            } else {
              setPasswordError = true;
              addNotification("error", "Contraseña incorrecta");
            }
          },
          className: "btn-cambiar",
        },
      ],
    });
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  return (
    <MainContainer>
      <HeaderFilter
        searchBar
        placeholderSearchBar="Buscar producto"
        filterWish
        filterPrice
        showWishlist={isProvider}
        showVisibility={isProvider}
        showCart={!isProvider}
        showView
        gridView={gridView}
        setGridView={setGridView}
        onVisibilityChange={handleVisibility}
        wishlistFilter={wishlistFilter}
        setWishlistFilter={setWishlistFilter}
        onPriceChange={setPriceRange}
        onWishlistChange={setSelectedWishlist}
        onSearchChange={handleSearchChange}
        selectedArticles={selectedArticles}
        setSelectedArticles={setSelectedArticles}
      />
      <GridView
        isGridView={gridView}
        data={{ tableData: filteredTableData, gridData: filteredGridData }}
        headers={headersTable}
        headerMapping={headerMapping}
        percentageParams={percentageParams}
        pulsating
        visibility={isProvider}
        cart={!isProvider}
        favorite
        typePercentage="percentageRequired"
        cardsPerPage={12}
        rowsPerPage={15}
        fetchProducts={fetchProducts}
        onVisibilityChange={handleVisibility}
        selectedArticles={selectedArticles}
        setSelectedArticles={setSelectedArticles}
        wishlistFilter={wishlistFilter}
        setWishlistFilter={setWishlistFilter}
        dataDetailTable={dataDetailTable}
        productInfo={productInfo}
        showEditButtonTable={isProvider}
      />
      <Modal {...modalData} onClose={handleCancelClick} />
      <CustomNotification
        open={notifications.length > 0}
        alerts={notifications}
        deleteNotification={deleteNotification}
      />
    </MainContainer>
  );
};

export default OnboardingProducts;
