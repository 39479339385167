export const es = {
    login: {
        title: "Iniciar Sesión"
    },
    headers: {
        upgradePlan: { title: "Mi Cuenta" },
        acquired: { title: "Adquirido" },
        products: {
            title: "Productos",
            info: "Lista de productos"
        },
        merchants: {
            title: "Productos de Onboarding",
            info: "Lista de productos"
        },
        productsMultipleEdition: {
            title: "Productos",
            info: "Edición de productos"
        },
        editProducts: {
            title: "Productos",
            info: "Lista de productos"
        },
        matchTask: {
            title: "Tareas",
            info: "Lista de productos asignados"
        },
        contentoh: { info: "Productos" },
        orders: {
            info: "Órdenes de trabajo"
        },
        ordersBilling: {
            info: "Facturación"
        },
        profile: {
            title: "Mi cuenta",
            info: "Información de usuario"
        },
        addProducts: {
            title: "Productos",
            info: "Proveedores"
        },
        productAdd: {
            title: "Productos",
            info: ""
        },
        providers: {
            title: "Productos",
            info: "Productos solicitados a proveedor"
        },
        quotes: {
            title: "",
            info: "Citas para entrega de producto"
        },
        checkout: {
            info: "Proveedor"
        },
        transferPay: {
            info: "Proveedor"
        },
        cardPay: {
            info: "Proveedor"
        },
        retailers: {
            title: "Cadena",
        },
        dashboardContentoh: {
            info: "Proveedor"
        },
        editionMultiple: {
            title: "Productos",
            info: "Lista de productos > Edición multiple"
        },
        tasks: {
            title: "Tareas",
            info: "Tablero de tareas"
        },
        contentohProducts: {
            info: "Productos"
        },
        merchantLists: {
            title: "Listas",
            info: "Listas de productos merchants"
        }
    },
    checkout: {
        requestedService: {
            title: "Servicios solicitados",
            cta: "Ir al checkout",
            table: {
                name: "Nombre",
                chains: "Cadenas",
                service: "Servicios",
                price: "Precio",
            },
            summary: {
                products: "PRODUCTOS",
                retailer: "CADENAS",
                dataSheet: "FICHA TÉCNICA",
                description: "DESCRIPCIÓNES",
                images: "IMÁGENES",
            }
        },
        footerInfo: {
            products: "PRODUCTOS",
            chains: "CADENAS",
            tecnicalSheet: "FICHAS TÉCNICAS",
            descriptions: "DESCRIPCIONES",
            images: "IMÁGENES",
            iva: "IVA",
            loader: "Esta acción podría demorar",
            payButton: {
                anyProduct: "Carrito Vacío",
                pay: "Pagar",
                continuar: "Continuar",
            }
        },
        buttonStepData: {
            confirm: "Confirmar cadenas y servicios",
            update: "Actualización completa",
        },
        steps: {
            total: {
                products: "Productos",
                price: "Precio",
                retailerChain: "cadenas"
            },
            second: {
                title: "Tipo de entrega",
                office: {
                    title: "En oficina",
                    desc: "Tu nos entregas los productos en nuestras instalaciones."
                },
                pickUp: {
                    title: "Pick Up",
                    desc: "Nosotros pasamos por los productos a tus instalaciones."
                },
                inSitu: {
                    title: "In situ",
                    desc: "Trabajamos los productos en tus instalaciones. Solo para CDMX."
                },
                shedule: {
                    title: "Agenda tu visita",
                    paragraph: "Elige el día que llevarás tus productos a nuestras instalaciones."
                },
                inOffice: {
                    subtitle: "Dirección de nuestras oficinas",
                    mail: "Enviar por correo",
                    contact: "Contacto"
                },
                defaultForm: {
                    address: "Escribe tu dirección o selecciona en el mapa",
                    street: "Calle y colonia",
                    state: "Estado",
                    zipCode: "C.P.",
                    collectedName: "Nombre",
                    telephone: "Teléfono",
                    contact: "Contacto de recolección",
                },
                financed: {
                    sede: "Elige una sede",
                    input: "Tipo de input",
                    request: "Solicitado por",
                    type: "Tipo",
                }
            },
            third: {
                datasheet: "Ficha técnica",
                description: "Descripción",
                images: "Imágenes",
                translate: "Traducción de ficha técnica",
                build: "Construcción de manuales",
                search: "Búsqueda",
                dataModal: {
                    paymentNull: "No selecionó ningún método de pago",
                    card: "No seleccionó ninguna tarjeta",
                    error: "Algo salió mal con tu pago...",
                    orderCard: "Datos de la tarjeta invalidos",
                    contract: "Faltan de llenar algunos datos del contrato",
                },
                contract: {
                    title: "Pago por contrato asignado",
                    contractNumber: "No. de contrato",
                    company: "Empresa",
                },
                transfer: {
                    office: "Pago en ventanilla",
                    transfer: "Pago con transferencia",
                    bank: "Nombre del Banco",
                    reference: "Referencia",
                },
                table: {
                    name: "Nombre",
                    chain: "Cadena",
                    service: "Servicio",
                    price: "Precio",
                }
            }
        },
        dataModal: {
            button: "Aceptar",
            success: {
                title: "Orden creada con éxito",
                message: "Tu orden es: "
            },
            error: {
                title: "Ups! Algo salió mal",
                message: "Contacta al equipo de TI para resolver tu caso"
            }
        },
        deleteModal: {
            text: "¿Seguro que quieres borrar los productos o cadenas eleccionadas?",
            buttons: {
                cancel: "Cancelar",
                delete: "Borrar"
            }
        },
        buttonSteps: {
            step1: "Confirmar cadenas y servicios",
            step2: "Elegir envío",
            step3: "Método de pago",
        },
        servicePanel: {
            dataService: {
                dataset: {
                    title: "Ficha técnica",
                    detail: "La ficha técnica es la información más importante del producto, relevante para cumplir muchas normativas y obligatoria en la mayoría de las cadenas.",
                },
                article: {
                    title: "Descripción",
                    detail: "Enamoramos a tu cliente con la descripción del producto, explicamos como es, como funciona y con las fotos, ya no necesitas imaginar el producto.",
                },
                image: {
                    title: "Imágenes",
                    detail: "Si hay algo más importante que ficha técnica, son las imágenes. No puede existir el e-commerce sin las imágenes de tus productos.",
                }
            },
            button: {
                add: "Agregar",
                remove: "Quitar",
                action: "Esta acción se aplicará para todos los productos"
            }
        },
        dropdownButton: {
            modalUpdate: {
                title: "Actualizando...",
                message: "Esta acción puede demorar unos segundos...",
            },
            modalUpdateSuccess: {
                title: "Se actualizó con éxito",
            },
            modalUpdateError: {
                title: "Algo salió mal",
                message: "Puedes reportar esto con el equipo de TI",
            },
            buttonLabels: {
                accept: "Aceptar",
                cancel: "Cancelar",
            },
            modalTitles: {
                retailer: "Agregar cadenas",
                service: "Agregar servicios",
            },
            menuOptions: {
                updateServices: "Actualizar servicios",
                updateRetailers: "Actualizar cadenas",
            },
            retailerComponentModal: {
                paragraph: "Se agregarán a tus productos seleccionados.",
                selectLabel: "Agregar cadena",
                placeholder: "Buscar cadena",
            },
            productItem: {
                title: "Descuento por servicios",
                chains: "Cadenas",
                one: {
                    discount: "Accede a descuentos por selección de tipos de servicios.",
                    discount10: "10% al seleccionar 2 servicios.",
                    discount20: "20% al seleccionar 3 servicios"
                },
                two: {
                    discount10: "Tienes un descuento del 10% por haber seleccionado 2 tipos de servicios.",
                    discount20: "Agrega un servicio más para obtener un 20% de descuento."
                },
                three: {
                    discount20: "Tienes un descuento del 20% por haber seleccionado 3 tipos de servicios."
                }
            },
            retailerItem: {
                serviceDetail: {
                    dataset: {
                        name: "Ficha Técnica",
                        short: "atri."
                    },
                    article: {
                        name: "Descripción",
                        short: "desc."
                    },
                    image: {
                        name: "Imágenes",
                        short: "imag."
                    }
                }
            },
        }
    },
    sidebar: {
        dashboard: "Dashboard",
        products: {
            title: "Productos",
            general: "General",
            addProduct: "Agregar Producto"
        },
        contentoh: {
            title: "Content-oh!",
            products: "Productos",
            orders: "Órdenes",
            quotes: "Citas"
        },
        onboarding: {
            title: "Onboarding",
            merchants: "Productos",
            lists: "Listas",
            acquired: "Adquiridos"
        },
        tasks: "Tareas"
    },
    general: {
        title: "Lista de productos",
        subtitle: "Visualiza de forma general todos los productos, da click sobre alguno para ver el detalle o selecciona varios y trabaja en multiedición.",
        tableSection: {
            name: "NOMBRE",
            category: "CATEGORÍA",
            retailers: "CADENAS",
            percentage: "PORCENTAJE",
            version: "VERSIÓN",
            val: "VAL.",
            date: "FECHA",
        },
        productDetail: {
            genericModal: {
                message: "Tu plan actual no te permite realizar esta acción",
                detail: "¿Quieres actualizarlo?",
                buttons: {
                    cancel: "Cancelar",
                    upgrade: "Actualizar"
                },
            },
            container: {
                progress: "Progreso",
                status: "Estatus",
                version: "Versión",
                category: "Categoría",
                retailers: "Cadenas",
                images: "Imágenes",
            },
            progress: {
                title: "Porcentaje de avance",
                image: "Asig.",
                valid: "Validado",
            },
            description: {
                name: "Nombre",
                size: "Tamaño",
            },
            dataSheet: "Información básica del producto",
            comments: "Comentarios",
            content: {
                title: "Contenido restante",
                description: "Para este producto requieres completar los siguientes activos, puedes agregarlos a tu carrito y nos haremos cargo de entregar tus productos de forma correcta.",
                content: "Contenido",
            },
            summary: {
                products: "Productos",
                dataSheet: "Ficha técnica",
                description: "Descripción",
                images: "Imágenes",
            },
            inCart: {
                duplicated: "¡Árticulo ya en el carrito!",
                request: "Solicitar a Content-oh!",
            },
            comment: "Agregar comentario",
            buttonSend: "Enviar",
            buttonValidate: "Validar",
        }
    },
    exports: {
        exportModal: {
            title: "Productos Elegidos",
            subtitle: "Selecciona entregables",
            formActive: {
                title: "Selecciona los activos",
                datasheet: "Fichas Técnicas",
                description: "Descripciones",
                image: "Imágenes",
                brands: "Selecciona las Cadenas",
                selectAll: "Seleccionar todo",
            },
            buttons: {
                back: "Regresar",
                export: "Exportar",
            }
        },
        versionModal: {
            title: "Elige la versión de tus productos",
            button: "Versión",
        },
        productsExportModal: {
            title: "Productos a Exportar",
            form: {
                title: "Los siguientes productos se encuentrán incompletos:",
                upc: "UPC",
                name: "Nombre",
                retailer: "Cadena",
                status: "Estatus",
            },
            buttons: {
                complete: "Completar Productos",
                export: "Exportar Productos",
            }
        },
        exportedModal: {
            title: "Tus productos se están procesando.",
            subtitle: "Te compartiremos una liga de descarga por correo electrónico al terminar.",
            close: "Cerrar",
        },
    },
    addProducts: {
        utils: {
            onLoad: {
                modal: {
                    message: "Error en archivo de plantilla",
                    detail: "Puede que la plantilla que estás intentando subir no sea la correcta para esta tabla, intenta volverla a descargar"
                }
            },
            errorFileComponent: "Salir",
            uploadFile: {
                message: "Descarga plantilla",
                button: "Subir"
            },
            upcList: {
                title: "Estos UPC's se encontraban en plataforma",
                subtitle: "Para la orden se utilizarán los UPC's ya existentes",
                button: "Continuar"
            }
        },
        bottom: {
            modalDataErrorCreate: {
                message: "Error al crear artículos",
                detail: "Al parecer se excede el límite de usuarios",
            },
            modalDataRepeatUpc: {
                message: "Algunos de los UPC ya se encuentran en plataforma",
                detail: "Se insertaron todos los UPC's a excepción de:"
            },
            modalDataError: {
                message: "Tienes algunos errores",
                detail: "Es probable que necesites completar un campo o que tengas UPC's repetidos"
            },
            uploadFile: {
                message: "¿Ya tienes el archivo de plantilla?",
                detail: "Completa la información como cada columna lo solicita. Al terminar sube tu archivo en este módulo.  Máx. 100 productos.",
            },
            description: {
                lightText: "Sube tus productos múltiples",
                lightText2: "en un ",
                boldText: "archivo CSV o XSL",
                boldText2: "Máx. 100 productos.",
            },
            emptyList: {
                message: "Lista de productos vacía",
                detail: "Ingresa al menos un producto para continuar",
                button: "Aceptar",
            },
            isRetailer: {
                provider: "Solicitar a proveedor",
                create: "Crear productos",
                send: "Enviar la solicitud al fabricante",
                add: "Añade estos productos a tu lista",
            },
            request: {
                title: "Solicitar a Content-oh!",
                subtitle: "Crea la órden de compra"
            }
        },
        mid: {
            table: {
                name: "Nombre",
                department: "Departamento",
                category: "Categoría",
                productType: "Tipo de producto",
                provider: "Proveedor",
                retailer: "Cadena",
                retailers: "Cadenas",
                error: "El UPC ya existe",
            },
        },
        top: {
            title: "Agrega tus productos",
            isRetailer: {
                title: "Proveedor nuevo",
                subtitle: "Agrega a tu nuevo proveedor"
            }
        }
    },
    buttonsSection: {
        export: "Exportar",
        upgradePlan: {
            message: "Tu plan actual no te permite utilizar esta función",
            detail: "¿Quieres actualizarlo?",
            cancel: "Cancelar",
            accept: "Actualizar"
        }
    }
}