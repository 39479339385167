import { Container,Loader } from "./styles";

export const Loading = () => {
  return (
    <Container className="loading">
      <Loader>
        <span className="first"></span>
        <span className="second"></span>
        <span className="third"></span>
      </Loader>
    </Container>
  );
};
