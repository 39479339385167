import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: var(--background);
  color: var(--white-contentoh);
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
  .contentoh-logo {
    width: 35px;
    margin-top: 13px;
  }

  animation: bounce 1s infinite;

  @keyframes bounce {
    0%,
    100% {
      transform: translateY(-25%);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: translateY(0);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
`;
