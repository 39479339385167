import styled from "styled-components";
import checkedPurple from "../../../assets/checkBox/checked.svg";
import checkedGreen from "../../../assets/checkBox/checkverde.svg";
import checkedPink from "../../../assets/checkBox/checkrosa.svg";
import unchecked from "../../../assets/checkBox/unchecked.svg";
import { FontFamily, GlobalColors } from "../../../global-files/variables";

export const Container = styled.div`
  input {
    display: none;
    &:checked + label {
      &:before {
        background-color: ${GlobalColors.white};
        background-image: ${({ typeColor }) => ( typeColor === "purple" ? 
        `url(${checkedPurple})` : typeColor === "green" 
        ? `url(${checkedGreen})` : `url(${checkedPink})` )}
      } 
    }
  }

  label {
    position: relative;
    min-height: 16px;
    min-width: 16px;
    display: inline-block;

    p {
      display: inline;
      color: ${GlobalColors.s4};
      font-family: ${FontFamily.AvenirNext};
      font-weight: 500;
      font-size: 13px;
      line-height: 21px;
      margin-left: 20px;

      &.filter-text {
        color: ${GlobalColors.magenta_s2};
        font-size: 18px;
        line-height: 24px;
        margin-left: 23px;
      }
    }

    &:before {
      content: "";
      width: 16px;
      height: 16px;
      position: absolute;
      display: inline-block;
      top: 50%;
      transform: translateY(-50%);
      background-image: url(${unchecked});
      z-index: 5;
      cursor: pointer;
    }
  }
`;
