import axios from "axios";
import { Base64 } from "js-base64";
import { v4 as uuidv4 } from "uuid";

export const getRetailerServices = async (
  articleId,
  category,
  categoryId,
  version,
  Authorization
) => {
  try {
    const responseArray = await Promise.all([
      axios.post(
        `${process.env.REACT_APP_ARTICLE_DATA_DATASHEET_ENDPOINT}?idCategory=${categoryId}&articleId=${articleId}&version=${version}`,
        { retailers: false},
        { headers: { Authorization } }
      ),
      axios.get(
        `${process.env.REACT_APP_ARTICLE_DATA_DESCRIPTION_ENDPOINT}?idCategory=${categoryId}&articleId=${articleId}&version=${version}`,
        { headers: { Authorization } }
      ),
      axios.post(
        `${process.env.REACT_APP_ARTICLE_DATA_IMAGES_ENDPOINT}?articleId=${articleId}&version=${version}`,
        { category },
        { headers: { Authorization } }
      ),
    ]);

    const datasheets = JSON.parse(responseArray[0].data.body).data;
    const descriptions = JSON.parse(responseArray[1].data.body).data;
    const images = JSON.parse(responseArray[2].data.body).data;

    images.values = images?.values?.map((value) => {
      value.name = images.inputs.find((e) => e.id === value.image_id)?.name;
      return getImage(value);
    });

    return [datasheets, descriptions, images];
  } catch (error) {
    console.log(error);
  }
};

export const getImage = (imageGeneral, width = 250, height = 250) => {
  try {
    const image = imageGeneral;
    const ext = image.src.split(".");
    const uuid = ext[0].split("-");
    return {
      ...image,
      version: image.id_version,
      ext: ext[ext.length - 1],
      uuid:
        uuid.reduce((prevVal, currVal, idx) => {
          if (idx < 2) return "";
          else return idx === 2 ? currVal : prevVal + "-" + currVal;
        }, "") || uuidv4(),
      srcDB: image.src,
      src: image.image_id
        ? "https://d24s337exbt5zr.cloudfront.net/" +
          Base64.encode(
            JSON.stringify({
              bucket: process.env.REACT_APP_IMAGES_BUCKET,
              key: image.src,
              edits: {
                resize: {
                  width: width * 4,
                  height: height * 4,
                  fit: "contain",
                  background: {
                    r: 255,
                    g: 255,
                    b: 255,
                    alpha: 1,
                  },
                },
              },
            })
          )
        : image.src,
    };
  } catch (err) {
    console.log("err", err);
    return (imageGeneral["error"] = err);
  }
};

export const getPercentage = async (params = {}) => {
  const func = async () => {
    const headers = {
      Authorization:
        params?.headers?.Authorization ?? sessionStorage.getItem("jwt"),
    };
    return await axios
      .post(
        process.env.REACT_APP_VALID_EXPORT_ENDPOINT,
        { data: params.data },
        { headers }
      )
      .then((response) => [response.data])
      .catch((err) => console.log(err, "Errror"));
  };

  return await func();
};

export const getProfilePicture = (userId, width, height) => {
  try {
    return (
      "https://d24s337exbt5zr.cloudfront.net/" +
      Base64.encode(
        JSON.stringify({
          bucket: process.env.REACT_APP_IMAGES_PROFILE_BUCKET,
          key: `id-${userId}/${userId}.png`,
          edits: {
            resize: {
              width: width,
              height: height,
              fit: "contain",
              background: {
                r: 255,
                g: 255,
                b: 255,
                alpha: 0,
              },
            },
          },
        })
      )
    );
  } catch (err) {
    console.log("err", err);
    return null;
  }
};

export const fetchUsers = async (auth) => {
  const usersPromises = [];
  let usersGroups = [];
  const header = {
    headers: {
      Authorization: auth,
    },
  };
  /**
   * 7 will fetch all text specialists
   * 8 will fetch all img specialists
   * 4 will fetch all text facilitators
   * 5 will fetch all img facilitators
   * 6 will fetch all auditors
   */
  usersPromises.push(
    axios.get(`${process.env.REACT_APP_USER_ENDPOINT}?fetchRole=7`, header),
    axios.get(`${process.env.REACT_APP_USER_ENDPOINT}?fetchRole=8`, header),
    axios.get(`${process.env.REACT_APP_USER_ENDPOINT}?fetchRole=4`, header),
    axios.get(`${process.env.REACT_APP_USER_ENDPOINT}?fetchRole=5`, header),
    axios.get(`${process.env.REACT_APP_USER_ENDPOINT}?fetchRole=6`, header)
  );
  const usersResponse = await Promise.all(usersPromises);
  usersResponse.forEach((item, i) => {
    usersGroups.push(item.data.data);
  });
  return usersGroups;
};

export const getNewStatus = (statusArray) => {
  let lookupString = "";
  statusArray.forEach((element) => (lookupString += element + "/"));
  if (lookupString.includes("AS")) return "AS";
  if (lookupString.includes("RCA")) return "RCA";
  if (lookupString.includes("RC")) return "RC";
  if (lookupString.includes("RA")) return "RA";
  if (lookupString.includes("RP")) return "RP";
  if (lookupString.includes("ACA")) return "ACA";
  if (lookupString.includes("PA")) return "PA";
  if (lookupString.includes("CA")) return "CA";
  if (lookupString.includes("IE")) return "IE";
  if (lookupString.includes("AC")) return "AC";
  if (lookupString.includes("AA")) return "AA";
  if (lookupString.includes("AP")) return "AP";
  if (lookupString.includes("R")) return "R";
  if (lookupString.includes("NA")) return "NA";
  if (lookupString.includes("Ex")) return "EX";
  return new Error("Status not found");
};
