import { useState } from "react";
import PropTypes from "prop-types";
import ReactImageFallback from "react-image-fallback";
import { ImageContainer, ImageView, FullScreenModal } from "./styles";
import defaultProductImage from "../../../assets/defaultImages/defaultUpdate.png";
import CustomButton from "../CustomButton/index.jsx";
import AWS from "aws-sdk";
import useImageProduct from "../../../hooks/useImageProduct.jsx";
import axios from "axios";

const ImageVisor = ({ value, name, onToggle, onChange }) => {
  const [isSmallModalOpen, setSmallModalOpen] = useState(false);
  const [isFullScreenModalOpen, setFullScreenModalOpen] = useState(false);
  // const [imageSrc, setImageSrc] = useState(value || defaultProductImage);
  const imageSrc = useImageProduct(value, 2000, 2000);
  const [isDragging, setIsDragging] = useState(false);
  const [previewURL, setPreviewURL] = useState(value || defaultProductImage);
  const [selectedFile, setSelectedFile] = useState(null);

  const S3_BUCKET = process.env.REACT_APP_IMAGES_PROFILE_BUCKET;
  const REGION = "us-east-1";

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_KUTS3,
    secretAccessKey: process.env.REACT_APP_AKUTS3,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const toggleSmallModal = () => {
    setSmallModalOpen((prev) => {
      const newState = !prev;
      if (onToggle) onToggle(newState);
      return newState;
    });
  };

  const toggleFullScreenModal = () => {
    setFullScreenModalOpen((prev) => {
      const newState = !prev;
      if (onToggle) onToggle(newState);
      return newState;
    });
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      handleNewImage(files[0]);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleNewImage = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      // setImageSrc(reader.result);
    };
    reader.readAsDataURL(file);
  };

  // const handleNewImage = (file) => {
  //   if (file) {
  //     // Registrar los datos básicos del archivo
  //     console.log("Nombre del archivo:", file.name);
  //     console.log("Tipo del archivo:", file.type);
  //     console.log("Tamaño del archivo:", file.size, "bytes");

  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       const dataURL = reader.result;
  //       console.log("Data URL:", dataURL);
  //       // Si deseas actualizar la imagen en el estado, descomenta la siguiente línea
  //       // setImageSrc(dataURL);
  //       setPreviewURL(dataURL); // Actualiza la imagen mostrada
  //       setSelectedFile(file); // Almacena el archivo seleccionado
  //     };
  //     reader.onerror = (error) => {
  //       console.error("Error al leer el archivo:", error);
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     console.log("No se seleccionó ningún archivo.");
  //   }
  // };

  const handleSave = async () => {
    if (!selectedFile) {
      console.log("No hay imagen para guardar.");
      return;
    }

    try {
      // Determinar el tipo de archivo
      const fileType = selectedFile.type.split("/")[1];
      const fileName = ``;
      // const fileName = `id-${userAux.id_user}/${user.id_user}.${fileType}`;

      const params = {
        Body: selectedFile,
        Bucket: S3_BUCKET,
        Key: fileName,
        ContentType: selectedFile.type,
        ACL: "public-read", // Ajusta según tus necesidades de permisos
      };

      // Subir el archivo a S3
      const uploadResult = await myBucket.putObject(params).promise();
      console.log("Upload Success", uploadResult);

      // Construir la URL de la imagen subida
      const imageUrl = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${fileName}`;
      console.log("Image URL:", imageUrl);

      // Actualizar el estado con la nueva URL
      setPreviewURL(imageUrl);
      setSelectedFile(null); // Limpiar el archivo seleccionado

      // Notificar al componente padre sobre el cambio
      if (onChange) onChange(imageUrl);

      // (Opcional) Obtener los datos actualizados del usuario
      const response = await axios.get(process.env.REACT_APP_USER_ENDPOINT, {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      });
      console.log("User data updated:", response.data);
    } catch (error) {
      console.error("Error al subir la imagen a S3:", error);
    }
  };

  return (
    <>
      <ImageContainer onClick={toggleSmallModal}>
        <ReactImageFallback
          src={imageSrc}
          fallbackImage={defaultProductImage}
          alt={name ?? "Imagen del producto"}
        />
      </ImageContainer>

      {isSmallModalOpen && (
        <ImageView>
          <div className="header">
            <p>{name}</p>
            <div className="buttons">
              <CustomButton
                variant="gray"
                icon="fullscreen"
                onClick={toggleFullScreenModal}
                iconOnly
                showTooltip
                tooltipText="Ver en pantalla completa"
              />
              <CustomButton
                variant="gray"
                icon="close"
                iconOnly
                onClick={toggleSmallModal}
              />
            </div>
          </div>
          <div className="body">
            <ReactImageFallback
              src={imageSrc}
              fallbackImage={defaultProductImage}
              alt={name ?? "Imagen del producto"}
            />
          </div>
        </ImageView>
      )}

      {isFullScreenModalOpen && (
        <FullScreenModal
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onDragLeave={handleDragLeave}
        >
          <div className="header">
            <p className="name">{name}</p>
            <div className="buttons">
              <CustomButton
                variant="gray"
                icon="delete_forever"
                iconOnly
                onClick={() => console.log("Delete image")}
              />
              <CustomButton
                variant="gray"
                type="file"
                iconOnly
                icon="upload_file"
                onFileChange={(e) => handleNewImage(e.target.files[0])}
                accept="image/png, image/jpeg"
              />
              <CustomButton
                variant="gray"
                icon="close"
                iconOnly
                onClick={toggleFullScreenModal}
              />
            </div>
          </div>
          <div className="body">
            <ReactImageFallback
              src={imageSrc}
              fallbackImage={defaultProductImage}
              alt={name ?? "Imagen del producto"}
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </div>
        </FullScreenModal>
      )}
    </>
  );
};

ImageVisor.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  onToggle: PropTypes.func,
  onChange: PropTypes.func,
};

ImageVisor.defaultProps = {
  value: "",
  name: "Imagen",
  onToggle: null,
};

export default ImageVisor;
