import React, { useEffect, useState, useRef } from "react";
import { Container } from "./styles";
import searchIcon from "../../../../../assets/Icons/search.svg";

const AddGroup = ({
  id,
  key,
  items = [],
  value = [],
  defaultSearch = "",
  defaultOption = "",
  onChange,
  placeholder = "",
}) => {
  const [itemsSelected, setItemsSelected] = useState(value);
  const [search, setSearch] = useState(defaultSearch);
  const [containerVisible, setContainerVisible] = useState(false);
  const containerRef = useRef(null);
  
  const itemsFiltered = (() => {
    if (!search) return items.filter(item => !itemsSelected.includes(item.value));

    return items
      .filter(item => !itemsSelected.includes(item.value))
      .filter((item) =>
        item.label.toLowerCase().includes(search.toLowerCase())
      );
  })();

  useEffect(() => {
    onChange && onChange(itemsSelected);
  }, [itemsSelected]);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setContainerVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <Container id={id} key={key} ref={containerRef}>
      <button
        className="custom-select"
        onClick={() => setContainerVisible(!containerVisible)}
      >
        <span className="material-symbols-outlined add-icon">
          add
        </span>
      </button>
      {containerVisible && (
      <div className="container-def">
        <div className="search-bar">
          <img src={searchIcon} className="search-icon" alt="search icon" />
          <input
            type="text"
            className="search"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search}
            placeholder={placeholder}
          ></input>
        </div>
        <p className="default-option">{defaultOption}</p>
        {itemsFiltered.map((item, i) => {
          return (
            <div className="container-options" key={i}>
              <div className="options">
                <input
                  id="custom-check"
                  className="custom-check"
                  type="checkbox"
                  checked={itemsSelected.includes(item.value)}
                  onChange={(e) => {
                    setItemsSelected((prev) => {
                      const existItem = prev.find((e) => e === item.value);

                      if (existItem) {
                        return [...prev].filter((e) => e !== item.value);
                      }

                      return [...prev, item.value];
                    });
                  }}
                />
                <label htmlFor="" className="texto">
                  {item.label}
                </label>
              </div>
            </div>
          );
        })}
      </div>)}
    </Container>
  );
};

export default AddGroup;