import styled from "styled-components";
import { GlobalColors, FontFamily } from "../../../../global-files/variables";

export const Container = styled.div`
  width: 100%;
  max-height: 100%;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;
  background: white;
  padding: 20px;
  overflow: auto;
  //border: 1px solid red;

  section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;

    // container del nombre de la seccion con su icono info
    .container-headerChats {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      gap: 5px;

      .tooltip-title {
        max-width: 100%;

        label {
          font-family: ${FontFamily.RobotoMedium}, sans-serif;
          font-size: 15px;
          text-align: left;
          color: #262626;
        }
      }
    }

    // container de la lista de los items chats de la seccion
    .container-bodyChats {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
`;

export const ContainerItemChat = styled.div`
  width: 100%;
  min-width: min-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  background: #f7f7fc;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.25s;

  * {
    cursor: inherit;
  }

  // imagen del logo de la compañia
  .img-logoCompany {
    border-radius: 50%;
    overflow: hidden;
    height: 34px;
    width: 34px;

    .icon-logoCompany {
      width: 100%;
      height: 100%;
    }
  }

  // container de las etiquetas "name" y "lastDate"
  .container-info {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    .label-name {
      font-family: ${FontFamily.RobotoMedium}, sans-serif;
      font-size: 15px;
      text-align: left;
      color: #262626;
    }
    .label-lastDate {
      font-family: ${FontFamily.RobotoRegular}, sans-serif;
      font-size: 11px;
      text-align: left;
      color: #b3b3b3;
    }
  }

  .container-indicators {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;

    // label "Interno"
    .label-ownCompany {
      padding: 5px 10px;
      border-radius: 3px;
      font-family: ${FontFamily.RobotoRegular}, sans-serif;
      font-size: 10px;
      background-color: rgba(0, 0, 0, 0.05);
      color: #707070;
    }
  }

  // cuando el item chat tenga el cursor
  &:hover {
    background-color: #d7d7dd;

    .container-info {
      .label-lastDate {
        color: #959292;
      }
    }

    /*
    .label-ownCompany {
      background-color: ;
    }*/
  }
`;

// container con 2 labels "label-title" y "label-message"
export const ContainerTooltip = styled.div`
  min-width: min-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  background: transparent;

  .label-title {
    font-family: ${FontFamily.RobotoMedium}, sans-serif;
    font-size: 12px;
    text-align: left;
    color: #262626;
  }
  .label-message {
    font-family: ${FontFamily.RobotoRegular}, sans-serif;
    font-size: 11px;
    text-align: left;
    color: #262626;
  }
`;
