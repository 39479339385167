import React, { useState, useEffect } from "react";
import { Container } from "./styles";

//Componentes
import Modal from "../../../../general/Modal";
import CustomChip from "../../../../general/customInputs/CustomChip";

import useNotifications from "../../../../../hooks/useNotification";
import { useModal } from "../../../../../hooks/useModal";
//svg
import AddGroup from "../AddGroup";
import { PhaseHeader } from "../PhaseHeader";
import { addGroup, checkAvailablePhase, deleteGroup } from "./utils";
import CustomNotification from "../../../../general/CustomNotification";

export const Phase = ({
  key,
  id,
  phaseId,
  phases,
  phaseName,
  nextPhase,
  groups,
  onDeletePhase,
  token,
  showMenu=true,
  showButtonChip=true,
}) => {
  const [selectedValue, setSelectedValue] = useState([]);
  const [chips, setChips] = useState([]);
  const [groupsSelected, setGroupsSelected] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [nextPhaseState, setNextPhaseState] = useState("");
  const [tempChips, setTempChips] = useState([]);
  const open = Boolean(anchorEl);
  const { modalData, openModal, closeModal, handleCancelClick } = useModal();
  const { notifications, addNotification, deleteNotification } = useNotifications();

  const loadData = async() => {
    const activeGroups = groups.filter((group) => group.groupActive === 1);
    const initialChips = activeGroups.map((group) => ({
      id: group.groupId,
      label: group.groupName,
    }));
    setChips(initialChips);

    const nextPhaseObject = phases.find((phase) => phase.phaseId === nextPhase);
    if (nextPhaseObject) {
      setNextPhaseState(nextPhaseObject.phaseName);
    }
  };

  useEffect(() => {
    loadData();
  }, [groups]);

  // useEffect(() => {
  //   loadData();
  // }, [nextPhase, phases]);
  useEffect(() => {
    const nextPhaseObject = phases.find((phase) => phase.phaseId === nextPhase);
    if (nextPhaseObject) {
      setNextPhaseState(nextPhaseObject.phaseName);
    }
  }, [nextPhase, phases]);
  

  useEffect(() => {
    handleGroupSelect();
  }, [groupsSelected]);

  useEffect(() => {
    handleNextPhaseSelect();
  }, [selectedValue]);

  const handleGroupSelect = async () => {
    for (const groupId of groupsSelected) {
        const matchingGroup = groups.find((group) => group.groupId === groupId);
        if (matchingGroup) {
            if (!chips.find((chip) => chip.id === matchingGroup.groupId)) {
                setChips((prevChips) => [
                    ...prevChips,
                    { id: matchingGroup.groupId, label: matchingGroup.groupName },
                ]);
            }
            await addGroup(phaseId, [groupId], token, addNotification, loadData); 
        }
    }
};

  const handleNextPhaseSelect = () => {
    if (selectedValue.length > 0) {
      const Id = selectedValue[0];
      const matchingPhase = phases.find((phase) => phase.phaseId === Id);
      if (matchingPhase) {
        checkAvailablePhase(phaseId, phases, matchingPhase.phaseId, token, addNotification, openModal, loadData);
      }
    }
  };

  //eliminar chip
  const handleChipDelete = async(phaseId, chipToRemove) => {
    setGroupsSelected((prevGroups) =>
      prevGroups.filter((chip) => chip.id !== chipToRemove.id)
    );
    setChips((prevChips) =>
      prevChips.filter((chip) => chip.id !== chipToRemove.id)
    );
    setTempChips((prevChips) =>
    prevChips.filter((chip) => chip.id !== chipToRemove.id)
  );
    await deleteGroup(phaseId, chipToRemove.id, token, addNotification, loadData, setTempChips);
  };

  return (
    <Container>
      <PhaseHeader
        id={id}
        key={key}
        phaseName={phaseName}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        phases={phases}
        nextPhaseState={nextPhaseState}
        showMenu={showMenu}
        openModal={openModal}
        closeModal={closeModal}
        addNotification={addNotification}
        token={token}
        checkAvailablePhase={checkAvailablePhase}
        onDeletePhase={onDeletePhase}
        loadData={loadData}
      />
      <div className="attributes-container">
        {chips.map((chip) => (
          <CustomChip
            key={chip.id}
            label={chip.label}
            onDelete={() => handleChipDelete(phaseId, chip)}
            showDelete={showButtonChip}
          />
        ))}
        {showButtonChip && (
          <AddGroup
            id={`add-group-select-${phaseId}`}
            key={key}
            items={groups
              .filter((group) => group.groupActive === 0)
              .map((group) => ({
                value: group.groupId,
                label: group.groupName,
              }))}
            defaultOption="Todos los grupos"
            placeholder="Buscar grupo"
            onChange={(selectedItems) => {
              setGroupsSelected(selectedItems);
            }}
          />
        )}
      </div>
      <Modal
        {...modalData}
        onClose={handleCancelClick}
      />
     <CustomNotification
        open={notifications.length > 0}
        alerts={notifications}
        deleteNotification={deleteNotification}
      />
    </Container>
  );
};
