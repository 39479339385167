import styled from "styled-components";

export const PlanMainContainer = styled.div`
  margin-top: 0;
  & + * {
    margin-top: 30px;
  }
`;

export const CurrentPlan = styled.div`
  padding: 25px 20px;
  background: #f7f7fc;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom:20px;

  .plan-info-container {
    h2 {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 17px;
      font-feature-settings: "pnum" on, "lnum" on;
      color: #262626;
    }

    p {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: var(--gray-contentoh);
      & + * {
        margin-top: 5px;
      }
    }
  }
  .used-products,
  .products-limit,
  .products-title {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #8a6caa;
    line-height:11.72px;
  }

  .info-header-container{
    display: flex;
    width: 45%;
    justify-content: space-between;
    align-items: center;
  }

  .used-products-container {
    border: 1px solid #8a6caa;
    border-radius: 5px;
    padding: 0 5px;
    min-height: 20px;
    h2 {
      text-align: center;
    }
  }
  .products-colab-container {
    display:flex;
    gap:10px;
  }
`;

export const CompanyCollaborators = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #8a6caa;
  border-radius: 5px;
  padding: 0 5px;
  min-height: 20px;

  h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #503d66;
  }

  .collaborators-counter {
    display: flex;
    align-items: center;

    p {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      color: #8a6caa;
      line-height:11.72px;

      .current-collaborators {
        color: #8A6CAA;
      }
    }
  }
`;

export const CollaboratorsTable = styled.table`
  thead {
    tr {
      th {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #503d66;
        text-align: left;
        padding: 10px;
      }
    }
  }

  tbody {
    tr {
      td {
        font-weight: normal;
        font-size: 12px;
        line-height: 30px;
        max-height: 30px;
        vertical-align: middle;
        color: #817393;
        padding: 10px;

        div {
          width: 30px;
          height: 30px;
          display: flex;

          img {
            width: 30px;
            height: 30px;
            object-fit: fill;
            border-radius: 50%;
          }
        }
      }
    }
  }
`;
