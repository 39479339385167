import styled from "styled-components";

export const Container = styled.div`
  width: fit-content;

  img {
    object-fit: contain;
  }

  &.carousel-image {
    img {
      width: 85px;
      height: 55px;
      cursor: pointer;
    }

    & + * {
      margin-left: 1px;
    }
  }

  &.main-image {
    img {
      width: 340px;
      height: 295px;
    }
  }

  &.catalogue-image-big-size {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    img {
      width: 179px;
      height: 179px;
    }
  }

  &.catalogue-image-small-size {
    img {
      width: 60px;
      height: 60px;
    }
  }

  &.catalogue-modal-image {
    flex: 1 0 50%;
    cursor: pointer;

    img {
      width: 168px;
      height: 108px;
    }
  }

  &.expanded-modal-image {
    width: 710px;
    height: 710px;
    display: flex; 
    justify-content: center; 
    align-items: center; 

    img {
      width: 710px;
      height: 710px;
    }
  }
`;

