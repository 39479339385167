import { Container } from "./styles";
import { ScreenHeader } from "../../atoms/ScreenHeader";
import { TagAndInput } from "../../molecules/TagAndInput";
import { BoxOnboarding } from "../BoxOnboarding";
import { Box } from "../Box";
import { useEffect, useState } from "react";
import acceptIcon from "../../../assets/images/generalButton/acceptIcon.svg";
import rejectIcon from "../../../assets/images/generalButton/rejectIcon.svg";
import { Tooltip } from "../../atoms/Tooltip";
import { height, width } from "@mui/system";

export const InputGroup = ({
  inputGroup = {},
  auditInputGroup,
  activeSection,
  dataInputs = {},
  auditInputs = {},
  updatedDescriptions,
  setUpdatedDescriptions,
  updatedDatasheets,
  setUpdatedDatasheets,
  articleId,
  version,
  dinamicHeight,
  compare,
  groupData = [],
  setUpdatedBoxData,
  activeRetailer,
  updateApprovedInputs,
}) => {
  const InputReviewSection = ({
    inputId,
    isApproved,
    comment,
    handleImgClick,
    changeImageBtn,
    showDivState,
    imageStates,
  }) => (
    <div style={{ display:'flex', alignItems:'center' }}>
      <Tooltip
        componentTooltip={
          <>
            <p>
              <b>Comentarios:</b>
            </p>
            <p>{comment}</p>
          </>
        }
        children={
          !isApproved && (
            <img
              src={imageStates[inputId] ? acceptIcon : rejectIcon}
              alt={"botón rechazar"}
              style={{ cursor: "pointer" }}
              onClick={() => handleImgClick(inputId)}
            />
          )
        }
        classNameTooltip={"container-tooltip"}
      />
      {showDivState[inputId] && (
        <div className="btnReviewState">
          <div>
            <button
              id="aceptbtn"
              onClick={() => changeImageBtn(inputId, "validate")}
            >
              <img
                src={acceptIcon}
                alt="acept button"
                style={{ cursor: "pointer" }}
              />
              Validar
            </button>
          </div>
          {/* <div>
            <button
              id="rejectbtn"
              onClick={() => changeImageBtn(inputId, "reject")}
            >
              <img
                src={rejectIcon}
                alt="reject button"
                style={{ cursor: "pointer" }}
              />
              Rechazar
            </button>
          </div> */}
        </div>
      )}
    </div>
  );

  const inputTypeValue = (type) => {
    switch (type) {
      case "Booleano":
        return "checkbox";
      case "Numérico":
        return "number";
      default:
        return "text";
    }
  };
  const [showBox, setShowBox] = useState(false);
  const [showDivStates, setShowDivStates] = useState({});
  const [imageStates, setImageStates] = useState({});

  const handleImgClick = (inputId) => {
    setShowDivStates((prevStates) => ({
      ...prevStates,
      [inputId]: !prevStates[inputId],
    }));
  };

  const changeImageBtn = (inputId, action) => {
    setImageStates((prevStates) => ({
      ...prevStates,
      [inputId]: action === "validate",
    }));
    updateApprovedInputs(inputId);
  };

  const isEquals = (dataInputsVal, auditInputsVal) => {
    const result = dataInputsVal === auditInputsVal;
    return result;
  };

  useEffect(() => {
    if (
      inputGroup.groupId === "16" &&
      activeRetailer &&
      activeRetailer?.retailer?.id
    ) {
      if (activeRetailer?.retailer?.id === 68) {
        setShowBox(true);
      } else {
        setShowBox(false);
      }
    }
  }, [activeRetailer, inputGroup.groupId]);

  return (
    <>
      {inputGroup.groupId === "16" && activeRetailer?.retailer?.id === 68 && (
        <Container
          className={
            activeSection === "Ficha técnica"
              ? "datasheets-layout"
              : "descriptions-layout"
          }
        >
          <Box
            inputGroup={inputGroup}
            dataInputs={dataInputs}
            onChange={setUpdatedBoxData}
          />
        </Container>
      )}
      {inputGroup.groupId !== "16" && (
        <Container
          className={
            activeSection === "Ficha técnica"
              ? "datasheets-layout"
              : "descriptions-layout"
          }
        >
          {inputGroup?.dataGroup && (
            <ScreenHeader
              headerType={"retailer-name-header"}
              text={`${inputGroup?.dataGroup}`}
            />
          )}
          <div className="inputs-container">
            {inputGroup?.inputs?.map((input, index) => {
              return(
              activeSection === "Ficha técnica" ? (
                <div className={
                  dataInputs[input]?.id === 6700 || dataInputs[input]?.id === 6701
                    ? "inputs-validation-onboarding"
                    : "inputs-validation"
                }>
                 {dataInputs[input]?.isApproved === 0 && (
                    <InputReviewSection
                      inputId={dataInputs[input]?.id}
                      isApproved={dataInputs[input]?.isApproved}
                      comment={dataInputs[input]?.comment}
                      handleImgClick={handleImgClick}
                      changeImageBtn={changeImageBtn}
                      showDivState={showDivStates}
                      imageStates={imageStates}
                    />
                  )}
                {((dataInputs[input]?.id!=6700 && dataInputs[input]?.id!=6701) 
                  || dataInputs[input]?.isApproved!=undefined) && (
                  <TagAndInput
                  key={
                    index +
                    "-" +
                    dataInputs[input]?.value +
                    "-" +
                    dataInputs[input]?.id +
                    "-" +
                    compare
                  }
                  //disabled={input === 40001}
                  disabled={dataInputs[input]?.isApproved}
                  inputId={dataInputs[input]?.id}
                  version={version}
                  inputType={inputTypeValue(dataInputs[input]?.type)}
                  label={
                    dataInputs[input]?.name +
                    (dataInputs[input]?.required ? "*" : "")
                  }
                  value={
                    compare
                      ? auditInputs[input]?.value
                      : dataInputs[input]?.value
                  }
                  inputPlaceHolder={input?.placeholder}
                  articleId={articleId}
                  isRequired={dataInputs[input]?.required}
                  updatedDatasheets={updatedDatasheets}
                  setUpdatedDatasheets={setUpdatedDatasheets}
                  maxChar={
                    dataInputs[input]?.max_chars
                      ? dataInputs[input]?.max_chars
                      : 999
                  }
                  optionList={dataInputs[input]?.option_list}
                  description={dataInputs[input]?.description}
                  showTooltip={true}
                  auditClass={
                    compare &&
                    !isEquals(
                      dataInputs[input].value,
                      auditInputs[input].value
                    )
                      ? "audit-class"
                      : ""
                  }
                />
                )}
                </div>
              ) : (
                <>
                  {input?.isApproved === 0 && (
                    <InputReviewSection
                      inputId={input?.id}
                      isApproved={input?.isApproved}
                      comment={input?.comment}
                      handleImgClick={handleImgClick}
                      changeImageBtn={changeImageBtn}
                      showDivState={showDivStates}
                      imageStates={imageStates}
                    />
                  )}
                  {/*(input.id===44186||input.id===44187) && (
                    <InputReviewSection
                      inputId={input?.id}
                      isApproved={aprrovedInput}
                      comment={input?.comment}
                      handleImgClick={handleImgClick}
                      changeImageBtn={changeImageBtn}
                      showDivState={showDivStates}
                      imageStates={imageStates}
                    />
                  )*/}
                  <TagAndInput
                    key={index + "-" + input?.value + "-" + compare}
                    inputId={input.id}
                    index={index}
                    inputType={"textarea"}
                    label={input?.name + (input.required ? "*" : "")}
                    value={
                      compare
                        ? auditInputGroup?.inputs[index]?.value
                        : input?.value
                    }
                    isRequired={input.required}
                    disabled={input.id===44186||input.id===44187?false:input?.isApproved}
                    // disabled={true}
                    maxChar={input.max_chars}
                    inputPlaceHolder={input?.placeholder}
                    updatedDescriptions={updatedDescriptions}
                    setUpdatedDescriptions={setUpdatedDescriptions}
                    articleId={articleId}
                    version={version}
                    dinamicHeight={dinamicHeight}
                    description={input?.description}
                    showTooltip={true}
                    auditClass={
                      compare &&
                      !isEquals(
                        input?.value,
                        auditInputGroup?.inputs[index]?.value
                      )
                        ? "audit-class"
                        : ""
                    }
                  />
                </>
              )
            )
          }
            )}
          </div>
        </Container>
      )}
      {inputGroup.groupId === "16" && activeRetailer?.retailer?.id === 74 && (
        <Container
          className={
            activeSection === "Ficha técnica"
              ? "datasheets-layout"
              : "descriptions-layout"
          }
        >
          {inputGroup?.dataGroup && (
            <ScreenHeader
              headerType={"retailer-name-header"}
              text={`${inputGroup?.dataGroup}`}
            />
          )}
          <div className="inputs-container">
            {inputGroup?.inputs?.map((input, index) =>
              activeSection === "Ficha técnica" ? (
                <>
                  <TagAndInput
                    key={
                      index +
                      "-" +
                      dataInputs[input]?.value +
                      "-" +
                      dataInputs[input]?.id +
                      "-" +
                      compare
                    }
                    //disabled={input === 40001}
                    disabled={dataInputs[input]?.isApproved}
                    // disabled={true}
                    inputId={dataInputs[input]?.id}
                    version={version}
                    inputType={inputTypeValue(dataInputs[input]?.type)}
                    label={
                      dataInputs[input]?.name +
                      (dataInputs[input]?.required ? "*" : "")
                    }
                    value={
                      compare
                        ? auditInputs[input]?.value
                        : dataInputs[input]?.value
                    }
                    inputPlaceHolder={input?.placeholder}
                    articleId={articleId}
                    isRequired={dataInputs[input]?.required}
                    updatedDatasheets={updatedDatasheets}
                    setUpdatedDatasheets={setUpdatedDatasheets}
                    maxChar={
                      dataInputs[input]?.max_chars
                        ? dataInputs[input]?.max_chars
                        : 999
                    }
                    optionList={dataInputs[input]?.option_list}
                    description={dataInputs[input]?.description}
                    showTooltip={true}
                    inputGroup={inputGroup}
                    dataInputs={dataInputs}
                    onChange={setUpdatedBoxData}
                    auditClass={
                      compare &&
                      !isEquals(
                        dataInputs[input].value,
                        auditInputs[input].value
                      )
                        ? "audit-class"
                        : ""
                    }
                  />
                  {dataInputs[input]?.isApproved === 0 && (
                    <InputReviewSection
                      inputId={dataInputs[input]?.id}
                      isApproved={dataInputs[input]?.isApproved}
                      comment={dataInputs[input]?.comment}
                      handleImgClick={handleImgClick}
                      changeImageBtn={changeImageBtn}
                      showDivState={showDivStates}
                      imageStates={imageStates}
                    />
                  )}
                </>
              ) : (
                <>
                  {input?.isApproved === 0 && (
                    <InputReviewSection
                      inputId={input?.id}
                      isApproved={input?.isApproved}
                      comment={input?.comment}
                      handleImgClick={handleImgClick}
                      changeImageBtn={changeImageBtn}
                      showDivState={showDivStates}
                      imageStates={imageStates}
                    />
                  )}
                  <TagAndInput
                    key={index + "-" + input?.value + "-" + compare}
                    inputId={input.id}
                    index={index}
                    disabled={input?.isApproved}
                    // disabled={true}
                    inputType={"textarea"}
                    label={input?.name + (input.required ? "*" : "")}
                    value={
                      compare
                        ? auditInputGroup?.inputs[index]?.value
                        : input?.value
                    }
                    isRequired={input.required}
                    maxChar={input.max_chars}
                    inputPlaceHolder={input?.placeholder}
                    updatedDescriptions={updatedDescriptions}
                    setUpdatedDescriptions={setUpdatedDescriptions}
                    articleId={articleId}
                    version={version}
                    dinamicHeight={dinamicHeight}
                    description={input?.description}
                    showTooltip={true}
                    auditClass={
                      compare &&
                      !isEquals(
                        input?.value,
                        auditInputGroup?.inputs[index]?.value
                      )
                        ? "audit-class"
                        : ""
                    }
                  />
                </>
              )
            )}
          </div>
        </Container>
      )}
    </>
  );
};
