import { FontFamily } from "../../../global-files/variables";
import styled from "styled-components";

export const Container = styled.div`
  width: fit-content;
  //margin: 100px 0px 0px 100px;	modo dev
  //border: 1px solid red;	modo dev

  + .MuiTooltip-popper {
    background-color: transparent;

    .MuiTooltip-tooltip {
      --background: white;
      box-shadow: 0px 0px 10px 1px gray;
      background-color: var(--background);
      padding: 10px;
      border-radius: 7px;
      max-width: 60vw;
      font-family: ${FontFamily.RobotoMedium}, sans-serif;
      font-size: 12px;
      text-align: left;
      color: #262626;

      .MuiTooltip-arrow {
        color: var(--background);
      }
    }

    &[data-popper-placement*="bottom"] .MuiTooltip-tooltip {
      margin-top: 10px;
    }
    &[data-popper-placement*="top"] .MuiTooltip-tooltip {
      margin-bottom: 10px;
    }
    &[data-popper-placement*="left"] .MuiTooltip-tooltip {
      margin-right: 10px;
    }
    &[data-popper-placement*="right"] .MuiTooltip-tooltip {
      margin-left: 10px;
    }
  }
`;
