import { Container } from "./styles";
import { CheckBox } from "../../atoms/CheckBox";

export const GalleryHeader = ({ setCheckAll, setSelectedImages, shotThd }) => {
  const checkAll = (e) => {
    const checkboxList = document.querySelectorAll("[id^='gallery-element-']");
    checkboxList.forEach((chk) => (chk.checked = e.target.checked));
    setCheckAll(e.target.checked);
    if (!e.target.checked) setSelectedImages([]);
  };
  return (
    <Container>
      <div>
        <CheckBox id="check-all-images" onChange={(e) => checkAll(e)} />
      </div>
      <p className="image-header">Imagen</p>
      <p className="image-name">Nombre</p>
      <p className="image-size">Tamaño</p>
      <p className="image-format">Formato</p>
      <p className="image-description">¿Tiene leyenda?</p>
      <p className="">Tipo de toma</p>
      <p className="">Tipo de imagen</p>
      <p className="">Tipo de empaque</p>
      <p className="">Estatus</p>
    </Container>
  );
};
