import React, { useEffect, useState } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
// import { Dashboard } from "contentoh-components-library";
import ForgotPassword from "../components/Login/ForgotPassword";
import Login from "../components/Login";
import MyAccount from "../components/MyAccount";
import { changeHeader } from "./changeHeader";
import UpgradePlan from "../components/MyAccount/UpgradePlan";
import AddProducts from "../components/AddProducts";
import RetailerProducts from "../components/RetailerProducts";
import OnboardingProducts from "../components/OnboardingProducts";
import NotFound from "../components/NotFound";
import { EditProduct } from "../components/EditProduct";
import { addToCart } from "../components/_utils/data";
import { RetailerProductEdition } from "../components/EditPrueba";
import MultipleEdition from "../components/MultipleEdition";
import Tasks from "../components/Tasks";

export const DashboardRouters = withRouter((props) => {
  const [user] = useState(JSON.parse(sessionStorage.getItem("user")));
  const [company] = useState(JSON.parse(sessionStorage.getItem("company")));
  // const productToEdit = JSON.parse(sessionStorage.getItem("productEdit"));
  // const productSelected = JSON.parse(sessionStorage.getItem("productSelected"));
  const jwt = sessionStorage.getItem("jwt");
  const setShowNotification = props.setShowNotification;
  const [location, setLocation] = useState(props.location);

  const productToEdit = JSON.parse(sessionStorage.getItem("productEdit"));
  const productSelected = JSON.parse(sessionStorage.getItem("productSelected"));
  const tabsSections = {
    Descripción: true,
    "Ficha técnica": false,
    Imágenes: false,
  };


  useEffect(() => {
     const temp = changeHeader(props.location.pathname);
     props.setDocTitle(temp.title);
     props.setDescription(temp.info);
 }, [props]);

  useEffect(() => {
    setLocation(props.location);
  }, [props.location]);

  return [
    <Switch>
      <Route exact path="/Dashboard"/>
      <Route path="/" exact component={Login} />
      <Route path="/Profile" component={MyAccount} />
      <Route exact path="/upgradeplan" component={UpgradePlan} />
      <Route exact path="/AddProducts">
        <AddProducts setShowNotification={setShowNotification} />
      </Route>
      <Route key={props.location.pathname} exact path="/EditProduct/:productId">
        <EditProduct 
          productSelected={productSelected}
          productToEdit={productToEdit}
          user={user}
          company={company}
          token={jwt}
          tabsSections={tabsSections}
          location={location}
          addToCart={addToCart} />
      </Route>
      {/* <Route key={props.location.pathname} exact path="/EditProduct/:productId">
        <RetailerProductEdition 
          productSelected={productSelected}
          productToEdit={productToEdit}
          user={user}
          company={company}
          token={jwt}
          tabsSections={tabsSections}
          location={location}
          addToCart={addToCart} />
      </Route> */}
      <Route key={props.location.pathname} exact path="/allacquired">
          <RetailerProducts user={user}/>
      </Route>
      <Route key={props.location.pathname} exact path="/tasks">
          <Tasks user={user}/>
      </Route>
      <Route key={props.location.pathname} exact path="/merchants">
          <OnboardingProducts user={user}/>
      </Route>
      <Route
        exact
        path="/multipleEdition"
        component={MultipleEdition}
      />
      {/* <Route key={props.location.pathname} exact path="/allacquired">
      {user.is_retailer || user.is_onboarding===1 ? (
          <RetailerProducts type={"general"} acquired={true}/>
        ) : (
          <GeneralProducts titles="Global" option="Productos" />
        )}
      </Route> */}
       <Route component={NotFound} />
    </Switch>,
  ];
});
