import { Container, ContainerLoading, Img } from "./styles";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faSpinner as iconLoading } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";

export const Image = (props) => {
  const {
    className, // string
    width, // string
    sizeLoading, // number
    colorLoading, // string
    classNameLoading, // string
    src, // imagen a cargar (string)
    componentDefault, // componente a mostrar en caso de que no cargue src (JSX)
    classNameImg, // string
    borderType, // "rectangle" | "circle" -> por default none
  } = props;
  const [imgLoad, setImgLoad] = useState();
  const [loading, setLoading] = useState(false);

  /* solo para hacer pruebas en modo dev
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []); */

  return (
    <Container className={className} width={width} borderType={borderType}>
      {src && (imgLoad === undefined || loading) && (
        <ContainerLoading
          className={classNameLoading}
          sizeLoading={sizeLoading}
          colorLoading={colorLoading}
        >
          <Icon pulse icon={iconLoading} />
        </ContainerLoading>
      )}

      {(!src || (imgLoad === false && !loading)) && componentDefault}

      <Img
        className={classNameImg}
        src={src}
        onLoad={(event) => {
          setImgLoad(true);
        }}
        onError={(event) => {
          setImgLoad(false);
        }}
        show={imgLoad === true && !loading}
      />
    </Container>
  );
};
