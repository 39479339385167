import styled from "styled-components";
import { FontFamily, GlobalColors } from "../../../global-files/variables";

export const Container = styled.div`
  font-family: ${FontFamily.Roboto}, sans-serif;
  font-weight: 400;
  font-size: var(--size);
  line-height: 1.2;
  background: white;
  position: relative;
  overflow: hidden;
  width: 80%;
  height: 100%;
  text-align: center;
  #contenedor-caja {
    display: flex;
    justify-content: space-between;
    color: #262626;
  }
  #atr-alto {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  p {
    font-family: ${FontFamily.RobotoMedium}, sans-serif;
    font-size: 12px;
    line-height: 1.2;
    font-weight: 600;
    align-self: center;
  }
  }
  .icon-red {
    color: #B64545;
    height:18px;
  }
  .icon-gray {
    color: #707070;
  }
  .icon-gray-l {
    color: #707070;
    height:18px;
  }
`
