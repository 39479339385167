import styled from "styled-components";

export const Alert = styled.div`
  width: 100%;
  height: 50px;
  background: #d43594;
  border-radius: 7px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 15px;
  box-sizing: border-box;
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #f7e3ef;
  }
  figure {
    width: 15px;
    height: 20px;
    margin-right: 10px;
    img {
      width: 100%;
    }
  }
  #close {
    position: absolute;
    top: 16px;
    right: 5px;
  }

  & + * {
    margin-top: 30px;
  }
`;