import { Base64 } from "js-base64";
import teamContentoh from "../../../assets/imagesFake/AvatarContentoh.svg";
import EmptyCircleGray from "../../../assets/defaultImages/EmptyCircleGray.svg";
import defaultUpdate from "../../../assets/defaultImages/defaultUpdate.png";

export const getProfilePicture = (userId, fileType = 'png', width = 24, height = 24) => {
    if (userId === -1) return teamContentoh;
    if (!userId) return EmptyCircleGray;
    try {
      return (
        "https://d24s337exbt5zr.cloudfront.net/" +
        Base64.encode(
          JSON.stringify({
            bucket: process.env.REACT_APP_IMAGES_PROFILE_BUCKET,
            key: `id-${userId}/${userId}.${fileType}`,
            edits: {
              resize: {
                width: width,
                height: height,
                fit: "contain",
                background: {
                  r: 255,
                  g: 255,
                  b: 255,
                  alpha: 0,
                },
              },
            },
          })
        )
      );
    } catch (err) {
      console.log("err", err);
      return { src: defaultUpdate };
    }
  };


  export const getFileTypeFromUrl = (url) => {
    const match = url.match(/\.([a-zA-Z0-9]+)\?/);
    return match ? match[1] : 'png';
  };