import { SelectCustom, Container, BackgroundEnd } from "./style";

export default function Select(props) {
  return (
    <Container width={props.width}>
      <SelectCustom
        id={props.id}
        width={props.width}
        onChange={props.onChange}
        value={props.valueSelected ? props.valueSelected : ""}
        className={props.className}
      >
        <option value="" disabled>
          {props.placeholder}
        </option>
        {props.options &&
          props.options.map((item, i) => (
            <option
              className={item.required ? "option-required" : ""}
              //className={ ? "option-required" : ""}
              key={"select-" + item.value ? item.value : item + "-" + i}
              value={item.value ? item.value : item}
              // selected={
              //   item.value
              //     ? item.value === props.valueSelected && "selected"
              //     : item === props.valueSelected && "selected"
              // }
            >
              {item.name ? item.name : item} {item?.required ? "*" : ""}
            </option>
          ))}
      </SelectCustom>
    </Container>
  );
}
