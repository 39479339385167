import styled from "styled-components";
import { GlobalColors, FontFamily } from "../../../global-files/variables";

export const Container = styled.div`
  display: grid;
  place-items: center;
  width: ${({ width }) => (width ? width : "min-content")};
  min-width: min-content;

  .container-modal {
    .container-background {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
`;

export const ContainerModalImg = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: transparent;

  .container-header {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    padding: 8px 10px;

    .container-btnDownload {
      width: 28px;
      height: 28px;
      overflow: hidden;
      display: grid;
      place-items: center;
    }
  }

  .container-imgModal {
    width: 100%;
    height: 100%;

    .img {
      width: 100%;
      max-height: 100vh;
      object-fit: contain;
    }
  }
`;

export const ContainerLoading = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  min-width: min-content;
  font-size: ${({ sizeLoading }) => (sizeLoading ? sizeLoading : "30")}px;
  color: ${({ colorLoading }) => (colorLoading ? colorLoading : "#9e2674")};
  padding: 5px;
`;

export const Img = styled.img`
  display: ${({ show }) => (show ? "grid" : "none")};
  width: 100%;
  cursor: pointer;
  object-fit: contain;
`;
