import styled from "styled-components";
import { FontFamily, GlobalColors } from "../../../global-files/variables";

export const Container = styled.div`
  display: flex;
  padding: 10px;
  padding-left: 0;
  align-items: center;

  p {
    font-family: ${FontFamily.AvenirNext};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.015em;
    color: ${GlobalColors.s5};
    flex: 1;
    text-align: center;

    &.value-header {
      max-width: 60px;
    }

    &.image-header {
      max-width: 60px;
    }

    &.image-name {
      min-width: 20%;
    }

    &.image-format {
      max-width: 60px;
    }

    &.image-size {
      max-width: 90px;
    }

    &.image-identifier {
      max-width: 80px;
    }

    &.image-description {
      max-width: 120px;
    }
  }
`;
