import { Base64 } from "js-base64";
import moment from "moment";

/*====================================================================
            UTILERIAS PARA EL MANEJO DE ARCHIVOS
======================================================================*/
/*------------------------
devolver el base64 
completo de un file
--------------------------*/
export const getBase64 = async (file) => {
  return new Promise((success) => {
    const reader = new FileReader();
    reader.addEventListener("loadend", () => {
      success(reader.result);
    });
    reader.readAsDataURL(file);
  });
};

/*------------------------
devolver la data de un string base64
--------------------------*/
export const getDataBase64 = (base64Full) => {
  //const base64 = reader.result.replace(/^data:\w+\/\w+.*;base64,/, "");
  try {
    const base64 = base64Full.split("base64,");
    return base64.length ? base64[1] : undefined;
  } catch (err) {
    return undefined;
  }
};

/*------------------------
devolver un data Buffer a partir
de un data base64
--------------------------*/
export const getDataBuffer = (dataBase64) => {
  try {
    const dataBuffer = Buffer.from(dataBase64, "base64");
    return dataBuffer;
  } catch (err) {
    return undefined;
  }
};

/*------------------------
devolver la extension de un file
a partir de un string: "name.ext"
--------------------------*/
export const getFileExtension = (fileName = "") => {
  const nameParts = fileName.split(".");
  let ext = "";
  nameParts.forEach((part, index) => {
    if (index !== 0) {
      if (index + 1 === nameParts.length) ext += part;
      else ext += part + ".";
    }
  });
  return ext === "" ? undefined : ext;
};

/*------------------------
devolver el nombre de un file
a partir de un string: "name.ext"
--------------------------*/
export const getFileName = (fileName = "") => {
  const nameParts = fileName.split(".");
  if (nameParts.length) return nameParts[0];
  return undefined;
};

/*------------------------
obtener la URL de una imagen
con un peso mas pequeño al 
peso original pasando como
parametros:
=> bucket: <string> bucket en donde se ecuentra la img 
=> key: <string> ruta y nombre completa de la img (keyS3)
=> width: <number> largo de la img en px
=> height: <number> altura de la img en px
--------------------------*/
export const getImageURL = (bucket, key, width, height) => {
  try {
    const configAWS = {
      bucket: bucket,
      key: key,
      edits: {
        resize: {
          width: width,
          height: height,
          fit: "contain",
          background: { r: 255, g: 255, b: 255, alpha: 0 },
        },
      },
    };
    let url = "https://d24s337exbt5zr.cloudfront.net/";
    url += Base64.encode(JSON.stringify(configAWS));
    return url;
  } catch (err) {
    console.log(`ERROR al obtener la URL de la imagen (${key})`, err.message);
    return undefined;
  }
};

/*------------------------
obtener la dimension de una imagen 
a partir de un objeto <File>
retorno: {
=> width: <number> largo de la img en px
=> height: <number> altura de la img en px
} 
NOTA regresa <undefined> si no se pudo 
obtener la dimension de la imagen
--------------------------*/
export const getImageSize = (file) => {
  return new Promise(async (response) => {
    const img = new Image();
    img.onload = () => response({ width: img.width, height: img.height });
    img.onerror = () => response(undefined);
    const base64 = await getBase64(file);
    img.src = base64;
  });
};

/*------------------------
obtener una redimension (height) proporcional 
a la dimension original (height) a partir de
un width deseado
retorno:
=> newHeight: <number> altura de la img en px
NOTA regresa <undefined> si no se pudo 
obtener el nuevo <height> de la imagen
--------------------------*/
export const getResizeImgHeight = (originalWidth, originalHeight, newWidth) => {
  const scale = (newWidth * 100) / originalWidth / 100;
  return Math.ceil(originalHeight * scale);
};

/*====================================================================
            UTILERIAS PARA EL MANEJO DE FECHAS
======================================================================*/
/*------------------------
nombres de los dias de la semana
--------------------------*/
const weekdayName = {
  1: { short: "Dom", full: "Domingo" },
  2: { short: "Lun", full: "Lunes" },
  3: { short: "Mar", full: "Martes" },
  4: { short: "Mié", full: "Miércoles" },
  5: { short: "Jue", full: "Jueves" },
  6: { short: "Vie", full: "Viernes" },
  7: { short: "Sab", full: "Sabado" },
};
/*------------------------
nombres de los meses
--------------------------*/
const monthName = {
  1: { short: "Ene", full: "Enero" },
  2: { short: "Feb", full: "Febrero" },
  3: { short: "Mar", full: "Marzo" },
  4: { short: "Abr", full: "Abril" },
  5: { short: "May", full: "Mayo" },
  6: { short: "Jun", full: "Junio" },
  7: { short: "Jul", full: "Julio" },
  8: { short: "Ago", full: "Agosto" },
  9: { short: "Sep", full: "Septiembre" },
  10: { short: "Oct", full: "Octubre" },
  11: { short: "Nov", full: "Noviembre" },
  12: { short: "Dic", full: "Diciembre" },
};

/*------------------------
Obtener una fecha a partir de 
un objeto Date() en formato: 
'DDD dd MMM aaaa'
ejemplo: 'Ene 12 Jul 2022'
--------------------------*/
export const getFullDate = (date = new Date()) => {
  if (isNaN(date)) return undefined;
  let dateString = `${weekdayName[date.getDay() + 1].short} `;
  dateString += date.getDate() + " ";
  dateString += `${monthName[date.getMonth() + 1].short} `;
  dateString += date.getFullYear();
  return dateString;
};

/*------------------------
Obtener una fecha a partir de 
un objeto Date() en formato: 
'aaaa-MM-dd'
ejemplo: '2022-12-25'
--------------------------*/
export const getFormatedDate = (date = new Date(), separator = "/") => {
  if (isNaN(date)) return undefined;
  let dateString = "";
  dateString += date.getFullYear() + separator;
  dateString += date.getMonth() + 1 + separator;
  dateString += date.getDate();
  return dateString;
};

/*------------------------
Obtener una fecha a partir de 
un objeto Date() en formato: 
'dd de MMMM del aaaa'
ejemplo: '12 de octubre del 2022'
=> si es la fecha del dia actual:
devolvera 'Hoy'
=> si es la fecha del dia de ayer:
devolvera 'Ayer'
=> si es fecha del mismo año:
devolvera '12 de octubre'
--------------------------*/
export const getCustomDate = (date = new Date()) => {
  if (isNaN(date)) return "";
  let dateString = date.getDate() + " de ";
  dateString += `${monthName[date.getMonth() + 1].full}`;
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  // fecha de Hoy
  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    return "Hoy";
  }
  // fecha de Ayer
  if (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  ) {
    return "Ayer";
  }
  // fechas del mismo año
  if (date.getFullYear() === today.getFullYear()) return dateString;
  // fechas de diferente año
  return dateString + " del " + date.getFullYear();
};

/*------------------------
Obtener una hora a partir de 
una fecha string (current_timestamp)
en formato: 'hh:mm PM/AM'
ejemplo: '12:34 PM'
--------------------------*/
export const getTime = (time = "") => {
  if ([undefined, null, ""].includes(time))
    return moment(new Date()).format("LT");
  return moment(time).format("LT");
};

/*====================================================================
            UTILERIAS PARA EL MANEJO DE VALIDACIONES
======================================================================*/
/*------------------------
recibe un number o string
y determina si es numero natural
0,1,2,...
--------------------------*/
export const isValidNaturalNumber = (number) => {
  if ([undefined, null, ""].includes(number)) return false;
  if (!["string", "number"].includes(typeof number)) return false;
  try {
    const numberString = number + "";
    for (let i = 0; i < numberString.length; i++) {
      if ("0123456789".indexOf(numberString.charAt(i)) === -1) return false;
    }
    return true;
  } catch (err) {
    return false;
  }
};

/*------------------------
recibe un array y determina si 
el array esta vacio []
--------------------------*/
export const isArrayEmpty = (array) => {
  if (Array.isArray(array) && array.length) return false;
  return true;
};

/*------------------------
recibe un string y determina si 
esta vacio ""
--------------------------*/
export const isStringEmpty = (cad) => {
  if (cad && typeof cad === "string" && cad.trim().length !== 0) return false;
  return true;
};

/*------------------------
recibe un string JSON y lo
decodifica a su valor original
--------------------------*/
export const decodeJSON = (valueJSON) => {
  try {
    const decodeValue = JSON.parse(valueJSON);
    return decodeValue;
  } catch (err) {
    return valueJSON;
  }
};

/*------------------------
recibe un dato y determina si 
es objeto JS
--------------------------*/
export const isObject = (obj) => {
  if (obj && typeof obj === "object" && !Array.isArray(obj)) return true;
  return false;
};

/*------------------------
recibe un value y lo codifica 
primero a JSON y luego a URI
--------------------------*/
export const encodeUriJson = (value) => {
  try {
    const encodeValue = encodeURIComponent(JSON.stringify(value));
    return encodeValue;
  } catch (err) {
    return undefined;
  }
};
