import styled from "styled-components";
import checked from "../../../../../assets/checkBox/checkverde.svg";

export const Container = styled.div`
  position: relative;

  .custom-select {
    font-family: Roboto,sans-serif;
    font-size: 12px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 30px;
    height: 30px;
    padding: 2px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    
    justify-content: space-between;
    border-radius: 50px;
    background-color: transparent;
    border: 1px solid var(--pink-contentoh);
    color: var(--pink-contentoh);
    cursor: pointer;
    &:hover{
      background-color: var(--pink-contentoh);
      border: 1px solid var(--pink-contentoh);
      color: var(--white-contentoh);
    }
  }

  .add-icon {
    width: 13px;
    height: 13px;
  }

  .container-def {
    margin-top: 5px;
    box-shadow: 0px 2px 4px #00000040;
    border-radius: 5px;
    background-color: var(--white-contentoh);
    padding-bottom: 10px;
    position: absolute;
    top: 25px;
    left: 30px;
    z-index: 10;
    max-height: 500px;
    overflow: auto;
    width: 200px;
  }

  .search-bar {
    display: flex;
    align-items: center;
    padding: 0px 5px;
    border-bottom: 1px solid #f0f0f0;
  }

  .search {
    border: none;
    height: 34px;
    padding: 5px 10px;
    color: var(--gray-contentoh);
  }

  .search::placeholder {
    color: var(--gray-contentoh);
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
  }

  .container-options {
    padding: 5px;
  }

  .default-option {
    cursor: pointer;
    padding: 10px 10px 5px;
    color: #817393;
    font-family: Roboto;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
  }

  .options {
    height: 24px;
    gap: 10px;
    display: flex;
    align-items: center;
  }

  .custom-check {
    background-color: #D9D9D9;
    width: 16px;
    height: 16px;
    appearance: none;
    border: 1px solid #cbcbcb;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }

  .custom-check:checked {
    background-color: var(--white-contentoh);
    background-image: url(${checked});
  }

  .texto {
    color: #817393;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
  }
`;
