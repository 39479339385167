import styled from "styled-components";
import { GlobalColors } from "../../../global-files/variables";

export const Container = styled.div`
  border: 1px solid ${GlobalColors.s2};
  border-radius: 6px;
  padding: 10px;
  width: 100%;

  .retailer-name-header {
    & + * {
      margin-top: 10px;
    }
  }
  &.datasheets-layout {
    padding-right: 0;

    .inputs-container {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: 5px;

      .input-container {
        flex: 1 1 20%;
        min-width: 227px;
        display: flex;
        flex-direction: column;
      }
    }

    .inputs-validation-onboarding{
      display: flex;
      align-items: center;
      gap:5px;
      padding: 0px 5px;
      //border: 1px solid #e33aa9;
      // border-left: 1px solid #e33aa9;
      // border-bottom: 1px solid #e33aa9;
      border-radius: 6px;
      position:relative;
    }

    .inputs-validation{
      position:relative;
    }
  }

  & + * {
    margin-top: 15px;
  }

  .btnReviewState {
    display: flex;
    flex-direction: column;
    border: 1px solid lightgray;
    border-radius: 3px;
    z-index: 1000;
    position: absolute;
    transform: translate(35%, 0);
  }

  .btnReviewState button {
    display: flex;
    align-items: center;
    border: none;
    background: white;
    border-radius: 3px;
    box-shadow: 2px 2px 2px lightgray;
  }
  .btnReviewState button:hover {
    background: lightgray;
  }
  /* #divBtnsReviewdown {
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
  }
  #aceptbtn {
    background: white;
    border: none;
  }
  #rejectbtn {
    background: white;
    border: none;
  } */
`;
