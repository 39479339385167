import styled from "styled-components";
import { GlobalColors, FontFamily } from "../../../global-files/variables";

export const Container = styled.div`
  display: grid;
  place-items: center;
  width: ${({ width }) => (width ? width : "min-content")};
  min-width: min-content;
  overflow: hidden;
  border-radius: ${({ borderType }) => {
    return borderType?.toLowerCase() === "rectangle"
      ? "6px"
      : borderType?.toLowerCase() === "circle"
      ? "50%"
      : "0px"; // default none
  }};
`;

export const ContainerLoading = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  min-width: min-content;
  font-size: ${({ sizeLoading }) => (sizeLoading ? sizeLoading : "30")}px;
  color: ${({ colorLoading }) => (colorLoading ? colorLoading : "#9e2674")};
  padding: 5px;
`;

export const Img = styled.img`
  display: ${({ show }) => (show ? "grid" : "none")};
  width: 100%;
  object-fit: contain;
  border-radius: inherit;
`;
