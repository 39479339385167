import styled from "styled-components";

export const Container = styled.div`
    padding:20px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
    color: var(--gray-contentoh);
`;
