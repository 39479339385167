import styled from "styled-components";

export const MainContainer = styled.div`
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y:auto;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
`;