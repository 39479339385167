import React, { useState, useEffect } from "react";
import ReactImageFallback from "react-image-fallback";
import { Header, RoleBadge } from "./styles";
import defaultProfile from "../../../assets/defaultImages/defaultProfile.png";
import spinner from "../../../assets/defaultImages/Spinner.gif";
import { getRoleUser } from "../../main/ContainerDashboard/utils";
import { Base64 } from "js-base64";
import useNotifications from "../../../hooks/useNotification";
import CustomNotification from "../../general/CustomNotification";
import useScreenSizes from "../../../hooks/useScreenSizes";

const ProfileHeader = ({ toggleMenuText, showMenuText }) => {
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem("user")));
  const {
    notifications,
    notificationOpen,
    addNotification,
    deleteNotification,
    setNotificationOpen,
  } = useNotifications();
  const [imageUrl, setImageUrl] = useState(defaultProfile);
  const { isMobile } = useScreenSizes();

  useEffect(() => {
    if (user && user.id_user) {
      const fetchProfileImage = async () => {
        try {
          const url = getProfilePicture(user.id_user, 'png', 60, 60, addNotification);
          setImageUrl(url);
        } catch (err) {
          console.error("Error fetching profile image:", err);
          addNotification("error", "Error al obtener la foto de perfil");
          setImageUrl(defaultProfile);
        }
      };

      fetchProfileImage();
    }
  }, [user.id_user, user.src]);

  const getProfilePicture = (
    userId,
    fileType = "png",
    width = 30,
    height = 30,
    addNotification
  ) => {
    try {
      const imageUrl =
        "https://d24s337exbt5zr.cloudfront.net/" +
        Base64.encode(
          JSON.stringify({
            bucket: process.env.REACT_APP_IMAGES_PROFILE_BUCKET,
            key: `id-${userId}/${userId}.${fileType}`,
            edits: {
              resize: {
                width: width,
                height: height,
                fit: "cover",
                background: {
                  r: 255,
                  g: 255,
                  b: 255,
                  alpha: 0,
                },
              },
            },
          })
        );
      return imageUrl;
    } catch (err) {
      console.log("Error al obtener foto de perfil:", err);
      addNotification("error", "Error al obtener foto de perfil");
      return defaultProfile;
    }
  };

  return (
    <Header showMenuText={showMenuText}>
      {showMenuText || isMobile ? (
        <div className="profile-avatar">
          <ReactImageFallback
            src={imageUrl}
            fallbackImage="fallback-image-url"
            initialImage={spinner}
            alt="avatar"
            className="avatar"
          />
          <div className="container-info">
            <p id="title">{`${user.name} ${user.last_name}`}</p>
            <p className="info-user">{user.position}</p>
            <p className="info-user">{getRoleUser(user.id_role)}</p>
          </div>
        </div>
      ) : (
        <ReactImageFallback
          src={imageUrl}
          fallbackImage="fallback-image-url"
          initialImage={spinner}
          alt="avatar"
          className="avatar"
        />
      )}

      <div>
        <button className="button-menu" onClick={toggleMenuText}>
          <span className="material-symbols-outlined menu">menu_open</span>
        </button>
      </div>
      <CustomNotification
        open={notifications.length > 0}
        alerts={notifications}
        deleteNotification={deleteNotification}
      />
    </Header>
  );
};

export default ProfileHeader;
