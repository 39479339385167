import styled from "styled-components";

export const ProductDetailContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 40%;
  height: calc(-77px + 100vh);
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;

  &.open {
    transform: translateX(0);
  }
`;

export const SidebarContent = styled.div`
  padding: 20px;
  .product-detail-container {
    display: flex;
    width: 100%;
    padding: 0px;
    justify-content: space-between;
    h2 {
      font-weight: bold;
      font-size: clamp(1.5rem, 2vw, 2.5rem);
      line-height: 1.2;
      text-align: left;
      letter-spacing: -0.015em;
      color: var(--pink-hard-contentoh);
    }
  }
  .container-buttons {
    display: flex;
    gap: 5px;
  }
  .container-product {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .container-image-percentage {
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }
  .container-images {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .box-details {
    background: rgb(240, 238, 242);
    font-style: normal;
    font-weight: 500;
    font-size: clamp(0.875rem, 1vw, 1.125rem);
    padding: 5px 5px 5px 10px;
    line-height: 1.3;
    border-radius: 7px;
    letter-spacing: -0.015em;
    text-align: left;
    position: relative;
  }
  .text-attribute {
    font-weight: normal;
    font-size: clamp(0.85rem, 1vw, 1.1rem);
    line-height: 1.3;
    letter-spacing: -0.015em;
    color: var(--purple-light-contentoh);
  }
  .version-container {
    background: rgb(240, 238, 242);
    min-height: 20px;
    font-size: 11px;
    display: flex;
    padding: 4px 9px;
    justify-content: space-around;
    border-radius: 15px;
    color: rgb(129, 115, 147);
  }
  .percentage-container{
    height: 23px;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: -0.015em;
    color: var(--purple-light-contentoh);
    border: 1px solid var(--purple-light-contentoh);
    box-sizing: border-box;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
  }
`;

export const RowAttribute = styled.div`
  display: flex;
  gap: 35px;
  align-items: center;
`;

export const TitleAttribute = styled.div`
  font-weight: 700;
  font-size: clamp(0.875rem, 1vw, 1.125rem);
  line-height: 1.5;
  letter-spacing: -0.015em;
  color: rgb(129, 115, 147);
`;
