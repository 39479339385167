import axios from "axios";

export const fetchData = async (
  groups = [],
  attributes = [],
  setAttributesFilter,
  setGroupsFilter,
  addNotification
) => {
  const groupsId = groups.map((group) => parseInt(group, 10));
  const attributesId = attributes.map((attribute) => parseInt(attribute, 10));
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_GROUP_BY_ATTRIBUTE}/get`,
      { groupsIds: groupsId, attributesIds: attributesId },
      { headers: { Authorization: sessionStorage.getItem("jwt") } }
    );
    const data = JSON.parse(response.data.body);
    const attributesByGroups = data.attributesByGroups;
    const groupsByRetailer = data.groupsByRetailer;
    const attributesFilterSet = new Set();
    const groupsFilterSet = new Set();

    if (attributesByGroups && typeof attributesByGroups === "object") {
      Object.entries(attributesByGroups).forEach(
        ([attributeName, { attributeId, groups }]) => {
          attributesFilterSet.add(
            JSON.stringify({
              value: attributeId,
              label: attributeName || null,
              groups: groups || [],
            })
          );
        }
      );
    }

    if (groupsByRetailer && typeof groupsByRetailer === "object") {
      Object.entries(groupsByRetailer).forEach(([groupId, groupName]) => {
        groupsFilterSet.add(
          JSON.stringify({
            value: parseInt(groupId, 10),
            label: groupName || null,
          })
        );
      });
    }
    console.log("attributesData", attributesByGroups);
    console.log("groupsData", groupsByRetailer);
    setAttributesFilter(attributesFilterSet);
    setGroupsFilter(groupsFilterSet);
    return JSON.parse(response.data.body);
    
  } catch (error) {
    console.error("Error al recuperar atributos", error);
    addNotification("error", "Error al recuperar atributos");
    throw error;
  }
};


export const updateAttributes = async (updateAttributes, addNotification) => {
  try {
    const body = { attributesByGroups: updateAttributes };
    console.log("body", body)
    const response = await axios.post(
      `${process.env.REACT_APP_GROUP_BY_ATTRIBUTE}/update`,
      body,
      { headers: { Authorization: sessionStorage.getItem("jwt") } }
    );
    if (response.status === 200) {
      addNotification("success", "Cambios aplicados correctamente");
    } else {
      addNotification("error", `Error al aplicar los cambios: ${response.status}`);
    }
  } catch (error) {
    console.error("Error al actualizar atributos", error);
    addNotification("error", "Error al actualizar atributos");
  }
};


export const filterAttributes = (attributesGroups, addNotification) => {
  try {
    return attributesGroups;
  } catch (error) {
    console.error("Error al filtrar atributos:", error);
    addNotification('error', 'Error al filtrar atributos')
  }
};

export const allAttributes = async (
  setLoading,
  setAttributesData,
  addNotification
) => {
  setLoading(true);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_GROUP_BY_ATTRIBUTE}/get`,
      {
        groupsIds: [],
        attributesIds: [],
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );
    const data = JSON.parse(response.data.body);
    const attributesByGroups = data.attributesByGroups;
    const groupsByRetailer = data.groupsByRetailer;
    setAttributesData(attributesByGroups);
    //   console.log( "attributesData", attributesByGroups)
    //   console.log("groupsData", groupsByRetailer)
    //   localStorage.setItem(
    //     "attributesData",
    //     JSON.stringify(attributesByGroups)
    //   );
    //   localStorage.setItem("groupsData", JSON.stringify(groupsByRetailer));
  } catch (error) {
    console.error("Error al recuperar la data de grupo por atributo", error);
    addNotification("error", "Error al recuperar la data de grupo por atributo");
  } finally {
    setLoading(false);
  }
};

export const handlerSelectAll = (evt, openModal, closeModal, handleCancelClick) => {
  openModal({
    className: "modal-masive-change",
    title: 'Confirmar acción',
    text: 'Estas a punto de hacer una acción masiva para seleccionar grupos.',
    icon: "info",
    customComponent: <p className="texto">¿Estas seguro?</p>,
    showAccept: true,
    showCancel: true,
    onAcceptClick: () => {
      closeModal();
    },
    onCancelClick: handleCancelClick,
  });
};

export const handleDeselectAll = (
  setRowsChecked,
  setSelectedRows,
  setColumnsChecked,
  setAttributesByGroups,
  selectedRows,
  allGroups
) => {
  setRowsChecked([]);
  setSelectedRows([]);
  setColumnsChecked([]);
  setAttributesByGroups((prevAttributesByGroups) => {
    const updatedAttributesByGroups = { ...prevAttributesByGroups };
    selectedRows.forEach((attributeId) => {
      updatedAttributesByGroups[attributeId] = {
        active: [],
        disable: Object.keys(allGroups).map((groupId) => parseInt(groupId, 10)),
      };
    });
    return updatedAttributesByGroups;
  });
};

export const initializeSelectedColumns = (
  attributesGroups,
  setColumnsChecked
) => {
  const initialCheckedColumns = Object.entries(attributesGroups).reduce(
    (result, [, { attributeId, groups }]) => {
      const columnsToCheck = Object.values(groups || []).map(
        ({ groupId }) => {
          return `${attributeId}-${groupId}`;
        }
      );
      return result.concat(columnsToCheck);
    },
    []
  );

  setColumnsChecked(initialCheckedColumns);
};

export const searchAttributes = (
  searchText = "",
  attributesGroups,
  setAttributesGroups,
  originalAttributesGroups,
  addNotification
) => {
  try {
    const tempArray = Object.entries(attributesGroups).slice();
    const filteredAttributes =
      searchText.length > 0
        ? tempArray.filter(([attributeName]) =>
            attributeName.toLowerCase().includes(searchText.toLowerCase())
          )
        : Object.entries(originalAttributesGroups);

    const filteredAttributesObject = filteredAttributes.reduce(
      (result, [attributeName, { attributeId, groups }]) => {
        result[attributeName] = { attributeId, groups };
        return result;
      },
      {}
    );

    setAttributesGroups(filteredAttributesObject);
  } catch (error) {
    console.error("Error al buscar atributos:", error);
    addNotification("error", "Error al buscar atributos");
  }
};
  
export const handleSaveChanges = (attributesByGroups, modifiedAttributes, addNotification) => {
  const filteredAttributes = Object.keys(modifiedAttributes).reduce((result, key) => {
    if (attributesByGroups[key] && 
        (attributesByGroups[key].active.length > 0 || attributesByGroups[key].disable.length > 0)) {
      result[key] = modifiedAttributes[key];
    }
    return result;
  }, {});
  updateAttributes(filteredAttributes, addNotification);
};

