import React, { useState } from "react";
import SignIn from "./SignIn";
import RegistrationCompany from "./RegistrationCompany";
import ValidationCode from "./ValidationCode";
import { GraphicPanel } from "./styles";
import RegistrationUser from "./RegistrationUser";
import { signUp, authenticateUser, getUserDetails } from "./utils/AuthService";
import ForgotPassword from "./ForgotPassword";

const UserPanel = () => {
  const [step, setStep] = useState("signIn");
  const [email, setEmail] = useState("");
  const [confirmationError, setConfirmationError] = useState("");
  const [companyData, setCompanyData] = useState({});
  const [userData, setUserData] = useState({});

  const handleSignUp = async (userData, companyData) => {
    const userType = "retailer";
    const isOnboarding = 1;
    const retailerId = 74;
    const retailerUserId = 349;
    try {
      const response = await signUp({ ...companyData, ...userData }, userType, isOnboarding, retailerId, retailerUserId);
      
      if (response.success) {
        setEmail(userData.email);
        setStep("verificationCode");
      } else {
        setConfirmationError(response.error);
      }
    } catch (error) {
      console.error("Error al registrarse:", error);
      setConfirmationError("error");
      setEmail(userData.email);
      sessionStorage.setItem("email", JSON.stringify(userData.email));
      setStep("verificationCode");
    }
  };
  


  const renderStep = () => {
    switch (step) {
      case "signIn":
        return <SignIn onRegister={() => setStep("companyRegistration")} onForgotPassword={() => setStep("forgotPassword")} />;
      case "companyRegistration":
        return <RegistrationCompany onNext={(data) => { setCompanyData(data); setStep("userRegistration"); }} />;
      case "userRegistration":
        return <RegistrationUser onNext={(data) => { setUserData(data); handleSignUp(data, companyData, "provider"); }} />;
      case "verificationCode":
        return <ValidationCode email={email} confirmationError={confirmationError} />;
      case "forgotPassword":
        return <ForgotPassword onNext={setStep}/>;
      default:
        return <SignIn onRegister={() => setStep("companyRegistration")} onForgotPassword={() => setStep("forgotPassword")} />;
    }
  };

  return <GraphicPanel>{renderStep()}</GraphicPanel>;
};

export default UserPanel;
