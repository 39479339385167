import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;

  * + * {
    margin-left: 10px;
  }
`;
