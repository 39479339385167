import styled from "styled-components";
import { GlobalColors, FontFamily } from "../../../global-files/variables";

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 20px;
  box-sizing: border-box;
  border-radius: 2px;
  margin-left: auto;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  & > p {
    font-family: ${FontFamily.AvenirNext};
    font-weight: 400;
    font-size: 11px;
    line-height: 19px;
    color: ${GlobalColors.s5};
    background-color: ${GlobalColors.s2};
    padding: 1px 3px;
    border-radius: 3px;
    min-width: 50px;
    height: 21px;
    text-align: center;
  }
  border: ${(props) => props.error && "1px solid red"};
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #d4d1d7;
    border-radius: 3px;
  }
`;
