import axios from "axios";

export const sendInvitation = async ({
  user,
  inviteProviders,
  providersToInvite,
  usersToInvite,
  email,
  categoriesSelected,
  position,
  onSubmit,
  loadData,
  inviteMoreUsers
}) => {
  if (user.is_retailer && inviteProviders) {
    const body = {
      emailToInvite: encodeURIComponent(JSON.stringify(providersToInvite)),
    };
    await axios.post(`${process.env.REACT_APP_INVITE_USER}`, body, {
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });
    loadData();
  } else if (inviteMoreUsers) {
    const body = {
      emailToInvite: `[${usersToInvite.map((user) => `{"email":"${user.email}"}`).join(",")}]`,
    };
    await onSubmit(body);
  } else {
    await onSubmit(email, categoriesSelected, position);
  }
};

export const emailOnChange = (e, setEmail) => {
  setEmail(e.target.value);
};

export const changePosition = (e, setPosition) => {
  setPosition(e.target.value);
};

export const invitationOnChange = (e, field, index, isProvider, inviteArray, setInviteArray) => {
  const newInviteArray = [...inviteArray];
  newInviteArray[index][field] = e.target.value;
  setInviteArray(newInviteArray);
};

export const addNotification = (type, message, setNotifications, setNotificationOpen) => {
  setNotifications((prev) => [...prev, { type, message }]);
  setNotificationOpen(true);
};
