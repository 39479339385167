import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableHeader, TableCell, TableRow } from './styles';

const ImageDetails = ({ imagesDetails }) => {
  return (
    <Table>
      <thead>
        <tr>
          <TableHeader>Nombre</TableHeader>
          <TableHeader>Tamaño</TableHeader>
        </tr>
      </thead>
      <tbody>
        {imagesDetails
          .filter((image) => image.required === 1)
          .map((image, index) => (
            <TableRow key={image.id}>
              <TableCell>{image.name}</TableCell>
              <TableCell>{image.horizontal_px} x {image.vertical_px}</TableCell>
            </TableRow>
          ))}
      </tbody>
    </Table>
  );
};

ImageDetails.propTypes = {
  imagesDetails: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      vertical_px: PropTypes.number.isRequired,
      horizontal_px: PropTypes.number.isRequired,
      required: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default ImageDetails;
