import styled from "styled-components";
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction:row;
  width: 95%;
  padding: 14px 0;
  div {
    display: flex;
    align-items: center;
  }
  figure {
    & + p {
      margin-left: 13px;
    }
  }
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #503d66;
  }
  .edit :hover {
    cursor: pointer;
  }
`;