import React, { useEffect, useState } from "react";
import { Container } from "./styles";
import Loading from "../loading";
import Modal from "../Modal";
import CustomNotification from "../CustomNotification/index.jsx";
import CustomButton from "../CustomButton/index.jsx";
import CheckBox from "../customInputs/CheckBox/index.jsx";
import { createList } from "./utils";
import useNotifications from "../../../hooks/useNotification.jsx";
import { useModal } from "../../../hooks/useModal.jsx";
import searchIcon from "../../../assets/IconComponents/search.svg";

const WishList = ({ openList, wishlistFilter, setWishlistFilter }) => {
  const { modalData, openModal, closeModal, handleCancelClick } = useModal();
  const { notifications, addNotification, deleteNotification } = useNotifications();
  const [filters, setFilters] = useState({ name: "" });
  const [lists, setLists] = useState([]);
  const [selectedLists, setSelectedLists] = useState([]);
  const [loading, setLoading] = useState(true);



  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilters((prev) => ({ ...prev, name: value }));
  };

  const handleListSelection = (listId) => {
    setSelectedLists((prevSelected) =>
      prevSelected.includes(listId)
        ? prevSelected.filter((id) => id !== listId)
        : [...prevSelected, listId]
    );
  };

  const handleCreateList = async () => {
    try {
      const newListName = prompt("Ingresa el nombre de la lista:");
      if (newListName) {
        await createList(newListName);
        addNotification("Lista creada correctamente", "success");
        setLists((prev) => [
          ...prev,
          { id: lists.length + 1, name: newListName },
        ]);
      }
    } catch (error) {
      addNotification("Error creating list", "error");
    }
  };

  const filteredLists = wishlistFilter.filter((list) =>
    list.name.toLowerCase().includes(filters.name.toLowerCase())
  );

  return (
    <Container>
      <div className="search-bar">
        <img src={searchIcon} className="search-icon" alt="search icon" />
        <input
          type="text"
          className="search"
          onChange={handleFilterChange}
          value={filters.name}
          placeholder="Buscar wishlist"
        />
      </div>
      <div className="list-container">
        {filteredLists.map((list) => (
          <div key={list.id} className="list-item">
            <CheckBox
              id={list.id}
              checked={selectedLists.includes(list.id)}
              onChange={() => handleListSelection(list.id)}
              label={list.name}
            />
          </div>
        ))}
      </div>
      <div className="container-wish">
        <CustomButton
          className="create-wishlist"
          onClick={handleCreateList}
          label="Crear WishList"
        />
      </div>
      <Modal {...modalData} onClose={handleCancelClick} />
      <CustomNotification
        open={notifications.length > 0}
        alerts={notifications}
        deleteNotification={deleteNotification}
      />
    </Container>
  );
};

export default WishList;
