import { Container } from "./styles";
import ReactImageFallback from "react-image-fallback";
import defaultProductImage from "../../../assets/images/defaultImages/defaultProductImage.png";

export const ProductImage = ({ img, imageType, altText, onClick}) => {
  // Expresión regular para validar que los últimos tres caracteres sean ".mp4"
  const regex = /\.mp4$/;
  if (regex.test(img)){
    return (
      <Container className={imageType}>
        <video controls width="100%" height="auto">
        <source src= {img} type="video/mp4" />
        </video>
      </Container>
    );
  }
  else{
    return (
      <Container className={imageType}>
         <ReactImageFallback
          src={img}
          fallbackImage={defaultProductImage}
          alt={altText}
          onClick={onClick}
        />
      </Container>
    );
  }
};
