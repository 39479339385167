import styled from "styled-components";
import { GlobalColors } from "../../../global-files/variables";

export const Container = styled.div`
    display:flex;
    flex-direction:row;
    gap:8px;
  .button-circle{
    width:30px;
    height:30px;
    padding:2px;
    border-radius:100%;
    min-width:30px;
    border: 1px solid #F0F0F0;
  }
  .button-circle:hover {
    border: 1px solid #F0F0F0;
  }

`;
export const ContainerIcon = styled.div`
cursor: pointer;
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 100 700;
  src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v68/kJEhBvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oFsLjBuVY.woff2)
    format("woff2");
}
.iconos-out {
  font-family: "Material Symbols Outlined";
  font-size: 20px;
  //line-height: 1;
  margin-top: 10px;
}
.iconos-out {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
  color:  #B64545;
}
.iconos-grises {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
  color:  ${GlobalColors.gray};
}`;
