import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import Loading from "../../general/loading";
import ErrorLabel from "../../general/ErrorLabel";
import {
  ContainerForgot,
  FormularioForgot,
  Row,
  ItemRegister,
  RowButton,
  Button,
} from "../styles";
import Logo from "../../general/Logo";
import PasswordValidation from "../PasswordValidation";
import CustomNotification from "../../general/CustomNotification";
import useNotifications from "../../../hooks/useNotification";

const ForgotPassword = ( { onNext }) => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [stage, setStage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [emptyEmail, setEmptyEmail] = useState("");
  const [emptyCode, setEmptyCode] = useState("");
  const [emptyNewPassword, setEmptyNewPassword] = useState("");
  const [showErrors, setShowErrors] = useState(false);
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const { notifications, addNotification, deleteNotification } = useNotifications();

  const handleSendCode = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      await Auth.forgotPassword(email);
      setStage(2);
      addNotification("success", "Código enviado con éxito. Revisa tu correo.");
    } catch (error) {
      setError(error.message);
      addNotification("error", "Error al enviar el código: " + error.message);
    }
    setLoading(false);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword);
      setLoading(false);
      addNotification("success", "Contraseña restablecida con éxito.");
      if (onNext) {
        onNext("signIn");
      } else {
        history.push("/");
      }
    } catch (error) {
      setError(error.message);
      addNotification("error", "Error al restablecer la contraseña: " + error.message);
    }
    setLoading(false);
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setNewPassword(value);
    validatePassword(value);
    validateForm();
  };

  const validatePassword = (password) => {
    setEmptyNewPassword(password.length === 0);
    setIsLengthValid(password.length >= 8);
    setHasUpperCase(/[A-Z]/.test(password));
    setHasLowerCase(/[a-z]/.test(password));
    setHasNumber(/\d/.test(password));
    setHasSpecialChar(/[!@#$%^&*(),.?":{}|<>]/.test(password));
    validateForm();
  };

  useEffect(() => {
    validateForm();
  }, [newPassword, isLengthValid, hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar]);

  const validateForm = () => {
    const isPasswordValid =
      isLengthValid &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber &&
      hasSpecialChar;

    setIsFormValid(isPasswordValid);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  return loading ? (
    <Loading />
  ) : (
    <ContainerForgot>
      {stage === 1 ? (
        <FormularioForgot onSubmit={handleSendCode}>
          <Logo />
          <div className="container-formulario">
            <p className="title">Recuperar Contraseña</p>
            <Row>
              <label>Correo Electrónico</label>
              <ItemRegister>
                <input
                  id="emailInput"
                  placeholder="Escribe tu correo electrónico"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmptyEmail(e.target.value.trim() === "");
                  }}
                  required
                />
              </ItemRegister>
            </Row>
            <ErrorLabel
              showErrors={showErrors}
              condition={emptyEmail}
              message="Ingrese su correo electrónico"
            />
            <RowButton>
              <Button type="submit" onClick={handleSendCode}>Enviar Código</Button>
            </RowButton>
          </div>
        </FormularioForgot>
      ) : (
        <FormularioForgot onSubmit={handleResetPassword}>
          <div className="container-formulario">
            <Row>
              <label>Código</label>
              <ItemRegister>
                <input
                  id="codeInput"
                  placeholder="Escribe el código enviado a tu correo"
                  type="text"
                  value={code}
                  onChange={(e) => {
                    setCode(e.target.value);
                    setEmptyCode(e.target.value.trim() === "");
                  }}
                  required
                />
              </ItemRegister>
            </Row>
            <ErrorLabel
              showErrors={showErrors}
              condition={emptyCode}
              message="Ingrese el código que se envió a su correo"
            />
            <Row>
              <label>Nueva Contraseña</label>
              <ItemRegister>
                <input
                  id="passwordInput"
                  placeholder="Escribe la nueva contraseña"
                  type={showNewPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={handlePasswordChange}
                  required
                />
                <span
                  onClick={toggleConfirmPasswordVisibility}
                  className="material-icons"
                  style={{
                    cursor: "pointer",
                    color: "#CBCBCB",
                    fontSize: "18px",
                  }}
                >
                  {showNewPassword ? "visibility_off" : "visibility"}
                </span>
              </ItemRegister>
            </Row>
            <ErrorLabel
              showErrors={showErrors}
              condition={emptyNewPassword}
              message="Ingrese su nueva contraseña"
            />
            <PasswordValidation
              isLengthValid={isLengthValid}
              hasUpperCase={hasUpperCase}
              hasLowerCase={hasLowerCase}
              hasNumber={hasNumber}
              hasSpecialChar={hasSpecialChar}
            />
            <RowButton>
              <Button type="submit" disabled={!isFormValid} onClick={handleResetPassword}>Restablecer Contraseña</Button>
            </RowButton>
          </div>
          {error && <ErrorLabel message={error} />}
        </FormularioForgot>
      )}
      <CustomNotification
        open={notifications.length > 0}
        alerts={notifications}
        deleteNotification={deleteNotification}
      />
    </ContainerForgot>
  );
};

export default ForgotPassword;
