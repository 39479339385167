import styled from "styled-components";

export const MainContainer = styled.div`
display: flex;
flex-direction: column;
gap: 5px;
.input-name-header {
    font-size: 14px;
    line-height: 19px;
    max-width: 100%;
    font-weight: 400;
    color: var(--text-purple);
    overflow: hidden;
    text-overflow: ellipsis;
}
.select-attribute > div {
    min-width: 150px;
}
.select-attribute span{
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.description-limit {
    font-size: 12px;
    color: var(--black);
    text-align: right;
}
.description{
    font-size: 12px;
    color: var(--gray-contentoh);
}
`;