import { Container } from "./styles";
import { useEffect, useState } from "react";
import { CustomIcon } from "../../../atoms/CustomIcon";
import { ButtonV2 } from "../../../atoms/ButtonV2";
import { Image } from "../../../atoms/Image";
import {
  faComments as IconChat,
  faClose as IconX,
  faImage as IconImage,
} from "@fortawesome/free-solid-svg-icons";

export const Header = (props) => {
  const {
    showBtnClose, // boolean
    showChatsList, // boolean
    chatCompany /* {id , name , src} */,
    multipleCompanies, // boolean
    onClickBtnChooseChat, // () => {}
    onClickBtnClose, // () => {}
  } = props;

  return (
    <>
      <Container>
        {showChatsList ? (
          <>
            <CustomIcon
              className="icon-chooseChat"
              type={"pink"}
              borderType={"circle"}
              size={10}
              icon={IconChat}
            />
            <label className="title"> Todos los chats </label>
          </>
        ) : (
          chatCompany &&
          chatCompany.id &&
          chatCompany.name && (
            <>
              {/* logo de la compañia */}
              <Image
                className="logoCompany"
                width={"24px"}
                sizeLoading={20}
                colorLoading={undefined}
                src={chatCompany.src}
                componentDefault={
                  <CustomIcon
                    className="icon-logoCompany"
                    type={"white"}
                    borderType={"circle"}
                    size={12}
                    icon={IconImage}
                  />
                }
              />

              {/* nombre de la compañia */}
              <label className="title"> {chatCompany.name} </label>

              {/* boton de chat */}
              {multipleCompanies && (
                <ButtonV2
                  className="btn-chooseChat"
                  type={"pink"}
                  size={10}
                  borderType={"circle"}
                  icon={IconChat}
                  onClick={(event) =>
                    onClickBtnChooseChat && onClickBtnChooseChat()
                  }
                />
              )}
            </>
          )
        )}

        {/* boton X */}
        {showBtnClose && (
          <ButtonV2
            className={"btn-X"}
            type={"white"}
            size={11}
            borderType={"circle"}
            icon={IconX}
            onClick={(event) => onClickBtnClose && onClickBtnClose()}
          />
        )}
      </Container>
    </>
  );
};
