import React from 'react';
import { Alert } from "./styles";
import infoWhite from "../../../assets/IconComponents/infoWhite.svg";
import closeWhite from "../../../assets/IconComponents/closeWhite.svg";

const AlertMessage = ({ alert }) => (
  alert && (
    <Alert style={{ display: "none" }}>
      <figure>
        <img src={infoWhite} alt="Alert" />
      </figure>
      <p>Verificación de correo electrónico</p>
      <figure id="close">
        <img src={closeWhite} alt="Close" />
      </figure>
    </Alert>
  )
);

export default AlertMessage;
