import styled from "styled-components";

export const MainContainer = styled.div`
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y:auto;
    overflow-x: hidden;
    width: 100%;
    height: 100%;

    .modal-visibility {
        .custom-component {
          margin-top: 10px;
        }
        .btn-cambiar {
          border: 1px solid var(--pink-contentoh);
          background: var(--pink-contentoh);
          color: var(--white-contentoh);
          border-radius: 30px;
          padding: 4px 8px;
          cursor: pointer;
          &:hover {
            border: 1px solid var(--pink-contentoh);
            background: var(--white-contentoh);
            color: var(--pink-contentoh);
          }
        }
        .btn-cancelar {
          border: 1px solid var(--pink-contentoh);
          background: var(--white-contentoh);
          color: var(--pink-contentoh);
          border-radius: 30px;
          cursor: pointer;
          padding: 4px 8px;
          &:hover {
            border: 1px solid var(--pink-contentoh);
            background: var(--pink-contentoh);
            color: var(--white-contentoh);
          }
        }
      }
`;