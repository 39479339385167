import React, { useState, useEffect } from "react";
import { Container } from "./styles";
import searchIcon from "../../../assets/IconComponents/search.svg";

const SelectInput = ({
  id,
  items = [],
  value = "",
  name = "",
  defaultSearch = "",
  defaultOption = "",
  onChange,
  placeholder = "",
}) => {
  const [selectedItem, setSelectedItem] = useState(value);
  const [search, setSearch] = useState(defaultSearch);
  const [containerVisible, setContainerVisible] = useState(false);

  const itemsFiltered = (() => {
    if (!search) return items;
    return items.filter((item) =>
      item.label.toLowerCase().includes(search.toLowerCase())
    );
  })();

  useEffect(() => {
    onChange && onChange(selectedItem);
  }, [selectedItem, onChange]);

  const handleOptionClick = (value) => {
    setSelectedItem(value);
    setContainerVisible(false);
  };

  const selectedItemLabel = items.find((item) => item.value === selectedItem)?.label || name;

  return (
    <Container id={id}>
      <button
        className="custom-select"
        onClick={() => setContainerVisible(!containerVisible)}
      >
        {selectedItemLabel}
        <div className="arrow-item">&#9664;</div>
      </button>
      {containerVisible && (
        <div className="container-def">
          <div className="search-bar">
            <img src={searchIcon} className="search-icon" alt="search icon" />
            <input
              type="text"
              className="search"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              placeholder={placeholder}
            />
          </div>
         {defaultOption && <p className="default-option">{defaultOption}</p>}
          <div className="container-options">
            {itemsFiltered.map((item, i) => (
              <div
                key={i}
                className={`options ${selectedItem === item.value ? 'selected' : ''}`}
                onClick={() => handleOptionClick(item.value)}
              >
                <label htmlFor={`custom-check-${i}`} className="texto">
                  {item.label}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </Container>
  );
};

export default SelectInput;
