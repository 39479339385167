import styled from "styled-components";
import { FontFamily, GlobalColors } from "../../../global-files/variables";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  #atr-alto {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .caja-borde {
    font-family: ${FontFamily.RobotoMedium}, sans-serif;
    font-size: 12px;
    line-height: 1.2;
    border-radius: ${({ borderType }) => {
      return borderType?.toLowerCase() === "rectangle"
        ? "5px"
        : borderType?.toLowerCase() === "circle"
        ? "50%"
        : "none";
    }};
  }
  span {
    font-family: ${FontFamily.RobotoMedium}, sans-serif;
    font-size: 12px;
    line-height: 1.2;
    font-weight: 600;
  }
  .icon {
    width: 8px;
    height: 8px;
  }
  .required-text {
    color: red;
  }
`;
