import React, { useState, useEffect } from "react";
import { Select, MenuItem, Button, Menu } from "@mui/material";
import { Container } from "./styles";
import options from "../../../../../assets/Icons/options.svg";

export const PhaseHeader = ({
  id,
  key,
  phaseName,
  selectedValue,
  setSelectedValue,
  phases,
  nextPhaseState,
  showMenu,
  openModal,
  closeModal,
  addNotification,
  token,
  checkAvailablePhase,
  onDeletePhase
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditToggle = () => {
    setIsEditing((prev) => !prev);
    handleClose();
  };

  const handleNextPhaseSelect = (selectedPhaseName) => {
    const selectedPhase = phases.find(
      (phase) => phase.phaseName === selectedPhaseName
    );
    if (selectedPhase) {
      checkAvailablePhase(
        id,
        selectedPhase.phaseId,
        phases,
        openModal,
        closeModal,
        addNotification,
        token,
      );
    }
  };
  

  useEffect(() => {
    if (selectedValue) {
      handleNextPhaseSelect(selectedValue);
    }
  }, [selectedValue]);

  return (
    <Container>
      <div key={key} className="header-phase">
        <h2>
          Fase {id} - {phaseName}
        </h2>
        <div className="phase-sel">
          <p className="text-phase">Siguiente Fase</p>
          <Select
            value={
                  selectedValue.length > 0
                    ? selectedValue[0]
                    : nextPhaseState
                    ? nextPhaseState
                    : ""
                }
            onChange={(e) => {
                  const selectedPhase = phases.find(
                    (phase) => phase.phaseName === e.target.value
                  );
                  setSelectedValue(selectedPhase ? [selectedPhase.phaseId] : []);
                }}
            placeholder="Fase"
            disabled={!isEditing}
            sx={{
              minWidth: "100px",
              height: "24px",
              background: "#F7F7FC",
              borderRadius: "5px",
              fontSize: "12px",
            }}
            MenuProps={{
                  PaperProps: {
                    style: {
                      background: "#F7F7FC",
                    },
                  },
                }}
          >
            {phases.map((phase) => (
              <MenuItem
                key={phase.phaseId}
                value={phase.phaseName}
                sx={{ fontSize: "12px", color: "#262626", minHeight: "auto" }}
              >
                {phase.phaseName}
              </MenuItem>
            ))}
          </Select>
          {showMenu && (
            <>
              <Button
                id="basic-button"
                aria-controls={Boolean(anchorEl) ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                onClick={handleClick}
                sx={{ minWidth: "20px" }}
              >
                <img src={options} alt="" />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{
                  ul: {
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  },
                }}
              >
                <MenuItem
                  onClick={handleEditToggle}
                  sx={{
                    fontSize: "12px",
                    color: "#262626",
                    minHeight: "auto",
                    fontFamily: "Roboto",
                  }}
                >
                  {isEditing ? "Cancelar Edición" : "Editar"}
                </MenuItem>
                <MenuItem
                  onClick={() => onDeletePhase(id)}
                  sx={{
                    fontSize: "12px",
                    color: "#262626",
                    minHeight: "auto",
                  }}
                >
                  Eliminar Fase
                </MenuItem>
              </Menu>
            </>
          )}
        </div>
      </div>
    </Container>
  );
};
