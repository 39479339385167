import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  overflow: auto;

  .data-container {
    display: flex;
    flex: 0%;
    height: calc(100% - ${({ headerTop }) => headerTop}px);
    .image-data-panel {
      width: 340px;

      & + * {
        margin-left: 10px;
      }
    }

    .product-information {
      width: calc(100% - 341px);
      display: flex;
      flex-direction: column;

      .services-information-container {
        height: 100%;
        overflow: auto;
      }

      .image-services {
        aside {
          display: grid;
          grid-template-columns: repeat(auto-fill, 179px);
          column-gap: 15px;
          row-gap: 15px;
          padding: 20px;
        }
      }

      .commentary-box {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 10px;
        padding-left: 0;

        .commentary {
          display: flex;
          align-items: flex-end;

          .input-container {
            width: 500px;

            .quill {
              height: 100px;
            }

            & + * {
              margin-left: 5px;
            }
          }

          .buttons-box {
            display: flex;
            width: 210px;
            flex-wrap: wrap;

            .general-transparent-button {
              & + * {
                margin-top: 5px;
              }
            }

            .general-transparent-button,
            .general-green-button,
            .general-button-disabled {
              width: fit-content;
              min-width: 201px;
              height: 40px;
            }
          }
        }
      }

      .feedback-box {
        display: flex;
      }
    }
  }
  #modal-message-box {
    width: 400px;
    height: 100px;
  }
  .container {
    width: 100%;
    height: 100%;
    .dropzone {
      height: 100%;
      width: 100%;
    }
  }
`;
