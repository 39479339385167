import styled from "styled-components";
import { GlobalColors, FontFamily } from "../../../global-files/variables";

export const Container = styled.div`
  width: fit-content;
  min-width: min-content;
  //border: 1px solid red;

  .inputText {
    width: 100%;
    height: 100%;

    // container interno del input o textarea
    > div {
      min-width: 0px;
      font-family: ${FontFamily.RobotoRegular}, sans-serif;
      font-size: ${({ size }) => (size ? size : "13")}px;
      text-align: left;
      color: #a2a2a2;
      padding: ${({ borderType }) => {
        return ["rectangle", "oval"].includes(borderType?.toLowerCase())
          ? "5px 10px"
          : "0px";
      }};
      border-radius: ${({ borderType }) => {
        return borderType?.toLowerCase() === "oval"
          ? "17px"
          : borderType?.toLowerCase() === "rectangle"
          ? "6px"
          : "0px";
      }};

      // input 1 linea
      > input {
        padding: 0px;
      }

      // borde
      > fieldset {
        border: ${({ borderType }) => {
          return ["rectangle", "oval"].includes(borderType?.toLowerCase())
            ? "1px solid black"
            : "none";
        }};
        border-color: inherit;
      }

      &.Mui-focused {
        > fieldset {
          border-color: inherit;
        }
      }
      &.Mui-disabled {
        cursor: not-allowed;
        * {
          cursor: inherit;
        }
      }
    }

    &.fill {
      &.gray > div {
        background-color: #e8e5e5;
        color: #262626;
        border-color: #7b7979;
      }
      &.white > div {
        background-color: white;
        color: #e33aa9;
        border-color: #e33aa9;
      }
    }

    &.transparent {
      > div {
        background-color: transparent;
      }

      &.gray > div {
        color: #a2a2a2;
        border-color: #7b7979;
      }
      &.black > div {
        color: #262626;
        border-color: #7b7979;
      }
    }
  }
`;
