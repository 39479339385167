import axios from "axios";

export const reducerForArticles = (state, action) => {
  const temp = state.slice();
  switch (action.type) {
    case "depot":
      temp[action.index].depot = action.depot;
      temp[action.index].category = action.category;
      temp[action.index].type = action.type;
      temp[action.index].lists.category = action.lists.category;
      temp[action.index].lists.type = action.lists.type;
      return temp;
    case "category":
      temp[action.index].category = action.category;
      temp[action.index].type = action.type;
      temp[action.index].lists.type = action.lists.type;
      return temp;
    case "type":
      temp[action.index].type = action.type;
      return temp;
    case "delete":
      const [removed] = temp.splice(action.index, 1);
      const indexes = temp.reduce((acc, e, i) => {
        if (e.upc === removed.upc) acc.push(i);
        return acc;
      }, []);
      indexes.forEach((e, i) => {
        if (indexes.length - 1 !== i) delete temp[e].error;
      });
      return temp;
    case "newArticle":
      return [...state, action.payload];
    case "addByExcel":
      const newArticles = action.values.map((article, i) => {
        if (
          [...temp, ...action.values].find((f) => f.upc === article.upc) ||
          action.isDuplicate[i]
        ) article.error = true;
        return article;
      });
      return [...state, ...newArticles];
    case "sku":
    case "upc":
      const indx = temp.findIndex(
        (f) => f.upc === action.value && (action.isRetailer ? f.provider === action.provider : true)
      );
      temp[action.index].error = ![-1, action.index].includes(indx);
      temp[action.index][action.action] = action.value;
      return temp;
    case "name":
    case "provider":
      temp[action.index][action.action] = action.value;
      return temp;
    default:
      return temp;
  }
};


export const getUserData = async (query) => {
  const response = await getUser({ query });
  return JSON.parse(response.data.body).data.filter((f) => f.trade_name);
};


export const fetchCategories = async (query) => {
  const config = { headers: { Authorization: sessionStorage.getItem("jwt") } };
  const response = await axios.get(`${process.env.REACT_APP_CATEGORY_ENDPOINT}${query}`, config);
  return JSON.parse(response.data.body).data;
};

export const getUser = async (params = {}) => {
    const func = async () => {
      const query = params.query ? `?${params.query}` : "";
      return axios.get(`${process.env.REACT_APP_USER_ENDPOINT}${query}`, {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      });
    };
  
    return await func();
  };


  export const processCategories = (categories) => {
    const processedCategories = [];
    const processedTypes = [];
    
    for (const deptName in categories) {
      if (categories.hasOwnProperty(deptName)) {
        const categoryEntries = categories[deptName];
        for (const catName in categoryEntries) {
          if (categoryEntries.hasOwnProperty(catName)) {
            const category = categoryEntries[catName];
            processedCategories.push({
              id_category: category.id_category,
              name: catName,
            });
            processedTypes.push({
              id_category: category.id_category,
              name: catName,
            });
          }
        }
      }
    }
  
    return { processedCategories, processedTypes };
  };
  

  export const transformDepotData = (depotData) => {
    let options = [];
    let index = 0; 
  
    const recursiveTransform = (obj, depotLabel = '', categoryLabel = '') => {
      Object.keys(obj).forEach((key) => {
        if (obj[key].sub_category) {
          if (!depotLabel) {
            recursiveTransform(obj[key].sub_category, key);
          } else if (!categoryLabel) {
            recursiveTransform(obj[key].sub_category, depotLabel, key);
          } else {
            recursiveTransform(obj[key].sub_category, depotLabel, categoryLabel);
          }
        } else if (obj[key].id_category) {
          options.push({
            depot: depotLabel,
            category: categoryLabel,
            type: key,
            id_category: obj[key].id_category,
            index: index++
          });
        }
      });
    };
  
    recursiveTransform(depotData);
    return options;
  };
  
