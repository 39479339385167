import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
export const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  span {
    display: inline-block;
    border-radius: 100%;
    margin: 35px 5px;
    opacity: 1;
  }
  .first {
    background-color: #ff75cf;
  }
  .second {
    background-color: #e33aa9;
  }
  .third {
    background-color: #b12d84;
  }
  span:nth-child(1) {
    width: 16px;
    height: 16px;
    animation: opacitychange 1s ease-in-out infinite;
  }
  span:nth-child(2) {
    width: 18px;
    height: 18px;

    animation: opacitychange 1s ease-in-out 0.33s infinite;
  }
  span:nth-child(3) {
    width: 20px;
    height: 20px;

    animation: opacitychange 1s ease-in-out 0.66s infinite;
  }
  @keyframes opacitychange {
    0%,
    100% {
      opacity: 0;
    }
    60% {
      opacity: 1;
    }
  }
`;
