import React, { useState } from "react";
import MuiChip from "@mui/material/Chip";
import DeleteIcon from "@mui/icons-material/DeleteForeverOutlined";

const CustomChip = ({ key, label, onDelete, showDelete }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <MuiChip
      key={key}
      label={label}
      onDelete={showDelete ? onDelete : undefined}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      deleteIcon={showDelete ? <DeleteIcon data-testid="DeleteIcon" sx={{color:"#B64545!important",fontSize:"14px!important",cursor:"pointer"}}/> : null }
      style={{
        height:"30px",
        borderRadius: "5px",
        background: "transparent",
        border: `1px solid ${showDelete && isHovered ? "#B64545" : "#F0F0F0"}`,
        color: `${showDelete && isHovered ? "#B64545" : "#707070"}`,
      }}
    />
  );
};

export default CustomChip;
