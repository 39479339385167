import axios from "axios";

export const getActiveProviders = async (setListUsers, setListUsersFilter, setContract, token, addNotification) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_READ_ACTIVE_PROVIDERS}`,
            {
              headers: {
                Authorization: token,
              },
            }
        );
        
        const providers = JSON.parse(response.data.body).providers;
        console.log("response ",JSON.parse(response.data.body))
        console.log("providers ",providers)
        setListUsers(providers);
        setListUsersFilter(providers);
    } catch (error) {
        console.error("Error al obtener proveedores", error);
        addNotification("Error al obtener proveedores", "error");
    }
};


export const updateContracts = async (companyId, contract, token, addNotification) => {
    try {
        const response = await axios.put(
            `${process.env.REACT_APP_READ_UPDATE_PROVIDERS}`, 
            { 
                companyId,
                contract
            },
            {
              headers: {
                Authorization: token,
              },
            }
        );
        addNotification("success", "Se actualizó el contrato con éxito");
    } catch (error) {
        console.error("Error fetching providers", error);
        addNotification("error", "Error al actualizar el contrato");
    }
};



export const deleteCollaborator = async (usersSelected, setShow, getUsers, addNotification, setListUsers, setListUsersFilter, setLoading, token) => {
    try {
      const responseArray = [];
      usersSelected.forEach((userId) => {
        responseArray.push(
          axios({
            method: "delete",
            url: process.env.REACT_APP_USER_ENDPOINT,
            data: { idUser: userId },
            headers: {
              Authorization: sessionStorage.getItem("jwt"),
            },
          })
        );
      });
      await Promise.all(responseArray);
      getUsers(setListUsers, setListUsersFilter, token, addNotification);
      addNotification("success","Se eliminó correctamente")
      setShow(true);
    } catch (err) {
      console.log(err);
      addNotification("error","Ocurrió un error al eliminar")
    }
  };


export const chkOnChange = (e, id, setUsersSelected) => {
    setUsersSelected((prevSelected) => {
      if (e.target.checked) {
        return prevSelected.includes(id) ? prevSelected : [...prevSelected, id];
      } else {
        return prevSelected.filter((item) => item !== id);
      }
    });
  };
