import React, { useState, useEffect } from "react";
import { MainContainer, Section, BasicData } from "./styles";
import {
  getActiveProviders,
  chkOnChange,
  updateContracts,
} from "./utils";

// components
import GenericModal from "../../../general/GenericModal";
import InviteUser from "../../InviteUser";
import Loading from "../../../general/loading";
import CustomNotification from "../../../general/CustomNotification";
import SelectWithLabel from "../../../general/customInputs/SelectWithLabel";
import useNotifications from "../../../../hooks/useNotification";
import HeaderActive from "./HeaderActive";

// SVG assets
import { CheckboxList } from "../../../general/customInputs/CheckboxList";
import CheckBox from "../../../general/customInputs/CheckBox";

const ActiveProviders = ({ token, tabActive, isRetailer }) => {
  const [listUsers, setListUsers] = useState([]);
  const [listUsersFilter, setListUsersFilter] = useState([]);
  const [show, setShow] = useState(false);
  const [usersSelected, setUsersSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState({});
  const [showInvite, setShowInvite] = useState(false);
  const [providerName, setProviderName] = useState("");
  const [contract, setContract] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const { notifications, addNotification, deleteNotification } = useNotifications();
  const [contractValues, setContractValues] = useState({}); 
  const [editingRowId, setEditingRowId] = useState(null); 

  const contractFilter = [
    { id: 1, label: "All in one" },
    { id: 2, label: "Hazlo tu mismo" },
    { id: 3, label: "Creación asistida" },
  ];

  const contractList = [
    { id: 1, name: "All in one" },
    { id: 2, name: "Hazlo tu mismo" },
    { id: 3, name: "Creación asistida" },
  ];
  

  useEffect(() => {
    getActiveProviders(
      setListUsers,
      setListUsersFilter,
      setContract,
      token,
      addNotification
    );
  }, [token, addNotification]);

  const handleContractChange = (id, selectedContract) => {
    setContractValues((prev) => ({
      ...prev,
      [id]: selectedContract,
    }));
    updateContracts(id, selectedContract.name, token, addNotification);
  };
  

  const handleEdit = (id) => {
    setShowEdit(true);
    setEditingRowId(id);
  };

  const handleCloseEdit = () => {
    setShowEdit(false);
    setEditingRowId(null);
    setUsersSelected([]);
  };

  const handleDelete = (id) => {
    console.log("borrar");
  };

  const searchProvider = (providerName = "", listUsers, setListUsersFilter) => {
    const tempArray = listUsers.slice();
    if (providerName.length > 0) {
        const filteredUsers = tempArray.filter((item) => {
            const tradeName = item?.trade_name?.toLowerCase() || "";
            const guestUserName = item?.guest_user_name?.toLowerCase() || "";
            return tradeName.includes(providerName.toLowerCase()) || guestUserName.includes(providerName.toLowerCase());
        });
        setListUsersFilter(filteredUsers);
    } else {
        setListUsersFilter(tempArray);
    }
};

  return loading ? (
    <Loading />
  ) : (
    <MainContainer>
      <HeaderActive
        providerName={providerName}
        setProviderName={setProviderName}
        searchProvider={searchProvider}
        listUsers={listUsers}
        setListUsersFilter={setListUsersFilter}
        contractFilter={contractFilter}
        usersSelected={usersSelected}
        showEdit={showEdit}
        handleCloseEdit={handleCloseEdit}
        handleEdit={handleEdit}
        setShowInvite={setShowInvite}
      />
      <BasicData>
        <div className="row header">
          <p className="col">Nombre</p>
          <p className="col text_center">Correo</p>
          <p className="col text_center">Contrato</p>
        </div>
        {listUsersFilter?.length > 0 ? (
          listUsersFilter.map((e, i) => (
            <div className="row margin_row" key={i}>
              <div className="col chk-cont">
                <CheckBox
                  id={"chk-" + e.id_company}
                  type="checkbox"
                  typeColor="green"
                  checked={usersSelected.includes(e.id_company)}
                  onChange={(evt) => {
                    chkOnChange(evt, e.id_company, setUsersSelected);
                  }}
                />
                <p>{`${e.trade_name || e.guest_user_name}` || "---"}</p>
              </div>
              <p className="col">{e.email || e.guest_user_email || "---"}</p>
              <p className="col">
                {showEdit && usersSelected.includes(e.id_company) ? (
                  <select
                    id={`contract-${e.id_company}`}
                    value={
                      contractValues[e.id_company]?.id ||
                      contractList.find(
                        (contract) => contract.name === e.contract
                      )?.id ||
                      ""
                    }
                    onChange={(event) => {
                      const selectedContract = contractList.find(
                        (contract) => contract.id === Number(event.target.value)
                      );
                      handleContractChange(e.id_company, selectedContract);
                    }}
                  >
                    <option value="" disabled>
                      Seleccionar contrato
                    </option>
                    {contractList.map((contract) => (
                      <option key={contract.id} value={contract.id}>
                        {contract.name}
                      </option>
                    ))}
                  </select>
                ) : (
                  <p>
                    {contractValues[e.id_company]?.name ||
                      e.contract ||
                      "Sin contrato asignado"}
                  </p>
                )}
              </p>
            </div>
          ))
        ) : (
          <p className="no-users">No hay usuarios registrados</p>
        )}
      </BasicData>

      {show && <GenericModal close={() => setShow(false)} {...modalData} />}
      {showInvite && (
        <InviteUser
          setShowInvite={setShowInvite}
          inviteProviders={true}
          loadData={() =>
            getActiveProviders(
              setListUsers,
              setListUsersFilter,
              setContract,
              token,
              addNotification
            )
          }
        />
      )}
      <CustomNotification
        open={notifications.length > 0}
        alerts={notifications}
        deleteNotification={deleteNotification}
      />
    </MainContainer>
  );
};


export default ActiveProviders;