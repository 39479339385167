import { Container } from "./styles";
import ReactImageFallback from "react-image-fallback";
import defaultProfile from "../../../assets/images/defaultImages/defaultProfileImage.svg";

export const Avatar = ({
  image,
  altText,
  imageType,
  onClick,
  fallbackImage,
}) => {
  return (
    <Container className={imageType}>
      <ReactImageFallback
        src={image}
        fallbackImage={fallbackImage || defaultProfile}
        alt={altText}
        onClick={onClick}
      />
    </Container>
  );
};
