import styled from "styled-components";
export const MainContainer = styled.div`
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:10px;

  & > .filters-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .filters {
      display: flex;
      align-items: center;

      .search-user-input {
        display: flex;
        align-items: center;
        border: 1px solid #f0f0f0;
        border-radius: 50px;
        padding: 9px;

        img {
          width: 14px;
          height: 14px;
          & + * {
            margin-left: 15px;
          }
        }
        input {
          font-family: Roboto;
          font-size: 12px;
          outline: none;
          border: none;
        }
      }

      & > * + * {
        margin-left: 20px;
      }
    }

    .buttons-container {
      display: flex;
      gap:10px;
      align-items: center;

      button {
        background-color: transparent;
        cursor:pointer;
        &.trash-icon {
          img {
            width: 32px;
          }
          &:hover{
            background:var(--blue-light);
          }
        }

        &.circular-button {
          border-radius: 50%;
          overflow: hidden;
          width: 34px;
          height: 34px;
          border: 1px solid #f0f0f0;
        }

        &.add-users-button {
          font-family: Roboto, sans-serif;
          font-size: 12px;
          display: flex;
          width: 30px;
          height: 30px;
          padding: 2px;
          -webkit-box-pack: justify;
          justify-content: space-between;
          border-radius: 50px;
          background-color: var(--pink-contentoh);
          border: 1px solid var(--pink-contentoh);
          color: var(--white-contentoh);
          cursor: pointer;
          &:hover{
            background:transparent;
            border: 1px solid var(--pink-contentoh);
            color: var(--pink-contentoh);
          }
        }
        &.edit-users-button,
        &.delete-users-button {
          font-family: Roboto, sans-serif;
          font-size: 10px;
          display: flex;
          width: 30px;
          height: 30px;
          padding: 4px;
          -webkit-box-pack: justify;
          justify-content: space-between;
          border-radius: 50px;
          background-color: transparent;
          border: 1px solid var(--gray-light);
          color: var(--gray-contentoh);
          cursor: pointer;
          &:hover{
            background:var(--blue-light);
            border: 1px solid var(--pink-contentoh);
            color: var(--pink-contentoh);
          }
          span{
            font-size: 20px;
          }
        }
      }
    }
  }
`;

export const Section = styled.div`
  width: 95%;
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: var(--gray-contentoh);
  }
`;

export const BasicData = styled(Section)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.015em;
  color: #817393;
  width: 100%;

  .text_center {
    text-align: center;
  }
  .space {
    justify-content: space-between;
  }
  .align_end {
    justify-content: flex-end;
  }
  .margin_row {
    .col.chk-cont {
      display: flex;
      justify-content: flex-start;
      & > div {
        & + * {
          margin-left: 10px;
        }
      }
    }
    .col + * {
      margin-left: 10px;
    }
  }
  .column {
    display: flex;
    flex-direction: column;

    p {
      color: #817393;

      & + * {
        margin-top: 5px;
      }
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .col {
      flex: 1;
      align-items: center;

      figure {
        & + * {
          margin-left: 10px;
        }
      }

      &:first-child {
        padding: 10px;
        align-items: center;
        overflow: inherit;
      }
    }

    &.header {
      background-color: var(--blue-light);
      height: 34px;
      border-radius:5px 5px 0 0;

      .col {
        color: var(--black);
        text-align: left;
      }
    }
  }
  .center {
    justify-content: center;
    align-items: center;
  }
  input[type="email"] {
    width: 360px;
    height: 30px;
    border: 1px solid #f0eef2;
    box-sizing: border-box;
    border-radius: 7px;
    margin-right: 20px;
    ::placeholder {
      color: #d4d1d7;
    }
  }
  input[type="checkbox"]:checked {
    width: 75px;
    background-color: var(--pink-contentoh);
    color: green;
  }
  select {
    width: 116px;
    height: 30px;
    padding: 0 8px;
    border: 1px solid var(--pink-contentoh);
    border-radius: 7px;
    box-sizing: border-box;
    background: var(--white-contentoh);
  }
  button {
    width: 130px;
    height: 30px;
    font-size: 14px;
    line-height: 19px;
    color: var(--pink-contentoh);
    background: var(--white-contentoh);
    border: 1px solid var(--pink-contentoh);
    box-sizing: border-box;
    border-radius: 30px;
    :hover {
      cursor: pointer;
    }
  }
  .no-users{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    padding: 5px;
    color: var(--gray-contentoh);
    width: 100%;
  }
`;