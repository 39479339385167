import { Container } from "./styles";
import { FontFamily, GlobalColors } from "../../../global-files/variables";
import { BoxAttribute } from "../../molecules/BoxAttribute";
import { useState, useEffect, memo } from "react";
import { isEqual } from "lodash";

export const BoxOnboarding = memo(
  ({
    panelColor,
    index,
    onChange,
    data,
    dataInputs,
    inputs,
  }) => {
    const [atributos, setAtributos] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      onChange && onChange(atributos);
    }, [atributos]);
    

    useEffect(() => {
      if (isLoading && Object.keys(data).length > 0) {
        setAtributos(data);
        setIsLoading(false);
      }
    }, [data]);

    return (
      <Container
        panelColor={panelColor}
        fontFamily={FontFamily.Raleway}
        color={GlobalColors.original_magenta}
      >
        <div id="contenedor-caja">
          <p fontFamily={FontFamily.Raleway}>Caja {index + 1}</p>
          {inputs.map((attrId) => {
            return (
              <BoxAttribute
                id={attrId}
                key={attrId}
                borderType="rectangle"
                className="caja"
                text={dataInputs[attrId]?.name}
                required={dataInputs[attrId]?.required}
                description={dataInputs[attrId]?.description}
                atributos={atributos}
                setAtributos={setAtributos}
              />
            );
          })}
          
        </div>
      </Container>
    );
  },
  isEqual
);