import React, { useEffect, useState } from "react";
import axios from "axios";
import { getProfilePicture } from "../utils/user_utils";
import AWS from "aws-sdk";
import { 
  MainContainer,
  CheckNotification,
  AboutMe,
  SelectFile,
  ProfileImage,
  ImageContainer,
  ContainerPlan,
  BasicData
 } from "./styles";
 import { countryNames } from "../../../constants/countries";
 import CustomNotification from "../../general/CustomNotification";

// SVG assets
import location from "../../../assets/IconComponents/locationWhite.svg";
import profile from "../../../assets/IconComponents/profileWhite.svg";
import account from "../../../assets/IconComponents/account_circle.svg";
import defaultProfile from "../../../assets/defaultImages/defaultProfile.png";

// Styled components
import { Input, Textarea, SaveData } from "../CompanyData/styles";
import ReactImageFallback from "react-image-fallback";
import Plan from "../PlanSection/index";

import useNotifications from "../../../hooks/useNotification.jsx";
import CustomButton from "../../general/CustomButton/index.jsx";
import HeaderData from "../HeaderData/index.jsx";
import CustomInput from "../../general/CustomInput/index.jsx";

const S3_BUCKET = process.env.REACT_APP_IMAGES_PROFILE_BUCKET;
const REGION = "us-east-1";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_KUTS3,
  secretAccessKey: process.env.REACT_APP_AKUTS3,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const PersonalData = (props) => {
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem("user")));
  const [userAux, setUserAux] = useState(
    JSON.parse(sessionStorage.getItem("user"))
  );
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUploaded, setImageUploaded] = useState(defaultProfile);
  const [dataImage, setDataImage] = useState({});
  const [countrySelected, setCountrySelected] = useState(countryNames[user.country] || "");
  const countryArray = Object.entries(countryNames).map(([value, label]) => ({
    label,
    name: label,
  }));
  const { notifications, addNotification, deleteNotification, notificationOpen, setNotificationOpen } = useNotifications();

  const { history, child } = props;

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("user"));
    if (userData && userData.src) {
      const url = "https://d24s337exbt5zr.cloudfront.net/" + userData.src;
      setImageUploaded(url);
    } else {
      setImageUploaded(defaultProfile);
    }
  }, []);

  const onSubmit = async () => {
    const data = {
      name: document.getElementById("nameInput").value,
      last_name: document.getElementById("lastNameInput").value,
      birth_date: document.getElementById("birthDateInput").value,
      address: document.getElementById("addressInput").value,
      zip_code: document.getElementById("zipCodeInput").value,
      telephone: document.getElementById("telephoneInput").value,
      about_me: document.getElementById("aboutMeInput").value,
      position: document.getElementById("positionInput").value,
      country: document.getElementById("country").value,
      email_notify: document.getElementById("check-notify").checked ? 1 : 0,
    };
  
    try {
      await axios.put(
        `${process.env.REACT_APP_USER_ENDPOINT}?boolUser=1`,
        data,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      );
      if (dataImage.src) {
        const base64Response = await fetch(dataImage.src);
        const blob = await base64Response.blob();
        const imageUrl = URL.createObjectURL(blob);
        const img = new Image();
  
        img.onload = async () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          canvas.toBlob(async (newBlob) => {
            const file = new File([newBlob], `profile.png`, { type: 'image/png' });
  
            const params = {
              Body: file,
              Bucket: S3_BUCKET,
              Key: `id-${userAux.id_user}/${user.id_user}.png`,
            };
            await myBucket.putObject(params).promise();
            let response = await axios.get(process.env.REACT_APP_USER_ENDPOINT, {
              headers: {
                Authorization: sessionStorage.getItem("jwt"),
              },
            });
  
            const newUserInfo = JSON.parse(response.data.body).data[0];
            newUserInfo.src = `id-${user.id_user}/${user.id_user}.png?${new Date().getTime()}`;
            sessionStorage.setItem("user", JSON.stringify(newUserInfo));
            setUser(newUserInfo);
            addNotification("success", "¡Los cambios se han guardado exitosamente!");
            setActive(false);
          }, 'image/png');
        };
  
        img.src = imageUrl;
      }
    } catch (err) {
      console.log(err.message);
      addNotification("error", "Hubo un error al guardar los cambios. Inténtalo de nuevo.");
    }
  };
  
  
  const PreviewImage = (e) => {
    const file = document.getElementById("profile").files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImageUploaded(event.target.result);
        setDataImage({ src: event.target.result, name: file.name });
      };
      reader.readAsDataURL(file);
    }
  };
  

  return (
    <>
    <ContainerPlan>
      <Plan history={history} hasChild={child !== null} />
    </ContainerPlan>
    <MainContainer>
      <HeaderData icon={profile} text="Información personal" active={active} setActive={setActive}/>
      <CheckNotificationSection active={active} />
      <BasicDataSection active={active} user={user} setUser={setUser} countryArray={countryArray} setCountrySelected={setCountrySelected} />
      <LocationSection active={active} user={user} setUser={setUser} countryArray={countryArray} setCountrySelected={setCountrySelected} />
      <BasicData>
        <HeaderData icon={account} text="Imagen de perfil" active={active} setActive={setActive} showEdit={false}/>
        <ImageContainer>
          <div className="containerSelectFile">
            {active && (
              <SelectFile
                type="file"
                id="profile"
                name="profile"
                accept="image/png, image/jpeg, image/jpg, image/gif"
                onChange={PreviewImage}
              />
            )}
          </div>
          <ProfileImage>
            <ReactImageFallback
              src={imageUploaded}
              fallbackImage={defaultProfile}
              className="img"
            />
          </ProfileImage>
        </ImageContainer>
      </BasicData>
      {active && (
        <SaveData>
          <CustomButton
            label="Guardar cambios"
            variant="pink"
            onClick={onSubmit}
          />
        </SaveData>
      )}
      <CustomNotification
        open={notifications.length > 0}
        alerts={notifications}
        deleteNotification={deleteNotification}
      />
    </MainContainer>
    </>
    
  );
}

export default PersonalData;
const CheckNotificationSection = ({ active }) => (
  <CheckNotification>
    <div>
      <label className={`custom-radio-checkbox ${!active ? 'disabled' : ''}`}>
        <input
          className="custom-radio-checkbox__input"
          id="check-notify"
          type="checkbox"
          disabled={!active}
        />
        <span className="icon-checkbox icon-checkbox-show"></span>
        <span className="item">
          Deseo recibir notificación cuando fábrica de contenido termine uno de mis productos
        </span>
      </label>
    </div>
  </CheckNotification>
);

const BasicDataSection = ({ active, user, setUser }) => (
  <BasicData>
    <div>
      <p className="title">Acerca de mí</p>
      {active ? (
        <Textarea
          id="aboutMeInput"
          placeholder="Agrega una breve descripción sobre ti"
          defaultValue={user.about_me || ""}
        />
      ) : (
        <p>{user.about_me || "---"}</p>
      )}
    </div>
    <div>
      <p className="title">Nombre</p>
      {active ? (
        <CustomInput
          id="nameInput"
          placeholder="Nombre"
          type="text"
          value={user.name || ""}
          onChange={(e) => setUser({...user, name: e.target.value})}
        />
      ) : (
        <p>{user.name || "---"}</p>
      )}
    </div>
    <div>
      <p className="title">Apellido</p>
      {active ? (
        <CustomInput
          id="lastNameInput"
          placeholder="Apellidos"
          type="text"
          value={user.last_name || ""}
          onChange={(e) => setUser({...user, last_name: e.target.value})}
        />
      ) : (
        <p>{user.last_name || "---"}</p>
      )}
    </div>
    <div>
      <p className="title">Fecha de nacimiento</p>
      {active ? (
        <CustomInput
          type="date"
          id="birthDateInput"
          value={user.birth_Date ? user.birth_Date.split("T")[0] : ""}
          onChange={(e) => setUser({...user, birth_Date: e.target.value})}
        />
      ) : (
        <p>{user.birth_Date ? user.birth_Date.split("T")[0] : "---"}</p>
      )}
    </div>
    <div>
      <p className="title">Puesto</p>
      {active ? (
        <CustomInput
          id="positionInput"
          placeholder="Puesto"
          type="text"
          value={user.position || ""}
          onChange={(e) => setUser({...user, position: e.target.value})}
        />
      ) : (
        <p>{user.position || "---"}</p>
      )}
    </div>
  </BasicData>
);

const LocationSection = ({ active, user, setUser, countryArray, countrySelected, setCountrySelected, setActive }) => (
  <BasicData>
    <HeaderData icon={location} text="Ubicación" active={active} setActive={setActive} showEdit={false}/>
    <div>
      <p className="title">País</p>
        {active ? (
        <select
        id="country"
        className="country-select"
        defaultValue={user.country || countrySelected}
        onChange={(e) => setCountrySelected(e.target.value)}
      >
        <option value="" disabled>
          Seleccionar País
        </option>
        {countryArray.map((country) => (
          <option key={country.value} value={country.value}>
            {country.name}
          </option>
        ))}
      </select>     
      ) : (
        <p>{user.country || "---"}</p>
      )}
    </div>
    <div>
      <p className="title">Dirección</p>
      {active ? (
        <CustomInput
          id="addressInput"
          type="text"
          placeholder="Agrega tu dirección"
          value={user.address || ""}
          onChange={(e) => setUser({...user, address: e.target.value})}
        />
      ) : (
        <p>{user.address || "---"}</p>
      )}
    </div>
    <div>
      <p className="title">Código postal</p>
      {active ? (
        <CustomInput
          id="zipCodeInput"
          placeholder="Código postal"
          type="number"
          value={user.zip_code || ""}
          onChange={(e) => setUser({...user, zip_code: e.target.value})}
        />
      ) : (
        <p>{user.zip_code || "---"}</p>
      )}
    </div>
    <div>
      <p className="title">Teléfono</p>
      {active ? (
        <CustomInput
          id="telephoneInput"
          placeholder="Teléfono"
          type="number"
          value={user.telephone || ""}
          onChange={(e) => setUser({...user, telephone: e.target.value})}
        />
      ) : (
        <p>{user.telephone || "---"}</p>
      )}
    </div>
    <div>
      <p className="title">Correo electrónico</p>
      <p>{JSON.parse(sessionStorage.getItem("user")).email}</p>
    </div>
  </BasicData>
);
