import React, { useState, useEffect, useCallback } from 'react';
import { TitleButtons, DataProduct, MainContainer } from './styles';
import CustomInput from '../../general/CustomInput';
import CustomSelect from '../../general/CustomSelect';
import CustomButton from '../../general/CustomButton';
import { saveAs } from "file-saver";
import { getDownloadTemplateURL, getGroupedOptions, createArticles, validateForm, handleUploadProducts, handleBlur, parseExcelData, checkForDuplicateUpc } from './utils';
import Modal from '../../general/Modal';
import { useModal } from '../../../hooks/useModal';
import useNotifications from '../../../hooks/useNotification';
import CustomNotification from '../../general/CustomNotification';
import { UPCList } from '../UPCList';
import SelectData from '../SelectData';
import * as XLSX from "xlsx";

const HeaderAdd = ({ lists, newArticle, setNewArticle, transformedOptions, articles, isRetailer, isTHDFinanced, isBodegaFinanced, userFinanced, isDropshipFinanced, isOnboarding, addArticle, headerMapping }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const { modalData, openModal, closeModal, handleCancelClick } = useModal();
  const { notifications, addNotification, deleteNotification } = useNotifications();
  const company = JSON.parse(sessionStorage.getItem("company"));
  const retailerIds = company.retailers.map((retailer) => retailer.id);
  const retailerNames = company.retailers.map((retailer) => retailer.name);
  const formattedRetailerNames = retailerNames.length > 1 ? retailerNames.join(', ') : retailerNames[0] || '';  
  const [isUpcDuplicate, setIsUpcDuplicate] = useState(false);
  const [upcListExcel, setUpcListExcel] = useState([]);
  const [duplicateUpcs, setDuplicateUpcs] = useState([]);

  const handleDownloadTemplate = () => {
    const templateURL = getDownloadTemplateURL({ 
      isRetailer, 
      isTHDFinanced, 
      isBodegaFinanced, 
      userFinanced, 
      isDropshipFinanced,
      isOnboarding
    });
    const link = document.createElement('a');
    link.href = templateURL;
    link.download = 'plantilla.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  
        if (rows.length > 100) {
          addNotification('error', "El archivo no puede contener más de 100 upcs.");
          return;
        }
  
        const { parsedArticles, duplicates } = parseExcelData(rows, articles, addNotification);
        setUpcListExcel(parsedArticles);
        setDuplicateUpcs([...duplicates]);
  
        if (duplicates.size > 0) {
          openModal({
            title: 'UPCs Repetidos',
            text: 'Los siguientes UPCs se encontraban duplicados, así que los eliminamos:',
            customComponent: (
              <UPCList upcExisting={[...duplicates]} />
            ),
            showAccept: true,
            onAcceptClick: () => {
              closeModal();
            },
          });
        }
  
        parsedArticles.forEach((article) => {
          addArticle(article);
        });
      };
  
      reader.readAsBinaryString(file);
    } else {
      console.error("No se ha seleccionado ningún archivo.");
      return;
    }
  };
  
  

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "upc") {
      const isDuplicate = checkForDuplicateUpc(value, articles, upcListExcel);
      setIsUpcDuplicate(isDuplicate);
    }
    setNewArticle(prev => ({
      ...prev,
      [id]: value,
    }));
  };  

  const handleSelectChange = (selectedOption, id) => {
    setNewArticle((prev) => {
      const updatedArticle = { ...prev };
  
      if (id === "depot") {
        updatedArticle.depot = selectedOption;
      } else if (id === "category") {
        updatedArticle.category = selectedOption;
      } else if (id === "type") {
        updatedArticle.type = selectedOption;
      }
  
      if (updatedArticle.depot && updatedArticle.category && updatedArticle.type) {
        const matchingOption = transformedOptions.find(
          (option) =>
            option.depot.value === updatedArticle.depot.value &&
            option.category.value === updatedArticle.category.value &&
            option.type.value === updatedArticle.type.value
        );
        if (matchingOption) {
          updatedArticle.id = matchingOption.id_category;
        } else {
          updatedArticle.id = '';
        }
      }
      return updatedArticle;
    });
  };
  

const handleAddArticle = () => {
  if (isUpcDuplicate) {
    addNotification("error", "El UPC ingresado ya existe.");
    return;
  }
    if (validateForm(newArticle)) {
      const filteredArticle = Object.keys(headerMapping).reduce((acc, key) => {
        const mappedKey = headerMapping[key];
        if (newArticle[mappedKey] !== undefined) {
          acc[mappedKey] = newArticle[mappedKey];
        }
        return acc;
      }, {});
      filteredArticle.retailer = formattedRetailerNames;
      addArticle(filteredArticle);
      setNewArticle({
        sku: "",
        upc: "",
        name: "",
        depot: "",
        category: "",
        type: "",
        retailer: "",
      });
      setIsUpcDuplicate(false);
    } else {
      addNotification("error", "Por favor completa todos los campos requeridos.");
    }
  };

  const uploadProducts = async () => {
    if (articles.length === 0) {
      addNotification("error", "Primero debe haber un producto para poder enviar.");
      return;
    }
    
    const articlesData = articles.map((article) => ({
      upc: article.upc,
      name: article.name,
      idCategory: `${article.depot}|${article.category}|${article.type}`,
      sku: "",
      company: [company.id_company],
      error: false,
      retailersSelected: [retailerIds],
    }));
  
    try {
      const res = await createArticles({ data: articlesData });
      const { statusCode } = res.data;
      const responseBody = JSON.parse(res?.data?.body);
      const { upcExisting, articlesInserted } = responseBody;
  
      if (statusCode === 404) {
        addNotification('error', 'Al parecer se excede el límite de productos en tu plan');
        return;
      }
      if (statusCode === 400) {
        addNotification('error', 'Error al crear productos.');
        return;
      }
      if (articlesInserted) {
        addNotification('success', `${articlesInserted.length} producto(s) creado(s) correctamente`);
        setNewArticle({
          sku: "",
          upc: "",
          name: "",
          depot: "",
          category: "",
          type: "",
          retailer: "",
        });
        articles.length = 0;
      }
  
    } catch (error) {
      console.error("Error al crear productos:", error);
      addNotification('error', 'Error al crear productos.');
    }
  };  

  const groupedOptions = getGroupedOptions(transformedOptions);

  return (
    <MainContainer>
      <TitleButtons>
        <h2 className="title">Agregar aquí</h2>
        <div className="buttons-container">
          <CustomButton icon="backup" variant="gray" iconOnly={true} type="file" onFileChange={handleFileChange} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
          <CustomButton icon="cloud_download" variant="gray" iconOnly={true} onClick={handleDownloadTemplate} showTooltip={true} tooltipText='Descarga la plantilla'/>
          <CustomButton label="Subir productos a Content-oh!" variant="white" onClick={uploadProducts} disabled={articles.length === 0} />
        </div>
      </TitleButtons>
      <div className="container-indications">
        <p className="indications">
          Completa todos los campos y presiona añadir. También puedes usar la
          carga por Excel con el botón de subir <span className="material-symbols-outlined">backup</span>
        </p>
      </div>
      <DataProduct>
        <CustomInput id="upc" type="text" placeholder="UPC" variant="graylight" value={newArticle.upc} onBlur={(e) => handleBlur(e, company, addNotification, setNewArticle, articles, isRetailer, setIsUpcDuplicate)} onChange={(e) => handleInputChange(e, setNewArticle)} isError={isUpcDuplicate}/>
        <CustomInput id="name" type="text" placeholder="Nombre" variant="graylight" value={newArticle.name} onChange={handleInputChange} />
        <SelectData
          id="depot"
          placeholder="Elige un Departamento"
          options={groupedOptions.depotOptions}
          label={newArticle.depot}
          value={newArticle.depot}
          onChange={(selectedOption) => handleSelectChange(selectedOption.value, "depot")} 
          variant="gray"
        />
        <SelectData
          id="category"
          placeholder="Elige una Categoría"
          options={groupedOptions.categoryOptions}
          label={newArticle.category}
          value={newArticle.category}
          onChange={(selectedOption) => handleSelectChange(selectedOption.value, "category")}
          variant="gray"
        />
        <SelectData
          id="type"
          placeholder="Elige un Tipo"
          options={groupedOptions.typeOptions}
          label={newArticle.type}
          value={newArticle.type}
          onChange={(selectedOption) => handleSelectChange(selectedOption.value, "type")}
          variant="gray"
        />
        <CustomButton variant="pink" icon="add" iconOnly={true} onClick={handleAddArticle}/>
      </DataProduct>
      <CustomNotification
        open={notifications.length > 0}
        alerts={notifications}
        deleteNotification={deleteNotification}
      />
      <Modal
          {...modalData}
          onClose={handleCancelClick}
      />
    </MainContainer>
  );
};

export default HeaderAdd;
