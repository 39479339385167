import { Container } from "./styles";
import { Button as ButtonMUI } from "@mui/material";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import {
  faSpinner as IconLoading,
  faFileUpload as IconUpload,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { getFileExtension, getFileName } from "../../../global-files/utils";
import { ButtonV2 } from "../ButtonV2";

export const ButtonFileChooser = (props) => {
  const {
    id,
    key,
    className,
    disabled,
    type, // white | whiteS2 | pink | gray | purple => por default (white)
    transparent, // boolean => por default (NO transparent)
    label,
    size, // number ... label? size -> label && icon adaptable : size -> icon
    borderType, // rectangle || oval (solo aplica para label) || circle (solo aplica para icon)
    showIcon, // icon fontawesome
    iconPosition = "start", // end
    isLoading, // boolean
    filters, // string
    multipleFiles, // boolean
    onChangeFiles, // (files = [{}]) => {}
  } = props;

  const openChooser = () => {
    const files = [];
    const chooser = document.createElement("input");
    chooser.type = "file";
    chooser.style.display = "none";
    if (multipleFiles) chooser.multiple = true;
    if (filters) chooser.accept = filters; // "image/*,text/*,application/*"
    chooser.onchange = async () => {
      for (const file of chooser.files) {
        files.push(file);
      }
      onChangeFiles && onChangeFiles(files);
    };
    document.body.appendChild(chooser);
    chooser.click();
    document.body.removeChild(chooser);
  };

  return (
    <ButtonV2
      id={id}
      key={key}
      className={className}
      disabled={disabled}
      isLoading={isLoading}
      type={type}
      transparent={transparent}
      label={label}
      size={size}
      borderType={borderType}
      icon={showIcon ? IconUpload : undefined}
      iconPosition={iconPosition}
      onClick={(event) => {
        openChooser();
      }}
    />
  );
};
