import { useState, useCallback } from 'react';
import axios from 'axios';

const objToQueryString = (params) => {
  return new URLSearchParams(params).toString();
};

export const useGetPercentage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const getPercentage = useCallback(async (params = {}) => {
    const dataToSend = [];
    const query = params?.query ? objToQueryString(params?.query ?? {}) : "";

    try {
      setLoading(true);
      params.data.forEach((product) => {
        dataToSend.push({
          id_article: product.id_article,
          id_category: product.id_category,
          version: product.version,
          id_retailer_array: product.id_retailer_array,
        });
      });

      const response = await axios.post(
        `${process.env.REACT_APP_VALID_EXPORT_ENDPOINT}?${query}`,
        { data: dataToSend },
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          }
        }
      );
      
      setData(response.data);
      return response.data;
    } catch (err) {
      setError(err);
      console.error("Error", err);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    getPercentage,
    loading,
    error,
    data,
  };
};
