import { VrsnSelect } from "./style";

export const VersionSelect = ({
  id,
  selectedVersions,
  onChange,
  label,
  versions = [],
}) => {
  return (
    <VrsnSelect>
      <label htmlFor={id}>{label}</label>
      <select
        id={id}
        name={id}
        value={selectedVersions[id]}
        onChange={onChange}
      >
        {versions.map((item, index) => (
          <option key={`${id}-${index}`} value={item.version}>
            Version {item.version}
          </option>
        ))}
      </select>
    </VrsnSelect>
  );
};
