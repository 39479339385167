import styled from "styled-components";
import { FontFamily, GlobalColors } from "../../../global-files/variables";

export const Container = styled.div`
  &.grid-gallery {
    position: relative;
    width: 179px;
    border-radius: 3px;
    overflow: hidden;

    .buttons-container {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .image-container {
      border: 1px solid #f0eef2;

      .information-container {
        background-color: ${GlobalColors.s2};
        display: flex;
        justify-content: space-between;
        padding: 0 2px;

        p {
          font-family: ${FontFamily.Lato};
          color: ${GlobalColors.s4};
          font-size: 11px;
          line-height: 17px;
        }
      }
    }
  }

  &.row-gallery {
    display: flex;
    align-items: center;
    padding-right: 10px;

    .buttons-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .image-container {
      display: flex;
      align-items: center;
      flex: 1;
      .information-container {
        display: flex;
        flex: 1;

        .center {
          max-width: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        p {
          font-family: ${FontFamily.Lato};
          color: ${GlobalColors.s4};
          font-size: 14px;
          line-height: 17px;
          text-align: center;

          &.highlighted-data {
            font-family: ${FontFamily.AvenirNext};
            color: ${GlobalColors.s5};
            background-color: ${GlobalColors.s2};
            border-radius: 3px;
            font-weight: 600;
            font-size: 11px;
            line-height: 18px;
            padding: 0 10px;
          }

          &:nth-child(1) {
            //min-width: calc(20% - 76px);
            //flex: 1 0 20%;
            min-width: 22%;
          }

          &:nth-child(2),
          &:nth-child(3) {
            text-align: center;
          }
          &:nth-child(2) {
            max-width: 90px;
            min-width: 90px;
          }
          &:nth-child(3) {
            max-width: 60px;
            min-width: 60px;
          }
          /* &:nth-child(4) {
            max-width: 90px;
            min-width: 90px;
          }
          &:nth-child(5) {
            max-width: 80px;
            min-width: 80px;
          } */
        }

        & > * {
          flex: 1;
          padding: 5px;
        }
      }
    }
  }

  &.audit-class{
    border: 1px solid #8a6caa;
    background-color: #ededfc;
  }
`;
