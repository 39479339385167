import {
  ContainerBtnPopUpChat,
  ContainerFixed,
  ContainerPopUp,
} from "./styles";
import { useEffect, useState } from "react";
import { ButtonV2 } from "../../atoms/ButtonV2";
import { ContentChat } from "./ContentChat";
import { faComments as IconChat } from "@fortawesome/free-solid-svg-icons";
import { Slide } from "@mui/material";
import {
  isStringEmpty,
  isValidNaturalNumber,
} from "../../../global-files/utils";

export const Chat = (props) => {
  const {
    chatType, // "merchant_product" | "order_product" | "ticket"
    chatContainerType, // ("fixed") | ("popUp")
    chatData = {}, // {} => aqui poner los datos requeridos segun el tipo de chat
    classNameContainerFixed, // clases CSS del envoltoria chat fixed
    size = 14, // tamaño del icono
  } = props;
  const [showPopUpChat, setShowPopUpChat] = useState(false);
  const [data, setData] = useState();

  const { ticketCompany, retailerId } = chatData || {};

  useEffect(() => {
    switch (chatType) {
      case "merchant_product":
        validateChatMerchantProduct();
        break;
      case "order_product":
        validateChatOrderProduct();
        break;
      case "ticket":
        validateChatTicket();
        break;
      case "product_status":
        validateProductStatus();
        break;
      default:
        setData({
          code: 404,
          message: `El tipo de chat especificado "${chatType}" no es valido`,
          errorDetail: "",
        });
    }
  }, [chatType]);

  const validateProductStatus = () => {
    const {
      userToken, // string
      id, // number
      version,
      retailerId, // number
      status,
      orderId,
    } = chatData;

    if (!isValidGeneral(userToken, id)) return;

    // validar el ID de la cadena asociada al producto en la OT
    if (!isValidNaturalNumber(retailerId)) {
      setDataError("La cadena relacionada al producto no es válida");
      return;
    }

    // validar el ID de la version asociada al producto en la OT
    if (!isValidNaturalNumber(version)) {
      setDataError("La versión del producto no es válida");
      return;
    }

    if (orderId && !isValidNaturalNumber(orderId)) {
      setDataError("La orden del producto no es válida");
      return;
    }

    setData({
      userToken,
      id,
      version,
      retailerId,
      orderId,
      status,
    });
  };

  const isValidGeneral = (userToken, id) => {
    // validar token del user
    if (isStringEmpty(userToken)) {
      setDataError("El token del usuario no es valido");
      return false;
    }
    // validar ID del producto
    if (!isValidNaturalNumber(id)) {
      setDataError("El ID del producto no es valido");
      return false;
    }
    return true;
  };

  // validar props que se requieren para el tipo de chat "order_product"
  const validateChatOrderProduct = () => {
    const {
      userToken, // string
      id, // number
      orderId, // number
      retailerId, // number
    } = chatData;

    if (!isValidGeneral(userToken, id)) return;

    // validar el ID de la OT en donde se encuentra el producto
    if (!isValidNaturalNumber(orderId)) {
      setDataError("El ID de la orden del producto no es valido");
      return;
    }
    // validar el ID de la cadena asociada al producto en la OT
    if (!isValidNaturalNumber(retailerId)) {
      setDataError("La cadena relacionada al producto no es valida");
      return;
    }
    setData({
      userToken,
      id,
      orderId,
      retailerId,
    });
  };

  // validar props que se requieren para el tipo de chat "merchants_product"
  const validateChatMerchantProduct = () => {
    const {
      userToken, // string
      id, // number
      version, // number
    } = chatData;

    if (!isValidGeneral(userToken, id)) return;

    // validar la version del producto
    if (!isValidNaturalNumber(version)) {
      setDataError("La version del producto no es valida");
      return;
    }
    setData({
      userToken,
      id,
      version,
    });
  };

  // validar props que se requieren para el tipo de chat "ticket"
  const validateChatTicket = () => {
    const {
      currentUser /* {
        token, // string
        id: number ,
        companyId: number , 
        isUserTech: boolean ,
      } */,
      id, // number
      statusTicket, // (string) "IN_PROGRESS" | "PENDING" | "COMPLETED"
      ticketOwnerUserId, // number
    } = chatData;
    // validar el token del usuario conectado
    if (isStringEmpty(currentUser?.token)) {
      setDataError("Tu identificación de usuario no fue especificado");
      return;
    }
    // validar si ID del usuario conectado
    if (!isValidNaturalNumber(currentUser?.id)) {
      setDataError("Tu ID de usuario no fue especificado");
      return;
    }
    // validar si ID de la compañia del usuario conectado
    if (!isValidNaturalNumber(currentUser?.companyId)) {
      setDataError("El ID de tu compañia no fue especificado");
      return;
    }
    // validar si el usuario conectado es de tecnologia o no
    if (![false, true].includes(currentUser?.isUserTech)) {
      setDataError("Tu tipo de usuario no fue especificado");
      return;
    }
    // validar ID del ticket
    if (!isValidNaturalNumber(id)) {
      setDataError("El ID del ticket no es valido");
      return;
    }
    // validar ID del usuario que creó el ticket
    if (!isValidNaturalNumber(ticketOwnerUserId)) {
      setDataError(
        "El ID del usuario al que le pertenece el ticket no es valido"
      );
      return;
    }
    // validar el status del chat
    if (!["IN_PROGRESS", "PENDING", "COMPLETED"].includes(statusTicket)) {
      setDataError("El estatus del ticket no es valido");
      return;
    }
    let statusChat = "open";
    if (statusTicket === "COMPLETED") statusChat = "closed";

    // convertir user TECH a NORMAL si es el propietario del ticket
    let isUserTech = currentUser.isUserTech;
    if (isUserTech && currentUser.id === ticketOwnerUserId) {
      isUserTech = false;
    }
    setData({
      currentUser: {
        ...currentUser,
        isUserTech,
      },
      id,
      statusChat,
    });
  };

  const setDataError = (errorDetail) => {
    setData({
      code: 404,
      message: `No se especificaron algunos datos requeridos para el tipo de chat "${chatType}"`,
      errorDetail,
    });
  };

  if (chatContainerType?.toLowerCase() === "fixed") {
    /* chat tipo fixed */
    return (
      <ContainerFixed className={classNameContainerFixed}>
        <ContentChat
          chatType={chatType?.toLowerCase()}
          chatContainerType="fixed"
          dataChat={data}
          showBtnClose={false}
          ticketCompany={ticketCompany}
          activeRetailer={props.activeRetailer || { id: retailerId }}
        />
      </ContainerFixed>
    );
  } else {
    /* chat tipo popUp */
    return (
      <>
        <ContainerBtnPopUpChat>
          <ButtonV2
            className="container-btn-popUpChat"
            type="whiteS2"
            size={size}
            borderType="circle"
            icon={IconChat}
            onClick={() => {
              setShowPopUpChat(!showPopUpChat);
            }}
          />
        </ContainerBtnPopUpChat>

        <Slide direction="left" in={showPopUpChat} timeout={400}>
          <ContainerPopUp>
            <ContentChat
              chatType={chatType?.toLowerCase()}
              chatContainerType="popUp"
              dataChat={data}
              showPopUpChat={showPopUpChat}
              showBtnClose={true}
              ticketCompany={ticketCompany}
              onClickBtnClose={() => {
                setShowPopUpChat(false);
              }}
              activeRetailer={props.activeRetailer || { id: retailerId }}
            />
          </ContainerPopUp>
        </Slide>
      </>
    );
  }
};
