import axios from "axios";

// export const getCollaborators = async () => {
//     const response = await axios({
//       method: "get",
//       url: process.env.REACT_APP_COLLABORATOR_ENDPOINT,
//       headers: {
//         Authorization: sessionStorage.getItem("jwt"),
//       },
//     });
//     sessionStorage.setItem(
//       "collaborator",
//       JSON.stringify(JSON.parse(response.data.body).users)
//     );
//   };

  export const getProduct = async (params = {}, indexFront) => {
    const func = async () => {
      const queryParams = encodeURIComponent(JSON.stringify(params));
      const articlesResponse = await axios.get(
        `${process.env.REACT_APP_NEW_ARTICLES_ENDPOINT}?listType=general&params=${queryParams}&indexFront=${indexFront}`,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      );
      return articlesResponse;
    };
  
    return await func();
  };