import React, { useEffect, useState, useCallback } from "react";
import { MainContainer, Parrafo, BasicData } from "./styles.jsx";
import { useModal } from "../../../hooks/useModal.jsx";
import {
  fetchDataFromUtils,
  masiveDeleteGroup,
  searchUser
} from "./utils.jsx";
import CustomNotification from "../../general/CustomNotification";
import { EditGroup } from "../../general/EditGroup";
import Multiselect from "../../general/Multiselect";
import { CheckboxList } from "../../general/customInputs/CheckboxList";
import { CheckBox } from "contentoh-components-library/dist/components/atoms/CheckBox";
import trash from "../../../assets/IconComponents/trashv2.svg";
import searchIcon from "../../../assets/IconComponents/search.svg";
import save from "../../../assets/IconComponents/save.svg";
import groupEdit from "../../../assets/IconComponents/group_pink.svg";
import personAdd from "../../../assets/IconComponents/person_add.svg";
import InviteUser from "../InviteUser/index.jsx";
import useNotifications from "../../../hooks/useNotification.jsx";
import Modal from "../../general/Modal/index.jsx";

// Main Component
const GroupsUsers = () => {
  const [listUsersFilter, setListUsersFilter] = useState([]);
  const [showInvite, setShowInvite] = useState(false);
  const [userName, setUserName] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [userData, setUserData] = useState([]);
  const [groupsFilter, setGroupsFilter] = useState([]);
  const [groupsData, setGroupsData] = useState([]);
  const [rowsChecked, setRowsChecked] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [gruposSeleccionados, setGruposSeleccionados] = useState([]);
  const { modalData, openModal, closeModal, handleCancelClick, } = useModal();
  const [showEditGroup, setShowEditGroup] = useState(false);
  const { notifications, addNotification, deleteNotification } = useNotifications();
  const token = sessionStorage.getItem("jwt");

  const fetchData = useCallback(
    async (selectedGroupIds = []) => {
      const filteredGroupIds = selectedGroupIds.length > 0 ? selectedGroupIds.map((group) =>{ 
        return( 
          group
        )
      }) : [];
        await fetchDataFromUtils(filteredGroupIds, setUserData, setGroupsData, setListUsersFilter, setGroupsFilter, addNotification);
    },
    [setUserData, setGroupsData, setListUsersFilter, setGroupsFilter, addNotification]
  );

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedRows.length > 0) {
      setIsCheckboxChecked(true);
    } else {
      setIsCheckboxChecked(false);
    }
  }, [selectedRows]);

  const handleSelectAll = (evt) => {
    if (evt.target.checked) {
      const allRowIds = listUsersFilter.map((rowData) => rowData.idUser);
      setRowsChecked(allRowIds);
      setSelectedRows(allRowIds);
      setIsCheckboxChecked(true);
    } else {
      setRowsChecked([]);
      setSelectedRows([]);
      setIsCheckboxChecked(false);
    }
  };

  const handleSelectRow = (idUser) => {
    setRowsChecked((prevRowsChecked) => {
      if (prevRowsChecked.includes(idUser)) {
        setIsCheckboxChecked(false);
        setIsSaveButtonEnabled(false);
        return prevRowsChecked.filter((id) => id !== idUser);
      } else {
        setIsCheckboxChecked(true);
        setIsSaveButtonEnabled(true);
        return [...prevRowsChecked, idUser];
      }
    });

    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(idUser)) {
        return prevSelectedRows.filter((id) => id !== idUser);
      } else {
        return [...prevSelectedRows, idUser];
      }
    });
    setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, idUser]);
  };

  const handleDeleteMasive = async () => {
    await masiveDeleteGroup(selectedUsers, addNotification);
    setGruposSeleccionados([]);
    setSelectedUsers([]);
    setIsCheckboxChecked(false);
    setSelectedRows([]);
    fetchData();
  };
  

  const handleDeleteGroup = () => {
    openModal({
      className: "modal-delete",
      title: "Atención",
      text: "Se eliminaran todos los grupos de este usuario. ¿Estás seguro?", 
      showAccept: false,
      showCancel: false,
      buttons: [
        {
          label: "Si, estoy seguro",
          onClick: () => {
            handleDeleteMasive();
            closeModal();
          },
          className: "btn-yes",
        },
        {
          label: "No",
          onClick: closeModal,
          className: "btn-no",
        }
      ],
    });
  };

  const handleSaveChanges = () => {
    addNotification(
      'success',
      "El cambio de grupo fue aplicado correctamente."
    );
    setGruposSeleccionados([]);
    setSelectedUsers([]);
    setIsCheckboxChecked(false);
    setSelectedRows([]);
    fetchData();
  };

  const handleEditGroup = () => {
    setShowEditGroup(true);
  };

  const handleEditGroupClose = () => {
    setShowEditGroup(false);
    setIsCheckboxChecked(false);
    setSelectedRows([]);
    setSelectedUsers([]);
  };

  return (
      <MainContainer>
        <Filters
          userName={userName}
          setUserName={setUserName}
          searchUser={(name) => searchUser(name, userData, setListUsersFilter, addNotification)}
          groupsFilter={groupsFilter}
          setGroupsFilter={setGroupsFilter}
          setGroupsSelected={setGruposSeleccionados}
          fetchData={fetchData}
          isCheckboxChecked={isCheckboxChecked}
          setShowInvite={setShowInvite}
          handleEditGroup={handleEditGroup}
          handleDeleteGroup={handleDeleteGroup}
          handleSaveChanges={handleSaveChanges}
        />
        <UsersList
          listUsersFilter={listUsersFilter}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          setIsCheckboxChecked={setIsCheckboxChecked}
          handleSelectRow={handleSelectRow}
          handleSelectAll={handleSelectAll}
          token={token}
        />
         {showInvite && (
          <InviteUser
            setShowInvite={setShowInvite}
            inviteMoreUsers={true}
            loadData={fetchData}
          />
        )}
        <EditGroup
          token={token}
          show={showEditGroup}
          onClose={handleEditGroupClose}
        />
        <Modal {...modalData} onClose={handleCancelClick} />
        <CustomNotification
        open={notifications.length > 0}
        alerts={notifications}
        deleteNotification={deleteNotification}
      />
      </MainContainer>
  );
};

const Filters = ({
  userName,
  setUserName,
  searchUser,
  groupsFilter,
  setGroupsSelected,
  fetchData,
  isCheckboxChecked,
  setShowInvite,
  handleEditGroup,
  handleDeleteGroup,
  handleSaveChanges,
  userData,
  setListUsersFilter
}) => (
  <div className="filters-container">
    <div className="filters">
      <div className="search-user-input">
        <img src={searchIcon} alt="search-icon" />
        <input
          type="text"
          placeholder="Buscar usuarios"
          value={userName}
          onChange={(e) => {
            const name = e.target.value;
            setUserName(name);
            searchUser(name, userData, setListUsersFilter);
          }}
        />
      </div>
      <CheckboxList
        id="group-select"
        items={groupsFilter}
        name="Grupo"
        defaultSearch=""
        defaultOption="Todos los grupos"
        placeholder="Buscar grupo"
        onChange={(selectedItems) => {
          setGroupsSelected(selectedItems);
          fetchData(selectedItems);
        }}
      />
    </div>
    <div className="buttons-container">
      {isCheckboxChecked ? (
        <button
          className="trash-button"
          onClick={handleDeleteGroup}
        >
          <span class="material-symbols-outlined">
            delete
          </span>
        </button>
      ) : (
        <>
          <button
            className="add-users-button circular-button-v2"
            onClick={() => setShowInvite(true)}
          >
            <img src={personAdd} alt="add icon" />
          </button>
          <button
            className="edit-users-button circular-button-v2"
            onClick={handleEditGroup}
          >
            <img src={groupEdit} alt="edit icon" />
          </button>
        </>
      )}
      {isCheckboxChecked && (
        <button
          className="save-changes circular-button-v2"
          onClick={handleSaveChanges}
        >
          <span class="material-symbols-outlined">
            save
          </span>
        </button>
      )}
    </div>
  </div>
);

const UsersList = ({
  listUsersFilter,
  selectedRows,
  handleSelectRow,
  handleSelectAll,
  token,
}) => (
  <BasicData>
    <div className="row header th-custom">
      <p className="col row gap-10">
        <CheckBox
          id={"globalHeaderCheckbox"}
          type="checkbox"
          typeColor="green"
          checked={selectedRows.length === listUsersFilter.length}
          onChange={handleSelectAll}
        />
        Nombre
      </p>
      <p className="col text_center">Correo</p>
      <p className="col">Grupo</p>
    </div>
    {listUsersFilter.length > 0 ? (
      listUsersFilter.map((user) => (
        <div className="row" key={user.idUser}>
          <p className="col flex gap-10">
            <CheckBox
              id={`checkbox-${user.idUser}`}
              typeColor="green"
              onChange={() => handleSelectRow(user.idUser)}
              checked={selectedRows.includes(user.idUser)}
            />
            {user.userName}
          </p>
          <p className="col">{user.userEmail}</p>
          <p className="col">
            {selectedRows.includes(user.idUser) ? (
              <Multiselect
                arrayData={listUsersFilter}
                userId={user.idUser}
                token={token}
                showSearchBar={false}
                placeHolder="Buscar grupo"
                textButton="Editar grupos"
              />
            ) : (
              user.groups
                ?.filter(
                  (group) =>
                    group.groupId !== "Sin id" &&
                    group.userContentGroup === 1 &&
                    group.groupName !== "No tiene grupo asignados"
                )
                .map(
                  (group, index, array) =>
                    group.groupName + (index < array.length - 1 ? ", " : "")
                ) || "Sin grupos asignados"
            )}
          </p>
        </div>
      ))
    ) : (
      <div className="no-user">
        <Parrafo>No hay usuarios</Parrafo>
      </div>
    )}
  </BasicData>
);

export default GroupsUsers;
