import styled from 'styled-components';

export const MainNotification = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: fixed;
    bottom: 20px;
    left: 80px;
    z-index: 9999;
  
  .alert-container {
    min-width: 381px;
    width: auto;
  }
`;