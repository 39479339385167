import styled from "styled-components";
import { GlobalColors, FontFamily } from "../../../../global-files/variables";

export const Container = styled.div`
  width: 100%;
  //height: 100%; // modo dev
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  background: white;
  padding: 15px 14px 14px 14px;
  //border: 1px solid red;

  .container-chatDisabled {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    background-color: #f7f7f7;
    border-radius: 17px;
    padding: 10px 15px;

    .label-chatDisabled {
      flex-grow: 2;
      font-family: ${FontFamily.RobotoRegular}, sans-serif;
      font-size: 12px;
      text-align: center;
      color: #a2a2a2;
    }
  }

  .tooltipErrorUpdate {
    > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;

      .label-title {
        font-family: ${FontFamily.RobotoMedium}, sans-serif;
        font-size: 13px;
        text-align: center;
        color: #262626;
      }

      .label-detail {
        font-family: ${FontFamily.RobotoRegular}, sans-serif;
        font-size: 11px;
        text-align: center;
        color: #262626;
      }
    }
  }

  .container-ErrorUpdate {
    width: 20px;
    height: 20px;
    display: grid;
    place-items: center;
    overflow: hidden;
    padding: 0px;
  }

  @media (max-width: 600px) { 
    width: 90%;
  }
`;

export const ContainerMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 7px;
  flex-wrap: nowrap;
  background-color: #f7f7f7;
  border-radius: 17px;
  padding: 10px 7px 10px 15px;

  .input-itemMessage {
    flex-grow: 2;
    min-width: 80px;
    padding: 0px;
  }

  .btn-chooserFiles,
  .btn-sendMessage {
    width: 20px;
    height: 20px;
    display: grid;
    place-items: center;
    overflow: hidden;
    padding: 0px;
  }

  .btn-chooserFiles {
    .button {
      .icon:hover {
        color: #a2a2a2 !important;
      }
    }
  }
`;

export const ContainerSecondary = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  flex-wrap: nowrap;
  background-color: white;
  border-radius: 0px;
  padding: 0px;

  // container del preview files
  + .tooltip-previewFiles {
    width: 100%;
    padding: 15px 14px 0px 14px;
    background-color: transparent;
    z-index: 9;
    /*
    &[data-popper-placement*="bottom"] > .MuiTooltip-tooltip {
      margin-top: 5px;
    }
    &[data-popper-placement*="top"] > .MuiTooltip-tooltip {
      margin-bottom: 5px;
    }
    */

    > .MuiTooltip-tooltip {
      --background: #f7f7f7;
      width: 100%;
      background-color: var(--background);
      padding: 5px;
      border-radius: 15px;
      font-family: ${FontFamily.RobotoMedium}, sans-serif;
      font-size: 12px;
      text-align: left;
      color: #262626;
      max-width: unset;
      margin: 0px !important;

      .MuiTooltip-arrow {
        color: var(--background);
      }

      // container del carrusel de files
      .container-carrouselFiles {
        width: 100%;
        //border: 1px solid blue;

        // carrusel de files
        .carrousel-files {
          --sizePagination: 0px;
          width: 100%;
          padding-top: var(--sizePagination);
          border-radius: inherit;

          .swiper-pagination-progressbar {
            height: var(--sizePagination);
            border-radius: inherit;

            span {
              background-color: #e33aa9;
            }
          }

          .item-carrousel {
            width: 154px;
            height: 154px;
            background-color: white;
            display: grid;
            place-items: center;
            border-radius: 10px;
          }
        }

        // tooltip fileName
        + .tooltip-previewFileName {
          background-color: transparent;
          //transform: none !important;
          //inset: 0px auto auto auto !important;

          > .MuiTooltip-tooltip {
            --background: white;
            background-color: var(--background);
            padding: 5px;
            border-radius: 15px;
            font-family: ${FontFamily.RobotoMedium}, sans-serif;
            font-size: 12px;
            text-align: left;
            color: #262626;
            max-width: 154px;
          }

          &[data-popper-placement*="bottom"] .MuiTooltip-tooltip {
            margin-top: 15px;
          }
          &[data-popper-placement*="top"] .MuiTooltip-tooltip {
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  .btn-chooserFiles {
    width: 34px;
    height: 34px;
    display: grid;
    place-items: center;
    .button {
      background-color: #f7f7f7 !important;
      border: none !important;
      &:hover {
        background-color: #e7e7e7 !important;
      }
    }
  }

  .slide-btnSendFiles {
    flex-grow: 2;

    .btn-sendFiles {
      width: 100%;
      .button {
        padding: 10px 15px;
      }
    }
  }
`;

export const ContainerItemCarrousel = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  border-radius: inherit;
  background-color: inherit;

  .container-img {
    border-radius: inherit;
    width: 100%;
    height: 100%;
    background-color: inherit;
    transition: all 0.25s;
    z-index: 10;
  }

  .container-delete {
    position: absolute;
    border-radius: inherit;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    background-color: inherit;
    z-index: 11;
    opacity: 0;
    transition: all 0.3s;
    background-color: rgba(0, 0, 0, 0.35);
    overflow: hidden;

    .btn-delete .button {
      border: none;
      padding: 4px 8px;
      color: #262626 !important;
      .icon {
        font-size: 8px;
      }
    }
  }

  &:hover {
    .container-delete {
      opacity: 1;
    }
  }
`;
