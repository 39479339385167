import React from 'react';
import searchIcon from "../../../assets/IconComponents/search.svg";
import { MainContainer } from './styles';

const SearchInput = ({ placeholder, value, onChange }) => {
  return (
    <MainContainer>
      <img src={searchIcon} alt="search-icon" />
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
     </MainContainer>
  );
};

export default SearchInput;
