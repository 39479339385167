import styled from "styled-components";
import { FontFamily, GlobalColors } from "../../../global-files/variables";
export const Container = styled.div`
  font-family: ${FontFamily.Raleway_500};
  color: ${GlobalColors.s5};
  font-size: 14px;
  line-height: 21px;
  border-bottom: 2px solid ${GlobalColors.s3};
  width: 149px;
  padding-bottom: 5px;
  transition: 0.1s all;
  cursor: pointer;
  &.active {
    font-size: 18px;
    border-bottom: 2px solid ${GlobalColors.magenta_s2};
  }
  & + * {
    margin-left: 10px;
  }
`;