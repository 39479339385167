import React, { useState, useEffect } from "react";
import {
  Row,
  ItemRegister,
  ItemSelect,
  ItemLabel,
  RowButton,
  Button,
  FormularioRegister,
  Item
} from "../styles";
import Loading from "../../general/loading";
import { useAuth } from "../utils/AuthContext";
import PasswordValidation from "../PasswordValidation";
import ErrorLabel from "../../general/ErrorLabel";
import Logo from "../../general/Logo";
import { countryNames } from "../../../constants/countries";
import SelectInput from "../../general/SelectInput";
import Modal from "../../general/Modal";
import CustomNotification from "../../general/CustomNotification";
import AvisoPrivacidad from "../AvisoPrivacidad";
import Terminos from "../Terminos";
import useNotifications from "../../../hooks/useNotification";
import{ useModal } from "../../../hooks/useModal";

const RegistrationUser = ({ onNext }) => {
  const { setAuthenticated } = useAuth();
  const [loading, setLoading] = useState(false);
  const [emptyName, setEmptyName] = useState(true);
  const [emptyLastName, setEmptyLastName] = useState(true);
  const [emptyCountry, setEmptyCountry] = useState(true);
  const [emptyEmail, setEmptyEmail] = useState(true);
  const [emptyPassword, setEmptyPassword] = useState(true);
  const [showErrors, setShowErrors] = useState(false);
  const [registrationError, setRegistrationError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const { notifications, addNotification, deleteNotification } = useNotifications();
  const { modalData, openModal, closeModal, handleCancelClick } = useModal();
  const countryArray = Object.entries(countryNames).map(([id,name]) => ({
    label: name,
    value: name,
  }));

  useEffect(() => {
    validateForm();
  }, [name, lastName, email, password, confirmPassword, isLengthValid, hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar]);

  const validateForm = () => {
    const isNameValid = name.trim() !== "";
    const isLastNameValid = lastName.trim() !== "";
    const isEmailValid = email.trim() !== "";
    const isPasswordValid = isLengthValid && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar;
    const isConfirmPasswordValid = confirmPassword === password;
  
    setIsFormValid(isNameValid && isLastNameValid && isEmailValid && isPasswordValid && isConfirmPasswordValid);
  };
  
  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    validatePassword(value);
    validateForm();
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    
    if (value !== password) {
      setErrorMessage('Las contraseñas no coinciden');
    } else {
      setErrorMessage('');
    }
    validateForm();
  };

  const handleCountryChange = (country) => {
    setCountry(country);
  };

  const handleOpenTerms = () => {
    openModal({
      title: "Términos y Condiciones",
      customComponent: <Terminos />, 
      showAccept: true,
      showCancel: false,
      onAcceptClick: closeModal,
    });
  };

  const handleOpenNotice = () => {
    openModal({
      title: "Aviso de Privacidad",
      customComponent: <AvisoPrivacidad />, 
      showAccept: true,
      showCancel: false,
      onAcceptClick: closeModal,
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    validateForm();
    if (!isFormValid) {
      setShowErrors(true);
      return;
    }

    const userData = {
      name:name,
      lastName:lastName,
      email: email,
      password: password,
      country: country,
    };
    try {
      setLoading(true);
      onNext(userData);
      addNotification("success", `Se ha enviado el código de verificación al correo ${email}`);
    } catch (error) {
      console.error("Error de registro:", error);
      setRegistrationError("Error al registrarse.");
      addNotification("error", "Error al registrarse.");
      onNext(userData);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const validatePassword = (password) => {
    setEmptyPassword(password.length === 0);
    setIsLengthValid(password.length >= 8);
    setHasUpperCase(/[A-Z]/.test(password));
    setHasLowerCase(/[a-z]/.test(password));
    setHasNumber(/\d/.test(password));
    setHasSpecialChar(/[!@#$%^&*(),.?":{}|<>]/.test(password));
    validateForm();
  };

  return loading ? (
    <Loading />
  ) : (
    <FormularioRegister onSubmit={onSubmit}>
      <Logo/>
      <div className="container-register">
        <p className="title">Creación de cuenta</p>
        <Row>
          <label>Nombre</label>
          <ItemRegister>
            <input
              required
              id="nameInput"
              placeholder="Escribe tu nombre"
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setEmptyName(e.target.value.trim() === "");
                validateForm();
              }}
            />
          </ItemRegister>
        </Row>
        <ErrorLabel
          showErrors={showErrors}
          condition={emptyName}
          message="Ingrese su Nombre"
        />
        <Row>
          <label>Apellidos</label>
          <ItemRegister>
            <input
              required
              id="lastNameInput"
              placeholder="Escribe tus apellidos"
              type="text"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
                setEmptyLastName(e.target.value.trim() === "");
                validateForm();
              }}
            />
          </ItemRegister>
        </Row>
        <ErrorLabel
          showErrors={showErrors}
          condition={emptyLastName}
          message="Ingrese sus Apellidos"
        />
         <Row>
          <label>País</label>
          <ItemSelect>
            <SelectInput
              name="Selecciona tu país"
              items={countryArray}
              value={country}
              onChange={handleCountryChange}
              placeholder="Buscar país..."
            />
          </ItemSelect>
        </Row>
        <ErrorLabel
          showErrors={showErrors}
          condition={emptyCountry}
          message="Seleccione su país"
        />
        <Row>
          <label>Correo Electrónico</label>
          <ItemRegister>
            <input
              required
              id="emailInput"
              placeholder="Escribe tu correo electrónico"
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmptyEmail(e.target.value.trim() === "");
                validateForm();
              }}
            />
          </ItemRegister>
        </Row>
        <ErrorLabel
          showErrors={showErrors}
          condition={emptyEmail}
          message="Ingrese su correo electrónico"
        />
        <Row>
          <label>Crear contraseña</label>
          <ItemRegister>
            <input
              required
              id="passwordInput"
              placeholder="Escribe tu contraseña"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
            />
            <span
              onClick={togglePasswordVisibility}
              className="material-icons"
              style={{ cursor: "pointer", color: "#CBCBCB", fontSize: "18px" }}
            >
              {showPassword ? "visibility_off" : "visibility"}
            </span>
          </ItemRegister>
        </Row>
        <PasswordValidation
          isLengthValid={isLengthValid}
          hasUpperCase={hasUpperCase}
          hasLowerCase={hasLowerCase}
          hasNumber={hasNumber}
          hasSpecialChar={hasSpecialChar}
        />
        <Row>
          <label>Confirmar contraseña</label>
          <ItemRegister>
            <input
              required
              id="confirmPasswordInput"
              placeholder="Confirma tu contraseña"
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
            <span
              onClick={toggleConfirmPasswordVisibility}
              className="material-icons"
              style={{ cursor: "pointer", color: "#CBCBCB", fontSize: "18px" }}
            >
              {showConfirmPassword ? "visibility_off" : "visibility"}
            </span>
          </ItemRegister>
        </Row>
        {errorMessage && <p className="error">{errorMessage}</p>}
        {showAlert && (
          <div className="alert">
            <p>Por favor completa todos los campos correctamente.</p>
          </div>
        )}
        <RowButton>
          <Button type="submit" onClick={onSubmit}>
            Siguiente
          </Button>
        </RowButton>
      </div>
      <Row>
          <ItemLabel className="row">
            <label>
              Al completar el registro aceptas los <span onClick={handleOpenTerms}>Términos y condiciones</span> y el <span onClick={handleOpenNotice}>Aviso de privacidad</span>.
            </label>
          </ItemLabel>
      </Row>
      <CustomNotification
        open={notifications.length > 0}
        alerts={notifications}
        deleteNotification={deleteNotification}
      />
      <Modal
          {...modalData}
          onClose={handleCancelClick}
      />
    </FormularioRegister>
  );
};

export default RegistrationUser;
