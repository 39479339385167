import styled from "styled-components";
import { FontFamily, GlobalColors } from "../../../global-files/variables";

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  > * + * {
    margin-left: 8px;
  }

  .images-buttons {
    button + * {
      margin-left: 8px;
    }
  }

  .assignations-container {
    display: flex;
    cursor: pointer;
  }

  .asignation-panel {
    background: ${GlobalColors.s2};
    border: 1px solid ${GlobalColors.s3};
    box-sizing: border-box;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 10px 8px;
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    z-index: 10;

    .asignation-header {
      display: flex;
      justify-content: space-between;

      .asignation-status,
      .asignation-percent {
        & > p {
          font-family: ${FontFamily.AvenirNext};
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: ${GlobalColors.s5};
        }
      }

      .asignation-percent {
        text-align: right;
      }

      & + * {
        margin-top: 10px;
      }
    }

    .asignations-list {
      min-height: 40px;

      .no-asignations {
        padding: 5px;
        font-family: ${FontFamily.AvenirNext};
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: ${GlobalColors.s5};
      }

      & + * {
        margin-top: 10px;
        border-top: 1px solid ${GlobalColors.s3};
      }
    }

    .default-asignations-list {
      padding-top: 10px;
    }
  }
`;
