import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  .buttons-top {
    display: flex;
    align-items: center;
    padding-top: 10px;
  }

  .buttons-container {
    display: flex;

    button {
      width: 30px;
      height: 30px;
      border: 1px solid #f0f0f0;
      background-size: 20px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 15px;
      }

      & + * {
        margin-left: 10px;
      }
    }
  }
`;
