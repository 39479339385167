import styled from "styled-components";
export const MainContainer = styled.div`
  border: none;
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:20px;
`;

export const Header = styled.div`
  width:100%;
  display: flex;
  justify-content: space-between;
  .button-phase {
    cursor: pointer;
    padding: 9px 18px;
    background-color: var(--white-contentoh);
    border-radius: 30px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    border: none;
    color: var(--pink-contentoh);
    border:1px solid var(--pink-contentoh);
  }
  .button-save {
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    border: none;
    button{
      padding: 9px 18px;
    }
  }
  .modal-save{
    width:20%;
    background:transparent;
  }
`;

export const FlowsContainer = styled.div`
  width:100%;
`;