import styled from "styled-components";
import { FontFamily, GlobalColors } from "../../../global-files/variables";

export const Container = styled.div`
  background-color: ${GlobalColors.s3};
  width: fit-content;
  height: 27px;
  padding: 4px;
  border-radius: 15px;
  font-family: ${FontFamily.AvenirNext};
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: ${(backgroundColor) =>
    backgroundColor === "s2" || backgroundColor === "s1"
      ? GlobalColors.s4
      : GlobalColors.white};

  &.status-CA,
  &.status-IE {
    background-color: ${GlobalColors.in_progress};
  }

  &.status-R,
  &.status-AS,
  &.status-PA {
    background-color: ${GlobalColors.reception};
  }

  &.status-AA,
  &.status-AP,
  &.status-ACA,
  &.status-AC {
    background-color: ${GlobalColors.finished};
  }

  &.status-RA,
  &.status-RC,
  &.status-RP,
  &.status-RCA {
    background-color: ${GlobalColors.rejected_status};
  }

  &.status-Dat,
  &.status-Dsc,
  &.status-Imgs {
    background-color: ${GlobalColors.s4};
  }

  &.status-Ex {
    background-color: ${GlobalColors.exported};
  }

  &.status-DDI {
    background-color: ${GlobalColors.original_purpura};
  }

  &.status-GLD {
    background-color: ${GlobalColors.in_progress};
  }

  &.status-TAB {
    background-color: ${GlobalColors.deep_gray};
  }

  &.status-Pt {
    background-color: ${GlobalColors.s2};
    color: ${GlobalColors.s4};
  }
`;
export const ContainerIcon = styled.div`
  cursor: pointer;
  @font-face {
    font-family: "Material Symbols Outlined";
    font-style: normal;
    font-weight: 100 700;
    src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v68/kJEhBvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oFsLjBuVY.woff2)
      format("woff2");
  }

  .hola {
    font-family: "Material Symbols Outlined";
    font-size: 30px;
    //line-height: 1;
    margin-top: 10px;
  }
  .hola {
    font-variation-settings: "FILL"
        ${(props) => (props.percentageRequired < 100 ? 0 : 1)},
      "wght" 400, "GRAD" 0, "opsz" 48;
    color: ${(props) =>
      props.percentageRequired < 100 ? "#d4d1d7" : "#e33aa9"};
  }
`;
