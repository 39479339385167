import styled from "styled-components";
import { FontFamily, GlobalColors } from "../../../global-files/variables";

export const Container = styled.div`
  .title-container {
    position: relative;
    .tooltip {
      display: none;
      position: absolute;
      background-color: ${GlobalColors.white};
      color: ${({ color }) => (color ? color : GlobalColors.s5)};
      font-family: ${FontFamily.Raleway};
      font-size: 14px;
      line-height: 19px;
      left: 0;
      top: 0;
      height: fit-content;
      transition: display 2s;
    }

    &:hover {
      .tooltip {
        display: block;
      }
    }
  }

  & > :first-child {
    & + * {
      margin-top: ${({ inputType }) => (inputType !== "textarea" ? 4 : 15)}px;
    }
  }
  & + * {
    margin-top: ${({ inputType }) => (inputType !== "textarea" ? 0 : 10)}px;
  }
`;
