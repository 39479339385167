import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import {
  Container,
  NavLinkOption,
  ContainerPrincipal,
  GlobalStyle,
  Language
} from "./styles";
import iconLogoContentoh from "../../../assets/Login/logo.gif";
import iconLogoCompleto from "../../../assets/Login/logoCompleto.svg";
import ButtonLang from "../../general/ButtonLang";

const VerticalSideMenu = ({
  menuoption = [],
  containerdash,
}) => {
  const [trueBar, setTrueBar] = useState(false);
  return (
    <Router>
      <ContainerPrincipal>
        <Container
          bar={trueBar}
          onMouseOver={() => setTrueBar(true)}
          onMouseOut={() => setTrueBar(false)}
        >
          <div className="navbar-top">
            <img
              src={trueBar ? iconLogoCompleto : iconLogoContentoh }
              alt="Logo"
              className="logo"
            />
            <div className="option">
              {menuoption.map((item, index) => (
                <div key={index} className="option-div">
                  <NavLinkOption to={item.path} bar={trueBar.toString()}>
                    <img src={item.icon} alt={item.title}></img>
                    {trueBar ? <p>{item.title}</p> : null}
                  </NavLinkOption>
                  {item?.suboptions?.map((subitem, index) => (
                    <div className="sub-option" key={index}>
                      <NavLinkOption
                        to={subitem.path}
                        className="sub-option-link"
                        bar={trueBar.toString()}
                      >
                        {trueBar ? subitem.title : null}
                      </NavLinkOption>
                    </div>
                  ))}
                </div>
              ))}
              <div className="option-div">
                <ButtonLang trueBar={trueBar} />
              </div>
            </div>
          </div>
        </Container>
        {containerdash}
      </ContainerPrincipal>
      <GlobalStyle />
    </Router>
  );
};

export default VerticalSideMenu;