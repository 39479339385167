import { useState, useEffect } from 'react';
import axios from 'axios';

export const useCategories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      const company = JSON.parse(sessionStorage.getItem("company"));
      const user = JSON.parse(sessionStorage.getItem("user"));
      const key = company.financedRetailers ? "financedRetailers" : "retailers";
      const isRetailer = user.is_retailer === 0;
      const query = isRetailer || key === "financedRetailers"
        ? `?id=${encodeURIComponent(company[key].map((ret) => ret.id).join(","))}`
        : "";

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CATEGORY_ENDPOINT}${query}`,
          {
            headers: {
              Authorization: sessionStorage.getItem("jwt"),
            },
          }
        );

        const categoriesData = JSON.parse(response.data.body)?.data;
        console.log("categoriesData", categoriesData);

        // Extraer categorías y subcategorías desde el objeto
        const categoryValues = [];

        const extractCategories = (categoryObj, parentName = "") => {
          Object.keys(categoryObj).forEach((key) => {
            const category = categoryObj[key];
            const categoryName = parentName ? `${parentName} > ${key}` : key;

            if (category.sub_category) {
              extractCategories(category.sub_category, categoryName);
            } else if (category.id_category) {
              categoryValues.push({
                id: category.id_category,
                name: categoryName,
              });
            }
          });
        };

        extractCategories(categoriesData);
        categoryValues.sort((a, b) => a.name.localeCompare(b.name));

        setCategories(categoryValues);
      } catch (err) {
        console.error("Error fetching categories:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  return { categories, loading, error };
};

export const getCategoriesString = (categoriesArray) => {
  const newArray = [];
  categoriesArray?.forEach(({ name }) => {
    newArray.push(name);
  });

  const categoriesString =
    newArray.length > 2
      ? newArray[0] +
        ", " +
        newArray[1] +
        " y " +
        (newArray.length - 2) +
        " más"
      : newArray.join(", ");

  return categoriesString;
};
