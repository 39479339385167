import styled from "styled-components";

export const MainContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
  .products-select {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    background-color: var(--purple-light-contentoh);
    color: var(--white-contentoh);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px 0px;
   
    
    p {
      font-style: normal;
      font-weight: 500;
      font-size: clamp(16px, 6vw, 80px);
      text-align: center;
      color: rgb(237, 237, 237);
      line-height: 1;
      margin: 0;
    }
  }
`;
