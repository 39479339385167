import axios from "axios";
import { fetchPhases } from "../utils";

//agregar grupo a fase
export const addGroup = async (
  phaseId,
  groups,
  token,
  addNotification,
  loadData
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PHASES_ENDPOINT}/update`,
      {
        retailerGroupsIds: groups,
        phaseId,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    fetchPhases(token, addNotification);
    addNotification("success", "Se agrego correctamente el grupo a la fase.");
  } catch (error) {
    console.error("Error al agregar grupo a fase:", error);
    addNotification("error", "Error al agregar grupo a fase.");
  }
};

//actualiza siguiente fase
export const updateNextPhase = async (
  phaseId,
  newNextPhase,
  token,
  addNotification
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PHASES_ENDPOINT}/update`,
      {
        nextPhaseId: newNextPhase,
        phaseId,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    addNotification("success", "Se actualizo correctamente la siguiente fase.");
    fetchPhases(token, addNotification);
  } catch (error) {
    console.error("Error al actualizar la siguiente fase:", error);
    addNotification("error", "Error al actualizar la siguiente fase.");
  }
};

//checa si llega una fase que se tiene en otro lado
export const checkAvailablePhase = (
  phaseId,
  phases,
  newNextPhase,
  token,
  addNotification,
  openModal
) => {
  const isNextPhaseUsed = phases.some(
    (phase) => phase.nextPhaseIfApproved === newNextPhase
  );

  if (isNextPhaseUsed) {
    openModal({
      title:"Atención",
      className: "modal-next-phase",
      message: `La fase ${newNextPhase} ya está siendo utilizada como siguiente fase en otra fase.`,
      icon: "info",
      customComponent: (
        <div className="container-input-name">
          <p>La fase anterior se borrará</p>
          <p>¿Estás seguro?</p>
        </div>
      ),
      showCancel: true,
      showAccept: true,
      onAcceptClick: () => {
        updateNextPhase(phaseId, newNextPhase, token, addNotification)
    }
    });
  } else {
    // Si la fase no está siendo utilizada, se actualizar la siguiente fase
    updateNextPhase(phaseId, newNextPhase, token, addNotification);
  }
};

//eliminar grupo de fase
export const deleteGroup = async (
  phaseId,
  groupId,
  token,
  addNotification,
  loadData,
  setTempChips
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PHASES_ENDPOINT}/delete`,
      {
        deleteGroupOfPhase: {
          phaseId,
          groupId,
        },
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    setTempChips((prevChips) =>
      prevChips.filter((chip) => chip.id !== groupId)
    );
    addNotification("success", "Se eliminó el grupo correctamente");
    fetchPhases(token, addNotification);
  } catch (error) {
    console.error("Error al eliminar grupo de fase:", error);
    addNotification("error", "Error al eliminar grupo");
  }
};

//agregar grupo
export const onAdd = async (
  phaseId,
  selectedValue,
  groupsSelected,
  groups,
  setTempChips,
  setGroupsSelected,
  setSelectedValue,
  loadData
) => {
  if (selectedValue && !groupsSelected.includes(selectedValue)) {
    const updatedGroups = groups.map((group) =>
      group.groupName === selectedValue ? { ...group, groupActive: 1 } : group
    );
    setTempChips((prevChips) => [
      ...prevChips,
      { id: phaseId, label: selectedValue },
    ]);

    setGroupsSelected((prevGroups) => [...prevGroups, selectedValue]);
    setSelectedValue("");
    loadData();
  }
};
