import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Container, Observation, ServTab, SaveButton } from "./styles";
// import { Tooltip } from "../Tooltip";
import without from "../../../assets/images/flags/noPriority.svg";
import high from "../../../assets/images/flags/highPriority.svg";
import medium from "../../../assets/images/flags/mediumPriority.svg";
import low from "../../../assets/images/flags/lowPriority.svg";
import saveIconpink from "../../../assets/images/generalButton/saveIconpink.svg";
import { Modal } from "../../organisms/Modal";

export const ObservationFlag = ({
  observation,
  contentObservation,
  dataProduct,
  isObservationVisible, toggleObservation,
  // handleClickOutside,
  hideObservation
}) => {
  const PriorityFlags = {
    ["none"]: without,
    ["low"]: low,
    ["medium"]: medium,
    ["high"]: high,
  };

  //const [isObservationVisible, setObservationVisible] = useState(false);
  const [material, setMaterial] = useState(contentObservation || "");
  const [currentObservation, setCurrentObservation] = useState(observation);

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});

  const textareaRef = useRef(null);
  // const toggleObservation = () => {
  //   setObservationVisible(!isObservationVisible);
  // };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isObservationVisible &&
        !event.target.closest(".Observation") &&
        !event.target.closest(".Container")
      ) {
        // setObservationVisible(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isObservationVisible]);
  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, [isObservationVisible, handleClickOutside]);
  
  useEffect(() => {
    setMaterial(contentObservation || "");
  }, [contentObservation]); 

  useEffect(() => {
    setCurrentObservation(observation);
  }, [observation]);

  const saveRequest = async () => {
    let dataToSend = {
      orderId: parseInt(dataProduct.orderId),
      articleId: dataProduct.article.id_article,
      observation: textareaRef.current.value,
    };

    try {
      const response = await axios.put(
        process.env.REACT_APP_UPDATE_OBSERVATION,
        dataToSend
      );

      const succesResponse = {
        status: true,
        message: "Información guardada correctamente",
        detail: "",
        img: true,
      };
      const errorResponse = {
        status: false,
        message: "Error al guardar la información",
        detail: "",
        img: false,
      };

      setShowModal(true);
      setModalData(
        response.data.statusCode === 200 ? succesResponse : errorResponse
      );
      setCurrentObservation(material !== "" ? "high" : "low");
      hideObservation(); 
      return response;
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Container>
        <img
          src={PriorityFlags[currentObservation]}
          alt={observation}
          style={{ cursor: "pointer" }}
          onClick={toggleObservation}
        />
        {isObservationVisible && (
          <Observation
            onClick={(evt) => {
              evt.stopPropagation();
            }}
            className="Observation"
          >
            <ServTab
              onClick={(evt) => {
                evt.stopPropagation();
              }}
              className="close-Container"
            >
              <div>
                <span>Material completo</span>
              </div>

              <div className="flex">
                <textarea
                  ref={textareaRef}
                  name="textarea"
                  rows="10"
                  cols="50"
                  style={{ color: "black", padding: "5px" }}
                  value={material}
                  onChange={(e) => setMaterial(e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                />
              </div>

              <SaveButton
                onClick={(evt) => {
                  evt.stopPropagation();
                  saveRequest();
                }}
              >
                <img src={saveIconpink} alt="Guardar" />
              </SaveButton>
            </ServTab>
          </Observation>
        )}
      </Container>

      {showModal && (
        <Modal
          show={showModal}
          title={modalData.title}
          message={modalData.message}
          onClickBtnDefault={(event) => setShowModal(false)}
        />
      )}
    </>
  );
};
