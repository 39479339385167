import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const ContainerHeader = styled.header`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white-contentoh);
  display: flex;
  box-shadow: 0 1px 0 rgba(22, 8, 43, 0.1);
  height: auto;
  padding: 20px 0;
  flex-shrink: 0;
  .avatar {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    border: 1px solid var(--gray-light);
    box-shadow: 0 1px 0 rgba(22, 8, 43, 0.1);
  }
  .container-buttons{
    display:flex;
    align-items: center;
    gap: 10px;
    padding: 0 30px;
  }
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
`;
export const ContainerInfoRight = styled.div`
  display: flex;
  width: 156px;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ContainerInfoTitle = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  width: 100%;
  text-align: right;
  letter-spacing: -0.015em;
  color: #b12d84;
`;

export const ContainerInfoSubtitle = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 8px;
  text-align: right;
  color: #817393;
`;

export const UserInfo = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  height: auto;
  background: transparent;
  border-radius: 25px;
  justify-content: space-between;
  position: relative;
  cursor:pointer;
`;

export const ProfileMenu = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border: none;
  position: absolute;
  padding: 20px;
  top: 70%;
  right: 50%;
  transform: translateX(0);
  background-color: var(--blue-light);
  border-radius: 15px;
  z-index: 10;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

  .title-role {
    background-color: var(--purple);
    width:100%;
    min-width: 168px;
    height: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: var(--white-contentoh);
    text-align: center;
    border-radius: 15px;
    padding: 5px 10px;
  }

  .account-item {
    display: flex;
    gap: 10px;
    text-decoration: none;

    .icon-container {
      img {
        height: 100%;
      }
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #817393;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  flex-direction: column;
`;

export const HeaderTitle = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.015em;
  color: var(--black);
`;

export const ContainIcon = styled.div`
  width: 34px;
  height: 34px;
  border: 1px solid var(--gray-light);
  border-radius:50px;
  padding: 5px;
  cursor: pointer;
  background-color: transparent;
    span{
      font-size:20px;
      color: var(--gray-contentoh);
  }
  :hover{
      background: var(--pink-contentoh);
      border: 1px solid var(--pink-contentoh);
      span{
          font-size:20px;
          color: var(--white-contentoh);
      }
  }
`;

export const PulsatingCircle = styled.div`
  position: relative;
  right: -95%;
  top: -30px;
  transform: translateX(-50%) translateY(-50%);
  width: 15px;
  height: ${(props) => (props.notifications ? "15px" : "0px")};

  &:before {
    content: "";
    position: relative;
    right: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #ff009a;
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
  @keyframes pulse-ring {
    0% {
      transform: scale(0.33);
    }
    80%,
    100% {
      opacity: 0;
    }
  }
  @keyframes pulse-dot {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }
`;

export const ProfileNavLink = styled(NavLink)`
  display: flex;
  gap: 10px;
  text-decoration: none;
  align-items: center;

  .icon-container {
    img {
      height: 19px;
    }
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #817393;
  }
`;
