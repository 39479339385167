import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const ButtonLang = ({ trueBar }) => {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(sessionStorage.getItem("lang") || "es");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (selectedLang) => {
    setLang(selectedLang);
    i18n.changeLanguage(selectedLang);
    sessionStorage.setItem("lang", selectedLang);
    handleClose();
  };

  return (
    <>
      <Button
        sx={{ display: "block", mt: 5, padding: "0 20px" }}
        onClick={handleClick}
      >
        {trueBar ? (
          <p className="name">
            <span className="material-symbols-outlined">language</span>
            Lenguajes
          </p>
        ) : (
          <span className="material-symbols-outlined">language</span>
        )}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleLanguageChange("es")}>Español</MenuItem>
        <MenuItem onClick={() => handleLanguageChange("en")}>English</MenuItem>
      </Menu>
    </>
  );
};

export default ButtonLang;
