import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container } from "./styles";
import SearchBar from "../SearchBar";
import SearchInput from "../SearchInput";
import { CheckboxList } from "../customInputs/CheckboxList";
import CustomButton from "../CustomButton";
import CustomPrice from "../CustomPrice";
import WishList from "../WishList";
import { getExportables, getExportablesProvider } from "./utils";
import CustomNotification from "../CustomNotification";
import { useModal } from "../../../hooks/useModal";
import useNotifications from "../../../hooks/useNotification";
import Modal from "../Modal";
import ModalExport from "../ModalExport";
import { useHistory } from "react-router-dom";

const HeaderFilter = ({
  searchBar,
  placeholderSearchBar,
  filterWish,
  filterProvider,
  filterPhase,
  filterGroup,
  filterPrice,
  showWishlist,
  showVisibility,
  showMultipleEdition,
  showDelete,
  showCart,
  showView,
  showReport,
  showExportable,
  gridView,
  setGridView,
  onVisibilityChange,
  wishlistFilter,
  setWishlistFilter,
  onPriceChange,
  onWishlistChange,
  onSearchChange,
  providersFilter,
  setProvidersFilter,
  phasesFilter,
  setPhasesFilter,
  groupsFilter,
  setGroupsFilter,
  isProvider,
  selectedArticles,
  setSelectedArticles,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [wishlistValue, setWishlistValue] = useState([]);
  const [providerValue, setProviderValue] = useState([]);
  const [phaseValue, setPhaseValue] = useState([]);
  const [groupValue, setGroupValue] = useState([]);
  const [openList, setOpenList] = useState(false);
  const [loadingExportables, setLoadingExportables] = useState(true);
  const history = useHistory(); 
  const { modalData, openModal, closeModal, handleCancelClick } = useModal();
  const { notifications, addNotification, deleteNotification } =
    useNotifications();

  const transformedWishlist = wishlistFilter?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const transformedProviders = providersFilter?.map((item) => ({
    label: item.name,
    value: item.id_company,
  }));

  const transformedPhases = phasesFilter?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const transformedGroups = groupsFilter?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const itemsPrueba = [
    { id: 1, name: "prueba1" },
    { id: 2, name: "prueba2" },
  ];

  const handleSearch = (e) => {
    const name = e.target.value;
    setSearchValue(name);
    onSearchChange(name);
  };

  const toggleView = () => {
    setGridView(!gridView);
  };

  const handlePriceChange = (range) => {
    onPriceChange(range);
  };

  const handleWishlistChange = (selectedValue) => {
    onWishlistChange(selectedValue);
  };

  const handleProviderChange = (selectedValue) => {
    setProviderValue(selectedValue);
    setProvidersFilter(selectedValue);
  };

  const handlePhaseChange = (selectedValue) => {
    setPhaseValue(selectedValue);
    setPhasesFilter(selectedValue);
  };

  const handleGroupChange = (selectedValue) => {
    setGroupValue(selectedValue);
    setGroupsFilter(selectedValue);
  };

  const handleReportClick = () => {
    const flagUpdateFunction = (update) => console.log("Update:", update);
    const reportFilters = {};
    const aditionalFilter = {};
    if (isProvider) {
      getExportablesProvider(setLoadingExportables, addNotification);
    } else {
      getExportables(
        flagUpdateFunction,
        reportFilters,
        aditionalFilter,
        addNotification
      );
    }
  };

  const handleExportableClick = (selectedArticles) =>{
    if(selectedArticles.length > 0 ){
      openModal({
        className: "modal-export",
        title: "Exportable",
        text: "¿Deseas adquirir estos productos?. El exportable te llegará a este correo.",
        customComponent: <ModalExport productsSelected={selectedArticles.length}/>,
        buttons: [
          {
            label: "Regresar",
            onClick: closeModal,
            className: "btn-regresar",
          },
          {
            label: "Exportar",
            onClick: async () => {
        
            },
            className: "btn-exportar",
          },
        ],
      });
    }else{
      addNotification('error','Debes seleccionar productos para generar el exportable')
    }
  }

  const handleMultipleEditionClick = () => {
    if (selectedArticles.length > 0) {
      const articlesToEdit = selectedArticles.map(({ id_article, isVisible, version }) => ({
        id_article,
        isVisible,
        version,
      }));

      history.push({
        pathname: "/multipleEdition",
        state: { articles: articlesToEdit },
      });
    } else {
      addNotification("error", "Debes seleccionar productos para ir a edición múltiple");
    }
  };
  

  return (
    <Container>
      <div className="container-view">
        {searchBar && (
          <SearchInput
            placeholder={placeholderSearchBar}
            value={searchValue}
            onChange={handleSearch}
          />
        )}
        {filterWish && (
          <CheckboxList
            id="wishlist-filter"
            items={transformedWishlist}
            value={wishlistValue}
            name="Todas las Wishlist"
            defaultSearch=""
            defaultOption="Todas las Wishlist"
            placeholder="Buscar Wishlists"
            onChange={handleWishlistChange}
          />
        )}
        {filterProvider && (
          <CheckboxList
            id="provider-filter"
            items={transformedProviders}
            value={providerValue}
            name="Proveedores"
            defaultSearch=""
            defaultOption="Todos los proveedores"
            placeholder="Buscar proveedor"
            onChange={handleProviderChange}
          />
        )}
        {filterPhase && (
          <CheckboxList
            id="phase-filter"
            items={transformedPhases}
            value={phaseValue}
            name="Fases"
            defaultSearch=""
            defaultOption="Todas las Fases"
            placeholder="Buscar Fase"
            onChange={handlePhaseChange}
          />
        )}
        {filterGroup && (
          <CheckboxList
            id="group-filter"
            items={transformedGroups}
            value={groupValue}
            name="Grupos"
            defaultSearch=""
            defaultOption="Todos los grupos"
            placeholder="Buscar grupo"
            onChange={handleGroupChange}
          />
        )}
        {filterPrice && (
          <CustomPrice
            id="price-range"
            name="Todos los precios"
            minPrice={0}
            maxPrice={30000}
            onChange={handlePriceChange}
          />
        )}
        {showWishlist && (
          <>
            <CustomButton
              onClick={() => setOpenList((prevState) => !prevState)}
              variant="gray"
              icon="favorite"
              iconOnly
              showTooltip={true}
              tooltipText="Agregar Wishlist"
            />
            {openList && (
              <div className="wishlist-container">
                <WishList
                  openList={openList}
                  wishlistFilter={wishlistFilter}
                  setWishlistFilter={setWishlistFilter}
                />
              </div>
            )}
          </>
        )}
        {showVisibility && (
          <CustomButton
            onClick={() => onVisibilityChange()}
            className="wishlist-button"
            variant="gray"
            icon="visibility"
            iconOnly
            showTooltip={true}
            tooltipText="Cambiar visibilidad"
          />
        )}
      </div>
      <div className="container-view">
        {showMultipleEdition && (
          <CustomButton
            onClick={handleMultipleEditionClick}
            variant="gray"
            icon="edit_square"
            iconOnly
            showTooltip={true}
            tooltipText="Ir a Edición de multiple"
          />
        )}
        {showDelete && (
          <CustomButton
            onClick={() => console.log("wish")}
            variant="gray"
            icon="delete"
            iconOnly
            showTooltip={true}
            tooltipText="Borrar producto/s"
          />
        )}
        {showCart && (
          <CustomButton
            onClick={() => console.log("wish")}
            variant="gray"
            icon="add_shopping_cart"
            iconOnly
            showTooltip={true}
            tooltipText="Adquirir productos"
          />
        )}
        {showView && (
          <CustomButton
            onClick={toggleView}
            variant="gray"
            icon={gridView ? "table_rows" : "grid_view"}
            iconOnly
            showTooltip={true}
            tooltipText={gridView ? "Vista en tabla" : "Vista en grilla"}
          />
        )}
        {showReport && (
          <CustomButton
            onClick={handleReportClick}
            variant="gray"
            icon="cloud_download"
            iconOnly
            showTooltip={true}
            tooltipText="Descargar reporte de productos"
          />
        )}
        {showExportable && (
          <CustomButton
            onClick={() => handleExportableClick(selectedArticles)}
            variant="white"
            label="Exportar"
            showTooltip={true}
            tooltipText="Descargar exportable"
          />
        )}
      </div>
      <Modal {...modalData} onClose={handleCancelClick} />
      <CustomNotification
        open={notifications.length > 0}
        alerts={notifications}
        deleteNotification={deleteNotification}
      />
    </Container>
  );
};

HeaderFilter.defaultProps = {
  searchBar: false,
  placeholderSearchBar: "Buscar",
  filterWish: false,
  filterProvider: false,
  filterPhase: false,
  filterGroup: false,
  filterPrice: false,
  showWishlist: false,
  showVisibility: false,
  showMultipleEdition: false,
  showDelete: false,
  showCart: false,
  showView: false,
  showReport: false,
  showExportable: false,
  gridView: false,
  onVisibilityChange: () => {},
  wishlistFilter: [],
  setWishlistFilter: () => {},
  setGridView: () => {},
  providersFilter: [],
  setProvidersFilter: () => {},
  phasesFilter: [],
  setPhasesFilter: () => {},
  groupsFilter: [],
  setGroupsFilter: () => {},
};

HeaderFilter.propTypes = {
  searchBar: PropTypes.bool,
  placeholderSearchBar: PropTypes.string,
  filterWish: PropTypes.bool,
  filterProvider: PropTypes.bool,
  filterPhase: PropTypes.bool,
  filterGroup: PropTypes.bool,
  filterPrice: PropTypes.bool,
  showWishlist: PropTypes.bool,
  showVisibility: PropTypes.bool,
  showMultipleEdition: PropTypes.bool,
  showDelete: PropTypes.bool,
  showCart: PropTypes.bool,
  showView: PropTypes.bool,
  showReport: PropTypes.bool,
  showExportable: PropTypes.bool,
  gridView: PropTypes.bool,
  setGridView: PropTypes.func,
  onVisibilityChange: PropTypes.func,
  wishlistFilter: PropTypes.array,
  setWishlistFilter: PropTypes.func,
  onSearchChange: PropTypes.func,
  providersFilter: PropTypes.array,
  setProvidersFilter: PropTypes.func,
  phasesFilter: PropTypes.array,
  setPhasesFilter: PropTypes.func,
  groupsFilter: PropTypes.array,
  setGroupsFilter: PropTypes.func,
  selectedArticles: PropTypes.array.isRequired,
  setSelectedArticles: PropTypes.func.isRequired,
};

export default HeaderFilter;
