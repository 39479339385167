import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 500px;
    max-width: 90%;
    position: relative;
    box-shadow: 0px 2px 4px 0px #00000040;
    max-height:300px;
    overflow:auto;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    h2 {
      font-family: Roboto;
      font-size: 17px;
      font-weight: 500;
      line-height: 19.92px;
      text-align: center;
      color: #000000;
    }
  }

  .modal-close-button {
    border: none;
    font-size: 20px;
    cursor: pointer;
    background: transparent;
  }

  .btn-cancel {
    height: 25px;
    border: 1px solid var(--pink-contentoh);
    color: var(--pink-contentoh);
    font-size: 12px;
    padding: 5px 10px 5px 10px;
    border-radius: 30px;
    cursor: pointer;
    background: transparent;
    :hover {
      background: var(--pink-contentoh);
      color: var(--white-contentoh);
      border: 1px solid var(--pink-contentoh);
    }
  }

  .btn-accept {
    height: 25px;
    background: var(--pink-contentoh);
    color: var(--white-contentoh);
    font-size: 12px;
    padding: 5px 10px 5px 10px;
    border: 1px solid var(--pink-contentoh);
    border-radius: 30px;
    cursor: pointer;
    :hover {
      border: 1px solid var(--pink-contentoh);
      color: var(--pink-contentoh);
      background: transparent;
    }
  }

  .modal-body {
    margin-top: 20px;
    p {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.06px;
      color: var(--gray-contentoh);
    }
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 10px;
  }

  .additional-content {
    margin-top: 10px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
    color: var(--gray-contentoh);
  }
`;
