import React from 'react'
import { CheckboxList } from '../../../../general/customInputs/CheckboxList';
import searchIcon from "../../../../../assets/IconComponents/search.svg";

const HeaderActive = ({
    providerName,
    setProviderName,
    searchProvider,
    listUsers,
    setListUsersFilter,
    contractFilter,
    usersSelected,
    showEdit,
    handleCloseEdit,
    handleEdit,
    setShowInvite
}) => {
  return (
        <div className="filters-container">
        <div className="filters">
          <div className="search-user-input">
            <img src={searchIcon} alt="search-icon" />
            <input
              type="text"
              placeholder="Buscar usuarios"
              value={providerName}
              onChange={(e) => {
                const name = e.target.value;
                setProviderName(name);
                searchProvider(name, listUsers, setListUsersFilter);
              }}
            />
          </div>
          <CheckboxList
            id="contrato-select"
            items={contractFilter}
            name="Contrato"
            defaultSearch=""
            defaultOption="Todos los contratos"
            placeholder="Buscar contrato"
            onChange={(selectedItems) => {}}
          />
        </div>
        <div className="buttons-container">
          {usersSelected.length > 0 && (
            <>
              {/* <button className="delete-users-button" onClick={handleDelete}>
                <span className="material-symbols-outlined">
                  delete_forever
                </span>
              </button> */}
              <button
                className="edit-users-button"
                onClick={showEdit ? handleCloseEdit : handleEdit}
              >
                <span className="material-symbols-outlined">
                  {showEdit ? "close" : "edit"}
                </span>
              </button>
            </>
          )}
          <button
            className="add-users-button"
            onClick={() => setShowInvite(true)}
          >
            <span className="material-symbols-outlined">add</span>
          </button>
        </div>
      </div>
    );
}

export default HeaderActive