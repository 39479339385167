import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container } from "./styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import { EditGroup } from "../EditGroup";
import searchIcon from "../../../assets/IconComponents/Multiselect/search.svg";
import settings from "../../../assets/IconComponents/Multiselect/settings.svg";
import { fetchGroups, updateGroup, deleteGroup } from "./utils";

const Multiselect = ({
  arrayData,
  userId,
  token,
  showSearchBar,
  placeHolder = "Buscar grupo",
  textButton = "Editar grupos",
}) => {
  const [filters, setFilters] = useState([]);
  const [selectValues, setSelectValues] = useState([]);
  const [text, setText] = useState("");
  const [isEditGroupVisible, setIsEditGroupVisible] = useState(false);
  const [isItemListVisible, setIsItemListVisible] = useState(true);
  const currentUser = arrayData.find((user) => user.idUser === userId);
  const { idUser, groups } = currentUser || {};
  const [selectedItems, setSelectedItems] = useState([]);

  const loadUserGroups = (updatedValues) => {
    if (updatedValues && updatedValues.length > 0) {
      setSelectValues((prevValues) => ({
        ...prevValues,
        [idUser]: updatedValues.map((groupInfo) => groupInfo.groupName),
      }));
    } else {
      const hasUserContentGroup1 = groups.some((group) => group.userContentGroup === 1);

      if (hasUserContentGroup1) {
        const initialSelectedValues =
          groups
            .filter((group) => group.userContentGroup === 1)
            .map((group) => group.groupName) || [];
        setSelectValues((prevValues) => ({
          ...prevValues,
          [idUser]: initialSelectedValues,
        }));
      } else {
        setSelectValues((prevValues) => ({
          ...prevValues,
          [idUser]: ["Sin grupos asignados"],
        }));
      }
    }

    setFilters(groups ? groups : []);
  };

  useEffect(() => {
    loadUserGroups();
  }, [idUser, groups]);

  const onChange = (evt) => {
    setText(evt.target.value);
  };

  const handleChange = (userId) => async (event) => {
    const {
      target: { value },
    } = event;
    const filteredArrayCopy = [...filters];

    const updatedValues = groups.map((group) => {
      const isSelected = value.includes(group.groupName);
      return {
        ...group,
        userContentGroup: isSelected ? 1 : 0,
      };
    });

    setSelectedItems(updatedValues.filter((group) => group.userContentGroup === 1).map((group) => group.groupName));

    setSelectValues((prevValues) => ({
      ...prevValues,
      [userId]: updatedValues
        .filter((group) => group.userContentGroup === 1)
        .map((group) => group.groupName),
    }));

    const changes = updatedValues.reduce((result, group, index) => {
      if (group.userContentGroup !== filteredArrayCopy[index].userContentGroup) {
        result.push({
          groupId: group.groupId,
          userContentGroup: group.userContentGroup,
        });
      }
      return result;
    }, []);

    const updatedGroups = changes.filter((change) => change.userContentGroup === 1).map((change) => change.groupId);
    const deletedGroups = changes.filter((change) => change.userContentGroup === 0).map((change) => change.groupId);

    if (updatedGroups.length > 0) {
      await updateGroup({ userId, token, groupsIds: updatedGroups });
    }

    if (deletedGroups.length > 0) {
      await deleteGroup({ userId, token, groupsIds: deletedGroups });
    }
  };

  useEffect(() => {}, [selectValues]);

  useEffect(() => {
    let filteredArray = groups?.slice() || [];
    const normalizedText = text.trim().toLowerCase();

    if (normalizedText.length > 0) {
      filteredArray = filteredArray.filter((option) =>
        option?.groupName?.toLowerCase().includes(normalizedText)
      );
    }
    setFilters(filteredArray);
  }, [text]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "auto",
        display: isItemListVisible ? "block" : "none",
        left: "1013px",
      },
    },
  };

  return (
    <Container>
      {currentUser && (
        <Select
          labelId={`demo-multiple-checkbox-label-${idUser}`}
          id={`demo-multiple-checkbox-${idUser}`}
          multiple
          value={selectValues[idUser] || []}
          onChange={handleChange(idUser)}
          input={
            <OutlinedInput
              sx={{
                width: "80%",
                height: "34px",
                paddingBottom: "1px solid #F0F0F0",
              }}
            />
          }
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
          sx={{
            width: "80%",
            height: "24px",
            "& div": {
              color: "#707070",
              fontSize: "12px",
            },
          }}
        >
          {showSearchBar && (
            <MenuItem
              sx={{
                maxHeight: "34px",
                paddingLeft: "10px",
                "& .search-bar-filter": {
                  display: "flex",
                  gap: "10px",
                  input: {
                    border: "none",
                    background: "transparent",
                    "::placeholder": {
                      fontSize: "12px",
                      color: "#CBCBCB",
                    },
                    ":focus-visible, :hover": {
                      border: "none",
                      background: "transparent",
                      outline: "none",
                    },
                  },
                  ":focus-visible, :hover": {
                    background: "transparent",
                  },
                },
              }}
            >
              <div className="search-bar-filter">
                <img src={searchIcon} alt="search icon" />
                <input
                  type="text"
                  placeholder={placeHolder}
                  value={text}
                  onChange={onChange}
                />
              </div>
            </MenuItem>
          )}
          {groups &&
            groups
              .filter((group) => group.groupId !== "Sin id" && group.groupName !== "No tiene grupo asignado")
              .map((group) => (
                <MenuItem
                  key={group.groupId}
                  value={group.groupName}
                  sx={{
                    paddingLeft: "0px",
                    maxHeight: "34px",
                    ":focus-visible, :hover": {
                      border: "none",
                      background: "transparent",
                    },
                  }}
                >
                  <Checkbox
                    checked={selectValues[idUser]?.includes(group.groupName)}                
                    sx={{
                      maxHeight: "34px",
                      "&.Mui-checked": {
                        color: "#85BC5B",
                      },
                      "input, svg": {
                        maxWidth: "12px",
                        maxHeight: "12px",
                      },
                    }}
                  />
                  <ListItemText
                    primary={group.groupName}
                    sx={{
                      span: { fontSize: "12px", color: "#000000" },
                      "input, svg": {
                        maxWidth: "12px",
                        maxHeight: "12px",
                      },
                    }}
                  />
                </MenuItem>
              ))}
          <MenuItem
            sx={{
              minHeight: "34px",
              paddingLeft: "10px",
              ".btn-edit-group": {
                color: "#E33AA9",
                border: "none",
                background: "transparent",
                display: "flex",
                gap: "10px",
                alignItems: "center",
              },
              ":focus-visible, :hover": {
                border: "none",
                background: "transparent",
              },
            }}
          >
            <button
              className="btn-edit-group"
              onClick={() => {
                setIsItemListVisible(false);
                setIsEditGroupVisible(true);
              }}
            >
              <img src={settings} className="icon" alt="search icon" />
              {textButton}
            </button>
          </MenuItem>
        </Select>
      )}
      <EditGroup
        token={token}
        show={isEditGroupVisible}
        onClose={() => {
          setIsEditGroupVisible(false);
          setIsItemListVisible(true);
        }}
      />
    </Container>
  );
};

export default Multiselect;
