import styled from "styled-components";
export const InviteUsersContainer = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.35);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 100;
  .modal-enviado{
    max-width: 250px;
    background: none;
  }

  .form-container {
    height: fit-content;
    border-radius: 10px;
    background-color: var(--white-contentoh);
    padding: 15px 20px;
    margin-top: 150px;
    min-width:328px;

    p {
      color: #262626;
      font-family: Roboto;
      font-size: 17px;
    }

    input {
      border: 1px solid #f0f0f0;
      border-radius: 5px;
      height: 28px;
      padding-left: 10px;
    }

    .invitation-container {
      margin-top:10px;
      .invite-provider {
        input {
          width: 200px;

          & + * {
            margin-left: 10px;
          }
        }

        & + * {
          margin-top: 10px;
        }
      }
    }
    .add-invitation {
      background-color: transparent;
      color: var(--pink-contentoh);
      font-family: Roboto;
      font-size: 10px;
      border: none;
      cursor: pointer;
    }

    .email-container {
      input {
        width: 300px;
        border:none;
        font-size: 12px;
        font-family:Roboto, sans-serif;
      }
      input:before{
        border-bottom: 0px solid rgba(0, 0, 0, 0.42);
      }

      & + * {
        margin-top: 10px;
      }
    }

    .inputs-container {
      .input-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-size: 12px;
        }

        input {
          height: 24px;
          font-size: 12px;
        }

        #category-select {
          .button-list {
            padding: 5px 10px;
            border: none;
            border-radius: 3px;

            p {
              color: #808080;
            }

            &:hover {
              background-color: #f0f0f0;
            }
          }
        }

        & + * {
          margin-top: 10px;
        }
      }

      & + * {
        margin-top: 10px;
      }
    }
    .buttons-container {
      display: flex;
      justify-content: flex-end;

      button {
        border-radius: 17px;
        font-family: Roboto;
        font-size: 12px;
        background-color: transparent;
        border: 1px solid var(--pink-contentoh);
        color: var(--pink-contentoh);
        padding: 5px 10px;
        cursor:pointer;
        &:hover{
          background-color: var(--pink-contentoh);
          border: 1px solid var(--pink-contentoh);
          color: var(--white-contentoh);
        }
        & + * {
          margin-left: 10px;
        }

        &.invite-button {
          background-color: var(--pink-contentoh);
          border: 1px solid var(--pink-contentoh);
          color: var(--white-contentoh);
          &:hover{
            background-color: var(--blue-light);
            border: 1px solid var(--pink-contentoh);
            color: var(--pink-contentoh);
          }
        }
      }
    }
  }
`;