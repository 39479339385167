import styled from "styled-components";

export const MainContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #281f3366;
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const MessageContainer = styled.div`
  position: relative;
  padding: 40px 20px;
  box-sizing: border-box;
  width: 600px;
  min-height: ${(props) => (props.height ? "300px" : "190px")};
  background: #281f33;
  border-radius: 39px;
  & > .generic-text {
    width: 100%;
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #f7f7f7;
    margin: 20px 0px;
  }
`;
export const TextArea = styled.textarea`
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 479px;
  height: 84px;
  background: #f0eef2;
  border-radius: 4px;
  color: #817393;
  margin-bottom: 10px;
`;

export const Close = styled.figure`
  position: absolute;
  cursor: pointer;
  right: 45px;
  > img {
    width: 14px;
    height: 14px;
  }
`;

export const Icon = styled.figure`
  margin-bottom: 10px;
  height: ${(props) => props.heightImg && props.heightImg};
  > img {
    width: ${(props) => (props.widthImg ? props.widthImg : "85px")};
    height: ${(props) => (props.heightImg ? props.heightImg : "85px")};
  }
`;
export const BoldText = styled.span`
  font-weight: 600;
`;

export const Message = styled.p`
  width: 100%;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  text-align: center;
  color: #f7f7f7;
  margin: 20px 0px;
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const FirstButton = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 40px;
  border: 1px solid #f0eef2;
  box-sizing: border-box;
  border-radius: 30px;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #f0eef2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
`;

export const Button = styled(FirstButton)`
  border: 0;
  background: #e33aa9;
  cursor: pointer;
`;

export const Detail = styled(Message)`
  font-weight: bold;
  font-size: 18px;
  white-space: normal;
`;
