import styled from "styled-components";
import { GlobalColors } from "../../../global-files/variables";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 1px solid ${GlobalColors.s2};

  .input-name-header {
    overflow-x: auto;
    max-width: 40%;
    white-space: nowrap;
    height: 23px;
    line-height: 23px;
    text-overflow: initial;
  }
  .status-asignation-info {
    display: flex;
    align-items: center;

    * + * {
      margin-left: 5px;
    }
  }

  & + * {
    margin-top: 4px;
  }
`;
