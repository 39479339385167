import { GlobalColors, FontFamily } from "../../../global-files/variables";
import { Button } from "../../atoms/GeneralButton";
import { ScreenHeader } from "../../atoms/ScreenHeader";
import { VersionOptions, EmptyVersion, CreateVersion } from "./styles";
import createVersionImg from "../../../assets/images/versionSelector/createVersion.svg";
import genericModalWarning from "../../../assets/images/genericModal/genericModalWarning.svg";
import { VersionSelect } from "../../atoms/Select/VersionSelect";

export const Children = (
  step,
  setStep,
  versions,
  selectedVersions,
  onChange,
  createVersion
) => {
  switch (step) {
    case "version-options":
      return (
        <VersionOptions>
          <img src={createVersionImg} alt="create version icon" />
          <ScreenHeader
            fontFamily={FontFamily.Raleway_500}
            headerType="product-name-header"
            color={GlobalColors.white}
            text="Crea una nueva versión"
          />
          <ScreenHeader
            fontFamily={FontFamily.Raleway}
            headerType="retailer-name-header"
            color={GlobalColors.white}
            text="Puedes elegir el contenido de versiones anteriores o crear una versión desde cero"
          />
          <div className="buttons-container">
            <Button
              label="Versión desde cero"
              buttonType="general-transparent-button"
              onClick={() => setStep("empty-version")}
            />
            <Button
              label="Versión personalizada"
              buttonType="general-default-button"
              onClick={() => setStep("create-version")}
            />
          </div>
        </VersionOptions>
      );
    case "empty-version":
      return (
        <EmptyVersion>
          <img src={genericModalWarning} alt="warning icon" />
          <ScreenHeader
            fontFamily={FontFamily.Raleway_500}
            headerType="product-name-header"
            color={GlobalColors.white}
            text="Si creas una versión desde cero perderas la información actual"
          />
          <ScreenHeader
            fontFamily={FontFamily.Raleway}
            headerType="retailer-name-header"
            color={GlobalColors.white}
            text="¿Deseas Continuar?"
          />
          <div className="buttons-container">
            <Button
              label="Atrás"
              buttonType="general-transparent-button"
              onClick={() => setStep("version-options")}
            />
            <Button
              label="Continuar"
              buttonType="general-default-button"
              onClick={() => createVersion(true)}
            />
          </div>
        </EmptyVersion>
      );
    case "create-version":
      return (
        <CreateVersion>
          <ScreenHeader
            fontFamily={FontFamily.Raleway_500}
            headerType="retailer-name-header"
            color={GlobalColors.white}
            text="Elige el contenido que deseas utilizar de versiones anteriores"
          />
          <div className="version-selector">
            <div className="selector">
              <VersionSelect
                versions={versions}
                label="Ficha técnica"
                id="datasheet"
                selectedVersions={selectedVersions}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className="selector">
              <VersionSelect
                versions={versions}
                label="Descripción"
                id="description"
                selectedVersions={selectedVersions}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className="selector">
              <VersionSelect
                versions={versions}
                label="Imágenes"
                id="image"
                selectedVersions={selectedVersions}
                onChange={(e) => onChange(e)}
              />
            </div>
          </div>
          <div className="buttons-container">
            <Button
              label="Atrás"
              buttonType="general-transparent-button"
              onClick={() => setStep("version-options")}
            />
            <Button
              label="Continuar"
              buttonType="general-default-button"
              onClick={() => createVersion()}
            />
          </div>
        </CreateVersion>
      );
  }
};
