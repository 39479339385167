import { Container } from "./styles";
import { ScreenHeader } from "../../atoms/ScreenHeader/index";
import { Status } from "../../atoms/Status/index";
import { ProgressBar } from "../../atoms/ProgressBar/index";
// import { PriorityFlag } from "../../atoms/PriorityFlag/index";
import { ObservationFlag } from "../../atoms/ObservationFlag/index";

export const ProductNameHeader = ({
  productName,
  statusType,
  priority,
  date,
  percentRequired,
  productObservation,
  dataProduct,
  isObservationVisible,
  toggleObservation,
  // handleClickOutside,
   hideObservation
}) => {
  const typeFlag =
    productObservation !== null && productObservation !== "" ? "high" : "low";
  return (
    <Container>
      <ScreenHeader headerType={"product-name-header"} text={productName} />
      {/* {statusType && <Status statusType={statusType} />} */}
      <ProgressBar
        percent={percentRequired}
        progressBarType={statusType}
        percentRequired={percentRequired}
      />
      {/* <PriorityFlag priority={priority} /> */}
      <ObservationFlag
        observation={typeFlag}
        contentObservation={productObservation}
        dataProduct={dataProduct}
        isObservationVisible ={isObservationVisible}
        toggleObservation = {toggleObservation}
        // handleClickOutside = {handleClickOutside}
         hideObservation={hideObservation}
      />
      <ScreenHeader headerType={"date-header"} text={date} />
    </Container>
  );
};
