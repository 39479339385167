import React, { useEffect, useState } from "react";
import { Container, TH, TD } from "./styles";
import CheckBox from "../customInputs/CheckBox";
import Tooltip from "../Tooltip";
import CustomField from "../CustomField";
import CustomButton from "../CustomButton";
import ApprovedButton from "../ApprovedButton";
import ImageVisor from "../ImageVisor";
import { useStore } from "../../../stores/useStore";

const TableLarge = React.forwardRef((props, ref) => {
  const {
    initialData,
    sectionsData,
    headerBaseData,
    headerSectionData,
    withCheckbox,
    selectedSection,
    onSave,
    rejectMessage,
  } = props;
  const [rowsChecked, setRowsChecked] = useState([]);
  const [isImageVisorOpen, setImageVisorOpen] = useState(false);
  const [editedFields, setEditedFields] = useState([]);

  const data = initialData.map((item) => ({
    ...item,
  }));

  const {setCurrentData, store, setArticleID} = useStore();

  useEffect(() => {
    setCurrentData(sectionsData[0].dataAttributes);
    setArticleID(sectionsData[0].articleId);
  }, [sectionsData]);

  function handlerSelectAll(evt) {
    if (evt.target.checked) {
      setRowsChecked(data.map(({ articleId }) => articleId));
    } else {
      setRowsChecked([]);
    }
  }

  function handlerSelect(evt, id) {
    if (evt.target.checked) {
      setRowsChecked((prev) => (prev.includes(id) ? prev : [...prev, id]));
    } else {
      setRowsChecked((prev) => prev.filter((f) => f !== id));
    }
  }

  const handleFieldChange = (
    articleId,
    fieldId,
    newValue,
    isApproved,
    comment,
    isAproval
  ) => {
    if (articleId !== undefined && fieldId !== undefined) {
      setEditedFields((prev) => {
        const updatedFields = [...prev];
        let article = updatedFields.find(
          (item) => item.articleId === articleId
        );

        if (!article) {
          // Si el artículo no existe en el estado, se agrega
          article = { articleId: articleId, fields: {} };
          updatedFields.push(article);
        }

        const existingField = article.fields[fieldId] || {};

        article.fields[fieldId] = {
          value: newValue !== undefined ? newValue : existingField.value,
          isApproved:
            isApproved !== undefined ? isApproved : existingField.isApproved,
          comment: comment !== undefined ? comment : existingField.comment,
        };

        return updatedFields;
      });
    } else {
      console.error("articleId o fieldId es undefined", articleId, fieldId);
    }
  };
  
  
  const renderCustomField = (field, typeAccessor, articleId) => {
    const {
      type,
      id_attribute,
      required,
      value,
      comment,
      name_attribute,
      min_chars,
      max_chars,
      isApproved,
      description,
      option_list,
      src,
    } = field;

    const uniqueFieldId = `${articleId}-${id_attribute}`;
    const handleReject = (newIsApproved, newComment) => {
      handleFieldChange(
        articleId,
        id_attribute,
        value,
        newIsApproved,
        newComment,
      );
    };

    const editedFieldEntry = editedFields.find(item => item.articleId === articleId);
    const fieldValue = editedFieldEntry?.fields?.[id_attribute]?.value ?? value;

    return (
      <div className="container-attribute">
        <ApprovedButton
          isApproved={isApproved}
          comment={comment}
          onReject={handleReject}
          id_attribute={id_attribute}
        />
        {typeAccessor === "imageVisor" ? (
          <ImageVisor
            value={src}
            name={value}
            onToggle={() => setImageVisorOpen((prev) => !prev)}
            // onChange={() => handleFieldChange(articleId, id_attribute, src)}
          />
        ) : (
          <CustomField
            id={uniqueFieldId}
            type={type}
            value={fieldValue}
            required={required}
            max_char={max_chars}
            option_list={option_list}
            typeAccessor={typeAccessor}
            onChange={(newValue) =>
              handleFieldChange(articleId, id_attribute, newValue)
            }
            id_attribute={id_attribute}
          />
        )}
      </div>
    );
  };

  const renderRowData = (row, column) => {
    if (
      column.accessor === "customField" ||
      column.accessor === "textArea" ||
      column.accessor === "imageVisor"
    ) {
      const typeAccessor = column.accessor;
      const section = sectionsData.find(
        (section) => section.articleId === row.articleId
      );
      const customFields = section ? section.dataAttributes : [];
      const field = customFields.find(
        (field) => field.name_attribute === column.Header
      );

      if (field) {
        const fieldValue =
          editedFields[row.articleId]?.[field.id_attribute] || field.value;
        return (
          <div key={`${row.articleId}-${field.id_attribute}`}>
            {renderCustomField(
              {
                ...field,
                value: fieldValue,
              },
              typeAccessor,
              row.articleId
            )}
          </div>
        );
      }
      return "-";
    } else {
      return row[column.accessor] || "-";
    }
  };

  const handleSave = () => {
    onSave(editedFields);
  };

  // console.log("sectionsData =>",sectionsData, "headerSectionData =>",headerSectionData);

  return (
    <Container
      withCheckbox={withCheckbox}
      isImageVisorOpen={isImageVisorOpen}
      ref={ref}
    >
      <table className="custom-table">
        <thead>
          <tr>
            {withCheckbox ? (
              <th>
                <CheckBox
                  id="globalHeaderCheckbox"
                  type="checkbox"
                  typeColor="green"
                  checked={rowsChecked.length === data.length}
                  onChange={handlerSelectAll}
                />
              </th>
            ) : null}
            {headerBaseData.map((column, i) => (
              <TH
                key={`base-${column.accessor}-${i}`}
                withCheckbox={withCheckbox}
                {...column}
                width={column.width}
                fixed
                fixedToLeft={column.fixedToLeft}
              >
                <span>{column.Header || column.name_attribute}</span>
              </TH>
            ))}
            <TH key={`btn-header`} width="50px" fixed fixedToLeft={440}></TH>
            {headerSectionData.map((column, i) => (
              <TH
                key={`section-${i}`}
                withCheckbox={withCheckbox}
                {...column}
                fixed={false}
              >
                {sectionsData[0].dataAttributes.map(
                  (attribute) =>
                    attribute.name_attribute === column.Header && (
                      <Tooltip
                        key={attribute.id_attribute}
                        title={attribute.description}
                        position="top"
                        variant="white"
                      >
                        <span>
                          {attribute.name_attribute}{" "}
                          {attribute.required ? "*" : ""}
                        </span>
                      </Tooltip>
                    )
                )}
              </TH>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.articleId}>
              {withCheckbox ? (
                <td>
                  <CheckBox
                    id={row.articleId}
                    className="checkbox_fixed"
                    checked={rowsChecked.includes(row.articleId)}
                    onChange={(e) => handlerSelect(e, row.articleId)}
                    typeColor="green"
                  />
                </td>
              ) : null}
              {headerBaseData.map((column, i) => (
                <TD
                  key={`base-${column.accessor}-${i}`}
                  withCheckbox={withCheckbox}
                  {...column}
                  width={column.width}
                  fixed
                  fixedToLeft={column.fixedToLeft}
                >
                  {renderRowData(row, column)}
                </TD>
              ))}
              <TD key="save" width="50px" fixed fixedToLeft={440}>
                <CustomButton
                  className="button-save"
                  variant="pink"
                  icon="save"
                  iconOnly
                  onClick={handleSave}
                />
              </TD>
              {headerSectionData.map((column, i) => (
                <TD
                  key={`section-${column.accessor}-${i}`}
                  withCheckbox={withCheckbox}
                  {...column}
                  fixed={false}
                >
                  {renderRowData(row, column)}
                </TD>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
});

export default TableLarge;
