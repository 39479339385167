import styled from "styled-components";

export const Container = styled.div`
display:flex;
flex-direction: column;
gap:20px;
width:100%;
ul{
  display:flex;
  flex-direction:column;
  width:100%;
  gap:10px;
}
  .item{
    display:flex;
    width:100%;
  }
  .drag{
    width:100%;
  }
  .container-input-name{
    p{
      font-family: Roboto, sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: left;
      color: var(--gray-contentoh);
      margin-bottom:15px;
    }
    .input-phases{
      width:100%;
      height: 30px;
      padding: 8px 10px;
      border-radius: 5px;
      border: 1px solid var(--gray-light);
      ::placeholder {
        font-family: Roboto, sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        color: #CBCBCB;
      }
    }
  }
  .modal-add-phase {
    .contentModal{
      min-width:310px;
    }
    .container-buttons{
      justify-content: flex-end;
    }
    .btn-save {
      cursor: pointer;
      font-family: Roboto, sans-serif;
      font-size: 12px;
      font-weight: 500;
      border: none;
      background: var(--pink-contentoh);
      color: var( --white-contentoh);
      border-radius: 12px;
      padding: 5px 10px;
      &:hover{
        color: var(--pink-contentoh);
        border: 1px solid var(--pink-contentoh);
        background: var(--blue-light);
      }
    }
  }
  .no-phases-container {
    display: flex;
    flex-direction:column;
    gap:10px;
    align-items: center;
    border-radius:10px;
    border:1px solid var(--gray-light);
    padding:10px 15px;
    font-family:Roboto, sans-serif;
    font-size:12px;
  }
`;

export const Header = styled.div`
  width:100%;
  display: flex;
  justify-content: space-between;
  margin-top:10px;
  .button-phase {
    cursor: pointer;
    padding: 9px 18px;
    background-color: var(--white-contentoh);
    border-radius: 30px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    border: none;
    color: var(--pink-contentoh);
    border: 1px solid var(--pink-contentoh);
    &:hover{
      background: var(--pink-contentoh);
      color: var( --white-contentoh);
    }
  }

  .button-save {
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 500;
    border: none;
    background: var(--pink-contentoh);
    color: var( --white-contentoh);
    border-radius: 30px;
    padding: 9px 18px;
    &:hover{
      color: var(--pink-contentoh);
      border: 1px solid var(--pink-contentoh);
      background: var(--blue-light);
    }
  }

  .modal-save{
    width:20%;
    background:transparent;
  }

  .btn-cancel{
    background: var(--pink-contentoh);
    border: 1px solid var(--pink-contentoh);
    color: var(--white-contentoh);
  }
  .btn-save{
    background:transparent;
    border: 1px solid var(--pink-contentoh);
    color: var(--pink-contentoh);
  }
`;
