import styled, { css } from "styled-components";

export const Container = styled.div`
  background: #fff;
  box-sizing: border-box;
  height: 100%;
  overflow-x: auto;
  width: 100%;
  margin-right: 30px;

  .checkbox_fixed {
    left: 0px;
    position: sticky;
    z-index: 15;
  }

  .custom-table {
    border-collapse: collapse;
    box-sizing: border-box;
    position: relative;
    width: 100%;

    & > thead {
      top: 0;
      height: 45px;
      position: sticky;
      z-index: 1;
      & > tr {
        background-color: var(--blue-light);
        border-radius: 5px 5px 0px 0px;
        height: 45px;
      }
    }

    & > tbody {
      // z-index: 5;
    }

    & > tbody > tr {
      height: auto;
      min-height: 70px;
      &:last-child {
        border-radius: 0px 0px 5px 5px;
      }
    }
  }

  .custom-table th,
  .custom-table td {
    padding: 8px;
    border: 0;
    z-index: ${({ isImageVisorOpen }) => (isImageVisorOpen ? 1 : 10)};
    max-width: 270px;
  }

  .custom-table th {
    border-radius: 5px 0 0 5px;
    border: 0;
    color: var(--black);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    text-wrap: nowrap;
    z-index: ${({ isImageVisorOpen }) => (isImageVisorOpen ? 1 : 10)};
  }

  .custom-table td {
    z-index: 1;
  }

  .custom-table tr {
    background: var(--white-contentoh);
    border-bottom: 1px solid var(--gray-light);
    border-left: 1px solid var(--gray-light);
    border-right: 1px solid var(--gray-light);
    display: flex;
    align-items: center;
    z-index: 5;
    ${({ withCheckbox }) => withCheckbox && `padding-left: 10px;`}
  }
`;

export const TH = styled.th`
  background: var(--blue-light);
  min-height: ${({ height }) => height ?? "auto"};
  min-width: ${({ width }) => width ?? "270px"};
  position: sticky;
  text-align: ${({ fixed }) => (fixed ? 'left' : 'center')};
  left: ${({ fixedToLeft }) => (fixedToLeft >= 0 ? `${fixedToLeft}px` : "20px")};
  z-index: ${({ fixed }) => (fixed ? 15 : 1)};
  ${({ fixedToLeft, withCheckbox }) =>
    fixedToLeft >= 0 &&
    css`
      left: ${withCheckbox ? 20 + fixedToLeft : fixedToLeft}px;
    `}
`;

export const TD = styled.td`
  align-items: center;
  background: var(--white-contentoh);
  box-sizing: border-box;
  display: flex;
  height: 100%;
  min-width: ${({ width }) => width ?? "270px"};
  text-align: center;
  // justify-content: ${({ fixed }) => (fixed ? 'left' : 'center')};
  vertical-align: middle;
  position: sticky;
  left: ${({ fixedToLeft }) => (fixedToLeft >= 0 ? `${fixedToLeft}px` : "20px")};
  z-index: ${({ fixed }) => (fixed ? 15 : 1)};
  text-wrap: nowrap;
  overflow: ${({ fixed }) => (fixed ? 'auto' : 'unset')};

  .content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .button-save {
    padding: 3px;
    width: 24px;
    height: 24px;
    .icon {
      font-size: 18px;
    }
  }

  .container-attribute{
    display: flex;
    gap: 5px;
    align-items: center;
  }

  ${({ fixedToLeft, withCheckbox }) =>
    fixedToLeft >= 0 &&
    css`
      left: ${withCheckbox ? 20 + fixedToLeft : fixedToLeft}px;
    `}
`;
