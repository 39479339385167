import { useState, useCallback } from "react";
import axios from "axios";

const useAcquiredProducts = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const objToQueryString = (params) =>
    Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join("&");

  const postAcquiredProducts = useCallback(async (params = {}) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        process.env.REACT_APP_DROPSHIP_ACQUIRED_ENDPOINT,
        params?.data,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      );
      setData(response.data);
      return response.data;
    } catch (error) {
      setError(error);
      console.error("Error posting acquired products:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const getAcquiredProducts = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DROPSHIP_ACQUIRED_ENDPOINT}?%7B%22upc%22%3A%5B%5D%2C%22providers%22%3A%5B%5D%2C%22category%22%3A%5B%5D%7D`,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      );
      setData(response.data);
      return response.data;
    } catch (error) {
      setError(error);
      console.error("Error getting acquired products:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    error,
    data,
    postAcquiredProducts,
    getAcquiredProducts,
  };
};

export default useAcquiredProducts;
