import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";

// components
import GenericModal from "../../general/GenericModal/index.js";
import Loading from "../../general/loading/index.jsx";
import Modal from "../../general/Modal";
import { ButtonV2 } from "contentoh-components-library/dist/components/atoms/ButtonV2";
import CheckBox from "../../general/customInputs/CheckBox/index.jsx";
import { MainContainer, BasicData } from "./styles.js";
import {
  updateAttributes,
  fetchData,
  handlerSelectAll,
  handleDeselectAll,
  initializeSelectedColumns,
  handleSaveChanges,
  searchAttributes,
} from "./utils.js";

import useNotifications from "../../../hooks/useNotification.jsx";
import searchIcon from "../../../assets/IconComponents/search.svg";
import { CheckboxList } from "../../general/customInputs/CheckboxList";
import CustomNotification from "../../general/CustomNotification/index.jsx";
import { useModal } from "../../../hooks/useModal";
import CustomButton from "../../general/CustomButton/index.jsx";

const AttributesGroup = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [attributesByGroups, setAttributesByGroups] = useState({});
  const [attribute, setAttribute] = useState("");
  const [groupsSelected, setGroupsSelected] = useState([]);
  const [attributesSelected, setAttributesSelected] = useState([]);
  const [groupsTable, setGroupsTable] = useState([]);
  const [attributesGroups, setAttributesGroups] = useState({});
  const [rowsChecked, setRowsChecked] = useState([]);
  const [columnsChecked, setColumnsChecked] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [groupsData, setGroupsData] = useState([]);
  const [atributos, setAtributos] = useState([]);
  const { notifications, addNotification, deleteNotification } = useNotifications();
  const { modalData, openModal, closeModal, handleCancelClick } = useModal();
  const [attributesFilter, setAttributesFilter] = useState([]);
  const [groupsFilter, setGroupsFilter] = useState([]);
  const [modifiedAttributes,setModifiedAttributes] = useState({})

  const [originalAttributesGroups, setOriginalAttributesGroups] = useState({});

  const initializeAttributesData = useCallback(async () => {
    setLoading(true);
    try {
      const data = await fetchData(
        groupsSelected,
        attributesSelected,
        setAttributesFilter,
        setGroupsFilter,
        addNotification
      );
      setGroupsTable(Object.entries(data.groupsByRetailer || []));
      setAttributesGroups(data.attributesByGroups || {});
      setAttributesByGroups(data.attributesByGroups || {});
      setOriginalAttributesGroups(data.attributesByGroups || {});
      initializeSelectedColumns(
        data.attributesByGroups || {},
        setColumnsChecked
      );
    } catch (error) {
      addNotification(
        "Error",
        "Error al recuperar la data de grupo por atributo"
      );
    } finally {
      setLoading(false);
    }
  }, [groupsSelected, attributesSelected, addNotification]);

  useEffect(() => {
    initializeAttributesData();
  }, [initializeAttributesData]);

  // Filtro Grupos
  useEffect(() => {
    setGroupsData((prev) =>
      Array.isArray(prev)
        ? prev.map((item) => ({
            ...item,
            isSelected: groupsSelected.includes(item.id),
          }))
        : []
    );
    setLoading(false);
  }, [groupsSelected]);

  // Filtro Atributos
  useEffect(() => {
    setAtributos((prev) =>
      Array.isArray(prev)
        ? prev.map((item) => ({
            ...item,
            isSelected: attributesSelected.includes(item.id),
          }))
        : []
    );
    setLoading(false);
  }, [attributesSelected]);

  const handleCheckboxChange = (
    groupId,
    attributeId,
    attributeName,
    isChecked,
    setSelectedRows,
    setColumnsChecked,
    setAttributesGroups,
    setAttributesByGroups,
    setModifiedAttributes
  ) => {
    setSelectedRows((prevSelectedRows) => {
      const updatedSelectedRows = isChecked
        ? [...prevSelectedRows, `${attributeId}-${groupId}`]
        : prevSelectedRows.filter((rowId) => rowId !== `${attributeId}-${groupId}`);
      return updatedSelectedRows;
    });
    setColumnsChecked((prevColumnsChecked) => {
      const columnId = `${attributeId}-${groupId}`;
      const updatedColumnsChecked = isChecked
        ? [...prevColumnsChecked, columnId]
        : prevColumnsChecked.filter((id) => id !== columnId);
      return updatedColumnsChecked;
    });
    setAttributesByGroups((prevAttributesByGroups) => {
      const updatedAttributesByGroups = { ...prevAttributesByGroups };
      if (!updatedAttributesByGroups[attributeId]) {
        updatedAttributesByGroups[attributeId] = {
          active: [],
          disable: [],
        };
      }
  
      const groupIdNumber = parseInt(groupId, 10);
      const groupList = isChecked ? "active" : "disable";
      const oppositeGroupList = isChecked ? "disable" : "active";
      if (!updatedAttributesByGroups[attributeId][groupList].includes(groupIdNumber)) {
        updatedAttributesByGroups[attributeId][groupList].push(groupIdNumber);
      }
      updatedAttributesByGroups[attributeId][oppositeGroupList] =
        updatedAttributesByGroups[attributeId][oppositeGroupList].filter(
          (group) => group !== groupIdNumber
        );
  
      setModifiedAttributes((prevModifiedAttributes) => {
        const newModifiedAttributes = { ...prevModifiedAttributes };
  
        if (newModifiedAttributes[attributeId]) {
          newModifiedAttributes[attributeId] = updatedAttributesByGroups[attributeId];
        } else {
          newModifiedAttributes[attributeId] = updatedAttributesByGroups[attributeId];
        }
  
        return newModifiedAttributes;
      });
  
      return updatedAttributesByGroups;
    });
    setAttributesGroups((prevAttributesGroups) => {
      const updatedAttributesGroups = { ...prevAttributesGroups };
      const attribute = updatedAttributesGroups[attributeName];
  
      if (attribute) {
        const groupIndex = attribute.groups.findIndex(g => g.groupId === parseInt(groupId, 10));
        if (isChecked && groupIndex === -1) {
          attribute.groups.push({ groupId: parseInt(groupId, 10), groupName: "" }); 
        } else if (!isChecked && groupIndex !== -1) {
          attribute.groups.splice(groupIndex, 1);
        }
      }
      return updatedAttributesGroups;
    });
  };
  const handleRowCheckboxChange = (
    isChecked,
    attributeId,
    setRowsChecked,
    setSelectedRows,
    setColumnsChecked,
    setAttributesByGroups
  ) => {
    setRowsChecked((prevRowsChecked) => {
      const updatedRowsChecked = isChecked
        ? [...prevRowsChecked, attributeId]
        : prevRowsChecked.filter((id) => id !== attributeId);
      return updatedRowsChecked;
    });
  
    setSelectedRows((prevSelectedRows) => {
      const updatedSelectedRows = isChecked
        ? [...prevSelectedRows, attributeId]
        : prevSelectedRows.filter((id) => id !== attributeId);
      return updatedSelectedRows;
    });
  
    setColumnsChecked((prevColumnsChecked) => {
      let updatedColumnsChecked;
      if (isChecked) {
        updatedColumnsChecked = [
          ...prevColumnsChecked,
          ...groupsTable.map(([groupId]) => `${attributeId}-${groupId}`)
        ];
      } else {
        updatedColumnsChecked = prevColumnsChecked.filter(
          (id) => !id.startsWith(`${attributeId}-`)
        );
      }
      return updatedColumnsChecked;
    });
  
    setAttributesByGroups((prevAttributesByGroups) => {
      const updatedAttributesByGroups = { ...prevAttributesByGroups };
  
      if (!updatedAttributesByGroups[attributeId]) {
        updatedAttributesByGroups[attributeId] = {
          active: [],
          disable: [],
        };
      }
  
      const groupList = isChecked ? "active" : "disable";
      const oppositeGroupList = isChecked ? "disable" : "active";
  
      updatedAttributesByGroups[attributeId][groupList] = groupsTable.map(([id]) =>
        parseInt(id, 10)
      );
  
      updatedAttributesByGroups[attributeId][oppositeGroupList] = [];
  
      setModifiedAttributes((prevModifiedAttributes) => {
        const newModifiedAttributes = { ...prevModifiedAttributes };
        newModifiedAttributes[attributeId] = updatedAttributesByGroups[attributeId];
        return newModifiedAttributes;
      });
  
      return updatedAttributesByGroups;
    });
  };

  const handlerSelectAll = (evt) => {
    openModal({
      className: "modal-masive-change",
      title: "Atención",
      text: "Estas a punto de hacer una acción masiva para seleccionar grupos.",
      customComponent: (<p className="texto">¿Estas seguro?</p>),
      showCancel: true,
      showAccept: true,
      onAcceptClick: () => {
        closeModal();
        if (!evt.target.checked) {
          handleSelectAll();
        } else {
          handleDeselectAll();
        }
      },
    });
  };
  
  const handleSelectAll = () => {
    const allRowIds = Object.values(attributesGroups).map(
      (rowData) => rowData.attributeId
    );
    setRowsChecked(allRowIds);
    setSelectedRows(allRowIds);
    setColumnsChecked((prevColumnsChecked) => {
      const allColumnsIds = groupsTable.reduce((result, [groupId]) => {
        return result.concat(
          allRowIds.map((attributeId) => `${attributeId}-${groupId}`)
        );
      }, []);
      return allColumnsIds;
    });
  
    setAttributesByGroups((prevAttributesByGroups) => {
      const updatedAttributesByGroups = { ...prevAttributesByGroups };
      allRowIds.forEach((attributeId) => {
        updatedAttributesByGroups[attributeId] = {
          active: groupsTable.map(([groupId]) => parseInt(groupId, 10)),
          disable: [],
        };
      });
      setModifiedAttributes((prevModifiedAttributes) => {
        const newModifiedAttributes = { ...prevModifiedAttributes };
        allRowIds.forEach((attributeId) => {
          newModifiedAttributes[attributeId] = {
            active: groupsTable.map(([groupId]) => parseInt(groupId, 10)),
            disable: [],
          };
        });
        return newModifiedAttributes;
      });
  
      return updatedAttributesByGroups;
    });
  };
  
  const handleDeselectAll = () => {
    setRowsChecked([]);
    setSelectedRows([]);
    setColumnsChecked([]);
  
    setAttributesByGroups((prevAttributesByGroups) => {
      const updatedAttributesByGroups = { ...prevAttributesByGroups };
      selectedRows.forEach((attributeId) => {
        updatedAttributesByGroups[attributeId] = {
          active: [],
          disable: groupsTable.map(([groupId]) => parseInt(groupId, 10)),
        };
      });
      setModifiedAttributes((prevModifiedAttributes) => {
        const newModifiedAttributes = { ...prevModifiedAttributes };
        selectedRows.forEach((attributeId) => {
          newModifiedAttributes[attributeId] = {
            active: [],
            disable: groupsTable.map(([groupId]) => parseInt(groupId, 10)),
          };
        });
        return newModifiedAttributes;
      });
  
      return updatedAttributesByGroups;
    });
  };
  
  
  

  return loading ? (
    <Loading />
  ) : (
    <>
      <MainContainer>
        <div className="filters-container">
          <div className="filters">
            <div className="search-user-input">
              <img src={searchIcon} alt="search-icon" />
              <input
                type="text"
                placeholder="Buscar"
                value={attribute}
                onChange={(e) => {
                  const searchText = e.target.value;
                  setAttribute(searchText);
                  searchAttributes(searchText, attributesGroups,setAttributesGroups, originalAttributesGroups, addNotification);              
                }
                }
              />
            </div>
            <CheckboxList
              id="group-select"
              items={Array.from(groupsFilter).map((item) => JSON.parse(item))}
              name="Grupo"
              placeholder="Buscar grupo"
              defaultOption="Todos los grupos"
              onChange={(selectedItems) => {
                setGroupsSelected(
                  selectedItems.length > 0 ? selectedItems : []
                );
              }}
            />
            <CheckboxList
              id="attribute-select"
              items={Array.from(attributesFilter).map((item) =>
                JSON.parse(item)
              )}
              name="Atributo"
              placeholder="Buscar atributo"
              defaultOption="Todos los atributos"
              onChange={(selectedItems) => {
                setAttributesSelected(
                  selectedItems.length > 0 ? selectedItems : []
                );
              }}
            />
          </div>
          <CustomButton variant="pink" label="Guardar Cambios" onClick={() => handleSaveChanges(attributesByGroups, modifiedAttributes, addNotification)}/>
        </div>
        <div className="table-attributes-container">
          <BasicData>
            <div className="row header th-custom">
              <p className="col row justify-between width-atributo elipsis">
                Atributo
                <CheckBox
                  id="globalHeaderCheckbox"
                  typeColor="pink"
                  checked={
                    selectedRows.length ===
                    Object.entries(attributesGroups).length
                  }
                  onChange={(evt) => handlerSelectAll(evt)}
                />
              </p>
              {groupsTable.map(([id, name]) => (
                <p key={id} className="col text_center elipsis">
                  {name}
                </p>
              ))}
            </div>
            {Object.entries(attributesGroups).length > 0 ? (
              Object.entries(attributesGroups).map(
                ([attributeName, { attributeId, groups }]) => (
                  <div key={attributeId} className="row" id={attributeId}>
                    <p className="col row justify-between width-atributo">
                      {attributeName}
                      <CheckBox
                        id={`chk-row-${attributeId}`}
                        className="custom-class"
                        typeColor="pink"
                        disabled={false}
                        checked={selectedRows.includes(attributeId)}
                        onChange={(evt) => {
                          handleRowCheckboxChange(
                           evt.target.checked,
                            attributeId,
                            setRowsChecked,
                            setSelectedRows,
                            setColumnsChecked,
                            setAttributesByGroups,
                            groupsTable,
                            setModifiedAttributes
                        )}}
                      />
                    </p>
                    {groupsTable.map(([groupId, groupName]) => (
                      <p
                        key={`group-column-${groupId}`}
                        className="col text_center"
                      >
                     <CheckBox
                        id={`${attributeId}-${groupId}`}
                        className="custom-class"
                        typeColor="pink"
                        disabled={false}
                        checked={columnsChecked.includes(`${attributeId}-${groupId}`)}
                        onChange={(evt) =>
                          handleCheckboxChange(
                            groupId,
                            attributeId,
                            attributeName,
                            evt.target.checked,
                            setSelectedRows,
                            setColumnsChecked,
                            setAttributesGroups,
                            setAttributesByGroups,
                            setModifiedAttributes
                          )
                        }
                      />

                      </p>
                    ))}
                  </div>
                )
              )
            ) : (
              <div className="no-users">
                <p>No hay atributos con ese grupo.</p>
              </div>
            )}
          </BasicData>
        </div>
        <Modal
          {...modalData}
          onClose={handleCancelClick}
        />
        <CustomNotification
        open={notifications.length > 0}
        alerts={notifications}
        deleteNotification={deleteNotification}
      />
      </MainContainer>
    </>
  );
};

export default AttributesGroup;
