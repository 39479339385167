import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction:column;
  gap:10px;
  align-items: center;
  border-radius:10px;
  border:1px solid var(--gray-light);
  padding:10px 15px;
  font-family:Roboto, sans-serif;
  font-size:17px;

 .header-phase{
  display:flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  h2{
    font-family:Roboto, sans-serif;
    font-size:17px;
  }
  p{
    font-family:Roboto, sans-serif;
    font-size:12px;
  }
 }
 .attributes-container{
  display: flex;
  align-self: flex-start;
  flex-wrap: wrap;
  gap: 10px;
 }
 .phase-sel{
  display: flex;
  align-items: center;
  gap: 10px;
 }
 .button-circle{
  width:30px;
  height:30px;
  padding:2px;
  border-radius:100%;
  min-width:30px;
  border: 1px solid var(--pink-contentoh);
}
.button-circle:hover {
  border: 1px solid var(--pink-contentoh);
}
.text-phase{
  font-family: Roboto, sans-serif;
  font-size:12px;
  color:var(--gray-contentoh);
}
.text-button-container {
  display: flex;
  gap: 10px;
  align-items: center;
  button{
    min-width: 5px;
    cursor: pointer;
  }
}
.modal-next-phase .contentModal {
  max-width: 250px;
  .container-input-name p{
    text-align:center;
  }
}
.gray{
  color: var(--gray-medium);
}
`;
