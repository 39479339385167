import React from "react";
import { Validation } from "./styles";

const PasswordValidation = ({
  isLengthValid,
  hasUpperCase,
  hasLowerCase,
  hasNumber,
  hasSpecialChar,
}) => {
  return (
    <Validation>
      <span className="validation-label">
        <span className={`material-icons ${hasUpperCase ? "check" : "cancel"}`}>
            {hasUpperCase ? "check_circle" : "cancel"}
        </span>
        Usar mayúsculas
      </span>
      <span className="validation-label">
        <span className={`material-icons ${hasLowerCase ? "check" : "cancel"}`}>
            {hasLowerCase ? "check_circle" : "cancel"}
        </span>
        Usar minúsculas
      </span>
      <span className="validation-label">
        <span className={`material-icons ${hasNumber ? "check" : "cancel"}`}>
            {hasNumber ? "check_circle" : "cancel"}
        </span>
        Usar números
      </span>
      <span className="validation-label">
        <span className={`material-icons ${hasSpecialChar ? "check" : "cancel"}`}>
            {hasSpecialChar ? "check_circle" : "cancel"}
        </span>
        Usar caracteres especiales
      </span>
      <span className="validation-label">
        <span className={`material-icons ${isLengthValid ? "check" : "cancel"}`}>
            {isLengthValid ? "check_circle" : "cancel"}
        </span>
        Mínimo 8 caracteres
      </span>
    </Validation>
  );
};

export default PasswordValidation;
