import "../../../global-files/global-styles.css";
import styled from "styled-components";
import acceptIcon from "../../../assets/images/generalButton/acceptIcon.svg";
import rejectIcon from "../../../assets/images/generalButton/rejectIcon.svg";
import nullIcon from "../../../assets/images/generalButton/nullIcon.svg";
import openModalIcon from "../../../assets/images/generalButton/openModal.svg";
import closeIcon from "../../../assets/images/generalButton/closeIcon.svg";
import closeIconv2 from "../../../assets/images/generalButton/closeIconv2.svg";
import closeIconWhite from "../../../assets/images/generalButton/closeIconWhite.svg";
import rowsLayout from "../../../assets/images/generalButton/rowLayout.svg";
import gridLayout from "../../../assets/images/generalButton/gridLayout.svg";
import arrowIcon from "../../../assets/images/arrow/arrowIcon.svg";
import saveIcon from "../../../assets/images/generalButton/saveIcon.svg";
import saveIconHover from "../../../assets/images/generalButton/saveIconHover.svg";
import deleteIcon from "../../../assets/images/generalButton/deleteIcon.svg";
import deleteIconHover from "../../../assets/images/generalButton/deleteIconHover.svg";
import downloadIcon from "../../../assets/images/generalButton/downloadIcon.svg";
import { GlobalColors } from "../../../global-files/variables";

export const Container = styled.button`
  background-color: #e33aa9;
  border: none;
  border-radius: 30px;
  color: #fff;
  font-family: ${(props) => props.buttonFont};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  padding: 10px 25px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &.general-purple-button {
    background-color: #603888;
  }

  &.general-transparent-button {
    background-color: transparent;
    border: 1px solid #503d66;
    color: #503d66;
  }
  &.general-white-button {
    background-color: ${GlobalColors.s1};
    color: ${GlobalColors.original_purpura};
  }

  &.general-green-button {
    background-color: #71de56;
  }

  &.circular-button {
    width: 25px;
    height: 25px;
    padding: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;

    &.accept-button {
      background-image: url(${acceptIcon});
    }

    &.reject-button {
      background-image: url(${rejectIcon});
    }

    &.null-button {
      background-image: url(${nullIcon});
    }

    &.save-button {
      background-image: url(${saveIcon});
      border: 1px solid ${GlobalColors.s3};
      border-radius: 15px;

      &:hover {
        background-image: url(${saveIconHover});
        background-color: ${GlobalColors.magenta_s2};
        border: 1px solid ${GlobalColors.magenta_s2};
      }
    }

    &.delete-button {
      background-image: url(${deleteIcon});
      border: 1px solid ${GlobalColors.s3};
      border-radius: 15px;
      background-size: 15px;

      &:hover {
        background-image: url(${deleteIconHover});
        background-color: ${GlobalColors.magenta_s2};
        border: 1px solid ${GlobalColors.magenta_s2};
      }
    }

    &.download-button {
      background-image: url(${downloadIcon});
      border: 1px solid ${GlobalColors.s3};
      border-radius: 15px;
      background-size: 15px;

      &:hover {
        color: white;
        background-color: ${GlobalColors.magenta_s2};
        border: 1px solid ${GlobalColors.magenta_s2};
      }
    }

    &.disabled {
      opacity: 0.5;
    }

    img {
      width: 100%;
    }

    &.version-button {
      color: ${GlobalColors.s3};
      border: 1px solid ${GlobalColors.s3};
      border-radius: 15px;
      font-size: 12px;
      line-height: 25px;
      text-align: center;
    }
  }

  &.general-arrow-button {
    background-image: url(${arrowIcon});
    width: 27px;
    height: 27px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
  }

  &.close-button {
    width: 52px;
    height: 52px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${closeIcon});
  }

  &.close-buttonv2 {
    width: 52px;
    height: 52px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${closeIconv2});
  }

  &.close-button-white {
    width: 52px;
    height: 52px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${closeIconWhite});
  }

  &.open-modal-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 10px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    width: 21px;
    height: 21px;
    padding: 0;
    border-radius: 0;
    background-image: url(${openModalIcon});
  }

  &.grid-layout,
  &.row-layout {
    background-color: transparent;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    padding: 0;
  }

  &.grid-layout {
    background-image: url(${rowsLayout});
  }

  &.row-layout {
    background-image: url(${gridLayout});
  }

  &.general-button-disabled {
    background-color: grey;
    color: ${GlobalColors.white};

    &:hover {
      opacity: 1;
    }
  }
  &.general-button-rec {
    background:#85BC5B;
    color: ${GlobalColors.white};
    padding: 4px 5px;
    border-radius: 3px;
    font-size: 10px;
    height: 20px;
    line-height: 12px;
    text-align: center;
  }

  &.general-button-sol {
    background-color: #e33aa9;
    border: none;
    border-radius: 50px;
    color: ${GlobalColors.white};
    height: 34px;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    padding: 10px 15px;
    cursor: pointer;
  }
`;

