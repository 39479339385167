import { Auth } from "aws-amplify";
import axios from "axios";

export const authenticateUser = async (email, password) => {
  try {
    const user = await Auth.signIn(email, password); 
    return user;
  } catch (error) {
    throw error;
  }
};

export const getUserDetails = async (token) => {
  try {
    const response = await axios.get(process.env.REACT_APP_USER_ENDPOINT, {
      headers: {
        Authorization: token,
      },
    });
    const responseBody = JSON.parse(response.data.body);
    const userDetails = responseBody.data[0];
    const companyDetails = responseBody.data[1];
    return { user: userDetails, company: companyDetails };
  } catch (error) {
    throw error;
  }
};


export async function signUp(userData, userType, isOnboarding, retailerId, retailerUserId) {
  let username = userData.email;
  let password = userData.password;
  let email = userData.email;
  let phone_number = "";
  let name = `${userData.name} ${userData.lastName}`;
  userData.userType = userType;

  userData.isOnboarding = isOnboarding;
  userData.retailerId = retailerId;
  userData.retailerUserId = retailerUserId;

  console.log("datos",userData)

  try {
    const user = await Auth.signUp({
      username,
      password,
      attributes: { email, phone_number, name },
    });

    userData.userSub = user.userSub;
    if (user.userSub) {
      await axios.post(
        `${process.env.REACT_APP_USER_ENDPOINT}?isUser=1`,
        userData
      );
      return { success: true };
    }
  } catch (err) {
    let error;
    switch (err.code) {
      case "UsernameExistsException":
        error = "emailExists";
        break;
      case "InvalidParameterException":
        error = "phoneFormatError";
        break;
      default:
        error = "error";
    }
    return { success: false, error };
  }
}


export async function confirmSignUp(userEmail, confirmationCode) {
  try {
    await Auth.confirmSignUp(userEmail, confirmationCode);
    return { success: true };
  } catch (err) {
    return { success: false, error: err.code };
  }
}

export async function forgotPassword(username, code, newPassword) {
  try {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
    return { success: true };
  } catch (err) {
    let error;
    switch (err.code) {
      case "CodeMismatchException":
        error = "Código de confirmación incorrecto";
        break;
      case "LimitExceededException":
        error = "Has intentado demasiadas veces, intentalo despues";
        break;
      case "ExpiredCodeException":
        error = "Código de confirmación expirado";
        break;
      default:
        error = "error";
    }
    return { success: false, error };
  }
}

export async function confirmPasswordChange(user, newPassword) {
  let name = "Colaborador";
  let phone_number = "+521111";
  try {
    await Auth.completeNewPassword(user, newPassword, { name, phone_number });
    return { success: true };
  } catch (err) {
    return { success: false, error: err.code };
  }
}
