import styled from "styled-components";
import checked from "../../../assets/checkBox/checked.svg";
import unchecked from "../../../assets/checkBox/unchecked.svg";

export const MainContainer = styled.div`
  border: 1px solid var(--gray-light-secondary);
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
`;

export const Section = styled.div`
  width: 95%;
  border-bottom: 1px solid var(--gray-light-secondary);
  padding: 14px 0px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  p {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: var(--gray-contentoh);

    &.title {
      font-weight: 500;
    }
  }
`;

export const CheckNotification = styled(Section)`
  .item {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #503d66;
  }
  .custom-radio-checkbox {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-family: arial;
  }
  .disabled {
    cursor: default;
  }
  .icon-checkbox-show {
    background-image: url(${unchecked});
  }
  .custom-radio-checkbox > .custom-radio-checkbox__input {
    display: none;
  }
  .custom-radio-checkbox > .icon-checkbox {
    display: inline-block;
    width: 17px;
    height: 16px;
    margin-right: 7px;
    background-size: cover;
  }
  .custom-radio-checkbox
    > .custom-radio-checkbox__input:checked
    + .icon-checkbox-show {
    background-image: url(${checked});
  }
`;

export const AboutMe = styled(Section)`
  box-sizing: border-box;
  .title {
    font-weight: bold;
    color: #503d66;
  }
`;

export const SelectFile = styled.input`
  color: transparent;
  display: block;
  cursor: pointer;
  border: 1px solid var(--pink-contentoh);
  box-sizing: border-box;
  border-radius: 14px;
  color: #222;
  display: inline-block;
  padding: 6px 40px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--pink-contentoh);
  max-width:150px;
  &::-webkit-file-upload-button {
    visibility: hidden;
  }
  ::before {
    content: "Subir imagen";
  }
  :hover {
    background: var(--pink-contentoh);
    color: var(--white-contentoh);
  }
`;

export const ProfileImage = styled.div`
  width: 83px;
  height: 83px;
  .img {
    border-radius: 50%;
    width: 83px;
    height: 83px;
    object-fit: cover;
  }
`;

export const ImageContainer = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  height: 85px;

  .containerSelectFile {
    min-width: 200px;
  }
`;

export const ContainerPlan = styled.section`
  display: flex;
  flex-direction:column;
  width: 100%;
`;

export const BasicData = styled(Section)`
.country-select {
  width: 190px;
  height: auto;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border: 1px solid var(--gray-light);
  padding: 2px 7px;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
}
  div {
    height: 28px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    .title,
    .item {
      width: 35%;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.015em;
      color: #503d66;
      & + p {
        color: #817393;
      }
    }

    h4 {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.015em;
      color: #503d66;
    }

    figure {
      margin: 0;
      & + * {
        margin-left: 13px;
      }
    }
  }

  #inputSelect div {
    display: flex;
    place-items:start;
    flex-direction: column;
    left: -15px;
    height: auto;
  }
`;