import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import MyAccount from "../../../assets/IconComponents/MyAccount.svg";
import AccountLogOut from "../../../assets/IconComponents/AccountLogOut.svg";
import { Redirect } from "react-router-dom";
import {
  ContainerHeader,
  UserInfo,
  Container,
  ContainerInfo,
  HeaderTitle,
  ProfileMenu,
  ProfileNavLink,
} from "./styles.js";
import { DashboardRouters } from "../../../routers/DashboardRouters.js";
import { useDocTitle } from "../../../hooks/useTitles.js";
import { useDescriptionPage } from "../../../hooks/useDescriptionPage.js";
import GenericModal from "../../general/GenericModal";
import ReactImageFallback from "react-image-fallback";
import spinner from "../../../assets/defaultImages/Spinner.gif";
import { getRoleUser, getProfilePicture } from "./utils.js";
import CustomNotification from "../../general/CustomNotification";
import useNotifications from "../../../hooks/useNotification.jsx";
import defaultProfile from "../../../assets/defaultImages/defaultProfile.png";
import ButtonLang from "../../general/ButtonLang/index.jsx";
import CustomButton from "../../general/CustomButton/index.jsx";

export const ContainerDashboard = () => {
  const [doctitle, setDocTitle] = useDocTitle(
    sessionStorage.getItem("title")
      ? sessionStorage.getItem("title")
      : "Dashboard"
  );
  const [description, setDescription] = useDescriptionPage(
    sessionStorage.getItem("description")
      ? sessionStorage.getItem("description")
      : ""
  );
  const history = useHistory();
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem("user")));
  const [display, setdisplay] = useState(false);
  const [notifyMenu, setCount] = useState(false);
  const [unreadNotification, setUnreadNotification] = useState(false);
  const [productsLimitReached, setProductsLimitReached] = useState(false);
  const [upgradePlanRedirect, setUpgradePlanRedirect] = useState(false);
  const {
    notifications,
    notificationOpen,
    addNotification,
    deleteNotification,
    setNotificationOpen,
  } = useNotifications();
  const [profileImage, setProfileImage] = useState(spinner);
  const [imageUrl, setImageUrl] = useState(defaultProfile);

  const [show, setShow] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showGenericModal, setShowGenericModal] = useState(false);
  const [title, setTitle] = useState({});

  const isValidSrc = (url) => {
    return url && url.startsWith("https://d24s337exbt5zr.cloudfront.net/");
  };

  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        const url = getProfilePicture(user.id_user, 'png', 120, 120, addNotification);
        if (isValidSrc(url)) {
          const profileImagePath = url.replace("https://d24s337exbt5zr.cloudfront.net/", "");
          setImageUrl(`https://d24s337exbt5zr.cloudfront.net/${profileImagePath}`);
          const updatedUser = { ...user, src: profileImagePath };
          sessionStorage.setItem('user', JSON.stringify(updatedUser));
        } else {
          setImageUrl(defaultProfile);
        }
      } catch (err) {
        console.error("Error fetching profile image:", err);
        addNotification("error", "Error al obtener la foto de perfil");
        setImageUrl(defaultProfile);
      }
    };
  
    if (user && user.id_user) { 
      fetchProfileImage();
    }
  }, [user.id_user, user.src]);
  
  const checkImage = 1000;
  useEffect(() => {
    const intervalImg = setInterval(() => {
      const storedUser = JSON.parse(sessionStorage.getItem("user"));
      setUser(storedUser);
    }, checkImage);

    return () => clearInterval(intervalImg);
  }, [user, checkImage]);

  useEffect(() => {
    title.title = sessionStorage.getItem("title");
    title.info = sessionStorage.getItem("description");
    setTitle(title);
  }, [title]);

  const openMenuUser = (e) => {
    if (
      !e.target.closest("#menu-user") &&
      display &&
      window.location.pathname !== "/DashboardContentoh"
    ) {
      document.removeEventListener("click", openMenuUser, false);
      setdisplay(false);
    }
  };

  useEffect(() => {
    if (display) {
      document.addEventListener("click", openMenuUser, false);
    }
  }, [display]);

  useEffect(() => {
    if (upgradePlanRedirect) {
      setProductsLimitReached(false);
      setUpgradePlanRedirect(false);
    }
  }, [upgradePlanRedirect]);

  return (
    <>
      <Container>
        <ContainerHeader>
          <ContainerInfo>
            <HeaderTitle>{doctitle}</HeaderTitle>
          </ContainerInfo>
          <div className="container-buttons">
            <CustomButton
              icon="shopping_bag"
              variant="gray"
              iconOnly={true}
              onClick={() => {
                setShow(true);
              }}
              pulsating={true}
              showTooltip={true}
              tooltipText="Carrito"
            />
            <CustomButton
              icon="notifications"
              variant="gray"
              iconOnly={true}
              onClick={() => {
                setCount(true);
              }}
              pulsating={true}
              showTooltip={true}
              tooltipText="Notificaciones"
            />
            <UserInfo
              id="menu-user"
              onClick={(e) => {
                setdisplay(!display);
                if (display)
                  document.removeEventListener("click", openMenuUser, false);
                e.stopPropagation();
              }}
            >
              <div className="avatar-container">
                <ReactImageFallback
                  src={imageUrl}
                  fallbackImage="fallback-image-url"
                  initialImage={spinner}
                  alt="avatar"
                  className="avatar"
                />
              </div>
              {display && (
                <ProfileMenu>
                  <div className="title-role">
                    <p>{getRoleUser(user.id_role, user.is_retailer)}</p>
                  </div>
                  <div className="title-role">
                    <p>{`${user.name} ${user.last_name}`}</p>
                    <p>{user.position}</p>
                  </div>
                  <ProfileNavLink to="/profile">
                    <div className="icon-container">
                      <img src={MyAccount} alt="My account circle" />
                    </div>
                    <p>Mi cuenta</p>
                  </ProfileNavLink>
                  <ProfileNavLink
                    to="#"
                    onClick={(event) => {
                      event.preventDefault();
                      setShowGenericModal(!showGenericModal);
                    }}
                  >
                    <div className="icon-container">
                      <img src={AccountLogOut} alt="log out icon" />
                    </div>
                    <p>Cerrar sesión</p>
                  </ProfileNavLink>
                </ProfileMenu>
              )}
            </UserInfo>
          </div>
        </ContainerHeader>
        <DashboardRouters
          setShowNotification={setShowNotification}
          setDocTitle={setDocTitle}
          setDescription={setDescription}
        />
        {productsLimitReached && (
          <GenericModal
            close={() => setProductsLimitReached(!productsLimitReached)}
            message="Excediste el número máximo de productos permitidos en tu plan"
            button1={{
              name: "Entendido",
              action: () => setProductsLimitReached(!productsLimitReached),
            }}
            button2={{
              name: "Cambiar de plan",
              action: () => {
                setUpgradePlanRedirect(true);
              },
            }}
          />
        )}
        {upgradePlanRedirect && (
          <Redirect
            to={{
              pathname: `/upgradeplan`,
            }}
          />
        )}
      </Container>
      {showGenericModal && (
        <GenericModal
          close={() => setShowGenericModal(!showGenericModal)}
          message="¿Estás seguro que quieres cerrar sesión?"
          button1={{
            name: "Cancelar",
            action: () => setShowGenericModal(!showGenericModal),
          }}
          button2={{
            name: "Cerrar sesión",
            action: async () => {
              try {
                await Auth.signOut({ global: true });
                sessionStorage.clear();
                history.push("/login");
                window.location.reload();
              } catch (error) {}
            },
          }}
        />
      )}
      <CustomNotification
        open={notifications.length > 0}
        alerts={notifications}
        deleteNotification={deleteNotification}
      />
    </>
  );
};
