import { Container } from "./styles";
import { ProductImage } from "../../atoms/ProductImage/index";

export const ImageSelector = ({
  images = [],
  setActiveImage,
  modalSelector,
}) => {
  return (
    <Container className={modalSelector && "modal-image-selector"}>
      {images?.values?.map((image, index) => (
        <ProductImage
          key={index}
          img={image.src}
          altText={image.altText}
          imageType={modalSelector ? "catalogue-modal-image" : "carousel-image"}
          onClick={() => setActiveImage(index)}
        />
      ))}
    </Container>
  );
};
