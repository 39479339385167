import styled from "styled-components";
import { GlobalColors, FontFamily } from "../../../global-files/variables";

export const Container = styled.div`
  background-color: ${GlobalColors.s2};
  width: max-content;
  padding: 8px;
  border: 1px solid ${GlobalColors.s3};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 100;

  button {
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    font-family: ${FontFamily.AvenirNext};
    color: ${GlobalColors.s4};
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;

    img {
      & + p {
        margin-left: 7px;
      }
    }

    &:hover {
      background-color: ${GlobalColors.s3};
      border-radius: 10px;
    }

    & + * {
      margin-top: 8px;
    }
  }
`;
