import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ErrorLabel from "../../general/ErrorLabel";
import {
  FormularioRegister,
  Row,
  ItemRegister,
  RowButton,
  Button,
  Item,
} from "../styles";
import Logo from "../../general/Logo";
import { confirmSignUp } from "../utils/AuthService";
import CustomNotification from "../../general/CustomNotification";
import useNotifications from "../../../hooks/useNotification";

const ValidationCode = ({ email, confirmationError}) => {
  const [code, setCode] = useState("");
  const [emptyCode, setEmptyCode] = useState(true);
  const [showErrors, setShowErrors] = useState(false);
  const [resend, setResend] = useState(false);
  const [awsError, setAwsError] = useState("");
  const { notifications, addNotification, deleteNotification } = useNotifications();
  const history = useHistory();

  useEffect(() => {
    if (confirmationError !== "") {
      setEmptyCode(false);
    }
  }, [confirmationError]);

  const checkCode = async (e) => {
    e.preventDefault();
    setShowErrors(true);
    let valid = true;
  
    if (code.length > 0) {
      try {
        const result = await confirmSignUp(email, code);
  
        if (result.success) {
          addNotification(
            "success",
            "Código de verificación válido"
          );
          history.push("/profile");
        } else {
          addNotification(
            "error",
            `Error: ${result.error}`
          );
          setAwsError(result.error);
          valid = false;
        }
      } catch (err) {
        addNotification(
          "error",
          "Error al verificar el código de confirmación"
        );
        console.error("Error confirmando el código:", err);
        valid = false;
      }
    } else {
      setEmptyCode(true);
      valid = false;
    }
  
    if (valid && !sessionStorage.getItem("resetPasswordProcess")) {
      history.push("/Dashboard");
    } else if (valid && sessionStorage.getItem("resetPasswordProcess")) {
      history.push("/resetPasswordStep");
    }
  };
  

  const validateResend = async (e) => {
    e.preventDefault();
    const email = JSON.parse(sessionStorage.getItem("email"));
    try {
      sessionStorage.setItem("email", JSON.stringify(email));
      sessionStorage.setItem("resetPasswordProcess", JSON.stringify("true"));
      setResend(true);
      addNotification(
        "success",
        "Se ha reenviado el correo de verificación"
      );
    } catch (err) {
      setAwsError("InternalErrorException");
      console.log(err.message);
      addNotification(
        "error",
        "Error al reenviar el correo de verificación"
      );
    }
  };

  return (
    <FormularioRegister>
      <Logo />
      <div className="container-register">
        <p className="title">Código de verificación</p>
        <Row>
          <label>Ingrese el código de verificación enviado a su correo</label>
          <ItemRegister>
            <input
              required
              id="codeInput"
              placeholder="XXX-XXX"
              type="number"
              value={code}
              min="0"
              max="6"
              onChange={(e) => {
                setCode(e.target.value);
                setEmptyCode(e.target.value.trim() === "");
              }}
            />
          </ItemRegister>
        </Row>
        <ErrorLabel
          showErrors={showErrors}
          condition={emptyCode}
          message="Ingrese su código de verificación"
        />
        {awsError === "LimitExceededException" && !emptyCode && (
          <ErrorLabel
            showErrors={showErrors}
            condition={true}
            message="Haz realizado demasiados intentos, intentalo más tarde"
          />
        )}
        {awsError === "CodeMismatchException" && !emptyCode && (
          <ErrorLabel
            showErrors={showErrors}
            condition={true}
            message="Código de verificación incorrecto"
          />
        )}
        {awsError === "ExpiredCodeException" && !emptyCode && (
          <ErrorLabel
            showErrors={showErrors}
            condition={true}
            message="El código ingresado está expirado"
          />
        )}
        {awsError === "InternalErrorException" && !emptyCode && (
          <ErrorLabel
            showErrors={showErrors}
            condition={true}
            message="Algo salió mal, por favor vuelva a intentarlo"
          />
        )}
        <RowButton>
          <Button type="button" onClick={(e) => checkCode(e)}>
            Enviar
          </Button>
        </RowButton>
      </div>
      <Row>
        <Item>
          <Button type="button" onClick={validateResend}>
            Reenviar código de verificación
          </Button>
        </Item>
      </Row>
      <CustomNotification
        open={notifications.length > 0}
        alerts={notifications}
        deleteNotification={deleteNotification}
      />
    </FormularioRegister>
  );
};

export default ValidationCode;
