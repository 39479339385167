import styled from "styled-components";

export const Formulario = styled.div`
  display:flex;
  flex-direction:column;
  gap:30px;
  .container-formulario{
    background: var(--white-contentoh);
    border:1px solid #F0F0F0;
    box-shadow: 0px 2px 4px 0px #00000040;
    border-radius:10px;
    padding:15px;
    display:flex;
    flex-direction:column;
    gap:15px;
  }
  .title{
    font-family: Roboto;
    font-size:17px;
    font-weight:600;
    color:#262626;
  }
`;

export const ContainerForgot = styled.div`
  display: flex;
  justify-content: center;
`;

export const FormularioRegister = styled.div`
  display:flex;
  flex-direction:column;
  gap:30px;
  width:70%;
  .container-register{
    background: var(--white-contentoh);
    border:1px solid #F0F0F0;
    box-shadow: 0px 2px 4px 0px #00000040;
    border-radius:10px;
    padding:15px;
    display:flex;
    flex-direction:column;
    gap:15px;
  }
  .title{
    font-family: Roboto;
    font-size:17px;
    font-weight:600;
    color:#262626;
  }
  label{
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
    text-align: left;
    color: var(--gray-contentoh);
  }
  .error{
    font-family: Roboto;
    font-size: 10px;
    font-weight: 500;
    line-height: 11.72px;
    text-align: left;
    color: #B64545;
  }
`;

export const FormularioForgot = styled.div`
  display:flex;
  flex-direction:column;
  gap:30px;
  width:70%;
  .container-formulario{
    background: var(--white-contentoh);
    border:1px solid #F0F0F0;
    box-shadow: 0px 2px 4px 0px #00000040;
    border-radius:10px;
    padding:15px;
    display:flex;
    flex-direction:column;
    gap:15px;
  }
  .title{
    font-family: Roboto;
    font-size:17px;
    font-weight:600;
    color:#262626;
  }
`;

export const TextUnderImage = styled.div`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "17px")};
  font-family: Roboto;
  color: ${({ color }) => (color ? color : "#262626")};
  display: flex;
  line-height: 36px;
  justify-content: center;
  align-items: center;

  > p {
    text-align: center;
    width: 80%;
    margin: 0;
    font-family: Roboto;
    font-size: 17px;
    font-weight: 400;
    line-height: 19.92px;
    text-align: center;
    color: #262626;

  }
`;

export const VersionFooter = styled.div`
  position: fixed;
  top: 97%;
  left: 96%;
  z-index: 15;
  height: 90px;
  width: 100%;
  pointer-events: none;
  color: var(--white-contentoh);
`;

export const StepsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    margin: 1.3%;
  }
`;
export const MainContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
`;
export const GraphicPanel = styled.div`
  width: 50%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .image-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    max-height: 700px;
    gap:10px;

    figure {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 0;
    }
  }
  background: ${({ color }) =>
    color ? color : "linear-gradient(215.01deg, #F13A43 8.44%, #991D1B 90.59%)"};
  /* background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0.887706) 49.27%,
      rgba(255, 255, 255, 0) 100%
    ),
    #d43594; */

    @media (max-width: 768px) {
      width: 100%;
      .image-container {
        flex-direction: column; 
      }
    }
`;
export const FormPanel = styled.div`
  width: 50%;
  overflow-y: auto;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .loginLabel {
    width: 92%;
    display: flex;
    justify-content: flex-end;
    color: #b12d84;
    :hover {
      cursor: pointer;
    }
  }

  > figure {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    > img {
      height: 62.14px;
      width: 63.8%;
    }
  }
`;
export const FormContainer = styled.form`
  max-width: 460px;
  height: 80%;
  margin: 0 auto;

  h3 {
    color: ${(props) => (props.paso === 3 ? "black" : "#707070")};
    font-size: 24px;
    margin-top: 3.8%;
    margin-bottom: 3.8%;
    font-weight: ${(props) => (props.paso === 3 ? "600" : "normal")};
  }

  p {
    color: var(--gray-contentoh);
    font-size: 14px;
    margin-top: 3.8%;
    margin-bottom: 3.8%;
    font-weight: normal;
  }

  > p:last-child {
    margin-top: 0.6%;
  }
`;
export const Item = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  .checkbox {
    width: 18px;
    height: 18px;
    margin-right: 14px;
  }
  .checkboxLabel {
    font-family:Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 24px;
    color: var(--gray-contentoh);
    :hover {
      cursor: pointer;
    }
  }
  p {
    margin-bottom: 10px;
    width: 100%;
  }
  input {
    height: 30px;
    width: 100%;
    border: 0.5px solid rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    border-radius: 3px;
    padding: 0 11px;
    font-family: Lato;
  }

  label {
    font-size: 11px;
    color: red;
    font-weight: 700;
  }
`;
export const LongItem = styled.div`
  width: 100%;
  display:flex;
  border-radius:5px;
  border: 1px solid #F0F0F0;
  padding:0px 10px;
  align-items: center;
  max-width:288px;
  p {
    margin-bottom: 10px;
    width: 100%;
  }
  .gray{
    color: #CBCBCB;
    font-size:18px;
  }
  input {
    height: 30px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 11px;
    font-family: Roboto;
    color: #817393;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    padding: 10px;
    border:none;
    outline: none;
    resize: none;
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  select {
    width: 100%;
    height: 30px;
  }
  label {
    font-size: 11px;
    color: red;
    font-weight: 700;
  }
  .resendTrue {
    color: green;
  }
  .password {
    & + * {
      margin-top: 20px;
    }
    &:hover {
      border: 1px solid var(--pink-contentoh);
    }
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    position: relative;
    width: 100%;
    border: 1px solid #f0eef2;
    .icon {
      position: absolute;
      height: 60%;
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      opacity: 0.3;
      cursor: pointer;
    }
    .icon:hover {
      opacity: 0.8;
    }
  }
`;
export const ItemRegister = styled.div`
  width: 100%;
  display:flex;
  border-radius:5px;
  border: 1px solid #F0F0F0;
  padding:0px 10px;
  align-items: center;
  max-width:288px;
  p {
    margin-bottom: 10px;
    width: 100%;
  }
  .gray{
    color: #CBCBCB;
    font-size:18px;
  }
  input {
    height: 30px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 11px;
    font-family: Roboto;
    color: #817393;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    padding: 10px 0;
    border:none;
    outline: none;
    resize: none;
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::placeholder {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.06px;
      text-align: left;
      color:#CBCBCB;
    }
  }
  select {
    width: 100%;
    height: 30px;
  }
  label {
    font-size: 12px;
    color: red;
    font-weight: 400;
    color: var(--gray-contentoh);
  }
  .resendTrue {
    color: green;
  }
  .password {
    & + * {
      margin-top: 20px;
    }
    &:hover {
      border: 1px solid var(--pink-contentoh);
    }
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    position: relative;
    width: 100%;
    border: 1px solid #f0eef2;
    .icon {
      position: absolute;
      height: 60%;
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      opacity: 0.3;
      cursor: pointer;
    }
    .icon:hover {
      opacity: 0.8;
    }
  }
`;
export const ItemLabel = styled.div`
  width: 100%;
  display:flex;
  padding:0px 10px;
  align-items: center;
  max-width:288px;
  label {
    font-size: 12px;
    font-weight: 400;
    color: #fff;
  }
  span{
    cursor:pointer;
    text-decoration:none;
    :hover{
      text-decoration: underline;
      font-weight: 600;
    }
  }
`;
export const ItemSelect = styled.div`
  width: 100%;
  display:flex;
  padding:0px;
  align-items: center;
  max-width:288px;
  button{
    border: 1px solid rgb(240, 240, 240);
    border-radius: 5px;
    color: #CBCBCB;
    span{
      padding:0px;
      gap:5px;
    }
  }
  p {
    margin-bottom: 10px;
    width: 100%;
  }
  .gray{
    color: #CBCBCB;
    font-size:18px;
  }
  input {
    height: 30px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 11px;
    font-family: Roboto;
    color: #817393;
    margin: auto 10px!important;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    padding: 10px 0;
    border:none;
    outline: none;
    resize: none;
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::placeholder {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.06px;
      text-align: left;
      color:#CBCBCB;
    }
  }
  select {
    width: 100%;
    height: 30px;
  }
  label {
    font-size: 12px;
    color: red;
    font-weight: 400;
    color: var(--gray-contentoh);
  }
  .container-def{
    max-height: 300px;
  }
`;
export const Validation = styled.div`
  width: 90%;
  place-content: end;
  place-self: end;
  display:flex;
  flex-wrap:wrap;
  border-radius:5px;
  align-items: center;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  line-height: 11.72px;
  text-align: left;
  color: var(--gray-contentoh);
  gap: 5px;

  .validation-label{
    display:flex;
    gap:5px;
  }

  .check {
    font-size:12px;
    color: green;
  }

  .cancel {
    font-size:12px;
    color: red;
  }
  p {
    margin-bottom: 10px;
    width: 100%;
  }
  .gray{
    color: #CBCBCB;
    font-size:18px;
  }
  input {
    height: 30px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 11px;
    color: #817393;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    padding: 10px;
    border:none;
    outline: none;
    resize: none;
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::placeholder {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.06px;
      text-align: left;
      color:#CBCBCB;
    }
  }
  select {
    width: 100%;
    height: 30px;
  }
  label {
    font-size: 11px;
    color: red;
    font-weight: 700;
  }
  .resendTrue {
    color: green;
  }
  .password {
    & + * {
      margin-top: 20px;
    }
    &:hover {
      border: 1px solid var(--pink-contentoh);
    }
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    position: relative;
    width: 100%;
    border: 1px solid #f0eef2;
    .icon {
      position: absolute;
      height: 60%;
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      opacity: 0.3;
      cursor: pointer;
    }
    .icon:hover {
      opacity: 0.8;
    }
  }
`;
export const PhoneSelect = styled.div`
  width: 10.7%;
  select {
    height: 30px;
    width: 100%;
    border: 0.5px solid rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    border-radius: 3px;
  }
`;
export const PhoneNumberInput = styled.div`
  width: 83%;
  input {
    height: 30px;
    width: 100%;
    border: 0.5px solid rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    border-radius: 3px;
    font-family: Lato;
  }
`;
export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap:10px;

  .changeEmail {
    width: 100%;
    text-align: center;
    font-size: 13px;
    margin-top: 2%;
    font-weight: bold;
    color: #b12d84;
    :hover {
      cursor: pointer;
    }
  }
  .registrationLabel,
  .loginLabel {
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: Roboto;
    color: #fff;
    font-size:12px;
    gap:5px;
    :hover {
      cursor: pointer;
    }
  }
  .registrate,
  .login {
    font-family: Roboto;
    color: #fff;
    text-decoration: underline;
    font-size:12px;
    cursor:pointer;
    :hover{
      zoom: 1.05;
    }
  }

  .row {
    display: flex;
    flex-direction: row;

    &:nth-child(2) {
      font-weight: 500;
      margin: 0;
    }
  }

  &.privacy {
    justify-content: initial;
  }

  & + .passwod-strength {
    margin-top: 26px;

    & + .privacy {
      margin-top: 49px;
    }
  }

  &.privacy {
    align-items: initial;

    & + .privacy {
      margin-top: 12px;
    }
  }

  input {
    margin: auto 0;

    & + * {
      margin-left: 15px;
    }
  }
`;

export const Button = styled.button`
  font-family: Roboto;
  font-size: 12px;
  background: ${({ background }) => (background ? background : "#B64545")};
  border-radius: 30px;
  border: none;
  width: auto;
  height: 24px;
  padding: 5px 10px;
  color: ${({ color }) => (color ? color : "white")};
  cursor:  pointer;
`;

export const RowButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2%;
`;

export const Error = styled.div`
  font-family: Roboto;
  font-size: 10px;
  font-weight: 600;
  line-height: 11.72px;
  text-align: left;
  color:#B64545;
`;

export const ConfirmationRowButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 2%;
`;
export const StepsRow = styled.div`
  display: flex;
  width: 100%;
`;

export const StepDiv = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${(props) => {
    if (props.pasoActual > props.paso) {
      return "#71DE56";
    } else if (props.pasoActual === props.paso) {
      return "#C4C4C4";
    } else {
      return "#E2E2E2";
    }
  }};
  border: 0.5px solid var(--gray-light-secondary);
`;
export const ItemPaso = styled.div`
  width: 100%;
  p {
    margin: 0;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 11px;
  }
`;
//Styled-Components passwordCreation
export const Terms = styled.div`
  font-size: 13px;

  a {
    text-decoration: none;
    font-weight: bold;
    font-size: 13px;
    line-height: 24px;
    color: #817393;
  }
  span {
    font-weight: bold;
  }
  div {
    opacity: 80%;
    line-height: 24px;
  }
  .verificationCode {
    color: #e451ac;
    font-size: 11px;
    text-decoration: underline #e451ac;
    cursor: pointer;
  }
`;
export const ConfirmationTitle = styled.div`
  font-size: 15px;
  width: 100%;
  margin-top: 4%;
`;


export const Title = styled.h2`
  font-weight: 600;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "36px")};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "36px")};
  text-align: center;
  letter-spacing: -0.015em;
  color: ${({ color }) => (color ? color : "#000000")};
`;

export const Panel = styled.div`
  width: 50%;
  height: 100vh;
  display: flex;
  padding: 25px 50px;
  box-sizing: border-box;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "space-around"};
  align-items: center;
  flex-direction: column;
  background: ${({ color }) =>
    color ? color : "linear-gradient(180deg, var(--pink-contentoh) 0%, #3b1366 100%)"};

  .image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-height: 700px;
    gap:20px;
    .logo-container{
      width:50%;
    }
  }
`;
