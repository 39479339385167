import React, { useState } from "react";
import { Button, MainContainer } from "./styles";

const options = [
  { icon: "description", label: "Descripción", key: "description" },
  { icon: "dataset", label: "Ficha técnica", key: "datasheet" },
  { icon: "image", label: "Imágenes", key: "images" },
];

const EditionTabs = ({ getSection }) => {
  const [section, setSection] = useState("description");

  function changeSection(section = "description") {
    setSection(section);
    if (getSection) getSection(section);
  }

  return (
    <MainContainer>
      {options.map(({ icon, label, key }) => (
        <Button
          key={key}
          onClick={() => changeSection(key)}
          isActive={section === key}
        >
          <p className="tab-text">
            <span className="icon-tab material-symbols-outlined">
              {icon}
            </span>
            {label}
          </p>
        </Button>
      ))}
    </MainContainer>
  );
};

export default EditionTabs;