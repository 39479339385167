import styled from "styled-components";

export const Container = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  &.medium-image {
    width: 26px;
    height: 26px;
  }

  &.big-image {
    width: 30px;
    height: 30px;
  }

  &.profile-image {
    width: 40px;
    height: 40px;
  }

  &.retailer-image {
    width: 50px;
    height: 50px;
  }
`;
