import styled from "styled-components";
import { GlobalColors } from "../../../global-files/variables";

export const Container = styled.div`
  width: 300px;
  height: 100%;
  background-color: ${GlobalColors.deep_gray};
  padding: 15px;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 500;

  .versions-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .buttons-container {
      display: flex;
      align-items: center;

      button + * {
        margin-left: 10px;
      }
    }
  }
`;
