import axios from "axios";

export const getUsers = async (setListUsers, setListUsersFilter) => {
  const response = await axios.get(
    `${process.env.REACT_APP_USER_ENDPOINT}?isColaborators=1`,
    {
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    }
  );
  const collaborators = JSON.parse(response.data.body).data[2];
  setListUsers(collaborators);
  setListUsersFilter(collaborators);
  sessionStorage.setItem("collaborators", JSON.stringify(collaborators));
};

export const filterUsers = async (
  categoriesArray, martketStall, setListUsers, setListUsersFilter, setLoading
) => {
  setLoading(true);
  const categoriesIds = categoriesArray.length
    ? `&categoriesIds=${categoriesArray.join(",")}`
    : "";
  const positionsIds = martketStall.length
    ? `&position=${martketStall.join(",")}`
    : "";
  const query = `${categoriesIds}${positionsIds}`;
  const response = await axios.get(
    `${process.env.REACT_APP_USER_ENDPOINT}?isColaborators=1${query}`,
    {
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    }
  );
  const collaborators = JSON.parse(response.data.body).data[2];
  setListUsers(collaborators);
  setListUsersFilter(collaborators);
  sessionStorage.setItem("collaborators", JSON.stringify(collaborators));
  setLoading(false);
};

export const getCategories = async (setCategories, setLoading) => {
  const company = JSON.parse(sessionStorage.getItem("company"));
  const user = JSON.parse(sessionStorage.getItem("user"));
  const key = company.financedRetailers ? "financedRetailers" : "retailers";
  const isRetailer = user.is_retailer === 0;
  const query = isRetailer || key === "financedRetailers"
    ? `?id=${encodeURIComponent(company[key].map((ret) => ret.id).join(","))}`
    : "";
  const response = await axios.get(
    `${process.env.REACT_APP_CATEGORY_ENDPOINT}${query}`,
    {
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    }
  );
  const categories = JSON.parse(response.data.body)?.data;
  const categoryValues = categories.map((category, index) => ({
    id: index,
    name: category.name,
    subOptions: category.sub_category.map(sub => ({
      id: sub.id_category,
      name: sub.name,
    }))
  }));
  categoryValues.sort((a, b) => a.name.localeCompare(b.name));
  setCategories(categoryValues);
  setLoading(false);
};

export const searchUser = (listUsers, setListUsersFilter, userName) => {
  const filteredUsers = listUsers.filter((item) =>
    item?.name?.toLowerCase().includes(userName.toLowerCase())
  );
  setListUsersFilter(filteredUsers);
};

const getProductLimitations = (planID) => {
  switch (planID) {
    case 1:
      return { prodLimit: 3, colabLimit: 1 };
    case 2:
      return { prodLimit: 10, colabLimit: 1 };
    case 3:
      return { prodLimit: 50, colabLimit: 1 };
    case 4:
      return { prodLimit: 100, colabLimit: 3 };
    case 5:
      return { prodLimit: 500, colabLimit: 5 };
    case 6:
      return { prodLimit: 1000, colabLimit: 10 };
    case 7:
      return { prodLimit: 2500, colabLimit: 10 };
    case 8:
      return { prodLimit: 5000, colabLimit: 50 };
    case 9:
      return { prodLimit: Infinity, colabLimit: 30 };
    default:
      return { prodLimit: 0, colabLimit: 0 };
  }
};

const fetchCount = async (url) => {
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });
    return JSON.parse(response.data?.body)?.count || 0;
  } catch (error) {
    console.error("Error fetching count:", error);
    return 0; 
  }
};

export const membershipLimitations = async (validation) => {
  let result = {};

  try {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const membership = user.membership;

    let activeItems = 0;

    if (validation === "products") {
      const url = `${process.env.REACT_APP_ARTICLE_ENDPOINT}?countCheck=true`;
      activeItems = await fetchCount(url);
      result = "/AddProducts";
    } else if (validation === "colab") {
      const url = `${process.env.REACT_APP_USER_ENDPOINT}?isColaborators=1&countCheck=true`;
      activeItems = await fetchCount(url);
      result = true;
    } else {
      throw new Error("Invalid validation type");
    }

    const { prodLimit, colabLimit } = getProductLimitations(membership.planID);

    if (validation === "products" && activeItems >= prodLimit) {
      result = "maxProductsCreated";
    } else if (validation === "colab" && activeItems >= colabLimit) {
      result = false;
    }
  } catch (err) {
    console.error("Error in membership limitations:", err);
    result = false;
  }

  return result;
};

export const getCategoriesString = (categoriesArray) => {
    const newArray = [];
    categoriesArray?.forEach(({ category }) => {
      newArray.push(...category.split("|"));
    });
  
    const categoriesString =
      newArray.length > 2
        ? newArray[0] +
          ", " +
          newArray[1] +
          " y " +
          (newArray.length - 2) +
          " más"
        : newArray.join("|");
  
    return categoriesString;
  };

export const deleteCollaborator = async (usersSelected, addNotification) => {
    try {
      const responseArray = [];
      usersSelected.forEach((userId) => {
        responseArray.push(
          axios({
            method: "delete",
            url: process.env.REACT_APP_USER_ENDPOINT,
            data: { idUser: userId },
            headers: {
              Authorization: sessionStorage.getItem("jwt"),
            },
          })
        );
      });
      await Promise.all(responseArray);
      getUsers();
      addNotification('success','Se eliminó correctamente');
    } catch (err) {
      console.log(err);
    }
  };

  export const onSubmit = async (emailCollaborator, department, position, setLoading, setModalData,face, loadData, setShow, setColabLimitReached) => {
    try {
      const isValid = await membershipLimitations("colab");
      if (isValid) {
        let body = {
          emailCollaborator,
          department,
          position,
        };
        const res = await axios.post(
          `${process.env.REACT_APP_USER_ENDPOINT}`,
          body,
          {
            headers: {
              Authorization: sessionStorage.getItem("jwt"),
            },
          }
        );
        if (res.data.statusCode === 200) {
          setLoading(true);
          setModalData({
            img: face,
            message: `Se creó el usuario ${body.emailCollaborator} correctamente`,
            detail: "Se le envió un correo con una contraseña temporal",
            button2: { name: "Aceptar", action: () => setShow(false) },
          });
          loadData();
        } else {
          setModalData({
            message: `Ocurrió un error al crear la cuenta ${body.emailCollaborator}`,
            detail: "Asegúrese que no se encuentra registrado actualmente",
            button2: { name: "Aceptar", action: () => setShow(false) },
          });
        }
        setShow(true);
      } else {
        setColabLimitReached(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

