import styled from "styled-components";
import { GlobalColors, FontFamily } from "../../../global-files/variables";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-width: min-content;
  min-height: min-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 20px 10px;
  overflow: auto;
  //border: 1px solid red;

  img {
    width: 80%;
    min-width: 35px;
    max-width: 480px;
    max-height: 90%;
    object-fit: contain;
    //border: 1px solid blue;
  }

  .divInfo {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 7px;
    align-items: center;

    .btnInfo {
      padding: 0px;
    }

    .label-error {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      gap: 3px;
      font-family: ${FontFamily.RobotoRegular}, sans-serif;
      font-size: 13px;
      color: #262626;

      label {
        font-family: ${FontFamily.RobotoMedium}, sans-serif;
        font-size: 15px;
        color: #e33aa9;
      }
    }
  }
`;
