import React, { useState, useEffect } from "react";
import { Container } from "./styles";
import { FontFamily, GlobalColors } from "../../../global-files/variables";
import { BoxOnboarding } from "../BoxOnboarding";
import { BoxButtons } from "../../molecules/BoxButtons";

export const Box = ({ onChange, dataInputs, inputGroup }) => {
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
  const [boxOnboardingList, setBoxOnboardingList] = useState([
    {
      showAdd: true,
      value: {},
    },
  ]);

  useEffect(() => {
    const temp = {};
    let maxBoxId = 0;
    inputGroup.inputs.forEach((attrId) => {
      if (!dataInputs[attrId].box) return;
      Object.entries(dataInputs[attrId].box).forEach(([boxId, value]) => {
        if (boxId > maxBoxId) maxBoxId = boxId;
        if (!temp[boxId]) temp[boxId] = {};
        temp[boxId][attrId] = value;
      });
    });
    const tempList = Object.values(temp);
    if (tempList.length > 0) {
      setBoxOnboardingList(
        tempList.map((value, index) =>
          index === tempList.length - 1 ? { showAdd: true, value } : { value }
        )
      );
    }
  }, []);

  const handleAddBoxOnboarding = () => {
    const newBoxOnboarding = {
      showAdd: false,
      value: {},
    };

    setBoxOnboardingList([...boxOnboardingList, newBoxOnboarding]);
  };

  const handleDeleteBoxOnboarding = (index) => {
    if (index !== 0) {
      const updatedBoxOnboardingList = [...boxOnboardingList];
      updatedBoxOnboardingList.splice(index, 1);
      setBoxOnboardingList(updatedBoxOnboardingList);
    } else {
      setIsDeleteDisabled(true);
    }
  };
  
  useEffect(() => {
    onChange && onChange(boxOnboardingList);
  }, [boxOnboardingList]);

  return (
    <Container
      fontFamily={FontFamily.Raleway}
      color={GlobalColors.original_magenta}
    >
      <h1 fontFamily={FontFamily.Raleway}>{inputGroup.dataGroup}</h1>
      <div className="container-box">
        {boxOnboardingList.map((boxOnboarding, index) => (
          <>

            <div className="container-buttons">
              <BoxOnboarding
                key={index}
                index={index}
                showAdd={boxOnboarding.showAdd}
                data={boxOnboarding.value}
                dataInputs={dataInputs}
                inputs={inputGroup.inputs}
                onChange={(e) => {
                  setBoxOnboardingList((prev) => {
                    return prev.map((box, i) => {
                      if (i != index) {
                        return box;
                      }
                      return { ...box, value: e };
                    });
                  });
                }}
              />
              <BoxButtons
                key={index}
                index={index}
                showAdd={boxOnboarding.showAdd}
                onAdd={handleAddBoxOnboarding}
                onDelete={() => handleDeleteBoxOnboarding(index)}
                isDeleteDisabled={isDeleteDisabled}
              />
            </div>
          </>
        ))}
      </div>
    </Container>
  );
};
