import { Container } from "./styles";
import React from "react";
import { TextField } from "@mui/material";

export const InputText = (props) => {
  const {
    id,
    key,
    className,
    type, // gray | black => (default -> black)
    transparent, // boolean
    size, // number ... default (13)
    borderType, // rectangle || circle || (cualquier otro valor -> no borde)
    placeHolder, // (string) texto por defecto
    multiline, // (boolean) true -> textarea  false -> 1 linea
    maxRows, //	(number) maxima cantidad de rows visibles
    minRows, // (number) cantidad de rows visibles minimos
    //label, // (string) titulo del inputText
    disabled, // (boolean) true -> bloqueado
    text, // (string) texto del input
    onChangeText, // (newText) => {}
    onEnter, // (event) => {}
    refInputText, // useRef()
  } = props;

  return (
    <>
      <Container
        id={id}
        key={key}
        className={"container-inputText " + className}
        borderType={borderType}
        size={size}
      >
        <TextField
          className={
            "inputText " +
            (["gray", "white", "black"].includes(type) ? type : "white") +
            (transparent ? " transparent" : " fill")
          }
          multiline={multiline}
          inputRef={refInputText}
          disabled={disabled}
          placeholder={placeHolder}
          label={undefined}
          minRows={minRows}
          maxRows={maxRows}
          variant="outlined"
          value={text}
          onChange={(event) => onChangeText && onChangeText(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter" && !event.shiftKey) {
              event.preventDefault();
              onEnter && onEnter(event);
            }
          }}
        />
      </Container>
    </>
  );
};
