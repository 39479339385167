import styled from "styled-components";

export const MainContainer = styled.div`
  .grid-view {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    height: 100%;
  }

  .grid {
    justify-content: flex-start;
  }

  .table {
    flex-direction: column;
  }
  .modal-cart {
    .custom-component {
      margin-top: 10px;
    }
    .btn-adquirir {
      border: 1px solid var(--pink-contentoh);
      background: var(--pink-contentoh);
      color: var(--white-contentoh);
      border-radius: 30px;
      padding: 4px 8px;
      cursor: pointer;
      &:hover {
        border: 1px solid var(--pink-contentoh);
        background: var(--white-contentoh);
        color: var(--pink-contentoh);
      }
    }
    .btn-cancelar {
      border: 1px solid var(--pink-contentoh);
      background: var(--white-contentoh);
      color: var(--pink-contentoh);
      border-radius: 30px;
      cursor: pointer;
      padding: 4px 8px;
      &:hover {
        border: 1px solid var(--pink-contentoh);
        background: var(--pink-contentoh);
        color: var(--white-contentoh);
      }
    }
  }
`;
