import React from "react";
import providerLogo from "../../assets/Login/provider_logo.png";
import radioshackLogo from "../../assets/Login/logoRadioshack.png";
import officeDepotLogo from "../../assets/Login/logoOfficeDepot.png";
import { Panel, Title, TextUnderImage } from "./styles";

const ProviderPanel = () => {
  return (
    <Panel style={{ background: `url(${providerLogo})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
      <div className="image-container">
        <Title color="#262626">¡Bienvenido a Content-oh!</Title>
        <TextUnderImage color="#262626">
          <p>Inicia sesión para entrar a tu HUB personalizado para Office Depot y RadioShack</p>
        </TextUnderImage>
        <img className="logo-container" src={officeDepotLogo} alt="OfficeDepot" />
        <img className="logo-container" src={radioshackLogo} alt="Radioshack" />
      </div>
    </Panel>
  );
};

export default ProviderPanel;
