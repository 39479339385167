import axios from "axios";

const encodeFilters = (filter) => encodeURIComponent(JSON.stringify(filter));

/**
 * Obtiene los productos del minorista basado en los parámetros dados.
 * @param {object} params - Parámetros de filtrado para la consulta.
 * @param {function} setProductCount - Función para actualizar el conteo de productos.
 * @returns {Promise<object>} - Lista de productos y datos relacionados.
 */
export const getRetailerProducts = async (params, setProductCount) => {
  try {
    const {
      canonicFilter,
      productsType,
      providerFilter,
      phaseFilter,
      groupFilter,
      startIndex,
      setFilterLists,
    } = params;

    let providerFilterEncoded = encodeFilters(providerFilter);
    let phaseFilterEncoded = encodeFilters(phaseFilter);
    let groupFilterEncoded = encodeFilters(groupFilter);

    if (canonicFilter) {
      switch (canonicFilter.filterName) {
        case "filterByPhase":
          phaseFilterEncoded = canonicFilter.filterValues;
          break;
        case "filterByProvider":
          providerFilterEncoded = canonicFilter.filterValues;
          break;
        case "filterByGroup":
          groupFilterEncoded = canonicFilter.filterValues;
          break;
        default:
          break;
      }
    }
    const baseUrl = process.env.REACT_APP_ARTICLE_RETAILER_ENDPOINT;
    const filterParams = [
      `productsType=${productsType}`,
      `startIndex=${startIndex}`,
      `providerFilter=${providerFilterEncoded}`,
      `phaseFilter=${phaseFilterEncoded}`,
      `groupPhase=${groupFilterEncoded}`,
      canonicFilter
        ? `&filterName=${canonicFilter.filterName}&filterValues=${canonicFilter.filterValues}`
        : "",
    ].join("&");

    const productsResponse = await axios.get(`${baseUrl}?${filterParams}`, {
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });

    const parsedResponse = JSON.parse(productsResponse.data.body);
    // console.log("parsedResponse",parsedResponse)
    setProductCount(parsedResponse.count);
    if (!params.filterLists) {
      setFilterLists(parsedResponse);
    }

    const productList = parsedResponse.data || [];
    // console.log("productList",productList)

    const tableData = productList.map((item) => ({
      upc: item.article.upc,
      name: item.article.name,
      category: item.article.category,
      phaseId: item.article.phaseId,
      phaseName: item.article.phaseName,
      groupId: item.article.groupId,
      groupName: item.article.groupName,
      providerId: item.article.providerUserId,
      providerName: item.article.providerUserName,
      percentage: item.article.percentage || 0,
      reviewState: getButtonLabel(item.article.review_state),
      id_article: item.article.id_article,
    }));

    const gridData = productList.map((item) => ({
      name: item.article.name,
      src: item.article.src,
      category: item.article.category,
      id_article: item.article.id_article,
      upc: item.article.upc,
      percentage: item.article.percentage || 0,
      phaseId: item.article.phaseId,
      phaseName: item.article.phaseName,
      groupId: item.article.groupId,
      groupName: item.article.groupName,
      providerId: item.article.providerUserId,
      providerName: item.article.providerUserName,
      reviewState: getButtonLabel(item.article.review_state),
      version: item.version,
    }));

    const dataDetailTable = productList.map((item) => ({
      id_article: item.article.id_article,
      id_category: item.article.id_category,
      version: item.version,
    }))

    const headersTable = [
      "UPC",
      "Nombre",
      "Categoría",
      "Fase",
      "Grupo",
      "Estatus",
      "Avance",
    ];

    const headerMapping = {
      UPC: "upc",
      Nombre: "name",
      Categoría: "category",
      Fase: "phaseName",
      Grupo: "groupName",
      Estatus: "reviewState",
      Avance: "percentage",
    };
    // console.log("gridData", gridData);
    // console.log("tableData", tableData);

    const providersDataFilter = parsedResponse.providers
      .filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.id_company === item.id_company)
      )
      .map((item) => ({
        id_company: item.id_company,
        name: item.companyProviderName,
      }));

    const phasesDataFilter = parsedResponse.phases.map((item) => ({
      id: item.id,
      name: item.name,
    }));

    const groupsDataFilter = parsedResponse.groups.map((item) => ({
      id: item.id,
      name: item.name,
    }));

    const percentageParams = productList.map((item) => ({
      id_article: item.article.id_article,
      id_category: item.article.id_category,
      version: item.version,
      id_retailer_array: item.retailers.map((retailer) => retailer.id),
    }));


    return {
      err: false,
      productList: parsedResponse.data || [],
      dataRadio: Object.values(parsedResponse.data),
      tableData,
      gridData,
      dataDetailTable,
      headersTable,
      headerMapping,
      percentageParams,
      providersDataFilter,
      phasesDataFilter,
      groupsDataFilter
    };
  } catch (error) {
    console.error(
      "No se pueden obtener los productos de la base de datos",
      error
    );
    return { err: true, productList: [] };
  }
};

/**
 * Obtiene productos y actualiza las banderas según el estado de la respuesta.
 * @param {object} params - Parámetros de filtrado para la consulta.
 * @param {object} currentFlags - Estado actual de las banderas.
 * @param {function} flagUpdateFunction - Función para actualizar las banderas.
 * @param {function} setProductCount - Función para actualizar el conteo de productos.
 * @returns {Promise<{ tableData: array, gridData: array }>} - Datos para la tabla y el grid.
 */
export const getProducts = async (
  params,
  currentFlags,
  flagUpdateFunction,
  setProductCount
) => {
  const products = await getRetailerProducts(params, setProductCount);

  if (products.err) {
    flagUpdateFunction(updateFlags(currentFlags, "RequestError"));
    return { tableData: [], gridData: [] };
  } else if (products.productList.length === 0) {
    flagUpdateFunction(updateFlags(currentFlags, "EmptyList"));
  }

  const productList = products.productList;

  const gridData = productList.map((item) => ({
    name: item.name,
    src: item.src,
    category: item.category,
    upc: item.upc,
    percentage: item.percentage,
  }));

  const tableData = productList.map((item) => ({
    upc: item.upc,
    nombre: item.name,
    category: item.category,
    phaseName: item.phaseName,
    groupName: item.groupName,
    percentage: item.percentage,
  }));

  return { tableData, gridData };
};

/**
 * Actualiza las banderas basado en el nombre de la actualización.
 * @param {object} flags - Objetos que contienen los estados previos para el manejo de errores y estados de filtro.
 * @param {string} UpdateName - Cadena que describe el caso que debe ser manejado.
 * @returns {object} - Las banderas actualizadas.
 */
export const updateFlags = (flags, UpdateName) => {
  switch (UpdateName) {
    case "RequestError":
      return {
        ...flags,
        errorCatched: true,
        NoProductsFound: false,
        isLoading: false,
        warningMessage:
          "Hubo un problema al recuperar los productos para tu cadena",
      };
    case "EmptyList":
      return {
        ...flags,
        errorCatched: false,
        NoProductsFound: true,
        isLoading: false,
        warningMessage: "No se encontraron productos para tu cadena",
      };
    default:
      return flags;
  }
};


export const filterProducts = (
  gridData,
  tableData,
  searchValue
) => {
  let filteredGridData = [...gridData];
  let filteredTableData = [...tableData];
  if (searchValue) {
    const lowercasedSearchValue = searchValue.toLowerCase();
    filteredGridData = filteredGridData.filter(
      (product) =>
        product.name.toLowerCase().includes(lowercasedSearchValue) ||
        product.upc.toLowerCase().includes(lowercasedSearchValue)
    );
    filteredTableData = filteredTableData.filter(
      (product) =>
        product.name.toLowerCase().includes(lowercasedSearchValue) ||
        product.upc.toLowerCase().includes(lowercasedSearchValue)
    );
  }

  return { filteredGridData, filteredTableData };
};

export const getButtonLabel = (reviewState) => {
  switch (reviewState) {
    case "rejected_to_provider":
      return "Rechazado a proveedor"
    case "rejected":
      return "Rechazado";
    case "in_progress":
      return "En progreso";
    case "approved":
      return "Aprobado";
    default:
      return "No definido";
  }
};