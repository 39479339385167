export const extractPercentageData = (
  fetchedPercentages,
  id_article,
  typePercentage
) => {
  if (!fetchedPercentages || !id_article || !typePercentage) return null;

  const articlePercentages = fetchedPercentages[id_article];

  if (articlePercentages && typeof articlePercentages === "object") {
    const { percentageRequired, percentageGeneral } = articlePercentages;
    return typePercentage === "percentageRequired"
      ? percentageRequired
      : percentageGeneral;
  }

  return null;
};
