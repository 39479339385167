import React, { useState, useRef } from 'react';
import { Button, Popover, MenuItem, ListItemIcon } from '@mui/material';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Modal from '../Modal';
import { useModal } from '../../../hooks/useModal';
import Tooltip from '../Tooltip';
import { MainContainer } from './styles';
import { useStore } from '../../../stores/useStore';

const ApprovedButton = ({ isApproved, comment, onReject, id_attribute }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [status, setStatus] = useState(isApproved ? 'approved' : comment ? 'rejected' : 'doNotDisturb');
  const inputRef = useRef(null); 
  const { modalData, openModal, closeModal, handleCancelClick } = useModal();
  const store = useStore((state) => state.currentData);
  const {setDataObjAproval, getSingleObj} = useStore();
  const obj = getSingleObj(id_attribute);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApprove = () => {
    if (onReject) {
      // onReject(true, null);
      setDataObjAproval(id_attribute, true, null);
      console.log("store aproved",store);
    }
    handleClose();
    setStatus('approved');
  };

  const handleReject = () => {
    openModal({
      className: 'modal-reject',
      title: 'Escribe el mensaje de rechazo:',
      customComponent: (
        <textarea
          className="reject-textarea"
          ref={inputRef}
          placeholder="Escribe tu razón aquí..."
          rows={4}
          cols={50}
        />
      ),
      showAccept: true,
      showCancel: true,
      onAcceptClick: handleAcceptClick,
    });
  };

  const handleAcceptClick = () => {
    const value = inputRef.current.value;
    if (onReject) {
      // onReject(false, value);
      setDataObjAproval(id_attribute, false, value);
      console.log("store aproved",store);
    }
    inputRef.current.value = '';
    closeModal();
    setStatus(value ? 'rejected' : 'doNotDisturb');
  };

  const renderIcon = () => {
    if (obj?.isApproved) {
      return <CheckCircleIcon sx={{ color: 'var(--secondary-color)' }} />;
    } else if (!obj?.isApproved) {
      return (
        <Tooltip title={`Motivo de rechazo: ${comment}`} position="top" variant="white">
          <CancelIcon sx={{ color: 'var(--error-color)' }} />
        </Tooltip>
      );
    } else { 
      return (
        <Tooltip title="Debes aprobar o rechazar para continuar" position="top" variant="white">
          <DoNotDisturbOnIcon sx={{ color: 'var(--purple-light-contentoh)' }} />
        </Tooltip>
      );
    }
  };

  return (
    <MainContainer>
      <Button onClick={handleClick} sx={{ minWidth: '30px' }}>
        {renderIcon()}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={handleApprove} sx={{ fontSize: '0.75rem' }}>
          <ListItemIcon sx={{ minWidth: '30px' }}>
            <CheckCircleIcon sx={{ color: 'var(--secondary-color)' }} />
          </ListItemIcon>
          Validar
        </MenuItem>
        <MenuItem onClick={handleReject} sx={{ fontSize: '0.75rem' }}>
          <ListItemIcon sx={{ minWidth: '30px' }}>
            <CancelIcon sx={{ color: 'var(--error-color)' }} />
          </ListItemIcon>
          Rechazar
        </MenuItem>
      </Popover>
      <Modal {...modalData} onClose={handleCancelClick} />
    </MainContainer>
  );
};

export default ApprovedButton;