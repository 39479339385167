import React from "react";
import ReactImageFallback from "react-image-fallback";
// import Status from "../../_utils/TableComponents/Status";
import {
  MainContainer,
  MessageContainer,
  Header,
  Icon,
  Close,
  Message,
  ContainerButtons,
  FirstButton,
  Button,
  Detail,
  TextArea,
  BoldText,
} from "./styles";

import yellowAlert from "../../../assets/IconComponents/yellowAlert.svg";
import closeWhite from "../../../assets/IconComponents/closeWhite.svg";

/*
props (* required):
  - *close: function to quit pop up e.g close={() => setShow(!show)}
  - button1/button2: name and action button e.g. button1={{ name: 'Cancelar', action: () => console.log('Cancelar') }}
  - img: source image
  - *message: message to show (like title)
  - detail: message detail
  - withoutImg: if the value is "true" the icon will not be displayed, if this attribute is ignored or has the value of "false" the icon will be displayed
  - customComponent: contains a custom component
*/
export default function GenericModal(props) {
  return (
    <MainContainer>
      <MessageContainer height={props.button1 || props.button2}>
        <Header>
          <Icon widthImg={props.widthImg} heightImg={props.heightImg}>
            {!props.withoutImg && (
              <ReactImageFallback
                src={props.img}
                fallbackImage={yellowAlert}
                alt=""
              />
            )}
          </Icon>
          <Close
            onClick={(event) => {
              event.stopPropagation();
              props.close();
            }}
          >
            <img src={closeWhite} alt="Cerrar" />
          </Close>
        </Header>
        <Message>
          {props.bold && <BoldText>{props.bold}</BoldText>} {props.message}
        </Message>
        {props.detail && (
          <Detail>
            {`${props.detail} `}
            {/* {props.status && (
              <Status
                noHover
                width={props.CustomComponentWidth}
                status={props.status}
              />
            )} */}
          </Detail>
        )}
        {props.textArea && <TextArea id="area" />}
        {props.customComponent && props.customComponent}
        <ContainerButtons>
          {props.button1 && (
            <FirstButton
              onClick={(event) => {
                props.button1.action();
              }}
            >
              {props.button1.name}
            </FirstButton>
          )}
          {props.button2 && (
            <Button
              onClick={(event) => {
                props.button2.action();
              }}
            >
              {props.button2.name}
            </Button>
          )}
        </ContainerButtons>
      </MessageContainer>
    </MainContainer>
  );
}
