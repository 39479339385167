import styled from "styled-components";

export const MainContainer = styled.div`
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--gray-light);
  border-radius: 10px;
  gap: 10px;
   .indications {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
    text-align: left;
    color: var(--gray-contentoh);
    display: flex;
    align-items: center;
    gap: 5px;
    span{
        font-size:20px;
    }
  }
  .btn-circular{
    background:transparent;
    width:34px;
    height:34px;
    border-radius:50%;
    border: 1px solid var(--gray-light);
    cursor: pointer;
    span{
        font-size:18px;
        color: var(--gray-contentoh);
    }
    :hover{
        background: var(--pink-contentoh);
        border: 1px solid var(--pink-contentoh);
        span{
            font-size:18px;
            color: var(--white-contentoh);
        }
    }
  }
  .container-indications{
    display: flex;
    gap: 10px;
  }
`;

export const TitleButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .buttons-container{
    display: flex;
    gap: 10px;
  }
  .title {
    font-size: 19px;
    font-weight: 500;
    line-height: 19.92px;
  }
  @media (max-width: 530px) {
    flex-direction: column;
    gap: 5px;
}
`;

export const DataProduct = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;

    input{
        max-width: 200px;
        border: 1px solid var(--gray-light);
        border-radius:5px;
        padding:10px;
        :placeholder{
            color: var(--gray-medium);
            font-size: 12px;
        }
    }

    .icon {
      font-size: 30px;
    }

    @media (max-width: 1070px) {
        flex-wrap: wrap;
    }
`;

