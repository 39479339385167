import React, { useEffect, useState } from "react";
import {
  ProductDetailContainer,
  RowAttribute,
  SidebarContent,
  TitleAttribute,
} from "./styles";
import CustomButton from "../CustomButton";
import ProductImageViewer from "../ProductImageViewer";
import ImageDetails from "../ImageDetails";
import useImages from "../../../hooks/useImages";
import Loading from "../loading";
import { redirectEdit } from "./utils";
import { useHistory } from "react-router-dom";
import useNotifications from "../../../hooks/useNotification";
import CustomNotification from "../CustomNotification";

const ProductDetailPanel = ({
  isOpen,
  onClose,
  selectedProductData,
  selectedCurrentData,
  showEdit = true,
}) => {
  const { notifications, addNotification, deleteNotification } = useNotifications();
  const articleId = selectedProductData?.id_article || null;
  const idCategory = selectedProductData?.id_category
    ? Number(selectedProductData.id_category)
    : null;
  const version = selectedProductData?.version || null;
  const [redirect, setRedirect] = useState(null);
  const history = useHistory(); 

  const {
    imagesData,
    loading: loadingImages,
    error: errorImages,
  } = useImages({
    articleId,
    version,
    idCategory,
  });

  
  const productImages =
  imagesData?.values?.map((image) => {
    if (!image || !image.src) {
      return { src: "" };
    }
    return {
      src: image.src,
    };
  }) || [];


  useEffect(() => {
    // if (imagesData) {
    //   console.log("Images Data:", imagesData);
    // }
    // if (errorImages) {
    //   console.error("Error Images:", errorImages);
    // }
  }, [imagesData, errorImages]);
  const imageInputs =
    imagesData?.inputs?.map(
      ({ id, name, vertical_px, horizontal_px, required }) => ({
        id,
        name,
        vertical_px,
        horizontal_px,
        required,
      })
    ) || [];

    const handleRedirectClick = () => {
      redirectEdit(selectedProductData, setRedirect, addNotification);
    };
  
    useEffect(() => {
      if (redirect) {
        history.push(redirect);
        setRedirect(null);
      }
    }, [redirect, history]);

  return (
    <ProductDetailContainer className={isOpen ? "open" : ""}>
      <SidebarContent>
        {selectedProductData ? (
          <div className="container-product">
            <div className="product-detail-container">
              <h2>{selectedCurrentData.name}</h2>
              <div className="container-buttons">
                {showEdit && <CustomButton
                  variant="gray"
                  icon="edit_square"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleRedirectClick();
                  }}
                  iconOnly
                />}
                <CustomButton
                  variant="gray"
                  icon="close"
                  onClick={onClose}
                  iconOnly
                />
              </div>
            </div>
            <div>
              {/* <RowAttribute>
                <p className="text-attribute">14/10/2024</p>
              </RowAttribute> */}
              <RowAttribute>
                <TitleAttribute>UPC</TitleAttribute>
                <p className="text-attribute">{selectedCurrentData.upc}</p>
                <div className="version-container">
                  <p className="text-attribute">Versión 1</p>
                </div>
              </RowAttribute>
              <RowAttribute>
                <TitleAttribute>Precio</TitleAttribute>
                <p className="text-attribute">{selectedCurrentData.price}</p>
              </RowAttribute>
            </div>
            <div className="box-details">
              <TitleAttribute>{showEdit ? "Departamento" : "Categoría"}</TitleAttribute>
              <p className="text-attribute">{showEdit ? selectedCurrentData.department : selectedCurrentData.category}</p>
            </div>
            <div className="container-image-percentage">
              <div className="container-images">
                <TitleAttribute>Imágenes</TitleAttribute>
                {loadingImages ? (
                  <Loading />
                ) : (
                  <ProductImageViewer images={productImages} />
                )}
                {errorImages && <p>Error al cargar las imágenes.</p>}
              </div>
              <div className="container-images">
                <TitleAttribute>Porcentaje de avance</TitleAttribute>
                <div className="percentage-container">
                  <p>Radioshack</p>
                  <p>{selectedCurrentData.percentage}%</p>
                </div>
              </div>
            </div>
            <div className="container-image-percentage">
              <div className="container-images">
                <ImageDetails imagesDetails={imageInputs}></ImageDetails>
              </div>
            </div>
          </div>
        ) : (
          <div className="product-detail-container">
            <p>No hay información del producto</p>
            <div className="container-buttons">
              <CustomButton
                variant="gray"
                icon="close"
                onClick={onClose}
                iconOnly
              />
            </div>
          </div>
        )}
      </SidebarContent>
      <CustomNotification
        open={notifications.length > 0}
        alerts={notifications}
        deleteNotification={deleteNotification}
      />
    </ProductDetailContainer>
  );
};

export default ProductDetailPanel;
