import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { saveUserSession, clearCaches } from "./utils/sessionUtils";
import { getUserDetails } from "./utils/AuthService";
import {
  Row,
  LongItem,
  Validation,
  Item,
  RowButton,
  Button,
  Formulario,
} from "./styles";
import Loading from "../general/loading";
import { useAuth } from "./utils/AuthContext";
import ErrorLabel from "../general/ErrorLabel";
import { authenticateUser } from "./utils/AuthService";
import Logo from "../general/Logo";
import { useTranslation } from "react-i18next";

export default function SignIn({ onRegister, onForgotPassword }) {
  const { setAuthenticated } = useAuth();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emptyEmail, setEmptyEmail] = useState(true);
  const [emptyPassword, setEmptyPassword] = useState(true);
  const [showErrors, setShowErrors] = useState(false);
  const [signInError, setSignInError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();

  const onSubmit = async (event) => {
    event.preventDefault();
    setSignInError("");
    setShowErrors(true);

    let valid = true;
    if (email === "") {
      valid = false;
      setEmptyEmail(true);
    } else {
      setEmptyEmail(false);
    }
    if (password === "") {
      valid = false;
      setEmptyPassword(true);
    } else {
      setEmptyPassword(false);
    }
    if (valid) {
      try {
        setLoading(true);
        const session = await authenticateUser(email, password);
        const token = session.signInUserSession.idToken.jwtToken;
        const userDetails = await getUserDetails(token);
        saveUserSession(userDetails, session);
        setAuthenticated(true);
        history.push("/Profile");
      } catch (error) {
        console.log(error);
        setLoading(false);
        if (error.code === "NotAuthorizedException") {
          setSignInError("NotAuthorizedException");
        } else if (error.code === "UserNotConfirmedException") {
          sessionStorage.setItem("email", JSON.stringify(email));
          // props.setPaso(5);
        } else {
          setSignInError("Error");
        }
      }
    }
  };

  const onEnterKey = (evt) => {
    if (evt.charCode === 13) {
      onSubmit(evt);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return loading ? (
    <Loading />
  ) : (
    <Formulario onSubmit={onSubmit}>
      <Logo />
      <div className="container-formulario">
        <p className="title">{t('login.title')}</p>
        <Row>
          <LongItem>
            <span className="material-symbols-outlined gray">mail</span>
            <input
              required
              id="usernameInput"
              placeholder="Correo electrónico"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyPress={onEnterKey}
            />
          </LongItem>
        </Row>
        <ErrorLabel
          showErrors={showErrors}
          condition={emptyEmail}
          message="Ingrese su correo electrónico"
        />
        <Row>
          <LongItem>
            <span className="material-symbols-outlined gray">lock</span>
            <input
              required
              id="passwordInput"
              placeholder="Contraseña"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={onEnterKey}
            />
             <span
              onClick={togglePasswordVisibility}
              className="material-icons"
              style={{ cursor: "pointer", color: "#CBCBCB", fontSize: "18px" }}
            >
              {showPassword ? "visibility_off" : "visibility"}
            </span>
          </LongItem>
        </Row>
        <ErrorLabel
          showErrors={showErrors}
          condition={emptyPassword}
          message="Ingrese su contraseña"
        />
        <Row>
          <Item className="row">
            <label onClick={onForgotPassword} className="checkboxLabel">
              Recuperar contraseña
            </label>
          </Item>
        </Row>
        <Validation>
          <ErrorLabel
            showErrors={showErrors}
            condition={signInError === "NotAuthorizedException"}
            message="Correo o contraseña incorrectos"
          />
          <ErrorLabel
            showErrors={showErrors}
            condition={signInError === "Error"}
            message="Ha habido un problema al iniciar sesión"
          />
        </Validation>
        <RowButton>
          <Button type="submit" onSubmit={onSubmit} onClick={onSubmit}>
            Iniciar Sesión
          </Button>
        </RowButton>
      </div>
      <Row>
        <label className="registrationLabel">
          ¿Aún no tienes cuenta?
          <label
            className="registrate"
            onClick={onRegister}
          >
            ¡Regístrate aquí!
          </label>
        </label>
      </Row>
    </Formulario>
  );
}
