import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: fit-content;

  .buttons-container {
    display: flex;
    align-items: center;

    > * + * {
      margin-left: 3px;
    }
  }
`;
