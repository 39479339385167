import React from "react";
import MuiPagination from '@mui/material/Pagination';
import { ContainerPagination } from "./styles";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (event, page) => {
    onPageChange(page);
  };

  return (
    <ContainerPagination>
      <MuiPagination
      count={totalPages}
      page={currentPage}
      onChange={handlePageChange}
      sx={{
        "& .MuiPaginationItem-root": {
          color: "var(--purple)",
        },
        "& .MuiPaginationItem-root.Mui-selected": {
          backgroundColor: "var(--purple)",
          color: "#fff",
        },
      }}
    />
    </ContainerPagination>
    
  );
};

export default Pagination;
