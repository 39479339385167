import React, { useState } from "react";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { MainContainer, LeftContainer, RightContainer, MainData } from "./styles";

//Components
import ProfileHeader from "./ProfileHeader";
import NavbarMenu from "../general/NavbarMenu";
import AlertMessage from "../general/AlertMessage";
import PersonalData from "./PersonalData";
import CompanyData from "./CompanyData";
import ChangePass from "./ChangePass";
import GroupsUsers from "./GroupsUsers";
import GenericModal from "../general/GenericModal";

//Assets
import addPeople from "../../assets/IconComponents/addPeople.svg";
import billing from "../../assets/IconComponents/billing.svg";
import company from "../../assets/IconComponents/company.svg";
import logout from "../../assets/IconComponents/logout.svg";
import password from "../../assets/IconComponents/password.svg";
import profile from "../../assets/IconComponents/profile.svg";
import group from "../../assets/IconComponents/group.svg";
import person from "../../assets/IconComponents/person.svg";
import lock from "../../assets/IconComponents/lock.svg";
import work from "../../assets/IconComponents/work.svg";
import providers from "../../assets/IconComponents/package_2.svg";
import arrow from "../../assets/IconComponents/arrow_right.svg";
import attributes from "../../assets/IconComponents/attributes.svg";
import activeProvider from "../../assets/IconComponents/active_providers.svg";
import pendingInvitations from "../../assets/IconComponents/pending_invitation.svg";
import AttributesGroup from "./AttributesGroup";
import InviteProvider from "./InviteProvider";
import Flows from "./Flows";
import Billing from "./Billing";
import AddPeople from "./AddPeople";


const MyAccount = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [user] = useState(JSON.parse(sessionStorage.getItem("user")));
  const [tabActive, setTabActive] = useState("Proveedores activos");
  const [child, setChild] = useState(<PersonalData history={history}/>);
  const [showMenuText, setShowMenuText] = useState(false);

  const handleMenuClick = (component) => {
    setChild(component);
  };

  const toggleMenuText = () => {
    setShowMenuText(!showMenuText);
  };

  const menuItems = user.is_onboarding === 1 && user.is_retailer === 1 ? [
    { img: { src: person, alt: "menu-icon" }, title: "Información personal", path: "/profile", uKey: 1, component: <PersonalData history={history}/> },
    { img: { src: work, alt: "menu-icon" }, title: "Información de la empresa", path: "/profile/company-data", uKey: 2, component: <CompanyData history={history}/> },
    { img: { src: lock, alt: "menu-icon" }, title: "Cambiar contraseña", path: "/profile/change-pass", uKey: 3, component: <ChangePass history={history}/> },
    { img: { src: group, alt: "menu-icon" }, title: "Grupos y usuarios", path: "/profile/groups-users", uKey: 4, component: <GroupsUsers history={history}/> },
    { img: { src: attributes, alt: "menu-icon" }, title: "Atributos por grupo", path: "/profile/attributes-group", uKey: 5, component: <AttributesGroup history={history}/> },
    { img: { src: arrow, alt: "menu-icon" }, title: "Flujos de revisión", path: "/profile/flows", uKey: 6, component: <Flows history={history}/>},
    user.is_retailer === 1 && {
      img: { src: providers, alt: "menu-icon" },
      title: "Proveedores",
      path: "/profile/invite-providers",
      uKey: 7,
      component: <InviteProvider history={history} user={user} 
      tabActive={tabActive} 
      setTabActive={setTabActive} />,
      subMenu: [
        { title: "Proveedores activos", icon: { src: activeProvider, alt: "icon-active" }},
        { title: "Invitaciones pendientes", icon: { src: pendingInvitations, alt: "icon-pending" }},
      ],
      setTabActive: setTabActive
    }
  ] : [
    { img: { src: profile, alt: "menu-icon" }, title: "Información personal", path: "/profile", uKey: 1, component: <PersonalData history={history}/>  },
    { img: { src: company, alt: "menu-icon" }, title: "Información de la empresa", path: "/profile/company-data", uKey: 2, component: <CompanyData history={history}/> },
    { img: { src: billing, alt: "menu-icon" }, title: "Datos de facturación", path: "/profile/billing-data", uKey: 3, component: <Billing history={history}/> },
    { img: { src: password, alt: "menu-icon" }, title: "Cambiar contraseña", path: "/profile/change-pass", uKey: 4, component: <ChangePass history={history}/> },
    { img: { src: addPeople, alt: "menu-icon" }, title: "Usuarios", path: "/profile/add-people", uKey: 5, component: <AddPeople history={history}/> },
  ];

  return (
    <Router>
      <MainContainer>
        <LeftContainer showMenuText={showMenuText}>
          <ProfileHeader toggleMenuText={toggleMenuText} showMenuText={showMenuText}/>
          <MainData>
            <NavbarMenu items={menuItems} history={history} onMenuClick={handleMenuClick} setTabActive={setTabActive} showMenuText={showMenuText}/>
          </MainData>
        </LeftContainer>
        <RightContainer>
          <AlertMessage alert={true} />
            {child}
        </RightContainer>
      </MainContainer>
    </Router>
  );
};

export default MyAccount;
