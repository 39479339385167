import React, { useReducer, useState, useEffect } from "react";
import HeaderAdd from "./HeaderAdd";
import { MainContainer } from "./styles";
import {
  reducerForArticles,
  getUserData,
  fetchCategories,
  processCategories,
  transformDepotData,
} from "./utils";
import CustomTable from "../general/CustomTable";

const AddProducts = () => {
  const [articles, dispatch] = useReducer(reducerForArticles, []);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const isRetailer =
    JSON.parse(sessionStorage.getItem("user")).is_retailer === 1;
  const userFinanced = JSON.parse(
    sessionStorage.getItem("company")
  ).hasOwnProperty("financedRetailers");
  const ids =
    JSON.parse(sessionStorage.getItem("company"))?.retailers?.map(
      ({ id }) => id
    ) ?? [];
  const isTHDFinanced =
    userFinanced && [58, 59, 60, 61].some((id) => ids?.includes(id));
  const isDropshipFinanced = userFinanced && ids?.includes(68);
  const isBodegaFinanced = userFinanced && ids?.includes(70);
  const isOnboarding = userFinanced && JSON.parse(sessionStorage.getItem("user")).is_onboarding;

  const [lists, setLists] = useState({
    depot: [],
  });

  const [newArticle, setNewArticle] = useState({
    sku: "",
    upc: "",
    name: "",
    depot: "",
    category: "",
    type: "",
    retailer: isRetailer ? "" : [],
  });
  useEffect(() => {
    const loadInitialData = async () => {
      const providers = await getUserData("isProviders=1");
      setLists((prev) => ({
        ...prev,
        providers: providers.map((e) => ({
          value: e.id_company,
          name: e.trade_name,
        })),
      }));

      const company = JSON.parse(sessionStorage.getItem("company"));
      const key = company.financedRetailers ? "financedRetailers" : "retailers";
      const query =
        isRetailer || key === "financedRetailers"
          ? `?id=${encodeURIComponent(
              company[key].map((ret) => ret.id).join(",")
            )}`
          : "";

      const categories = await fetchCategories(query);
      const { processedCategories, processedTypes } =
        processCategories(categories);

      setLists((prev) => ({
        ...prev,
        depot: categories,
      }));
    };

    loadInitialData();
  }, [isRetailer]);

  const handleDeleteArticle = (index) => {
    dispatch({ type: "delete", index });
  };
  const transformedOptions = transformDepotData(lists?.depot);

  const headers = [
    "UPC",
    "Nombre",
    "Departamento",
    "Categoría",
    "Tipo de producto",
    "Cadenas",
  ];
  const headerMapping = {
    UPC: "upc",
    Nombre: "name",
    Departamento: "depot",
    Categoría: "category",
    "Tipo de producto": "type",
    Cadenas: "retailer",
  };

  return (
    <MainContainer>
      <HeaderAdd
        lists={lists}
        newArticle={newArticle}
        setNewArticle={setNewArticle}
        transformedOptions={transformedOptions}
        articles={articles}
        isRetailer={isRetailer}
        isTHDFinanced={isTHDFinanced}
        isBodegaFinanced={isBodegaFinanced}
        userFinanced={userFinanced}
        isDropshipFinanced={isDropshipFinanced}
        isOnboarding={isOnboarding}
        addArticle={(article) => {
          dispatch({ type: "newArticle", payload: article });
        }}
        headerMapping={headerMapping}
      />
      <CustomTable
        data={articles}
        headers={headers}
        headerVariant="gray"
        headerMapping={headerMapping}
        rowsPerPage={20}
        showDeleteButton={true}
        onDelete={handleDeleteArticle}
        selectedArticles={selectedArticles}
        setSelectedArticles={setSelectedArticles}
      />
    </MainContainer>
  );
};

export default AddProducts;
