import styled from 'styled-components';

export const InputBase = styled.input`
  width: 100%;
  min-width: 190px;
  height: 28px;
  display: flex;
  align-items: center;
  padding: 7px;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid ${({ variant, isError }) => 
    isError 
      ? 'var(--pink-contentoh)' 
      : variant === 'gray' 
      ? '#d4d1d7' 
      : variant === 'graylight' 
      ? 'var(--gray-light)' 
      : '#ccc'};
  background-color: ${({ variant }) => 
    variant === 'gray' || variant === 'graylight' ? '#fff' : '#f0f0f0'};
  
  &:placeholder {
    font-weight: normal;
    font-size: 12px;
    color: ${({ variant }) => 
      variant === 'gray' || variant === 'graylight' ? '#d4d1d7' : '#a0a0a0'};
  }

  &:focus {
    border: ${({ variant, isError }) => 
      isError 
        ? '2px solid var(--pink-contentoh)' 
        : variant === 'gray' 
        ? '1px solid #b0b0b0' 
        : variant === 'graylight' 
        ? '1px solid var(--pink-contentoh)' 
        : '1px solid #808080'};
    outline: none;
  }

  &:disabled {
    background-color: #f5f5f5;
    color: #a0a0a0;
    border: 1px solid #e0e0e0;
  }
`;

export const TextAreaBase = styled.textarea`
  min-width: 190px;
  width: 100%;
  resize: vertical;
  min-height: 50px;
  border-radius: 6px;
  font-family: 'Roboto';
  padding: 4px;
  border: 1px solid ${({ variant, isError }) => 
    isError 
      ? 'var(--pink-contentoh)' 
      : variant === 'gray' 
      ? '#d4d1d7' 
      : variant === 'graylight' 
      ? 'var(--gray-light)' 
      : '#ccc'};
  background-color: ${({ variant }) => 
    variant === 'gray' || variant === 'graylight' ? '#fff' : '#f0f0f0'};
  
  ::placeholder {
    font-weight: normal;
    font-size: 12px;
    color: ${({ variant }) => 
      variant === 'gray' || variant === 'graylight' ? '#d4d1d7' : '#a0a0a0'};
  }

  &:focus {
    border: ${({ variant, isError }) => 
      isError 
        ? '2px solid var(--pink-contentoh)' 
        : variant === 'gray' 
        ? '1px solid #b0b0b0' 
        : variant === 'graylight' 
        ? '1px solid var(--pink-contentoh)' 
        : '1px solid #808080'};
    outline: none;
  }

  &:disabled {
    background-color: #f5f5f5;
    color: #a0a0a0;
    border: 1px solid #e0e0e0;
  }
`;