import styled from "styled-components";
import "../../../global-files/global-styles.css";
import { GlobalColors, FontFamily } from "../../../global-files/variables";

export const Container = styled.div`
  background-color: #f0eef2;
  border-radius: 3px;
  display: flex;
  width: fit-content;
  height: 26px;
  padding: 5px;
  align-items: center;

  .feature-name {
    font-size: 14px;
    font-family: ${FontFamily.Raleway_500};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: ${GlobalColors.original_magenta};

    & + * {
      margin-left: 5px;
    }
  }
  .feature-value {
    font-family: ${FontFamily.AvenirNext};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    height: 12px;
    letter-spacing: -0.015em;
    color: ${GlobalColors.s4};
  }

  &:last-of-type {
    p {
      font-size: 24px;
    }
  }
`;
