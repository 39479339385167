import axios from "axios";
import { TEMPLATES_URLS } from "../../../constants/templates_upload";
import { fetchCategories } from "../utils";

export const getDownloadTemplateURL = ({ isRetailer, isTHDFinanced, isBodegaFinanced, userFinanced, isDropshipFinanced, isOnboarding }) => {
  if (isRetailer && isTHDFinanced) return TEMPLATES_URLS.retailerTHD;
  if (isRetailer && isBodegaFinanced) return TEMPLATES_URLS.retailerBodega;
  if (userFinanced && isTHDFinanced) return TEMPLATES_URLS.providerFinanced;
  if (userFinanced && isBodegaFinanced) return TEMPLATES_URLS.providerBodega;
  if (userFinanced && isDropshipFinanced) return TEMPLATES_URLS.providerMerchants;
  if (userFinanced && isOnboarding) return TEMPLATES_URLS.onboarding;
  return TEMPLATES_URLS.provider;
};
export const getBaseHeaders = (userFinanced, isTHDFinanced, isBodegaFinanced, isRetailer) => {
  if (userFinanced) {
    if (isTHDFinanced) return ["SKU", "UPC", "Nombre de Producto", "Categoría", "Cadenas"];
    if (isBodegaFinanced) return ["UPC", "Nombre de Producto", "Categoría", "Cadenas"];
    return ["UPC", "Nombre de Producto", "Categoría"];
  }
  return isRetailer ? ["UPC", "Nombre de Producto", "Categoría", "Compañias"] : ["UPC", "Nombre de Producto", "Categoría", "Cadenas"];
};

export const checkDuplicates = async (articles) => {
  const duplicateChecks = articles.map(article => checkDuplicateUPC(article.upc));
  return Promise.all(duplicateChecks);
};

export const mapArticlesByUPC = (articles) => {
  return articles.reduce((acc, article) => {
    acc[article.upc] = article;
    return acc;
  }, {});
};
export const handleCategoryParsing = (col, article, lists) => {
  const [department, category, type] = col.split("|");
  const departmentList = Object.keys(lists.categories);
  const categoryList = Object.keys(lists.categories[department]?.sub_category || {});
  const typeList = Object.keys(lists.categories[department]?.sub_category[category]?.sub_category || {});


  article.depot = department;
  article.category = category;
  article.type = type;
  article.lists = { depot: departmentList, category: categoryList, type: typeList };
};

export const assignCategoryIds = (obj, arrayCat, categories) => {
  const arrayId = [];
  Object.keys(obj).forEach((key, i) => {
    const [department, category, type] = arrayCat[i].split("|");
    const id = categories[department].sub_category[category].sub_category[type]?.id;
    arrayId.push(id);
    obj[key].id = id;
  });
  return arrayId;
};

export const validateRetailerCategories = async (obj, arrayId) => {
  const config = { headers: { Authorization: sessionStorage.getItem("jwt") } };
  const body = { product_type_ids: arrayId };
  const res = await axios.post(process.env.REACT_APP_RETAILER_ENDPOINT, body, config);

  Object.keys(obj).forEach((key, i) => {
    obj[key].retailers = res.data.body[i];
  });
};
export const checkDuplicateUPC = async (data) => {
  try {
    const isDuplicated = await fetchExistingUPCs(data);
    return isDuplicated;
  } catch (error) {
    console.error("Error in checkDuplicateUPC:", error);
    return false; 
  }
};

const fetchExistingUPCs = async (upc) => {
  const dato=upc.upc
  const company = JSON.parse(sessionStorage.getItem("company"));
  const id_company = company.id_company;
  try {
    const response = await axios.get(`${process.env.REACT_APP_UPC}?upc=${dato}&company=${id_company}`);
    const parsedBody = JSON.parse(response.data.body);
    return parsedBody.upcIsDuplicated;
  } catch (error) {
    console.error("Error al buscar UPCs:", error);
    return false;
  }
};

export const setProviderRetailer = (isRetailer, lists, col) => {
  if (isRetailer) {
    return lists.retailers[col] || null;
  }
  return lists.providers[col] || null;
};

export const validateUPC = ({
  valueUpc,
  valueCompany,
  isRetailer,
  index,
  alertDuplicate,
  setNewArticle
}) => {
  const params = buildParams(valueUpc, valueCompany, isRetailer);
  
  const reducerAction = buildReducerAction(valueUpc, valueCompany, index, isRetailer);

  checkDuplicateUPC(params)
    .then((isDuplicate) => {
      handleDuplicateResult(isDuplicate, reducerAction, index, alertDuplicate, setNewArticle);
    })
    .catch((error) => {
      console.error('Error checking UPC:', error);
      alertDuplicate({ error: true, message: 'Error checking UPC' });
    });
};


const buildParams = (upc, company, isRetailer) => {
  const params = { upc: upc };
  if (isRetailer) {
    params.company = company;
  }
  return params;
};

const buildReducerAction = (upc, company, index, isRetailer) => {
  const reducerAction = { index, value: upc };
  if (isRetailer) {
    reducerAction.provider = company;
    reducerAction.isRetailer = isRetailer;
  }
  return reducerAction;
};

const handleDuplicateResult = (isDuplicate, reducerAction, index, alertDuplicate, setNewArticle) => {
  if (index === undefined) {
    alertDuplicate(isDuplicate);
    if (setNewArticle) {
      setNewArticle((prev) => ({
        ...prev,
        upc: reducerAction.value,
        company: reducerAction.provider,
        error: isDuplicate, 
      }));
    }
  } else {
    reducerAction.action = isDuplicate ? 'DbDuplicateUPC' : 'upc';
    reducerAction.error = isDuplicate;
    alertDuplicate(reducerAction);
  }
};

export const getGroupedOptions = (transformedOptions) => {
  const depotOptions = [];
  const categoryOptions = [];
  const typeOptions = [];

  transformedOptions.forEach(option => {
      if (!depotOptions.some(item => item.value === option.depot)) {
          depotOptions.push({ label: option.depot, value: option.depot });
      }
      if (!categoryOptions.some(item => item.value === option.category)) {
          categoryOptions.push({ label: option.category, value: option.category });
      }
      if (!typeOptions.some(item => item.value === option.type)) {
          typeOptions.push({ label: option.type, value: option.type });
      }
  });

  return { depotOptions, categoryOptions, typeOptions };
};

export const createArticles = async (params = {}) => {
  const func = async () => {
    return axios.post(process.env.REACT_APP_ARTICLE_ENDPOINT, params.data, {
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });
  };

  return await func();
};

export const validateForm = (newArticle) => {
  const requiredFields = ['upc', 'name', 'depot', 'category', 'type'];
  return requiredFields.every(field => {
      const value = newArticle[field];
      return value && value.trim() !== '';
  });
};

export const handleBlur = (e, company, addNotification, setNewArticle, articles, isRetailer, setIsUpcDuplicate) => {
  const { id, value } = e.target;
  const id_company = company.id_company;
  if (id === 'upc') {
      if (checkDuplicateUPCTable(value, articles)) {
          addNotification('error', 'El UPC ya existe en la tabla.');
          return;
      }
      validateUPC({
          valueUpc: value,
          valueCompany: id_company, 
          isRetailer, 
          index: undefined,
          alertDuplicate: (isDuplicate) => {
              if (isDuplicate === true) {
                setIsUpcDuplicate(true);
                  addNotification('error', 'El UPC ya existe en la base de datos.');
              }
          },
          setNewArticle,
      });
  }
};


export const handleUploadProducts = async (selectedFile, articles, addArticle, addNotification) => {
  try {
      const response = await createArticles(articles);

      
  } catch (error) {
      console.error("Error al subir productos:", error);
      addNotification('error', 'Error al subir productos.');
  }
};

export const checkDuplicateUPCTable = (upc, articles) => {
  return articles.some(article => article.upc === upc);
};


export const parseExcelData = (rows, articles, addNotification) => {
  const headers = rows.shift();
  const parsedArticles = [];
  const currentUpcs = new Set();
  const duplicates = new Set();

  rows.forEach((row) => {
    if (row[2] && row[2].includes("|")) {
      const [depot, category, type] = row[2].split("|");
      const upc = row[0];
      const article = {
        upc: row[0],
        name: row[1],
        depot: depot,
        category: category,
        type: type,
        idCategory: `${depot}|${category}|${type}`,
        retailer: row[3],
      };

      if (currentUpcs.has(upc) || articles.some(article => article.upc === upc)) {
        duplicates.add(upc);
      } else {
        currentUpcs.add(upc);
        parsedArticles.push(article);
      }
    } else {
      addNotification('error', "El archivo no contiene el formato correcto por favor descarga la plantilla");
      console.error("Formato incorrecto en la fila", row);
    }
  });

  return { parsedArticles, duplicates };
};


export const checkForDuplicateUpc = (upc, articles, upcListExcel) => {
  const isDuplicateInArticles = articles.some(article => article.upc === upc);
  const isDuplicateInExcel = upcListExcel.includes(upc);
  return isDuplicateInArticles || isDuplicateInExcel;
};