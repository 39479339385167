import { Button } from "../../atoms/GeneralButton";
import { Container } from "./styles";
import closeIcon from "../../../assets/images/versionSelector/closeVersionSelector.svg";
import { useEffect, useState } from "react";
import { Children } from "./RenderChilds";
import axios from "axios";

export const CreateVersion = ({
  articleId,
  version,
  setShowCreateVersion,
  versionsList,
  realoadVersion,
  jwt,
}) => {
  const [step, setStep] = useState("version-options");
  const [selectedVersions, setSelectedVersions] = useState({
    datasheet: version,
    description: version,
    image: version,
  });

  const createVersion = async (isEmpty) => {
    try {
      let data = { articleId, isEmpty };

      if (!isEmpty) data["version"] = selectedVersions;

      await axios.post(process.env.REACT_APP_VERSIONS_ENDPOINT, data, {
        headers: { Authorization: jwt },
      });
      setShowCreateVersion(false);
      realoadVersion && realoadVersion();
    } catch (error) {
      console.log(error);
    }
  };

  const onChange = (e) => {
    setSelectedVersions({
      ...selectedVersions,
      [e.target.name]: parseInt(e.target.value),
    });
  };

  return (
    <Container>
      <div id="create-version" className="modal-container">
        <div className="button-container">
          <Button
            image={closeIcon}
            buttonType={"circular-button"}
            id="close-modal-button"
            onClick={() => setShowCreateVersion(false)}
          />
        </div>
        {Children(
          step,
          setStep,
          versionsList,
          selectedVersions,
          onChange,
          createVersion
        )}
      </div>
    </Container>
  );
};
