import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en } from './languages/en';
import { es } from './languages/es';

const userLang = navigator.language || navigator.userLanguage;
const defaultLang = userLang.substring(0, 2);
const lang = sessionStorage.getItem("lang") || defaultLang;

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: en
            },
            es: {
                translation: es
            }
        },
        lng: lang,
        fallbackLng: 'es',
        zinterpolation: {
            escapeValue: false
        }
    })