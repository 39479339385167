import { Container } from "./styles";

export const FeatureTag = ({ feature, value }) => {
  return (
    <Container>
      <p className="feature-name">{feature}</p>
      <p className="feature-value">{value}</p>
    </Container>
  );
};
