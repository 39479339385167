import styled from "styled-components";

export const Container = styled.div`
    min-width:200px;
    max-height:34px;
    .search-bar-filter {
        display: flex;
        align-items: center;
        border: 1px solid #f0f0f0;
        padding-left: 10px;
  
        img {
          width: 15px;
          height: 15px;
        }
  
        input {
          background-color: transparent;
          padding: 10px 10px;
          outline: none;
          border: none;
        }
      }

      img.icon{
        width: 15px;
          height: 15px;
      }

      .modal-save .contentModal {
        max-width:200px;
      }
`;
