import { Container } from "./styles";
import React from "react";

export const Button = ({
  id,
  buttonType,
  label,
  image,
  altText,
  buttonFont,
  onClick,
}) => {
  return (
    <Container
      id={id}
      className={buttonType}
      buttonFont={buttonFont}
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      key={`button-${buttonType}`}
      disabled={buttonType === "general-button-disabled"}
    >
      {buttonType.includes("general") && label}
      {image && <img src={image} alt={altText} />}
    </Container>
  );
};

Button.defaultProps = {
  buttonType: "purple-button",
  buttonFont: "Avenir Next",
};
