import styled from "styled-components";
export const MainContainer = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #f0f0f0;
    border-radius: 50px;
    padding: 9px;
    gap:10px;
    min-width: 250px;
  
  img {
    width: 14px;
    height: 14px;
  }
  
  
  input {
    font-family: Roboto;
    font-size: 12px;
    outline: none;
    border: none;
  }
  `;