export const saveUserSession = (userDetails, session) => {
    sessionStorage.setItem("auth", true);
    sessionStorage.setItem("jwt", session.signInUserSession.idToken.jwtToken);
    sessionStorage.setItem("user", JSON.stringify(userDetails.user));
    sessionStorage.setItem("company", JSON.stringify(userDetails.company));
    sessionStorage.setItem("merchantsCategories", JSON.stringify(userDetails.merchantsCategories));
  };
  
  export const clearCaches = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };
  