import { InviteUsersContainer } from "./styles";
import { CustomSelect } from "contentoh-components-library";
import { useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { sendInvitation, emailOnChange, changePosition, invitationOnChange } from "./utils";
import useNotifications from "../../../hooks/useNotification";
import axios from "axios";

const InviteUser = ({
  acces,
  categories,
  setShowInvite,
  inviteProviders,
  loadData,
  inviteMoreUsers
}) => {
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [accesSelected, setAccesSelected] = useState([]);
  const [email, setEmail] = useState("");
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [providersToInvite, setProvidersToInvite] = useState([{ email: "", userName: "" }]);
  const [usersToInvite, setUsersToInvite] = useState([{ email: "", userName: "" }]);
  const [position, setPosition] = useState("");
  const [loading, setLoading] = useState();
  const { notifications, addNotification, deleteNotification } = useNotifications();

  const onSubmit = async (body) => {
    try {
        const res = await axios.post(
          `${process.env.REACT_APP_INVITE_USER}`,
          body,
          {
            headers: {
              Authorization: sessionStorage.getItem("jwt"),
            },
          }
        );
        if (res.data.statusCode === 200) {
          setLoading(true);
          addNotification("error","Se envió a su correo una contraseña temporal")
          setLoading(true);
        } else {
          addNotification("error","Asegúrese que no se encuentra registrado actualmente")
        }
    } catch (err) {
      console.log(err);
    }
  };
  const handleSendInvitation = async () => {
    try {
      await sendInvitation({
        user,
        inviteProviders,
        providersToInvite,
        usersToInvite,
        email,
        categoriesSelected,
        position,
        onSubmit,
        loadData,
        inviteMoreUsers
      });
      addNotification(
        "success",
        "Invitación enviada correctamente.",
      );
      setShowInvite(false);
    } catch (error) {
      addNotification(
        "error",
        "Error al enviar la invitación.",
      );
    }
  };

  return (
    <InviteUsersContainer>
      <div className="form-container">
        <p>Invitar Usuarios</p>
        {inviteProviders ? (
          <div className="invitation-container">
            {providersToInvite.map((provider, index) => (
              <div className="invite-provider" key={index}>
                <input
                  value={provider.email}
                  type="email"
                  placeholder="ejemplo@correo.com"
                  onChange={(e) => invitationOnChange(e, "email", index, true, providersToInvite, setProvidersToInvite)}
                />
                <input
                  value={provider.userName}
                  type="text"
                  placeholder="Nombre"
                  onChange={(e) => invitationOnChange(e, "userName", index, true, providersToInvite, setProvidersToInvite)}
                />
              </div>
            ))}
            <button
              className="add-invitation"
              onClick={() => setProvidersToInvite((current) => [...current, { email: "", userName: "" }])}
            >
              + Añadir otra invitación
            </button>
          </div>
        ) : (
          <>
            {inviteMoreUsers ? (
              <div className="email-container">
                {usersToInvite.map((user, index) => (
                  <div key={index} className="invite-user">
                    <Input
                      sx={{
                        m: "8px 0",
                        width: "100%",
                        height: "30px",
                        border: "1px solid #F0F0F0",
                        borderRadius: "5px",
                        p: "0px 10px",
                        "&.MuiInput-underline:before, &.Mui-error:before, &.MuiInput-underline:hover": {
                          borderBottom: "none",
                          border: "none",
                        },
                        "&.MuiInput-underline:hover:not(.Mui-disabled, .Mui-error):before": {
                          border: "1px solid #F0F0F0",
                          borderRadius: "5px",
                          height: "30px",
                          p: "0px 10px",
                        },
                        "&.MuiInput-underline:after": {
                          borderBottom: "1px solid #E33AA9",
                        },
                        "&::placeholder": {
                          fontSize: "12px",
                        },
                      }}
                      placeholder="Correo electrónico"
                      value={user.email}
                      type="email"
                      onChange={(e) => invitationOnChange(e, "email", index, false, usersToInvite, setUsersToInvite)}
                      startAdornment={
                        <InputAdornment position="start" sx={{ border: "none" }}>
                          <MailOutlineIcon sx={{ color: "#CBCBCB", fontSize: "14px" }} />
                        </InputAdornment>
                      }
                    />
                  </div>
                ))}
                <button
                  className="add-invitation"
                  onClick={() => setUsersToInvite((current) => [...current, { email: "", userName: "" }])}
                >
                  + Añadir otra invitación
                </button>
              </div>
            ) : (
              <div className="email-container">
                {usersToInvite.map((user, index) => (
                  <div key={index} className="invite-user">
                    <Input
                      sx={{
                        marginTop: "8px",
                        width: "100%",
                        height: "30px",
                        border: "1px solid #F0F0F0",
                        borderRadius: "5px",
                        p: "0px 10px",
                        "&.MuiInput-underline:before, &.Mui-error:before, &.MuiInput-underline:hover": {
                          borderBottom: "none",
                          border: "none",
                        },
                        "&.MuiInput-underline:hover:not(.Mui-disabled, .Mui-error):before": {
                          border: "1px solid #F0F0F0",
                          borderRadius: "5px",
                          height: "30px",
                          p: "0px 10px",
                        },
                        "&.MuiInput-underline:after": {
                          borderBottom: "1px solid #E33AA9",
                        },
                        "&::placeholder": {
                          fontSize: "12px",
                        },
                      }}
                      placeholder="Correo electrónico"
                      value={user.email}
                      type="email"
                      onChange={(e) => invitationOnChange(e, "email", index, false, usersToInvite, setUsersToInvite)}
                      startAdornment={
                        <InputAdornment position="start" sx={{ border: "none" }}>
                          <MailOutlineIcon sx={{ color: "#CBCBCB", fontSize: "14px" }} />
                        </InputAdornment>
                      }
                    />
                  </div>
                ))}
              </div>
            )}
          </>
        )}
        <div className="inputs-container">
          {acces && (
            <div className="input-container">
              <p className="label">Acceso</p>
              <CustomSelect
                options={acces}
                showSearchBar={true}
                placeHolder={"Buscar accesos"}
                selectLabel={"Accesos"}
                customSelectId={"acces-select"}
                defaultOption={"Todos los Accesos"}
                parameterArray={accesSelected}
                setParameterArray={setAccesSelected}
              />
            </div>
          )}
          {categories && (
            <div className="input-container">
              <p className="label">Puesto</p>
              <input
                type="text"
                placeholder="Puesto"
                onChange={(e) => changePosition(e, setPosition)}
              />
            </div>
          )}
          {categories && (
            <div className="input-container">
              <p className="label">Departamento</p>
              <CustomSelect
                options={categories}
                showSearchBar={true}
                placeHolder={"Buscar departamento"}
                selectLabel={"Departamentos"}
                customSelectId={"category-select"}
                defaultOption={"Todos los departamentos"}
                parameterArray={categoriesSelected}
                setParameterArray={setCategoriesSelected}
              />
            </div>
          )}
        </div>
        <div className="buttons-container">
          <button className="cancel-button" onClick={() => setShowInvite(false)}>
            Cancelar
          </button>
          <button className="invite-button" onClick={handleSendInvitation}>
            Invitar
          </button>
        </div>
      </div>
    </InviteUsersContainer>
  );
};

export default InviteUser;
