import styled from "styled-components";
import { GlobalColors } from "../../../global-files/variables";

export const Container = styled.div`
  width: 340px;
  padding: 10px;
  border: 1px solid ${GlobalColors.s2};
  border-radius: 3px;

  .input-name-header,
  .table-row-text {
    color: ${({ darkMode }) => darkMode && GlobalColors.s1};
  }
  .gray-table-row {
    background-color: ${({ darkMode }) => darkMode && GlobalColors.s5};
  }
`;
