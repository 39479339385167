import React, { useState, useEffect, useRef } from "react";
import { Container } from "./styles";
import CustomButton from "../general/CustomButton";
import TableLarge from "../general/TableLarge";
import EditionTabs from "../general/EditionTabs";
import { useModal } from "../../hooks/useModal";
import useNotifications from "../../hooks/useNotification";
import Modal from "../general/Modal";
import { useLocation, useHistory } from "react-router-dom";
import { getDataTable, updateData } from "./utils";
import CustomNotification from "../general/CustomNotification";
import { useStore } from "../../stores/useStore";

const MultipleEdition = () => {
  const { modalData, openModal, closeModal, handleCancelClick } = useModal();
  const { notifications, addNotification, deleteNotification } =
    useNotifications();
  const [inputValue, setInputValue] = useState("");
  const [rejectMessage, setRejectMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedSection, setSelectedSection] = useState("description");
  const location = useLocation();
  const { articles } = location.state || {};
  const history = useHistory();
  const tableLeftRef = useRef(null);
  const [currentData, setCurrentData] = useState({
    headerBaseData: {},
    headerSectionData: {},
    initialData: {},
    descriptionsData: {},
  });
  const [initialCache, setInitialCache] = useState({
    initialData: [],
    headerBaseData: [],
  });

  const fetchDescriptions = async () => {
    const { initialData, descriptionsData, headerBaseData, headerSectionData } =
      await getDataTable(
        selectedSection,
        initialCache.initialData,
        initialCache.headerBaseData,
        articles
      );
    if (
      selectedSection === "description" &&
      !initialCache.initialData.length &&
      !initialCache.headerBaseData.length
    ) {
      setInitialCache({ initialData, headerBaseData });
    }
    setCurrentData({
      headerBaseData,
      headerSectionData,
      initialData,
      descriptionsData,
    });
  };

  useEffect(() => {
    fetchDescriptions();
  }, [selectedSection, articles]);

  const storedData = useStore((state) => state.currentData);
  const { articleID } = useStore();

  const updateSection = async (editedFields) => {
    const storedUser = sessionStorage.getItem("user");
    const userId = storedUser ? JSON.parse(storedUser).id_user : null;
    const version = 1;

    if (!userId) {
      throw new Error("Usuario no encontrado");
    }

    if (currentData.descriptionsData.length > 0) {
        const service = selectedSection;
        try {
          // const articleId = editedFields[0].articleId;
          // const articleData = editedFields.reduce((acc, item) => {
          //   const camposTransformados = Object.entries(item.fields).map(
          //     ([attributeId, detalles]) => ({
          //       attributeId,
          //       value: detalles.value,
          //       comment: detalles.comment,
          //       isApproved: detalles.isApproved,
          //     })
          //   );
          //   return acc.concat(camposTransformados);
          // }, []);

            const response = await updateData(userId, service, articleID, version, storedData);

        if (response.success) {
          fetchDescriptions();
          addNotification("success", "Atributos actualizados con éxito");
        } else {
          addNotification("error", "No se pudieron actualizar los atributos");
        }
      } catch (error) {
        addNotification("error", "Error al actualizar descripciones");
        console.error("Error:", error);
      }
    }
  };

  const scrollToLeft = () => {
    console.log(tableLeftRef.current);
    if (tableLeftRef.current) {
      tableLeftRef.current.scrollTo({
        left: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <Container>
      <div className="container-head">
        <EditionTabs getSection={(section) => setSelectedSection(section)} />
        <div className="action_buttons">
          <CustomButton
            onClick={scrollToLeft}
            variant="gray"
            showTooltip
            tooltipText="Regresa al inicio de la tabla"
            icon="arrow_back"
            iconOnly
          ></CustomButton>
          <CustomButton
            variant="gray"
            icon="close"
            iconOnly
            onClick={() => {
              history.goBack();
            }}
          />
        </div>
      </div>
      <div className="table">
        {currentData.initialData.length > 0 &&
        currentData.descriptionsData.length > 0 ? (
          <TableLarge
            ref={tableLeftRef}
            withCheckbox
            initialData={currentData.initialData}
            sectionsData={currentData.descriptionsData}
            headerBaseData={currentData.headerBaseData}
            headerSectionData={currentData.headerSectionData}
            selectedSection={selectedSection}
            onSave={updateSection}
            rejectMessage={rejectMessage}
          />
        ) : (
          <p>Loading data...</p>
        )}
      </div>
      <Modal {...modalData} onClose={handleCancelClick} />
      <CustomNotification
        open={notifications.length > 0}
        alerts={notifications}
        deleteNotification={deleteNotification}
      />
    </Container>
  );
};

export default MultipleEdition;
