import React, { useState, StrictMode } from "react";
import { Switch, BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import Main from "./components/main/index";
import Login from "./components/Login/index";
import "./index.css";
import { AuthProvider, useAuth } from "../src/components/Login/utils/AuthContext.js";
import Loading from "./components/general/loading/index";
import { initializeApp } from 'firebase/app';
import "./translate.config.js"

//Configuración de firebase
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

function App() {
  const [userType, setUserType] = useState(null);
  return (
    <StrictMode>
      <AuthProvider>
        <Router>
          <Switch>
            <Route path="/login" component={Login} />
            <PrivateRoute path="/" userType={userType} setUserType={setUserType} />
          </Switch>
        </Router>
      </AuthProvider>
    </StrictMode>
  );
}
export default App;

function PrivateRoute({ userType, setUserType, ...rest }) {
  const { authenticated, loading } = useAuth();

  if (loading) {
    return <Loading />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated ? (
          <Main {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}