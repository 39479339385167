import React from "react";
import {
  MainContainer,
  VersionFooter,
} from "./styles";
import ProviderPanel from "./ProviderPanel";
import UserPanel from "./UserPanel";
import useScreenSizes from "../../hooks/useScreenSizes";
import FloatLogo from "../general/FloatLogo";

const Login = () => {
  const { isMobile } = useScreenSizes();
  return (
    <MainContainer>
      {!isMobile && <ProviderPanel />}
      <UserPanel />
      <FloatLogo/>
      <VersionFooter>v.1.1</VersionFooter>
    </MainContainer>
  );
};

export default Login;
