import styled from "styled-components";
import { GlobalColors, FontFamily } from "../../../global-files/variables";

export const Container = styled.div`
  width: fit-content;
  min-width: min-content;
  min-height: min-content;
  overflow: hidden;
  cursor: default;
  display: grid;
  place-items: center;
  padding: 0px;
  border-radius: ${({ borderType }) => {
    return borderType?.toLowerCase() === "rectangle"
      ? "6px"
      : borderType?.toLowerCase() === "circle"
      ? "50%"
      : "0px";
  }};
  border: none;

  // colores de acuerdo al tipo de icono
  &.icon-word .icon {
    color: #1f3780;
    border-color: #1f3780;
  }
  &.icon-excel .icon {
    color: #2d5639;
    border-color: #2d5639;
  }
  &.icon-csv .icon {
    color: #6b8e73;
    border-color: #6b8e73;
  }
  &.icon-image .icon {
    color: #ca7f4d;
    border-color: #ca7f4d;
  }
  &.icon-pdf .icon {
    color: #951a1a;
    border-color: #951a1a;
  }
  &.icon-powerPoint .icon {
    color: #ba4f19;
    border-color: #ba4f19;
  }
  &.icon-zip .icon {
    color: #b8950c;
    border-color: #b8950c;
  }
  &.icon-code .icon {
    color: #1c778e;
    border-color: #1c778e;
  }
  &.icon-video .icon {
    color: #a15bb1;
    border-color: #a15bb1;
  }
  &.icon-audio .icon {
    color: #3090e9;
    border-color: #3090e9;
  }
  &.icon-default .icon {
    color: #5e6265;
    border-color: #5e6265;
  }
`;
