import checked from "../../../../assets/defaultImages/checkboxCustom/checked.svg";
import unchecked from "../../../../assets/defaultImages/checkboxCustom/unchecked.svg";

import { CheckboxCustomContainer } from "./styles";

export const CheckboxCustom = (props) => {
  return (
    <CheckboxCustomContainer
      checked={props.checked ? props.checked : checked}
      unchecked={props.unchecked ? props.unchecked : unchecked}
      size={props?.size}
      labelWidth={props?.labelWidth}
    >
      <input
        type="checkbox"
        name={props.name}
        id={props.id}
        onChange={props.onChange}
        onClick={props.onClick}
        defaultChecked={props.defaultChecked}
        register={props.register}
        ref={props.ref}
        disabled={props.disabled}
        className={props.className}
        checked={props.isChecked}
      />
      <label htmlFor={props.htmlFor}>{props.labelText}</label>
    </CheckboxCustomContainer>
  );
};
