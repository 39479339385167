import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 15px;
  overflow: auto;
`;

export const LeftContainer = styled.div`
  width: ${(props) => (props.showMenuText ? '390px' : 'auto')};
  position: relative;
  border-radius: 7px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  transition: width 2s ease;
`;


export const MainData = styled.div`
  box-sizing: border-box;
`;

export const DataItem = styled.div`
  display: flex;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.015em;
  padding: 0 30px;

  figure {
    & + * {
      margin-left: 11px;
    }
  }

  .info-container {
    display: flex;
    width: 100%;

    p:first-child {
      width: 117px;
      color: #503d66;

      & + p {
        margin-left: 20px;
        color: #817393;
      }
    }
  }

  & + * {
    margin-top: 12px;
  }

  & + ul {
    margin-top: 24px;
  }
`;

export const RightContainer = styled.div`
  margin-right: 30px;
  padding-left: 20px;
  box-sizing: border-box;
  flex: 0%;
  overflow: auto;
`;

export const CurrentPlan = styled.div`
  padding: 20px;
  background: #fafafa;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;

  .plan-info-container {
    h2 {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      font-feature-settings: "pnum" on, "lnum" on;
      color: #ba0070;

      .used-products {
        color: #d4d1d7;
        border-right: 2px solid var(--pink-contentoh);
        padding-right: 10px;

        & + * {
          margin-left: 10px;
        }
      }

      .products-limit {
        color: #503d66;

        & + * {
          margin-left: 20px;
        }
      }

      .products-title {
        color: #817393;
      }
    }

    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #817393;
      & + * {
        margin-top: 5px;
      }
    }
  }

  .plan-button-container {
    display: flex;
    align-items: center;
  }
`;

export const InviteUsersContainer = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.35);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 100;
  .modal-enviado{
    max-width: 250px;
    background: none;
  }

  .form-container {
    height: fit-content;
    border-radius: 10px;
    background-color: var(--white-contentoh);
    padding: 15px 20px;
    margin-top: 150px;
    min-width:328px;

    p {
      color: #262626;
      font-family: Roboto;
      font-size: 17px;
    }

    input {
      border: 1px solid #f0f0f0;
      border-radius: 5px;
      height: 28px;
      padding-left: 10px;
    }

    .invitation-container {
      margin-top:10px;
      .invite-provider {
        input {
          width: 200px;

          & + * {
            margin-left: 10px;
          }
        }

        & + * {
          margin-top: 10px;
        }
      }
    }
    .add-invitation {
      background-color: transparent;
      color: var(--pink-contentoh);
      font-family: Roboto;
      font-size: 10px;
      border: none;
      cursor: pointer;
    }

    .email-container {
      input {
        width: 300px;
        border:none;
        font-size: 12px;
        font-family:Roboto, sans-serif;
      }
      input:before{
        border-bottom: 0px solid rgba(0, 0, 0, 0.42);
      }

      & + * {
        margin-top: 10px;
      }
    }

    .inputs-container {
      .input-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-size: 12px;
        }

        input {
          height: 24px;
          font-size: 12px;
        }

        #category-select {
          .button-list {
            padding: 5px 10px;
            border: none;
            border-radius: 3px;

            p {
              color: #808080;
            }

            &:hover {
              background-color: #f0f0f0;
            }
          }
        }

        & + * {
          margin-top: 10px;
        }
      }

      & + * {
        margin-top: 10px;
      }
    }
    .buttons-container {
      display: flex;
      justify-content: flex-end;

      button {
        border-radius: 17px;
        font-family: Roboto;
        font-size: 12px;
        background-color: transparent;
        border: 1px solid var(--pink-contentoh);
        color: var(--pink-contentoh);
        padding: 5px 10px;

        & + * {
          margin-left: 10px;
        }

        &.invite-button {
          background-color: var(--pink-contentoh);
          color: var(--white-contentoh);
        }
      }
    }
  }
`;
