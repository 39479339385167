import styled from "styled-components";
import { GlobalColors, FontFamily } from "../../../global-files/variables";

export const Container = styled.i`
  width: fit-content;
  min-width: min-content;
  cursor: default;
  display: grid;
  place-items: center;
  font-size: ${({ size }) => (size ? size : "13")}px;
  border-radius: ${({ borderType }) => {
    return borderType?.toLowerCase() === "rectangle"
      ? "6px"
      : borderType?.toLowerCase() === "circle"
      ? "50%"
      : "0px";
  }};
  padding: ${({ borderType }) => {
    return ["rectangle", "circle"].includes(borderType?.toLowerCase())
      ? "5px"
      : "0px";
  }};
  border: ${({ borderType, transparent }) => {
    return ["rectangle", "circle"].includes(borderType?.toLowerCase())
      ? "1px solid black"
      : "none";
  }};

  // tipos de iconos fill
  &.fill {
    &.white {
      background-color: white;
      color: #e33aa9;
      border-color: #e33aa9;
    }
    &.whiteS2 {
      background-color: white;
      color: #7b7979;
      border-color: #f0f0f0;
    }
    &.pink {
      background-color: #e33aa9;
      color: white;
      border-color: #e33aa9;
    }
    &.gray {
      background-color: #e8e5e5;
      border-color: #7b7979;
      color: #7b7979;
    }
    &.purple {
      background-color: #f7f7fc;
      color: #8a6caa;
      border-color: #8a6caa;
    }
  }

  // tipos de iconos transparent
  &.transparent {
    &.white {
      color: white;
      border-color: #e33aa9;
    }
    &.whiteS2 {
      color: #7b7979;
      border-color: #f0f0f0;
    }
    &.pink {
      color: #e33aa9;
      border-color: #e33aa9;
    }
    &.gray {
      color: #7b7979;
      border-color: #7b7979;
    }
    &.purple {
      color: #8a6caa;
      border-color: #8a6caa;
    }
    &.black {
      color: #262626;
      border-color: #262626;
    }
  }
`;
