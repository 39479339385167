import styled from "styled-components";
import { FontFamily, GlobalColors } from "../../../global-files/variables";

export const Container = styled.div`
  width: 100%;

  > div {
    label {
      &:before {
        outline: 1px solid ${({ isRequired }) => (isRequired ? "red" : "none")};
      }
    }
  }

  input,
  textarea {
    width: 100%;
    border: 1px solid
      ${({ isRequired }) => (isRequired ? "red" : `${GlobalColors.s2}`)};

    font-family: ${FontFamily.AvenirNext};
    color: ${GlobalColors.deep_gray};
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    padding: 10px;
    outline: none;
    border-radius: 5px;
    resize: none;

    &:focus {
      border: 1px solid ${GlobalColors.magenta_s2};
    }
  }

  select {
    background: #fafafa;
    outline: none;
    border: 1px solid
      ${({ isRequired }) => (isRequired ? "red" : `${GlobalColors.s2}`)};
    width: 100%;
    cursor: pointer;
    font-family: ${FontFamily.AvenirNext};
    color: ${GlobalColors.deep_gray};
    font-size: 12px;
    line-height: 21px;
    padding: 10px;
    border-right: 2px solid #e33aa9;

    &:focus {
      border: 1px solid ${GlobalColors.magenta_s2};
    }
  }

  p {
    font-family: ${FontFamily.Raleway};
    font-size: 12px;
    color: ${GlobalColors.deep_gray};
  }

  &.audit-class {
    input,
    .quill {
      border: 1px solid #8a6caa;
      background-color: #ededfc;
    }
  }

  .btnReviewState {
    display: "flex";
    flex-direction: "column";
    border: 1px solid lightgray;
    border-radius: 3px;
    z-index: 1000;
    /* position: absolute; */
  }

  .btnReviewState button {
    border: none;
    background: white;
    border-radius: 3px;
  }
  .btnReviewState button:hover {
    background: lightgray;
  }
`;
