import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import CustomNotification, { NOTIFICATION_SUCCESSFUL, NOTIFICATION_ERROR } from "../../general/CustomNotification";
import { FormularioChange, ItemRegister, RowButton, Row, Button } from "./styles";
import ErrorLabel from "../../general/ErrorLabel";
import PasswordValidation from "../../Login/PasswordValidation";
import { useHistory } from "react-router-dom";
import edit from "../../../assets/IconComponents/edit.svg";
import password from "../../../assets/IconComponents/password.svg";
import { MainContainer, Header, TitleIcon, BasicData } from "./styles";
import useNotifications from "../../../hooks/useNotification";
import HeaderData from "../HeaderData";
import CustomInput from "../../general/CustomInput"; // Asegúrate de importar CustomInput

const ChangePass = (props) => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [stage, setStage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [emptyEmail, setEmptyEmail] = useState("");
  const [emptyCode, setEmptyCode] = useState("");
  const [emptyNewPassword, setEmptyNewPassword] = useState("");
  const [showErrors, setShowErrors] = useState(false);
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const { notifications, addNotification, deleteNotification } = useNotifications();
  const [active, setActive] = useState(true);

  const handleSendCode = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      await Auth.forgotPassword(email);
      setStage(2);
      addNotification("success", "Código enviado con éxito. Revisa tu correo.");
    } catch (error) {
      setError(error.message);
      addNotification("error", "Error al enviar el código: " + error.message);
    }
    setLoading(false);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword);
      setLoading(false);
      addNotification("success", "Contraseña restablecida con éxito.");
      setStage(1);
    } catch (error) {
      setError(error.message);
      addNotification("error", "Error al restablecer la contraseña: " + error.message);
    }
    setLoading(false);
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setNewPassword(value);
    validatePassword(value);
    validateForm();
  };

  const validatePassword = (password) => {
    setEmptyNewPassword(password.length === 0);
    setIsLengthValid(password.length >= 8);
    setHasUpperCase(/[A-Z]/.test(password));
    setHasLowerCase(/[a-z]/.test(password));
    setHasNumber(/\d/.test(password));
    setHasSpecialChar(/[!@#$%^&*(),.?":{}|<>]/.test(password));
    validateForm();
  };

  useEffect(() => {
    validateForm();
  }, [newPassword, isLengthValid, hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar]);

  const validateForm = () => {
    const isPasswordValid =
      isLengthValid &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber &&
      hasSpecialChar;

    setIsFormValid(isPasswordValid);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  return (
    <MainContainer>
      <HeaderData icon={password} text="Cambiar contraseña" active={active} setActive={setActive} showEdit={false}/>
      {stage === 1 ? (
        <FormularioChange onSubmit={handleSendCode}>
            <Row>
            <p className="item">Correo electrónico</p>
              <ItemRegister>
                <CustomInput
                  id="emailInput"
                  placeholder="Escribe tu correo electrónico"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmptyEmail(e.target.value.trim() === "");
                  }}
                  required
                />
              </ItemRegister>
              <Button type="submit" onClick={handleSendCode}>Enviar Código</Button>
            </Row>
            <ErrorLabel
              showErrors={showErrors}
              condition={emptyEmail}
              message="Ingrese su correo electrónico"
            />
        </FormularioChange>
      ) : (
        <FormularioChange onSubmit={handleResetPassword}>
            <Row>
            <p className="item">Código</p>
              <ItemRegister>
                <CustomInput
                  id="codeInput"
                  placeholder="Escribe el código enviado a tu correo"
                  type="text"
                  value={code}
                  onChange={(e) => {
                    setCode(e.target.value);
                    setEmptyCode(e.target.value.trim() === "");
                  }}
                  required
                />
              </ItemRegister>
            </Row>
            <ErrorLabel
              showErrors={showErrors}
              condition={emptyCode}
              message="Ingrese el código que se envió a su correo"
            />
            <Row>
            <p className="item">Nueva Contraseña</p>
              <ItemRegister>
                <CustomInput
                  id="passwordInput"
                  placeholder="Escribe la nueva contraseña"
                  type={showNewPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={handlePasswordChange}
                  required
                />
                <span
                  onClick={toggleConfirmPasswordVisibility}
                  className="material-icons"
                  style={{
                    cursor: "pointer",
                    color: "#CBCBCB",
                    fontSize: "18px",
                  }}
                >
                  {showNewPassword ? "visibility_off" : "visibility"}
                </span>
              </ItemRegister>
            </Row>
            <ErrorLabel
              showErrors={showErrors}
              condition={emptyNewPassword}
              message="Ingrese su nueva contraseña"
            />
            <PasswordValidation
              isLengthValid={isLengthValid}
              hasUpperCase={hasUpperCase}
              hasLowerCase={hasLowerCase}
              hasNumber={hasNumber}
              hasSpecialChar={hasSpecialChar}
            />
            <RowButton>
              <Button type="submit" disabled={!isFormValid} onClick={handleResetPassword}>Restablecer Contraseña</Button>
            </RowButton>
          {error && <ErrorLabel message={error} />}
        </FormularioChange>
      )}
       <CustomNotification
        open={notifications.length > 0}
        alerts={notifications}
        deleteNotification={deleteNotification}
      />
    </MainContainer>
  )
}

export default ChangePass;
