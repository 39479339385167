import { Container } from "./styles";
import { ProductImage } from "../../atoms/ProductImage";
import { ImageDataTable } from "../ImageDataTable";
import { ImageSelector } from "../../molecules/ImageSelector";
import { useState, useEffect } from "react";
import { ValidationPanel } from "../../atoms/ValidationPanel";
import { Button } from "../../atoms/GeneralButton";

export const ProductImageModal = ({
  images = [],
  headerInfo,
  validation,
  setShowModal,
  sendToFacilitator,
  approveRejectButtons = false,
}) => {
  const [activeImage, setActiveImage] = useState(0);
  const [buttonType, setButtonType] = useState(
    validation ? validation : "null-button"
  );
  const [showValidationPanel, setShowValidationPanel] = useState(false);

  const closeValidationPanel = (e) => {
    if (
      !e.target.closest("#validation-panel") ||
      (e.target.closest(".validation-option") && showValidationPanel)
    ) {
      document.removeEventListener("click", closeValidationPanel, false);
      setShowValidationPanel(false);
    }
  };

  useEffect(() => {
    if (showValidationPanel) {
      document.addEventListener("click", closeValidationPanel, false);
    }
  }, [showValidationPanel]);

  return (
    <Container>
      <div className="modal-container">
        <div className="modal-image-container">
          <ProductImage
            img={
              "https://" +
              process.env.REACT_APP_IMAGES_BUCKET +
              ".s3.amazonaws.com/" +
              images?.values[activeImage]?.srcDB
            }
            altText={images?.values[activeImage]?.name}
            imageType={"expanded-modal-image"}
          />
        </div>
        <div className="modal-data-container">
          <div className="validation-and-table-container">
            <div className="validation-panel">
              {approveRejectButtons && (
                <Button
                  buttonType={`circular-button ${buttonType}`}
                  onClick={() => setShowValidationPanel(true)}
                />
              )}
              <Button
                buttonType={"close-button"}
                onClick={() => {
                  setShowModal(false);
                }}
              />

              {showValidationPanel && (
                <ValidationPanel
                  id="validation-panel"
                  setOptionClicked={setButtonType}
                  setShowValidationPanel={setShowValidationPanel}
                  approve={() => sendToFacilitator && sendToFacilitator("A")}
                  reject={() => sendToFacilitator && sendToFacilitator("R")}
                />
              )}
            </div>

            <ImageDataTable
              imageData={images?.values[activeImage]?.data}
              headerInfo={headerInfo}
              activeImage={images?.values[activeImage]}
              darkMode={true}
              lists={images}
            />
          </div>
          <ImageSelector
            images={images}
            setActiveImage={setActiveImage}
            modalSelector={true}
          />
        </div>
      </div>
    </Container>
  );
};
