import { Container } from "./styles";

export const Status = ({ statusType, ovalForm }) => {
  return (
    <Container
      className={`status-${statusType?.replace(/\/.*/, "")} ${
        ovalForm && "oval-form"
      }`}
    >
      <p>{statusType ?? "NS"}</p>
    </Container>
  );
};
