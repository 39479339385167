import React from 'react';
import { Error } from '../../Login/styles';

const ErrorLabel = ({ showErrors, condition, message }) => {
  if (showErrors && condition) {
    return (
      <Error>
        {message}
      </Error>
    );
  }
  return null;
};

export default ErrorLabel;
