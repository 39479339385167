import { Alert, Snackbar } from "@mui/material";
import { MainNotification } from "./styles";

export const NOTIFICATION_SUCCESSFUL = "success";
export const NOTIFICATION_ERROR = "error";

export default function CustomNotification({
  open = true,
  alerts = [], // [ { id , type , message } ]
  deleteNotification, 
  ...props
}) {
  return (
    <MainNotification>
      {alerts.map((a) => (
        <Snackbar
          key={a.id}
          open={open}
          autoHideDuration={null}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          className={props.className}
          style={{ position: "static" }}
        >
          <Alert
            className="alert-container"
            severity={a.type}
            onClose={() => {
              deleteNotification(a.id);
            }}
          >
            {a.message}
          </Alert>
        </Snackbar>
      ))}
    </MainNotification>
  );
}
