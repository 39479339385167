import React from "react";
import { Container } from "./styles";

const AvisoPrivacidad = () => {
  return (
    <Container>
      <h2>Servicios profesionales de innovación S.A. de C.V.</h2><br/>
      <p>Fecha de última actualización: 19 de Julio 2024</p>
      <p>
        En Servicios profesionales de innovación S.A. de C.V. (en adelante "Servicios profesionales de innovación S.A. de C.V."), nos comprometemos
        a proteger la privacidad y los datos personales de nuestros usuarios,
        clientes y empleados.
      </p>
      <p>
        Este Aviso de Privacidad explica cómo recopilamos, utilizamos,
        protegemos y compartimos su información personal.
      </p>
      <ol>
        <li>
          <b>Responsable del Tratamiento de Datos Personales</b><br/> Contentoh, con
          domicilio en Cto. Interior Melchor Ocampo 469, Anzures, Miguel Hidalgo, 11590 Ciudad de México, CDMX, 
          es el responsable del tratamiento de sus datos personales.
        </li>
        <li>
          <b>Datos Personales que Recopilamos</b><br/> Podemos recopilar los siguientes
          datos personales:
          <ul>
           <li>Nombre completo</li>
           <li>Dirección de correo electrónico</li>
           <li>Número de teléfono</li>
           <li>Información de pago </li>
           <li>Dirección física</li>
           <li>Información demográfica</li>
           <li>Información sobre el uso de nuestros servicios y productos</li>
          </ul> 
        </li>
        <li>
          <b>Finalidades del Tratamiento de Datos Personales</b><br/> Recopilamos y
          utilizamos sus datos personales para las siguientes finalidades:<br/>
          Proveer los servicios y productos solicitados Facturación y cobro
          Enviar información y actualizaciones sobre nuestros productos y
          servicios Mejorar nuestros servicios y personalizar la experiencia del
          usuario Cumplir con obligaciones legales y regulatorias
        </li>
        <li>
          <b>Transferencias de Datos Personales</b><br/> Servicios profesionales de innovación S.A. de C.V. no venderá, arrendará ni
          transferirá sus datos personales a terceros sin su consentimiento
          previo. Sin embargo, podemos compartir su información con: Proveedores
          de servicios que nos asisten en nuestras operaciones comerciales
          Autoridades competentes cuando sea necesario para cumplir con una
          obligación legal.
        </li>
        <li>
          <b>Derechos del Titular de los Datos Personales.</b><br/> Como titular de los datos
          personales, tiene derecho a: Acceder a sus datos personales.<br/> Rectificar
          sus datos personales cuando sean inexactos o incompletos.<br/> Cancelar sus
          datos personales cuando considere que no se requieren para alguna de
          las finalidades señaladas en este aviso.<br/> Oponerse al tratamiento de sus
          datos personales para fines específicos Para ejercer estos derechos,
          puede contactarnos a través del correo electrónico etc@contentoh.com.
        </li>
        <li>
          <b>Medidas de Seguridad</b><br/> En Contentoh implementamos medidas de seguridad
          administrativas, técnicas y físicas para proteger sus datos personales
          contra pérdida, robo, acceso no autorizado, divulgación, alteración y
          destrucción.
        </li>
        <li>
          <b>Cambios en el Aviso de Privacidad</b><br/> Contentoh se reserva el derecho de
          actualizar o modificar este Aviso de Privacidad en cualquier momento.
          Cualquier cambio será publicado en nuestra página web https://www.contentoh.com/.
        </li>
        <li>
          <b>Contacto</b><br/> Si tiene alguna pregunta o inquietud sobre este Aviso de
          Privacidad, puede contactarnos a través del correo electrónico etc@contentoh.com.
        </li>
      </ol>
      <br/>
      <p><b>Servicios profesionales de innovación S.A. de C.V.</b></p>
    </Container>
  );
};

export default AvisoPrivacidad;
