import React from 'react';
import { Container } from './styles';

const CheckBox = ({
  id,
  className = '',
  typeColor,
  label,
  disabled = false,
  checked,
  onChange,
}) => {
  return (
    <Container className={className} typeColor={typeColor}>
      <input
        type="checkbox"
        id={id}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id}>
        {label && <p>{label}</p>}
      </label>
    </Container>
  );
};

export default CheckBox;
