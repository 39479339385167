import React from "react";
import { Container } from "./styles";

const Terminos = () => {
  return (
    <Container>
      <h2>Servicios profesionales de innovación S.A. de C.V.</h2>
      <br />
      <p>Fecha de última actualización: 19 de Julio 2024</p>
      <p>Bienvenido a Servicios profesionales de innovación S.A. de C.V.</p>
      <p>
        Estos Términos y Condiciones regulan el uso de nuestro sitio web y
        servicios. Al acceder y utilizar nuestro sitio web, aceptas cumplir con
        estos Términos y Condiciones.
      </p>
      <ol>
        <li>
          <p>
            <b>Aceptación de los Términos</b>
          </p>
          Al acceder y utilizar el sitio web de Contentoh, aceptas estos
          Términos y Condiciones en su totalidad. Si no estás de acuerdo con
          alguna parte de estos términos, debes dejar de usar nuestro sitio web
          de inmediato.
        </li>
        <li>
          <p>
            <b>Uso del Sitio Web</b>
          </p>
          El uso de nuestro sitio web es bajo tu propio riesgo. Nos reservamos
          el derecho de modificar, suspender o descontinuar el sitio web en
          cualquier momento, con o sin previo aviso. No seremos responsables por
          cualquier modificación, suspensión o interrupción del sitio web.
        </li>
        <li>
          <p>
            <b>Propiedad Intelectual</b>
          </p>
          Todo el contenido presente en este sitio web, incluyendo textos,
          gráficos, logotipos, imágenes y software, es propiedad de Contentoh o
          de sus licenciantes y está protegido por las leyes de propiedad
          intelectual. No se permite el uso, reproducción o distribución del
          contenido sin nuestro permiso previo por escrito.
        </li>
        <li>
          <p>
            <b>Conducta del Usuario</b>
          </p>
          Al utilizar nuestro sitio web, te comprometes a no:
          <br />
          Violar cualquier ley o regulación aplicable Publicar o transmitir
          contenido ilegal, ofensivo o que infrinja los derechos de terceros
          Interferir con el funcionamiento del sitio web o con el acceso de
          otros usuarios Usar el sitio web para enviar publicidad no solicitada
          (spam)
        </li>
        <li>
          <p>
            <b>Enlaces a Sitios de Terceros</b>
          </p>
          Nuestro sitio web puede contener enlaces a sitios web de terceros.
          Estos enlaces se proporcionan únicamente para tu conveniencia y no
          implican que respaldemos esos sitios. No tenemos control sobre el
          contenido de los sitios web de terceros y no asumimos ninguna
          responsabilidad por ellos.
        </li>
        <li>
          <p>
            <b>Limitación de Responsabilidad</b>
          </p>
          Contentoh no será responsable por cualquier daño directo, indirecto,
          incidental, especial o consecuente que resulte del uso o la
          imposibilidad de usar nuestro sitio web o servicios. Esta limitación
          de responsabilidad se aplicará en la medida máxima permitida por la
          ley.
        </li>
        <li>
          <p>
            <b>Indemnización</b>
          </p>
          Aceptas indemnizar y eximir de responsabilidad a Contentoh y a sus
          empleados, directores, agentes y licenciantes de cualquier
          reclamación, pérdida, daño, responsabilidad, costos y gastos
          (incluyendo honorarios legales) que surjan del uso de nuestro sitio
          web o del incumplimiento de estos Términos y Condiciones.
        </li>
        <li>
          <p>
            <b>Cambios en los Términos y Condiciones</b>
          </p>
          Nos reservamos el derecho de modificar estos Términos y Condiciones en
          cualquier momento. Cualquier cambio será efectivo inmediatamente
          después de su publicación en nuestro sitio web. Es tu responsabilidad
          revisar periódicamente estos términos para estar al tanto de cualquier
          modificación.
        </li>
        <li>
          <p>
            <b>Ley Aplicable y Jurisdicción</b>
          </p>
          Estos Términos y Condiciones se rigen por las leyes de México y
          cualquier disputa relacionada con ellos será resuelta exclusivamente
          en los tribunales de CDMX, México.
        </li>
        <li>
          <p>
            <b>Contacto</b>
          </p>
          Si tienes alguna pregunta o inquietud sobre estos Términos y
          Condiciones, puedes contactarnos a través del correo electrónico
          etc@contentoh.com.
        </li>
      </ol>
    </Container>
  );
};

export default Terminos;
