import { Container } from "./styles";
import { HeaderTop } from "../molecules/HeaderTop";
import { ImagePreviewer } from "../organisms/ImagePreviewer";
import { ImageDataTable } from "../organisms/ImageDataTable";
import { FullProductNameHeader } from "../organisms/FullProductNameHeader";
import { FullTabsMenu } from "../organisms/FullTabsMenu";
import { InputGroup } from "../organisms/InputGroup";
import { useEffect, useReducer, useState, useCallback } from "react";
import { GalleryElement } from "../molecules/GalleryElement";
import { saveAs } from "file-saver";
import { Commentary } from "../atoms/Commentary";
import {
  getRetailerServices,
  getPercentage,
  fetchUsers,
} from "../../global-files/data";
import { GalleryHeader } from "../molecules/GalleryHeader";
import { ProductImageModal } from "../organisms/ProductImageModal";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import AWS from "aws-sdk";
import attributesSent from "../../assets/images/modalsSVGs/attributesSent.svg";
import descriptionSent from "../../assets/images/modalsSVGs/descriptionSent.svg";
import imagesSent from "../../assets/images/modalsSVGs/uploadingImages.svg";
import { TagAndInput } from "../molecules/TagAndInput/index";
import { Button } from "../atoms/GeneralButton";
import { GenericModal } from "../atoms/GenericModal";
import { ScreenHeader } from "../atoms/ScreenHeader";
import { Loading } from "../atoms/Loading";
import succes from "../../assets/images/genericModal/genericModalCheck.svg";
import { VersionSelector } from "../organisms/VersionSelector";
import { useCloseModal } from "../../global-files/customHooks";
import {
  getAuditVersion,
  getInputsData,
  createMessage,
  sendMessage,
} from "./utils";
import { Modal } from "../organisms/Modal";
import { ButtonV2 } from "../atoms/ButtonV2";
import generateThumbnail from "./generateThumnail";

const reducerImages = (state, action) => {
  let { values, attrForImgs, inputsByRetailer } = state;
  switch (action.action) {
    case "init":
      const newInputsByRetailer = {};
      action?.init?.inputsByRetailer?.forEach((inputs) => {
        inputs?.forEach((input) => {
          if (!newInputsByRetailer[`${input.id_retailer}`])
            newInputsByRetailer[`${input.id_retailer}`] = [];
          newInputsByRetailer[`${input.id_retailer}`].push(input);
        });
      });
      inputsByRetailer = newInputsByRetailer;
      return {
        ...action.init,
        inputsByRetailer,
        valuesInitial: action.init.values,
        inputsInitial: action.init.inputs,
      };
    case "addImg":
      values = [...values, action.img];
      return { ...state, values };
    case "changeImageInfo":
      values[action.index][action.attribute] = action.value;
      return { ...state, values };
    case "changeShotType":
      values[action.index][action.attribute] = action.value;
      return { ...state, values };

    case "changeAttrValue":
      const index = attrForImgs.general.findIndex((f) => f.id === action.id);
      if (index !== -1) {
        attrForImgs.general[index].value = action.value;
      }

      return { ...state, attrForImgs, values };
    case "deleteImage":
      values = values.filter(
        (value) => action.selectedImages.indexOf(value) === -1
      );
      return { ...state, values };
    case "orderImages": {
      let { inputsByRetailer, valuesInitial, inputsInitial, inputs } = state;
      try {
        const orderedImages = [];
        const imageIdArray = [];
        if (action?.retailerId && !inputsByRetailer[action.retailerId]) {
          inputsByRetailer[action.retailerId] = [];
        }
        action.retailerId &&
          inputsByRetailer[action.retailerId]?.filter((input) => {
            imageIdArray.push(input.id_image);
            valuesInitial.forEach(async (value) => {
              if (value.ext == "mp4") {
                convertirVideoABase64(
                  "https://" +
                    process.env.REACT_APP_IMAGES_BUCKET +
                    ".s3.amazonaws.com/" +
                    value.srcDB
                )
                  .then(async (base64) => {
                    const videoSrc =
                      "data:video/mp4;base64," + base64.split(",")[1]; // Añade el tipo MIME adecuado
                    value.src = await generateThumbnail(videoSrc, 2);
                  })
                  .catch((error) => {
                    console.error("Error:", error);
                  });
              }
              if (value.image_id === input.id_image) orderedImages.push(value);
            });
          });
        inputs = inputsInitial?.filter((input) =>
          imageIdArray.includes(input.id)
        );
        values = orderedImages.length > 0 ? orderedImages : [];
      } catch (error) {
        console.log(error);
      }
      return { ...state, values, inputs };
    }
    default:
      return state;
  }
};

const S3_BUCKET = process.env.REACT_APP_IMAGES_BUCKET;
const REGION = "us-east-1";

function convertirVideoABase64(url) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      if (xhr.status === 200) {
        const blob = xhr.response;
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      } else {
        reject(new Error("Error al cargar el video"));
      }
    };
    xhr.onerror = function () {
      reject(new Error("Error de red al cargar el video"));
    };
    xhr.send();
  });
}

function obtenerDuracionVideoBase64(base64Data) {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.src = base64Data;
    video.addEventListener("loadedmetadata", () => {
      resolve(video.duration);
    });
    video.addEventListener("error", reject);
  });
}

AWS.config.update({
  accessKeyId: process.env.REACT_APP_KUTS3,
  secretAccessKey: process.env.REACT_APP_AKUTS3,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export const RetailerProductEdition = ({
  tabsSections,
  productSelected = {},
  user = {},
  token,
  location,
}) => {
  const [activeTab, setActiveTab] = useState("Descripción");
  const [activeImage, setActiveImage] = useState();
  const [imageLayout, setImageLayout] = useState(false);
  const [headerTop, setHeaderTop] = useState(0);
  const [descriptions, setDescriptions] = useState([]);
  const [datasheets, setDatasheets] = useState([]);
  const [images, setImages] = useReducer(reducerImages, {});
  const [showModal, setShowModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*, video/mp4",
    noKeyboard: true,
    multiple: true,
    noClick: true,
    onDrop: (acceptedFiles) => {
      const newImages = [];
      acceptedFiles.map((file) => {
        const reader = new FileReader();
        reader.fileName = file.name;
        reader.onload = async function (e) {
          const ext = e.srcElement.fileName.split(".");
          const img = new Image();
          if (ext[ext.length - 1] == "mp4") {
            obtenerDuracionVideoBase64(e.target.result)
              .then(async (duracion) => {
                if (duracion > 15 && duracion < 600) {
                  img.src = await generateThumbnail(e.target.result, 2);
                  setTimeout(() => {
                    const width = img.width;
                    const height = img.height;
                    const newImg = {
                      action: "addImg",
                      img: {
                        src: img.src, //e.target.result,
                        name: e.target.fileName,
                        ext: ext[ext.length - 1],
                        width: width,
                        height: height,
                        video_src:
                          ext[ext.length - 1] == "mp4" ? e.target.result : "",
                      },
                    };
                    setImages(newImg);
                  }, 500);
                } else {
                  setModalAlert({
                    show: true,
                    title: "Hubo un error al subir el video",
                    message:
                      "Los videos deben durar entre 15 segundos y 10 minutos",
                  });
                }
              })
              .catch((error) => {
                console.error("Error al obtener la duración del video:", error);
              });
          } else {
            img.src = e.target.result;
            setTimeout(() => {
              const width = img.width;
              const height = img.height;
              const newImg = {
                action: "addImg",
                img: {
                  src: img.src, //e.target.result,
                  name: e.target.fileName,
                  ext: ext[ext.length - 1],
                  width: width,
                  height: height,
                  video_src:
                    ext[ext.length - 1] == "mp4" ? e.target.result : "",
                },
              };
              setImages(newImg);
            }, 500);
          }
        };
        reader.onerror = function (error) {
          console.log("dropzoneError: ", error);
        };
        reader.readAsDataURL(file);
        return file;
      });
    },
  });
  const [updatedDatasheets, setUpdatedDatasheets] = useState([]);
  const [updatedDescriptions, setUpdatedDescriptions] = useState([]);
  const [imagesUploaded, setImagesUploaded] = useState(false);
  const [dataImages, setDataImages] = useState();
  const selectedPr = {
    "services": {
        "datasheets": 1,
        "descriptions": 1,
        "images": 1
    },
    "orderId": 15456,
    "status": "PA",
    "datasheet_status": "PA",
    "observations": null,
    "prio": "none",
    "version": 7,
    "description_status": "PA",
    "images_status": "PA",
    "statusByRetailer": {
        "4": {
            "datasheet": "PA",
            "description": "PA",
            "images": "PA"
        }
    },
    "article": {
        "id_article": 121211,
        "id_category": "4",
        "name": "Test Product",
        "upc": "993366",
        "timestamp": "2024-03-26T18:05:49.000Z",
        "id_user": 28,
        "status": "NULL",
        "active": 1,
        "company_id": 1,
        "company_name": "GRUPO BRAHMA",
        "country": "México",
        "id_order": 15456,
        "id_datasheet_especialist": null,
        "id_datasheet_facilitator": null,
        "id_description_especialist": null,
        "id_description_facilitator": null,
        "id_images_especialist": null,
        "id_images_facilitator": null,
        "id_auditor": null,
        "id_recepcionist": null,
        "category": "Abarrotes|Abarrotes Secos|Alimentos Deshidratados",
        "missingAttributes": 0,
        "missingDescriptions": 0,
        "missingImages": 0
    },
    "retailers": [
        {
            "id": 4,
            "name": "Walmart Super y Superama"
        }
    ],
    "country": "México",
    "upc": "993366"
};
  sessionStorage.setItem("productSelected", JSON.stringify(selectedPr));
  const [product, setProduct] = useState(
    JSON.parse(sessionStorage.getItem("productSelected")) ?? selectedPr
  );
  const [percentages, setPercentages] = useState(
    new Array(product?.retailers?.length).fill({ percentage: 0 })
  );
  // const [percentages, setPercentages] = useState([{ retailers: {} }]);
  const [activePercentage, setActivePercentage] = useState(0);
  const [activeRetailer, setActiveRetailer] = useState({});
  const [services, setServices] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [message, setMessage] = useState("");
  const [icon, setIcon] = useState(null);
  const [version, setVersion] = useState(product?.version);
  const [comments, setComments] = useState({});
  const [comment, setComment] = useState("");
  const [requiredNull, setRequiredNull] = useState({
    "Ficha técnica": 0,
    Descripción: 0,
    Imágenes: 0,
  });
  const [crossComment, setCrossComment] = useState(false);
  const [userGroups, setUserGroups] = useState([]);
  const [assig, setAssig] = useState({});
  const [selectedImages, setSelectedImages] = useState([]);
  const [componentsArray, setComponentsArray] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const isRetailer = user?.is_retailer;
  const [loading, setLoading] = useState(true);
  const [retailerStatus, setRetailerStatus] = useState("-");
  const [statusArray, setStatusArray] = useState([]);
  const [socketType, setSocketType] = useState(null);
  const [saving, setSaving] = useState(loading);
  const [showVersionSelector, setShowVersionSelector] =
    useCloseModal("version-selector");
  const shotThd = [58, 59, 60, 61].includes(activeRetailer.id);
  const [auditableVersion, setAuditableVersion] = useState(null);
  const [auditServices, setAuditServices] = useState([]);
  const [auditDatasheets, setAuditDatasheets] = useState([]);
  const [auditDescriptions, setAuditDescriptions] = useState([]);
  const [auditImages, setAuditImages] = useState([]);
  const [compare, setCompare] = useState(false);
  const [observation, setObservation] = useState();
  const [valRejAll, setValRejAll] = useState(false);
  const [modalAlert, setModalAlert] = useState({
    show: false,
    title: "",
    message: "",
    errorInputMessage: false,
  });

  const [desc, setDesc] = useState([]);
  const [fich, setFich] = useState([]);
  const [imag, setImag] = useState([]);

  const [isObservationVisible, setObservationVisible] = useState(false);

  const toggleObservation = () => {
    setObservationVisible(!isObservationVisible);
  };
  // const handleClickOutside = (event) => {
  //   if (
  //     isObservationVisible &&
  //     !event.target.closest(".Observation") &&
  //     !event.target.closest(".Container")
  //   ) {
  //     hideObservation();
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, [isObservationVisible]);

  const hideObservation = () => {
    setObservationVisible(false);
  };

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    const commentText = document.querySelector(
      "#commentary-box .ql-container .ql-editor > p"
    ).innerHTML;
    createComment(e, commentText, activeTab);
  };


  useEffect(() => {
    const fetchAuditVersion = async () => {
      console.log({product});
      
      const { id_article } = product?.article?.id_article || {};
      if (id_article) {
        await getAuditVersion(id_article, setAuditableVersion, token);
      }
    };
    fetchAuditVersion();
  }, [product]);

  const loadAuditableData = async () => {
    if (auditableVersion) {
      const auditServices = await getRetailerServices(
        product?.article?.id_article,
        product?.article?.category,
        parseInt(product?.article?.id_category),
        auditableVersion.version,
        token
      );
      getInputsData(
        auditServices,
        activeRetailer,
        setAuditDatasheets,
        setAuditDescriptions
      );
      setAuditServices(auditServices);
      setAuditImages(auditServices[2]);
    }
  };

  useEffect(() => {
    if (auditableVersion) {
      loadAuditableData();
    }
  }, [auditableVersion]);

  useEffect(() => {
    checkAll && setSelectedImages(images.values);
  }, [checkAll]);

  const loadData = async () => {
    try {
      const services = await getRetailerServices(
        product?.article?.id_article,
        product?.article?.category,
        parseInt(product?.article?.id_category),
        version,
        token
      );
      if (auditableVersion) {
        loadAuditableData();
      }
      //Converts the data inside the datasheets object to array
      setServices(services);
      getServices();
      setImages({ action: "init", init: services[2] });
      if (services[2]?.values?.length > 0) setActiveImage(0);
      const data = [
        {
          id_article: product.article.id_article,
          id_category: product.article.id_category,
          version: product.version,
          id_retailer_array: (
            product.retailersAvailable ?? product.retailers
          ).map(({ id }) => id),
        },
      ];
      const headers = { Authorization: token };
      getPercentage({ data, headers }).then((res) => {
        setPercentages(res[0]);
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getServices = async () => {
    const servicesResponse = await axios.get(
      `${process.env.REACT_APP_SERVICES_ENDPOINT}?articleId=${
        product?.article?.id_article
      }&orderId=${product?.article?.id_order || product.orderId}&end=true`
    );
    const parsedResponse = JSON.parse(servicesResponse?.data?.body).data;

    const retailers = product.retailers || product.retailersAvailable;
    const retailerResponse = parsedResponse?.map((srv) => srv.id_retailer);
    const active = retailers?.find((retailer) =>
      retailerResponse.includes(retailer.id)
    );
    !activeRetailer.id && setActiveRetailer(active ? active : retailers[0]);
    setServicesData(parsedResponse);
  };

  const translateConcept = (concept) => {
    const translation = {
      datasheet: "Ficha técnica",
      description: "Descripción",
      images: "Imágenes",
    };
    return translation[concept];
  };

  const getComments = async (tab = "Descripción") => {
    const commentsResponse = await Promise.all([
      axios.get(
        `${process.env.REACT_APP_COMMENTS_ENDPOINT}?articleId=${product?.article?.id_article}&concept=description&orderIdColab=${product?.orderId}&version=${version}`
      ),
      axios.get(
        `${process.env.REACT_APP_COMMENTS_ENDPOINT}?articleId=${product?.article?.id_article}&concept=datasheet&orderIdColab=${product?.orderId}&version=${version}`
      ),
      axios.get(
        `${process.env.REACT_APP_COMMENTS_ENDPOINT}?articleId=${product?.article?.id_article}&concept=images&orderIdColab=${product?.orderId}&version=${version}`
      ),
    ]);

    let comments = {};
    commentsResponse.forEach(
      (comment) =>
        JSON.parse(comment?.data?.body).data[0] &&
        (comments[
          translateConcept(JSON.parse(comment?.data?.body)?.data[0]?.concept)
        ] = JSON.parse(comment?.data?.body).data[0])
    );
    setComment(comments[tab]);
    setComments(comments);
  };

  // useEffect(async () => {
  //   setLoading(true);
  //   loadData();
  //   getComments();
  //   setUserGroups(await fetchUsers(token));
  //   let arr = [];
  //   switch (user.id_role) {
  //     case 7:
  //     case 8:
  //       arr = ["PA", "AS", "CA", "RC", "RA", "RP", "RCA"];
  //       break;
  //     case 4:
  //     case 5:
  //       arr = ["RC", "AC", "AA", "AP", "ACA"];
  //       break;
  //     case 6:
  //       arr = ["RP", "RCA", "AC", "RA"];
  //       break;
  //     default:
  //       arr = [];
  //       break;
  //   }
  //   setStatusArray(arr);
  // }, [product, version]);

  useEffect(() => {
    const initializeData = async () => {
      setLoading(true);
      await loadData();
      await getComments();
      setUserGroups(await fetchUsers(token));
      let arr = [];
    switch (user.id_role) {
      case 7:
      case 8:
        arr = ["PA", "AS", "CA", "RC", "RA", "RP", "RCA"];
        break;
      case 4:
      case 5:
        arr = ["RC", "AC", "AA", "AP", "ACA"];
        break;
      case 6:
        arr = ["RP", "RCA", "AC", "RA"];
        break;
      default:
        arr = [];
        break;
    }
    };
    initializeData();
  }, [product, version]);

  const loadAssignations = (currentProduct) => {
    setAssig({
      Descripción: {
        assignations: [
          {
            collaboratorType: "especialist",
            id: currentProduct?.article?.id_description_especialist,
          },
          {
            collaboratorType: "facilitator",
            id: currentProduct?.article?.id_description_facilitator,
          },
        ],
        collaborators: {
          especialist: userGroups[0] || [],
          facilitator: userGroups[2] || [],
        },
      },
      "Ficha técnica": {
        assignations: [
          {
            collaboratorType: "especialist",
            id: currentProduct?.article?.id_datasheet_especialist,
          },
          {
            collaboratorType: "facilitator",
            id: currentProduct?.article?.id_datasheet_facilitator,
          },
        ],
        collaborators: {
          especialist: userGroups[0] || [],
          facilitator: userGroups[2] || [],
        },
      },
      Imágenes: {
        assignations: [
          {
            collaboratorType: "especialist",
            id: currentProduct?.article?.id_images_especialist,
          },
          {
            collaboratorType: "facilitator",
            id: currentProduct?.article?.id_images_facilitator,
          },
        ],
        collaborators: {
          especialist: userGroups[1] || [],
          facilitator: userGroups[3] || [],
        },
      },
    });
  };

  useEffect(() => {
    loadAssignations(product);
  }, [userGroups]);

  useEffect(() => {
    const retailers = product?.retailersAvailable || product?.retailers;
    if (
      Object.keys(percentages[product.article.id_article] ?? {})?.length > 0
    ) {
      retailers?.forEach((retailer, index) => {
        retailer["percentage"] = Number(
          percentages[product.article.id_article][retailer.id]
            ?.percentageRequired
        );
      });
    }
    setActivePercentage(retailers[0]?.percentage);
  }, [percentages]);

  useEffect(() => {
    getInputsData(services, activeRetailer, setDatasheets, setDescriptions);
    auditableVersion && loadAuditableData();
    setActivePercentage(Math.round(activeRetailer?.percentage, 0));
    activeRetailer?.id &&
      setImages({ action: "orderImages", retailerId: activeRetailer.id });
  }, [activeRetailer, services]);

  const thumbs = () => {
    const imageInputs = socketType?.slice();
    const imageType = images?.imageType?.map((e) => ({
      value: e?.id,
      name: e?.name,
    }));
    const imagePackagingType = images?.imagePackagingType?.map((e) => ({
      value: e?.id,
      name: e?.name,
    }));
    const imageShotType = images?.imageShotType?.map((e) => ({
      value: e?.id,
      name: e?.type_shot,
    }));
    return images?.values?.map((image, index) => (
      <GalleryElement
        setCheckAll={setCheckAll}
        key={index + "-" + image.name}
        image={image}
        gridLayout={imageLayout}
        id={"gallery-element-" + index}
        index={index + "-" + image.name + "-" + compare}
        number={index}
        imageType={imageType}
        imagePackagingType={imagePackagingType}
        imageInputs={imageInputs}
        imageShotType={imageShotType}
        setSocketType={setSocketType}
        changeImage={setImages}
        selectedImages={selectedImages}
        setSelectedImages={setSelectedImages}
        auditImages={auditImages}
        compare={compare}
      />
    ));
  };

  const saveDescriptions = async () => {
    const dataClean = updatedDescriptions.filter((f) => f.value !== "");
    if (dataClean.length > 0) {
      setLoading(true);
      const productTemp = product;
      const articleId = product?.article?.id_article;
      const dataObject = {
        articleId,
        articleData: dataClean,
      };
      if (product?.orderId) dataObject["orderId"] = product?.orderId;
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_ARTICLE_DATA_ENDPOINT}?description=true&version=${version}`,
          dataObject,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (res.data.statusCode === 200) {
          const { newStatus, newArticleStatus } = JSON.parse(res.data.body);
          if (newArticleStatus)
            productTemp.status = newArticleStatus[articleId];
          if (newStatus) productTemp.description_status = newStatus;
          setProduct(productTemp);
          sessionStorage.setItem(
            "productSelected",
            JSON.stringify(productTemp)
          );

          setUpdatedDescriptions([]);
          setMessage("Descripciones guardadas con éxito");
          await loadData();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const saveDatasheets = async () => {
    const dataClean = updatedDatasheets.filter((f) => f.value !== "");
    if (dataClean.length > 0) {
      setLoading(true);
      const productTemp = product;
      const articleId = product?.article?.id_article;
      const dataObject = {
        articleId,
        articleData: dataClean,
      };
      if (product?.orderId) dataObject["orderId"] = product?.orderId;
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_ARTICLE_DATA_ENDPOINT}?datasheet=true&version=${version}`,
          dataObject,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (res.data.statusCode === 200) {
          const { newStatus, newArticleStatus } = JSON.parse(res.data.body);
          if (newArticleStatus)
            productTemp.status = newArticleStatus[articleId];
          if (newStatus) productTemp.datasheet_status = newStatus;
          setProduct(productTemp);
          sessionStorage.setItem(
            "productSelected",
            JSON.stringify(productTemp)
          );
          setUpdatedDatasheets([]);
          setMessage("Fichas técnicas guardadas");
          await loadData();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const imageInputs = images?.inputs?.map((e) => ({
      value: e?.id,
      name: e?.name,
      required: e?.required,
      active: images?.values?.some((value) => value?.image_id === e?.id),
    }));
    setSocketType(imageInputs);
  }, [images]);

  const updateImages = useCallback(async () => {
    const imagesList = images?.values?.slice();
    const imagesListTemp = imagesList?.reduce((acc, image) => {
      acc[image?.image_id] = ++acc[image?.image_id] || 0;
      return acc;
    }, {});

    const duplicated = imagesList?.filter((image) => {
      return imagesListTemp[image?.image_id];
    });

    const attrForImgs = Object.values(images?.attrForImgs ?? {}).pop();
    const data = {
      articleId: product?.article?.id_article,
      attrReqImgs: attrForImgs?.map((e) => ({
        attrId: e.id,
        value: e.value ?? "-",
      })),
      articleData: imagesList?.filter((e) => !e.id),
      updateImages: imagesList?.filter((e) => e.id),
    };
    if (product?.orderId) data["orderId"] = product?.orderId;
    let valid =
      data?.articleData?.length === 0
        ? true
        : data?.articleData?.every((e, i) => {
            if (e?.image_id && e?.packing_type && e?.image_type) {
              return true;
            }
            return false;
          });
    if (valid && data?.updateImages?.length > 0 && duplicated?.length === 0) {
      valid = data?.updateImages?.every((e, i) => {
        if (e?.image_id && e?.packing_type && e?.image_type) {
          return true;
        }
        return false;
      });
    }
    if (valid && duplicated?.length === 0) {
      setLoading(true);
      try {
        data?.articleData?.forEach((e) => {
          e.uuid = uuidv4();
        });
        setDataImages(data);
        if (data?.articleData?.length > 0) {
          setImagesUploaded(false);
          const promiseArray = [];
          data?.articleData?.forEach((e) => {
            let file;
            if (e.ext == "mp4") {
              file = Buffer.from(
                e.video_src.replace(/^data:video\/\w+;base64,/, ""),
                "base64"
              );
              e.video_src = "";
            } else {
              file = Buffer.from(
                e.src.replace(/^data:image\/\w+;base64,/, ""),
                "base64"
              );
            }
            const params = {
              ACL: "public-read",
              Body: file,
              Bucket: S3_BUCKET,
              Key: `id-${data.articleId}/${version}/${e?.image_id}-${e?.uuid}.${e?.ext}`,
            };
            promiseArray.push(myBucket.putObject(params).promise());
          });
          await Promise.all(promiseArray);
          setImagesUploaded(true);
        } else {
          setImagesUploaded(true);
        }
      } catch (err) {
        console.log(err);
        // setMainLoading(false);
      }
    } else {
      // setMainLoading(false);
      setMessage(
        "Completa los campos que solicita cada una de la imágenes o hay imágenes con el mismo tipo de toma.\nRecuerda hay campos obligatorios y no podras avanzar si no estan completos."
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images, imagesUploaded]);

  // useEffect(async () => {
  //   if (imagesUploaded) {
  //     dataImages.articleData = dataImages?.articleData.map((e) => {
  //       delete e.src;
  //       e.imageID = e.image_id;
  //       e.packingType = e.packing_type;
  //       e.imageType = e.image_type;
  //       if (product?.orderId) e["orderId"] = product?.orderId;
  //       return e;
  //     });
  //     try {
  //       const res = await axios.put(
  //         `${process.env.REACT_APP_ARTICLE_DATA_ENDPOINT}?image=true&version=${version}`,
  //         dataImages,
  //         {
  //           headers: {
  //             Authorization: token,
  //           },
  //         }
  //       );
  //       if (res.data.statusCode === 200) {
  //         let productTemp = product;
  //         const { newStatus, newArticleStatus } = JSON.parse(res.data.body);
  //         if (newArticleStatus)
  //           productTemp.status = newArticleStatus[product?.article?.id_article];
  //         if (newStatus) productTemp.images_status = newStatus;
  //         setProduct(productTemp);
  //         sessionStorage.setItem(
  //           "productSelected",
  //           JSON.stringify(productTemp)
  //         );
  //         setImages({});
  //         setMessage("Imágenes guardadas con éxito");
  //         sessionStorage.removeItem("imagesList");
  //         await loadData();
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // }, [dataImages, imagesUploaded]);

  const evaluationFinished = (userId, tab, statusArray) => {
    const srv = servicesData.filter((serv) => serv.service === getConcept(tab));
    const srvActive = srv
      .find((serv) => serv.id_retailer === activeRetailer?.id)
      ?.status?.replace(/.*\//, "");
    const currStatus = product[`${getConcept(tab)}_status`]?.replace(
      /.*\//,
      ""
    );
    const unvalidated = ["IE", "CA"].includes(currStatus);

    const auditorUnvalidated = !["RA", "AA", "ACA", "AP"].includes(currStatus);

    switch (userId) {
      case 7:
      case 8:
        return (
          (statusArray.includes(srvActive) &&
            statusArray.includes(product?.status)) ||
          srv.filter((serv) =>
            statusArray.includes(serv.status?.replace(/.*\//, ""))
          ).length === srv.length
        );
      case 4:
      case 5:
        return (
          unvalidated &&
          ["CA", "IE"].includes(product.status) && // "RC", "AC", "AA", "AP", "ACA"
          srv.filter((serv) => statusArray.includes(serv.status)).length ===
            srv.length
        );
      case 6:
        return (
          statusArray.includes(product.status) && // RP, RCA, AC, RA true
          srv.every((serv) =>
            ["RA", "AA", "AP", "ACA"].includes(serv.status)
          ) &&
          auditorUnvalidated
        );
      default:
        break;
    }
  };

  const getConcept = (tab) => {
    switch (tab) {
      case "Descripción":
        return "description";
      case "Ficha técnica":
        return "datasheet";
      case "Imágenes":
        return "images";
    }
  };

  const approveRejectButtons = (action) => {
    let concept = getConcept(action || activeTab);

    const retailerStatus = servicesData
      .find(
        (srv) =>
          srv.id_retailer === activeRetailer?.id && srv.service === concept
      )
      ?.status?.replace(/.*\//, "");

    //sessionStorage product
    const adminFacilitatorCanEvaluate =
      retailerStatus === "IE" && [1, 4, 5].includes(user.id_role);
    const adminAuditorCanEvaluate =
      ["AC", "RP", "RCA", "SAC"].includes(retailerStatus) &&
      [1, 6].includes(user.id_role);
    return adminFacilitatorCanEvaluate || adminAuditorCanEvaluate;
  };

  const approveRejectAllButtons = () => {
    let concepts = [];
    switch (user.id_role) {
      case 4:
        concepts = ["description", "datasheet"];
        break;
      case 5:
        concepts = ["images"];
        break;

      default:
        concepts = ["description", "datasheet", "images"];
        break;
    }

    const services = servicesData.filter(({ service }) =>
      concepts.includes(service)
    );

    const adminFacilitatorCanEvaluate =
      services.every((srv) => srv?.status?.replace(/.*\//, "") === "IE") &&
      [1, 4, 5].includes(user.id_role);

    //sessionStorage product
    const adminAuditorCanEvaluate =
      servicesData.every((srv) =>
        ["AC", "RP", "RCA"].includes(srv?.status?.replace(/.*\//, ""))
      ) && [1, 6].includes(user.id_role);
    return adminFacilitatorCanEvaluate || adminAuditorCanEvaluate;
  };

  const getSectionIcon = () => {
    switch (activeTab) {
      case "Ficha técnica":
        setIcon(attributesSent);
        break;
      case "Descripción":
        setIcon(descriptionSent);
        break;
      case "Imágenes":
        setIcon(imagesSent);
        break;
      default:
        break;
    }
  };

  const sendToFacilitator = async (result) => {
    setLoading(true);
    try {
      let concept = getConcept(activeTab);

      const productTemp = { ...product };
      const evalStatus = retailerStatus;
      const articleId = product.article.id_article;
      const orderId = product.orderId;

      let data = {
        articleId,
        orderId,
        concept,
        evalStatus,
        retailerId: activeRetailer.id,
      };
      let res;
      let message;
      if (result) {
        data.result = result;
        res = await axios.put(
          `${process.env.REACT_APP_EVALUATION_ENDPOINT}`,
          data,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        getServices();
      } else {
        const specialistDone = ["RC", "RA", "CA"].includes(evalStatus);

        if (specialistDone) {
          message = `${activeTab} enviada a facilitador`;
          getSectionIcon();
        } else if (["IE", "AC", "RP", "RCA"].includes(evalStatus)) {
          message = "Evaluación enviada";
          getSectionIcon();
        }
        res = await axios.put(`${process.env.REACT_APP_SEND_EVAL}`, data, {
          headers: {
            Authorization: token,
          },
        });
      }
      if (res.data.statusCode === 200) {
        const { newStatus, newOrderStatus, newArticleStatus } = JSON.parse(
          res.data.body
        );
        const messageToChat = createMessage(
          product.retailers,
          activeRetailer.id,
          evalStatus,
          newStatus,
          activeTab
        );

        const data = {
          paramsBody: {
            id: product.article.id_article,
            version: version,
            items: [{ type: "status", value: messageToChat }],
            retailerId: activeRetailer.id,
            status: product.status,
          },
          paramsHeader: { Authorization: token },
        };
        await sendMessage(data);
        if (newOrderStatus) productTemp.status = newArticleStatus[articleId];
        productTemp[`${concept}_status`] = newStatus;
        await loadData();
        if (message) setMessage(message);
        sessionStorage.setItem("productSelected", JSON.stringify(productTemp));
        setProduct(productTemp);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const userAssigned = (tab, rol) => {
    let concept = getConcept(activeTab);

    const allowedRoles = [1, 4, 5, 6, 7, 8];
    const validUser = allowedRoles.includes(user?.id_role);

    if (!rol) {
      switch (user.id_role) {
        case 4:
        case 5:
          rol = "facilitator";
          break;
        case 7:
        case 8:
          rol = "especialist";
          break;
      }
    }

    return (
      user.id_role === 1 ||
      (product.article[`id_${concept}_${rol}`] === user.id_user && validUser)
    );
  };

  const auditorAssigned = () => {
    return product?.article[`id_auditor`] === user.id_user;
  };

  const createComment = async (e, body, tab) => {
    let concept = "";
    switch (activeTab) {
      case "Ficha técnica":
        concept = "datasheet";
        break;
      case "Imágenes":
        concept = "images";
        break;

      default:
        concept = "description";
        break;
    }
    const data = {
      articleId: product?.article?.id_article,
      orderId: product?.orderId,
      message: body?.replace(/<.*?\/?>/gm, ""),
      concept: concept,
      version: version,
    };
    await axios.post(`${process.env.REACT_APP_COMMENTS_ENDPOINT}`, data, {
      headers: {
        Authorization: token,
      },
    });
    await getComments(tab);
    setMessage("");
    setComponentsArray([]);
  };

  const getRequired = (services) => {
    try {
      const objetcTemp = {};
      const datasheetServicesArray = Object.values(services[0]);
      const dsInputs = datasheetServicesArray.pop();
      const descriptionsServicesArray = services[1];

      let dsInputsRequired = [];
      let desInputsRequired = 0;
      datasheetServicesArray?.forEach((datasheet) => {
        const [requested] = servicesData?.filter(
          (srv) =>
            srv.id_retailer === datasheet.retailer.id &&
            srv.service === getConcept(activeTab)
        );
        requested &&
          datasheet?.data &&
          Object.values(datasheet?.data).forEach((dataGroup) =>
            dsInputsRequired.push(
              ...dataGroup.inputs.filter(
                (input) =>
                  dsInputs[input].required &&
                  (dsInputs[input].value === undefined ||
                    !dsInputs[input].value)
              )
            )
          );
      });

      objetcTemp["Ficha técnica"] = dsInputsRequired.length;

      const regex = /(<\/?p>)|(<\/?strong>)|(<br>)/gm;
      descriptionsServicesArray.forEach((description) => {
        const [requested] = servicesData.filter(
          (srv) =>
            srv.id_retailer === description.id &&
            srv.service === getConcept(activeTab)
        );
        if (requested) {
          description.inputs.forEach((input) => {
            if (
              input.required &&
              (!input.value || input.value?.replace(regex, "") === "")
            ) {
              desInputsRequired++;
            }
          });
        }
      });

      objetcTemp["Descripción"] = desInputsRequired;

      const retailersRequested = [];
      services[2]?.retailerMandatories?.forEach((retMan) =>
        retMan.forEach((rm) => retailersRequested.push(rm))
      );
      const requiredImages = services[2]?.inputs?.filter(
        (e) =>
          e.required === 1 &&
          retailersRequested.filter(
            (ret) =>
              ret.id_image === e.id &&
              servicesData.filter((srv) => srv.id_retailer === ret.id_retailer)
                .length > 0
          ).length > 0
      );

      let requiredCounter = 0;
      requiredImages?.forEach(
        (req) =>
          services[2].values.filter((img) => img.image_id === req.id).length ===
            0 && requiredCounter++
      );
      objetcTemp["Imágenes"] = requiredCounter;
      setRequiredNull(objetcTemp);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setComment(comments[activeTab]);
  }, [activeTab]);

  const commentRevised = async () => {
    const data = {
      commentId: comment.id,
    };
    await axios.put(`${process.env.REACT_APP_COMMENTS_ENDPOINT}`, data, {
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });
    setCrossComment(false);
    await getComments();
  };

  const setAssignation = async (assignationType, assignationId) => {
    let concept = "";
    switch (activeTab) {
      case "Ficha técnica":
        concept = "datasheet";
        break;
      case "Imágenes":
        concept = "images";
        break;

      default:
        concept = "description";
        break;
    }
    const productTemp = product;
    productTemp.article[`id_${concept}_${assignationType}`] = assignationId;
    const data = {
      articleList: [
        {
          orderId: product.orderId,
          articleId: product?.article?.id_article,
        },
      ],
      concept: concept,
      userId: assignationId,
    };
    await axios({
      method: "post",
      url: process.env.REACT_APP_ASSIGNATIONS_ENDPOINT,
      data,
      headers: {
        Authorization: token,
      },
    });
    loadAssignations(productTemp);
    sessionStorage.setItem("productSelected", JSON.stringify(productTemp));
  };

  const downloadImages = () => {
    selectedImages.length > 0
      ? selectedImages.forEach((e) => {
          if (e.id) {
            saveAs(
              `https://${process.env.REACT_APP_IMAGES_BUCKET}.s3.amazonaws.com/${e.srcDB}`,
              `${product.article.upc}_${e.name}.${e.ext}`
            );
          }
        })
      : images?.values?.forEach((e) => {
          if (e.id) {
            saveAs(
              `https://${process.env.REACT_APP_IMAGES_BUCKET}.s3.amazonaws.com/${e.srcDB}`,
              `${product.article.upc}_${e.name}.${e.ext}`
            );
          }
        });
  };

  const deleteImages = () => {
    setLoading(true);
    const { values } = images;
    const imgsInBack = [];

    selectedImages.forEach((selectedImg) => {
      if (selectedImg.id) imgsInBack.push(selectedImg);
    });

    const imgsLeft = values.filter(
      (value) => selectedImages.indexOf(value) === -1
    );

    if (imgsInBack.length > 0) {
      const data = {
        articleId: product.article.id_article,
        deleteImages: imgsInBack,
        orderId: product.orderId,
      };
      try {
        axios.put(
          `${process.env.REACT_APP_ARTICLE_DATA_ENDPOINT}?image=true&version=${version}`,
          data,
          {
            headers: { Authorization: token },
          }
        );
      } catch (err) {
        console.log(err);
      }
    }

    setImages({
      action: "deleteImage",
      selectedImages,
    });

    getRequired([
      services[0],
      services[1],
      { ...services[2], values: imgsLeft },
    ]);

    setTimeout(() => {
      setLoading(false);
    }, 500);

    setMessage("");
    setComponentsArray([]);
  };

  const askToDeleteImages = () => {
    if (selectedImages.length > 0) {
      setMessage("¿Está seguro de eliminar las imágenes seleccionadas?");
      setComponentsArray([
        <ScreenHeader
          key={"1"}
          text={"¿Está seguro de eliminar las imágenes seleccionadas?"}
          headerType="retailer-name-header"
          color={"white"}
        />,
        <Button
          key={"2"}
          buttonType="general-white-button"
          label={"Cancelar"}
          onClick={() => setMessage("")}
        />,
        <Button
          key={"3"}
          buttonType="general-button-default"
          label={"Aceptar"}
          onClick={() => {
            setMessage("");
            deleteImages();
          }}
        />,
      ]);
    }
  };

  const getRetailerStatus = (servicesData, tab) => {
    const arr = servicesData?.slice();
    let concept = getConcept(tab);

    let retailerService = {};
    [retailerService] = arr?.filter(
      (service) =>
        service.id_retailer === activeRetailer?.id &&
        service.service === concept
    );
    return retailerService ? retailerService.status : "NS";
  };

  useEffect(() => {
    let status = getRetailerStatus(servicesData, activeTab);
    setRetailerStatus(status);
  }, [activeTab, servicesData, activeRetailer]);

  useEffect(() => {
    services.length > 0 && getRequired(services);
  }, [services, servicesData, activeTab]);

  useEffect(() => {
    setSaving(loading);
  }, [loading]);

  const validateAll = async (result) => {
    try {
      setLoading(true);
      const evaluationArray = [];
      let conceptArray = [];
      switch (user.id_role) {
        case 4:
          conceptArray = ["description", "datasheet"];
          break;
        case 5:
          conceptArray = ["images"];
          break;

        default:
          conceptArray = ["description", "datasheet", "images"];
          break;
      }

      const messages = [];

      servicesData?.forEach((ret) => {
        if (conceptArray.includes(ret.service)) {
          let data = {
            articleId: product.article.id_article,
            orderId: product.id_order ?? product.orderId,
            concept: ret.service,
            result: result,
            evalStatus: ret.status,
            retailerId: ret.id_retailer,
          };
          evaluationArray.push(
            axios.put(`${process.env.REACT_APP_EVALUATION_ENDPOINT}`, data, {
              headers: {
                Authorization: token,
              },
            })
          );
        }
      });
      await Promise.all(evaluationArray);

      const productTemp = product;
      productTemp.status = `${result}A`;
      productTemp.datasheet_status =
        productTemp.datasheet_status === "NS" ? "NS" : `${result}A`;
      productTemp.description_status =
        productTemp.description_status === "NS" ? "NS" : `${result}A`;
      productTemp.images_status =
        productTemp.images_status === "NS" ? "NS" : `${result}A`;
      sessionStorage.setItem("productSelected", JSON.stringify(productTemp));
      setProduct(productTemp);
      const messagesResponse = await Promise.all(messages);

      await loadData();
    } catch (error) {
      console.log(error);
    }
  };

  // const getObservation = async () => {
  //   const response = await axios.get(
  //     `${process.env.REACT_APP_READ_OBSERVATION}?articleId=${product.article.id_article}&orderId=${product?.orderId}`,
  //     {
  //       headers: {
  //         Authorization: sessionStorage.getItem("jwt"),
  //       },
  //     }
  //   );
  //   const parseData = JSON.parse(response.data.body).data[0];
  //   setObservation(parseData.observations);
  // };
  // useEffect(() => {
  //   getObservation();
  // }, []);
  return (
    <Container headerTop={headerTop}>
      <HeaderTop
        setHeaderTop={setHeaderTop}
        auditableVersion={auditableVersion}
        setCompare={setCompare}
        isAuditor={[1, 6].includes(user.id_role)}
        withChat={location?.state?.withChat}
        chatType={location?.state?.chatType}
        productSelected={product}
        token={token}
        activeRetailer={activeRetailer}
      />
      <div className="data-container">
        <div className="image-data-panel">
          <ImagePreviewer
            activeImage={images?.values ? images?.values[activeImage] : {}}
            imagesArray={images}
            setActiveImage={setActiveImage}
            setShowModal={setShowModal}
          />
          <ImageDataTable
            lists={images}
            activeImage={images?.values ? images?.values[activeImage] : {}}
            retailerSelected={activeRetailer?.id}
            setImages={setImages}
            assignationsImages={assig["Imágenes"]}
            imagesStatus={product?.images_status}
            setAssignation={setAssignation}
            isRetailer={isRetailer}
            onClickSave={() =>
              product?.services?.images === 1 && updateImages()
            }
            showSaveButton={auditorAssigned() || userAssigned()}
            setShowVersionSelector={setShowVersionSelector}
            version={version}
            shotThd={shotThd}
          />
        </div>
        <div className="product-information">
          <FullProductNameHeader
            headerData={product}
            productObservation={observation}
            percent={activePercentage}
            activeRetailer={activeRetailer}
            servicesData={servicesData}
            setActiveRetailer={setActiveRetailer}
            sendToFacilitator={sendToFacilitator}
            approve={() => {
              sendToFacilitator("A");
            }}
            reject={() => {
              setShowRejectModal(true);
            }}
            showApproveRejectAll={
              approveRejectAllButtons() && (auditorAssigned() || userAssigned())
            }
            showValidationButtons={
              approveRejectButtons() && (auditorAssigned() || userAssigned())
            }
            approveAll={() => validateAll("A")}
            rejectAll={() => {
              setShowRejectModal(true);
              setValRejAll(true);
            }}
            isObservationVisible={isObservationVisible}
            toggleObservation={toggleObservation}
            // handleClickOutside={handleClickOutside}
            hideObservation={hideObservation}
          />
          <FullTabsMenu
            tabsSections={tabsSections}
            status={retailerStatus}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            setImageLayout={setImageLayout}
            downloadImages={downloadImages}
            askToDeleteImages={askToDeleteImages}
            assig={assig[activeTab]}
            setAssignation={setAssignation}
            isRetailer={isRetailer}
            showSaveButton={auditorAssigned() || userAssigned()}
            version={version}
            desc={desc}
            setDesc={setDesc}
            fich={fich}
            setFich={setFich}
            imag={imag}
            setImag={setImag}
            updatedDescriptions={updatedDescriptions}
            setUpdatedDescriptions={setUpdatedDescriptions}
            updatedDatasheets={updatedDatasheets}
            setUpdatedDatasheets={setUpdatedDatasheets}
            images={images}
            setImages={setImages}
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
            setShowVersionSelector={setShowVersionSelector}
            onClickSave={() => {
              switch (activeTab) {
                case "Descripción":
                  !saving &&
                    product?.description_status !== "NS" &&
                    saveDescriptions();
                  break;
                case "Ficha técnica":
                  !saving &&
                    product?.datasheet_status !== "NS" &&
                    saveDatasheets();
                  break;
                case "Imágenes":
                  !saving && product?.images_status !== "NS" && updateImages();
                  break;

                default:
                  break;
              }
            }}
            canAssign={![7, 8].includes(user.id_role)}
          />
          <div
            className={
              "services-information-container " +
              (imageLayout && activeTab === "Imágenes" ? "image-services" : "")
            }
          >
            {loading ? (
              <Loading />
            ) : (
              <>
                {!imageLayout &&
                  activeTab === "Imágenes" &&
                  product?.services?.images === 1 && (
                    <GalleryHeader
                      setSelectedImages={setSelectedImages}
                      checkAll={checkAll}
                      setCheckAll={setCheckAll}
                      shotThd={shotThd}
                    />
                  )}

                {activeTab === "Ficha técnica" &&
                  (product?.datasheet_status !== "NS" ? (
                    datasheets[0]?.data?.map((dataGroup, index) => (
                      <InputGroup
                        key={index + "-" + activeRetailer.name}
                        articleId={product.article.id_article}
                        version={version}
                        activeSection={activeTab}
                        inputGroup={dataGroup}
                        dataInputs={datasheets[1]}
                        auditInputs={auditDatasheets[1]}
                        updatedDatasheets={updatedDatasheets}
                        setUpdatedDatasheets={setUpdatedDatasheets}
                        compare={compare}
                      />
                    ))
                  ) : (
                    <ScreenHeader
                      text={"No cuentas con este servicio"}
                      headerType={"input-name-header"}
                    />
                  ))}
                {activeTab === "Descripción" &&
                  (product?.description_status !== "NS" ? (
                    <InputGroup
                      activeSection={activeTab}
                      inputGroup={descriptions[0]}
                      auditInputGroup={auditDescriptions[0]}
                      updatedDescriptions={updatedDescriptions}
                      setUpdatedDescriptions={setUpdatedDescriptions}
                      articleId={product?.article?.id_article}
                      version={version}
                      dinamicHeight={true}
                      compare={compare}
                    />
                  ) : (
                    <ScreenHeader
                      text={"No cuentas con este servicio"}
                      headerType={"input-name-header"}
                    />
                  ))}

                {activeTab === "Imágenes" &&
                  (product?.images_status !== "NS" ? (
                    <section className="container">
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <aside>{thumbs()}</aside>
                      </div>
                    </section>
                  ) : (
                    <ScreenHeader
                      text={"No cuentas con este servicio"}
                      headerType={"input-name-header"}
                    />
                  ))}
              </>
            )}
          </div>
          {/* {(userAssigned(activeTab) || auditorAssigned()) &&
            product[`${getConcept(activeTab)}_status`] !== "NS" && (
              <div className="commentary-box">
                {[7, 8].includes(user.id_role) && (
                  <Button
                    buttonType={
                      evaluationFinished(
                        user.id_role,
                        activeTab,
                        statusArray
                      ) && requiredNull[activeTab] === 0
                        ? "general-green-button"
                        : "general-button-disabled"
                    }
                    label={"Enviar evaluación"}
                    onClick={() => sendToFacilitator()}
                  />
                )}
              </div>
            )} */}

          {(userAssigned(activeTab) || auditorAssigned()) &&
            product[`${getConcept(activeTab)}_status`] !== "NS" && (
              <div className="commentary-box">
                {!comment ? (
                  <div className="commentary">
                    <TagAndInput
                      label={"Caja de Comentario"}
                      inputType={"textarea"}
                      inputCols={80}
                      inputRows={4}
                      inputId={"commentary-box"}
                      index={0}
                    />
                    <div className="buttons-box">
                      <Button
                        buttonType={"general-transparent-button"}
                        label={"Enviar comentario"}
                        onClick={handleCommentSubmit}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="feedback-box">
                    <Commentary
                      comment={comment?.message?.replace(/<.*?\/?>/gm, "")}
                      reviewed={crossComment}
                    />
                    <Button
                      buttonType={"circular-button accept-button"}
                      onClick={async () => {
                        setCrossComment(true);
                        commentRevised();
                      }}
                    />
                  </div>
                )}
                {[7, 8].includes(user.id_role) && (
                  <Button
                    buttonType={
                      evaluationFinished(
                        user.id_role,
                        activeTab,
                        statusArray
                      ) && requiredNull[activeTab] === 0
                        ? "general-green-button"
                        : "general-button-disabled"
                    }
                    label={"Enviar evaluación"}
                    onClick={() => sendToFacilitator()}
                  />
                )}
              </div>
            )}
        </div>
      </div>
      {showModal && (
        <ProductImageModal
          images={images}
          setShowModal={setShowModal}
          sendToFacilitator={sendToFacilitator}
          approveRejectButtons={approveRejectButtons()}
        />
      )}
      {message.length > 0 && (
        <GenericModal
          buttonType={componentsArray.length > 0 && "delete-product"}
          componentsArray={
            componentsArray.length > 0
              ? componentsArray
              : [
                  <img key="1" src={succes} alt="success icon" />,
                  <ScreenHeader
                    key="2"
                    headerType={"retailer-name-header"}
                    text={message}
                    color={"white"}
                  />,
                ]
          }
          onClick={() => setMessage("")}
        />
      )}
      {showVersionSelector && (
        <VersionSelector
          modalId={"version-selector"}
          articleId={product.article.id_article}
          setVersion={setVersion}
          companyName={product.article.company_name}
          currentVersion={version}
          setShowVersionSelector={setShowVersionSelector}
          jwt={token}
        />
      )}
      {showRejectModal && (
        <Modal
          title={`Agregar mensaje de rechazo para ${activeTab?.toLowerCase()}`}
          show={showRejectModal}
          customComponent={
            <TagAndInput
              inputType={"textarea"}
              inputId={"modal-message-box"}
              index={0}
              color={"white"}
            />
          }
          buttons={[
            <ButtonV2
              key={"btn-Cancelar"}
              type={"white"}
              label={"Cancelar"}
              size={12}
              onClick={() => {
                setShowRejectModal(false);
              }}
            />,
            <ButtonV2
              key={"btn-Aceptar"}
              type={"pink"}
              label={"Aceptar"}
              size={12}
              onClick={async (e) => {
                const element = document.querySelector(
                  "#modal-message-box .ql-container .ql-editor > p"
                );

                if (!element) {
                  console.error("Elemento no encontrado");
                  return;
                }

                const body = element.innerHTML;

                if (typeof body !== "string") {
                  console.error("El contenido de body no es una cadena", body);
                  return;
                }

                await createComment(e, body, activeTab);
                valRejAll ? validateAll("R") : sendToFacilitator("R");
                setMessage("Rechazado");
                setShowRejectModal(false);
              }}
            />,
          ]}
        />
      )}
      <Modal
        className="container-modalAlert"
        show={modalAlert.show}
        title={modalAlert.title}
        message={modalAlert.message}
        icon={"info"}
        onClickBtnDefault={(event) => {
          setModalAlert((prev) => ({
            ...prev,
            show: false,
            errorInputMessage: false,
          }));
        }}
      />
    </Container>
  );
};
