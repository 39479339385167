import axios from "axios";

export const getUsers = async (props, setListUsers, setListUsersFilter, setLoading) => {
    setLoading(true);
    const response = await axios.get(`${process.env.REACT_APP_PENDING_INVITATIONS}`, {
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });
    const collaborators = JSON.parse(response.data.body).data;
    const users = props.tabActive === "Proveedores activos"
      ? collaborators.usersFinanced
      : collaborators.mailsPendings;
    
    setListUsers(users);
    setListUsersFilter(users);
    sessionStorage.setItem("collaborators", JSON.stringify(collaborators));
    setLoading(false);
};
export const searchUser = (userName = "", listUsers, setListUsersFilter, tabActive) => {
    const tempArray = listUsers.slice();
    if (userName.length > 0) {
      const filteredUsers = tabActive === "Proveedores activos"
        ? tempArray.filter((item) =>
            item?.trade_name?.toLowerCase().includes(userName.toLowerCase())
          )
        : tempArray.filter((item) =>
            item?.guest_user_name?.toLowerCase().includes(userName.toLowerCase())
          );
      setListUsersFilter(filteredUsers);
    } else {
      setListUsersFilter(tempArray);
    }
};

export const chkOnChange = (e, id, usersSelected, setUsersSelected) => {
    const tempArray = usersSelected.slice();
    if (e.target.checked) {
      tempArray.push(id);
      setUsersSelected(tempArray);
    } else {
      setUsersSelected(tempArray.filter((item) => item !== id));
    }
};

export const onChangeName = (e, listUsers, setListUsersFilter, tabActive) => {
    const userName = e.target.value;
    searchUser(userName, listUsers, setListUsersFilter, tabActive);
};
