import { Container } from "./styles";
import { FeatureTag } from "../../atoms/FeatureTag/index";
import { useEffect, useState } from "react";

export const FeaturesBar = ({ features }) => {
  const [isIE, setIsIE] = useState(false);

  useEffect(() => {
    setIsIE(
      window.navigator.userAgentString?.indexOf("MSIE") > -1 ||
        window.navigator.userAgentString?.indexOf("rv:") > -1
    );
  }, []);

  return (
    <Container className={isIE ? "IEClass" : "gapClass"}>
      {features !== undefined &&
        features.map(
          (feature, index) =>
            feature.value && (
              <FeatureTag
                key={index}
                feature={feature.feature}
                value={feature.value}
              />
            )
        )}
    </Container>
  );
};
