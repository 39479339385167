import { Avatar } from "../../atoms/Avatar/index";
import { Container } from "./styles";
import nullIcon from "../../../assets/images/versionSelector/nullIcon.svg";
import acceptIcon from "../../../assets/images/versionSelector/acceptIcon.svg";
import versionSelected from "../../../assets/images/versionSelector/versionSelected.svg";
import { Button } from "../../atoms/GeneralButton";
import { ScreenHeader } from "../../atoms/ScreenHeader";
import { FontFamily, GlobalColors } from "../../../global-files/variables";

export const VersionItem = ({
  version = "",
  articleStatus,
  currentVersion,
  productOwner = "owner test",
  setVersion,
  timestamp,
}) => {
  let textExportedDate = "";
  if (timestamp !== null) {
    const exportedDate = new Date(timestamp).toLocaleDateString();
    textExportedDate = `Última vez exportado: ${exportedDate}`;
  }

  return (
    <Container>
      <div className="header">
        <Button
          buttonType={"circular-button" + (currentVersion ? " disabled" : "")}
          image={versionSelected}
          onClick={() => setVersion && setVersion(version)}
        />
        <img
          src={articleStatus ? acceptIcon : nullIcon}
          alt={articleStatus ? "Validated version" : "Unvalidated version"}
        />
      </div>
      <div className="version-info">
        <ScreenHeader
          text={"Versión " + version}
          headerType={"input-name-header"}
          color={GlobalColors.magenta_s2}
        />
        <p>{textExportedDate}</p>
        <p>
          {currentVersion
            ? "Versión actual"
            : articleStatus
            ? "Edición Content-Oh!"
            : "Versión de " + productOwner}
        </p>
        <p>{articleStatus ? "Content-Oh! digital" : productOwner}</p>
      </div>
    </Container>
  );
};
