import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  &.gapClass {
    gap: 8px;
  }

  &.IEClass {
    * + * {
      margin-left: 8px;
    }
  }
`;
