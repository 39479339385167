import { useState, useEffect } from "react";
import axios from "axios";

const useImages = ({ articleId, version, idCategory }) => {
  const [imagesData, setImagesData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      if (!idCategory || !articleId || !version) {
        return;
      }
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_ARTICLE_DATA_IMAGES_ENDPOINT}?articleId=${articleId}&version=${version}`,
          {
            category: idCategory,
            retailersList: false,
          },
          {
            headers: {
              Authorization: sessionStorage.getItem("jwt"),
            },
          }
        );
        const data= JSON.parse(response.data.body).data;
        setImagesData(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    if (articleId) {
      fetchImages(); 
    }
  }, [articleId, version, idCategory]);

  return {
    imagesData,
    loading,
    error,
  };
};

export default useImages;
