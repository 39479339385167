import styled from "styled-components";
import { FontFamily, GlobalColors } from "../../../global-files/variables";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #281f3366;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-container {
    position: relative;
    padding: 40px 20px;
    background: #281f33;
    border-radius: 39px;

    .button-container {
      display: flex;
      justify-content: flex-end;
      padding-right: 15px;
    }
  }
`;

export const VersionOptions = styled.div`
  max-width: 560px;
  text-align: center;

  & > * + * {
    margin-top: 20px;
  }

  img + * {
    margin-top: 0;
  }

  .buttons-container {
    display: flex;
    justify-content: space-evenly;

    button {
      flex: 0.3 0 30%;
    }

    .general-transparent-button {
      color: ${GlobalColors.white};
      border: 1px solid ${GlobalColors.white};
    }
  }
`;

export const EmptyVersion = VersionOptions;

export const CreateVersion = styled(VersionOptions)`
  .retailer-name-header {
    margin: 30px 0;
  }
  .version-selector {
    display: flex;
    justify-content: space-evenly;

    .selector {
      flex: 0.1 1 20%;
      font-family: ${FontFamily.Lato};
      color: ${GlobalColors.white};
      font-size: 11px;
      font-weight: normal;
    }

    & + * {
      margin-top: 50px;
    }
  }
`;
