import styled from "styled-components";

export const Container = styled.div`
  height: 22px;
  width: 10px;
  border-left: 1px solid #342b3f;
  padding-left: 3px;

  img {
    width: 7px;
    vertical-align: middle;
  }

  &.active {
    img {
      transform: rotate(180deg);
    }
  }
`;
