import React, {useState} from 'react'
import Loading from '../../../general/loading';
import searchIcon from "../../../../assets/IconComponents/search.svg";
import { MainContainer, BasicData } from './styles';
import useNotifications from '../../../../hooks/useNotification';
import { onChangeName, chkOnChange } from './utils';
import { CheckboxCustom } from '../../../general/customInputs/checkboxCustom';

const PendingInvitations = (props) => {
    const [loading, setLoading] = useState(false);
    const [userName, setUserName] = useState("");
    const [usersSelected, setUsersSelected] = useState([]);
    const [listUsers, setListUsers] = useState([]);
    const [listUsersFilter, setListUsersFilter] = useState([]);
    const {
      notifications,
      notificationOpen,
      addNotification,
      deleteNotification,
      setNotificationOpen,
    } = useNotifications();

    return loading ? (
        <Loading />
      ) : (
        <MainContainer>
          <div className="filters-container">
            <div className="filters">
              <div className="search-user-input">
                <img src={searchIcon} alt="search-icon" />
                <input
                  type="text"
                  placeholder="Buscar usuarios"
                  value={userName}
                  onChange={(e) =>
                    onChangeName(e, listUsers, setListUsersFilter, props.tabActive)
                  }
                />
              </div>
            </div>
          </div>
    
          <BasicData>
            <div className="row header">
              <p className="col">Nombre</p>
              <p className="col text_center">Correo</p>
            </div>
            {listUsersFilter.length > 0 ? (
              listUsersFilter.map((e, i) => (
                <div className="row margin_row" key={i}>
                  <div className="col chk-cont">
                    <CheckboxCustom
                      id={"chk-" + i}
                      htmlFor={"chk-" + i}
                      onChange={(evt) =>
                        chkOnChange(
                          evt,
                          e.id_user || e.id,
                          usersSelected,
                          setUsersSelected
                        )
                      }
                    />
                    <p>{`${e.trade_name || e.guest_user_name}` || "---"}</p>
                  </div>
                  <p className="col">{e.email || e.guest_user_email || "---"}</p>
                </div>
              ))
            ) : (
              <p className="no-users">No hay usuarios registrados</p>
            )}
          </BasicData>
        </MainContainer>
      );
}

export default PendingInvitations