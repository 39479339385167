import { Container } from "./styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { ScreenHeader } from "../../atoms/ScreenHeader";
import { Button } from "../../atoms/GeneralButton";
import addVersion from "../../../assets/images/versionSelector/addVersion.svg";
import closeIcon from "../../../assets/images/versionSelector/closeVersionSelector.svg";
import { FontFamily, GlobalColors } from "../../../global-files/variables";
import { VersionItem } from "../../molecules/VersionItem";
import { CreateVersion } from "../../organisms/CreateVersion";
import { useCloseModal } from "../../../global-files/customHooks";

export const VersionSelector = ({
  modalId,
  articleId,
  setVersion,
  companyName,
  currentVersion,
  setShowVersionSelector,
  jwt,
}) => {
  const [versions, setVersions] = useState([]);
  const [showCreateVersion, setShowCreateVersion] =
    useCloseModal("create-version");
  const [reload, setReload] = useState(false);

  const loadProductVersions = async (articleId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_VERSIONS_ENDPOINT}?articleId=${articleId}&provider=true`,
        {
          headers: {
            Authorization: jwt,
          },
        }
      );

      const versionList = JSON.parse(response.data.body).data;
      setVersions(versionList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const ac = new AbortController();
    loadProductVersions(articleId);

    return () => {
      setVersions([]);
      setShowCreateVersion(false);
      return () => ac.abort(); // Abort both fetches on unmount
    };
  }, [reload]);

  return (
    <Container id={modalId}>
      <div className="versions-header">
        <ScreenHeader
          text={"Versión del producto"}
          headerType={"input-name-header"}
          color={GlobalColors.white}
          fontFamily={FontFamily.Lato}
        />
        <div className="buttons-container">
          <Button
            image={addVersion}
            buttonType={"circular-button"}
            onClick={() => setShowCreateVersion(true)}
            id="add-version"
          />
          <Button
            image={closeIcon}
            buttonType={"circular-button"}
            onClick={() => setShowVersionSelector(false)}
            id="close-button"
          />
        </div>
      </div>
      <div className="versions-container">
        {versions?.map((item) => (
          <VersionItem
            key={item.version}
            version={item.version}
            articleStatus={item.article_status}
            currentVersion={item.version === currentVersion}
            productOwner={companyName}
            setVersion={setVersion}
            timestamp={item.timestamp}
          />
        ))}
      </div>
      {showCreateVersion && (
        <CreateVersion
          articleId={articleId}
          version={currentVersion}
          versionsList={versions}
          setShowCreateVersion={setShowCreateVersion}
          realoadVersion={() => setReload(!reload)}
          jwt={jwt}
        />
      )}
    </Container>
  );
};
