import React from 'react'
import contentoh from "../../../assets/Login/logo.gif";
import { Container } from './styles';

const FloatLogo = () => {
  return (
    <Container>
        <img className='contentoh-logo fa-bounce' src={contentoh} alt="contentoh-logo" />
    </Container>
    
  )
}

export default FloatLogo