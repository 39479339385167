import dashboard from "../assets/IconComponents/menuContentOh/iconDashboard.svg";
import products from "../assets/IconComponents/menuContentOh/iconProducts.svg";
import merchants from "../assets/IconComponents/menuContentOh/iconMerchants.svg";
import contentoh from "../assets/IconComponents/menuContentOh/iconContentoh.svg";
import task from "../assets/IconComponents/menuContentOh/iconTask.svg";

export const Sections = [
  {
    key: "dashboard",
    icon: dashboard,
    path: "/Dashboard",
    title: "Dashboard",
    roles: ["general"],
  },
  {
    key: "products",
    icon: products,
    path: "/products",
    title: "Productos",
    suboptions: [
      { path: "/allacquired", title: "General", roles: ["onboarding","general"] },
      { path: "/tasks", title: "Tareas", roles: ["onboarding","general"] },
      { path: "/AddProducts", title: "Agregar Producto", roles: ["provider"] },
    ],
    roles: ["onboarding","general"],
  },
  {
    key: "merchants",
    icon: merchants,
    path: "/merchants",
    title: "Onboarding",
    suboptions: [
      { path: "/merchants", title: "Productos", roles: ["onboarding"] },
      { path: "/merchantsLists", title: "Listas", roles: ["onboarding"] },
      { path: "/acquired", title: "Adquiridos", roles: ["onboarding"] },
    ],
    roles: ["onboarding"],
  },
  {
    key: "contentoh",
    icon: contentoh,
    path: "/ContentohProducts",
    title: "Content-oh!",
    suboptions: [
      { path: "/ContentohProducts", title: "Productos", roles: ["general"] },
      { path: "/orders", title: "Órdenes", roles: ["general", "provider"] },
      { path: "/Quotes", title: "Citas", roles: ["general"] },
    ],
    roles: ["general", "provider"],
  },
  {
    key: "tasks",
    icon: task,
    path: "/tasks",
    title: "Tareas",
    roles: ["general"],
  },
];
