import React, { useEffect, useState } from "react";
import { ContainerModal } from "./styles";
import {
  faClose as xIcon,
  faExclamationTriangle as warningIcon,
  faCheck as successIcon,
  faExclamation as infoIcon,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { Fade, Grow, Zoom, Modal as ModalMUI, Backdrop } from "@mui/material";
import { ButtonV2 } from "../../atoms/ButtonV2";
import { CustomIcon } from "../../atoms/CustomIcon";

export const Modal = (props) => {
  const {
    className, // clases CSS del componente raiz del modal
    show, // boolean
    title, // string
    message, // string
    icon, // "error" | "warning" | "success" | "successV2" | "info" (por default no icon)
    image,
    customComponent, // JSX
    buttons, // array<JSX>
    onClickModal, // (event) => {}
    onClickBtnDefault, // (event) => {}
  } = props;
  const icons = {
    error: xIcon,
    warning: warningIcon,
    info: infoIcon,
    success: successIcon,
    successv2: successIcon,
  };

  return (
    <Backdrop
      open={show}
      className="container-backgroundModal"
      timeout={400}
      style={{
        backgroundColor: "transparent",
        zIndex: 10100,
      }}
      onClick={(event) => onClickModal && onClickModal(event)}
    >
      <Fade in={show} timeout={400}>
        <ContainerModal className={className ? className : ""}>
          <div className="contentModal">
            <header>
              {/* titulo */}
              {![null, undefined, ""].includes(title?.trim()) && (
                <label className="label-title">{title.trim()}</label>
              )}
            </header>

            {/* imagen modal */}
            {image && (
              <img src={image} alt="img modal"></img>
            )}
            {/* icono modal */}
            {icons[icon?.toLowerCase()] && (
              <CustomIcon
                type={"white"}
                className={"iconModal " + "iconModal-" + icon.toLowerCase()}
                size={35}
                borderType={"circle"}
                icon={icons[icon.toLowerCase()]}
              />
            )}

            {/* label message */}
            {![null, undefined, ""].includes(message?.trim()) && (
              <label className="label-message"> {message.trim()} </label>
            )}

            {/* custom component */}
            {customComponent && (
              <div className="container-customComponent">{customComponent}</div>
            )}

            {/* container buttons */}
            <div className="container-buttons">
              {buttons?.length ? (
                buttons
              ) : (
                <ButtonV2
                  key={"btn-Aceptar"}
                  type={"white"}
                  borderType={"oval"}
                  label={"Aceptar"}
                  size={12}
                  onClick={(event) =>
                    onClickBtnDefault && onClickBtnDefault(event)
                  }
                />
              )}
            </div>
          </div>
        </ContainerModal>
      </Fade>
    </Backdrop>
  );
};
