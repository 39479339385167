import styled from "styled-components";

export const Container = styled.div`
  width: 340px;
  display: flex;
  overflow: auto;

  &.modal-image-selector {
    width: 345px;
    height: 218px;
    flex-wrap: wrap;
  }
`;
