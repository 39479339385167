import React, { useState, useEffect, useReducer, useRef } from "react";
import { Container } from "./styles";
import { Button } from "@mui/material";
import { GroupRow } from "./styles";
import trash from "../../../assets/IconComponents/EditGroup/trash.svg";
import edit from "../../../assets/IconComponents/EditGroup/edit.svg";
import close from "../../../assets/IconComponents/EditGroup/close.svg";
import save from "../../../assets/IconComponents/EditGroup/save.svg";
import { Fade, Grow, Zoom, Backdrop } from "@mui/material";
import axios from "axios";

const reducerGroups = (state, action) => {
  let { values } = state;
  switch (action.type) {
    case "init":
      return { ...state, values: action.payload };
    case "addGroup":
      values = [...values, action.group];
      return { ...state, values };
    case "updateGroup":
      values[action.payload.index][action.payload.property] =
        action.payload.value;
      return { ...state, values };
    case "deleteGroup":
      values = values.map((item) =>
        item.groupId !== action.payload.id ? item : { ...item, isDeleted: true }
      );
      return { ...state, values };
    default:
      return state;
  }
};

export const EditGroup = ({ token, show, onClose }) => {
  const [groups, setGroups] = useState([]);
  const [state, dispatch] = useReducer(reducerGroups, {});
  const [isMoreThanTenGroups, setIsMoreThanTenGroups] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isEditingList, setIsEditingList] = useState([]);
  const [newGroup, setNewGroup] = useState(false);
  const [currentEditIndex, setCurrentEditIndex] = useState(null);
  const [inputValue, setInputValue] = useState([]);

  useEffect(() => {}, [groups]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_GROUPS_ENDPOINT}/get`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );

      const receivedGroups = JSON.parse(response.data.body).groups;
      const groupsArray = [];
      receivedGroups.forEach(({ id, name, retailer_id }) => {
        groupsArray.push({
          groupId: id,
          groupName: name || null,
          retailerId: retailer_id || null,
        });
      });
      setGroups(groupsArray);

      dispatch({ type: "init", payload: groupsArray });
    } catch (error) {
      console.error("Error al obtener grupos:", error);
    }
  };

  const AddGroup = async (index, concatenatedValue) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_GROUPS_ENDPOINT}/create`,
        {
          groupName: concatenatedValue,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      const createdGroup = response.data.body;
      dispatch({ type: "addGroup", payload: createdGroup });
      setEditingIndex(null);
      setInputValue([]);
      setIsEditingList((prevList) => {
        const newList = [...prevList];
        newList[index] = false;
        return newList;
      });
      setCurrentEditIndex(null);
      loadData();
    } catch (error) {
      console.error("Error al agregar grupo:", error);
    }
  };

  const handleUpdateGroup = async (index, groupId, updatedGroupName) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_GROUPS_ENDPOINT}/update?id=${groupId}&groupName=${updatedGroupName}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const updatedData = response.data.body;
      dispatch({
        type: "updateGroup",
        payload: {
          index,
          property: "groupName",
          value: updatedData.groupName,
        },
      });
      setEditingIndex(null);
      setInputValue([]);
      setIsEditingList((prevList) => {
        const newList = [...prevList];
        newList[index] = false;
        return newList;
      });
      setCurrentEditIndex(null);
      loadData();
    } catch (error) {
      console.error("Error al actualizar grupo:", error);
    }
  };

  const deleteGroup = async (groupId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_GROUPS_ENDPOINT}/delete?id=${groupId}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch({
        type: "deleteGroup",
        payload: { id: groupId },
      });
      loadData();
    } catch (error) {
      console.error("Error al borrar grupo:", error);
    }
  };

  const handleEditButtonClick = (index) => {
    if (isEditingList[index]) {
      handleSaveChanges(index);
    } else {
      setIsEditingList((prevList) => {
        const newList = [...prevList];
        newList[index] = true;
        return newList;
      });
      setEditingIndex(index);
      setInputValue(groups[index].groupName);
      setNewGroup(groups[index].groupId);
      setCurrentEditIndex(index);
    }
  };

  const handleDeleteButtonClick = async (groupId) => {
    await deleteGroup(groupId);
  };

  const handleInputChange = (value, index) => {
    setInputValue((prevInputValue) => prevInputValue + value);

    setGroups((prevGroups) => {
      const updatedGroups = [...prevGroups];
      if (index !== undefined) {
        updatedGroups[index] = { ...updatedGroups[index], groupName: value };
      }
      return updatedGroups;
    });
  };

  const handleAddButtonClick = () => {
    const isAnyGroupEditing = isEditingList.some((editing) => editing);
    if (!isAnyGroupEditing && groups.length < 20) {
      setIsEditingList((prevList) => {
        const newList = [...prevList];
        newList[groups.length] = true;
        return newList;
      });
      setEditingIndex(groups.length);
      const nuevoGrupo = {
        groupName: inputValue,
        isEditing: true,
      };
      setGroups([...groups, nuevoGrupo]);
      setIsMoreThanTenGroups(groups.length + 1 > 10);
      setNewGroup(undefined);
      setCurrentEditIndex(groups.length);
    }
  };

  const handleSaveChanges = (index) => {
    const concatenatedValue = groups[index].groupName;
    if (newGroup !== undefined) {
      handleUpdateGroup(index, newGroup, concatenatedValue);
    } else {
      AddGroup(index, concatenatedValue);
    }
  };

  return (
    <Backdrop
      open={show}
      className="container-backgroundModal"
      timeout={400}
      style={{
        backgroundColor: "transparent",
        zIndex: show ? 10 : -1,
      }}
    >
      <Fade in={show} timeout={400}>
        <Container>
          <div className="Modal-edit-group">
            <div className="header">
              <h2 className="title-edit">Editar grupos</h2>
              <Button
                className={`circular-button close-edit-button`}
                onClick={onClose}
              >
                <img src={close} alt="close icon" />
              </Button>
            </div>
            <p className="details-edit">
              Agrega, edita y borra. Luego guarda los cambios.
            </p>
            <div
              className={`${
                isMoreThanTenGroups ? "table-groups-max" : "table-groups-edit"
              }`}
            >
              {groups.map((group, index) => (
                <GroupRow
                  key={group.groupId}
                  editing={editingIndex === index}
                  deleting={deletingIndex === index}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  className={`group-row ${
                    isEditingList[index] ? "editing" : ""
                  }`}
                >
                  <input
                    key={index}
                    className={`input-group`}
                    placeholder="Escribe el nombre del grupo"
                    value={index !== undefined ? group.groupName : inputValue}
                    onChange={(e) => handleInputChange(e.target.value, index)}
                    disabled={!isEditingList[index]}
                    autoFocus={isEditingList[index] && editingIndex === index}
                  />
                  {currentEditIndex === index ? (
                    <Button
                      className={`circular-button save-group-button visible`}
                      onClick={() => handleSaveChanges(index)}
                    >
                      <img src={save} alt="save update icon" />
                    </Button>
                  ) : (
                    <>
                      <Button
                        className={`circular-button edit-group-button ${
                          hoveredIndex === index ? "visible" : ""
                        }`}
                        onClick={() => handleEditButtonClick(index)}
                      >
                        <img src={edit} alt="edit icon" />
                      </Button>
                      <Button
                        className={`circular-button delete-group-button ${
                          hoveredIndex === index ? "visible" : ""
                        }`}
                        onClick={() => handleDeleteButtonClick(group.groupId)}
                      >
                        <img src={trash} alt="trash icon" />
                      </Button>
                    </>
                  )}
                </GroupRow>
              ))}
            </div>
            <div className="container-save-add">
              <Button
                className="button-group-add"
                onClick={() => handleAddButtonClick()}
              >
                + Agregar Grupo
              </Button>
            </div>
          </div>
        </Container>
      </Fade>
    </Backdrop>
  );
};
