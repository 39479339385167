import styled from "styled-components";
import { FontFamily, GlobalColors } from "../../../global-files/variables";

export const Container = styled.div`
  width: 500px;
  height: 80px;
  border: 1px solid ${GlobalColors.s2};
  padding: 10px;
  p {
    font-family: ${FontFamily.AvenirNext};
    font-size: 12px;
    line-height: 15px;
    color: ${GlobalColors.deep_gray};
    text-decoration: ${({ reviewed }) => (reviewed ? "line-through" : "none")};
  }
`;