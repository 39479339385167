import React, { useState, useEffect } from "react";
import axios from "axios";
import { MainContainer, Header, BasicData, TitleIcon } from "./styles";
// SVG assets
import edit from "../../../assets/IconComponents/edit.svg";
import billingIcon from "../../../assets/IconComponents/billing.svg";
import { Textarea, SubmmitButton, SaveData } from "../CompanyData/styles";
import CustomNotification from "../../general/CustomNotification";
import useNotifications from "../../../hooks/useNotification";
import CustomButton from "../../general/CustomButton";
import HeaderData from "../HeaderData";
import CustomInput from "../../general/CustomInput";

const Billing = (props) => {
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem("user")));
  const [canEdit, setCanEdit] = useState(false);
  const [company, setCompany] = useState(
    JSON.parse(sessionStorage.getItem("company"))
  );
  const {
    notifications,
    notificationOpen,
    addNotification,
    deleteNotification,
    setNotificationOpen,
  } = useNotifications();

  const onSubmit = async (e) => {
    e.preventDefault();
    let newValues = {};
    document.querySelector("#nameInput").value !== "" &&
      (newValues.name = document.querySelector("#nameInput").value);
    document.querySelector("#addressInput").value !== "" &&
      (newValues.address = document.querySelector("#addressInput").value);
    document.querySelector("#zipCodeInput").value !== "" &&
      (newValues.zip_code = document.querySelector("#zipCodeInput").value);
    document.querySelector("#telephoneInput").value !== "" &&
      (newValues.telephone = document.querySelector("#telephoneInput").value);
    setUser(newValues);

    let newValuesCompany = {};
    document.querySelector("#rfcInput").value !== "" &&
      (newValuesCompany.rfc = document.querySelector("#rfcInput").value);

    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_USER_ENDPOINT}?boolCompany=1`,
        data: newValuesCompany,
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      });

      await axios.put(
        `${process.env.REACT_APP_USER_ENDPOINT}?boolUser=1`,
        newValues,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      );
      let response = await axios.get(process.env.REACT_APP_USER_ENDPOINT, {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      });
      const newUserInfo = JSON.parse(response.data.body).data[0];
      const newCompanyInfo = JSON.parse(response.data.body).data[1];
      sessionStorage.setItem("user", JSON.stringify(newUserInfo));
      sessionStorage.setItem("company", JSON.stringify(newCompanyInfo));
      setUser(newUserInfo);
      setCompany(newCompanyInfo);
      setCanEdit(!canEdit);
      addNotification("success", "Se han actualizado los datos correctamente");
    } catch (err) {
      console.log(err.message);
      addNotification("error", "Error al actualizar los datos");
    }
  };

  return (
    <MainContainer>
      <HeaderData
        icon={billingIcon}
        text="Datos de facturación"
        active={canEdit}
        setActive={setCanEdit}
      />
      <BasicDataSection user={user} company={company} canEdit={canEdit} />
      {canEdit && (
        <SaveData>
          <CustomButton
            label="Guardar cambios"
            variant="pink"
            onClick={(e) => {
              onSubmit(e);
            }}
          />
        </SaveData>
      )}
      <CustomNotification
        open={notifications.length > 0}
        alerts={notifications}
        deleteNotification={deleteNotification}
      />
    </MainContainer>
  );
};

export default Billing;

const BasicDataSection = ({ user, company, canEdit }) => (
  <BasicData>
    <div>
      <p className="item">Nombre</p>
      {canEdit ? (
        <CustomInput
          id="nameInput"
          placeholder="Nombre"
          defaultValue={user.name ? user.name : ""}
        />
      ) : (
        <p>{user.name ? user.name : "---"}</p>
      )}
    </div>
    <div>
      <p className="item">RFC</p>
      {canEdit ? (
        <CustomInput
          id="rfcInput"
          placeholder="RFC"
          defaultValue={company.rfc ? company.rfc : ""}
        />
      ) : (
        <p>{company.rfc ? company.rfc : "---"}</p>
      )}
    </div>
    <div>
      <p className="item">Dirección fiscal</p>
      {canEdit ? (
        <Textarea
          id="addressInput"
          placeholder="Agrega tu dirección"
          defaultValue={user.address ? user.address : ""}
        />
      ) : (
        <p>{user.address ? user.address : "---"}</p>
      )}
    </div>
    <div>
      <p className="item">Código postal</p>
      {canEdit ? (
        <CustomInput
          id="zipCodeInput"
          placeholder="Código postal"
          type="number"
          defaultValue={user.zip_code ? user.zip_code : ""}
        />
      ) : (
        <p>{user.zip_code ? user.zip_code : "---"}</p>
      )}
    </div>
    <div>
      <p className="item">Teléfono</p>
      {canEdit ? (
        <CustomInput
          id="telephoneInput"
          placeholder="Teléfono"
          type="tel"
          defaultValue={user.telephone || ""}
        />
      ) : (
        <p>{user.telephone || "---"}</p>
      )}
    </div>
    <div>
      <p className="item">Correo electrónico</p>
      <p>{JSON.parse(sessionStorage.getItem("user")).email}</p>
    </div>
  </BasicData>
);
