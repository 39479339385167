import { Container } from "./styles";
import { ScreenHeader } from "../../atoms/ScreenHeader";
import { StatusAsignationInfo } from "../StatusAsignationInfo";
import { GlobalColors } from "../../../global-files/variables";

export const TableHeader = ({
  imagesStatus,
  activeImage,
  assignationsImages,
  setAssignation,
  isRetailer,
  onClickSave,
  showSaveButton,
  version,
  setShowVersionSelector,
}) => {
  return (
    <Container>
      <ScreenHeader
        text={activeImage?.name || "-"}
        color={GlobalColors.s5}
        headerType={"input-name-header"}
      />
      <StatusAsignationInfo
        status={imagesStatus}
        assignationsImages={assignationsImages}
        setAssignation={setAssignation}
        isRetailer={isRetailer}
        onClickSave={onClickSave}
        showSaveButton={showSaveButton}
        version={version}
        setShowVersionSelector={setShowVersionSelector}
      />
    </Container>
  );
};
