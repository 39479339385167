import { Container } from "./styles";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import React from "react";

export const CustomIcon = (props) => {
  const {
    id,
    key,
    className,
    type, // white | whiteS2 | pink | gray || purple | black  => (default white)
    transparent, // boolean
    size, // number ... default (13)
    borderType, // rectangle || circle (default)
    icon, // icon fontawesome
  } = props;

  return (
    <>
      {icon ? (
        <Container
          id={id}
          key={key}
          className={
            "container-icon " +
            (["white", "whiteS2", "pink", "gray", "purple", "black"].includes(
              type
            )
              ? type
              : "white") +
            (transparent ? " transparent " : " fill ") +
            className
          }
          borderType={borderType}
          size={size}
        >
          <Icon icon={icon} />
        </Container>
      ) : null}
    </>
  );
};
