import styled from "styled-components";

export const MainContainer = styled.div`
.modal-reject{
    .reject-textarea {
        font-family: 'Roboto';
        width: 100%;
        min-width: 190px;
        height: 28px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        padding: 7px;
        box-sizing: border-box;
        border-radius: 6px;
        border: 1px solid var(--gray-light);
        background-color: var(--white-contentoh);
        resize: vertical; 
        max-height: 166px;
    }
}

`;