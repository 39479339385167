import styled from "styled-components";

export const MainContainer = styled.div`
  border: 1px solid var(--gray-light-secondary);
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .p-5 {
    width: 90%;
    margin-top: 20px;
  }
`;

export const Section = styled.div`
  width: 95%;
  border-bottom: 1px solid var(--gray-light-secondary);
  padding: 14px 0px;
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: var(--gray-contentoh);
  }
`;

export const Header = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction:row;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #817393;
  }
`;

export const BasicData = styled(Section)`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  box-sizing: border-box;
  div {
    display: flex;
    flex-direction: column;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: -0.015em;
    color: #969696;
    p:first-child {
      font-size: 12px;
      line-height: 16px;
      color: var(--gray-contentoh);
      font-weight: 400;

      & + * {
        margin-top: 5px;
      }
    }
    a {
      text-decoration: none;
      color: #d43594;
      :hover {
        text-decoration: underline;
      }
    }
    button {
      font-size: 18px;
      line-height: 25px;
      color: var(--white-contentoh);
      width: 200px;
      height: 40px;
      margin: 15px 0;
      background: #d43594;
      border-radius: 30px;
      border-width: 0;
      :hover {
        cursor: pointer;
      }
    }
    label {
      font-size: 10px;
      color: red;
    }
  }
  .align_right {
    width: 100%;
    display: flex;
    align-items: flex-end;
  }
`;

export const TitleIcon = styled.figure`
  margin: 0 15px 0 0;
  align-items: center;
  cursor: pointer;
`;


export const FormularioChange = styled.div`
  display:flex;
  flex-direction:column;
  gap:30px;
  width:90%;
  .container-formulario{
    background: var(--white-contentoh);
    border:1px solid #F0F0F0;
    box-shadow: 0px 2px 4px 0px #00000040;
    border-radius:10px;
    padding:15px;
    display:flex;
    flex-direction:column;
    gap:15px;
  }
  .item{
    width: auto;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: rgb(80, 61, 102);
  }
`;


export const ItemRegister = styled.div`
  width: 100%;
  display:flex;
  align-items: center;
  max-width:288px;
  p {
    margin-bottom: 10px;
    width: 100%;
  }
  .gray{
    color: #CBCBCB;
    font-size:18px;
  }
  
  .resendTrue {
    color: green;
  }
  .password {
    & + * {
      margin-top: 20px;
    }
    &:hover {
      border: 1px solid var(--pink-contentoh);
    }
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    position: relative;
    width: 100%;
    border: 1px solid #f0eef2;
    .icon {
      position: absolute;
      height: 60%;
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      opacity: 0.3;
      cursor: pointer;
    }
    .icon:hover {
      opacity: 0.8;
    }
  }
`;


export const Button = styled.button`
  font-family: Roboto;
  font-size: 12px;
  background: ${({ background }) => (background ? background : "var(--pink-contentoh)")};
  border-radius: 30px;
  border: 1px solid var(--pink-contentoh);
  width: auto;
  height: 24px;
  padding: 4px 8px;
  color: ${({ color }) => (color ? color : "white")};
  cursor:  pointer;
  min-width: 100px;
  :hover{
    background: var(--white-contentoh);
    border: 1px solid var(--pink-contentoh);
    color:var(--pink-contentoh);
  }
`;

export const RowButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2%;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap:10px;
  padding: 10px 10px;
`;