import styled, { css } from "styled-components";

export const ImageContainer = styled.div`
  align-items: center;
  background: var(--white-contentoh);
  border-radius: 7px;
  border: 1px solid var(--gray-medium);
  cursor: pointer;
  display: flex;
  height: 52px;
  justify-content: center;
  width: 52px;
  & > img {
    border-radius: 7px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

export const ImageView = styled.div`
  background: var(--white-contentoh);
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  height: auto;
  width: 250px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  z-index: 10;
  overflow: hidden;

  .header {
    align-items: center;
    background: var(--gray-medium);
    border-radius: 5px 5px 0px 0px;
    color: var(--white-main, #fff);
    display: flex;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    height: auto;
    justify-content: space-between;
    line-height: normal;
    overflow: hidden;
    padding: 3px 5px;
    text-overflow: ellipsis;
    width: 100%;

    .buttons {
      display: flex;
      gap: 5px;
      button{
        height: 24px;
        width: 24px;
        .icon{
            font-size: 14px;
        }
      }
    }
  }
  .body {
    width: 100%;
    height: auto;
    overflow: hidden; 
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  ${({ visorView }) =>
    visorView === "fullscreen" &&
    css`
      background: var(--black);
      height: 100%;
      left: 0;
      top: 0;
      width: 100%;
      .header {
        background: var(--black);
        font-size: 14px;
        height: 45px;
        .buttons > img {
          border-radius: 20px;
          border: 1px solid var(--white-contentoh);
          padding: 3px;
        }
      }
      > div {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;
        & > img {
          border-radius: 5px;
          height: 500px;
          width: 500px;
        }
      }
    `}
`;


export const FullScreenModal = styled.div`
  position: fixed;
  top: 0;
  left: 0; 
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 9999;
  padding: 20px; 
  box-sizing: border-box; 

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    color: white;
    .name {
        font-size: 14px;
        font-weight: 600;
    }
    .buttons {
        display: flex;
        gap: 10px;
    }
  }

  .body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  img {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;
