import styled from "styled-components";

export const Container = styled.div`
  .features-bar-container {
    display: flex;
    justify-content: space-between;
  }

  & + * {
    margin-top: 17px;
  }
`;
