import { Container, ContainerIcon } from "./styles";
import { Tooltip } from "../Tooltip";

export const ProgressBar = ({ percent, progressBarType, percentRequired }) => {
  return (
    <>
      {percentRequired ? (
        <>
          <Tooltip
            componentTooltip={
              <>
                <p>
                  <b>Campos requeridos</b>
                </p>
                <p>
                  Este nuevo icono te indicará cuando <br></br> completes todos
                  los campos requeridos
                </p>
              </>
            }
            children={
              <ContainerIcon
                className="fondo"
                percentageRequired={percentRequired}
              >
                <span class="hola">local_police</span>
              </ContainerIcon>
            }
            classNameTooltip={"container-tooltip"}
          />
        </>
      ) : (
        ""
      )}
      <Tooltip
            componentTooltip={
              <>
                <p>
                  <b>Porcentaje de requeridos</b>
                </p>
                <p>Este porcentaje indica la completitud de los atributos requeridos para todos los servicios.</p>
              </>
            }
            children={
              <Container className={`status-${progressBarType}`}>
                <p>{percent}%</p>
              </Container>
            }
            classNameTooltip={"container-tooltip"}
          />
    </>
  );
};
