import styled from "styled-components";
import { FontFamily, GlobalColors } from "../../../global-files/variables";

export const Container = styled.div`
  font-family: ${FontFamily.Roboto}, sans-serif;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: var(--size);
  line-height: 1.2;
  height: auto;
  border-radius: 10px;
  background: white;
  position: relative;
  overflow: hidden;
  width: 100%;
  text-align: center;
  .container-buttons {
    display: flex;
    gap:10px;
  }
  .container-box{
    display: flex;
    flex-direction:column-reverse;
    gap:10px;
  }
  #contenedor-caja {
    display: flex;
    justify-content: space-between;
    color: #262626;
    white-space: nowrap;
    gap: 10px;
  }
  #atr-alto {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
  }
  h1 {
    font-family: ${FontFamily.RobotoMedium}, sans-serif;
    font-size: 17px;
    line-height: 1.2;
    font-weight: 500;
    text-align: left;
  }
`;