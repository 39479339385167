import { SCREEN_SIZES } from '../constants/screen.js'
import useWindowDimensions from "./useWindowDimensions.js"

const useScreenSizes = () => {

    const {width} = useWindowDimensions()

    const isMobile = width<=SCREEN_SIZES.SM
    const isTablet = width>SCREEN_SIZES.SM && width<=SCREEN_SIZES.MD
    const isDesktop = width>SCREEN_SIZES.LG

    return {
        isMobile,
        isTablet,
        isDesktop,
    }

}

export default useScreenSizes