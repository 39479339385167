import React from 'react';
import { Container } from './styles';

const Modal = ({ 
  title = "", 
  text = "", 
  buttons = [], 
  show = false, 
  onClose = () => {}, 
  additionalContent = null, 
  customComponent = null, 
  className = "", 
  titleClassName = "", 
  footerClassName = "", 
  buttonStyles = [], 
  showAccept = false, 
  showCancel = false, 
  onAcceptClick = () => {}, 
  onCancelClick = () => {} 
}) => {

  const handleCancelClick = () => {
    onClose();
    onCancelClick();
  };

  if (!show) return null;

  return (
    <Container className={className} onClick={handleCancelClick}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <div className={`modal-header ${titleClassName}`}>
          <h2>{title}</h2>
          <button className="modal-close-button" onClick={handleCancelClick}>×</button>
        </div>
        <div className="modal-body">
          {text && <p>{text}</p>}
          {additionalContent && <div className="additional-content">{additionalContent}</div>}
          {customComponent && <div className="custom-component">{customComponent}</div>}
        </div>
        <div className={`modal-footer ${footerClassName}`}>
          {showCancel && (
            <button 
              onClick={handleCancelClick}
              className="btn-cancel" 
              style={buttonStyles[1] || {}}
            >
              Cancelar
            </button>
          )}
          {showAccept && (
            <button 
              onClick={onAcceptClick} 
              className="btn-accept" 
              style={buttonStyles[0] || {}}
            >
              Aceptar
            </button>
          )}
          {buttons.map((button, index) => (
            <button 
              key={index} 
              onClick={button.onClick} 
              className={button.className} 
              style={buttonStyles[index + (showAccept || showCancel ? 2 : 0)] || {}}
            >
              {button.label}
            </button>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Modal;
