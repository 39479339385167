import React from 'react';
import PropTypes from 'prop-types';
import CustomInput from '../CustomInput';
import CustomSelect from '../CustomSelect';
import { MainContainer } from './styles';
import { useStore } from "../../../stores/useStore";

const CustomField = ({
  id,
  type = 'input',
  label,
  description,
  required = false,
  min_char = 0,
  max_char = 0,
  option_list = [],
  variant = 'graylight',
  value,
  placeholder,
  onChange,
  onBlur,
  disabled = false,
  isError = false,
  typeAccessor = "customField",
  id_attribute,
}) => {
  const inputType = typeAccessor === "textArea" ? "textarea" : "text";
  const inputValue = value || '';

  let formattedOptions = [];
  // if (type === 'Booleano') {
  //   formattedOptions = [
  //     { value: true, label: 'Sí' },
  //     { value: false, label: 'No' },
  //   ];
  // } else 
  if (option_list.length > 0) {
    formattedOptions = option_list.map((item, index) => ({
      value: item.trim(),
      label: item.trim(),
    }));
  }

  const setDataObjValue = useStore((state) => state.setDataObjValue);
  const store = useStore((state) => state.currentData);

  const handleChange = (selectedOption) => {
    if (selectedOption) {
      const newValue = selectedOption.value;

      setDataObjValue(id_attribute, newValue);
      console.log("store después =>", store);

      onChange(newValue); // Llama a onChange con el valor adecuado
    } else {
      console.error('selectedOption es undefined');
    }
  };
  
  // console.log({value})

  return (
    <MainContainer>
      {label && <label htmlFor={id} className='input-name-header'>{label}{required && '*'}</label>}
      { formattedOptions.length > 0 || type === 'Booleano' ? (
        <CustomSelect
          id={id}
          className="select-attribute"
          label={label}
          options={formattedOptions}
          onChange={handleChange}
          value={formattedOptions.find(option => option.value === inputValue)}
          variant="gray-light"
          placeholder={label}
          id_attribute={id_attribute}
        />
      ) : (
          <CustomInput
            id={id}
            type={inputType}
            placeholder={placeholder}
            value={inputValue}
            onChange={(e) => onChange(e.target)}
            onBlur={onBlur}
            variant={variant}
            disabled={disabled}
            isError={isError}
            id_attribute={id_attribute}
          />
      )}
      {description && <p className='description-limit'>{min_char}/{max_char}</p>}
      {description && <p className='description'>{description}</p>}
    </MainContainer>
  );
};

CustomField.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['Alfanumerico','Alfanumérico', 'Numérico', 'Booleano']),
  label: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  option_list: PropTypes.arrayOf(PropTypes.string),
  variant: PropTypes.oneOf(['gray', 'pink']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  isError: PropTypes.bool,
  typeAccessor: PropTypes.string,
};

export default CustomField;