import styled from "styled-components";
import { GlobalColors, FontFamily } from "../../../../global-files/variables";

export const Container = styled.div`
  width: 100%;
  max-height: 100%;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  background: white;
  padding: 15px 11px;
  overflow: auto;
  //border: 1px solid red;

  .btn-loadMore {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 2;

    .container-btnLoadMore {
      border-radius: 10px;
      .button {
        padding: 5px 10px;
        border: none;
        gap: 10px;
        font-family: ${FontFamily.RobotoRegular};
        .icon,
        .iconLoading {
          font-size: 10px;
        }
      }
    }
  }

  @media (max-width: 768px) { 
    width: 90%;
  }

  // lista de items
  > ul {
    --marginUserValue: 10px;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0px;

    // items cambio de estatus general del article
    // items cambio de estatus en servicio del article
    .label-statusArticle,
    .label-statusService {
      width: 100%;
      padding: 10px;
      border-radius: 10px;
      display: grid;
      place-items: center;
      cursor: default;

      text-align: left;
      font-family: ${FontFamily.RobotoRegular}, sans-serif;
      color: white;
      font-size: 12px;

      .label {
        text-align: left;
        font-family: ${FontFamily.RobotoRegular}, sans-serif;
        color: white;
        font-size: 12px;
        white-space: pre-wrap;
        cursor: inherit;
      }
    }
    .label-statusArticle {
      background-color: #85bc5b;
    }
    .label-statusService {
      background-color: #8a6caa;
    }

    // cada item dentro de la lista (fechas|message|img|file|status...)
    li {
      width: 90%;
      padding: 0px;
      margin: 16px 0px 0px 0px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: nowrap;
      gap: var(--marginUserValue);
      align-items: center;
      align-self: flex-start;
      //border: 1px solid blue;

      & > label:first-child,
      .item-img {
        margin: 0px 0px 0px 44px;
      }

      &.sameUser {
        margin: 2px 0px 0px 0px;
        /* padding: 0px 0px 0px calc(34px + var(--marginUserValue)); */
      }

      // items gray label (para fechas)
      &.label-gray {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px; //0px calc(34px + var(--marginUserValue));

        .separator {
          flex-grow: 2;
          min-width: 35px;
          border: 0.3px solid #f0f0f0;
        }
        label {
          padding: 4px 15px;
          text-align: center;
          font-family: ${FontFamily.RobotoMedium}, sans-serif;
          color: #b3b3b3;
          font-size: 10px;
        }
      }

      // container ImageTooltip del user de un item
      .container-imgUser {
        display: grid;
        place-items: center;
        width: 34px;
        min-width: min-content;
        border-radius: 50%;
        overflow: hidden;
        align-self: flex-start;
        margin: 0;
      }
      .icon-imgUser.fill {
        padding: 0px;
        width: 34px;
        height: 34px;
        color: #9d9d9d;
        border-color: #9d9d9d;
        border-width: 2px;
      }
      .tooltip-imgUser {
        .label-nameUser {
          font-family: ${FontFamily.RobotoMedium}, sans-serif;
          color: #262626;
          font-size: 12px;
          text-align: center;
        }
      }

      // items de tipo message, comment y file
      .item-message,
      .item-comment,
      .item-file,
      .item-statusTicket,
      .item-statusItem {
        border-radius: 10px;
        padding: 10px;
        font-family: ${FontFamily.RobotoRegular}, sans-serif;
        font-size: 12px;
        text-align: left;
        white-space: pre-line;
        color: white;
        word-break: break-word;
      }
      .item-message,
      .item-comment,
      .item-file {
        background-color: #8386ee;
      }
      .item-statusTicket,
      .item-statusItem {
        background-color: #85bc5b;
      }

      .item-statusItem {
        display: flex;
        gap: 3px;
        align-items: center;
      }

      .item-message {
        &.different-company {
          border: 1px solid #e33aa9;
        }
      }
      /*
      .item-comment {
        background-color: #5e61b2;
      }
      */

      // items de tipo img
      .item-img {
        display: grid;
        place-items: center;
        background-color: #f7f7f7;
        border-radius: 10px;
        overflow: hidden;
        max-width: 60%;
        max-height: 300px;

        .container-ImagePreview {
          max-height: inherit;

          > .img {
            max-height: inherit;
          }

          .label-nameImg {
            .iconDefaultImg {
              border-radius: 0px;
              border: none;
              padding: 0px;
              color: #8386ee;
            }
          }
        }
      }

      // items de tipo file
      .item-file {
        width: auto;
        max-width: 70%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;

        .btnDownloadFileItem {
          width: 28px;
          min-width: 28px;
          height: 28px;
          min-height: 28px;
        }

        // nameFile solo 1 linea
        .label-nameFileShort {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          flex-grow: 2;
          cursor: default;
          font-family: ${FontFamily.RobotoRegular}, sans-serif;
          font-size: 12px;
          text-align: center;
          color: white;
        }
      }

      // label hora del item
      .label-time {
        min-width: fit-content;
        color: #c7c7c7;
        font-family: ${FontFamily.RobotoRegular}, sans-serif;
        font-size: 9px;
      }

      & > .label-time:first-child {
        display: none;
      }

      // items del currentUser
      &.own-message {
        align-self: flex-end;
        flex-direction: row-reverse;

        /* &.sameUser {
          padding: 0px calc(34px + var(--marginUserValue)) 0px 0px;
        } */

        .item-message,
        .item-comment {
          color: #262626;
        }
        .item-message,
        .item-comment,
        .item-file {
          background-color: #f0f0f0;
        }

        & > .item-message:first-child {
          margin: 0px 40px 0px 0px;
        }
        /*
        .item-comment {
          background-color: #d7d7d7;
        }
        */

        // items de tipo img
        .item-img {
          .container-ImagePreview {
            .label-nameImg {
              .iconDefaultImg {
                color: #c9c7c7;
              }
            }
          }
        }

        // items de tipo file
        .item-file {
          .btnDownloadFileItem {
            .iconFile,
            .container-btnDownload .buttonMUI .icon {
              color: #262626;
            }
          }

          // nameFile solo 1 linea
          .label-nameFileShort {
            color: #262626;
          }
        }
      }

      // items neutrales (center aligned)
      &.itemNeutral {
        width: 100%;

        .itemStatus {
          width: 100%;
          padding: 10px;
          border-radius: 10px;
          display: grid;
          place-items: center;
          cursor: default;
          text-align: center;
          font-family: ${FontFamily.RobotoRegular}, sans-serif;
          color: white;
          font-size: 12px;
          line-height: 1.2;
          white-space: pre-wrap;

          &.ticket {
            color: white;
            background-color: #85bc5b;
          }
        }
      }
    }
  }

  .content-chat {
    ::-webkit-scrollbar {
      width: 0;
    }
  }
`;
