import styled, { keyframes } from 'styled-components';

export const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export const Circle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ isAvailable }) => (isAvailable ? 'var(--green)' : 'red')};
  animation: ${pulseAnimation} 1.5s infinite;
  border: 2px solid var(--white-contentoh);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
`;