import { Container } from "./styles";
import { useEffect, useState } from "react";
import { getProfilePicture } from "../../../../global-files/data";
import { ImageTooltip } from "../../../molecules/ImageTooltip";
import {
  decodeJSON,
  getFullDate,
  getImageURL,
  getResizeImgHeight,
  getTime,
} from "../../../../global-files/utils";
import {
  faUser as IconUser,
  faImage as IconImage,
  faRedoAlt as IconReload,
} from "@fortawesome/free-solid-svg-icons";
import { CustomIcon } from "../../../atoms/CustomIcon";
import { ButtonV2 } from "../../../atoms/ButtonV2";
import { ButtonDownloadFile } from "../../../molecules/ButtonDownloadFile";
import { Modal } from "../../Modal";
import { Tooltip } from "../../../atoms/Tooltip";
import { Slide, Zoom } from "@mui/material";
import { useRef } from "react";
import { ImagePreview } from "../../../atoms/ImagePreview";
import { Status } from "../../../atoms/Status";
// import { isUserTech } from "../../../../global-files/handle_userTech";
// import { container } from "aws-amplify";

export const ContainerItems = (props) => {
  const {
    items /* [
      { id , date , type , value , userId } ,
      ...
    ] */,
    users /* {
      id: { name , companyId } ,
      ...
    } */,
    currentUser, // { id , companyId , ... }
    activeCompanyId, // number
    chatType, // string
    showBtnLoadMore, // boolean
    onClickBtnLoadMore, // () => {}
    ticketCompany, // id de la comañía que creó el ticket
  } = props;
  const [scrollHeight, setScrollHeight] = useState(0);
  const [btnLoadMoreLoading, setBtnLoadMoreLoading] = useState(false);
  const [customItems, setCustomItems] = useState([]);
  const [modalAlert, setModalAlert] = useState({
    show: false,
    title: "",
    message: "",
  });
  const containerList = useRef(null);
  const containerItems = useRef(null);

  useEffect(() => {
    if (!items) return;
    if (
      ["merchant_product", "order_product", "product_status"].includes(chatType)
    )
      renderItems();
    else if (chatType === "ticket") renderItemsTicket();
    else setCustomItems([]);
  }, [items]);

  useEffect(() => {
    const containerScroll = document.getElementById("ulItems");
    if (customItems.length)
      setTimeout(() => {
        containerScroll.lastElementChild.scrollIntoView({
          block: "start",
        });
      }, 1);
  }, [customItems]);

  const updateScroll = () => {
    const containerScroll = document.getElementById("containerItems");
    if (containerScroll) {
      const newScrollHeight = containerScroll.scrollHeight + 15;
      const positionScroll = newScrollHeight - scrollHeight;

      if (newScrollHeight !== scrollHeight) {
        containerScroll.scrollTop = positionScroll;
        setScrollHeight(newScrollHeight);
      }
    }
  };

  const renderGrayLabel = (text, key) => {
    return (
      <Zoom
        in={true}
        timeout={600}
        container={containerList.current}
        key={key ?? ""}
      >
        <li className={`label-gray`}>
          <div className="separator"></div>
          <label>{text}</label>
          <div className="separator"></div>
        </li>
      </Zoom>
    );
  };

  const renderStatusService = (text, key) => {
    return (
      <Zoom
        in={true}
        timeout={600}
        container={containerList.current}
        key={key ?? ""}
      >
        <div className="label-statusService">{text}</div>
      </Zoom>
    );
  };

  const renderStatusArticle = (text, key) => {
    return (
      <Zoom
        in={true}
        timeout={600}
        container={containerList.current}
        key={key ?? ""}
      >
        <div className="label-statusArticle">{text}</div>
      </Zoom>
    );
  };

  const renderStatusTicket = (item) => {
    return <label className="item-statusTicket">{item.value}</label>;
  };

  const renderStatusItem = (item) => {
    const splitText = item.value.split("|");
    let message = [];
    splitText.forEach((text, i) => {
      if (i === 1 || i === 3) message.push(<Status statusType={text} />);
      else message.push(text);
    });
    return <label className="item-statusItem">{message}</label>;
  };

  const renderStatusTicketNeutral = (item) => {
    return (
      <Tooltip
        componentTooltip={<label>{users[item.userId].name}</label>}
        className="itemStatus ticket"
        position={"topCenter"}
        addArrow={false}
        transitionType={"zoom"}
        followCursor={false}
      >
        {item.value}
      </Tooltip>
    );
  };

  const renderMessage = (item, differentCompany) => {
    return (
      <label
        className={`item-message ${
          differentCompany ? "different-company" : ""
        }`}
      >
        {" "}
        {item.value}{" "}
      </label>
    );
  };

  const renderComment = (item) => {
    return <label className="item-comment"> {item.value} </label>;
  };

  const renderImg = (item) => {
    const value = decodeJSON(item.value);
    const originalImgURL = `${process.env.REACT_APP_IMAGES_ENDPOINT}/${value.key}`;
    // dimensiones de la imagen a mostrar en el chat
    const imgHeight = getResizeImgHeight(
      value.width ?? 400,
      value.height ?? 400,
      400
    );
    const imgURL = getImageURL(
      process.env.REACT_APP_IMAGES_BUCKET,
      value.key,
      400,
      imgHeight
    );

    const screen = window.screen;
    // dimensiones de la imagen a mostrar en modal full
    const modalImgHeight = getResizeImgHeight(
      value.width ?? screen.width,
      value.height ?? screen.height,
      screen.width
    );
    const modalImgURL = getImageURL(
      process.env.REACT_APP_IMAGES_BUCKET,
      value.key,
      screen.width,
      modalImgHeight
    );

    return (
      <div className="item-img">
        <ImagePreview
          className="container-ImagePreview"
          width={"100%"}
          sizeLoading={35}
          colorLoading={undefined}
          imgURL={imgURL}
          modalImgURL={modalImgURL}
          downloadImgURL={originalImgURL}
          imageName={value.name}
          showButtonDownload={true}
          componentDefault={
            <Tooltip
              componentTooltip={<label>{value.name}</label>}
              className="label-nameImg"
              position={"topCenter"}
              addArrow={false}
              transitionType={"zoom"}
              followCursor={false}
            >
              <CustomIcon
                className="iconDefaultImg"
                type={"white"}
                size={60}
                borderType={"rectangle"}
                icon={IconImage}
              />
            </Tooltip>
          }
          componentDefaultModal={
            <Tooltip
              componentTooltip={<label>{value.name}</label>}
              className="label-nameImgModal"
              position={"topCenter"}
              addArrow={false}
              transitionType={"zoom"}
              followCursor={false}
            >
              <CustomIcon
                className="iconDefaultImgModal"
                type={"white"}
                size={100}
                borderType={"rectangle"}
                icon={IconImage}
              />
            </Tooltip>
          }
        />
      </div>
    );
    /*
    <Tooltip
          componentTooltip={<label>{value.name}</label>}
          className="label-nameFileShort"
          position={"topCenter"}
          addArrow={false}
          transitionType={"zoom"}
          followCursor={false}
        >
          {value.name}
        </Tooltip>
         */
  };

  const renderFile = (item, ownMessage) => {
    const value = decodeJSON(item.value);
    const url = `${process.env.REACT_APP_IMAGES_ENDPOINT}/${value.key}`;

    return (
      <div className="item-file">
        <ButtonDownloadFile
          className="btnDownloadFileItem"
          size={22}
          type={ownMessage ? "black" : "white"}
          transparent
          url={url}
          onDownload={(error) => {
            error &&
              setModalAlert((prev) => ({
                show: true,
                title: error.message,
                message: error.details,
              }));
          }}
        />
        <Tooltip
          componentTooltip={<label>{value.name}</label>}
          className="label-nameFileShort"
          position={"topCenter"}
          addArrow={false}
          transitionType={"zoom"}
          followCursor={false}
        >
          {value.name}
        </Tooltip>
      </div>
    );
  };

  const getUserCreatedItem = (ownMessage, item, lastUserId) => {
    const differentCompany =
      currentUser.companyId !== users[item.userId].companyId;
    return (
      <Slide
        id={"itemScroll-" + item.id}
        direction={ownMessage ? "left" : "right"}
        in={true}
        timeout={500}
        container={containerList.current}
        key={"item-" + item.id}
      >
        <li
          className={
            (ownMessage && item.type !== "status" ? "own-message " : "") +
            (lastUserId === item.userId ? "sameUser" : "")
          }
        >
          {/* img del user */}
          {item.type !== "statusTicket" && lastUserId !== item.userId && (
            <ImageTooltip
              className={"container-imgUser"}
              sizeLoading={25}
              colorLoading={undefined}
              src={getProfilePicture(item.userId, 34, 34)}
              componentDefault={
                <CustomIcon
                  className="icon-imgUser"
                  type={"whiteS2"}
                  size={17}
                  borderType={"circle"}
                  icon={IconUser}
                />
              }
              classNameTooltip={"tooltip-imgUser"}
              componentTooltip={
                <label className="label-nameUser">
                  {users[item.userId].name}
                </label>
              }
            />
          )}

          {item.type === "message"
            ? renderMessage(item, differentCompany)
            : item.type === "comment"
            ? renderComment(item)
            : item.type === "img"
            ? renderImg(item)
            : item.type === "status"
            ? renderStatusItem(item)
            : item.type === "file"
            ? renderFile(item, ownMessage)
            : item.type === "statusTicket" &&
              [ticketCompany, 254].includes(currentUser.companyId)
            ? renderStatusTicket(item)
            : null}

          <label className="label-time">{getTime(item.date)}</label>
        </li>
      </Slide>
    );
  };

  const getItemNeutral = (item) => {
    return (
      <Zoom
        in={true}
        timeout={600}
        container={containerList.current}
        key={"item-" + item.id}
      >
        <li className={"itemNeutral"}>
          {item.type === "statusTicket"
            ? renderStatusTicketNeutral(item)
            : null}
        </li>
      </Zoom>
    );
  };

  const renderItems = () => {
    let jsxItems = [];
    let date = undefined;
    let countDate = 1;
    for (const item of items) {
      // manejo de las etiquetas fechas
      if (!date) {
        date = getFullDate(new Date(item.date));
        jsxItems.push(renderGrayLabel(date, `itemDate-${countDate++}`));
      } else {
        let newDate = getFullDate(new Date(item.date));
        if (newDate !== date) {
          jsxItems.push(renderGrayLabel(newDate, `itemDate-${countDate++}`));
          date = newDate;
        }
      }

      // elementos que van alineados al centro
      switch (item.type) {
        case "statusArticle":
          jsxItems.push(renderStatusArticle(item.value, `item-${item.id}`));
          break;
        case "statusService":
          jsxItems.push(renderStatusService(item.value, `item-${item.id}`));
          break;
      }

      // acomodo del item (left o right)
      let ownMessage = false;
      // CHAT MERCHANT ARTICLE
      if (currentUser.id === item.userId) {
        ownMessage = true;
      }

      // renderizar tipo de item
      jsxItems.push(getUserCreatedItem(ownMessage, item));
    }
    setCustomItems(jsxItems);
  };

  const renderItemsTicket = () => {
    let jsxItems = [];
    let date = undefined;
    let countDate = 1;
    let lastUserId = undefined;

    // recorrido de los items (del mas viejo al mas reciente)
    for (let index = items.length - 1; index >= 0; index--) {
      const item = items[index];
      // manejo de las etiquetas fechas
      if (!date) {
        date = getFullDate(new Date(item.date));
        if (item.type === "statusTicket") {
          [ticketCompany, 254].includes(currentUser.companyId) &&
            jsxItems.push(renderGrayLabel(date, `itemDate-${countDate++}`));
        } else {
          jsxItems.push(renderGrayLabel(date, `itemDate-${countDate++}`));
        }
        lastUserId = undefined;
      } else {
        let newDate = getFullDate(new Date(item.date));
        if (newDate !== date) {
          if (item.type === "statusTicket") {
            [ticketCompany, 254].includes(currentUser.companyId) &&
              jsxItems.push(renderGrayLabel(date, `itemDate-${countDate++}`));
          } else {
            jsxItems.push(renderGrayLabel(date, `itemDate-${countDate++}`));
          }
          date = newDate;
          lastUserId = undefined;
        }
      }

      // elementos que van alineados al centro
      /*
      switch (item.type) {
        case "statusTicket":
          jsxItems.push(getItemNeutral(item));
          lastUserId = undefined;
          continue;
      }
      */

      // acomodo del item (left o right)
      let ownMessage = false;
      if (currentUser.id === item.userId) {
        ownMessage = true;
      }

      // renderizar tipo de item
      jsxItems.push(
        getUserCreatedItem(ownMessage, item, lastUserId, index === 0)
      );
      lastUserId = item.userId;
    }

    setCustomItems(jsxItems);
  };

  return (
    <>
      <Container id="containerItems" ref={containerItems}>
        <div className="btn-loadMore">
          {showBtnLoadMore && (
            <ButtonV2
              className="container-btnLoadMore"
              type={"purple"}
              label={"cargar más"}
              size={10}
              borderType="oval"
              icon={IconReload}
              iconPosition="start"
              isLoading={btnLoadMoreLoading}
              onClick={async (event) => {
                setBtnLoadMoreLoading(true);
                if (onClickBtnLoadMore) {
                  const responseError = await onClickBtnLoadMore();
                  if (responseError) {
                    setModalAlert({
                      show: true,
                      title: responseError.title,
                      message: responseError.message,
                    });
                  }
                }
                setBtnLoadMoreLoading(false);
              }}
            />
          )}
        </div>

        <ul id="ulItems" ref={containerList}>
          {customItems}
        </ul>
      </Container>

      <Modal
        show={modalAlert.show}
        title={modalAlert.title}
        message={modalAlert.message}
        icon={"info"}
        onClickBtnDefault={(event) =>
          setModalAlert((prev) => ({
            ...prev,
            show: false,
          }))
        }
      />
    </>
  );
};
