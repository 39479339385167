import styled from "styled-components";

export const UpgradePlanContainer = styled.div`
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  & > div:last-child > div {
    display: flex;
  }
  .header {
    width: 100%;
    display: flex;
    margin-bottom: 30px;
  }
  .tabPlan {
    width: 25%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fafafa;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #817393;
    cursor: pointer;
  }
  .activeTab {
    cursor: default;
    width: 50%;
    background: var(--white-contentoh);
    border: 1px solid var(--pink-contentoh);
    box-sizing: border-box;
  }
  .changePlans {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    box-sizing: border-box;
    width: 220px;
    height: 60px;
    background: #fafafa;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #817393;
    margin-right: 35px;
    > p:last-child {
      font-size: 14px;
      line-height: 19px;
    }
  }
  .launch {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .border-bottom {
    border-bottom: 1px solid #f0eef2;
  }
  .border-solid {
    border-bottom: 1px solid #817393;
  }
  .features-container {
    min-width: 250px;
    overflow-y: auto;
    &:nth-child(n + 2) {
      min-width: 170px;
      width: 25%;
    }
    & > * {
      height: 40px;
      width: 100%;
    }
    h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      color: #817393;
      padding-bottom: 12px;
      text-align: center;
      margin-top: 18px;

      & + * {
        padding: 18px 0;
      }
    }
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      & > figure {
        padding: 0 20px;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    p {
      width: 160px;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      text-align: right;
      color: #817393;
    }
    figure {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 18px;
      height: 18px;
    }
    .center {
      text-align: center;
    }
  }
  .active-plan {
    background: #fafafa;
  }
  .current {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #d4d1d7;
  }
  .button-container {
    display: none;
    justify-content: center;
    margin: 40px 0 20px;

    button,
    a {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #ededed;
      background: var(--pink-contentoh);
      border-radius: 30px;
      border: none;
      padding: 10px 15px;
      cursor: pointer;
      text-decoration: none;
    }
  }

  .close-button {
    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
    img{
      width:30px;
    }
  }
`;
