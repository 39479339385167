import styled from "styled-components";
import { GlobalColors } from "../../../global-files/variables";
import { FontFamily } from "../../../global-files/variables";

export const Container = styled.h2`
  font-family: ${(props) => props.fontFamily};
  font-style: normal;
  font-size: 36px;
  line-height: 42px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: ${(props) => props.color};
  position: relative;

  &.with-bold-text {
    span {
      font-family: ${FontFamily.Raleway_700};
    }
  }

  &.product-name-header {
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
    letter-spacing: -0.015em;
    color: ${({ color }) => (color ? color : GlobalColors.secondary_magenta)};
  }

  &.retailer-name-header {
    font-size: 18px;
    font-weight: 400;
    line-height: 19px;
    color: ${({ color }) => (color ? color : GlobalColors.s5)};
  }

  &.input-name-header {
    font-size: 14px;
    line-height: 19px;
    height: 19px;
    max-width: 100%;
    font-weight: 400;
    color: ${({ color }) => (color ? color : GlobalColors.s5)};
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.date-header {
    font-family: ${FontFamily.AvenirNext};
    color: ${({ color }) => (color ? color : GlobalColors.s4)};
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
  }

  &.table-row-text {
    font-family: ${FontFamily.AvenirNext};
    color: ${({ color }) => (color ? color : GlobalColors.s4)};
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;

    &:last-child {
      min-width: 50px;
      text-align: center;
    }
  }

  &.gray-table-row {
    font-family: ${FontFamily.AvenirNext};
    font-weight: 400;
    font-size: 11px;
    line-height: 19px;
    color: ${({ color }) => (color ? color : GlobalColors.s5)};
    background-color: ${GlobalColors.s2};
    padding: 1px 3px;
    border-radius: 3px;
    min-width: 50px;
    text-align: center;
  }
  &.header-and-paragraph {
    font-family: ${FontFamily.Raleway};
    font-size: 36px;
    color: ${GlobalColors.s5};
    p {
      font-family: ${FontFamily.AvenirNext};
      font-size: 14px;
      color: ${GlobalColors.s4};
      letter-spacing: -0.015em;
    }
  }
`;
