import styled from "styled-components";
import { FontFamily } from "../../../../global-files/variables";

export const Container = styled.div`
  width: 100%;
  max-width: 700px;
  min-width: 350px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0px;
  background: white;
  padding: 0px;
  overflow: hidden;
  border-radius: inherit;
  //border: 1px solid red;

  .container-chatEmpty {
    width: 100%;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
    background-color: transparent;

    .icon-chat {
      color: #808080;
    }

    label {
      font-family: ${FontFamily.RobotoRegular}, sans-serif;
      font-size: 17px;
      text-align: center;
      color: #808080;
    }
  }
  @media (max-width: 600px) {
    min-width: 300px;
  }
`;
