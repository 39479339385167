import { Container } from "./styles";
import { ScreenHeader } from "../../atoms/ScreenHeader/index";
import { GeneralInput } from "../../atoms/GeneralInput/index";
import { useState, useEffect } from "react";
import acceptIcon from "../../../assets/images/generalButton/acceptIcon.svg";
import rejectIcon from "../../../assets/images/generalButton/rejectIcon.svg";
export const TagAndInput = ({
  inputType,
  label,
  value,
  inputPlaceHolder,
  inputId,
  updatedDescriptions,
  setUpdatedDescriptions,
  updatedDatasheets,
  setUpdatedDatasheets,
  articleId,
  version,
  index,
  isRequired,
  dinamicHeight,
  inputCols,
  inputRows,
  maxChar,
  required,
  optionList,
  description,
  inputOnChange,
  color,
  disabled,
  onKeyDown,
  showTooltip,
  auditClass,
  onChange,
  dataInputs,
  inputGroup,
  boxOnboardingData,
}) => {
  const [boxOnboardingList, setBoxOnboardingList] = useState(
    boxOnboardingData || []
  );

  useEffect(() => {
    const temp = {};
    let maxBoxId = 0;
    inputGroup?.inputs.forEach((attrId) => {
      if (!dataInputs[attrId].box) return;
      Object.entries(dataInputs[attrId].box).forEach(([boxId, value]) => {
        if (boxId > maxBoxId) maxBoxId = boxId;
        if (!temp[boxId]) temp[boxId] = {};
        temp[boxId][attrId] = value;
      });
    });
    const tempList = Object.values(temp);
    if (tempList.length > 0) {
      setBoxOnboardingList(
        tempList.map((value, index) =>
          index === tempList.length - 1 ? { value } : { value }
        )
      );
    }
  }, []);
  useEffect(() => {
    onChange && onChange(boxOnboardingList);
  }, [boxOnboardingList]);

  return (
    <Container
      inputType={inputType}
      className={"input-container"}
      key={`generalTagInput-${inputType}`}
    >
      {label?.length && (
        <div className="title-container">
          <ScreenHeader
            text={label}
            headerType={"input-name-header"}
            color={color}
          />
          {showTooltip && <span className="tooltip">{label}</span>}
        </div>
      )}
      <GeneralInput
        inputId={inputId}
        inputType={inputType}
        inputValue={value}
        index={index}
        disabled={disabled}
        isRequired={isRequired}
        inputPlaceholder={inputPlaceHolder}
        updatedDescriptions={updatedDescriptions}
        setUpdatedDescriptions={setUpdatedDescriptions}
        updatedDatasheets={updatedDatasheets}
        setUpdatedDatasheets={setUpdatedDatasheets}
        articleId={articleId}
        version={version}
        dinamicHeight={dinamicHeight}
        inputCols={inputCols}
        inputRows={inputRows}
        maxChar={maxChar}
        required={required}
        optionList={optionList}
        description={description}
        inputOnChange={inputOnChange}
        onKeyDown={onKeyDown}
        auditClass={auditClass}
        onChange={(e) => {
          setBoxOnboardingList((prev) => {
            return prev.map((box, i) => {
              if (i != index) {
                return box;
              }
              return { ...box, value: e };
            });
          });
        }}
      />
    </Container>
  );
};
