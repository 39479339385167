import styled from "styled-components";

export const Container = styled.div`
  padding: 10px 0px;
  padding-right: 35px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;

  .container-view{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    button {
        box-sizing: border-box;
    }
  }

  .wishlist-button {
    position: relative;
    z-index: 1;
  }
  .container-view {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .wishlist-container {
    position: absolute;
    top: 100%;
    left: 90%;
    transform: translateY(-50%);
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 100;
    display: block;
  }  
  
  .wishlist-container.hover {
    opacity: 1;
    pointer-events: all;
  }  

  .modal-export{
    .btn-exportar {
      border: 1px solid var(--pink-contentoh);
      background: var(--pink-contentoh);
      color: var(--white-contentoh);
      border-radius: 30px;
      padding: 4px 8px;
      cursor: pointer;
      &:hover {
        border: 1px solid var(--pink-contentoh);
        background: var(--white-contentoh);
        color: var(--pink-contentoh);
      }
    }
    .btn-regresar {
      border: 1px solid var(--pink-contentoh);
      background: var(--white-contentoh);
      color: var(--pink-contentoh);
      border-radius: 30px;
      cursor: pointer;
      padding: 4px 8px;
      &:hover {
        border: 1px solid var(--pink-contentoh);
        background: var(--pink-contentoh);
        color: var(--white-contentoh);
      }
    }
    .modal-content{
      max-height: 500px;
    }
  }
`;
