import { Container } from "./styles";
import dropDownArrowButton from "../../../assets/images/dropDownButton/dropDownArrowButton.svg";

export const DropDownButton = ({ active }) => {
  return (
    <Container className={active && "active"}>
      <img src={dropDownArrowButton} alt="V" />
    </Container>
  );
};
