import { Container } from "./styles";
import { ProductImage } from "../../atoms/ProductImage";
import { CheckBox } from "../../atoms/CheckBox";
import { Button } from "../../atoms/GeneralButton";
import { ValidationPanel } from "../../atoms/ValidationPanel";
import { useState, useEffect } from "react";
import Select from "../../atoms/Select";
import rejectIcon from "../../../assets/images/generalButton/rejectIcon.svg";
import acceptIcon from "../../../assets/images/generalButton/acceptIcon.svg";
import { Tooltip } from "../../atoms/Tooltip";

export const GalleryElement = ({
  image,
  gridLayout = false,
  id = "chk-default",
  label,
  number,
  imageInputs,
  imageType,
  imagePackagingType,
  changeImage,
  selectedImages,
  setSelectedImages,
  setCheckAll,
  auditImages,
  compare,
  updateApprovedInputsImages,
}) => {
  const [showValidationPanel, setShowValidationPanel] = useState(false);
  const [showDivStates, setShowDivStates] = useState({});
  const [imageStates, setImageStates] = useState({});

  const handleImgClick = (inputId) => {
    setShowDivStates((prevStates) => ({
      ...prevStates,
      [inputId]: !prevStates[inputId],
    }));
  };
  const changeImageBtn = (inputId, action) => {
    setImageStates((prevStates) => ({
      ...prevStates,
      [inputId]: action === "validate",
    }));
    updateApprovedInputsImages(inputId);
  };

  const closeValidationPanel = (e) => {
    if (
      !e.target.closest("#validation-panel") ||
      (e.target.closest(".validation-option") && showValidationPanel)
    ) {
      document.removeEventListener("click", closeValidationPanel, false);
      setShowValidationPanel(false);
    }
  };

  const checkOnChange = (e) => {
    const chkGlobal = document.getElementById("check-all-images");
    const checkboxList = document.querySelectorAll("[id^='gallery-element-']");
    let temp = selectedImages.slice();
    e.target.checked
      ? temp.push(image)
      : (temp = temp.filter((img) => image.id !== img.id));
    let counter = 0;
    checkboxList?.forEach((chk) => chk.checked && counter++);
    chkGlobal.checked = checkboxList.length === counter;
    setCheckAll(checkboxList.length === counter);
    setSelectedImages(temp);
  };

  useEffect(() => {
    if (showValidationPanel) {
      document.addEventListener("click", closeValidationPanel, false);
    }
  }, [showValidationPanel]);

  const isEquals = (value, auditValue) => {
    return value === auditValue;
  };
  //TOMAR EL image_id
  return (
    <Container
      className={`${gridLayout ? "grid-gallery" : "row-gallery"} ${
        compare && !isEquals(image?.uuid, auditImages?.values[number]?.uuid)
          ? "audit-class"
          : ""
      }`}
    >
      <div className="buttons-container">
        <CheckBox id={id} label={label} onChange={(e) => checkOnChange(e)} />
      </div>
      <div className="image-container">
        <ProductImage
          img={compare ? auditImages?.values[number]?.src : image?.src}
          altText={image?.altText}
          imageType={`catalogue-image-${gridLayout ? "big" : "small"}-size`}
        />
        <div className="information-container">
          <p>{image?.name}</p>
          {!gridLayout && (
            <>
              <p>
                {image.width}x{image.height}
              </p>
              <p>{image.ext}</p>
              <CheckBox
                className="center"
                id={`has-description-${image.id}`}
                defaultChecked={image.has_leyend}
                onChange={(e) => {
                  changeImage({
                    action: "changeImageInfo",
                    attribute: "has_leyend",
                    value: e.target.checked,
                    index: number,
                  });
                }}
              />
              <Select
                width="100%"
                placeholder="Tipo de toma"
                options={imageInputs?.filter(
                  (img) => img.value === image.image_id || !img.active
                )}
                valueSelected={image.image_id}
                onChange={(e) => {
                  changeImage({
                    action: "changeImageInfo",
                    attribute: "image_id",
                    value: +e.target.value,
                    index: number,
                  });
                }}
              />
              <Select
                width="100%"
                placeholder="Tipo de imagen"
                options={imageType}
                valueSelected={image.image_type}
                onChange={(e) => {
                  changeImage({
                    action: "changeImageInfo",
                    attribute: "image_type",
                    value: +e.target.value,
                    index: number,
                  });
                }}
              />

              <Select
                width="100%"
                placeholder="Tipo de empaque"
                options={imagePackagingType}
                valueSelected={image.packing_type}
                onChange={(e) => {
                  changeImage({
                    action: "changeImageInfo",
                    attribute: "packing_type",
                    value: +e.target.value,
                    index: number,
                  });
                }}
              />

              {image?.isApproved === false && (
                <div
                  style={{
                    display: "flex",
                    "justify-content": "center",
                    "align-items": "center",
                  }}
                >
                  <Tooltip
                    componentTooltip={
                      <>
                        <p>
                          <b>Comentarios:</b>
                        </p>
                        <p>{image.comment}</p>
                      </>
                    }
                    children={
                      !image.isApproved && (
                        <img
                          src={
                            imageStates[image.image_id]
                              ? acceptIcon
                              : rejectIcon
                          }
                          alt={"botón rechazar"}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleImgClick(image.image_id)}
                        />
                      )
                    }
                    classNameTooltip={"container-tooltip"}
                  />
                  {showDivStates[image.image_id] && (
                    <div className="btnReviewState">
                      <div>
                        <button
                          id="aceptbtn"
                          onClick={() =>
                            changeImageBtn(image.image_id, "validate")
                          }
                        >
                          <img
                            src={acceptIcon}
                            alt="acept button"
                            style={{ cursor: "pointer" }}
                          />
                          Validar
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Container>
  );
};
