import styled from "styled-components";

export const Container = styled.div`
  .container-def {
    margin-top: 10px;
    box-shadow: 0px 2px 4px #00000040;
    border-radius: 5px;
    background-color: var(--white-contentoh);
    padding-bottom: 10px;
    position: absolute;
    z-index: 10;
    max-height: 500px;
    overflow: auto;
  }

  .custom-select {
    font-family: "Roboto";
    font-size: 12px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 5px 10px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-radius: 5px;
    background-color: var(--white-contentoh);
    border: 1px solid #f0f0f0;
    cursor: pointer;
    // min-width: 180px;
    width: 100%;
    color: #262626;
  }

  .search-bar{
    display: flex;
    align-items: center;
    padding: 0px 5px;
    border-bottom: 1px solid #f0f0f0;
  }

  .search {
    border: none;
    height: 34px;
    padding: 5px 10px;
    color: var(--gray-contentoh);
  }
  .search::placeholder {
    display: inline;
    color: var(--gray-contentoh);
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
  }

  .container-options {
    padding: 5px;
  }

  .default-option {
    cursor: pointer;
    padding: 10px 10px 5px;
    color: #817393;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
  }

  .options {
    height: 24px;
    gap: 10px;
    display: flex;
    align-items: center;
    cursor:pointer;
  }

  .options:hover,
  .options.selected {
    background-color: #f0f0f0;
  }

  .texto {
    display: inline;
    color: #817393;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    cursor:pointer;
  }

  .search-icon{
    width:13px;
    height: 13px;
  }

  .arrow-item{
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    cursor: pointer;
    font-size: 10px;
    margin-left: 10px;
  }
`;
