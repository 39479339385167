import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import GenericModal from "../../general/GenericModal";
import activeFeature from "../../../assets/IconComponents/planIcons/activeFeature.svg";
import excludeFeature from "../../../assets/IconComponents/Exclude.svg";
import face from "../../../assets/IconComponents/face.svg";

export const Plan = ({ features, plan }) => {
  const [redirect, setRedirect] = useState(false);
  const [state, setState] = useState(false);
  const canUpgrade = plan.actualPlanId < plan.id;
  const currentPlan = plan.actualPlanId === plan.id;
  const canPay = plan.id !== 9;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const company = JSON.parse(sessionStorage.getItem("company"));
  const [showGenericModal, setShowGenericModal] = useState(false);

  useEffect(() => {
    const membership = JSON.parse(sessionStorage.getItem("user")).membership;
    const today = new Date().getTime();
    const end_date = new Date(membership.end_date).getTime();
    const remainingMonths = Math.round((end_date - today) / 86400000 / 30);
    const discount = (remainingMonths * plan.actualPrice) / 12;
    const price = plan.price - (discount || 0);
    setState({
      subtotal: price,
      items: [
        {
          discount,
          remainingMonths,
          name: plan.title,
          price,
        },
      ],
      paymentMethod: null,
      productServices: price,
      plan,
      isPlan: true,
    });
  }, [plan]);

  const getFeature = (feature) => {
    switch (feature.id) {
      case 1:
        const num = plan.price;
        const price =
          typeof num === "string"
            ? num
            : `${new Intl.NumberFormat("es-MX", {
                currency: "MXN",
                style: "currency",
                maximumFractionDigits: 0,
              }).format(num)} MXN`;
        return (
          <div className="border-bottom">
            <p className="center">{price}</p>
          </div>
        );
      case 2:
        return (
          <div className="border-bottom">
            <p className="center">{plan.products}</p>
          </div>
        );
      case 3:
        return (
          <div className="border-solid">
            <p className="center">{plan.users}</p>
          </div>
        );
      default:
        if (plan.featuresNotIncluded?.includes(feature.id)) {
          return (
            <figure
              className={`${
                feature.id === 13 ? "border-solid" : "border-bottom"
              }`}
            >
              <img key={feature.id} src={excludeFeature} alt="Active" />
            </figure>
          );
        }
        return (
          <figure
            className={`${
              feature.id === 13 ? "border-solid" : "border-bottom"
            }`}
          >
            <img key={feature.id} src={activeFeature} alt="Active" />
          </figure>
        );
    }
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: "/checkout",
          state,
        }}
      />
    );
  }

  return (
    <>
      <div className={`features-container${currentPlan ? " active-plan" : ""}`}>
        <h2 className="border-solid">{plan.title}</h2>
        {features.map((feature) => getFeature(feature))}
        <div className="button-container">
          {canUpgrade && (
            <button
              onClick={() => {
                if (canPay) {
                  setRedirect(true);
                } else {
                  setShowGenericModal(true);
                }
              }}
            >
              {canPay ? (
                "Crece tu plan"
              ) : (
                <a
                  href={`mailto:ilopez@contentoh.com?subject=Me interesa el Plan Empresarial&body=Buen día.%0D%0A%0D%0ASoy de la compañía ${company.company_name} y estoy interesado en la contratación del plan empresarial. Mi correo de contacto es ${user.email}%0D%0A%0D%0ASaludos.`}
                >
                  Contáctanos
                </a>
              )}
            </button>
          )}
          {currentPlan && <span className="current">Plan actual</span>}
        </div>
      </div>
      {showGenericModal && (
        <GenericModal
          close={() => setShowGenericModal(false)}
          img={face}
          button2={{
            name: "Entendido",
            action: () => setShowGenericModal(false),
          }}
          message="Envía el mensaje de solicitud desde tu aplicación de correo por defecto"
        />
      )}
    </>
  );
};
