import React, { useState, useEffect } from "react";
import { ContainerDashboard } from "./ContainerDashboard/index";
import VerticalSideMenu from "./VerticalSideMenu";
import { Sections } from "../../constants/sections";

const getMenuOptionsForUser = (user, company) => {
  const userRoles = [];
  if (user?.id_role === 0 && user?.is_retailer === 0) userRoles.push("provider");
  if (user?.is_retailer === 1) userRoles.push("retailer");
  if (user?.is_onboarding === 1) userRoles.push("onboarding");
  if (user?.is_onboarding !== 1) userRoles.push("general");
  if (company?.financedRetailers?.some(({ id }) => [68].includes(id))) {
    userRoles.push("financed_retailer");
  }

  return Sections.map((section) => {
    const sectionRoles = section.roles || [];

    if (section.suboptions) {
      const visibleSuboptions = section.suboptions.filter((suboption) => {
        const suboptionRoles = suboption.roles || [];
        const hasRole = suboptionRoles.some((role) => userRoles.includes(role)) || suboptionRoles.includes("all");
        return hasRole;
      });
      section.suboptions = visibleSuboptions;
      if (visibleSuboptions.length === 0) {
        return null;
      }
    }
    const sectionIsValid = sectionRoles.some((role) => userRoles.includes(role)) || sectionRoles.includes("all");
    return sectionIsValid ? section : null;
  }).filter(Boolean);
};



export default function Main() {
  const [user] = useState(JSON.parse(sessionStorage.getItem("user")));
  const [company] = useState(JSON.parse(sessionStorage.getItem("company")));
  const [display, setDisplay] = useState(false);
  const [menuOptions, setMenuOptions] = useState([]);
  const [title, setTitle] = useState({
    title: sessionStorage.getItem("title"),
    info: sessionStorage.getItem("description"),
  });

  useEffect(() => {
    setTitle({
      title: sessionStorage.getItem("title"),
      info: sessionStorage.getItem("description"),
    });
  }, []);

  useEffect(() => {
    const options = getMenuOptionsForUser(user, company);
    setMenuOptions(options);
  }, [user, company]);

  useEffect(() => {
    const openMenuUser = (e) => {
      if (!e.target.closest("#menu-user") && display && window.location.pathname !== "/DashboardContentoh") {
        document.removeEventListener("click", openMenuUser, false);
        setDisplay(false);
      }
    };

    if (display) {
      document.addEventListener("click", openMenuUser, false);
    }

    return () => document.removeEventListener("click", openMenuUser);
  }, [display]);

  return (
    <VerticalSideMenu
      menuoption={menuOptions}
      containerdash={<ContainerDashboard />}
    />
  );
}
