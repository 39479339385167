import React, { useEffect, useState } from "react";
import { MainContainer } from "./styles";
import CustomTable from "../CustomTable";
import Card from "./Card";
import { useGetPercentage } from "../../../hooks/useGetPercentage.jsx";
import { extractPercentageData } from "./utils.jsx";
import Pagination from "../Pagination/index.jsx";
import Modal from "../Modal/index.jsx";
import CustomNotification from "../CustomNotification/index.jsx";
import useNotifications from "../../../hooks/useNotification.jsx";
import { useModal } from "../../../hooks/useModal.jsx";
import CustomInput from "../CustomInput/index.jsx";
import useAcquiredProducts from "../../../hooks/useAcquiredProducts.jsx";

const GridView = ({
  isGridView,
  data,
  headers,
  pulsating,
  visibility,
  cart,
  favorite,
  headerMapping,
  percentageParams,
  typePercentage,
  rowsPerPage,
  cardsPerPage,
  fetchProducts,
  onVisibilityChange,
  selectedArticles,
  setSelectedArticles,
  wishlistFilter,
  setWishlistFilter,
  dataDetailTable,
  productInfo,
  showEditButtonTable
}) => {
  const { tableData, gridData } = data;
  const [loading, setLoading] = useState(false);
  const [percentagesByArticle, setPercentagesByArticle] = useState({});
  const { postAcquiredProducts, getAcquiredProducts } = useAcquiredProducts();
  const [currentPage, setCurrentPage] = useState(1);
  const { modalData, openModal, closeModal, handleCancelClick } = useModal();
  const { notifications, addNotification, deleteNotification } = useNotifications();
  const { getPercentage, loading: percentageLoading, data: percentages, error } = useGetPercentage();
  const totalPages = isGridView
    ? Math.ceil(gridData.length / cardsPerPage)
    : Math.ceil(tableData.length / rowsPerPage);

  const currentData = isGridView
    ? gridData.slice((currentPage - 1) * cardsPerPage, currentPage * cardsPerPage)
    : tableData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);
  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const fetchedPercentages = await getPercentage({
          data: percentageParams,
        });
        if (fetchedPercentages) {
          setPercentagesByArticle(fetchedPercentages);
        }
      } catch (error) {
        console.error("Error al obtener porcentajes:", error);
      } finally {
        setLoading(false);
      }
    };
  
    if (percentageParams && percentageParams.length > 0) {
      fetchProducts();
    }
  }, [percentageParams]);
  

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleAddToCart = (itemData) => {
    openModal({
      className: "modal-cart",
      title: "¿Deseas adquirir estos productos?",
      buttons: [
        {
          label: "Cancelar",
          onClick: closeModal,
          className: "btn-cancelar",
        },
        {
          label: "Adquirir",
          onClick: async () => {
            try {
              await postAcquiredProducts({ data: itemData });
              addNotification("success","Producto adquirido con éxito.");
              const acquiredProductsResponse = await getAcquiredProducts();
            console.log("Acquired Products:", acquiredProductsResponse);
              closeModal();
            } catch (error) {
              addNotification("error","Error al adquirir el producto.");
              console.error("Error al adquirir el producto:", error);
            }
          },
          className: "btn-adquirir",
        },
      ],
    });
  };

  return (
    <MainContainer key="GridView">
      <div className={`grid-view ${isGridView ? "grid" : "table"}`}>
        {isGridView ? (
          currentData.map((item, index) => {
            const percentage = extractPercentageData(
              percentagesByArticle,
              item.id_article,
              typePercentage
            );
            return (
              <Card
                id={Number(item.id_article)}
                key={Number(item.id_article)}
                data={item}
                pulsating={pulsating}
                typePercentage={typePercentage}
                percentageRequired={
                  typePercentage === "percentageRequired" ? percentage : null
                }
                percentageGeneral={
                  typePercentage === "percentageGeneral" ? percentage : null
                }
                visibility={visibility}
                cart={cart}
                favorite={favorite}
                percentageLoading={loading}
                onVisibilityChange={() => onVisibilityChange(item.id_article, item.isVisible)}
                selectedArticles={selectedArticles}
                setSelectedArticles={setSelectedArticles}
                wishlistFilter={wishlistFilter}
                setWishlistFilter={setWishlistFilter}
                handleAddToCart={handleAddToCart}
                addNotification={addNotification}
                productInfo={productInfo}
              />
            );
          })
        ) : (
          <CustomTable
            data={currentData.map((item) => ({
              ...item,
              percentage: extractPercentageData(
                percentagesByArticle,
                item.id_article,
                typePercentage
              ),
            }))}
            headers={headers}
            headerMapping={headerMapping}
            headerVariant="gray"
            typePercentage={typePercentage}
            rowsPerPage={rowsPerPage}
            selectedArticles={selectedArticles}
            setSelectedArticles={setSelectedArticles}
            enableRowClick
            dataDetailTable={dataDetailTable}
            productInfo={productInfo}
            showEditButton={showEditButtonTable}
          />
        )}
      </div>
      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange}/>
      <Modal {...modalData} onClose={handleCancelClick} />
      <CustomNotification
        open={notifications.length > 0}
        alerts={notifications}
        deleteNotification={deleteNotification}
      />
    </MainContainer>
  );
};

export default GridView;