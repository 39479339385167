import React, { useState, useEffect } from "react";
import { CardContainer } from "./styles";
import CustomButton from "../../CustomButton";
import PulsatingCircle from "../../PulsatingCircle";
import { useHistory } from "react-router-dom";
import CheckBox from "../../customInputs/CheckBox";
import { getButtonVariant, getColorPercentage, redirectEdit } from "./utils.jsx";
import useImageProduct from "../../../../hooks/useImageProduct.jsx";
import WishList from "../../WishList/index.jsx";
import PropTypes from "prop-types";

const Card = ({
  id,
  key,
  data,
  pulsating,
  cart,
  visibility,
  favorite,
  percentageRequired,
  percentageServices,
  percentageGeneral,
  typePercentage,
  percentageLoading,
  onVisibilityChange,
  selectedArticles,
  setSelectedArticles,
  wishlistFilter,
  setWishlistFilter,
  handleAddToCart,
  addNotification,
  productInfo
}) => {
  const imageUrl = useImageProduct(data.src, 1000, 1000);
  const [openList, setOpenList] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const history = useHistory();

  let displayPercentage;
  switch (typePercentage) {
    case "percentageRequired":
      displayPercentage = percentageRequired;
      break;
    case "percentageServices":
      displayPercentage = percentageServices;
      break;
    case "percentageGeneral":
      displayPercentage = percentageGeneral;
      break;
    default:
      displayPercentage = null;
  }

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setSelectedArticles((prev) => [
        ...prev,
        { id_article: data.id_article, isVisible: data.isVisible, version: data.version },
      ]);
    } else {
      setSelectedArticles((prev) =>
        prev.filter((article) => article.id_article !== data.id_article)
      );
    }
  };

  useEffect(() => {
    if (selectedArticles.length === 0) {
      const checkbox = document.getElementById(`chk-${data.id_article}`);
      if (checkbox) checkbox.checked = false;
    }
  }, [selectedArticles, data.id_article]);

  const handleCart = () => {
    const itemData = {
      articles: [
        {
          articleId: data.id_article,
          version: data.version,
        },
      ],
      arrayRetailers: data.arrayRetailers || [],
      stage: data.stage,
    };

    handleAddToCart(itemData);
  };

  const handleCardClick = () => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const currentPath = history.location.pathname;
    if (user && user.is_retailer === 1) {
      return;
    }
    if (Array.isArray(productInfo) && productInfo.length > 0) {
      const filteredProductInfo = productInfo.find(
        (attribute) => attribute.article.id_article === data.id_article
      );
  
      sessionStorage.setItem("productSelected", JSON.stringify(filteredProductInfo));
      sessionStorage.setItem("productEdit", JSON.stringify(filteredProductInfo));
    }
    if (currentPath === '/merchants' || currentPath === '/tasks' ) {
      redirectEdit(data, setRedirect, addNotification);
    }
  };
  

  useEffect(() => {
    if (redirect) {
      history.push(redirect);
      setRedirect(null);
    }
  }, [redirect, history]);

  return (
    <CardContainer key={`${key}-card`} id={`card-${id}`}>
      <div className="header-card">
        <img onClick={handleCardClick} src={imageUrl} alt="image-card" />
        <div className="header-circle">
          <CheckBox
            id={`chk-${data.id_article}`}
            type="checkbox"
            typeColor="purple"
            onChange={(e)=>handleCheckboxChange(e)}
          />
          {/* {pulsating && <PulsatingCircle />} */}
          {/* {pulsating && <CustomButton
              variant="gray"
              icon="mark_unread_chat_alt"
              iconOnly
            />} */}
        </div>
      </div>
      <div className="body-card">
        {data.name && <h3 className="title-card">{data.name}</h3>}
        {data.category && <p className="category">{data.category}</p>}
        {data.upc && <p className="description">{data.upc}</p>}
        {data.department && <p className="category">{data.department}</p>}
        {data.price && <p className="description">{data.price}</p>}
        {data.providerName && <p className="description">{data.providerName}</p>}
      </div>
      <div className={`footer-card ${data.phaseName && data.groupName ? "flex-phase" : ""}`}>
        {percentageLoading ? (
          <div className="loader">
            <CustomButton
              variant="gray"
              icon="hourglass_empty"
              iconVariant="filled"
              onClick={(event) => {
                event.stopPropagation();
              }}
              iconOnly
            />
          </div>
        ) : (
          displayPercentage !== null && (
            <CustomButton
              label={`${displayPercentage ? displayPercentage : 0}%`}
              onClick={(event) => {
                event.stopPropagation();
              }}
              variant={getColorPercentage(displayPercentage)}
              icon="local_police"
              iconVariant="filled"
              shape="rectangle"
            />
          )
        )}
        {data.phaseName &&
          <CustomButton
            className="phase-button"
              label={data.phaseName}
              onClick={(event) => {
                event.stopPropagation();
              }}
              variant="blue-light"
              shape="rectangle"
            />
        }
        {data.reviewState &&
          <CustomButton
            className="state-button"
              label={data.reviewState}
              onClick={(event) => {
                event.stopPropagation();
              }}
              variant={getButtonVariant(data.reviewState)}
              shape="rectangle"
            />
        }
        {data.groupName && (
            <CustomButton
            className="group-button"
              label={data.groupName || "Sin grupo"}
              onClick={(event) => {
                event.stopPropagation();
              }}
              variant="blue-light"
              shape="rectangle"
            />
        )}
        <div className="buttons-visibility">
          {data.stage === null && cart && (
            <CustomButton
              className="cart-button"
              onClick={(event) => {
                event.stopPropagation();
                handleCart();
              }}
              variant="gray"
              icon="add_shopping_cart"
              iconOnly
              showTooltip={true}
              tooltipText="Adquirir producto"
            />
          )}
          {visibility && (
            <CustomButton
              className="visibility-button"
              onClick={(event) => {
                event.stopPropagation();
                onVisibilityChange(data.id_article, data.isVisible);
              }}
              variant={data.isVisible ? "gray" : "pink"}
              icon={data.isVisible ? "visibility" : "visibility_off"}
              iconOnly
              showTooltip={true}
              tooltipText="Visibilidad"
            />
          )}
          {favorite && (
            <CustomButton
              onClick={(event) => {
                event.stopPropagation();
                setOpenList((prevState) => !prevState);
              }}
              className="wishlist-button"
              variant="gray"
              icon="favorite"
              iconOnly
              showTooltip={true}
              tooltipText="Agregar a Wishlist"
            />
          )}
          {openList && (
            <div className={`wishlist-container ${openList ? "hover" : ""}`}>
              <WishList
                openList={openList}
                wishlistFilter={wishlistFilter}
                setWishlistFilter={setWishlistFilter}
              />
            </div>
          )}
        </div>
      </div>
    </CardContainer>
  );
};

Card.propTypes = {
  id: PropTypes.number,
  key: PropTypes.number,
  data: PropTypes.shape({
    src: PropTypes.string,
    name: PropTypes.string,
    category: PropTypes.string,
    upc: PropTypes.string,
    department: PropTypes.string,
    price: PropTypes.string,
    id_article: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isVisible: PropTypes.number,
    stage: PropTypes.string,
    version: PropTypes.number,
    arrayRetailers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      })
    ),
  }),
  pulsating: PropTypes.bool,
  visibility: PropTypes.bool,
  favorite: PropTypes.bool,
  percentageRequired: PropTypes.number,
  percentageServices: PropTypes.number,
  percentageGeneral: PropTypes.number,
  typePercentage: PropTypes.string,
  percentageLoading: PropTypes.bool,
  onVisibilityChange: PropTypes.func.isRequired,
  selectedArticles: PropTypes.arrayOf(
    PropTypes.shape({
      id_article: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      isVisible: PropTypes.number,
    })
  ).isRequired,
  setSelectedArticles: PropTypes.func.isRequired,
  wishlistFilter: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  setWishlistFilter: PropTypes.func,
  handleAddToCart: PropTypes.func,
};

export default Card;
