import { useState } from 'react';

export const useModal = () => {
  const [modalData, setModalData] = useState({
    show: false,
    title: "",
    text: "",
    customComponent: null,
    className: "",
    titleClassName: "",
    footerClassName: "",
    buttonStyles: [],
    showAccept: false,
    showCancel: false,
    onAcceptClick: () => {},
    onCancelClick: () => {},
  });

  const openModal = (data) => {
    setModalData({
      ...modalData,
      show: true,
      ...data,
    });
  };

  const closeModal = () => {
    setModalData((prev) => ({
      ...prev,
      show: false,
    }));
  };

  const handleCancelClick = () => {
    closeModal();
    modalData.onCancelClick();
  };

  return {
    modalData,
    openModal,
    closeModal,
    handleCancelClick,
  };
};
