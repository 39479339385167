import React, { useState } from "react";
import { Container } from "./styles.jsx";
import Slider from "@mui/material/Slider";

const CustomPrice = ({ id, name, minPrice = 0, maxPrice = 100, onChange }) => {
  const [range, setRange] = useState([minPrice, maxPrice]);
  const [containerVisible, setContainerVisible] = useState(false);

  const handleChange = (event, newValue) => {
    setRange(newValue);
    onChange && onChange(newValue);
  };

  return (
    <Container id={id}>
      <button
        className="custom-select"
        onClick={() => setContainerVisible(!containerVisible)}
      >
        {name}
        <div className="arrow-item">&#9664;</div>
      </button>
      {containerVisible && (
        <div className="price-range">
          <div className="range-label-container">
            <Slider
              value={range}
              onChange={handleChange}
              valueLabelDisplay="auto"
              min={minPrice}
              max={maxPrice}
              getAriaLabel={() => 'Price range'}
              sx={{
                color: 'var(--purple)',
                height: 8,
                '& .MuiSlider-thumb': {
                  height: 16,
                  width: 16,
                  backgroundColor: 'var(--purple)',
                  border: '2px solid var(--purple)',
                  '&:hover': {
                    boxShadow: 'inherit',
                  },
                },
                '& .MuiSlider-track': {
                  border: 'none',
                },
                '& .MuiSlider-rail': {
                  height: 8,
                  borderRadius: 4,
                },
              }}
              getAriaValueText={(value) => `${value}`}
            />
            <div className="range-labels">
              <span>${range[0]}</span>
              <span>${range[1]}</span>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default CustomPrice;
