import React, { useState, useEffect } from "react";
import CheckBox from "../customInputs/CheckBox";
import { TableContainer } from "./styles";
import PropTypes from "prop-types";
import CustomButton from "../CustomButton";
import ProductDetailPanel from "../ProductDetailPanel";

const CustomTable = ({
  data,
  headers,
  headerVariant = "gray",
  headerMapping,
  rowsPerPage,
  showDeleteButton = false,
  onDelete,
  selectedArticles,
  setSelectedArticles,
  enableRowClick = false,
  dataDetailTable,
  addNotification,
  productInfo,
  showEditButton,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const currentData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handleCheckboxChange = (event, item) => {
    if (event.target.checked) {
      setSelectedArticles((prev) => [
        ...prev,
        { id_article: item.id_article, isVisible: data.isVisible, version: item.version },
      ]);
    } else {
      setSelectedArticles((prev) =>
        prev.filter((article) => article.id_article !== item.id_article)
      );
    }
  };

  useEffect(() => {
    if (selectedArticles.length === 0) {
      const checkbox = document.getElementById(`chk-${data.id_article}`);
      if (checkbox) checkbox.checked = false;
    }
  }, [selectedArticles, data.id_article]);

  const handleRowClick = (item) => {
    if (enableRowClick) {
      setSelectedProduct(item);
      if (Array.isArray(productInfo) && productInfo.length > 0) {
        const filteredProductInfo = productInfo.find(
          (attribute) => attribute.article.id_article === item.id_article
        );
        // if (filteredProductInfo) {
          sessionStorage.setItem("productSelected", JSON.stringify(filteredProductInfo));
          sessionStorage.setItem("productEdit", JSON.stringify(filteredProductInfo));
        // }
      }
     
      setIsSidebarOpen(true);
    }
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
    setSelectedProduct(null);
  };

  const selectedProductData = selectedProduct?.id_article
  ? dataDetailTable.find(
      (product) => product.id_article === selectedProduct.id_article
    )
  : null;

const selectedCurrentData = selectedProduct?.id_article
  ? currentData.find(
      (product) => product.id_article === selectedProduct.id_article
    )
  : null;

  return (
    <>
      <TableContainer>
        <div className={`row header ${headerVariant}`}>
          {headers.map((header, index) => (
            <p
              className={`col ${index !== 0 ? "text_center" : ""}`}
              key={index}
            >
              {index === 0 && (
                <span className="row gap-10">
                  <CheckBox
                    id="globalHeaderCheckbox"
                    type="checkbox"
                    typeColor="green"
                    onClick={(event) => event.stopPropagation()}
                  />
                  {header}
                </span>
              )}
              {index !== 0 && header}
            </p>
          ))}
          {showDeleteButton && <div className="col text_center"></div>}
        </div>
        {currentData && currentData.length > 0 ? (
          currentData.map((item, index) => (
            <div className="row" key={index} onClick={(event) => {
              if(event.target.type === "checkbox" || event.target.tagName === "LABEL") return;
              handleRowClick(item)
              event.stopPropagation();
              }}>
              {headers.map((header, headerIndex) => (
                <p
                  className={`col ${
                    headerIndex === 0 ? "row gap-10" : "text_center"
                  }`}
                  key={headerIndex}
                >
                  {headerIndex === 0 && (
                    <CheckBox
                      id={`chk-${item.id_article}`}
                      type="checkbox"
                      typeColor="purple"
                      onClick={(event) => event.stopPropagation()}
                      onChange={(event) => {
                        event.stopPropagation();
                        handleCheckboxChange(event, item);
                      }}
                    />
                  )}
                  {headerMapping[header] === "percentage"
                    ? `${item.percentage ? item.percentage : 0}%`
                    : item[headerMapping[header]] || "No disponible"}
                </p>
              ))}
              {showDeleteButton && (
                <div className="col text_center">
                  <CustomButton
                    variant="gray"
                    icon="delete"
                    iconOnly
                    onClick={(event) => {
                      event.stopPropagation();
                      onDelete(item);
                    }}
                  />
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="no-users">No hay datos disponibles</div>
        )}
      </TableContainer>
      <ProductDetailPanel
        isOpen={isSidebarOpen}
        onClose={handleCloseSidebar}
        selectedProductData={selectedProductData}
        selectedCurrentData={selectedCurrentData}
        showEdit={showEditButton}
      />
    </>
  );
};

CustomTable.propTypes = {
  data: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  headerVariant: PropTypes.string,
  headerMapping: PropTypes.object.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  showDeleteButton: PropTypes.bool,
  onDelete: PropTypes.func,
  selectedArticles: PropTypes.arrayOf(
    PropTypes.shape({
      id_article: PropTypes.string.isRequired,
      isVisible: PropTypes.bool,
    })
  ).isRequired,
  setSelectedArticles: PropTypes.func.isRequired,
  enableRowClick: PropTypes.bool,
  dataDetailTable: PropTypes.array.isRequired,
  showEditButton: PropTypes.bool
};

export default CustomTable;
