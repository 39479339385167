import { NavLink } from "react-router-dom";

const NavbarItem = (props) => {
  return (
    <li key={props.uKey}>
      <NavLink
        exact
        activeClassName="active"
        to={props.path ? props.path : ""}
        onClick={async (event) => {
          if (props.path === "/logout") {
            props.setShow(true);
          } else {
            props.history.push({ pathname: props.path });
            if (props.onMenuClick && props.component) {
              props.onMenuClick(props.component);
            }
          }
        }}
      >
        <div id="leftContainer">
          <figure>
            <img src={props.img.src} alt={props.img.alt} />
          </figure>
          {props.showMenuText && <p>{props.title}</p>}
        </div>
        {props.subMenu && props.showMenuText &&
          props.subMenu.map((subItem, index) => (
            <div className="sub-menu" key={index} onClick={() => props.setTabActive(subItem.title)}>
              {subItem.icon && (
                <figure>
                  <img src={subItem.icon.src} alt={subItem.icon.alt} />
                </figure>
              )}
              <p>{subItem.title}</p>
            </div>
          ))}
      </NavLink>
    </li>
  );
};

export default NavbarItem;
