import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ImageViewer } from "./styles";
import { getImageProduct } from "./utils.jsx";
import Loading from "../loading/index.jsx";
import defaultProductImage from "../../../assets/defaultImages/defaultUpdate.png";

const ProductImageViewer = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(images[0] || {});
  const [mainImageUrl, setMainImageUrl] = useState("");
  const [thumbnailUrls, setThumbnailUrls] = useState(Array(images.length).fill(""));
  const [loadingMain, setLoadingMain] = useState(true);
  const [loadingThumbnails, setLoadingThumbnails] = useState(true);
  const timeoutDuration = 3000;

  useEffect(() => {
    const fetchMainImage = async () => {
      if (!selectedImage.src) return;
      setLoadingMain(true);
      const timer = setTimeout(() => {
        setMainImageUrl(defaultProductImage);
        setLoadingMain(false);
      }, timeoutDuration);

      try {
        const url = await getImageProduct(selectedImage.src, 2000, 2000);
        clearTimeout(timer);
        setMainImageUrl(url || defaultProductImage);
      } catch {
        setMainImageUrl(defaultProductImage);
      } finally {
        setLoadingMain(false);
      }
    };

    fetchMainImage();
  }, [selectedImage]);

  useEffect(() => {
    const updateThumbnails = async () => {
      setLoadingThumbnails(true);

      const timer = setTimeout(() => {
        setThumbnailUrls(Array(images.length).fill(defaultProductImage));
        setLoadingThumbnails(false);
      }, timeoutDuration);

      try {
        const urls = await Promise.all(
          images.map((image) => image.src ? getImageProduct(image.src, 200, 200) : Promise.resolve(defaultProductImage))
        );
        clearTimeout(timer);
        setThumbnailUrls(urls);
      } catch {
        setThumbnailUrls(Array(images.length).fill(defaultProductImage));
      } finally {
        setLoadingThumbnails(false);
      }
    };

    updateThumbnails();
  }, [images]);

  const handleThumbnailClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <ImageViewer>
      <div className="main-image-container">
        {loadingMain ? (
          <Loading />
        ) : (
          <img
            src={mainImageUrl}
            alt="Product Main"
            className="main-image"
            width={200}
            height={200}
          />
        )}
      </div>
      <div className="thumbnails-container">
        {loadingThumbnails ? (
          <Loading />
        ) : (
          thumbnailUrls.map((thumbnailUrl, index) => (
            <img
              key={index}
              src={thumbnailUrl}
              alt={`Thumbnail ${index}`}
              className={`thumbnail ${selectedImage.src === images[index].src ? "active" : ""}`}
              onClick={() => handleThumbnailClick(images[index])} 
              width={50}
              height={50}
            />
          ))
        )}
      </div>
    </ImageViewer>
  );
};

ProductImageViewer.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ProductImageViewer;