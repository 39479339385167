export const en = {
    login: {
        title: "Login"
    },
    headers: {
        upgradePlan: { title: "My Account" },
        acquired: { title: "Acquired" },
        products: {
            title: "Products",
            info: "Products list"
        },
        merchants: {
            title: "Onboarding Products",
            info: "Products List"
        },
        productsMultipleEdition: {
            title: "Products",
            info: "Product editing"
        },
        editProducts: {
            title: "Products",
            info: "Products list"
        },
        matchTask: {
            title: "Tasks",
            info: "List of assigned products"
        },
        contentoh: { info: "Products" },
        orders: {
            info: "Work orders"
        },
        ordersBilling: {
            info: "Billing"
        },
        profile: {
            title: "My Account",
            info: "User info"
        },
        addProducts: {
            title: "Products",
            info: "Providers"
        },
        productAdd: {
            title: "Products",
            info: ""
        },
        providers: {
            title: "Products",
            info: "Products requested from supplier"
        },
        quotes: {
            title: "",
            info: "Appointments for product delivery"
        },
        checkout: {
            info: "Provider"
        },
        transferPay: {
            info: "Provider"
        },
        cardPay: {
            info: "Provider"
        },
        retailers: {
            title: "Retailers",
        },
        dashboardContentoh: {
            info: "Provider"
        },
        editionMultiple: {
            title: "Products",
            info: "Products List > Multiple edition"
        },
        tasks: {
            title: "Tasks",
            info: "Tasks board"
        },
        contentohProducts: {
            info: "Products"
        },
        merchantLists: {
            title: "Lists",
            info: "List of merchant products"
        }
    },
    checkout: {
        requestedService: {
            title: "Requested Services",
            cta: "Go to checkout",
            table: {
                name: "Name",
                chains: "Retailers",
                service: "Services",
                price: "Price",
            },
            summary: {
                products: "PRODUCTS",
                retailer: "RETAILERS",
                dataSheet: "DATA SHEETS",
                description: "DESCRIPTIONS",
                images: "IMAGES",
            }
        },
        footerInfo: {
            products: "PRODUCTS",
            chains: "RETAILERS",
            tecnicalSheet: "DATA SHEETS",
            descriptions: "DESCRIPTIONS",
            images: "IMAGES",
            iva: "TAX",
            loader: "This action may take some time",
            payButton: {
                anyProduct: "Empty Cart",
                pay: "Pay",
                continuar: "Continue",
            },
        },
        buttonStepData: {
            confirm: "Confirm retailers and services",
            update: "Update complete",
        },
        steps: {
            total: {
                products: "Products",
                price: "Price",
                retailerChain: "retailers",
            },
            second: {
                title: "Delivery Type",
                office: {
                    title: "At office",
                    desc: "You deliver the products to us at our facilities."
                },
                pickUp: {
                    title: "Pick Up",
                    desc: "We pick up the products from your facilities."
                },
                inSitu: {
                    title: "In situ",
                    desc: "We work on the products at your facilities. Only for CDMX."
                },
                shedule: {
                    title: "Schedule your visit",
                    paragraph: "Choose the day you will bring your products to our facilities."
                },
                inOffice: {
                    subtitle: "Our office address",
                    mail: "Send by postmail",
                    contact: "Contact"
                },
                defaultForm: {
                    address: "Write your address or select it on the map",
                    street: "Street and neighborhood",
                    state: "State",
                    zipCode: "Zip Code",
                    collectedName: "Name",
                    telephone: "Telephone",
                    contact: "Collection contact",
                },
                financed: {
                    sede: "Choose a location",
                    input: "Input type",
                    request: "Requested by",
                    type: "Type",
                }
            },
            third: {
                datasheet: "Data Sheet",
                description: "Description",
                images: "Images",
                translate: "Data sheet translation",
                build: "Manual construction",
                search: "Search",
                dataModal: {
                    paymentNull: "You didn't select any payment method",
                    card: "You didn't select any card",
                    error: "Something went wrong with your payment...",
                    contract: "Some contract details are missing",
                },
                contract: {
                    title: "Payment for assigned contract",
                    contractNumber: "Contract Number",
                    company: "Company",
                },
                transfer: {
                    office: "Payment at branch",
                    transfer: "Payment by transfer",
                    bank: "Bank Name",
                    reference: "Reference",
                },
                table: {
                    name: "Name",
                    chain: "Retailer",
                    service: "Service",
                    price: "Price",
                }
            }
        },
        dataModal: {
            button: "Accept",
            success: {
                title: "Order created successfully",
                message: "Your order is: "
            },
            error: {
                title: "Ups! Something went wrong",
                message: "Contact IT team for support"
            }
        },
        deleteModal: {
            text: "Are you sure you want to delete the selected products or retailers?",
            buttons: {
                cancel: "Cancel",
                delete: "Delete"
            }
        },
        buttonSteps: {
            step1: "Confirm retailers and services",
            step2: "Chose delivery",
            step3: "Payment method",
        },
        servicePanel: {
            dataService: {
                dataset: {
                    title: "Data Sheet",
                    detail: "The technical data sheet is the most important information about the product, relevant for complying with many regulations and mandatory in most retailers."
                },
                article: {
                    title: "Description",
                    detail: "We enchant your customer with the product description, we explain what it is like, how it works and with the photos, you no longer need to imagine the product."
                },
                image: {
                    title: "Images",
                    detail: "If there is anything more important than the technical data sheet, it is the images. E-commerce cannot exist without images of your products."
                }
            },
            button: {
                add: "Add",
                remove: "Quit",
                action: "This action will be applied to all products"
            }
        },
        dropdownButton: {
            modalUpdate: {
                title: "Updating...",
                message: "This action may take a few seconds...",
            },
            modalUpdateSuccess: {
                title: "Successfully updated",
            },
            modalUpdateError: {
                title: "Something went wrong",
                message: "You can report this to the IT team",
            },
            buttonLabels: {
                accept: "Accept",
                cancel: "Cancel",
            },
            modalTitles: {
                retailer: "Add retailers",
                service: "Add services",
            },
            menuOptions: {
                updateServices: "Update services",
                updateRetailers: "Update retailers",
            },
            retailerComponentModal: {
                paragraph: "They will be added to your selected products.",
                selectLabel: "Add retailer",
                placeholder: "Search retailer",
            },
            productItem: {
                title: "Discounts per service",
                chains: "Retailers",
                one: {
                    discount: "Access discounts for selecting types of services.",
                    discount10: "10% off when selecting 2 services.",
                    discount20: "20% off when selecting 3 services."
                },
                two: {
                    discount10: "You have a 10% discount for selecting 2 types of services.",
                    discount20: "Add one more service to get a 20% discount."
                },
                three: {
                    discount20: "You have a 20% discount for selecting 3 types of services."
                }
            },
            retailerItem: {
                serviceDetail: {
                    dataset: {
                        name: "Data Sheet",
                        short: "d.s."
                    },
                    article: {
                        name: "Description",
                        short: "desc."
                    },
                    image: {
                        name: "Images",
                        short: "imag."
                    }
                }
            }
        }
    },
    sidebar: {
        dashboard: "Dashboard",
        products: {
            title: "Products",
            general: "General",
            addProduct: "Add Product"
        },
        contentoh: {
            title: "Content-oh!",
            products: "Products",
            orders: "Orders",
            quotes: "Quotes"
        },
        onboarding: {
            title: "Onboarding",
            merchants: "Products",
            lists: "Lists",
            acquired: "Acquired"
        },
        tasks: "Tasks"
    },
    general: {
        title: "Products list",
        subtitle: "View all products in general, click on one to see details or select several and work in multi-edition.",
        tableSection: {
            name: "NAME",
            category: "CATEGORY",
            retailers: "RETAILERS",
            percentage: "PERCENTAGE",
            version: "VERSION",
            val: "VAL",
            date: "DATE",
        },
        productDetail: {
            genericModal: {
                message: "Your current plan does not allow you to perform this action",
                detail: "Do you want to upgrade?",
                buttons: {
                    cancel: "Cancel",
                    upgrade: "Upgrade"
                },
            },
            container: {
                progress: "Progress",
                status: "Status",
                version: "Version",
                category: "Category",
                retailers: "Retailers",
                images: "Images",
            },
            progress: {
                title: "Progress Percentage",
                image: "Assigned",
                valid: "Validated",
            },
            description: {
                name: "Name",
                size: "Size",
            },
            dataSheet: "Basic Product Information",
            comments: "Comments",
            content: {
                title: "Remaining Content",
                description: "For this product, you need to complete the following assets. You can add them to your cart and we will take care of delivering your products correctly.",
                content: "Content",
            },
            summary: {
                products: "Products",
                dataSheet: "Data Sheet",
                description: "Description",
                images: "Images",
            },
            inCart: {
                duplicated: "Item already in cart!",
                request: "Request to Content-oh!",
            },
            comment: "Add comment",
            buttonSend: "Send",
            buttonValidate: "Validate",
        }
    },
    exports: {
        exportModal: {
            title: "Selected Products",
            subtitle: "Select deliverables",
            formActive: {
                title: "Select assets",
                datasheet: "Data Sheets",
                description: "Descriptions",
                image: "Images",
                brands: "Select Retailers",
                selectAll: "Select All",
            },
            buttons: {
                back: "Back",
                export: "Export",
            }
        },
        versionModal: {
            title: "Choose the version of your products",
            button: "Version",
        },
        productsExportModal: {
            title: "Products to Export",
            form: {
                title: "The following products are incomplete:",
                upc: "UPC",
                name: "Name",
                retailer: "Chain",
                status: "Status",
            },
            buttons: {
                complete: "Complete Products",
                export: "Export Products",
            }
        },
        exportedModal: {
            title: "Your products are being processed.",
            subtitle: "We will share a download link with you via email once completed.",
            close: "Close",
        },        
    },
    addProducts: {
        utils: {
            onLoad: {
                modal: {
                    message: "Error in template file",
                    detail: "The template you are trying to upload might not be correct for this table, try downloading it again"
                }
            },
            errorFileComponent: "Exit",
            uploadFile: {
                message: "Download template",
                button: "Upload"
            },
            upcList: {
                title: "These UPCs were found on the platform",
                subtitle: "For the order, the existing UPCs will be used",
                button: "Continue"
            }
        },        
        bottom: {
            modalDataErrorCreate: {
                message: "Error creating items",
                detail: "It seems the user limit has been exceeded",
            },
            modalDataRepeatUpc: {
                message: "Some of the UPCs are already on the platform",
                detail: "All UPCs were inserted except for:"
            },
            modalDataError: {
                message: "You have some errors",
                detail: "You may need to complete a field or you have duplicate UPCs"
            },
            uploadFile: {
                message: "Do you already have the template file?",
                detail: "Fill in the information as requested by each column. When finished, upload your file in this module. Max. 100 products.",
            },
            description: {
                lightText: "Upload your multiple products",
                lightText2: "in a ",
                boldText: "CSV or XSL file",
                boldText2: "Max. 100 products.",
            },
            emptyList: {
                message: "Empty product list",
                detail: "Enter at least one product to continue",
                button: "Accept",
            },
            isRetailer: {
                provider: "Request from supplier",
                create: "Create products",
                send: "Send the request to the manufacturer",
                add: "Add these products to your list",
            },
            request: {
                title: "Request to Content-oh!",
                subtitle: "Create the purchase order"
            }
        },
        mid: {
            table: {
                name: "Name",
                department: "Department",
                category: "Category",
                productType: "Product Type",
                provider: "Provider",
                retailer: "Retailer",
                retailers: "Retailers",
                error: "The UPC already exists",
            },
        },
        top: {
            title: "Add your products",
            isRetailer: {
                title: "New supplier",
                subtitle: "Add your new supplier"
            }
        }
    },
    buttonsSection: {
        export: "Export",
        upgradePlan: {
            message: "Your current plan does not allow you to use this feature",
            detail: "Do you want to upgrade it?",
            cancel: "Cancel",
            accept: "Upgrade"
        }
    }
    
    
}