export const getColorPercentage = (percentage) => {
  const percentageNumber = Number(percentage);
  if (percentageNumber === 100) {
    return "purple";
  } else if (percentageNumber >= 50 && percentageNumber < 100) {
    return "yellow";
  } else {
    return "red";
  }
};

export const redirectEdit = (productDetail, setRedirect, addNotification) => {
  const membership = JSON.parse(sessionStorage.getItem("user")).membership;
  const canEdit = membership.planID !== 1;

  try {
    if (!canEdit) {
      return true; 
    }else{
      addNotification('error','Necesitas aumentar tu plan para editar')
    }
    sessionStorage.setItem("asignations", JSON.stringify(productDetail.asignations ?? []));

    setRedirect(`/EditProduct/${productDetail.id_article}`);
  } catch (error) {
    console.log(error);
  }
};

export const getButtonVariant = (reviewState) => {
  switch (reviewState) {
    case "Rechazado a proveedor":
    case "Rechazado":
      return "red";
    case "En progreso":
      return "yellow";
    case "Aprobado":
      return "green";
    default:
      return "gray";
  }
};