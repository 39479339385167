import { GlobalColors } from "../../../global-files/variables";
import { Container } from "./styles";

export const ScreenHeader = ({
  text,
  boldText,
  fontFamily,
  color,
  headerType,
  Paragraph,
}) => {
  return (
    <Container
      fontFamily={fontFamily}
      color={color}
      className={headerType}
      key={`title-${text}`}
    >
      {text} {boldText && <span> {boldText}</span>}
      {Paragraph && <p>{Paragraph}</p>}
    </Container>
  );
};

ScreenHeader.defaultProps = {
  text: "Header Text Default",
  fontFamily: "Raleway",
  color: GlobalColors.s5,
};
