import styled from "styled-components";
import radiobuttonchecked from "../../../assets/IconComponents/radiobuttonchecked.svg";
import radiobuttonunchecked from "../../../assets/IconComponents/radiobuttonunchecked.svg";

export const MainContainer = styled.div`
  border: 1px solid var(--gray-light-secondary);
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Section = styled.div`
  width: 95%;
  border-bottom: 1px solid var(--gray-light-secondary);
  padding: 14px 0px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  p {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: var(--gray-contentoh);
  }

  .title {
    figure {
      & + p {
        margin-left: 12px;
      }
    }

    & + * {
      margin-top: 22px;
    }
  }
`;

export const Header = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction:row;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #817393;
  }
`;

export const BasicData = styled(Section)`
  div {
    height: 28px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    .title,
    .item {
      width: 35%;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.015em;
      color: #503d66;
      & + p {
        color: #817393;
      }
    }

    h4 {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.015em;
      color: #503d66;
    }

    figure {
      margin: 0;
      & + * {
        margin-left: 13px;
      }
    }
  }
  #address-input{
    max-width: 190px;
  }

  #inputSelect div {
    display: flex;
    place-items:start;
    flex-direction: column;
    left: -15px;
    height: auto;
  }
    .btnText {
      color: #fff;
      font-size: 12px;
      border: 1px solid var(--pink-contentoh);
      :hover{
        background: var(--white-contentoh);
        color: var(--pink-contentoh);
        border: 1px solid var(--pink-contentoh);
      }
    }
`;

export const TitleIcon = styled.figure`
  margin: 0 15px 0 0;
  align-items: center;
  cursor: pointer;
`;