import axios from "axios";
export const fetchPhases = async (token, addNotification) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PHASES_ENDPOINT}/get`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );
    const phasesData = JSON.parse(response.data.body).data;
    return phasesData.map(({ phaseId, phaseName, isInitialPhase, nextPhaseIfApproved, groups }) => ({
      phaseId,
      phaseName,
      isInitialPhase,
      nextPhaseIfApproved,
      groupsAssigned: groups.map(({ id, name, active }) => ({
        groupId: id,
        groupName: name,
        groupActive: active,
      })),
    }));
  } catch (error) {
    console.error("Error al obtener fases:", error);
    addNotification("error", "Error al obtener fases");
    return [];
  }
};

export const createPhase = async(search, token, setSearch, setInputValue, addNotification, loadData) => {
  try {
    const body = {
      name: search,
      retailerGroupsIds: [],
      nextPhaseId: null
    }
   const response = await axios.post(
      `${process.env.REACT_APP_PHASES_ENDPOINT}/create`,
      body,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    const createdPhase = response.data.body;
    setSearch();
    setInputValue("");
    addNotification("success", "Fase creada correctamente");
    loadData();
  } catch (error) {
    console.error("Error al agregar fase:", error);
    addNotification("error", "Error al agregar fase");
  }
}

export const updatePhases = async (listPhases, token, addNotification, loadData) => {
  try {
    const updateData = {
        updateReferencesOfNodes: listPhases.map((phase) => ({
          name:phase.phaseName,
          isInitialPhase: phase.isInitialPhase,
          phaseId: phase.phaseId,
          nextPhaseIfApproved: phase.nextPhaseIfApproved,
        }))
    };

    const response = await axios.post(
      `${process.env.REACT_APP_PHASES_ENDPOINT}/update`,
      updateData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    addNotification("success", "Fase actualizada correctamente.");
    loadData();
    fetchPhases(token, addNotification);
  } catch (error) {
    console.error("Error al actualizar fases", error);
    addNotification("error", "Error al actualizar fases.");
  }
};


export const deletePhase = async(id, phases, token, loadData, addNotification) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PHASES_ENDPOINT}/delete`,
      {
        updateReferencesOfNodes:phases,
        deletePhase:id
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    loadData();
    addNotification("success", "Fase eliminada correctamente.");
  } catch (error) {
    console.error("Error al eliminar fase:", error);
    addNotification("error", "Error al eliminar fase");
  } 
}