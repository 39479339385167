import { Container } from "./styles";
import { Buffer } from "buffer";
import { HeaderTop } from "../molecules/HeaderTop";
import { ImagePreviewer } from "../organisms/ImagePreviewer";
import { ImageDataTable } from "../organisms/ImageDataTable";
import { FullProductNameHeader } from "../organisms/FullProductNameHeader";
import { FullTabsMenu } from "../organisms/FullTabsMenu";
import { InputGroup } from "../organisms/InputGroup";
import { useEffect, useReducer, useState, useCallback } from "react";
import { GalleryElement } from "../molecules/GalleryElement";
import { saveAs } from "file-saver";
import {
  getRetailerServices,
  getPercentage,
  fetchUsers,
} from "../../global-files/data";
import { GalleryHeader } from "../molecules/GalleryHeader";
import { ProductImageModal } from "../organisms/ProductImageModal";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import AWS from "aws-sdk";
import { TagAndInput } from "../molecules/TagAndInput/index";
import { Button } from "../atoms/GeneralButton";
// import { Commentary } from "../../atoms/Commentary";
import { GenericModal } from "../atoms/GenericModal";
import { ScreenHeader } from "../atoms/ScreenHeader";
import { Loading } from "../atoms/Loading";
import succes from "../../assets/images/genericModal/genericModalCheck.svg";
import { Link } from "react-router-dom";
import face from "../../assets/images/defaultImages/defaultProfileImage.svg";
import errorModal from "../../assets/images/genericModal/errorModal.svg";
import { GlobalModal } from "../organisms/GlobalModal/index";
// import { SliderToolTip } from "../atoms/SliderToolTip";
// import InfoIcon from "../../assets/images/sliderToolTip/infoIcon.svg";
// import Slide1_1 from "../../assets/images/sliderToolTip/slide1.svg";
// import Slide1_2 from "../../assets/images/sliderToolTip/slide2.svg";
// import Slide1_3 from "../../assets/images/sliderToolTip/slide3.svg";
// import Slide1_4 from "../../assets/images/sliderToolTip/slide4.svg";
// import Slide1_5 from "../../assets/images/sliderToolTip/slide5.svg";
import { VersionSelector } from "../organisms/VersionSelector";
import { useCloseModal } from "../../global-files/customHooks";
// import { createMessage, sendMessage } from "../RetailerProductEdition/utils";
import { Modal } from "../organisms/Modal";
import { ButtonV2 } from "../atoms/ButtonV2";
// import { Box } from "../../organisms/Box";
import { useGetPercentage } from "../../hooks/useGetPercentage";

const reducerImages = (state, action) => {
  let { values, attrForImgs, inputsByRetailer } = state;
  switch (action.action) {
    case "init":
      const newInputsByRetailer = {};
      action?.init?.inputsByRetailer?.forEach((inputs) => {
        inputs?.forEach((input) => {
          if (!newInputsByRetailer[`${input.id_retailer}`])
            newInputsByRetailer[`${input.id_retailer}`] = [];
          newInputsByRetailer[`${input.id_retailer}`].push(input);
        });
      });
      inputsByRetailer = newInputsByRetailer;
      return {
        ...action.init,
        inputsByRetailer,
        valuesInitial: action.init.values,
        inputsInitial: action.init.inputs,
      };
    case "addImg":
      values = [...values, action.img];
      return { ...state, values };
    case "changeImageInfo":
      values[action.index][action.attribute] = action.value;
      return { ...state, values };
    case "changeAttrValue":
      const index = attrForImgs.general.findIndex((f) => (f.id = action.id));
      if (index !== -1) {
        attrForImgs.general[index].value = action.value;
      }

      return { ...state, attrForImgs, values };
    case "deleteImage":
      values = values.filter(
        (value) => action.selectedImages.indexOf(value) === -1
      );
      return { ...state, values };
    case "orderImages": {
      let {
        inputsByRetailer = {},
        valuesInitial,
        inputsInitial,
        inputs,
      } = state;
      try {
        const orderedImages = [];
        const imageIdArray = [];
        if (action?.retailerId && !inputsByRetailer[action.retailerId]) {
          inputsByRetailer[action.retailerId] = [];
        }
        if (action.retailerId) {
          inputsByRetailer[action.retailerId]?.filter((input) => {
            imageIdArray.push(input.id_image);
            valuesInitial.forEach((value) => {
              if (value.image_id === input.id_image) orderedImages.push(value);
            });
          });
        }

        inputs = inputsInitial?.filter((input) =>
          imageIdArray.includes(input.id)
        );

        values = orderedImages.length > 0 ? orderedImages : [];
      } catch (error) {
        console.log(action.action, error);
      }
      return { ...state, values, inputs };
    }
    default:
      return state;
  }
};
const S3_BUCKET = process.env.REACT_APP_IMAGES_BUCKET;
const REGION = "us-east-1";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_KUTS3,
  secretAccessKey: process.env.REACT_APP_AKUTS3,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export const EditProduct = ({
  tabsSections,
  user = {},
  location = {},
  token,
  revision = false,
  setShowContentohRequestModal,
  showSurvey,
  company,
  addToCart,
}) => {

  const [activeTab, setActiveTab] = useState("Descripción");
  const [activeImage, setActiveImage] = useState();
  const [imageLayout, setImageLayout] = useState(false);
  const [headerTop, setHeaderTop] = useState(0);
  const [descriptions, setDescriptions] = useState([]);
  const [datasheets, setDatasheets] = useState([]);
  const [images, setImages] = useReducer(reducerImages, {});
  const [showModal, setShowModal] = useState(false);
  const [boxData, setBoxData] = useState({});
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    noKeyboard: true,
    multiple: true,
    noClick: true,
    onDrop: (acceptedFiles) => {
      const newImages = [];
      acceptedFiles.map((file) => {
        const reader = new FileReader();
        reader.fileName = file.name;
        reader.onload = async function (e) {
          const ext = e.srcElement.fileName.split(".");
          const img = new Image();
          img.src = e.target.result;
          setTimeout(() => {
            const width = img.width;
            const height = img.height;
            const newImg = {
              action: "addImg",
              img: {
                src: e.target.result,
                name: e.target.fileName,
                ext: ext[ext.length - 1],
                width: width,
                height: height,
                isApproved: true, //VALIDAR QUE SOLO SEA PARA RADIOSHACK
              },
            };
            setImages(newImg);
          }, 500);
        };
        reader.onerror = function (error) {
          console.log("dropzoneError: ", error);
        };
        reader.readAsDataURL(file);
        return file;
      });
    },
  });
  const [updatedDatasheets, setUpdatedDatasheets] = useState([]);
  const [updatedDescriptions, setUpdatedDescriptions] = useState([]);
  const [imagesUploaded, setImagesUploaded] = useState(false);
  const [dataImages, setDataImages] = useState();
  // const [activePercentage, setActivePercentage] = useState(0);
  const [activeRetailer, setActiveRetailer] = useState({});
  const [services, setServices] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [message, setMessage] = useState("");
  const productSelectedStoraged = JSON.parse(sessionStorage.getItem("productSelected"));
  
  const selectedPr = {
    "services": {
      "datasheets": 1,
      "descriptions": 1,
      "images": 1
    },
    "orderId": 15456,
    "status": "PA",
    "datasheet_status": "PA",
    "observations": null,
    "prio": "none",
    "version": productSelectedStoraged.version,
    "description_status": "PA",
    "images_status": "PA",
    "statusByRetailer": {
      "4": {
        "datasheet": "PA",
        "description": "PA",
        "images": "PA"
      }
    },
    "article": {
      "id_article": productSelectedStoraged.article.id_article,
      "id_category": productSelectedStoraged.article.id_category,
      "name": productSelectedStoraged.article.name,
      "upc": productSelectedStoraged.article.upc,
      "timestamp": "2024-03-26T18:05:49.000Z",
      "id_user": 28,
      "status": "NULL",
      "active": 1,
      "company_id": productSelectedStoraged.article.company_id,
      "company_name": productSelectedStoraged.article.company_name,
      "country": "México",
      "id_order": 15456,
      "id_datasheet_especialist": null,
      "id_datasheet_facilitator": null,
      "id_description_especialist": null,
      "id_description_facilitator": null,
      "id_images_especialist": null,
      "id_images_facilitator": null,
      "id_auditor": null,
      "id_recepcionist": null,
      "category": productSelectedStoraged.article.category,
      "missingAttributes": 0,
      "missingDescriptions": 0,
      "missingImages": 0
    },
    "retailers": [
      {
        "id": 74,
        "name": "Radioshack",
      }
    ],
    "country": "México",
    "upc": "993366"
  };
  sessionStorage.setItem("productSelected", JSON.stringify(selectedPr));
  const [product, setProduct] = useState(
    JSON.parse(sessionStorage.getItem("productSelected"))
  );
  const [activePercentage, setActivePercentage] = useState(0);
  const [percentages, setPercentages] = useState(
    product?.retailers
      ? new Array(product?.retailers?.length).fill({ percentage: 0 })
      : new Array(product?.retailersAvailable?.length).fill({ percentage: 0 })
  );

  const recreateEditProductObj = {
    categoryName: product.article.category,
    id_article: product.article.id_article,
    id_category: product.article.id_category,
    name: product.article.name,
    percentage: 0,
    retailersAvailable: {
      id: product.retailers[0].id,
      name: product.retailers[0].name,
      percentage: 0
    },
    upc: product.article.upc,
    version: product.version,
  }

  const editPro = {
    "idCategory": "4",
    "ArticleId": 121211,
    "product": [recreateEditProductObj],
};
  sessionStorage.setItem("productEdit", JSON.stringify(editPro));
  const [productEdit, setProductEdit] = useState( editPro ||
    JSON.parse(sessionStorage.getItem("productEdit"))
  );
  
  const [version, setVersion] = useState(product?.version);
  const [comments] = useState({});
  const [comment, setComment] = useState("");
  const [crossComment, setCrossComment] = useState(false);
  const [userGroups, setUserGroups] = useState([]);
  const [assig, setAssig] = useState({});
  const [selectedImages, setSelectedImages] = useState([]);
  const [componentsArray, setComponentsArray] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const isRetailer = user?.is_retailer;
  const [loading, setLoading] = useState(true);
  const [validatedAll, setValidatedAll] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [approvedInputs, setApprovedInputs] = useState({});
  const [approvedInputsImages, setApprovedInputsImages] = useState({});

  const updateApprovedInputs = (inputId) => {
    setApprovedInputs((prevInputs) => ({
      ...prevInputs,
      [inputId]: true,
    }));
  };
  const updateApprovedInputsImages = (inputId) => {
    setApprovedInputsImages((prevInputs) => ({
      ...prevInputs,
      [inputId]: true,
    }));
  };

  const [origin, setOrigin] = useState({
    "Ficha técnica": null,
    Descripción: null,
    Imágenes: null,
  });

  const [inCart, setInCart] = useState(false);
  const closeGlobalModal = () => setGlobalModal();
  const [dataGenericModal, setDataGenericModal] = useState({
    message: "¿Estás seguro de continuar?",
    detail: ` ${
      user.is_retailer
        ? "Asegurate de haber revisado las tres secciones"
        : "Envíar el producto a la cadena"
    }`,
    button1: {
      name: "Cerrar",
      action: closeGlobalModal,
    },
    button2: {
      name: "Continuar",
      action: () => evaluationToRetailer(false),
    },
    img: face,
  });
  const [socketType, setSocketType] = useState(null);
  const [saving, setSaving] = useState(loading);
  const [retailerStatus, setRetailerStatus] = useState({
    58: {
      datasheet: "AA",
      description: "AA",
      images: "AC",
    },
  });
  const [showVersionSelector, setShowVersionSelector] =
    useCloseModal("version-selector");
  const [globalModal, setGlobalModal] = useState();

  const updateAuditStatus = async (status) => {
    const productTemp = product;
    productTemp.article_status = status;
    productTemp.datasheet_status = status;
    productTemp.description_status = status;
    productTemp.images_status = status;

    const retailerStatusCopy = { ...retailerStatus };
    const conceptArray = ["description", "datasheet", "images"];
    Object.keys(retailerStatusCopy).forEach((key) => {
      conceptArray.forEach((concept) => {
        if (retailerStatusCopy[key][concept]) {
          retailerStatusCopy[key][concept] = status;
        }
      });
    });
    setRetailerStatus(retailerStatusCopy);
    productTemp.statusByRetailer = retailerStatusCopy;
    setProduct(productTemp);
    sessionStorage.setItem("productSelected", JSON.stringify(productTemp));
    setProductEdit({
      ArticleId: productTemp.id_article,
      idCategory: productTemp.article.id_category,
      product: productTemp,
    });
    sessionStorage.setItem(
      "productEdit",
      JSON.stringify({
        ArticleId: productTemp.id_article,
        idCategory: productTemp.article.id_category,
        product: productTemp,
      })
    );
    await loadData();
  };

  const addCart = async (retailers) => {
    const { article, version } = product;
    const articleToOrder = [
      {
        articleId: article.id_article,
        version,
        userId: user.id_user,
        discount: 0,
        subtotal: 0,
        companyId: user.id_company,
        datasheet: retailers,
        description: retailers,
        image: retailers,
        attributeTranslations: false,
        descriptionTranslations: false,
        build: "[]",
        manual: "[]",
        userCreated: user.id_user,
      },
    ];
    let modatlType = "addedToCartError";
    if (addToCart) {
      const resCart = await addToCart({ data: articleToOrder });
      if (resCart.data.statusCode === 200) {
        modatlType = "addedToCart";
      }
    }
    showGlobalModal(modatlType);
  };

  const seenByProvider = async () => {
    const productTemp = product;
    const articleId = productTemp.id_article;
    const orderId = productTemp.id_order ?? productTemp.orderId;
    const promises = [];
    const conceptArray = ["description", "datasheet", "images"];
    conceptArray.forEach((concept) => {
      const sectionStatusKey = `${concept}_status`;
      const evalStatus = product[sectionStatusKey] || product?.version_status;
      const data = { articleId, orderId, concept, evalStatus };
      promises.push(
        axios.put(`${process.env.REACT_APP_SEND_EVAL}`, data, {
          headers: {
            Authorization: token,
          },
        })
      );
    });
    await Promise.all(promises);
    closeGlobalModal();
    await updateAuditStatus("FAP");
  };

  const showGlobalModal = useCallback(
    (type) => {
      const formatter = new Intl.ListFormat("es", { type: "conjunction" });
      switch (type) {
        case "generic":
          setGlobalModal(dataGenericModal);
          break;
        case "commentsSent":
          setGlobalModal({
            message: "Gracias, tus comentarios fueron entregados.",
            detail: "Trabajaremos en conjunto para resolverlo.",
            button1: {
              name: "Cerrar",
              action: closeGlobalModal,
            },
            img: face,
            textArea: false,
          });
          break;
        case "RAC":
          const servicesRejected = [];
          const translateServices = {
            datasheet: "ficha técnica",
            description: "descripción",
            images: "imágenes",
          };
          Object.keys(translateServices).forEach((service) => {
            if (product[`${service}_status`] === "RAC") {
              servicesRejected.push(translateServices[service]);
            }
          });
          const plural = ["Los servicios", "cumplen"];
          const singular = ["El servicio", "cumple"];
          const grammar = servicesRejected.length > 1 ? plural : singular;
          const formatted = formatter.format(servicesRejected);
          const text1 = `${grammar[0]} de ${formatted} no ${grammar[1]} con lo que la cadena necesita.`;
          const text2 = `¡Solicita el servicio de Content-oh! para completarlo!`;
          const retailers = product.retailersWithService.map((retailer) =>
            Number(retailer)
          );
          setGlobalModal({
            message:
              "Tu producto no cumple con los requerimientos de la cadena",
            customComponent: (
              <>
                <p className="generic-text">
                  {text1}
                  <br />
                  <br />
                  {text2}
                </p>
              </>
            ),
            button1: {
              name: "Continuar",
              action: async () => await seenByProvider(),
            },
            button2: {
              name: "Solicitar a Content-oh!",
              action: async () => {
                await addCart(retailers);
                await seenByProvider();
              },
            },
            img: errorModal,
          });
          break;
        case "AAC":
          setGlobalModal({
            message: "Felicidades",
            detail: "Tu producto cumple con los requerimientos de la cadena.",
            button1: {
              name: "Continuar",
              action: async () => await seenByProvider(),
            },
            img: face,
          });
          break;
        case "addedToCartError":
          setGlobalModal({
            detail:
              "Parece que algo salió mal al intentar añadir tu producto a tu carrito.",
            button1: {
              name: "Continuar",
              action: closeGlobalModal,
            },
          });
          break;
        case "addedToCart":
          setGlobalModal({
            message: "Producto añadido",
            detail: "Tu producto se añadió al carrito de compras.",
            button1: {
              name: "Continuar",
              action: closeGlobalModal,
            },
            img: face,
          });
          break;
        default:
          closeGlobalModal();
          break;
      }
    },
    [activeTab, dataGenericModal, product]
  );

  useEffect(() => {
    checkAll && setSelectedImages(images.values);
  }, [checkAll]);

  useEffect(() => {
    if (showModal) {
      setActiveTab("Imágenes");
    }
  }, [showModal]);

  useEffect(() => {
    setOrigin({
      "Ficha técnica": location?.state?.origin,
      Descripción: location?.state?.origin,
      Imágenes: location?.state?.origin,
    });
  }, [location?.state?.origin]);

  const loadData = async () => {
    const services = await getRetailerServices(
      product?.id_article || product?.article?.id_article,
      product?.article?.company_name || product?.categoryName,
      parseInt(product?.article?.id_category || product?.id_category),
      version,
      token
    );
    //Converts the data inside the datasheets object to array
    setServices(services);
    await getServices();

    setImages({ action: "init", init: services[2] });
    if (services[2]?.values?.length > 0) setActiveImage(0);
    const data = [
      {
        id_article: product.article.id_article,
        id_category: product.article.id_category,
        version: product.version,
        id_retailer_array: (
          product.retailersAvailable ?? product.retailers
        ).map(({ id }) => id),
      },
    ];
    const headers = { Authorization: token };
    getPercentage({ data, headers }).then((res) => {
      setPercentages(res[0]);
    });
    setLoading(false);
  };

  const getServices = async () => {
    const productInOrder = product.id_order || product.orderId;
    const idArticle = product?.id_article || product?.article?.id_article;
    let retailers = product.retailers || product.retailersAvailable;
    let active = null;
    if (isRevision() || productInOrder) {
      const servicesResponse = await axios.get(
        `${
          process.env.REACT_APP_SERVICES_ENDPOINT
        }?articleId=${idArticle}&orderId=${
          product?.id_order ?? product.orderId
        }&end=true`
      );
      const parsedResponse = JSON.parse(servicesResponse?.data?.body).data;
      const retailerResponse = parsedResponse?.map((srv) => srv.id_retailer);
      active = retailers?.find((retailer) =>
        retailerResponse.includes(retailer.id)
      );
      setServicesData(parsedResponse);
    }
    retailers &&
      !activeRetailer.id &&
      setActiveRetailer(active ? active : retailers[0]);
  };

  const isRevision = () => {
    if (productEdit) {
      const concept = getConcept(activeTab);
      const isRetailer = user.is_retailer === 1;
      const currentService = productEdit?.product[`${concept}_status`];
      const orgn = origin[activeTab];
      const revision = [
        "RequestWithContentoh",
        "RequestWithoutContentoh",
        "Contentoh",
      ].includes(orgn);
      if (isRetailer) {
        if (revision && currentService === "AP") return true;
      } else {
        const { product } = productEdit;
        const isRequestWithoutContentoh =
          orgn === "RequestWithoutContentoh" &&
          (["R", "CA"].includes(currentService) ||
            (currentService === "RCA" &&
              (!product.id_order || !product.orderId)));
        const isRequestWithContentoh =
          orgn === "RequestWithContentoh" && currentService === "AA";
        const isContentoh = orgn === "Contentoh" && currentService === "AA";
        if (isContentoh || isRequestWithoutContentoh || isRequestWithContentoh)
          return true;
      }
    }
    return false;
  };

  // const getCart = async () => {
  //   const res = await axios.get(`${process.env.REACT_APP_CART}/general/`, {
  //     headers: {
  //       Authorization: token,
  //     },
  //   });
  //   const arr = Object.values(JSON.parse(res.data.body).data)
  //     ?.map((e) => e.article_id)
  //     .filter(Boolean);
  //   setInCart(arr.some((e) => e.articleId === product.id_article));
  // };

  useEffect(() => {
    sessionStorage.setItem("user", JSON.stringify(user));
    loadData();
    // getCart();
    fetchUsers(token).then((res) => setUserGroups(res));
    setImages({ action: "orderImages", retailerId: activeRetailer.id });
    if (["RAC", "AAC"].includes(product.article_status))
      showGlobalModal(product.article_status);
  }, [product, version]);

  useEffect(() => {
    setImages({ action: "orderImages", retailerId: activeRetailer.id });
  }, [activeRetailer]);

  const loadAssignations = (currentProduct) => {
    setAssig(product?.asignations);
  };

  useEffect(() => {
    loadAssignations(product);
  }, [userGroups]);

  // useEffect(() => {
  //   const productTemp = product;
  //   const retailers = productTemp?.retailersAvailable || productTemp?.retailers;
  //   if (Object.keys(percentages[productTemp.id_article] ?? {})?.length > 0) {
  //     retailers?.forEach((retailer, index) => {
  //       retailer["percentage"] = Number(
  //         percentages[productTemp.id_article][retailer.id]?.percentageRequired
  //       );
  //     });
  //   }
  //   setProduct(productTemp);
  //   retailers && setActivePercentage(retailers[0]?.percentage);
  // }, [percentages]);

  const loadInputs = () => {
    if (services.length > 0) {
      if (services[0][activeRetailer.id]?.data)
        services[0][activeRetailer.id].data = Object.values(
          services[0][activeRetailer.id].data
        );
        const data = [
          {
            id_article: product.article.id_article,
            id_category: product.article.id_category,
            version: product.version,
            id_retailer_array: (
              product.retailersAvailable ?? product.retailers
            ).map(({ id }) => id),
          },
        ];
      getPercentage({ data, headers: { Authorization: token } }).then((res) => { 
        setActivePercentage(res[0][product.article.id_article].percentageRequired)
      });

      const datagroups = services[0][activeRetailer.id];
      const inputs = services[0]?.inputs;
      const descriptions = services[1]?.filter(
        (service) => service?.id === activeRetailer?.id
      );
      setDatasheets([datagroups, inputs]);
      setDescriptions(descriptions);
    }
  };

  useEffect(() => {
    loadInputs();
    activeRetailer.id &&
      setImages({ action: "orderImages", retailerId: activeRetailer.id });
  }, [servicesData, activeRetailer, services]);

  const thumbs = () => {
    const imageInputs = socketType?.slice();
    const imageType = images?.imageType?.map((e) => ({
      value: e?.id,
      name: e?.name,
    }));
    const imagePackagingType = images?.imagePackagingType?.map((e) => ({
      value: e?.id,
      name: e?.name,
    }));
    return images?.values?.map((image, index) => (
      <GalleryElement
        setCheckAll={setCheckAll}
        key={index}
        image={image}
        gridLayout={imageLayout}
        id={"gallery-element-" + index}
        index={index + "-" + image.name}
        number={index}
        imageType={imageType}
        imagePackagingType={imagePackagingType}
        imageInputs={imageInputs}
        changeImage={setImages}
        selectedImages={selectedImages}
        setSelectedImages={setSelectedImages}
        updateApprovedInputsImages={updateApprovedInputsImages}
      />
    ));
  };

  useEffect(() => {
    const imageInputs = images?.inputs?.map((e) => ({
      value: e?.id,
      name: e?.name,
      required: e?.required,
      active: images?.values?.some((value) => value?.image_id === e?.id),
    }));
    setSocketType(imageInputs);
  }, [images]);

  const saveDescriptions = async () => {
    //GUARDAS DESCRIPCIONES
    setLoading(true);
    const dataObject = {
      articleId: product?.article.id_article,
      articleData: updatedDescriptions,
    };
    dataObject.articleData = dataObject.articleData.map((item) => {
      const attributeId = item.attributeId;
      //if (approvedInputs.hasOwnProperty(attributeId)) {
      item.isApproved = true;
      //}
      return item;
    });

    if (product?.orderId) dataObject["orderId"] = product?.orderId;
    // console.log("dataObject", dataObject);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_ARTICLE_DATA_ENDPOINT}?description=true&version=${version}`,
        dataObject,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (res.data.statusCode === 200) {
        setUpdatedDescriptions([]);
        setMessage("Descripciones guardadas con éxito");
        await loadData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveDatasheets = async () => {
    const parseBoxData = [];
    Object.entries(boxData).forEach(([, box], index) => {
      const { value } = box;
      const attributesIds = Object.keys(value);
      attributesIds.forEach((attributeId) => {
        const boxId = index + 1;
        const valueOfAtribute = value[attributeId];
        parseBoxData.push({
          attributeId,
          value: valueOfAtribute,
          boxId,
        });
      });
    });

    const dataObject = {
      articleId: product?.article.id_article,
      articleData: updatedDatasheets,
      ...(parseBoxData.length > 0 && { boxData: parseBoxData }),
    };
    dataObject.articleData = dataObject.articleData.map((item) => {
      const attributeId = item.attributeId;
      if (approvedInputs.hasOwnProperty(attributeId)) {
        item.isApproved = true;
      }
      return item;
    });

    if (product?.orderId) dataObject["orderId"] = product?.orderId;
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_ARTICLE_DATA_ENDPOINT}?datasheet=true&version=${version}`,
        dataObject,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (res.data.statusCode === 200) {
        setUpdatedDatasheets([]);
        setMessage("Fichas técnicas guardadas");
        await loadData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateImages = useCallback(async () => {
    const imagesList = images?.values?.slice();
    const imagesListTemp = imagesList?.reduce((acc, image) => {
      acc[image?.image_id] = ++acc[image?.image_id] || 0;
      return acc;
    }, {});

    const duplicated = imagesList?.filter((image) => {
      return imagesListTemp[image?.image_id];
    });

    const attrForImgs = Object.values(images?.attrForImgs);
    attrForImgs.pop();
    const data = {
      articleId: product?.article.id_article,
      attrReqImgs: attrForImgs?.map((e) => ({
        attrId: e[0]?.id,
        value: e[0]?.value,
      })),
      articleData: imagesList?.filter((e) => !e.id),
      updateImages: imagesList?.filter((e) => e.id),
    };
    if (product?.orderId) data["orderId"] = product?.orderId;
    let valid =
      data?.articleData?.length === 0
        ? true
        : data?.articleData?.every((e, i) => {
            if (e?.image_id && e?.packing_type && e?.image_type) {
              return true;
            }
            return false;
          });
    if (valid && data?.updateImages?.length > 0 && duplicated?.length === 0) {
      valid = data?.updateImages?.every((e, i) => {
        if (e?.image_id && e?.packing_type && e?.image_type) {
          return true;
        }
        return false;
      });
    }
    console.log("DATA NEW", data);

    if (valid && duplicated?.length === 0) {
      setLoading(true);
      try {
        data?.articleData?.forEach((e) => {
          e.uuid = uuidv4();
        });
        data.updateImages = data.updateImages.map((image) => {
          return {
            ...image,
            isApproved: true,
          };
          /**if (approvedInputsImages[image.image_id] !== undefined) {
            return {
              ...image,
              isApproved: approvedInputsImages[image.image_id],
            };
          }**/
          return image;
        });
        setDataImages(data);
        if (data?.articleData?.length > 0) {
          setImagesUploaded(false);
          const promiseArray = [];
          data?.articleData?.forEach((e) => {
            const file = Buffer.from(
              e.src.replace(/^data:image\/\w+;base64,/, ""),
              "base64"
            );
            const params = {
              ACL: "public-read",
              Body: file,
              Bucket: S3_BUCKET,
              Key: `id-${data.articleId}/${version}/${e?.image_id}-${e?.uuid}.${e?.ext}`,
            };
            promiseArray.push(myBucket.putObject(params).promise());
          });
          await Promise.all(promiseArray);
          setImagesUploaded(true);
        } else {
          setImagesUploaded(true);
        }
      } catch (err) {
        console.log(err);
        // setMainLoading(false);
      }
    } else {
      // setMainLoading(false);
      setMessage(
        "Completa los campos que solicita cada una de la imágenes o hay imágenes con el mismo tipo de toma.\nRecuerda hay campos obligatorios y no podras avanzar si no estan completos."
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvedInputsImages, images, imagesUploaded]);

  useEffect(() => {
    const fetchData = async () => {
      if (imagesUploaded) {
        dataImages.articleData = dataImages?.articleData.map((e) => {
          delete e.src;
          e.imageID = e.image_id;
          e.packingType = e.packing_type;
          e.imageType = e.image_type;
          if (product?.orderId) e["orderId"] = product?.orderId;
          return e;
        });
        try {
          const res = await axios.put(
            `${process.env.REACT_APP_ARTICLE_DATA_ENDPOINT}?image=true&version=${version}`,
            dataImages,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          if (res.data.statusCode === 200) {
            setImages({});
            setMessage("Imágenes guardadas con éxito");
            sessionStorage.removeItem("imagesList");
            await loadData();
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
  }, [dataImages, imagesUploaded]);
  

  const getConcept = (tab) => {
    switch (tab) {
      case "Descripción":
        return "description";
      case "Ficha técnica":
        return "datasheet";
      case "Imágenes":
        return "images";
    }
  };

  const createComment = async (body) => {
    let concept = "";
    switch (activeTab) {
      case "Ficha técnica":
        concept = "datasheet";
        break;
      case "Imágenes":
        concept = "images";
        break;

      default:
        concept = "description";
        break;
    }
    const data = {
      articleId: product?.id_article,
      orderId: product?.orderId,
      message: body,
      concept: concept,
      version: version,
    };
    await axios.post(`${process.env.REACT_APP_COMMENTS_ENDPOINT}`, data, {
      headers: {
        Authorization: token,
      },
    });
  };

  useEffect(() => {
    setComment(comments[activeTab]);
  }, [activeTab]);

  const commentRevised = async () => {
    const data = {
      commentId: comment.id,
    };
    await axios.put(`${process.env.REACT_APP_COMMENTS_ENDPOINT}`, data, {
      headers: {
        Authorization: token,
      },
    });
    setCrossComment(false);
  };

  const setAssignation = async (assignationType, assignationId) => {
    let concept = "";
    switch (activeTab) {
      case "Ficha técnica":
        concept = "datasheet";
        break;
      case "Imágenes":
        concept = "images";
        break;

      default:
        concept = "description";
        break;
    }
    const productTemp = product;
    productTemp.article[`id_${concept}_${assignationType}`] = assignationId;
    const data = {
      articleList: [
        {
          orderId: product.orderId,
          articleId: product?.id_article,
        },
      ],
      concept: concept,
      userId: assignationId,
    };
    await axios({
      method: "post",
      url: process.env.REACT_APP_ASSIGNATIONS_ENDPOINT,
      data,
      headers: {
        Authorization: token,
      },
    });
    loadAssignations(productTemp);
    sessionStorage.setItem("productSelected", JSON.stringify(productTemp));
  };

  const downloadImages = () => {
    selectedImages.length > 0
      ? selectedImages.forEach((e) => {
          if (e.id) {
            saveAs(
              `https://${process.env.REACT_APP_IMAGES_BUCKET}.s3.amazonaws.com/${e.srcDB}`,
              `${product.upc || product.article.upc}_${e.name}.${e.ext}`
            );
          }
        })
      : images?.values?.forEach((e) => {
          if (e.id) {
            saveAs(
              `https://${process.env.REACT_APP_IMAGES_BUCKET}.s3.amazonaws.com/${e.srcDB}`,
              `${product.upc || product.article.upc}_${e.name}.${e.ext}`
            );
          }
        });
  };

  const deleteImages = () => {
    setLoading(true);
    const { values } = images;
    const imgsInBack = [];

    selectedImages.forEach((selectedImg) => {
      if (selectedImg.id) imgsInBack.push(selectedImg);
    });

    const imgsLeft = values.filter(
      (value) => selectedImages.indexOf(value) === -1
    );

    if (imgsInBack.length > 0) {
      const data = {
        articleId: product?.id_article || product?.article?.id_article,
        deleteImages: imgsInBack,
      };
      try {
        axios.put(
          `${process.env.REACT_APP_ARTICLE_DATA_ENDPOINT}?image=true&version=${version}`,
          data,
          {
            headers: { Authorization: token },
          }
        );
      } catch (err) {
        console.log(err);
      }
    }

    setImages({
      action: "deleteImage",
      selectedImages,
    });

    setTimeout(() => {
      setLoading(false);
    }, 500);

    setMessage("");
    setComponentsArray([]);
  };

  const askToDeleteImages = () => {
    if (selectedImages.length > 0) {
      setMessage("¿Está seguro de eliminar las imágenes seleccionadas?");
      setComponentsArray([
        <ScreenHeader
          key={"1"}
          text={"¿Está seguro de eliminar las imágenes seleccionadas?"}
          headerType="retailer-name-header"
          color={"white"}
        />,
        <Button
          key={"2"}
          buttonType="general-white-button"
          label={"Cancelar"}
          onClick={() => setMessage("")}
        />,
        <Button
          key={"3"}
          buttonType="general-button-default"
          label={"Aceptar"}
          onClick={() => {
            setMessage("");
            deleteImages();
          }}
        />,
      ]);
    }
  };

  const sendEvaluation = async (result) => {
    setLoading(true);
    const concept = getConcept(activeTab);
    const productTemp = product;
    const articleId = productTemp.id_article;
    const orderId = productTemp.id_order ?? productTemp.orderId;
    const sectionStatusKey = `${getConcept(activeTab)}_status`;
    const evalStatus = product[sectionStatusKey] || product?.version_status;
    let data = { articleId, orderId, concept, evalStatus };
    const retailerId = activeRetailer?.id;
    let res;
    try {
      if (result) {
        data.result = result;
        data.retailerId = retailerId;
        res = await axios.put(
          `${process.env.REACT_APP_EVALUATION_ENDPOINT}`,
          data,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        const newStatuses = JSON.parse(res.data.body);
        const orderStatus = newStatuses.newOrderStatus;
        const serviceStatus =
          newStatuses.newServiceStatus[articleId][
            `${getConcept(activeTab)}Status`
          ];

        const newProductEdit = productEdit;
        newProductEdit.product[sectionStatusKey] = serviceStatus;
        setProductEdit(newProductEdit);
        const showSurveyByProvider =
          !user.is_retailer && orderStatus[orderId] === "AP";
        const showSurveyByRetailer =
          user.is_retailer && orderStatus[orderId] === "ACA";
        showSurvey && showSurvey(showSurveyByProvider || showSurveyByRetailer);
      } else {
        res = await axios.put(`${process.env.REACT_APP_SEND_EVAL}`, data, {
          headers: {
            Authorization: token,
          },
        });
      }
      if (res.data.statusCode === 200) {
        const { newArticleStatus, newServiceStatus, newStatus } = JSON.parse(
          res.data.body
        );
        if (newArticleStatus) productTemp.status = newArticleStatus[articleId];
        const retailerStatusCopy = { ...retailerStatus };
        retailerStatusCopy[activeRetailer.id][concept] = newStatus;
        setRetailerStatus(retailerStatusCopy);
        productTemp.statusByRetailer = retailerStatusCopy;
        let status;
        if (newServiceStatus) {
          status = newServiceStatus[articleId][`${concept}Status`];
          productTemp[`${concept}_status`] = status;
        }
        setProduct(productTemp);
        sessionStorage.setItem("productSelected", JSON.stringify(productTemp));
        setOrigin((prev) => ({ ...prev, [concept]: status }));
      }
    } catch (err) {
      console.log(err);
    }
    loadData();
  };

  const validateAll = async (result) => {
    try {
      setLoading(true);
      const evaluationArray = [];
      const conceptArray = ["description", "datasheet", "images"];
      const dataGeneral = {
        articleId: product.id_article,
        orderId: product.id_order ?? product.orderId,
        result,
        isAproved: result === "A",
      };
      servicesData?.forEach((ret) => {
        const { service, id_retailer } = ret;
        let data = {};
        data = {
          ...dataGeneral,
          concept: service,
          retailerId: id_retailer,
          evalStatus: retailerStatus[id_retailer][service],
        };
        evaluationArray.push(
          axios.put(`${process.env.REACT_APP_EVALUATION_ENDPOINT}`, data, {
            headers: {
              Authorization: token,
            },
          })
        );
      });
      await Promise.all(evaluationArray);
      const userType = user.is_retailer === 1 ? "CA" : "P";

      const productTemp = product;
      const status = `${result}${userType}`;
      productTemp.article_status = status;
      productTemp.datasheet_status =
        productTemp.datasheet_status === "NA" ? "NA" : status;
      productTemp.description_status =
        productTemp.description_status === "NA" ? "NA" : status;
      productTemp.images_status =
        productTemp.images_status === "NA" ? "NA" : status;

      const retailerStatusCopy = { ...retailerStatus };
      Object.keys(retailerStatusCopy).forEach((key) => {
        conceptArray.forEach((concept) => {
          if (retailerStatusCopy[key][concept]) {
            retailerStatusCopy[key][concept] = status;
          }
        });
      });
      setRetailerStatus(retailerStatusCopy);
      productTemp.statusByRetailer = retailerStatusCopy;
      setProduct(productTemp);
      sessionStorage.setItem("productSelected", JSON.stringify(productTemp));
      setProductEdit({
        ArticleId: productTemp.id_article,
        idCategory: productTemp.article.id_category,
        product: productTemp,
      });
      sessionStorage.setItem(
        "productEdit",
        JSON.stringify({
          ArticleId: productTemp.id_article,
          idCategory: productTemp.article.id_category,
          product: productTemp,
        })
      );

      await loadData();
    } catch (error) {
      console.log(error);
    }
  };

  const evaluationToRetailer = async (result) => {
    const data = {
      articleId: product.id_article,
      companyId: user.is_retailer
        ? product.provider_company_id
        : product.retailer_company_id,
      versionId: product.version,
      status: result,
    };
    await axios.put(`${process.env.REACT_APP_RETAILER_REQUEST}`, data, {
      headers: {
        Authorization: token,
      },
    });
    const prod = productEdit;
    const statusComplete = user.is_retailer ? `${result}CA` : `${result}P`;
    prod.product.datasheet_status = statusComplete;
    prod.product.description_status = statusComplete;
    prod.product.images_status = statusComplete;
    prod.product.article_status = statusComplete;
    prod.product.services_status = `["${statusComplete}","${statusComplete}","${statusComplete}"]`;
    sessionStorage.setItem("productEdit", JSON.stringify(prod));
    setProduct(prod);
    showGlobalModal("generic");
    await loadData();
  };

  const getSectionStatus = () => {
    const concept = getConcept(activeTab);
    return ["AA", "AP", "R", "CA", "RCA"].includes(
      productEdit.product[`${concept}_status`]
    );
  };

  const enableActions = (versionStatus) => {
    try {
      if (versionStatus) {
        return ["AP", "ACA", "RCA", null].includes(versionStatus);
      }
      return true;
    } catch (err) {
      console.log(err, "Unable de check if user is retailer");
      return false;
    }
  };

  useEffect(() => {
    setSaving(loading);
  }, [loading]);

  // const slidefront = [
  //   {
  //     slide: Slide1_1,
  //     description:
  //       "Creamos tu contenido, y todo lo que haga falta para tu producto, cada sección puedes socilitar contenido enriquecido, y nosotros lo haremos por ti.",
  //   },
  //   {
  //     slide: Slide1_2,
  //     description:
  //       "Puedes agregar solicitudes de contenido enriquecido a tu carrito de compra las veces que sean necesarias. Una vez completada tu lista con los servicios necesitados sigue el proceso de compra en checkout.",
  //   },
  //   {
  //     slide: Slide1_3,
  //     description:
  //       "Revisa el contenido que deseas solicilitar, valida y tambien puedes agregar contenido extra, traducciones, o simplemente completar el checkout.",
  //   },
  //   {
  //     slide: Slide1_4,
  //     description:
  //       "Elige el tipo de entrega de tu producto, puedes ser recolección en el lugar o por el contrario puedes dejarlo en nuestras oficinas.",
  //   },
  //   {
  //     slide: Slide1_5,
  //     title: "Bienvenido al modo Content-oh!",
  //     description:
  //       "Finalmente elige la forma de pago que más te guste, procede con el pago y listo.",
  //   },
  // ];
  const sendToEvaluation = (result) => {
    if (result === "A") {
      if (
        origin[activeTab] === "RequestWithoutContentoh" &&
        !user.is_retailer &&
        (!product.id_order || !product.orderId)
      ) {
        setDataGenericModal((prev) => ({
          ...prev,
          button2: {
            name: "Continuar",
            action: () => evaluationToRetailer("A"),
          },
        }));
        showGlobalModal("generic");
      } else if (user.is_retailer) {
        if (product.id_order || product.orderId) {
          sendEvaluation("A");
        } else {
          setDataGenericModal((prev) => ({
            ...prev,
            button2: {
              name: "Continuar",
              action: () => evaluationToRetailer("A"),
            },
          }));
          showGlobalModal("generic");
        }
      } else {
        sendEvaluation("A");
      }
    } else {
      if (
        origin === "RequestWithoutContentoh" &&
        !user.is_retailer &&
        (!product.id_order || !product.orderId)
      ) {
        return;
      } else if (user.is_retailer) {
        if (product.id_order || product.orderId) {
          sendEvaluation("R");
        } else {
          setDataGenericModal((prev) => ({
            ...prev,
            button2: {
              name: "Continuar",
              action: () => evaluationToRetailer("R"),
            },
          }));
          showGlobalModal("generic");
        }
      } else {
        sendEvaluation("R");
      }
    }
  };

  return (
    <Container headerTop={headerTop}>
      <HeaderTop
        setHeaderTop={setHeaderTop}
        withChat={location?.state?.withChat}
        chatType={location?.state?.chatType}
        productSelected={product}
        token={token}
        activeRetailer={activeRetailer}
      />
      <div className="data-container">
        <div className="image-data-panel">
          <ImagePreviewer
            activeImage={images?.values ? images?.values[activeImage] : {}}
            imagesArray={images}
            setActiveImage={setActiveImage}
            setShowModal={setShowModal}
          />
          <ImageDataTable
            lists={images}
            activeImage={images?.values ? images?.values[activeImage] : {}}
            retailerSelected={activeRetailer?.id}
            setImages={setImages}
            assignationsImages={assig}
            imagesStatus={product?.images_status}
            setAssignation={setAssignation}
            isRetailer={isRetailer}
            onClickSave={() =>
              product?.services?.images === 1 && updateImages()
            }
            setShowVersionSelector={setShowVersionSelector}
            version={version}
          />
        </div>
        <div className="product-information">
          <FullProductNameHeader
            headerData={product}
            percent={activePercentage}
            activeRetailer={activeRetailer}
            setActiveRetailer={setActiveRetailer}
            showValidationButtons={isRevision() && getSectionStatus()}
            servicesData={servicesData ? servicesData : null}
            showApproveRejectAll={isRevision() && getSectionStatus()}
            approveAll={() => {
              if (
                origin === "RequestWithoutContentoh" &&
                !user.is_retailer &&
                (!product.id_order || !product.orderId)
              ) {
                setDataGenericModal((prev) => ({
                  ...prev,
                  button2: {
                    name: "Continuar",
                    action: () => evaluationToRetailer("A"),
                  },
                }));
                showGlobalModal("generic");
              } else if (user.is_retailer) {
                if (product.id_order || product.orderId) {
                  validateAll("A");
                } else {
                  setDataGenericModal((prev) => ({
                    ...prev,
                    button2: {
                      name: "Continuar",
                      action: () => evaluationToRetailer("A"),
                    },
                  }));
                  showGlobalModal("generic");
                }
              } else {
                validateAll("A");
              }
            }}
            rejectAll={() => {
              if (
                origin === "RequestWithoutContentoh" &&
                !user.is_retailer &&
                (!product.id_order || !product.orderId)
              ) {
                return;
              } else if (user.is_retailer) {
                if (product.id_order || product.orderId) {
                  setValidatedAll(true);
                  setShowRejectModal(true);
                } else {
                  setDataGenericModal((prev) => ({
                    ...prev,
                    button2: {
                      name: "Continuar",
                      action: () => evaluationToRetailer("R"),
                    },
                  }));
                  showGlobalModal("generic");
                }
              } else {
                setShowRejectModal(true);
                setValidatedAll(true);
              }
            }}
            approve={() => sendToEvaluation("A")}
            reject={() => {
              setShowRejectModal(true);
            }}
          />
          <FullTabsMenu
            tabsSections={tabsSections}
            status={
              activeRetailer?.id &&
              retailerStatus &&
              retailerStatus[activeRetailer.id]
                ? retailerStatus[activeRetailer?.id][getConcept(activeTab)] ||
                  "NS"
                : "-"
            }
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            setImageLayout={setImageLayout}
            downloadImages={downloadImages}
            askToDeleteImages={askToDeleteImages}
            assig={assig}
            setAssignation={setAssignation}
            isRetailer={isRetailer}
            showSaveButton={enableActions(product.version_status)}
            version={version}
            updatedDescriptions={updatedDescriptions}
            setUpdatedDescriptions={setUpdatedDescriptions}
            updatedDatasheets={updatedDatasheets}
            setUpdatedDatasheets={setUpdatedDatasheets}
            images={images}
            setImages={setImages}
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
            setShowVersionSelector={setShowVersionSelector}
            user={user}
            onClickSave={() => {
              switch (activeTab) {
                case "Descripción":
                  !saving && saveDescriptions();
                  break;
                case "Ficha técnica":
                  !saving && saveDatasheets();
                  break;
                case "Imágenes":
                  !saving && updateImages();
                  break;
                default:
                  break;
              }
            }}
          />
          <div
            className={
              "services-information-container " +
              (imageLayout && activeTab === "Imágenes" ? "image-services" : "")
            }
          >
            {loading ? (
              <Loading />
            ) : (
              <>
                {!imageLayout && activeTab === "Imágenes" && (
                  <GalleryHeader
                    setSelectedImages={setSelectedImages}
                    checkAll={checkAll}
                    setCheckAll={setCheckAll}
                  />
                )}
                {activeTab === "Ficha técnica" &&
                  (product?.datasheet_status !== "NS" ? (
                    <div>
                      {datasheets[0]?.data?.map((dataGroup, index) => (
                        <InputGroup
                          key={index + "-" + activeRetailer.name}
                          articleId={product.id_article}
                          version={version}
                          activeSection={activeTab}
                          inputGroup={dataGroup}
                          dataInputs={datasheets[1]}
                          updatedDatasheets={updatedDatasheets}
                          setUpdatedDatasheets={setUpdatedDatasheets}
                          isShowbox={true}
                          activeRetailer={services[0][activeRetailer.id]}
                          groupData={services[0][activeRetailer.id].data}
                          setUpdatedBoxData={(e) => {
                            setBoxData(e);
                          }}
                          index={index}
                          enableActions={enableActions(product.version_status)} //ADD THIS VALIDATION
                          updateApprovedInputs={updateApprovedInputs}
                        ></InputGroup>
                      ))}
                    </div>
                  ) : (
                    <ScreenHeader
                      text={"No cuentas con este servicio"}
                      headerType={"input-name-header"}
                    />
                  ))}
                {activeTab === "Descripción" &&
                  (product?.description_status !== "NS" ? (
                    <InputGroup
                      activeSection={activeTab}
                      inputGroup={descriptions[0]}
                      updatedDescriptions={updatedDescriptions}
                      setUpdatedDescriptions={setUpdatedDescriptions}
                      articleId={product?.id_article}
                      version={version}
                      isShowbox={false}
                      dinamicHeight={true}
                      updateApprovedInputs={updateApprovedInputs}
                    />
                  ) : (
                    <ScreenHeader
                      text={"No cuentas con este servicio"}
                      headerType={"input-name-header"}
                    />
                  ))}

                {activeTab === "Imágenes" &&
                  (product?.images_status !== "NS" ? (
                    <section className="container">
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <aside>{thumbs()}</aside>
                      </div>
                    </section>
                  ) : (
                    <ScreenHeader
                      text={"No cuentas con este servicio"}
                      headerType={"input-name-header"}
                    />
                  ))}
              </>
            )}
          </div>
          {isRevision() && getSectionStatus() ? (
            <div className="commentary-box">
              {/* Este lo comento porque la funcionalidad se tiene en el modal al momento de rechazar el producto */}
              {/* {!comment ? (
                <div className="commentary">
                  <TagAndInput
                    label={"Caja de Comentario"}
                    inputType={"textarea"}
                    inputCols={80}
                    inputRows={4}
                    inputId={"commentary-box"}
                    index={0}
                  />
                  <div className="buttons-box">
                    <Button
                      buttonType={"general-transparent-button"}
                      label={"Enviar comentario"}
                      onClick={(e) =>{
                        createComment(
                          e,
                          document.querySelector(
                            "#commentary-box .ql-container .ql-editor > p"
                          ).innerHTML,
                          activeTab
                        )
                      }
                      }
                    />
                  </div>
                </div>
              ) : (
                <div className="feedback-box">
                  <Commentary
                    comment={comment.message}
                    reviewed={crossComment}
                  />
                  <Button
                    buttonType={"circular-button accept-button"}
                    onClick={async () => {
                      setCrossComment(true);
                      commentRevised();
                    }}
                  />
                </div>
              )} */}
            </div>
          ) : (
            !revision && (
              <div className="required-inputs-message">
                <div>
                  <p>
                    Los atributos son requeridos por las plataformas de las
                    cadenas, es muy importante completar los campos requeridos
                    ya que pueden rechazar el producto por falta de información.
                  </p>
                </div>
                {inCart ? (
                  <button type="button">
                    <Link to="/checkout">
                      <p>Articulo en carrito</p>
                      <p>Ir a checkout</p>
                    </Link>
                  </button>
                ) : (
                  <></>
                )}
              </div>
            )
          )}
        </div>
      </div>
      {showModal && (
        <ProductImageModal
          images={images}
          setShowModal={setShowModal}
          approveRejectButtons={isRevision() && getSectionStatus()}
          sendToFacilitator={sendToEvaluation}
        />
      )}
      {message.length > 0 && !showSurvey && (
        <GenericModal
          buttonType={componentsArray.length > 0 && "delete-product"}
          componentsArray={
            componentsArray.length > 0
              ? componentsArray
              : [
                  <img key="1" src={succes} alt="success icon" />,
                  <ScreenHeader
                    key="2"
                    headerType={"retailer-name-header"}
                    text={message}
                    color={"white"}
                  />,
                ]
          }
          onClick={() => setMessage("")}
        />
      )}
      {globalModal && <GlobalModal close={closeGlobalModal} {...globalModal} />}
      {showVersionSelector && (
        <VersionSelector
          modalId={"version-selector"}
          articleId={product.id_article}
          setVersion={setVersion}
          companyName={company.company_name}
          currentVersion={version}
          setShowVersionSelector={setShowVersionSelector}
          jwt={token}
        />
      )}
      {showRejectModal && (
        <Modal
          title={"Agregar mensaje de rechazo"}
          show={showRejectModal}
          customComponent={
            <TagAndInput
              inputType={"textarea"}
              inputId={"modal-message-box"}
              index={0}
              color={"white"}
            />
          }
          buttons={[
            <ButtonV2
              key={"btn-Cancelar"}
              type={"white"}
              label={"Cancelar"}
              size={12}
              onClick={() => {
                setShowRejectModal(false);
              }}
            />,
            <ButtonV2
              key={"btn-Aceptar"}
              type={"pink"}
              label={"Aceptar"}
              size={12}
              onClick={async () => {
                const body = document.querySelector(
                  "#modal-message-box .ql-container .ql-editor > p"
                ).innerHTML;
                const messages = [
                  { type: "message", value: body?.replace(/<.*?\/?>/gm, "") },
                ];
                await createComment(messages[0].value);
                validatedAll ? validateAll("R") : sendToEvaluation("R");
                setShowRejectModal(false);
              }}
            />,
          ]}
        />
      )}
    </Container>
  );
};