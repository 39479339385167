import styled from "styled-components";

export const TableContainer = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.015em;
  color: var(--gray-contentoh);
  width: 100%;
  overflow-x: auto;

  .text_center {
    text-align: center;
  }
  .table-scroll {
    overflow-x: auto; 
    max-width: 100%;   
  }
  .space {
    justify-content: space-between;
  }
  .align_end {
    justify-content: flex-end;
  }
  .margin_row {
    .col.chk-cont {
      display: flex;
      justify-content: flex-start;
      & > div {
        & + * {
          margin-left: 10px;
        }
      }
    }
    .col + * {
      margin-left: 10px;
    }
  }
  .column {
    display: flex;
    flex-direction: column;

    p {
      color: #817393;

      & + * {
        margin-top: 5px;
      }
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    overflow: hidden; 

    .col {
      flex: 1;
      align-items: center;
      overflow: scroll;  

      figure {
        & + * {
          margin-left: 10px;
        }
      }

      &:first-child {
        padding: 10px;
        align-items: center;
        overflow: inherit;
      }
    }
    .gap-10 {
      gap: 10px;
    }

    &.header {
      background-color: var(--blue-light);
      height: 34px;
      border-radius: 5px 5px 0 0;
      font-style: normal;
      font-weight: 600;
      color: var(--black);
      

      .col {
        color: var(--black);
        text-align: left;
        overflow: hidden;
      }
      .gap-10 {
        gap: 10px;
      }
      .text_center {
        text-align: center;
      }
    }
  }
  .center {
    justify-content: center;
    align-items: center;
  }
  input[type='email'] {
    width: 360px;
    height: 30px;
    border: 1px solid #f0eef2;
    box-sizing: border-box;
    border-radius: 7px;
    margin-right: 20px;
    ::placeholder {
      color: #d4d1d7;
    }
  }
  input[type='checkbox']:checked {
    width: 75px;
    background-color: var(--pink-contentoh);
    color: green;
  }
  select {
    width: 156px;
    height: 30px;
    padding: 0 8px;
    border: 1px solid var(--pink-contentoh);
    border-radius: 7px;
    box-sizing: border-box;
    background: #fff;
  }
  .no-users {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    padding: 5px;
    color: var(--gray-contentoh);
    width: 100%;
  }
  button {
    width: 24px;
    height: 24px;
  }
`;

