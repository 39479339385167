import { useEffect, useRef, useState } from "react";
import { Fade, Grow, Popper, Tooltip as TooltipMUI, Zoom } from "@mui/material";
import { Container } from "./styles";
import React from "react";

export const Tooltip = (props) => {
  const {
    componentTooltip, // (jsx) componente que aparecera en el tooltip
    children, // (jsx) componente interno que va dentro de la etiqueta <Tooltip>
    className, // (string) clase del container trigger
    classNameTooltip, // (string) clase del contenedor tooltip
    position, // (string) posicion del contenedor tooltip (values en object positions)
    addArrow, // (boolean) add flecha en la parte posterior del tooltip?
    transitionType, // (string) -> 'grow' (default) | 'fade' | 'zoom'
    followCursor, //  (boolean) true -> el tooltip se movera junto al cursor
  } = props;
  const positions = {
    topStart: "top-start",
    topEnd: "top-end",
    topCenter: "top",

    rightStart: "right-start",
    rightEnd: "right-end",
    rightCenter: "right",

    bottomStart: "bottom-start",
    bottomEnd: "bottom-end",
    bottomCenter: "bottom",

    leftStart: "left-start",
    leftEnd: "left-end",
    leftCenter: "left",
  };

  return (
    <TooltipMUI
      {...props}
      placement={positions[position] ?? positions.topCenter}
      arrow={addArrow || addArrow === undefined ? true : false}
      componentsProps={{
        tooltip: { className: classNameTooltip },
        popper: { disablePortal: true },
        transition: { timeout: 300 },
      }}
      TransitionComponent={
        transitionType === "grow"
          ? Grow
          : transitionType === "fade"
          ? Fade
          : Zoom
      }
      enterDelay={300}
      followCursor={followCursor ?? false}
      title={componentTooltip}
    >
      <Container className={className}> {children} </Container>
    </TooltipMUI>
  );
};
