import { Container } from "./styles";
import { EditionActiveImage } from "../../molecules/EditionActiveImage";
import { ImageSelector } from "../../molecules/ImageSelector";

export const ImagePreviewer = ({
  imagesArray = [],
  activeImage,
  setActiveImage,
  setShowModal,
}) => {
  return (
    <Container>
      <EditionActiveImage
        img={activeImage?.src}
        altText={activeImage?.name}
        setShowModal={setShowModal}
      />
      <ImageSelector images={imagesArray} setActiveImage={setActiveImage} />
    </Container>
  );
};
