import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

export const Container = styled.div`
  height: 100%;
  box-sizing: border-box;
  padding: 23px 0 70px 0;
  flex-direction: column;
  justify-content: space-between;
  overflow: none;
  width: ${({ bar }) => (bar ? "240px" : "66px")};
  background: linear-gradient(180deg, var(--pink-contentoh) 0%, #3b1366 100%);
  scrollbar-width: none;
  display: flex;
  text-align: center;
  transition: width 0.3s linear;
  margin: 0;
  .navbar-top {
    .logo {
      height: ${({ bar }) => (bar ? "22px" : "44px")};
    }
    .option {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 50px;
      
      .option-div {
        width: 100%;
        white-space: nowrap;
      }
      .active {
        text-decoration: none;
        background: rgba(255, 255, 255, 0.1);
      }
      .sub-option {
        display: flex;
        flex-direction: column;
        padding-left: 32px;
        height: auto;
        width: auto;
        &:last-child {
          margin-bottom: 10px;
        }
      }
      span {
        color: var(--gray-light);
        font-size: 27px;
      }
      .name{
        display: flex;
        align-items: center;
        gap: 5px;
        text-transform: capitalize;
        color: var(--white-contentoh);
        font-size: 17px;
      }
    }
  }
`;

export const NavLinkOption = styled(NavLink)`
  height: 42px;
  display: flex;
  justify-content: ${({ bar }) => (bar ? "flex-start" : "center")};
  align-items: center;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: -0.015em;
  transition: color 0.3s linear;
  color: ${({ bar }) => (bar ? "#fafafa" : "transparent")};
  text-decoration: none;
  cursor: pointer;
  padding: ${({ bar }) => (bar ? "0 22px" : "0 8px")};

  &:hover {
    background: rgba(255, 255, 255, 0.2);
    color: #fafafa;
    text-decoration: none;
  }

  p {
    cursor: pointer;
    margin: 0;
    pointer-events: none;
  }

  img {
    height: 22px;
    margin-right: 10px;
  }

  &.sub-option-link {
    display: flex;
    justify-content: flex-start;
    text-decoration: none;
    position: relative;
    font-size: 13px;
    border-left: 1px solid #f0eef2;
    border-radius: 0;
    transition: color 0.3s linear;
    color: ${({ bar }) => (bar ? "#f0eef2" : "transparent")};
    height: 25px;
    margin: 0;
    padding-left: 12px;

    &::before {
      border-left: 1px solid var(--pink-contentoh);
      width: 10px;
      content: "";
      border-radius: 50%;
      height: 10px;
      background-color: white;
      position: absolute;
      top: 13;
      left: -6px;
    }

    &.active {
      background: none !important;
      text-decoration: none;
      transition: color 0.3s linear;
      color: ${({ bar }) => (bar ? "#fafafa" : "transparent")};

      &::before {
        background-color: var(--pink-contentoh);
      }
    }

    &:hover {
      background: none;
    }
  }
`;


export const ContainerPrincipal = styled.div`
  width: 100%;
  display: flex;
  height: 100vh;
  gap: 20px;
`;

export const GlobalStyle = createGlobalStyle`

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, figure, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
:focus {
  outline: 0;
}
ins {
  text-decoration: none;
}
del {
  text-decoration: line-through;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

`;