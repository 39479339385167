import axios from "axios";
import { saveAs } from "file-saver";
export const getExportables = async (
    flagUpdateFunction,
    reportFilters,
    aditionalFilter,
    addNotification
  ) => {
    //flagUpdateFunction((prev) => ({ ...prev, loadingExportables: true }));
    try {
      const defaultRetailers = JSON.parse(
        sessionStorage.getItem("company")
      ).retailers.map((retailer) => retailer.id);
  
      const email = encodeURIComponent(
        JSON.parse(sessionStorage.getItem("user")).email
      );
      let reportFiltersCopy = reportFilters ?? {
        filterByRetailer: defaultRetailers,
      };
      if (!reportFiltersCopy.filterByRetailer) {
        reportFiltersCopy.filterByRetailer = defaultRetailers;
      }
      const filtersForQuery = encodeURIComponent(JSON.stringify(reportFilters));
    //   const jsFilter = aditionalFilter
    //     ? `&jsFilter=${encodeURIComponent(JSON.stringify(aditionalFilter))}`
    //     : "";
    // ${process.env.REACT_APP_REPORTS}?email=${email}&filters=${filtersForQuery}&reportType=2${jsFilter}`,
      const response = await axios.get(
        `${process.env.REACT_APP_REPORTS}?email=${email}&filters=${filtersForQuery}&reportType=2`,
        {
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        }
      );
      saveAs(JSON.parse(response.data.body).url);
      addNotification('success','El reporte ha sido enviado a su correo')
    } catch (error) {
      console.log(error);
      addNotification('error','Error al enviar reporte')
    }
    flagUpdateFunction((prev) => ({ ...prev, loadingExportables: false }));
  };

  export const getExportablesProvider = async (setLoadingExportables, addNotification) => {
    setLoadingExportables(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_EXPORTABLES}`, {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      });
      saveAs(JSON.parse(response.data.body).url);
      addNotification('success','El reporte ha descargado correctamente')
      setLoadingExportables(false);
    } catch (error) {
      console.log(error);
      addNotification('error','Error al descargar reporte')
    }
  };