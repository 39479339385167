import React from 'react';
// import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Container } from "./styles";

export const CheckBox = ({
  id,
  label,
  onChange,
  isFilter,
  defaultChecked,
  disabled = false,
  checked,
  typeColor,
  className = "",
}) => {
  return (
    <Container key={`check-${id}`} className={className} typeColor={typeColor}>
      <input
        type="checkbox"
        name={id}
        id={id}
        onChange={onChange}
        defaultChecked={defaultChecked}
        disabled={disabled}
        checked={checked}
      />
      <label htmlFor={id}>
        {label && <p className={isFilter && "filter-text"}>{label}</p>}
        
      </label>
    </Container>
  );
};
