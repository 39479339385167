import React, { useState, useEffect } from "react";
import PendingInvitations from "./PendingInvitations";
import ActiveProviders from "./ActiveProviders";

const InviteProvider = (props) => {
  const token = sessionStorage.getItem("jwt");
  const user = JSON.parse(sessionStorage.getItem("user"));
const isRetailer = user && user.is_retailer === 1;

  return (
    <div>
      {props.tabActive === "Proveedores activos" ? (
        <ActiveProviders token={token} tabActive={props.tabActive} isRetailer={isRetailer}/>
      ) : (
        <PendingInvitations token={token} tabActive={props.tabActive} isRetailer={isRetailer}/>
      )}
    </div>
  );
};

export default InviteProvider;
