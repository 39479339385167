import React, { useState } from "react";
import Modal from "../../general/Modal";
import { MainContainer, FlowsContainer } from "./styles";
import { DragAndDropPhases } from "./DragAndDropPhases";

const Flows = (props) => {
  const token = sessionStorage.getItem("jwt");
  const [modalData, setModalData] = useState({ 
    show: false,
    title: "Actualización completa",
    message: "",
    icon: "success",
  });

  return (
    <>
      <MainContainer>
        <FlowsContainer>
          <DragAndDropPhases token={token}></DragAndDropPhases>
        </FlowsContainer>
        <Modal {...modalData}
            onClickBtnDefault={() =>
              setModalData((prev) => ({ ...prev, show: false }))
            }></Modal>
      </MainContainer>
    </>
  );
};

export default Flows;
