import React, { useState } from "react";
import {
  FormularioRegister,
  Row,
  ItemRegister,
  RowButton,
  Button,
} from "../styles";
import ErrorLabel from "../../general/ErrorLabel";
import Logo from "../../general/Logo";
import { signUp } from "../utils/AuthService";

const RegistrationCompany = ({ onNext }) => {
  const [commercialName, setCommercialName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [rfc, setRfc] = useState("");
  const [fiscalAddress, setFiscalAddress] = useState("");
  const [showErrors, setShowErrors] = useState(false);

  const [emptyCommercialName, setEmptyCommercialName] = useState(true);
  const [emptyCompanyName, setEmptyCompanyName] = useState(true);
  const [emptyBusinessName, setEmptyBusinessName] = useState(true);
  const [emptyRfc, setEmptyRfc] = useState(true);
  const [emptyFiscalAddress, setEmptyFiscalAddress] = useState(true);

  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowErrors(true);
    if (
      !emptyCommercialName &&
      !emptyCompanyName &&
      !emptyBusinessName &&
      !emptyRfc &&
      !emptyFiscalAddress
    ) {
      setLoading(true);
      const companyData = {
        commercialName: commercialName,
        companyName: companyName,
        businessName: businessName,
        rfc: rfc,
        adress: fiscalAddress,
      };
      onNext(companyData);
    }
  };

  return (
    <FormularioRegister onSubmit={handleSubmit}>
      <Logo />
      <div className="container-register">
        <p className="title">Creación de compañía</p>
        <Row>
          <label>Nombre comercial</label>
          <ItemRegister>
            <input
              required
              id="commercialNameInput"
              placeholder="Escribe el nombre comercial"
              type="text"
              value={commercialName}
              onChange={(e) => {
                setCommercialName(e.target.value);
                setEmptyCommercialName(e.target.value.trim() === "");
              }}
            />
          </ItemRegister>
        </Row>
        <ErrorLabel
          showErrors={showErrors}
          condition={emptyCommercialName}
          message="Ingrese el nombre comercial"
        />
        <Row>
          <label>Nombre de compañía</label>
          <ItemRegister>
            <input
              required
              id="companyNameInput"
              placeholder="Escribe el nombre de compañía"
              type="text"
              value={companyName}
              onChange={(e) => {
                setCompanyName(e.target.value);
                setEmptyCompanyName(e.target.value.trim() === "");
              }}
            />
          </ItemRegister>
        </Row>
        <ErrorLabel
          showErrors={showErrors}
          condition={emptyCompanyName}
          message="Ingrese el nombre de la compañía"
        />
        <Row>
          <label>Razón social</label>
          <ItemRegister>
            <input
              required
              id="businessNameInput"
              placeholder="Escribe la razón social"
              type="text"
              value={businessName}
              onChange={(e) => {
                setBusinessName(e.target.value);
                setEmptyBusinessName(e.target.value.trim() === "");
              }}
            />
          </ItemRegister>
        </Row>
        <ErrorLabel
          showErrors={showErrors}
          condition={emptyBusinessName}
          message="Ingrese la razón social"
        />
        <Row>
          <label>RFC</label>
          <ItemRegister>
            <input
              required
              id="rfcInput"
              placeholder="Escribe el RFC"
              type="text"
              value={rfc}
              onChange={(e) => {
                setRfc(e.target.value);
                setEmptyRfc(e.target.value.trim() === "");
              }}
            />
          </ItemRegister>
        </Row>
        <ErrorLabel
          showErrors={showErrors}
          condition={emptyRfc}
          message="Ingrese el RFC"
        />
        <Row>
          <label>Dirección fiscal</label>
          <ItemRegister>
            <input
              required
              id="fiscalAddressInput"
              placeholder="Escribe la dirección fiscal"
              type="text"
              value={fiscalAddress}
              onChange={(e) => {
                setFiscalAddress(e.target.value);
                setEmptyFiscalAddress(e.target.value.trim() === "");
              }}
            />
          </ItemRegister>
        </Row>
        <ErrorLabel
          showErrors={showErrors}
          condition={emptyFiscalAddress}
          message="Ingrese la dirección fiscal"
        />
        <RowButton>
          <Button type="submit" onClick={handleSubmit}>
            Siguiente
          </Button>
        </RowButton>
      </div>
      <Row>
        <label className="loginLabel">
          ¿Ya tienes cuenta?
          <label className="login">
            Inicia sesión aquí
          </label>
        </label>
      </Row>
    </FormularioRegister>
  );
};

export default RegistrationCompany;
