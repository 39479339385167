import styled from "styled-components";
import { FontFamily, GlobalColors } from "../../../global-files/variables";

export const Container = styled.div`
  width: fit-content;
  padding: 0 10px;
  height: 20px;
  background-color: ${GlobalColors.s3};
  border-radius: 3px;

  p {
    text-align: center;
    color: ${GlobalColors.white};
    font-family: ${FontFamily.Lato};
    font-size: 12px;
    line-height: 20px;
  }

  &.status-CA,
  &.status-IE {
    background-color: ${GlobalColors.in_progress};
  }

  &.status-R,
  &.status-AS,
  &.status-SAC,
  &.status-PA {
    background-color: ${GlobalColors.reception};
  }

  &.status-AA,
  &.status-AP,
  &.status-AC,
  &.status-AAC,
  &.status-ACA {
    background-color: ${GlobalColors.finished};
  }

  &.status-RA,
  &.status-RC,
  &.status-RP,
  &.status-RAC,
  &.status-RCA {
    background-color: ${GlobalColors.rejected_status};
  }

  &.status-Dat,
  &.status-Dsc,
  &.status-Imgs {
    background-color: ${GlobalColors.s4};
  }

  &.status-FAP,
  &.status-Ex {
    background-color: ${GlobalColors.exported};
  }

  &.status-DDI {
    background-color: ${GlobalColors.original_purpura};
  }

  &.status-GLD {
    background-color: ${GlobalColors.in_progress};
  }

  &.status-TAB {
    background-color: ${GlobalColors.deep_gray};
  }

  &.status-Pt {
    background-color: ${GlobalColors.s2};
    color: ${GlobalColors.s4};
  }

  &.oval-form {
    border-radius: 10px;
  }
`;
