import styled from "styled-components";
import { GlobalColors, FontFamily } from "../../../global-files/variables";

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 20px;
  padding: 0 5px;
  box-sizing: border-box;
  background: #fafafa;
  border-radius: 2px;
  border: ${(props) => props.error && "1px solid red"};
`;

export const InputCustom = styled.input`
  border-width: 0;
  width: 100%;
  font-family: ${FontFamily.AvenirNext};
  font-weight: 400;
  font-size: 11px;
  line-height: 19px;
  border-width: 0;
  color: ${GlobalColors.s5};
  background-color: ${GlobalColors.s2};
  outline: none;
  &::placeholder {
    font-size: 12px;
    color: #d4d1d7;
  }
`;
