import ReactHowler from "react-howler";
import { Container } from "./styles";
import { useEffect, useState } from "react";
import { NotFound } from "../../../atoms/NotFound";
import { Header } from "../Header";
import { faComments as IconChat } from "@fortawesome/free-solid-svg-icons";
import { Loading } from "../../../atoms/Loading";
import { ContainerItems } from "../ContainerItems";
import { Footer } from "../Footer";
import { ChatLists } from "../ChatLists";
import { CustomIcon } from "../../../atoms/CustomIcon";
import { fetchGET, fetchPOST } from "../../../../global-files/handle_http";
import {
  encodeUriJson,
  isStringEmpty,
  isValidNaturalNumber,
} from "../../../../global-files/utils";
import newMessage from "../../../../assets/sounds/newMessage.mp3";

export const ContentChat = (props) => {
  const {
    chatType, // "merchant_product" | "order_product" | "ticket"
    chatContainerType, // "fixed" | "popUp"
    dataChat, // { data | error }
    showBtnClose, // boolean
    onClickBtnClose, // () => {}
    showPopUpChat, // boolean
    ticketCompany, // id de la compañía que creó el ticket
    activeRetailer, // object retailer
  } = props;
  const [companies, setCompanies] = useState({}); /* {
    companyId: {
      name: string,
      statusChat: string  => current | open | closed
      items: [{..}] ,
      enabledLoadMore: boolean
    },
    ...
  } chat orderProduct */
  const [singleChat, setSingleChat] = useState({
    items: undefined,
    enabledLoadMore: false,
    statusChat: undefined,
  }); // chat merchantProduct o ticket
  const [activeCompanyId, setActiveCompanyId] = useState(); // number
  const [allUsers, setAllUsers] = useState({}); // {...}
  const [currentUser, setCurrentUser] = useState({
    id: undefined,
    companyId: undefined,
  });
  const [currentArticle, setCurrentArticle] = useState({
    status: undefined,
    version: undefined,
  });
  const [lastUpdateDate, setLastUpdateDate] = useState(); // string
  const [isLoading, setIsLoading] = useState(true); // boolean
  const [showChatsList, setShowChatsList] = useState(false); // boolean
  const [processUpdateID, setProcessUpdateID] = useState(); // number
  const [startUpdate, setStartUpdate] = useState(0); // number
  const [runUpdate, setRunUpdate] = useState(0); // number
  const [errorChat, setErrorChat] = useState({
    existError: false,
    code: undefined,
    message: undefined,
    errorDetail: undefined,
  });
  const [errorUpdate, setErrorUpdate] = useState({
    startDate: undefined, // Date()
    lastDate: undefined, // Date()
  });
  const [playSound, setPlaySound] = useState(false);

  // matar update al desmontar el componente del chat
  useEffect(() => {
    return () => {
      if (processUpdateID !== undefined) clearTimeout(processUpdateID);
    };
  }, []);

  const stopUpdate = () => {
    if (processUpdateID !== undefined) {
      clearTimeout(processUpdateID);
      setProcessUpdateID(undefined);
    }
  };

  // al cargar el componente
  useEffect(() => {
    //if (dataChat && showPopUpChat) {
    if (Object.keys(dataChat || {}).length) {
      stopUpdate();
      if (dataChat.code) {
        setErrorChat({
          existError: true,
          code: dataChat.code,
          message: dataChat.message,
          errorDetail: dataChat.errorDetail,
        });
        setIsLoading(false);
        return;
      }
      if (chatType === "merchant_product") getInitialMerchantProduct();
      else if (chatType === "order_product") getInitialOrderProduct();
      else if (chatType === "product_status") getInitialProductStatus();
      else getInitialTicket();
    }
    if (!showPopUpChat) {
      stopUpdate();
    }
  }, [dataChat, showPopUpChat]);

  // comenzar temporizador del update de 20s
  useEffect(() => {
    // return; // -- descomentarizar para hacer pruebas sin update
    if (startUpdate === 0) return;
    const processID = setTimeout(() => {
      setRunUpdate((prev) => prev + 1);
    }, 15000);
    setProcessUpdateID(processID);
    return () => clearTimeout(processID);
  }, [startUpdate]);

  // ejecutar update
  useEffect(() => {
    if (chatType === "merchant_product") {
      if (singleChat.items) getUpdateLatestMerchantProduct(true);
      else getInitialMerchantProduct();
    } else if (chatType === "order_product") {
      if (companies) getUpdateLatestOrderProduct(true);
      else getInitialOrderProduct();
    } else if (chatType === "product_status") {
      getInitialProductStatus();
    } else {
      if (singleChat.items) getUpdateLatestTicket(true);
      else getInitialTicket();
    }
  }, [runUpdate]);

  /*=======================================================================
                PETICION GET INICIAL SEGUN EL TIPO DE CHAT
  ======================================================================= */
  const getInitialProductStatus = async () => {
    const paramsQuery = {
      articleData: JSON.stringify({
        articleId: JSON.stringify(dataChat.id),
        version: JSON.stringify(dataChat.version),
        retailerId: JSON.stringify(dataChat.retailerId),
        status: dataChat.status,
      })
    };
    const paramsHeaders = { Authorization: dataChat.userToken };
    const response = await fetchGET(
      process.env.REACT_APP_READ_MESSAGES,
      paramsQuery,
      paramsHeaders
    );
    if (!response.body) {
      setErrorChat({
        existError: true,
        code: 400,
        message: response.message,
        errorDetail: response.errorDetail,
      });
      setIsLoading(false);
      return;
    }
    // success
    setStartUpdate((prev) => prev + 1);
    setCurrentUser({
      id: response.body.data.currentUserId,
      companyId: response.body.data.currentCompanyId,
    });
    setLastUpdateDate(response.body.data.lastUpdateDate);
    setAllUsers(response.body.users);
    setSingleChat({
      items: response.body.items,
      enabledLoadMore: response.body.items.length === 50,
    });
    setErrorChat({ existError: false });
    setIsLoading(false);
  };

  const getInitialMerchantProduct = async () => {
    const paramsQuery = {
      getType: "initial",
      id: JSON.stringify(dataChat.id),
      version: JSON.stringify(dataChat.version),
    };
    const paramsHeaders = { Authorization: dataChat.userToken };
    const response = await fetchGET(
      process.env.REACT_APP_PRODUCTS_CHAT_ENDPOINT,
      paramsQuery,
      paramsHeaders
    );
    if (!response.body) {
      setErrorChat({
        existError: true,
        code: 400,
        message: response.message,
        errorDetail: response.errorDetail,
      });
      setIsLoading(false);
      return;
    }
    // success
    setStartUpdate((prev) => prev + 1);
    setCurrentUser({
      id: response.body.data.currentUserId,
      companyId: response.body.data.currentCompanyId,
    });
    setLastUpdateDate(response.body.data.lastUpdateDate);
    setAllUsers(response.body.users);
    setSingleChat({
      items: response.body.items,
      enabledLoadMore: response.body.items.length === 50,
    });
    setErrorChat({ existError: false });
    setIsLoading(false);
  };

  const getInitialOrderProduct = async () => {
    clearTimeout(processUpdateID); // finalizamos el temporizador
    const paramsQuery = {
      getType: "initial",
      id: JSON.stringify(dataChat.id),
      orderId: JSON.stringify(dataChat.orderId),
      retailerId: JSON.stringify(dataChat.retailerId),
    };
    const paramsHeaders = { Authorization: dataChat.userToken };
    const response = await fetchGET(
      process.env.REACT_APP_PRODUCTS_CHAT_ENDPOINT,
      paramsQuery,
      paramsHeaders
    );
    setStartUpdate((prev) => prev + 1); // TEMPORAL
    if (!response.body) {
      setErrorChat({
        existError: true,
        code: 400,
        message: response.message,
        errorDetail: response.errorDetail,
      });
      setIsLoading(false);
      return;
    }
    // success
    setCurrentUser({
      id: response.body.data.currentUserId,
      companyId: response.body.data.currentCompanyId,
    });
    setLastUpdateDate(response.body.data.lastUpdateDate);
    setCurrentArticle({
      status: response.body.data.status,
      version: response.body.data.version,
    });
    setAllUsers(response.body.users);
    const companiesList = response.body.companies;
    const companiesIdList = Object.keys(companiesList);
    let companyActive;
    // encontrar el chat inicial a cargar y si se debe permitir cargar mas
    for (const companyId of companiesIdList) {
      if (companiesList[companyId].items.length < 50) {
        companiesList[companyId].enabledLoadMore = false;
      } else {
        companiesList[companyId].enabledLoadMore = true;
      }

      if (companiesList[companyId].statusChat === "current") {
        companyActive = Number(companyId);
      }
    }
    if (!companyActive && companiesIdList.length) {
      companyActive = Number(companiesIdList[0]);
    }
    setCompanies(companiesList);
    if (companyActive) setActiveCompanyId(companyActive);
    setErrorChat({ existError: false });
    setIsLoading(false);
  };

  const getInitialTicket = async () => {
    stopUpdate(); // finalizamos el temporizador
    const dataUser = {
      id: dataChat.currentUser?.id,
      companyId: dataChat.currentUser?.companyId,
      isUserTech: dataChat.currentUser?.isUserTech,
    };
    const paramsQuery = {
      //id: JSON.stringify(dataChat.id),
      id: dataChat.id,
      getType: "initial",
      //currentUser: encodeUriJson(dataUser),
      currentUser: encodeURIComponent(JSON.stringify(dataUser)),
      currentItemsFront: encodeUriJson(getItemsIdSingleChat()),
    };
    const response = await fetchGET(
      process.env.REACT_APP_TICKETS_CHAT_ENDPOINT,
      paramsQuery,
      { Authorization: dataChat.currentUser?.token }
    );
    // programar update en 20s
    setStartUpdate((prev) => prev + 1);
    if (!response.body) {
      setErrorChat({
        existError: true,
        code: 400,
        message: response.message,
        errorDetail: response.errorDetail,
      });
      setIsLoading(false);
      return;
    }
    // success
    setLastUpdateDate(response.body.data.lastUpdateDate);
    setAllUsers(response.body.users);
    setSingleChat({
      items: response.body.items,
      enabledLoadMore: response.body.items.length === 50,
      statusChat: dataChat.statusChat,
    });
    setErrorChat({ existError: false });
    setIsLoading(false);
  };

  /*=======================================================================
                PETICION GET LOAD_MORE SEGUN EL TIPO DE CHAT
  ======================================================================= */
  const getLoadMoreMerchantProduct = async () => {
    clearTimeout(processUpdateID); // finalizamos el temporizador
    let date = singleChat.items[singleChat.items.length - 1].date;
    const paramsQuery = {
      getType: "loadMore",
      id: JSON.stringify(dataChat.id),
      version: JSON.stringify(dataChat.version),
      date: encodeURIComponent(date),
    };
    const response = await fetchGET(
      process.env.REACT_APP_PRODUCTS_CHAT_ENDPOINT,
      paramsQuery
    );
    //setStartUpdate((prev) => prev + 1); // comendar un nuevo temporizador
    if (!response.body) {
      return {
        title: response.message,
        message: response.errorDetail,
      };
    }
    // success
    // actualizar la lista de los users
    updateAllUsers(response.body.users);
    // actualizar los items del chat
    let newSingleChat = { ...singleChat };
    newSingleChat.items = addOldItemsChat(
      newSingleChat.items,
      response.body.items
    );
    newSingleChat.enabledLoadMore = response.body.items.length === 50;
    setSingleChat(newSingleChat);
  };

  const getLoadMoreOrderProduct = async () => {
    clearTimeout(processUpdateID); // finalizamos el temporizador
    let company = companies[activeCompanyId];
    let date = company.items[company.items.length - 1].date;
    const paramsQuery = {
      getType: "loadMore",
      id: JSON.stringify(dataChat.id),
      orderId: JSON.stringify(dataChat.orderId),
      version: JSON.stringify(currentArticle.version),
      currentCompanyId: JSON.stringify(currentUser.companyId),
      date: encodeURIComponent(date),
      othersCompanyId: JSON.stringify([activeCompanyId]),
    };
    const response = await fetchGET(
      process.env.REACT_APP_PRODUCTS_CHAT_ENDPOINT,
      paramsQuery
    );
    //setStartUpdate((prev) => prev + 1); // comendar un nuevo temporizador
    if (!response.body) {
      return {
        title: response.message,
        message: response.errorDetail,
      };
    }
    // success
    // actualizar la lista de los users
    updateAllUsers(response.body.users);
    // actualizar los items del chat y la lista de users
    company.items = addOldItemsChat(company.items, response.body.items);
    company.enabledLoadMore = response.body.items.length === 50;
    setCompanies((prev) => ({
      ...prev,
      [activeCompanyId]: company,
    }));
  };

  // este GET obtiene la lista de compañoas con las que se puede chatear
  const getLoadMoreTicket = async () => {
    stopUpdate(); // finalizamos el temporizador
    let date = singleChat.items[singleChat.items.length - 1].date;
    const dataUser = {
      id: dataChat.currentUser?.id,
      companyId: dataChat.currentUser?.companyId,
      isUserTech: dataChat.currentUser?.isUserTech,
    };
    const paramsQuery = {
      id: JSON.stringify(dataChat.id),
      getType: "loadMore",
      date: encodeURIComponent(date),
      currentUser: encodeUriJson(dataUser),
      currentItemsFront: encodeUriJson(getItemsIdSingleChat()),
    };
    const response = await fetchGET(
      process.env.REACT_APP_TICKETS_CHAT_ENDPOINT,
      paramsQuery,
      { Authorization: dataChat.currentUser?.token }
    );
    //programar update en 20s
    setStartUpdate((prev) => prev + 1);
    if (!response.body) {
      return {
        title: response.message,
        message: response.errorDetail,
      };
    }
    // success
    // actualizar la lista de los users
    updateAllUsers(response.body.users);
    // actualizar los items del chat
    let newSingleChat = { ...singleChat };
    newSingleChat.items = addOldItemsChat(
      newSingleChat.items,
      response.body.items
    );
    newSingleChat.enabledLoadMore = response.body.items.length === 50;
    setSingleChat(newSingleChat);
  };

  /*=======================================================================
                PETICION GET UPDATE_LATEST SEGUN EL TIPO DE CHAT
  ======================================================================= */
  const getUpdateLatestMerchantProduct = async (fromUpdate = false) => {
    const paramsQuery = {
      getType: "updateLatest",
      id: JSON.stringify(dataChat.id),
      version: JSON.stringify(dataChat.version),
      date: encodeURIComponent(lastUpdateDate),
    };
    const paramsHeaders = { Authorization: dataChat.userToken };
    const response = await fetchGET(
      process.env.REACT_APP_PRODUCTS_CHAT_ENDPOINT,
      paramsQuery,
      paramsHeaders
    );
    if (!response.body) {
      console.log("ERROR updateLatest:", {
        message: response.message,
        errorDetail: response.errorDetail,
      });
      return;
    }
    // success
    setStartUpdate((prev) => prev + 1);
    setLastUpdateDate(response.body.data.lastUpdateDate);
    // actualizar la lista de los users
    updateAllUsers(response.body.users);
    // actualizar los items del chat
    let newSingleChat = { ...singleChat };
    newSingleChat.items = addNewItemsChat(
      newSingleChat.items,
      response.body.items,
      fromUpdate
    );
    setSingleChat(newSingleChat);
  };

  const getUpdateLatestOrderProduct = async (fromUpdate = false) => {
    const paramsQuery = {
      getType: "updateLatest",
      id: JSON.stringify(dataChat.id),
      orderId: JSON.stringify(dataChat.orderId),
      retailerId: JSON.stringify(dataChat.retailerId),
      version: JSON.stringify(currentArticle.version),
      status: currentArticle.status,
      currentCompanyId: JSON.stringify(currentUser.companyId),
      othersCompanyId: encodeURIComponent(
        JSON.stringify(Object.keys(companies))
      ),
      date: encodeURIComponent(lastUpdateDate),
    };
    const response = await fetchGET(
      process.env.REACT_APP_PRODUCTS_CHAT_ENDPOINT,
      paramsQuery
    );
    if (!response.body) {
      console.log("ERROR update: ", {
        message: response.message,
        errorDetail: response.errorDetail,
      });
      return;
    }
    // success
    setStartUpdate((prev) => prev + 1);
    // cuando cambie la lista de chats por un cambio de version o estatus
    if (response.body.data.status) {
      setIsLoading(true);
      setTimeout(() => {
        setLastUpdateDate(response.body.data.lastUpdateDate);
        setCurrentArticle({
          status: response.body.data.status,
          version: response.body.data.version,
        });
        setAllUsers(response.body.users);
        const companiesList = response.body.companies;
        const companiesIdList = Object.keys(companiesList);
        let companyActive;
        // encontrar el chat inicial a cargar y si se debe permitir cargar mas
        for (const companyId of companiesIdList) {
          if (companiesList[companyId].items.length < 50) {
            companiesList[companyId].enabledLoadMore = false;
          } else {
            companiesList[companyId].enabledLoadMore = true;
          }

          if (companiesList[companyId].statusChat === "current") {
            companyActive = Number(companyId);
          }
        }
        if (!companyActive && companiesIdList.length) {
          companyActive = Number(companiesIdList[0]);
        }
        setCompanies(companiesList);
        if (companyActive) setActiveCompanyId(companyActive);
        setErrorChat({ existError: false });
        setIsLoading(false);
      }, 2000);
    }
    // cuando se hizo un update de los mismos chats
    else {
      setLastUpdateDate(response.body.data.lastUpdateDate);
      // actualizar la lista de los users
      updateAllUsers(response.body.users);
      // actualizar los items de los chats
      let companiesList = { ...companies };
      Object.keys(response.body.companies).forEach((companyId) => {
        companiesList[companyId].items = addNewItemsChat(
          companiesList[companyId].items,
          response.body.companies[companyId].items,
          fromUpdate
        );
      });
      setCompanies(companiesList);
    }
  };

  const getUpdateLatestTicket = async (fromUpdate = false) => {
    if (singleChat.statusChat === "closed") return;
    const dataUser = {
      id: dataChat.currentUser?.id,
      companyId: dataChat.currentUser?.companyId,
      isUserTech: dataChat.currentUser?.isUserTech,
    };
    const paramsQuery = {
      id: JSON.stringify(dataChat.id),
      getType: "updateLatest",
      date: encodeURIComponent(lastUpdateDate),
      currentUser: encodeUriJson(dataUser),
      currentItemsFront: encodeUriJson(getItemsIdSingleChat()),
    };
    const response = await fetchGET(
      process.env.REACT_APP_TICKETS_CHAT_ENDPOINT,
      paramsQuery,
      { Authorization: dataChat.currentUser?.token }
    );
    //programar update en 20s
    setStartUpdate((prev) => prev + 1);
    if (!response.body) {
      if (errorUpdate.startDate) {
        setErrorUpdate((prev) => ({
          ...prev,
          lastDate: new Date(),
        }));
      } else {
        setErrorUpdate((prev) => ({
          startDate: new Date(),
        }));
      }
      console.log("ERROR updateLatest:", {
        message: response.message,
        errorDetail: response.errorDetail,
      });
      return;
    }
    // limpiar error update
    if (errorUpdate.startDate) {
      setErrorUpdate({});
    }
    // success
    setLastUpdateDate(response.body.data.lastUpdateDate);
    // actualizar la lista de los users
    updateAllUsers(response.body.users);
    // actualizar los items del chat
    if (response.body.items.length === 0) return;
    let newSingleChat = { ...singleChat };
    newSingleChat.items = addNewItemsChat(
      newSingleChat.items,
      response.body.items,
      fromUpdate
    );
    newSingleChat.statusChat = response.body.data.statusChat;

    setSingleChat(newSingleChat);
  };

  /*=======================================================================
            FUNCION QUE EJECUTA EL MICROSERVICIO CREATE-ITEM
  Este metodo recibe los items que queremos agregar al chat.
  requiere:
    => items: [ { type , value } , ... ]
  NOTA: si se quiere agregar un mensaje mandar un array con un object
  example: [ {
    type: "message" ,
    value: "mi mensaje"
  } ]
  ======================================================================= */
  const createItemsChat = async (items = []) => {
    if (items.length === 0) {
      return {
        message:
          "No se especifico ningun mensaje, imagen o archivo nuevo " +
          "que se quiera enviar al chat",
        errorDetail: "Reporta esto a TI",
      };
    }
    for (const item of items) {
      if (!["message", "img", "file"].includes(item.type)) {
        return {
          message:
            "Uno de los elementos que se quiere enviar al chat " +
            `no es un tipo valido "${item.type}"`,
          errorDetail: "Reporta esto a TI",
        };
      }
      if (isStringEmpty(item.value)) {
        return {
          message:
            "Uno de los elementos que se quiere enviar al chat " +
            `contiene un valor vacio`,
          errorDetail: "Reporta esto a TI",
        };
      }
    }
    let errorCreate;
    stopUpdate(); // finalizamos el temporizador

    // enviar items a la BD
    if (chatType === "merchant_product") {
      errorCreate = await createItemsMerchantProduct(items);
    } else if (chatType === "product_status") {
      errorCreate = await createItemsProductStatus(items);
    } else if (chatType === "order_product") {
      errorCreate = await createItemsOrderProduct(items);
    } else errorCreate = await createItemsTicket(items);

    // actualizar chat para que aparezcan los items enviados
    if (["merchant_product", "product_status"].includes(chatType)) {
      await getUpdateLatestMerchantProduct();
    } else if (chatType === "order_product") {
      await getUpdateLatestOrderProduct();
    } else await getUpdateLatestTicket();

    // cuando no se pudieron guardar los items
    if (errorCreate) {
      return errorCreate;
    }
  };

  const createItemsMerchantProduct = async (items = []) => {
    const { id, version, orderId } = dataChat;
    const { id: retailerId } = activeRetailer;
    const paramsBody = { id, version, items, retailerId };
    if (chatType === "product_status") paramsBody["orderId"] = orderId;
    const paramsHeaders = { Authorization: dataChat.userToken };
    const response = await fetchPOST(
      process.env.REACT_APP_PRODUCTS_CHAT_ENDPOINT,
      paramsBody,
      paramsHeaders
    );
    if (!response.body) {
      return {
        message: response.message,
        errorDetail: response.errorDetail,
      };
    }
  };
  const createItemsProductStatus = async (items = []) => {
    const { id, version, orderId, status } = dataChat;
    const { id: retailerId } = activeRetailer;
    const paramsBody = { id, version, items, retailerId, orderId, status };
    const paramsHeaders = { Authorization: dataChat.userToken };
    const response = await fetchPOST(
      process.env.REACT_APP_CREATE_MESSAGES,
      paramsBody,
      paramsHeaders
    );
    if (!response.body) {
      return {
        message: response.message,
        errorDetail: response.errorDetail,
      };
    }
  };

  const createItemsOrderProduct = async (items = []) => {
    const paramsBody = {
      id: dataChat.id,
      version: currentArticle.version,
      items,
      orderId: dataChat.orderId,
      retailerId: activeRetailer?.id,
    };
    const paramsHeaders = { Authorization: dataChat.userToken };
    const response = await fetchPOST(
      process.env.REACT_APP_PRODUCTS_CHAT_ENDPOINT,
      paramsBody,
      paramsHeaders
    );
    if (!response.body) {
      return {
        message: response.message,
        errorDetail: response.errorDetail,
      };
    }
  };

  const createItemsTicket = async (items = []) => {
    const paramsBody = {
      id: JSON.stringify(dataChat.id),
      userId: JSON.stringify(dataChat.currentUser.id),
      items: JSON.stringify(items),
    };
    const paramsHeaders = { Authorization: dataChat.userToken };
    const response = await fetchPOST(
      process.env.REACT_APP_TICKETS_CHAT_ENDPOINT,
      paramsBody,
      paramsHeaders
    );
    if (!response.body) {
      return {
        message: response.message,
        errorDetail: response.errorDetail,
      };
    }
  };

  /*=======================================================================
                        SECCION DE FUNCIONES
  ======================================================================= */
  const isSingleChat = () => {
    if (
      chatType === "merchant_product" ||
      chatType === "ticket" ||
      chatType === "product_status"
    ) {
      return true;
    }
    if (companies && Object.keys(companies).length < 2) return true;
    else return false;
  };

  const getItemsIdSingleChat = () => {
    const itemsId = {};
    if (Array.isArray(singleChat.items)) {
      singleChat.items.forEach((item) => {
        if (isValidNaturalNumber(item.id)) itemsId[item.id] = true;
      });
    }
    return itemsId;
  };

  const getChatCompany = () => {
    if (chatType === "merchant_product" || chatType === "ticket") {
      return undefined;
    }
    if (companies && companies[activeCompanyId]) {
      return {
        ...companies[activeCompanyId],
        id: activeCompanyId,
      };
    }
    return undefined;
  };

  const addOldItemsChat = (currentItems = [], newItems = []) => {
    let items = currentItems.slice();
    let itemsId = {};
    items.forEach((item) => {
      itemsId[item.id] = true;
    });
    newItems.forEach((item) => {
      if (!itemsId[item.id]) {
        items.push(item);
        itemsId[item.id] = true;
      }
    });
    return items;
  };

  const addNewItemsChat = (
    currentItems = [],
    newItems = [],
    fromUpdate = false
  ) => {
    let itemsId = {};
    currentItems.forEach((item) => {
      itemsId[item.id] = item;
    });
    let thereAreNewItems = false;
    newItems.forEach((newitem) => {
      if (!itemsId[newitem.id]) {
        itemsId[newitem.id] = newitem;
        thereAreNewItems = true;
      }
    });
    if (thereAreNewItems && fromUpdate) {
      setPlaySound(true);
      setTimeout(() => {
        setPlaySound(false);
      }, 5000);
    }
    if (chatType !== "ticket") {
      return Object.values(itemsId).sort((a, b) => {
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        return 0;
      });
    } else {
      return Object.values(itemsId).reverse();
    }
  };

  const updateAllUsers = (newUsers = {}) => {
    let currentUsers = { ...allUsers };
    Object.keys(newUsers).forEach((userId) => {
      if (!currentUsers[userId]) {
        currentUsers[userId] = newUsers[userId];
      }
    });
    setAllUsers(currentUsers);
  };

  const renderBodyChat = () => {
    if (isLoading) return <Loading />;
    if (errorChat.existError) {
      return (
        <NotFound
          code={errorChat.code}
          message={errorChat.message}
          details={errorChat.errorDetail}
        />
      );
    }
    // cuando se quiere ver la lista de chats
    if (showChatsList) {
      return (
        <ChatLists
          companies={companies}
          currentCompanyId={currentUser?.companyId}
          onClickCompany={(companyIdSelected) => {
            setActiveCompanyId(Number(companyIdSelected));
            setShowChatsList(false);
          }}
        />
      );
    }
    // mostrar chat?
    let items;
    let enabledLoadMore;
    if (["merchant_product", "ticket", "product_status"].includes(chatType)) {
      items = singleChat.items;
      enabledLoadMore = singleChat.enabledLoadMore;
    }
    // ORDER_PRODUCT
    else if (companies) {
      // caso 0 chats
      if (Object.keys(companies).length === 0) {
        return (
          <div className="container-chatEmpty">
            <CustomIcon
              className="icon-chat"
              transparent
              size={35}
              icon={IconChat}
              type={"gray"}
            />
            <label>
              De acuerdo al estatus del producto, tu compañia no tiene
              disponible ningun chat abierto o cerrado
            </label>
          </div>
        );
      }
      // caso 1 o mas chats
      if (companies[activeCompanyId]) {
        items = companies[activeCompanyId].items;
        enabledLoadMore = companies[activeCompanyId].enabledLoadMore;
      }
    }
    if (items) {
      let containerItems = null;
      if (items.length) {
        containerItems = (
          <ContainerItems
            items={items}
            users={allUsers}
            showBtnLoadMore={enabledLoadMore}
            chatType={chatType}
            activeCompanyId={activeCompanyId}
            ticketCompany={ticketCompany}
            currentUser={
              ["merchant_product", "order_product", "product_status"].includes(
                chatType
              )
                ? currentUser
                : dataChat.currentUser
            }
            onClickBtnLoadMore={
              chatType === "merchant_product"
                ? getLoadMoreMerchantProduct
                : chatType === "order_product"
                ? getLoadMoreOrderProduct
                : getLoadMoreTicket
            }
          />
        );
      } else {
        containerItems = (
          <div className="container-chatEmpty">
            <CustomIcon
              className="icon-chat"
              size={35}
              icon={IconChat}
              type={"gray"}
              transparent
            />
            <label>Este chat no tiene mensajes</label>
          </div>
        );
      }
      return (
        <>
          {containerItems}
          <Footer
            chatType={chatType}
            dataChat={
              chatType === "merchant_product"
                ? { id: dataChat.id, version: dataChat.version }
                : chatType === "order_product"
                ? { id: dataChat.id, version: currentArticle.version }
                : chatType === "ticket"
                ? { id: dataChat.id }
                : chatType === "product_status"
                ? {
                    id: dataChat.id,
                    version: dataChat.version,
                    retailerId: dataChat.retailerId,
                  }
                : undefined
            }
            chatCompany={getChatCompany()}
            statusChat={
              chatType === "order_product"
                ? getChatCompany()?.statusChat
                : chatType === "ticket"
                ? singleChat.statusChat
                : undefined
            }
            messageDisabled={
              chatType === "order_product"
                ? `La conversación con ${getChatCompany()?.name} ha finalizado`
                : chatType === "ticket"
                ? "Ticket cerrado"
                : `La conversación ha finalizado`
            }
            createItemsChat={createItemsChat}
            errorUpdate={errorUpdate}
          />
        </>
      );
    }
    return <Loading />;
  };

  return (
    <>
      <Container>
        {/* header */}
        {(chatContainerType !== "fixed" || !isSingleChat()) && (
          <Header
            showBtnClose={showBtnClose}
            onClickBtnClose={onClickBtnClose}
            showChatsList={showChatsList}
            chatCompany={getChatCompany()}
            multipleCompanies={!isSingleChat()}
            onClickBtnChooseChat={() => {
              setShowChatsList(true);
            }}
          />
        )}
        {/* body */}
        {renderBodyChat()}
      </Container>
      <ReactHowler src={newMessage} playing={playSound} />
    </>
  );
};
