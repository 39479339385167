import styled from "styled-components";
import { GlobalColors, FontFamily } from "../../../../global-files/variables";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  background: #f9f9f9;
  padding: 12px 15px;
  //overflow: auto;
  //border: 1px solid red;

  .logoCompany {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;

    .icon-logoCompany {
      padding: 5px;
    }
  }

  .title {
    flex-grow: 2;
    min-width: min-content;
    text-align: left;
    font-family: ${FontFamily.RobotoMedium}, sans-serif;
    font-size: 15px;
    color: #262626;
  }

  .btn-chooseChat,
  .icon-chooseChat {
    width: 24px;
    height: 24px;
    padding: 0px;
  }

  .btn-X {
    width: 24px;
    height: 24px;
    padding: 0px;
  }
`;
