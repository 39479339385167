import { useState } from "react";

//Components
import { Container } from "./styles";
import { Button } from "../../atoms/GeneralButton";
import { ValidationPanel } from "../../atoms/ValidationPanel";
import { useEffect } from "react";
import { RetailerSelector } from "../RetailerSelector";

export const AvatarAndValidation = ({
  retailers,
  validation,
  activeRetailer,
  setActiveRetailer,
  showValidationButtons,
  approve,
  reject,
  approveAll,
  rejectAll,
  showApproveRejectAll,
}) => {
  const [showValidationPanel, setShowValidationPanel] = useState(false);
  const [buttonType, setButtonType] = useState(
    validation ? validation : "null-button"
  );

  const closeValidationPanel = (e) => {
    if (
      !e.target.closest("#validation-panel") ||
      (e.target.closest(".validation-option") && showValidationPanel)
    ) {
      document.removeEventListener("click", closeValidationPanel, false);
      setShowValidationPanel(false);
    }
  };

  useEffect(() => {
    if (showValidationPanel) {
      document.addEventListener("click", closeValidationPanel, false);
    }
  }, [showValidationPanel]);

  return (
    <Container>
      <div className="buttons-container">
        <RetailerSelector
          retailers={retailers}
          activeRetailer={activeRetailer}
          setActiveRetailer={setActiveRetailer}
        />
        {showValidationButtons && (
          <Button
            buttonType={`circular-button ${buttonType}`}
            onClick={(e) => {
              e.stopPropagation();
              setShowValidationPanel(true);
            }}
          />
        )}
      </div>
      {showValidationPanel && (
        <ValidationPanel
          id="validation-panel"
          setOptionClicked={setButtonType}
          setShowValidationPanel={setShowValidationPanel}
          approve={approve}
          reject={reject}
          approveAll={approveAll}
          rejectAll={rejectAll}
          showApproveRejectAll={showApproveRejectAll}
        />
      )}
    </Container>
  );
};
