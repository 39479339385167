import { Base64 } from "js-base64";
import defaultProfile from "../../../assets/defaultImages/defaultProfile.png";

export const getRoleUser = (userId, isRetailer) => {
  if (isRetailer === 0) {
    switch (userId) {
      case 1:
        return "Administrador";
      case 2:
        return "KAM";
      case 3:
        return "Recepcionista";
      case 4:
        return "Coordinador textos";
      case 5:
        return "Coordinador imagenes";
      case 6:
        return "Auditor QA";
      case 7:
        return "Especialista textos";
      case 8:
        return "Especialista edición";
      case 9:
        return "Entregas";
      case 10:
        return "Custom";
      default:
        return "Proveedor";
    }
  } else {
    return "Cadena";
  }
  };

  export const getProfilePicture = (userId, fileType = 'png', width = 24, height = 24, addNotification) => {
    try {
      const imageUrl =
        "https://d24s337exbt5zr.cloudfront.net/" +
        Base64.encode(
          JSON.stringify({
            bucket: `${process.env.REACT_APP_IMAGES_PROFILE_BUCKET}`,
            key: `id-${userId}/${userId}.${fileType}`, 
            edits: {
              resize: {
                width: width,
                height: height,
                fit: "cover",
                background: {
                  r: 255,
                  g: 255,
                  b: 255,
                  alpha: 0,
                },
              },
            }
          })
        );
      return imageUrl;
    } catch (err) {
      console.log("Error al obtener foto de perfil:", err);
      addNotification('error', 'Error al obtener foto de perfil');
      return defaultProfile;
    }
  };
  