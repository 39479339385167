import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  .custom-select {
    font-size: 13px;
    display: flex;
    padding: 10px 15px;
    justify-content: space-between;
    border-radius: 50px;
    background-color: var(--white-contentoh);
    border: 1px solid rgb(240, 240, 240);
    cursor: pointer;
    width: 100%;
  }

  .price-range {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 10px;
    box-shadow: 0px 2px 4px #00000040;
    border-radius: 5px;
    background-color: var(--white-contentoh);
    padding: 10px;
    z-index: 10;
    min-width: 250px;
    width: auto;
    display: flex;
    justify-content: center;
  }

  .range-label-container{
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  .range-labels {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-top: 10px;
  }

  .arrow-item {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    cursor: pointer;
    font-size: 10px;
    margin-left: 10px;
  }
`;
