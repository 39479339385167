import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  & + * {
    margin-top: 14px;
  }
`;
