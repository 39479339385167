import { CollaboratorsTable } from "./styles";
import ReactImageFallback from "react-image-fallback";
import { getProfilePicture, getFileTypeFromUrl } from "../utils/user_utils";
import defaultProfile from "../../../assets/defaultImages/defaultProfile.png";
import spinner from "../../../assets/defaultImages/Spinner.gif";

export const CollaboratorTable = (props) => {
  return (
    <CollaboratorsTable>
      <thead>
        <tr>
          <th></th>
          <th>Nombre</th>
          <th>Correo electrónico</th>
        </tr>
      </thead>
      <tbody>
        {props.listUsers?.map((user, index) => {
          const fileType = getFileTypeFromUrl(user.src);
          <tr key={"ct-" + index}>
            <td>
              <div>
                <ReactImageFallback
                  src={getProfilePicture(user.id_user, fileType, 30, 30)}
                  fallbackImage={defaultProfile}
                  initialImage={spinner}
                />
              </div>
            </td>
            <td>{user.name}</td>
            <td>{user.email}</td>
          </tr>
      })}
      </tbody>
    </CollaboratorsTable>
  );
};
