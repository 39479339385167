import { Container } from "./styles";

export const GenericModal = ({ componentsArray = [], buttonType, onClick }) => {
  return (
    <Container>
      <div className={"global-styles " + buttonType}>
        <button className="close-button" onClick={onClick} />
        {componentsArray?.map((component) => component)}
      </div>
    </Container>
  );
};
