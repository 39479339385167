import { FontFamily, GlobalColors } from "../../../global-files/variables";
import styled from "styled-components";

export const Container = styled.div`
  background-color: ${GlobalColors.deep_gray};
  padding-bottom: 5px;
  border-bottom: 1px solid ${GlobalColors.s4};
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .version-info {
    text-align: right;
    h2,
    p {
      & + * {
        margin-top: 5px;
      }
    }

    p {
      font-size: 12px;
      font-family: ${FontFamily.AvenirNext};
      color: ${GlobalColors.s2};
    }
  }

  & + * {
    margin-top: 18px;
  }
`;
