import styled from "styled-components";
import { FontFamily, GlobalColors } from "../../../global-files/variables";
import closeIcon from "../../../assets/images/genericModal/genericModalClose.svg";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${GlobalColors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: rgba(59, 59, 59, 0.53);
  backdrop-filter: blur(4px);
  top: 0;
  left: 0;
  z-index: 100;

  h2 {
    overflow: initial;
  }

  .global-styles {
    background-color: ${GlobalColors.deep_gray};
    height: auto;
    width: 500px;
    position: relative;
    border-radius: 15px;
    padding: 40px;
    text-align: center;
    .close-button {
      background-image: url(${closeIcon});
      background-color: transparent;
      width: 14px;
      height: 14px;
      cursor: pointer;
      border: none;
      position: absolute;
      right: 20px;
      top: 20px;
    }
    .general-white-button {
      width: 160px;
      height: 40px;
      color: ${GlobalColors.s2};
      border: 1px solid ${GlobalColors.white};
      background-color: transparent;
      & + * {
        margin-left: 45px;
        height: 40px;
        width: 161px;
      }
    }

    &.log-out {
      padding-bottom: 60px;
      img {
        & + * {
          margin-top: 20px;
        }
      }
      .with-bold-text {
        font-size: 24px;
        & + * {
          margin-top: 20px;
        }
      }
    }

    &.delete-product {
      img {
        height: 50px;
        width: 56px;
        & + * {
          margin-top: 30px;
        }
      }
      .product-name-header,
      .retailer-name-header {
        & + * {
          margin-top: 30px;
        }
      }
    }

    &.product-edition {
      padding-top: 50px;
      .product-name-header {
        & + * {
          margin-top: 25px;
        }
      }
    }

    &.send-invitation {
      width: 604px;
      img {
        & + * {
          margin-top: 40px;
        }
      }
      p {
        font-family: ${FontFamily.Raleway_500};
        font-style: normal;
        font-size: 24px;
        color: ${GlobalColors.white};
        line-height: 28px;
        span {
          font-family: ${FontFamily.Raleway_700};
        }
      }
    }

    &.upload-image {
      width: 430px;
      padding: 50px;
      img {
        & + * {
          margin-top: 33px;
        }
      }
      .input-name-header {
        & + * {
          margin-top: 20px;
        }
      }
      .list-container {
        & + .general-button-default {
          margin-top: 40px;
        }
      }
    }
  }
`;
