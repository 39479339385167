import { useEffect, useState } from "react";
import { Container } from "./styles";
import { InputFormatter } from "../InputFormatter";
import { CheckBox } from "../CheckBox";

export const GeneralInput = ({
  inputType,
  inputId,
  inputName,
  inputValue = "",
  inputPlaceholder,
  validateInput,
  position,
  inputsArray,
  inputSize,
  articleId,
  updatedDatasheets,
  setUpdatedDatasheets,
  updatedDescriptions,
  setUpdatedDescriptions,
  maxChar,
  isRequired,
  version,
  optionList = [],
  description,
  inputOnChange,
  disabled,
  onKeyDown,
  auditClass,
}) => {
  const [textValue, setTextValue] = useState({
    value: inputValue,
  });
  const [requiredEmpty, setRequiredEmpty] = useState(false);

  const onHandleChange = (evt) => {
    if (validateInput) {
      setTextValue({ value: validateInput(evt, position, inputsArray) });
    } else if (
      updatedDatasheets ||
      updatedDescriptions ||
      inputType === "textarea"
    ) {
      let generalValue;
      if (optionList?.length > 0) {
        let valueSelected = evt.target.value;
        generalValue = valueSelected;
        setTextValue({ value: generalValue });
      } else {
        generalValue =
          inputType === "checkbox" ? evt.target.checked : evt.target.value;
        setTextValue({
          value: generalValue,
        });
      }
      let dataSave = updatedDatasheets?.slice();

      if (dataSave?.length > 0) {
        const index = dataSave.findIndex((e) => e.attributeId === inputId);
        if (index !== -1) {
          if (generalValue !== inputValue) dataSave[index].value = generalValue;
          else dataSave.splice(index, 1);
        } else {
          dataSave.push({
            articleId: articleId,
            attributeId: inputId,
            value: generalValue,
          });
        }
      } else {
        if (generalValue !== inputValue) {
          dataSave.push({
            articleId: articleId,
            attributeId: inputId,
            value: generalValue,
          });
        }
      }
      setUpdatedDatasheets(dataSave);
    } else {
      setTextValue({ value: evt.target.value });
      inputOnChange && inputOnChange(evt);
    }
  };

  useEffect(() => {
    setRequiredEmpty(
      isRequired &&
        (textValue.value?.length === 0 || textValue.value === undefined)
    );
  }, [textValue]);

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();
    // Prevent the page/container scrolling
    e.stopPropagation();
    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => e.target.focus(), 0);
  };

  return (
    <Container isRequired={requiredEmpty} className={auditClass}>
      {optionList?.length > 0 ? (
        <select
          key={`dropdownK${inputId}`}
          id={`dropdown${inputId}`}
          onChange={(e) => onHandleChange(e)}
          defaultValue={textValue.value}
          disabled={disabled}
        >
          <option value="">{description}</option>
          {JSON.parse(optionList || "[]").map((element, index) => (
            <option key={element + "-" + index} value={element}>
              {element}
            </option>
          ))}
        </select>
      ) : inputType === "checkbox" ? (
        <CheckBox
          id={inputId}
          onChange={(e) => onHandleChange(e)}
          defaultChecked={textValue.value === "true" ? true : false}
          disabled={disabled}
        />
      ) : inputType !== "textarea" ? (
        <input
          type={inputType}
          disabled={disabled}
          id={inputId}
          size={inputSize}
          className="general-input"
          placeholder={inputPlaceholder}
          value={textValue.value}
          onInput={(e) => onHandleChange(e)}
          maxLength={maxChar}
          required={isRequired}
          onKeyDown={onKeyDown}
          onWheel={numberInputOnWheelPreventChange}
        />
      ) : (
        <InputFormatter
          name={inputName}
          inputId={inputId}
          placeholder={inputPlaceholder}
          mainValue={textValue.value}
          onChange={onHandleChange}
          articleId={articleId}
          updatedDescriptions={updatedDescriptions}
          setUpdatedDescriptions={setUpdatedDescriptions}
          maxChar={maxChar}
          isRequired={isRequired}
          disabled={disabled}
        />
      )}
      <p>{description}</p>
    </Container>
  );
};
