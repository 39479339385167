import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  // display: grid;
  // grid-template-columns: 75% 25%;
  // grid-template-rows: 55px 1fr;
  // grid-template-areas:
  //   "tabs action_buttons"
  //   "table table";
  height: 100%;
  position:relative;

  .container-head{
    display: flex;
    justify-content: space-between;
  }

  .tabs {
    grid-area: tabs;
    display: flex;
    align-items: center;
  }
  .action_buttons {
    grid-area: action_buttons;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 0 10px;
    & > * {
      cursor: pointer;
    }
  }
  .table {
    grid-area: table;
    height: 100%;
    min-height:140px;
  }
  
  .buttons-container{
    display: flex;
    flex-direction: column;
    background: white;
    width: max-content;
    padding: 8px;
    border: 1px solid var(--gray-contentoh);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    position: absolute;
    top: 0;
    left: 50px;
    z-index: 100;
  }

  .validation-button{
    width: 30px;
    height: 20px;
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    color: black;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    background-color: white;
    margin-right:5px;
    img {
      & + p {
        margin-left: 7px;
      }
    }

    &:hover {
      border-radius: 10px;
    }
  }

  .validation-option {
    display: flex;
    width: 82px;
    height: 20px;
    align-items: center;
    border: none;
    cursor: pointer;
    color: black;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    background-color: white;
    left: 7px;
    gap:5px;

    &:hover {
      border-radius: 10px;
    }

    & + * {
      margin-top: 8px;
    }
  }

  td{
    margin:0;
    background: #ffffff !important;
  }

  th{
    border-radius: 0 0 0 0 !important;
    padding: 15px 10px !important;
  }
  
  textarea {
    width: 100%;
    border: 1px solid
      ${({ isRequired }) => (isRequired ? "red" : `var(--gray-light)`)};
    color: var(--gray-contentoh)};
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    padding: 10px;
    outline: none;
    border-radius: 5px;
    resize: none;

    &:focus {
      border: 1px solid var(--pink-contentoh);
    }
  }

  .general-input {
    width: 100%;
    height:27px;
    max-height:100px;
  }

  .select-edition-multiple {
    padding: 5px 10px;
    border-radius: 5px;
    gap: 10px;
    border:1px solid var(--gray-light);
    border-width: 1px 2px 1px 1px;
    outline: none;
    border-image: initial;
    width: 100%;
    min-height:27px;
    cursor: pointer;
    color: var(--gray-contentoh);
    font-size: 12px;
    line-height: 21px;
    overflow:hidden; 
    white-space:nowrap; 
    text-overflow:ellipsis;
    :focus{
      border:1px solid var(--pink-contentoh);
    }
  }
`;