import React, { useState, useEffect } from "react";
import HeaderFilter from "../general/HeaderFilter";
import { MainContainer } from "./styles";
import GridView from "../general/GridView";
import { filterProducts, getRetailerProducts } from "./utils";
const RetailerProducts = ({ user }) => {
  const [gridView, setGridView] = useState(true);
  const [gridData, setGridData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [headersTable, setHeadersTable] = useState([]);
  const [headerMapping, setHeaderMapping] = useState([]);
  const [percentageParams, setPercentageParams] = useState([]);
  const [providersDataFilter, setProvidersDataFilter] = useState([]);
  const [phasesDataFilter, setPhasesDataFilter] = useState([]);
  const [groupsDataFilter, setGroupsDataFilter] = useState([]);
  const [productInfo, setProductInfo] = useState([]);
  const [productCount, setProductCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filterLists, setFilterLists] = useState(null);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [dataDetailTable, setDataDetailTable] = useState([]);
  const isProvider = user.is_retailer === 0 && user.id_role === 0; 
  const [selectedProvider, setSelectedProvider] = useState();
  const [selectedPhase, setSelectedPhase] = useState();
  const [selectedGroup, setSelectedGroup] = useState();
  const [searchValue, setSearchValue] = useState("");
  const { filteredGridData, filteredTableData } = filterProducts(gridData, tableData, searchValue)

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      const params = {
        canonicFilter: null,
        productsType: "acquired",
        providerFilter: selectedProvider ? selectedProvider : "",
        phaseFilter: selectedPhase ?  selectedPhase : "",
        groupFilter: selectedGroup ?  selectedGroup : "",
        startIndex: 0,
        setFilterLists,
      };

      const response = await getRetailerProducts(params, setProductCount);
      if (!response.err) {
        setGridData(response.gridData);
        setTableData(response.tableData);
        setProductInfo(response.dataRadio);
        setHeadersTable(response.headersTable);
        setHeaderMapping(response.headerMapping);
        setPercentageParams(response.percentageParams);
        setProvidersDataFilter(response.providersDataFilter);
        setPhasesDataFilter(response.phasesDataFilter);
        setGroupsDataFilter(response.groupsDataFilter);
        setDataDetailTable(response.dataDetailTable);
      }
      setLoading(false);
    };

    fetchProducts();
  }, [selectedProvider, selectedPhase, selectedGroup, searchValue]);

  useEffect(() => {
    setSelectedArticles([]);
  }, [gridView]);

  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  return (
    <MainContainer>
      <HeaderFilter
        searchBar
        placeholderSearchBar="Buscar producto"
        filterProvider
        filterPhase
        filterGroup
        showMultipleEdition={!isProvider}
        showView
        showReport
        showExportable
        gridView={gridView}
        setGridView={setGridView}
        providersFilter={providersDataFilter}
        setProvidersFilter={(value) => setSelectedProvider(value)}
        phasesFilter={phasesDataFilter}
        setPhasesFilter={(value) => setSelectedPhase(value)}
        groupsFilter={groupsDataFilter}
        setGroupsFilter={(value) => setSelectedGroup(value)}
        onSearchChange={handleSearchChange}
        isProvider={isProvider}
        selectedArticles={selectedArticles}
        setSelectedArticles={setSelectedArticles}
      />
      <GridView
        isGridView={gridView}
        data={{ tableData: filteredTableData, gridData: filteredGridData }}
        headers={headersTable}
        headerMapping={headerMapping}
        percentageParams={percentageParams}
        typePercentage="percentageRequired"
        cardsPerPage={12}
        rowsPerPage={15}
        selectedArticles={selectedArticles}
        setSelectedArticles={setSelectedArticles}
        dataDetailTable={dataDetailTable}
        productInfo={productInfo}
        showEditButtonTable={false}
      />
    </MainContainer>
  );
};

export default RetailerProducts;
