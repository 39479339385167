import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex; // Asegúrate de que los botones se alineen correctamente
`;

export const Button = styled.button`
  background: var(--gray-light);
  color: var(--black);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background 0.3s;

  .icon-tab {
    font-size: 17px;
  }

  .tab-text {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  &:first-child {
    border-radius: 17px 0 0 17px;
  }

  &:last-child {
    border-radius: 0 17px 17px 0;
  }

  ${({ isActive }) => isActive && `
    color: var(--white-contentoh);
    background: var(--purple);
  `}

  &:hover {
    background: #8a6caad4;
    color: var(--white-contentoh);
  }
`;