import styled from "styled-components";
import { GlobalColors } from "../../../global-files/variables";

export const Container = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;

  .retailers-panel {
    background: ${GlobalColors.s2};
    border: 1px solid ${GlobalColors.s3};
    box-sizing: border-box;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 10px;
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    z-index: 10;
  }
`;
