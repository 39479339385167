import styled from "styled-components";
import { GlobalColors, FontFamily } from "../../../global-files/variables";

// Container para chat fixed
export const ContainerFixed = styled.div`
  width: auto;
  max-width: 550px;
  min-width: 325px;
  height: 100%;
  flex-grow: 2;
  background-color: white;
  border: 1px solid #f0f0f0;
  z-index: 1000;
  padding: 0px;
  @media (max-width: 1024px) {
    width: auto;
    max-width: 100%;
  }
  @media (max-width: 600px) {
    width: 90%;
    min-width: 300px;
  }
`;

export const ContainerBtnPopUpChat = styled.div`
  border-radius: 50%;
  height: 34px;
  width: 34px;

  .container-btn-popUpChat {
    height: inherit;
    width: inherit;
    overflow: hidden;
  }
`;

export const ContainerPopUp = styled.div`
  position: fixed;
  top: 20px;
  right: 23px;
  bottom: 20px;
  box-shadow: 0px 2px 10px 0px #00000073;
  z-index: 1000;
  width: calc(100% - 50px);
  max-width: 550px;
  min-width: 325px;
  border-radius: 10px;
  background: white;
  padding: 0px;
`;
