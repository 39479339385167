import styled from "styled-components";
import { GlobalColors, FontFamily } from "../../../global-files/variables";

export const Container = styled.div`
  .quill {
    background-color: ${GlobalColors.white};
    border: 1px solid
      ${({ focus, isRequired }) =>
        focus ? GlobalColors.magenta_s2 : isRequired ? "red" : GlobalColors.gray_light};
    position: relative;
    border-radius: 5px;

    .ql-toolbar {
      background-color: ${GlobalColors.s2};
      position: absolute;
      z-index: 10;
      border: 1px solid ${GlobalColors.gray_light};
      width: 45px;
      height: 45px;
      border-radius: 4px;
      visibility: ${({ selection }) => (selection ? "visible" : "hidden")};
      top: ${({ position }) => position.top}px;
      left: ${({ position }) => position.left}px;
    }

    .ql-container {
      border: none;
      font-family: ${FontFamily.AvenirNext};
      color: ${GlobalColors.deep_gray};
      strong {
        font-family: ${FontFamily.Raleway_900};
      }

      .ql-editor{
        padding: 8px 10px;
      }
    }
  }
  .description-limit {
    font-size: 12px;
    font-family: ${FontFamily.AvenirNext};
    color: ${GlobalColors.deep_gray};
    text-align: right;
    margin-top: 5px;
  }
`;
