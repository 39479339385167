import React, { useState, useEffect } from "react";
import axios from "axios";
import Plan from "../PlanSection/index";
import CustomNotification from "../../general/CustomNotification";

// SVG assets
import location from "../../../assets/IconComponents/locationWhite.svg";
import edit from "../../../assets/IconComponents/edit.svg";
import companyIcon from "../../../assets/IconComponents/company.svg";
import documentIcon from "../../../assets/IconComponents/document.svg";
import { 
  ContainerPlan,
  MainContainer,
  BasicData,
  Textarea,
  SaveData
} from "./styles";
import useNotifications from "../../../hooks/useNotification";
import CustomButton from "../../general/CustomButton";
import HeaderData from "../HeaderData/index.jsx";
import CustomInput from "../../general/CustomInput/index.jsx";

const CompanyData = (props) => {
  const { history } = props;
  const [canEdit, setCanEdit] = useState(false);
  const [company, setCompany] = useState(sessionStorage.getItem("company") ? JSON.parse(sessionStorage.getItem("company")) : {});
  const { notifications, addNotification, deleteNotification } = useNotifications();

  const getCompany = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_USER_ENDPOINT, {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      });
      const companyData = JSON.parse(response.data.body).data[1];
      sessionStorage.setItem("company", JSON.stringify(companyData));
      setCompany(companyData);
    } catch (err) {
      console.error("Error fetching company data:", err);
    }
  };

  useEffect(() => {
    getCompany();
  }, []);

  const onSubmit = async () => {
    const data = {
      about_company: document.getElementById("about_company").value,
      address: document.getElementById("address").value,
      trade_name: document.getElementById("trade_name").value,
      email: document.getElementById("email").value,
      social_link: document.getElementById("social_link").value,
      telephone: document.getElementById("telephone").value,
      web_site: document.getElementById("web_site").value,
      zip_code: document.getElementById("zip_code").value,
    };

    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_USER_ENDPOINT}?boolCompany=1`,
        data: data,
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      });
      setCanEdit(false);
      addNotification("success", "¡Los cambios se han guardado exitosamente!");
      getCompany();
    } catch (err) {
      console.error("Error updating company data:", err);
      addNotification("error", "Hubo un error al guardar los cambios. Inténtalo de nuevo.");
    }
  };

  return (
    <>
      <ContainerPlan>
        <Plan history={history} hasChild={props.child !== null} />
      </ContainerPlan>
      <MainContainer>
        <HeaderData icon={companyIcon} text="Información de la empresa" active={canEdit} setActive={setCanEdit}/>
        <BasicDataSection company={company} setCompany={setCompany} canEdit={canEdit} setCanEdit={setCanEdit}/>
        {canEdit && (
          <SaveData>
            <CustomButton label="Guardar cambios" variant="pink" onClick={onSubmit}/>
          </SaveData>
        )}
      </MainContainer>
      <CustomNotification
        open={notifications.length > 0}
        alerts={notifications}
        deleteNotification={deleteNotification}
      />
    </>
  );
};

export default CompanyData;

const BasicDataSection = ({ company, canEdit, setCanEdit, setCompany }) => (
  <>
    <BasicData>
      <div>
        <p className="title">Acerca de la empresa</p>
        {canEdit ? (
          <Textarea
            id="about_company"
            placeholder="Acerca de la empresa"
            defaultValue={company.about_company || ""}
          />
        ) : (
          <p>{company.about_company || "---"}</p>
        )}
      </div>
      <div>
        <p className="item">Nombre comercial</p>
        {canEdit ? (
          <CustomInput
            id="trade_name"
            type="text"
            placeholder="Dirección"
            value={company.trade_name || ""}
            onChange={(e) => setCompany({ ...company, trade_name: e.target.value })}
            variant="gray"
          />
        ) : (
          <p>{company.trade_name || "---"}</p>
        )}
      </div>
    </BasicData>
    <BasicData>
      <HeaderData icon={location} text="Ubicación" active={canEdit} setActive={setCanEdit} showEdit={false}/>
      <div>
        <p className="item">Dirección</p>
        {canEdit ? (
          <CustomInput
            id="address"
            type="text"
            placeholder="Dirección"
            value={company.address || ""}
            onChange={(e) => setCompany({ ...company, address: e.target.value })}
            variant="gray"
          />
        ) : (
          <p>{company.address || "---"}</p>
        )}
      </div>
      <div>
        <p className="item">Código postal</p>
        {canEdit ? (
          <CustomInput
            id="zip_code"
            type="number"
            placeholder="Código postal"
            value={company.zip_code || ""}
            onChange={(e) => setCompany({ ...company, zip_code: e.target.value })}
            variant="gray"
          />
        ) : (
          <p>{company.zip_code || "---"}</p>
        )}
      </div>
      <div>
        <p className="item">Teléfono</p>
        {canEdit ? (
          <CustomInput
            id="telephone"
            type="number"
            placeholder="Teléfono"
            value={company.telephone || ""}
            onChange={(e) => setCompany({ ...company, telephone: e.target.value })}
            variant="gray"
          />
        ) : (
          <p>{company.telephone || "---"}</p>
        )}
      </div>
      <div>
        <p className="item">Correo electrónico</p>
        {canEdit ? (
          <CustomInput
            id="email"
            type="email"
            placeholder="Correo electrónico"
            value={company.email || ""}
            onChange={(e) => setCompany({ ...company, email: e.target.value })}
            variant="gray"
          />
        ) : (
          <p>{company.email || "---"}</p>
        )}
      </div>
    </BasicData>
    <BasicData>
      <div>
        <p className="item">Sitio web</p>
        {canEdit ? (
          <CustomInput
            id="web_site"
            type="text"
            placeholder="Página web"
            value={company.web_site || ""}
            onChange={(e) => setCompany({ ...company, web_site: e.target.value })}
            variant="gray"
          />
        ) : (
          <p>{company.web_site || "---"}</p>
        )}
      </div>
    </BasicData>
    <BasicData>
      <div>
        <HeaderData icon={documentIcon} text="LinkedIn" active={canEdit} setActive={setCanEdit} showEdit={false}/>
        {canEdit ? (
          <CustomInput
            id="social_link"
            type="text"
            placeholder="Perfil de LinkedIn"
            value={company.social_link || ""}
            onChange={(e) => setCompany({ ...company, social_link: e.target.value })}
            variant="gray"
          />
        ) : (
          <p>{company.social_link || "---"}</p>
        )}
      </div>
    </BasicData>
  </>
);
