import styled from 'styled-components';

export const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 200px;
  font-size: 12px;
`;

export const SelectBase = styled.div`
  width: 100%;
  padding: 4px 14px;
  border-radius: ${({ variant }) =>
    variant === 'gray-light' ? '10px' : '50px'};
  background-color: ${({ variant }) =>
    variant === 'gray-light' ? 'rgb(250, 250, 250)' : 'var(--white-contentoh)'};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ variant }) =>
    variant === 'gray-light' ? 'var(--gray-light)' : 'var(--gray-light)'};
  color: ${({ variant }) =>
    variant === 'gray-light' ? 'rgb(40, 31, 51)' : 'var(--black)'};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  white-space: nowrap;
`;

export const Arrow = styled.span`
  font-size: 26px;
  color: ${({ variant }) => variant === 'gray' ? 'var(--black)' : '#666'};
`;

export const Options = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  background-color: var(--white-contentoh);
  border-radius: 10px;
  border: 1px solid #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 0;
  padding: 0;
  list-style: none;
  display: ${({ open }) => (open ? 'block' : 'none')};
  z-index: 1000;
`;

export const OptionItem = styled.li`
  padding: 10px 15px;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? 'var(--blue-light)' : 'var(--white-contentoh)')};
  color: ${({ selected }) => (selected ? 'var(--gray-contentoh)' : 'var(--black)')};
  
  &:hover {
    background-color: var(--blue-light);
    color:var(--gray-contentoh);
  }
`;
