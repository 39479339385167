import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { SelectWrapper, SelectBase, Arrow, Options, OptionItem } from './styles';

const SelectData = ({ id, label, placeholder, options = [], onChange, value, variant = 'gray' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);
  const selectRef = useRef(null);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedValue(option);
    setIsOpen(false);
    onChange(option);
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <SelectWrapper ref={selectRef}>
      <SelectBase onClick={handleToggle} variant={variant}>
        <span>{selectedValue ? label : placeholder}</span>
        <Arrow variant={variant} className="material-symbols-outlined">arrow_drop_down</Arrow>
      </SelectBase>
      <Options open={isOpen}>
        {Array.isArray(options) && options.map((option) => (
          <OptionItem
            key={option.value}
            onClick={() => handleOptionClick(option)}
            selected={selectedValue && selectedValue.value === option.value}
          >
            {option.label}
          </OptionItem>
        ))}
      </Options>
    </SelectWrapper>
  );
};

SelectData.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  variant: PropTypes.oneOf(['gray']),
};

export default SelectData;