import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "../../general/Modal";
import Loading from "../../general/loading";
import { getUsers, searchUser, deleteCollaborator } from "./utils";
import { BasicData, MainContainer } from "./styles";
import InviteUser from "../InviteUser";
import CustomSelect from "../../general/CustomSelect";
import CustomButton from "../../general/CustomButton";
import { CheckboxList } from "../../general/customInputs/CheckboxList";
import { useModal } from "../../../hooks/useModal";
import useNotifications from "../../../hooks/useNotification";
import CustomTable from "../../general/CustomTable";
import { useCategories, getCategoriesString } from "../../../hooks/useCategories";
import searchIcon from "../../../assets/IconComponents/search.svg";

const AddPeople = (props) => {
  const [listUsers, setListUsers] = useState([]);
  const [listUsersFilter, setListUsersFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const { modalData, openModal, closeModal, handleCancelClick } = useModal();
  const { categories, loading: loadingCategories } = useCategories();
  const [showInvite, setShowInvite] = useState(false);
  const [userName, setUserName] = useState("");
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [categoriesItems, setCategoriesItems] = useState([]);
  const [positions, setPositions] = useState([]);
  const [positionsSelected, setPositionsSelected] = useState([]);
  const [canUpdateRol, setCanUpdateRol] = useState(false);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const categoriesSelectedString = getCategoriesString(categoriesSelected);

  const headers = ["Nombre", "Correo", "Puesto", "Categorías"];
  const headerMapping = {
    Nombre: "name",
    Correo: "email",
    Puesto: "position",
    Categorías: "categories",
  };

  const loadData = async () => {
    await getUsers(setListUsers, setListUsersFilter);
  };

  const loadCategories = () => {
    if (categories && categories.length > 0) {
      const formattedCategories = categories.map((category) => ({
        label: category.name, 
        value: category.id,   
      }));
      setCategoriesItems(formattedCategories);
    }
  };
  
  useEffect(() => {
    loadData();
    loadCategories();
    const { id_role, is_retailer } = JSON.parse(sessionStorage.getItem("user"));
    if (is_retailer === 1 && id_role === 0) {
      setCanUpdateRol(true);
    }
  }, [categories]);

  const onChangeName = (e) => {
    setUserName(e.target.value);
    searchUser(e.target.value);
  };

  const handleDeleteCollaborator = () => {
    if (listUsersFilter.length > 0) {
      openModal({
        className: "modal-delete",
        title: "Atención",
        text: "Se eliminarán todos los grupos de este usuario. ¿Estás seguro?",
        buttons: [
          {
            label: "Sí, estoy seguro",
            onClick: () => {
              deleteCollaborator(listUsersFilter);
              closeModal();
            },
            className: "btn-yes",
          },
          {
            label: "No",
            onClick: closeModal,
            className: "btn-no",
          },
        ],
      });
    }
  };

  return loading || loadingCategories ? (
    <Loading />
  ) : (
    <MainContainer>
      <div className="filters-container">
        <div className="filters">
          <div className="search-user-input">
            <img src={searchIcon} alt="search-icon" />
            <input
              type="text"
              placeholder="Buscar usuarios"
              value={userName}
              onChange={onChangeName}
            />
          </div>
          <CheckboxList
          id="category-checkbox"
          items={categoriesItems}
          value={categoriesSelected} 
          name="Categorías"
          onChange={setCategoriesSelected}
          placeholder="Buscar departamento"
          defaultOption="Seleccionar categorías"
        />
        </div>
        <div className="buttons-container">
          <CustomButton
            variant="gray"
            icon="delete"
            iconOnly
            onClick={handleDeleteCollaborator}
          />
          <CustomButton
            variant="pink"
            label="Agregar usuarios"
            onClick={() => setShowInvite(true)}
          />
        </div>
      </div>

      <CustomTable
        data={listUsersFilter.map(user => ({
          ...user,
          categories: getCategoriesString(categoriesSelected),
        }))}
        headers={headers}
        headerVariant={canUpdateRol ? "with-rol" : "default"}
        headerMapping={headerMapping}
        rowsPerPage={10}
        selectedArticles={selectedArticles}
        setSelectedArticles={setSelectedArticles}
      />
      <Modal {...modalData} onClose={handleCancelClick} />
      {showInvite && <InviteUser onClose={() => setShowInvite(false)} />}
    </MainContainer>
  );
};

export default AddPeople;
