import { Container } from "./styles";
import { ButtonV2 } from "../ButtonV2";
import { Modal } from "../../organisms/Modal";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faCircleInfo as iconInfo } from "@fortawesome/free-solid-svg-icons";
import imgNotFountDefault from "../../../assets/images/defaultImages/notFound.svg";
import { useState, useEffect } from "react";

export const NotFound = (props) => {
  const {
    className, // string
    code, // string
    message, // string
    details, // string
  } = props;
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Container className={className}>
        <img src={imgNotFountDefault} alt={"imagen not found"} />

        <div className="divInfo">
          {/* icono info */}
          <ButtonV2
            className={"btnInfo"}
            type={"pink"}
            transparent
            icon={iconInfo}
            size={16}
            onClick={() => {
              setShowModal(true);
            }}
          />

          <label className="label-error">
            Codigo de error
            <label> {code ? code : 404} </label>
          </label>
        </div>

        <Modal
          show={showModal}
          title={message ? message : "Error NO especificado"}
          message={details ? details : "Reporta esto a TI"}
          icon={"info"}
          onClickBtnDefault={(event) => setShowModal(false)}
        />
      </Container>
    </>
  );
};
