import styled from "styled-components";

export const RoleBadge = styled.p`
  padding: 3px 10px;
  background: #603888;
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 19px;
  letter-spacing: -0.015em;
  color: #ededed;
  width: fit-content;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #f4f4f7;
  border-radius: 10px;
  padding: 15px 12px;

  .profile-avatar {
    display:flex;
    flex-direction:row;
    gap:10px;
  }

  .button-menu{
    background:transparent;
    border:none;
    cursor:pointer;
    &:hover{
      transform: scale(1.1);
    }

    .menu{
      color: var(--purple-light-contentoh);
      &:hover{
        color: var(--purple);
      }
    }
  }

  .avatar {
    width:${(props) => (props.showMenuText ? '30px' : '30px')};
    height:${(props) => (props.showMenuText ? '30px' : '30px')};
    border-radius: 100px;
  }
  #title {
    font-weight: bold;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: -0.015em;
    color: #262626;
  }
  .container-info{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .info-user{
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: var(--gray-contentoh);

  }

`;