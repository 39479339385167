import { Container } from "./styles";
import { TableHeader } from "../../molecules/TableHeader";
import { Container as Row } from "../../molecules/TableRow/styles";
import { ScreenHeader } from "../../atoms/ScreenHeader";
import LabelToInput from "../../atoms/LabelToInput";

export const ImageDataTable = ({
  activeImage,
  darkMode,
  lists,
  retailerSelected,
  setImages,
  assignationsImages,
  imagesStatus,
  setAssignation,
  isRetailer,
  onClickSave,
  showSaveButton,
  version,
  setShowVersionSelector,
  shotThd,
}) => {
  return (
    <Container darkMode={darkMode}>
      <TableHeader
        imagesStatus={imagesStatus}
        activeImage={activeImage}
        assignationsImages={assignationsImages}
        setAssignation={setAssignation}
        isRetailer={isRetailer}
        showSaveButton={showSaveButton}
        onClickSave={onClickSave}
        version={version}
        setShowVersionSelector={setShowVersionSelector}
      />
      <Row>
        <ScreenHeader headerType={"table-row-text"} text="Tipo de toma" />
        <ScreenHeader
          headerType={"table-row-text gray-table-row"}
          text={
            lists?.inputs?.find((f) => f.id === +activeImage?.image_id)?.name ||
            "-"
          }
        />
      </Row>
      <Row>
        <ScreenHeader headerType={"table-row-text"} text="Tipo de empaque" />
        <ScreenHeader
          headerType={"table-row-text gray-table-row"}
          text={
            lists?.imagePackagingType?.find(
              (f) => f.id === +activeImage?.packing_type
            )?.name || "-"
          }
        />
      </Row>
      <Row>
        <ScreenHeader headerType={"table-row-text"} text="Tipo de imagen" />
        <ScreenHeader
          headerType={"table-row-text gray-table-row"}
          text={
            lists?.imageType?.find((f) => f.id === +activeImage?.image_type)
              ?.name || "-"
          }
        />
      </Row>
      {shotThd && (
        <Row>
          <ScreenHeader
            headerType={"table-row-text"}
            text="Tipo de imagen THD"
          />
          <ScreenHeader
            headerType={"table-row-text gray-table-row"}
            text={
              lists?.imageShotType?.find(
                (f) => f.id === +activeImage?.image_shot_type
              )?.type_shot || "-"
            }
          />
        </Row>
      )}
      <Row>
        <ScreenHeader headerType={"table-row-text"} text="Formato" />
        <ScreenHeader
          headerType={"table-row-text"}
          text={activeImage?.ext || "-"}
        />
      </Row>
      <Row>
        <ScreenHeader headerType={"table-row-text"} text="Tamaño de imagen" />
        <ScreenHeader
          headerType={"table-row-text"}
          text={
            activeImage?.width && activeImage.height
              ? `${activeImage?.width}x${activeImage?.height}px`
              : "-"
          }
        />
      </Row>
      {retailerSelected &&
        lists?.attrForImgs &&
        lists?.attrForImgs?.general?.map((attr, index) => (
          <Row key={"row-" + index + "-" + attr?.name}>
            <ScreenHeader
              headerType={"table-row-text"}
              text={attr?.name || "-"}
            />
            <LabelToInput
              width="100px"
              defaultValue={attr?.value}
              onChange={(e) => {
                setImages({
                  action: "changeAttrValue",
                  value: e.target.value || "-",
                  id: attr.id,
                });
              }}
            />
          </Row>
        ))}
    </Container>
  );
};
