import styled from "styled-components";

export const MainContainer = styled.div`
  border: 1px solid var(--gray-light-secondary);
  box-sizing: border-box;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

export const Section = styled.div`
  width: 95%;
  border-bottom: 1px solid var(--gray-light-secondary);
  padding: 14px 0px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  p {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: var(--gray-contentoh);
  }
`;

export const Header = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction:row;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #503d66;
  }
`;

export const AboutCompany = styled(Section)`
  padding: 13px 8px;
  box-sizing: border-box;
  .title {
    font-weight: bold;
    color: #503d66;
  }
`;

export const BasicData = styled(Section)`
  div {
    height: 28px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    .title,
    .item {
      width: 35%;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.015em;
      color: #503d66;
      & + p {
        color: #817393;
      }
    }

    h4 {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.015em;
      color: #503d66;
    }

    figure {
      margin: 0;
      & + * {
        margin-left: 13px;
      }
    }
  }

  #inputSelect div {
    display: flex;
    place-items:start;
    flex-direction: column;
    left: -15px;
    height: auto;
  }
`;

export const Icon = styled.figure`
  margin: 0 10px;
  align-items: center;
`;

export const TitleIcon = styled.figure`
  margin: 0 15px 0 0;
  align-items: center;
  cursor: pointer;
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 28px;
  padding: 0 7px;
  display: flex;
  align-items: center;
  border: 1px solid #d4d1d7;
  font-family: Roboto;
  padding: 7px;
  box-sizing: border-box;
  border-radius: 6px;
  ::placeholder {
    font-weight: normal;
    font-size: 12px;
    color: #d4d1d7;
  }
`;

export const Input = styled.input`
  width:100%;
  min-width: 190px;
  height: 28px;
  margin-bottom: 4;
  display: flex;
  align-items: center;
  border: 1px solid #d4d1d7;
  padding: 7px;
  box-sizing: border-box;
  border-radius: 6px;
  ::placeholder {
    font-weight: normal;
    font-size: 12px;
    color: #d4d1d7;
  }
`;

export const SaveData = styled.div`
  padding: 14px 10px;
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 150px;
  align-self: end;
`;

export const ContainerPlan = styled.section`
  display: flex;
  flex-direction:column;
  width: 100%;
`;