import React, { useState, useEffect, useRef } from "react";
import { Container, Header } from "./styles";
import { Phase } from "./Phase";
import Modal from "../../../general/Modal";
import axios from "axios";
import useNotifications from "../../../../hooks/useNotification";
import { useModal } from "../../../../hooks/useModal";
import { createPhase, updatePhases, deletePhase } from "./utils"
import CustomNotification from "../../../general/CustomNotification";
import CustomButton from "../../../general/CustomButton";

export const DragAndDropPhases = ({ token }) => {
  const [listPhases, setListPhases]= useState([]);
  const [tempListPhases, setTempListPhases] = useState([]); 
  const [search, setSearch] = useState();
  const [newPhaseName, setNewPhaseName] = useState("");
  const [inputValue, setInputValue] = useState("");
  const { modalData, openModal, closeModal, handleCancelClick } = useModal();
  const { notifications, addNotification, deleteNotification } = useNotifications();
  const newPhaseNameRef = useRef("");

  useEffect(() => {
    const fetchData = async () => {
      await loadData();
    };
  
    fetchData();
  }, []);
  

  useEffect(() => {
    setNewPhaseName((prev) => prev + inputValue);
  }, [inputValue]);

  useEffect(() => {
    newPhaseNameRef.current = search;
  }, [newPhaseName]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddPhaseButton = () => {
    openModal({
      className: "modal-add-phase",
      title: "Agregar fase de revisión",
      customComponent: (
        <div className="container-input-name">
          <p>Las fases nuevas siempre se agregan al final. Puedes ordenar las fases arrastrándolas.</p>
          <input
            type="text"
            className="input-phases"
            onChange={(e) => {
              setSearch(e.target.value);
              handleInputChange(e);
            }}
            value={search}
            placeholder="Nombre de fase"
          />
        </div>
      ),
      showCancel: true,
      buttons: [
        {
          label: "Guardar",
          onClick: handleSavePhase,
          className: "btn-save",
        }
      ],
    });
  };

  const handleSavePhase = () => {
    createPhase(newPhaseNameRef.current, token, setSearch, setInputValue, addNotification, loadData);
    closeModal();
    setInputValue("");
  };

  const loadData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PHASES_ENDPOINT}/get`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const phasesData = JSON.parse(response.data.body).data;
      const phasesDataArray = [];
  
      phasesData.forEach(({ phaseId, phaseName, isInitialPhase, nextPhaseIfApproved, groups }) => {
        const groupsAssigned = groups.map(({ id, name, active }) => ({
          groupId: id,
          groupName: name,
          groupActive: active,
        }));
  
        phasesDataArray.push({
          phaseId,
          phaseName,
          isInitialPhase,
          nextPhaseIfApproved,
          groupsAssigned,
        });
      });
      setListPhases(phasesDataArray);
      setTempListPhases(phasesDataArray);
    } catch (error) {
      console.error("Error al obtener fases:", error);
      addNotification("error", "Error al obtener fases");
    } 
  };

const handleDeletePhase = (id) => {
  const updatedListPhases = [...listPhases];
  const filteredPhases = updatedListPhases.filter((phase) => phase.phaseId !== id);
  setListPhases(filteredPhases);
  deletePhase(id, filteredPhases, token, loadData, addNotification)
};


const handleSavePhases = async () => {
  await updatePhases(tempListPhases, token, addNotification, loadData);
  setListPhases(tempListPhases);
};

const renderPhase = (phase, idx) => {
  if (phase.isInitialPhase===1){
    return (
      <Phase
        key={phase.phaseId}
        id={idx}
        phaseId={phase.phaseId}
        phases={listPhases}
        setPhases={setListPhases}
        phaseName={phase.phaseName}
        nextPhase={phase.nextPhaseIfApproved}
        groups={phase.groupsAssigned}
        onDeletePhase={() => handleDeletePhase(phase.phaseId)}
        token={token}
        showMenu={false}
        showButtonChip={false}
      />
    );
  }else{
    return (
      <Phase
        key={phase.phaseId}
        id={idx}
        phaseId={phase.phaseId}
        phases={listPhases}
        phaseName={phase.phaseName}
        nextPhase={phase.nextPhaseIfApproved}
        groups={phase.groupsAssigned}
        onDeletePhase={() => handleDeletePhase(phase.phaseId)}
        token={token}
      />
    );
  }
  
};

  return (
    <Container>
      <Header>
        <CustomButton label="Agregar fase de revisión" variant="white" onClick={handleAddPhaseButton}/>
      </Header>
      {listPhases.length > 0 ? (
        <ul>{listPhases.map((phase, idx) => renderPhase(phase, idx))}</ul>
      ) : (
        <div className="no-phases-container">
          <p>Parece que aun no tienes fases disponibles.</p>
        </div>
      )}
       <Modal
        {...modalData}
        onClose={handleCancelClick}
      />
       <CustomNotification
        open={notifications.length > 0}
        alerts={notifications}
        deleteNotification={deleteNotification}
      />
    </Container>
  );
};
