import styled from "styled-components";

export const Navbar = styled.ul`
  width: 100%;
  background-color: #f4f4f7;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  .sub-menu {
    width:100%;
    &:hover {
      background-color: #b3b3b3;
    }
    &:active{
      background-color: #b3b3b3;
    }
  }

  a {
    text-decoration: none;
    justify-content: ${(props) => (props.showMenuText ? 'space-between' : 'center')};
    width: 100%;
    padding: ${(props) => (props.showMenuText ? '13px 24px' : '10px 14px')};
    display: flex;
    flex-direction: column;
    align-items: ${(props) => (props.showMenuText ? 'start' : 'center')};

    .sub-menu {
      background-color: #f4f4f7;
      padding: 13px;
      gap:10px;
      padding-left: 80px;
      display: ${(props) => (props.showMenuText ? 'none' : 'flex')};

      p {
        font-family: Roboto;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
        letter-spacing: -0.015em;
        color: #503d66;
      }

      &:hover {
        background-color: #b3b3b3;
        
      }
    }

    &.active {
      background-color: transparent;
      padding: 0;
      #leftContainer {
        padding: ${(props) => (props.showMenuText ? '13px' : '10px')};
        padding-left: ${(props) => (props.showMenuText ? '24px' : '14px')};
      }

      .sub-menu {
        display: ${(props) => (props.showMenuText ? 'flex' : 'none')};
      }
    }

    &:hover {
      background-color: #b3b3b3;
    }
  }

  li {
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;

    #leftContainer {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      figure {
        width: 14px;
        height: 14px;

        img {
          width: 100%;
        }

        & + * {
          margin-left: 18px;
        }
      }

      p {
        font-family: Roboto;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.015em;
        color: #503d66;
      }
    }

    figure {
      width: 14px;
      height: 14px;

      img {
        width: 100%;
      }

    }

    #arrow {
      width: 6px;
      height: 10px;

      img {
        width: 100%;
      }
    }
  }

  @media (max-width: 768px) {
    a {
      padding: 10px 16px;
    }

    #leftContainer {
      flex-direction: column;
    }

    .sub-menu {
      padding-left: 40px;
    }
  }
`;
