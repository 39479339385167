import { Container } from "./styles";
import acceptIcon from "../../../assets/images/generalButton/acceptIcon.svg";
import rejectIcon from "../../../assets/images/generalButton/rejectIcon.svg";
import nullIcon from "../../../assets/images/generalButton/nullIcon.svg";

export const ValidationPanel = ({
  id,
  setOptionClicked,
  setShowValidationPanel,
  approve,
  reject,
  approveAll,
  rejectAll,
  showApproveRejectAll,
}) => {
  return (
    <Container id={id}>
      <button
        onClick={() => {
          approve && approve();
        }}
        className="validation-option"
      >
        <img src={acceptIcon} alt="accept button" />
        <p>Validado</p>
      </button>
      <button
        onClick={() => {
          reject && reject();
        }}
        className="validation-option"
      >
        <img src={rejectIcon} alt="reject button" />
        <p>Rechazado</p>
      </button>
      {showApproveRejectAll && (
        <>
          <button
            onClick={() => {
              approveAll && approveAll();
            }}
            className="validation-option"
          >
            <img src={acceptIcon} alt="accept button" />
            <p>Validar todo y enviar a evaluación</p>
          </button>
          <button
            onClick={() => {
              rejectAll && rejectAll();
            }}
            className="validation-option"
          >
            <img src={rejectIcon} alt="reject button" />
            <p>Rechazar todo y enviar a evaluación</p>
          </button>
        </>
      )}
    </Container>
  );
};