import {
  Container,
  ContainerItemCarrousel,
  ContainerMain,
  ContainerSecondary,
} from "./styles";
import { useEffect, useState, useRef } from "react";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";
import { Image as CustomImage } from "../../../atoms/Image";
import {
  faArrowUp as IconSend,
  faImage as IconImage,
  faTrash as IconDelete,
  faExclamationTriangle as IconWarning,
} from "@fortawesome/free-solid-svg-icons";
import { CustomIcon } from "../../../atoms/CustomIcon";
import { ButtonV2 } from "../../../atoms/ButtonV2";
import { IconFile } from "../../../atoms/IconFile";
import { Modal } from "../../Modal";
import { Tooltip } from "../../../atoms/Tooltip";
import { Fade, Tooltip as TooltipMUI } from "@mui/material";
import { ButtonFileChooser } from "../../../atoms/ButtonFileChooser";
import { Slide } from "@mui/material";
// carrousel
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
//import "swiper/modules/free-mode/free-mode.min.css";
import { FreeMode, Mousewheel, Keyboard } from "swiper";
import {
  getBase64,
  getDataBase64,
  getDataBuffer,
  getFileExtension,
  getFileName,
  getImageSize,
  isStringEmpty,
  isValidNaturalNumber,
} from "../../../../global-files/utils";
import { InputText } from "../../../atoms/InputText";

const S3_BUCKET = process.env.REACT_APP_IMAGES_BUCKET;
const REGION = "us-east-1";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_KUTS3,
  secretAccessKey: process.env.REACT_APP_AKUTS3,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export const Footer = (props) => {
  const {
    chatType, // "merchant_product" | "order_product" | "ticket"
    dataChat /* {
      caso ticket: { id }
      case merchant_product: { id , version }
      case order_product: { id , version }
    } */,
    statusChat, // string
    messageDisabled, // string
    createItemsChat, // (items) => {}
    errorUpdate, // { startDate , lastDate }
  } = props;
  const [valueInputMessage, setValueInputMessage] = useState("");
  const [filesPreview, setFilesPreview] = useState([]);
  const [filesJsxPreview, setFilesJsxPreview] = useState([]);
  const [btnSendFilesLoading, setBtnSendFilesLoading] = useState(false);
  const [btnSendMessageLoading, setBtnSendMessageLoading] = useState(false);
  const [btnChooserFileDisabled, setBtnChooserFileDisabled] = useState(false);
  const [inputMessageDisabled, setInputMessageDisabled] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [toggleFocusInputMessage, setToggleFocusInputMessage] = useState(false);
  const [tooltipFileName, setTooltipFileName] = useState({
    show: false,
    fileName: "",
  });
  const [modalAlert, setModalAlert] = useState({
    show: false,
    title: "",
    message: "",
    errorInputMessage: false,
  });
  const containerRef = useRef();
  const refInputTextMessage = useRef();

  useEffect(() => {
    setTooltipFileName({
      show: false,
      fileName: "",
    });
    renderFilesPreview();
  }, [filesPreview]);

  useEffect(() => {
    if (filesJsxPreview.length) setOpenPreview(true);
    else setOpenPreview(false);
  }, [filesJsxPreview]);

  useEffect(() => {
    refInputTextMessage.current && refInputTextMessage.current.focus();
  }, [toggleFocusInputMessage]);

  const sendFilesPreview = async () => {
    if (createItemsChat) {
      const files = filesPreview.slice();
      if (!files.length) {
        setModalAlert({
          show: true,
          title: "No hay ningun archivo o imagen seleccionado para enviar",
          message:
            "Por favor, cargue los archivos o imagenes que quiera enviar al chat",
          errorInputMessage: false,
        });
        return;
      }
      disableSecondaryContainer(true);

      // subir archivos a AWS
      const customFiles = [];
      try {
        const fileUploadRequests = [];
        for (const file of files) {
          let errorMessage;
          // obtener base64
          const fullBase64 = await getBase64(file);
          if (!fullBase64) {
            errorMessage = "No se pudo decodificar el archivo o imagen";
          }
          // obtener buffer
          let dataBuffer;
          if (!errorMessage)
            dataBuffer = getDataBuffer(getDataBase64(fullBase64));
          if (!dataBuffer) {
            errorMessage = "No se pudo decodificar el archivo o imagen";
          }
          // obtener la key
          let fileKey = "chat/";
          switch (chatType) {
            case "merchant_product":
              if (
                isValidNaturalNumber(dataChat?.id) &&
                isValidNaturalNumber(dataChat?.version)
              ) {
                fileKey += `merchantArticle/${dataChat.id}-${dataChat.version}/`;
              } else {
                errorMessage = "El ID o la version del producto no es valido";
              }
              break;

            case "order_product":
              if (
                isValidNaturalNumber(dataChat?.id) &&
                isValidNaturalNumber(dataChat?.version)
              ) {
                fileKey += `orderArticle/${dataChat.id}-${dataChat.version}/`;
              } else {
                errorMessage = "El ID o la version del producto no es valido";
              }
              break;

            case "ticket":
              if (isValidNaturalNumber(dataChat?.id)) {
                fileKey += `ticket/${dataChat.id}/`;
              } else {
                errorMessage = "El ID del ticket no es valido";
              }
              break;
            case "product_status":
              if (
                isValidNaturalNumber(dataChat?.id) &&
                isValidNaturalNumber(dataChat?.version) &&
                isValidNaturalNumber(dataChat?.retailerId)
              ) {
                fileKey += `productStatus/${dataChat.id}-${dataChat?.version}-${dataChat?.retailerId}/`;
              } else {
                errorMessage = "El ID del ticket no es valido";
              }
              break;

            default:
              errorMessage =
                "El tipo de chat no se especifico de manera correcta";
          }
          if (!errorMessage) {
            const today = new Date();
            fileKey += `${uuidv4()}-${today.getTime()}.${getFileExtension(
              file.name
            )}`;
          } else {
            fileKey = undefined;
          }
          // enviar file a AWS
          customFiles.push({
            itemType: file.type.split("/")[0] === "image" ? "img" : "file",
            key: fileKey,
            errorOwn: errorMessage,
          });
          const paramsCreate = {
            ACL: "public-read",
            Body: dataBuffer,
            Bucket: S3_BUCKET,
            Key: fileKey,
          };
          fileUploadRequests.push(myBucket.putObject(paramsCreate).promise());
        }
        const responseAWS = await Promise.allSettled(fileUploadRequests);
        responseAWS.forEach((responseFile, index) => {
          if (responseFile.status === "rejected") {
            customFiles[index].errorAWS = responseFile.reason.message;
            customFiles[index].uploaded = false;
          } else {
            customFiles[index].uploaded = true;
          }
        });
      } catch (err) {
        console.log(err);
        disableSecondaryContainer(false);
        setModalAlert({
          show: true,
          title: "hubo un problema al subir los archivos al chat",
          message: `${err.message}
          Reporta esto a TI`,
          errorInputMessage: false,
        });
        return;
      }

      // obtener las keys de los files subidos
      const items = [];
      for (let index = 0; index < customFiles.length; index++) {
        const file = customFiles[index];
        if (file.uploaded) {
          const valueItem = {
            key: file.key,
            name: files[index].name,
          };
          // caso imagen -> obtener width y height
          if (file.itemType === "img") {
            const imgSize = await getImageSize(files[index]);
            if (imgSize) {
              valueItem.width = imgSize.width;
              valueItem.height = imgSize.height;
            }
          }
          items.push({ type: file.itemType, value: JSON.stringify(valueItem) });
        }
      }

      // enviar los archivos subidos en AWS a la BD
      if (items.length) {
        const responseError = await createItemsChat(items);
        // cuando ningun archivo se guardo en la BD
        if (responseError) {
          setModalAlert({
            show: true,
            title: responseError.message,
            message: responseError.errorDetail,
            errorInputMessage: false,
          });
          disableSecondaryContainer(false);
          return;
        }
      }

      // obtener los errores de los files no subidos a AWS
      const failedFiles = [];
      let errorDetailFiles = "";
      customFiles.forEach((file, index) => {
        if (!file.uploaded) {
          if (failedFiles.length === 0) {
            errorDetailFiles = `${files[index].name}:
            Error: ${file.errorOwn ? file.errorOwn : file.errorAWS}`;
          } else {
            errorDetailFiles += `${"\n\n"}${files[index].name}:
            Error: ${file.errorOwn ? file.errorOwn : file.errorAWS}`;
          }
          failedFiles.push(files[index]);
        }
      });
      if (failedFiles.length) {
        setModalAlert({
          show: true,
          title: `${failedFiles.length}/${files.length} archivos no fueron enviados al chat`,
          message: errorDetailFiles,
          errorInputMessage: false,
        });
      }
      // actualizar filesPreview
      disableSecondaryContainer(false);
      setFilesPreview(failedFiles);
    }

    /*
    if (file.type.split("/")[0] === "image") {
      if (arrayIMG.data.ext[0] === "svg") {
        handlerCreateItem("file", params.Key);
      } else handlerCreateItem("img", params.Key);
    } else if (
      file.type.split("/")[0] === "application" ||
      file.type.split("/")[0] === "text"
    ) {
      handlerCreateItem("file", params.Key);
    }
    */
  };

  const sendMessage = async () => {
    if (createItemsChat) {
      if (isStringEmpty(valueInputMessage)) {
        setValueInputMessage("");
        setModalAlert({
          show: true,
          title: "mensaje vacío",
          message:
            "Por favor, especifica el mensaje que quieres enviar al chat",
          errorInputMessage: true,
        });
        return;
      }
      setBtnSendMessageLoading(true);
      setBtnChooserFileDisabled(true);
      setInputMessageDisabled(true);
      const responseError = await createItemsChat([
        {
          type: "message",
          value: valueInputMessage.trim(),
        },
      ]);
      setInputMessageDisabled(false);
      setBtnSendMessageLoading(false);
      setBtnChooserFileDisabled(false);
      if (responseError) {
        setModalAlert({
          show: true,
          title: responseError.message,
          message: responseError.errorDetail,
          errorInputMessage: true,
        });
      } else {
        setValueInputMessage("");
        setToggleFocusInputMessage((prev) => !prev);
      }
    }
  };

  const disableSecondaryContainer = (disabled = false) => {
    setBtnSendFilesLoading(disabled);
    setBtnChooserFileDisabled(disabled);
    setOpenPreview(!disabled);
  };

  const deleteFile = async (index) => {
    let newFiles = filesPreview.slice();
    newFiles.splice(index, 1);
    setFilesPreview(newFiles);
  };

  const renderFilesPreview = async () => {
    const jsxFiles = [];
    for (let index = 0; index < filesPreview.length; index++) {
      const file = filesPreview[index];
      const fileType = file.type?.split("/")[0];
      const base64 = await getBase64(file);
      jsxFiles.push(
        <SwiperSlide className="item-carrousel" key={"item-" + index}>
          <ContainerItemCarrousel>
            {fileType === "image" ? (
              <CustomImage
                width={"100%"}
                className={"container-img"}
                sizeLoading={40}
                colorLoading={undefined}
                src={base64}
                componentDefault={
                  <CustomIcon
                    className="icon-img"
                    size={60}
                    icon={IconImage}
                    type={"pink"}
                    transparent
                  />
                }
              />
            ) : (
              <IconFile
                transparent
                size={60}
                ext={getFileExtension(file.name)}
              />
            )}

            <div
              className="container-delete"
              onMouseEnter={() => {
                setTooltipFileName({
                  show: true,
                  fileName: file.name,
                });
              }}
              onMouseLeave={() => {
                setTooltipFileName({
                  show: false,
                  fileName: "",
                });
              }}
            >
              <ButtonV2
                className="btn-delete"
                type={"white"}
                label={"Eliminar"}
                size={8}
                borderType={"oval"}
                icon={IconDelete}
                isLoading={undefined}
                onClick={async (event) => {
                  deleteFile(index);
                }}
              />
            </div>
          </ContainerItemCarrousel>
        </SwiperSlide>
      );
    }
    setFilesJsxPreview(jsxFiles);
  };

  const onChangeFiles = async (selectedFiles) => {
    let newFiles = filesPreview.slice();
    newFiles = newFiles.concat(selectedFiles);
    setFilesPreview(newFiles);
  };

  const getErrorUpdate = () => {
    if (errorUpdate?.startDate) {
      let errorDetail = "Ultima actualización hace ";
      if (errorUpdate?.lastDate) {
        let msDif =
          errorUpdate.lastDate.getTime() - errorUpdate.startDate.getTime();
        const minutes = Math.floor(msDif / 60000);
        const hours = Math.floor(minutes / 60);
        if (hours === 0) {
          if (minutes === 0) errorDetail += "menos de 1 minuto";
          else if (minutes === 1) errorDetail += "1 minuto";
          else errorDetail += minutes + " minutos";
        } else {
          if (hours === 1) errorDetail += "1 hora con ";
          else errorDetail += hours + " horas con ";

          if (minutes === 0) errorDetail += "menos de 1 minuto";
          else if (minutes === 1) errorDetail += "1 minuto";
          else errorDetail += minutes + " minutos";
        }
      } else {
        errorDetail += "menos de 1 minuto";
      }
      // retornar el icono
      return (
        <Tooltip
          componentTooltip={
            <div>
              <label className="label-title">Chat desactualizado</label>
              <label className="label-detail">{errorDetail}</label>
            </div>
          }
          className="container-ErrorUpdate"
          classNameTooltip="tooltipErrorUpdate"
          position={"topCenter"}
          addArrow={false}
          transitionType={"zoom"}
          followCursor={false}
        >
          <CustomIcon
            className="icon-errorUpdate"
            size={15}
            icon={IconWarning}
            type={"pink"}
            transparent
          />
        </Tooltip>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Container ref={containerRef}>
        {statusChat === "closed" ? (
          <>
            {/* chat bloqueado */}
            <div className="container-chatDisabled">
              {/* icono de chat desactualizado */}
              {getErrorUpdate()}

              <div className="label-chatDisabled">{messageDisabled}</div>
            </div>
          </>
        ) : filesJsxPreview.length === 0 ? (
          <>
            {/* container del input y el btn sendTXT */}
            <ContainerMain>
              {/* icono de chat desactualizado */}
              {getErrorUpdate()}

              <InputText
                className="input-itemMessage"
                type="gray"
                transparent
                size={13}
                placeHolder="Escribe aquí..."
                multiline
                maxRows={5}
                minRows={1}
                disabled={inputMessageDisabled}
                text={valueInputMessage}
                onChangeText={(newValue) => setValueInputMessage(newValue)}
                onEnter={async (event) => await sendMessage()}
                refInputText={refInputTextMessage}
              />

              {/* selector de files */}
              <ButtonFileChooser
                className="btn-chooserFiles"
                type={"gray"}
                transparent
                size={18}
                disabled={btnChooserFileDisabled}
                showIcon={true}
                multipleFiles={true}
                onChangeFiles={onChangeFiles}
              />

              {/* boton send message */}
              <ButtonV2
                className="btn-sendMessage"
                type={"pink"}
                size={10}
                borderType={"circle"}
                icon={IconSend}
                isLoading={btnSendMessageLoading}
                onClick={async (event) => {
                  await sendMessage();
                }}
              />
            </ContainerMain>
          </>
        ) : (
          <>
            {/* container para el modo previewFiles */}
            <TooltipMUI
              open={openPreview}
              placement={"top-start"}
              arrow={false}
              componentsProps={{
                popper: {
                  className: "tooltip-previewFiles",
                  disablePortal: true,
                },
                transition: { timeout: 300 },
              }}
              title={
                <TooltipMUI
                  open={tooltipFileName.show}
                  placement={"top"}
                  arrow={false}
                  followCursor={true}
                  TransitionComponent={Fade}
                  componentsProps={{
                    popper: {
                      className: "tooltip-previewFileName",
                      disablePortal: true,
                    },
                    transition: { timeout: 300 },
                  }}
                  title={<label>{tooltipFileName.fileName}</label>}
                >
                  <div className="container-carrouselFiles">
                    <Swiper
                      spaceBetween={5}
                      slidesPerView={"auto"}
                      freeMode
                      mousewheel
                      keyboard
                      modules={[Mousewheel, Keyboard, FreeMode]}
                      className="carrousel-files"
                    >
                      {filesJsxPreview}
                    </Swiper>
                  </div>
                </TooltipMUI>
              }
            >
              <ContainerSecondary>
                {/* icono de chat desactualizado */}
                {getErrorUpdate()}

                {/* selector de files */}
                <Slide
                  direction={"left"}
                  in={true}
                  timeout={500}
                  container={containerRef.current}
                >
                  <div>
                    <ButtonFileChooser
                      className={"btn-chooserFiles"}
                      type={"gray"}
                      size={17}
                      borderType="circle"
                      showIcon={true}
                      disabled={btnChooserFileDisabled}
                      multipleFiles={true}
                      onChangeFiles={onChangeFiles}
                    />
                  </div>
                </Slide>

                {/* boton send files */}
                <Slide
                  direction={"up"}
                  in={true}
                  timeout={500}
                  container={containerRef.current}
                >
                  <div className="slide-btnSendFiles">
                    <ButtonV2
                      className="btn-sendFiles"
                      type={"pink"}
                      label={"Enviar archivos"}
                      size={12}
                      borderType={"oval"}
                      loading={btnSendFilesLoading}
                      isLoading={btnSendFilesLoading}
                      onClick={async (event) => {
                        await sendFilesPreview();
                      }}
                    />
                  </div>
                </Slide>
              </ContainerSecondary>
            </TooltipMUI>
          </>
        )}
      </Container>

      <Modal
        className="container-modalAlert"
        show={modalAlert.show}
        title={modalAlert.title}
        message={modalAlert.message}
        icon={"info"}
        onClickBtnDefault={(event) => {
          setModalAlert((prev) => ({
            ...prev,
            show: false,
            errorInputMessage: false,
          }));
          setToggleFocusInputMessage((prev) => !prev);
        }}
      />
    </>
  );
};
