import { Container } from "./styles";
import IconInfo from "../../../assets/images/Icons/info.svg";
import TextField from "@mui/material/TextField";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { FontFamily, GlobalColors } from "../../../global-files/variables";

export const BoxAttribute = (props) => {
  const {
    id,
    key,
    className,
    text,
    borderType,
    atributos,
    setAtributos,
    required,
    description,
  } = props;
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAtributos((prevAtributos) => ({
      ...prevAtributos,
      [name]: value,
    }));
  };
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: GlobalColors.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
      fontFamily: `${FontFamily.Roboto}, sans-serif`,
    },
  }));
  return (
    <>
      <Container
        id={id}
        key={key}
        className={className}
        borderType={borderType}
      >
        <LightTooltip
          title={
            <div className="container-tooltip">
              <p className="container-tooltip">{description}</p>
            </div>
          }
        >
          <Container className="">
            <img className="icon" src={IconInfo} alt="iconInfo" />
          </Container>
        </LightTooltip>
        <span>
          {text}
          <span className={required ? "required-text" : ""}>
            {required ? "*" : ""}
          </span>
        </span>
        <TextField
          hiddenLabel
          key={props.id}
          name={props.id}
          value={atributos[props.id]}
          onChange={handleInputChange}
          className="caja-borde"
          variant="outlined"
          size="small"
          type="number"
          required={required}
        />
      </Container>
    </>
  );
};
