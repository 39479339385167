import { Container } from "./styles";
import { Avatar } from "../../atoms/Avatar";
import { useEffect, useState } from "react";
import { AsignationOption } from "../../atoms/AsignationOption";
import { DropDownButton } from "../../atoms/DropDownButton";

export const RetailerSelector = ({
  retailers,
  activeRetailer,
  setActiveRetailer,
  showStatus,
}) => {
  const [retailersPanel, setRetailersPanel] = useState(false);

  const closeRetailersPanel = (e) => {
    if (!e.target.closest("#retailers-assignation") && retailersPanel) {
      document.removeEventListener("click", closeRetailersPanel, false);
      setRetailersPanel(false);
    }
  };

  const getRetailerImage = async () => {
    activeRetailer.image = `https://content-management-images.s3.amazonaws.com/retailers/${activeRetailer.id}.png`;
    retailers.forEach(
      (retailer) =>
        (retailer.image = `https://content-management-images.s3.amazonaws.com/retailers/${retailer.id}.png`)
    );
  };

  useEffect(() => {
    if (retailersPanel) {
      document.addEventListener("click", closeRetailersPanel, false);
    }
  }, [retailersPanel]);

  useEffect(() => {
    getRetailerImage();
  }, [activeRetailer]);

  return (
    <Container id="retailers-assignation">
      <Avatar
        image={activeRetailer?.image}
        altText={activeRetailer?.name}
        imageType={"medium-image"}
        onClick={() => {
          retailers?.length > 0 && setRetailersPanel(!retailersPanel);
          if (retailersPanel)
            document.removeEventListener("click", closeRetailersPanel, false);
        }}
      />
      <DropDownButton active={retailersPanel} />
      {retailersPanel && (
        <div className="retailers-panel">
          {retailers.map((retailer, index) => (
            <AsignationOption
              key={`${index}-${retailer?.name}`}
              profileImage={retailer?.image}
              imageType={"medium-image"}
              asignationType={null}
              name={retailer?.name}
              team={retailer?.team}
              services={retailer?.services}
              onClick={() => {
                setActiveRetailer(retailer);
                document.removeEventListener(
                  "click",
                  closeRetailersPanel,
                  false
                );
                setRetailersPanel(false);
              }}
              active={retailer.id === activeRetailer.id}
              showStatus={showStatus}
            />
          ))}
        </div>
      )}
    </Container>
  );
};
