import { Container, ContainerIcon } from "./styles";
import { Button } from "@mui/material";

export const BoxButtons = ({
  showAdd,
  onAdd,
  onDelete,
  isTheFirstBox,
  index,
}) => {
  return (
    <Container index={index}>
      {!isTheFirstBox && (
        <Button variant="outlined" className="button-circle" onClick={onDelete}>
          <ContainerIcon>
            <span className="iconos-out">delete_forever</span>
          </ContainerIcon>
        </Button>
      )}
      {showAdd && (
        <Button onClick={onAdd} variant="outlined" className="button-circle">
          <ContainerIcon>
            <span className="iconos-out iconos-grises">add</span>
          </ContainerIcon>
        </Button>
      )}
    </Container>
  );
};
