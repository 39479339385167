import React from 'react'
import { ContainerPrincipal } from './styles'
import notFound from '../../assets/Backgrounds/notFound.svg'

const NotFound = () => {
  return (
    <ContainerPrincipal>
        <img className='not-found' src={notFound} alt="error-404"/>
        <div className='container-text'>
            <h2>Lo sentimos la página que buscas no existe</h2>
        </div>
    </ContainerPrincipal>
  )
}

export default NotFound