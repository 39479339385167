import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonBase } from './styles';
import Tooltip from '../Tooltip';

const CustomButton = ({
  label,
  onClick,
  className = '',
  variant = 'pink',
  disabled = false,
  icon,
  iconOnly = false,
  iconVariant = 'outlined',
  pulsating = false,
  showTooltip = false,
  tooltipText = '',
  type = 'default',
  onFileChange,
  shape = 'capsule',
  accept
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const computedShape = iconOnly ? 'circle' : shape;

  const handleMouseEnter = () => {
    if (showTooltip) setIsHovered(true);
  };

  const handleMouseLeave = () => {
    if (showTooltip) setIsHovered(false);
  };

  const iconClass = iconVariant === 'filled' 
  ? 'material-symbols-outlined filled' 
  : 'material-symbols-outlined';

  if (type === 'file') {
    return (
      <ButtonBase
        as="label"
        className={className}
        variant={variant}
        disabled={disabled}
        iconOnly={iconOnly}
        pulsating={pulsating}
        shape={computedShape}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {icon && <span className={`icon ${iconClass}`}>{icon}</span>}
        {!iconOnly && label}
        {pulsating && <div className="pulsating-circle"></div>}
        {showTooltip && tooltipText && (
          <span className="tooltip-text">{tooltipText}</span>
        )}
        <input
          type="file"
          style={{ display: 'none' }}
          onChange={onFileChange}
          accept={accept} 
        />
      </ButtonBase>
    );
  }

  return (
    <Tooltip title={showTooltip && tooltipText ? tooltipText : ''}>
    <ButtonBase
      onClick={onClick}
      className={className}
      variant={variant}
      disabled={disabled}
      iconOnly={iconOnly}
      pulsating={pulsating}
      shape={computedShape}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {icon && <span className={`icon ${iconClass}`}>{icon}</span>}
      {!iconOnly && label}
      {pulsating && <div className="pulsating-circle"></div>}
    </ButtonBase>
    </Tooltip>
  );
};

CustomButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['pink', 'white', 'gray', 'purple', 'yellow', 'red','blue-light','green']),
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  iconOnly: PropTypes.bool,
  pulsating: PropTypes.bool,
  showTooltip: PropTypes.bool,
  tooltipText: PropTypes.string,
  type: PropTypes.oneOf(['default', 'file']), 
  onFileChange: PropTypes.func,
  accept: PropTypes.string,
  iconVariant: PropTypes.oneOf(['outlined', 'filled']),
  shape: PropTypes.oneOf(['capsule', 'rectangle', 'circle'])
};

export default CustomButton;
