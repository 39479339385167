export const TEMPLATES_URLS = {
  retailerTHD:
    "https://content-management-images-prod.s3.amazonaws.com/templates/_creacion_de_productos_cadena.xlsx",
  retailerBodega:
    "https://content-management-images-prod.s3.amazonaws.com/templates/plantilla_creacion_de_productos_cadena_bodega.xlsx",
  providerBodega:
    "https://content-management-images-prod.s3.amazonaws.com/templates/plantilla_creacion_de_productos_financiados_proveedor_bodega.xlsx",
  provider:
    "https://content-management-images-prod.s3.amazonaws.com/templates/plantilla_creacion_de_productos.xlsx",
  providerFinanced:
    "https://content-management-images-prod.s3.amazonaws.com/templates/plantilla_creacion_de_productos_financiados.xlsx",
  providerMerchants:
    "https://content-management-images-prod.s3.amazonaws.com/templates/plantilla_merchants.xlsx",
  onboarding:
   "https://content-management-images-prod.s3.amazonaws.com/templates/plantilla_creacion_de_productos_onboarding.xlsx",
};
