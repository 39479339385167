import styled from "styled-components";

export const Validation = styled.div`
  width: 90%;
  place-content: end;
  place-self: end;
  display:flex;
  flex-wrap:wrap;
  border-radius:5px;
  align-items: center;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  line-height: 11.72px;
  text-align: left;
  color: var(--gray-contentoh);
  gap: 5px;

  .validation-label{
    display:flex;
    gap:5px;
  }

  .check {
    font-size:12px;
    color: green;
  }

  .cancel {
    font-size:12px;
    color: red;
  }
  p {
    margin-bottom: 10px;
    width: 100%;
  }
  .gray{
    color: #CBCBCB;
    font-size:18px;
  }
  input {
    height: 30px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 11px;
    color: #817393;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    padding: 10px;
    border:none;
    outline: none;
    resize: none;
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::placeholder {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.06px;
      text-align: left;
      color:#CBCBCB;
    }
  }
  select {
    width: 100%;
    height: 30px;
  }
  label {
    font-size: 11px;
    color: red;
    font-weight: 700;
  }
  .resendTrue {
    color: green;
  }
  .password {
    & + * {
      margin-top: 20px;
    }
    &:hover {
      border: 1px solid var(--pink-contentoh);
    }
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    position: relative;
    width: 100%;
    border: 1px solid #f0eef2;
    .icon {
      position: absolute;
      height: 60%;
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      opacity: 0.3;
      cursor: pointer;
    }
    .icon:hover {
      opacity: 0.8;
    }
  }
`;
