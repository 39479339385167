import styled from "styled-components";
import { GlobalColors } from "../../../global-files/variables";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  overflow: auto;
  position: fixed;
  background-color: rgba(59, 59, 59, 0.53);
  backdrop-filter: blur(4px);
  left: 0;
  top: 0;
  z-index: 100;

  .modal-container {
    display: flex;
    border-radius: 4px;
    overflow: hidden;

    .modal-data-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      min-width: 375px;
      background-color: ${GlobalColors.deep_gray};

      .validation-and-table-container {
        .validation-panel {
          position: relative;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .circular-button {
            & + * {
              margin-left: 10px;
            }
          }

          .close-button {
            border-radius: 3px;
            width: 30px;
            height: 30px;
            padding: 0;
            border: 1px solid #f0eef2;
            background-size: 30px;
          }

          & + * {
            margin-top: 25px;
          }
        }
      }
    }
  }
`;
