import { Container, ContainerLoading, ContainerModalImg, Img } from "./styles";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import {
  faSpinner as iconLoading,
  faArrowDown as IconDownload,
} from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { Fade, Grow, Modal as ModalMUI, Zoom } from "@mui/material";
import { Modal } from "../../organisms/Modal";
import { ButtonV2 } from "../ButtonV2";
import axios from "axios";
import { Image } from "../Image";

export const ImagePreview = (props) => {
  const {
    className, // string
    width, // string
    sizeLoading, // number
    colorLoading, // string
    downloadImgURL, // URL de la imagen a descargar (string)
    imgURL, // imagen a cargar (string)
    modalImgURL, // imagen a cargar (string) para el modo full
    imageName, // nombre del file
    showButtonDownload, // boolean
    componentDefault, // componente a mostrar en caso de que no cargue src (JSX)
    componentDefaultModal, // componente a mostrar en el modal en caso de que no cargue srcModal (JSX)
  } = props;
  const [imgLoad, setImgLoad] = useState();
  const [imgLoadModal, setImgLoadModal] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [showModalImg, setShowModalImg] = useState(false);
  const [modalAlert, setModalAlert] = useState({
    show: false,
    title: "",
    message:
      "Ocurrió un error al descargar la imagen ¿Quieres intentarlo de nuevo?",
    icon: "info",
    buttons: [
      <ButtonV2
        key={"btn-No"}
        type={"white"}
        borderType={"oval"}
        label={"No"}
        size={12}
        onClick={(event) => {
          setModalAlert((prev) => ({
            ...prev,
            show: false,
          }));
        }}
      />,
      <ButtonV2
        key={"btn-Intentar"}
        type={"pink"}
        borderType={"oval"}
        label={"Intentar"}
        size={12}
        onClick={(event) => {
          setModalAlert((prev) => ({
            ...prev,
            show: false,
          }));
          getImage();
        }}
      />,
    ],
  });

  const getImage = () => {
    //setLoadingDownload(true);
    const link = document.createElement("a");
    link.href = downloadImgURL;
    link.setAttribute("download", imageName ?? "image");
    link.style.display = "none";
    link.onerror = () => {
      setModalAlert((prev) => ({
        ...prev,
        show: true,
      }));
      return;
    };
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container className={className} width={width}>
      {imgURL && (imgLoad === undefined || loading) && (
        <ContainerLoading
          className={"container-loading"}
          sizeLoading={sizeLoading}
          colorLoading={colorLoading}
        >
          <Icon className="icon" pulse icon={iconLoading} />
        </ContainerLoading>
      )}

      {(!imgURL || (imgLoad === false && !loading)) && componentDefault}

      <Img
        show={imgLoad && !loading}
        className="img"
        src={imgURL}
        onLoad={(event) => {
          setImgLoad(true);
        }}
        onError={(event) => {
          setImgLoad(false);
        }}
        onClick={(event) => {
          setShowModalImg(true);
        }}
      />

      {/* modal que muestra la img en pantalla completa con
      boton de download en caso de ser especificado */}
      <ModalMUI
        open={showModalImg}
        disablePortal={true}
        closeAfterTransition
        componentsProps={{
          root: {
            className: "container-modal",
          },
          backdrop: {
            timeout: 400,
            className: "container-background",
          },
        }}
        onClick={(event) => {
          if (!loadingDownload) setShowModalImg(false);
        }}
      >
        <Fade in={showModalImg} timeout={400}>
          <ContainerModalImg>
            {/* icono download */}
            {showButtonDownload && (
              <div className="container-header">
                <ButtonV2
                  className={"container-btnDownload"}
                  type={"pink"}
                  size={14}
                  borderType={"circle"}
                  icon={IconDownload}
                  isLoading={loadingDownload}
                  onClick={(event) => {
                    event.stopPropagation();
                    getImage();
                  }}
                />
              </div>
            )}

            <Image
              className="container-imgModal"
              classNameImg="img"
              sizeLoading={100}
              colorLoading={"white"}
              src={modalImgURL}
              componentDefault={componentDefaultModal}
            />
          </ContainerModalImg>
        </Fade>
      </ModalMUI>

      {/* modal utilizado para alertar cuando falla la descarga */}
      <Modal
        show={modalAlert.show}
        title={modalAlert.title}
        message={modalAlert.message}
        icon={modalAlert.icon}
        buttons={modalAlert.buttons}
      />
    </Container>
  );
};
