import styled from 'styled-components';

export const ButtonBase = styled.button`
  width: ${({ variant }) =>
    variant === "pink" || variant === "white"
      ? "auto"
      : variant === "gray"
      ? "34px"
      : "auto"};

  height: ${({ variant }) =>
    variant === "pink" || variant === "white"
      ? "auto"
      : variant === "gray"
      ? "34px"
      : "auto"};

  cursor: pointer;
  border-radius: ${({ shape }) => {
    switch (shape) {
      case 'rectangle':
        return '5px';
      case 'circle':
        return '50%';
      default:
        return '30px';
    }
  }};
  
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-size: ${({ variant }) =>
    variant === "purple" || variant === "yellow" || variant === "red" || variant === "green"
      ? "10px"
      : "12px"};
  line-height: 14px;
  font-weight: 600;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  background-color: ${({ variant }) =>
    variant === "white"
      ? "var(--white-contentoh)"
      : variant === "pink"
      ? "var(--pink-contentoh)"
      : variant === "gray"
      ? "transparent"
      : variant === "purple" || variant === "yellow" || variant === "red" || variant === "green"
      ? "var(--white-contentoh)"
      : variant === "blue-light"
      ? "var(--blue-light)"
      : "transparent"};

  color: ${({ variant }) =>
    variant === "white"
      ? "var(--pink-contentoh)"
      : variant === "pink"
      ? "var(--white-contentoh)"
      : variant === "gray"
      ? "var(--gray-contentoh)"
      : variant === "purple"
      ? "var(--purple)"
      : variant === "yellow"
      ? "var(--yellow)"
      : variant === "red"
      ? "var(--red-contentoh)"
      : variant === "blue-light"
      ? "var(--gray-contentoh)"
      : variant === "green"
      ? "var(--green)"
      : "inherit"};

  border: ${({ variant }) =>
    variant === "pink" || variant === "white"
      ? "1px solid var(--pink-contentoh)"
      : variant === "gray"
      ? "1px solid var(--gray-light)"
      : variant === "purple" || variant === "yellow" || variant === "red" || variant === "green"
      ? "1px solid var(--gray-light)"
      : "none"};

  padding: ${({ variant, iconOnly }) =>
    iconOnly
      ? "0"
      : variant === "purple" || variant === "yellow" || variant === "red" || variant === "blue-light" || variant === "green"
      ? "3px 5px"
      : "9px 18px"};

  &:hover {
    background-color: ${({ variant }) =>
      variant === "white"
        ? "var(--pink-contentoh)"
        : variant === "pink"
        ? "var(--white-contentoh)"
        : variant === "gray"
        ? "var(--pink-contentoh)"
        : variant === "purple"
        ? "var(--purple)"
        : variant === "yellow"
        ? "var(--yellow)"
        : variant === "red"
        ? "var(--red-contentoh)"
        : variant === "blue-light"
        ? "var(--gray-light)" 
        : variant === "green"
        ? "var(--green)" 
        : "inherit"};

        color: ${({ variant }) =>
          variant === "gray" ||
          variant === "white" ||
          variant === "purple" ||
          variant === "yellow" ||
          variant === "red" ||
          variant === "green"
            ? "var(--white-contentoh)"
            : variant === "pink"
            ? "var(--pink-contentoh)"
            : variant === "blue-light"
            ? "var(--purple-light-contentoh)"
            : "inherit"};        

    border: ${({ variant }) =>
      variant === "gray"
        ? "1px solid var(--pink-contentoh)"
        : variant === "pink"
        ? "1px solid var(--pink-contentoh)"
        : variant === "purple"
        ? "1px solid var(--purple)"
        : variant === "yellow"
        ? "1px solid var(--yellow)"
        : variant === "red"
        ? "1px solid var(--red-contentoh)"
        : variant === "green"
        ? "1px solid var(--green)"
        : variant === "blue-light"
        ? "none" 
        : "inherit"};
  }

  &:disabled {
    background-color: #f0f0f0;
    color: #b0b0b0;
    cursor: not-allowed;
    border: 1px solid #d0d0d0;
  }

  .icon {
    margin-right: ${({ variant, iconOnly }) =>
      variant === "purple" || variant === "yellow" || variant === "red"
        ? "4px"
        : iconOnly
        ? "0"
        : "8px"};
    font-size: ${({ variant, iconOnly }) =>
      variant === "gray"
        ? "18px"
        : variant === "purple" || variant === "yellow" || variant === "red"
        ? "14px"
        : iconOnly
        ? "24px"
        : "18px"};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pulsating-circle {
    position: absolute;
    top: ${({ iconOnly }) => (iconOnly ? "3px" : "50%")};
    right: ${({ iconOnly }) => (iconOnly ? "-10px" : "50%")};
    transform: translate(-50%, -50%);
    width: 15px;
    height: ${({ pulsating }) => (pulsating ? "15px" : "0px")};
    display: ${({ pulsating }) => (pulsating ? "block" : "none")};

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--purple);
      border-radius: 50%;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
      animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
      border: 2px solid var(--white-contentoh);
      border-radius: 50%;
    }
  }

  @keyframes pulse-dot {
    0% {
      transform: scale(0.5);
    }
    50% {
      transform: scale(0.7);
    }
    100% {
      transform: scale(0.5);
    }
  }

  .filled.material-symbols-outlined {
    font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
  }
`;
