import { Container } from "./styles";
import { TabSection } from "../../atoms/TabSection";
import { useEffect, useState } from "react";

import { Modal } from "../../organisms/Modal";
import { ButtonV2 } from "../../atoms/ButtonV2";

export const TabsMenu = ({
  tabsSections = {},
  setImagesSection,
  setActiveTab,
  activeTab,
  updatedDescriptions,
  updatedDatasheets,
  images,
  setUpdatedDescriptions,
  setUpdatedDatasheets,
  setImages,
}) => {
  const [sections, setSections] = useState(tabsSections);

  const [modal, setModal] = useState(false);
  const [cancelAccept, setCancelAccept] = useState("");
  const [llave, setLlave] = useState("");
  const [objeto, setObjeto] = useState();

  const activeSection = (key, array = {}) => {
    let tempArray = {};
    setImagesSection(key === "Imágenes");
    Object.keys(array).forEach((section) => {
      tempArray[section] = section === key;
      if (section === key) return setActiveTab(key);
    });
    setSections(tempArray);
  };

  useEffect(() => {
    if (cancelAccept === "Aceptar" && llave !== "") {
      setCancelAccept("");
      setModal(false);
      activeSection(llave, objeto);
      setUpdatedDescriptions([]);
      setUpdatedDatasheets([]);
      setImages({});
    } else if (cancelAccept === "Cancelar" && llave !== "") {
      setModal(false);
      setCancelAccept("");
    }
  }, [cancelAccept]);

  const detectClickFunction = (e, key, sections) => {
    const targetIsTheCurrentTab = e.target.innerHTML === activeTab;
    const descriptionsWereUpdated = updatedDescriptions.length > 0;
    const datasheetWereUpdated = updatedDatasheets.length > 0;
    const imagesWereUpdated = images?.values?.filter((f) => !f.id)?.length > 0;
    const dataWereUpdated =
      descriptionsWereUpdated || datasheetWereUpdated || imagesWereUpdated;

    if (!targetIsTheCurrentTab && !dataWereUpdated) {
      setLlave(key);
      setObjeto(sections);
      activeSection(key, sections);
    } else if (!targetIsTheCurrentTab && dataWereUpdated) {
      setModal(true);
      setLlave(key);
      setObjeto(sections);
    }
  };

  return (
    <>
      {activeTab && (
        <Modal
          show={modal}
          title="Cambios sin Guardar"
          message="Estás cambiando de vista y aun no guardas cambios ¿Estás seguro?"
          buttons={[
            <ButtonV2
              key="btn-Cancelar"
              type="white"
              label="Cancelar"
              size={12}
              onClick={() => setCancelAccept("Cancelar")}
            />,
            <ButtonV2
              key="btn-Aceptar"
              type="pink"
              label="Aceptar"
              size={12}
              onClick={() => setCancelAccept("Aceptar")}
            />,
          ]}
        />
      )}

      <Container>
        {Object.keys(sections).map((key, index) => (
          <TabSection
            key={index}
            label={key}
            active={sections[key]}
            onClick={(e) => {
              detectClickFunction(e, key, sections);
            }}
          />
        ))}
      </Container>
    </>
  );
};
