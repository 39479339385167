import React from "react";
import PropTypes from "prop-types";
import StyledTooltip from "./styles";

const Tooltip = ({ title, children, position = "top", variant = "white" }) => {
  return (
    <StyledTooltip title={title} placement={position} variant={variant}>
      {children}
    </StyledTooltip>
  );
};

Tooltip.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf([
    "top",
    "bottom",
    "left",
    "right",
    "top-start",
    "top-end",
    "bottom-start",
    "bottom-end",
    "left-start",
    "left-end",
    "right-start",
    "right-end",
  ]),
  variant: PropTypes.oneOf(["gray", "white", "blue-light"]),
};

export default Tooltip;