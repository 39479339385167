import React from "react";
import { Header } from "./styles";
import edit from "../../../assets/IconComponents/edit.svg";

const HeaderData = ({ icon, text, active, setActive, showEdit = true }) => {
  return (
    <Header>
      <div>
        <figure>
          <img src={icon} alt="Section icon" />
        </figure>
        <p>{text}</p>
      </div>
      {showEdit && !active && (
        <figure onClick={() => setActive(!active)} className="edit">
          <img src={edit} alt="Edit" />
        </figure>
      )}
    </Header>
  );
};

export default HeaderData;
