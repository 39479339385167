import axios from 'axios';

export const fetchGroups = async (userId, token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_USERS_ONBOARDING_ENDPOINT}/groups/${userId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data.groups;
  } catch (error) {
    console.error('Error fetching user groups:', error);
    return [];
  }
};

export const updateGroup = async ({ userId, token, groupsIds }) => {
  try {
    const body = {
      userIds: [userId],
      groupsIds,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_USERS_ONBOARDING_ENDPOINT}/update`,
      body,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    console.log('Group updated successfully', response.data.body);
  } catch (error) {
    console.error('Error updating user groups:', error);
  }
};

export const deleteGroup = async ({ userId, token, groupsIds }) => {
  try {
    const body = {
      userIds: [userId],
      groupsIds,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_USERS_ONBOARDING_ENDPOINT}/delete`,
      body,
      {
        headers: {
          Authorization: sessionStorage.getItem('jwt'),
        },
      }
    );
    console.log('Group deleted successfully', response.data.body);
  } catch (error) {
    console.error('Error deleting user groups:', error);
  }
};
