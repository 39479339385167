import { useState, useCallback, useEffect } from "react";

const useNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const addNotification = useCallback((type, message, duration = 2000) => {
    const id = new Date().getTime();
    const newNotification = { id, type, message };

    setNotifications((prev) => [...prev, newNotification]);

    setTimeout(() => {
      deleteNotification(id);
    }, duration);
  }, []);

  const deleteNotification = useCallback((id) => {
    setNotifications((prev) => prev.filter((notification) => notification.id !== id));
  }, []);

  return {
    notifications,
    addNotification,
    deleteNotification,
  };
};

export default useNotifications;
