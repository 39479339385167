import React, { useRef, useState } from 'react';

function generateThumbnail(videoSrc, timeInSeconds) {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.crossOrigin = 'anonymous'; // Necessary for CORS-enabled videos
      video.src = videoSrc;
      video.currentTime = timeInSeconds; // Set the time to capture the desired frame
      video.addEventListener('loadeddata', () => {
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
        const dataURL = canvas.toDataURL('image/jpeg'); // Change format if needed
        resolve(dataURL);
      });
      video.addEventListener('error', reject);
    });
  }
  
export default generateThumbnail;