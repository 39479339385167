import axios from "axios";

export const getProducts = async () => {
  
    let response = await axios.get(process.env.REACT_APP_LIST_MERCHANTS, {
        headers: {
            Authorization: sessionStorage.getItem("jwt"),
        },
    });

    const productsLists = JSON.parse(response.data.body);
    // console.log("productsLists",productsLists)
    const productList = Object.values(productsLists.objArticles);
    const lists = Object.values(productsLists.list);
    const percentageParams = productList.map(item => ({
      id_article: item.article.id_article,
      id_category: item.article.id_category,
      version: item.version,
      id_retailer_array: item.article.retailers.map(retailer => retailer.id)
    }))

    const gridData = productList.map(item => ({
        name: item.article.name,
        src: item.src,
        department: item.article.category,
        upc: item.article.upc,
        lists: item.lists.name || "",
        price: `$ ${item.data.price || 0}`,
        percentage: 0, 
        id_article: item.article.id_article,
        isVisible: item.article.isVisible,
        stage: item.stage,
        version: item.version,
        arrayRetailers: item.article.retailers.map(retailer => ({
          id: retailer.id,
          name: retailer.name,
        })),
        id_category: item.article.id_category,
    }));

    const tableData = productList.map(item => ({
        upc: item.article.upc,
        name: item.article.name,
        department: item.article.category,
        provider: item.article.company_name,
        lists: item.lists.name || "No asignada",
        price: `$ ${item.data.price || 0}`,
        percentage: 0,
        id_article: item.article.id_article
    }));

    const dataDetailTable = productList.map(item => ({
      id_article: item.article.id_article,
      id_category: item.article.id_category,
      version: item.version,
    }))

    const wishListFilter = lists.map(item => ({
      id: item.id, 
      name: item.name,
    }));
    

    const headersTable = ['UPC', 'Nombre', 'Departamento', 'Proveedor', 'WishList', 'Price', 'Porcentaje'];

    const headerMapping = {
        'UPC': 'upc',
        'Nombre': 'name',
        'Departamento': 'department',
        'Proveedor': 'provider',
        'WishList': 'lists',
        'Price': 'price',
        'Porcentaje': 'percentage'
      };

    return { 
      gridData, 
      tableData,
      productInfo:productList,
      dataDetailTable,
      headersTable,
      headerMapping,
      percentageParams,
      wishListFilter
     };
}

export const validatePassword = (passwordValue) => {
  return passwordValue === "melocoton";
};

export const changeVisibility = async (
  articles,
  addNotification,
  closeModal
) => {
  try {
    const config = {
      headers: { Authorization: sessionStorage.getItem("jwt") },
    };
    const response = await axios.put(
      `${process.env.REACT_APP_DROPSHIP_VISIBILITY_ENDPOINT}`,
      {
        articlesId: articles.map(article => article.id_article),
        visibilityValues: articles.map(article => article.newVisibility),
      },
      config
    );
    addNotification("success", "Visibilidad cambiada correctamente");
    closeModal();
    return true;
  } catch (error) {
    console.error("Error al cambiar la visibilidad:", error);
    addNotification("error", "Ocurrió un error al cambiar la visibilidad");
    closeModal();
  }
};

export const filterProducts = (gridData, tableData, selectedWishlist, priceRange, searchValue) => {
  let filteredGridData = [...gridData];
  let filteredTableData = [...tableData];
  if (selectedWishlist && selectedWishlist.length > 0) {
      filteredGridData = filteredGridData.filter((product) =>
          product.lists.includes(selectedWishlist.name)
      );
      filteredTableData = filteredTableData.filter((product) =>
          product.lists.includes(selectedWishlist.name)
      );
  }
  const [ min, max ] = priceRange || { min: 0, max: 100000 };
  if (min !== 0 || max !== 100000) {
      filteredGridData = filteredGridData.filter((product) => {
          const priceString = product.price.replace(/[^0-9.-]+/g, "");
          const price = parseFloat(priceString);
          return price >= min && price <= max;
      });

      filteredTableData = filteredTableData.filter((product) => {
          const priceString = product.price.replace(/[^0-9.-]+/g, "");
          const price = parseFloat(priceString);
          return price >= min && price <= max;
      });
  }
  if (searchValue) {
      const lowercasedSearchValue = searchValue.toLowerCase();
      filteredGridData = filteredGridData.filter((product) =>
          product.name.toLowerCase().includes(lowercasedSearchValue) || 
          product.upc.toLowerCase().includes(lowercasedSearchValue)
      );
      filteredTableData = filteredTableData.filter((product) =>
          product.name.toLowerCase().includes(lowercasedSearchValue) || 
          product.upc.toLowerCase().includes(lowercasedSearchValue)
      );
  }

  return { filteredGridData, filteredTableData };
};

