import { Container } from "./styles";
import {
  faFileExcel as IconFileExcel,
  faFileWord as IconFileWord,
  faFilePdf as IconFilePdf,
  faFileCsv as IconFileCsv,
  faFileImage as IconFileImg,
  faFilePowerpoint as IconFilePowerPoint,
  faFileZipper as IconFileZip,
  faFileCode as IconFileCode,
  faFileAudio as IconFileAudio,
  faFileLines as IconFileDefault,
  faFileVideo as IconFileVideo,
} from "@fortawesome/free-solid-svg-icons";
import React, { useRef } from "react";
import { CustomIcon } from "../CustomIcon";

export const IconFile = (props) => {
  const {
    id,
    key,
    className,
    type, // white | whiteS2 | pink | gray | purple | black  => (default -> white)
    transparent, // boolean
    size, // number ... default (13)
    borderType, // rectangle || circle || (cualquier otro valor -> no borde)
    ext, // extension del file
  } = props;
  const extensions = {
    // file word
    docx: { icon: IconFileWord, type: "word" },
    docm: { icon: IconFileWord, type: "word" },
    doc: { icon: IconFileWord, type: "word" },
    docs: { icon: IconFileWord, type: "word" },

    // file excel
    xls: { icon: IconFileExcel, type: "excel" },
    xlsm: { icon: IconFileExcel, type: "excel" },
    xlsx: { icon: IconFileExcel, type: "excel" },
    tsv: { icon: IconFileExcel, type: "excel" },
    // file csv
    csv: { icon: IconFileCsv, type: "csv" },
    // file image
    svg: { icon: IconFileImg, type: "image" },
    gif: { icon: IconFileImg, type: "image" },
    // file pdf
    pdf: { icon: IconFilePdf, type: "pdf" },
    // file power point
    pot: { icon: IconFilePowerPoint, type: "powerPoint" },
    potm: { icon: IconFilePowerPoint, type: "powerPoint" },
    potx: { icon: IconFilePowerPoint, type: "powerPoint" },
    pps: { icon: IconFilePowerPoint, type: "powerPoint" },
    ppsm: { icon: IconFilePowerPoint, type: "powerPoint" },
    ppsx: { icon: IconFilePowerPoint, type: "powerPoint" },
    ppt: { icon: IconFilePowerPoint, type: "powerPoint" },
    pptm: { icon: IconFilePowerPoint, type: "powerPoint" },
    pptx: { icon: IconFilePowerPoint, type: "powerPoint" },

    // file zip
    rar: { icon: IconFileZip, type: "zip" },
    zip: { icon: IconFileZip, type: "zip" },
    tar: { icon: IconFileZip, type: "zip" },

    // file code
    xml: { icon: IconFileCode, type: "code" },
    htm: { icon: IconFileCode, type: "code" },
    html: { icon: IconFileCode, type: "code" },
    css: { icon: IconFileCode, type: "code" },
    js: { icon: IconFileCode, type: "code" },
    jsx: { icon: IconFileCode, type: "code" },
    ts: { icon: IconFileCode, type: "code" },
    tsx: { icon: IconFileCode, type: "code" },
    cpp: { icon: IconFileCode, type: "code" },
    c: { icon: IconFileCode, type: "code" },
    java: { icon: IconFileCode, type: "code" },
    php: { icon: IconFileCode, type: "code" },
    json: { icon: IconFileCode, type: "code" },
    sql: { icon: IconFileCode, type: "code" },

    // file video
    avi: { icon: IconFileVideo, type: "video" },
    mp4: { icon: IconFileVideo, type: "video" },
    mpeg: { icon: IconFileVideo, type: "video" },
    mpg: { icon: IconFileVideo, type: "video" },
    wmv: { icon: IconFileVideo, type: "video" },

    // file audio
    m4a: { icon: IconFileAudio, type: "audio" },
    mp3: { icon: IconFileAudio, type: "audio" },
    wav: { icon: IconFileAudio, type: "audio" },
    wma: { icon: IconFileAudio, type: "audio" },
  };
  const ref = useRef();

  return (
    <>
      <Container
        className={
          className +
          (!type && extensions[ext]?.type
            ? " icon-" + extensions[ext].type
            : undefined)
        }
        borderType={borderType}
      >
        <CustomIcon
          id={id}
          key={key}
          className="icon"
          type={type}
          transparent={transparent}
          borderType={borderType}
          size={size}
          icon={extensions[ext]?.icon ?? IconFileDefault}
        />
      </Container>
    </>
  );
};
