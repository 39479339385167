import axios from 'axios';

export const getDataTable = async (service, initialDataCache, headerBaseDataCache, articles) => {
  // const params = `?articles=121232&versions=1`;
  const articlesIds = articles.map((article) => article.id_article);
  const versions = articles.map((article) => article.version);
  const params = `?articles=${articlesIds.join(",")}&versions=${versions.join(",")}`;
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_MULTIPLE_EDITION_ENDPOINT}/${service}${params}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }
    );

    const data = JSON.parse(response.data.body).data;
    const articlesArray = Object.values(data);

    let initialData = initialDataCache;
    let headerBaseData = headerBaseDataCache;
    let descriptionsData = [];
    let headerSectionData = [];
    if (service === "description") {
      if (!initialDataCache.length && !headerBaseDataCache.length) {
        initialData = articlesArray.map((item) => ({
          articleId: item.id,
          upc: item.upc,
          name: item.name,
          category: item.category,
          version: item.version,
          retailers: item.retailer.map((retailer) => retailer.name),
        }));

        headerBaseData = [
          { Header: "UPC", accessor: "upc", width: "100px", fixedToLeft: 0 },
          { Header: "Nombre", accessor: "name", width: "100px", fixedToLeft: 100 },
          { Header: "Categoría", accessor: "category", width: "120px", fixedToLeft: 240 },
          { Header: "Ver.", accessor: "version", width: "50px", fixedToLeft: 270 },
          { Header: "Cadenas", accessor: "retailers", width: "100px", fixedToLeft: 370 },
        ];
      }

      descriptionsData = articlesArray.map((item) => ({
        articleId: item.id,
        dataAttributes: Object.values(item.descriptions).map((data) => ({
          id_attribute: data.attributeId,
          required: data.required,
          description: data.description,
          name_attribute: data.name,
          min_chars: data.min_chars,
          max_chars: data.max_chars,
          type: data.type,
          value: data.value,
          isApproved: data.isApproved,
        })),
      }));

      headerSectionData = articlesArray.flatMap((article) =>
        Object.values(article.descriptions).map((description) => ({
          Header: description.name,
          accessor: "textArea",
        }))
      );
    } else if (service === "datasheet") {
      const datasheetsData = articlesArray.map((item) => ({
        item,
        articleId: item.id,
        dataAttributes: Object.values(item.attributes).map((attribute) => ({
          id_attribute: attribute.attributeId,
          categoryId: attribute.categoryId,
          comment: attribute.comment,
          orderSheet: attribute.orderSheet,
          orderConcat: attribute.orderConcat,
          prefill: attribute.prefill,
          required: attribute.required,
          name_attribute: attribute.name,
          commercialModel: attribute.commercialModel,
          type: attribute.type,
          description: attribute.description,
          option_list: attribute.option_list,
          min_chars: attribute.min_chars,
          max_chars: attribute.max_chars,
          isApproved: attribute.isApproved,
          value: attribute.value,
        })),
      }));

      headerSectionData = articlesArray.flatMap((article) =>
        Object.values(article.attributes).map((attribute) => ({
          Header: attribute.name,
          accessor: "customField",
        }))
      );
      if (datasheetsData.length > 0) {
        return {
          initialData,
          descriptionsData: datasheetsData,
          headerBaseData,
          headerSectionData,
        };
      }
    } else {
      const typesData = JSON.parse(response.data.body).typesShot;

      const currentImages = Object.values(articlesArray[0].images);

      const imagesData = articlesArray.map((item) => ({
        articleId: item.id,
        dataAttributes: typesData
          .filter((attribute) => {
            const relatedImage = currentImages.find(
              (image) => image.imageId === attribute.id
            );
            return !!relatedImage?.value || attribute.mandatory === 1;
          })
          .map((attribute) => {
            const relatedImage = currentImages.find(
              (image) => image.imageId === attribute.id
            );

            return {
              id_attribute: attribute.id,
              categoryId: attribute.categoryId,
              comment: relatedImage?.value?.comment,
              lastModified: relatedImage?.value?.lastModified,
              packingType: relatedImage?.value?.packingType,
              imageType: relatedImage?.value?.imageType,
              src: relatedImage?.value?.src,
              width: relatedImage?.value?.width,
              height: relatedImage?.value?.height,
              required: attribute.mandatory,
              name_attribute: attribute.name,
              commercialModel: attribute.commercialModel,
              type: attribute.type,
              isApproved: relatedImage?.value?.isApproved,
            };
          }),
      }));

      const headerSectionData = imagesData[0].dataAttributes.map((type) => ({
        Header: type.name_attribute,
        accessor: "imageVisor",
        id_attribute: type.id_attribute,
      }));

      if (imagesData.length > 0) {
        return {
          initialData,
          descriptionsData: imagesData,
          headerBaseData,
          headerSectionData,
        };
      }
    }
    if (descriptionsData.length > 0) {
      return {
        initialData,
        descriptionsData,
        headerBaseData,
        headerSectionData,
      };
    }
    return {
      initialData: [],
      descriptionsData: [],
      headerBaseData: [],
      headerSectionData: [],
    };
  } catch (error) {
    console.error('Error fetching data:', error);
    return {
      initialData: [],
      descriptionsData: [],
      headerBaseData: [],
      headerSectionData: [],
    };
  }
};
export const updateData = async (userId, service, articleId, version, articleData) => {
  const servicesUrls = {
    description: 'https://observices-atdfbmccf5b3axde.mexicocentral-01.azurewebsites.net/api/IngresarDescripciones',
    datasheet: 'https://observices-atdfbmccf5b3axde.mexicocentral-01.azurewebsites.net/api/IngresarAtributos',
    images: 'https://observices-atdfbmccf5b3axde.mexicocentral-01.azurewebsites.net/api/ActualizarImagenes',
  };

  const url = servicesUrls[service];

  const actualData = articleData.map((item) => ({attributeId: String(item.id_attribute), value: item.value, isApproved: item.isApproved, comment: item.comment ?? ''}));

  const jsonPayload = {
    data: {
      userId,
      articleId,
      versionId: version,
      service,
      articleData: actualData,
    },
  };

  try {
    const method = service === 'images' ? 'put' : 'post';

    const response = await axios({
      method,
      url,
      data: jsonPayload,
      headers: {
        'Authorization': '',
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      return {
        success: true,
        message: `Datos enviados correctamente: ${response.data.message}`,
      };
    } else {
      return {
        success: false,
        message: `Error en la solicitud: ${response.statusText}`,
      };
    }
  } catch (error) {
    console.error('Error en la solicitud:', error);
    return {
      success: false,
      message: `Error en la solicitud: ${error.message}`,
    };
  }
};



