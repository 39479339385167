import styled from 'styled-components';

export const CardContainer = styled.div`
  max-width: 184px;
  max-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 8px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  position: relative;
  cursor: pointer;

  .header-card {
    overflow: hidden;
    border-radius: 5px 5px 0 0;
    position: relative;
    min-height: 147px;
    max-height: 147px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      min-width: 182px;
      max-height: 147px;
    }
  }

  .header-circle {
    position: absolute;
    top: 10px;
    width: 100%;
    padding: 0 10px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
  }

  .body-card {
    min-height: 90px;
    padding: 12px 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .title-card {
      font-size: 12px;
    }
    .description {
      font-size: 12px;
      color: var(--gray-contentoh);
    }
    .category {
      font-size: 10px;
      color: var(--gray-contentoh);
    }
  }

  &:hover {
    z-index: 2;
    transform: scale(1.05);
  }

  .footer-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 10px;
    border-top: 1px solid var(--gray-light);
  }

  .flex-phase{
    flex-wrap: wrap;
    gap: 5px;
    .phase-button {
      min-width: 90px;
      max-width: 90px;
      font-weight: 500;
      text-wrap: nowrap;
      overflow: hidden;
      overflow-x: scroll;
      display: block;
    }
    .group-button,
    .state-button {
      width: 100%;
      font-weight: 500;
    }
  }
  .loader {
    button {
      width: 20px;
      height: 20px;
    }
    .icon {
      font-size: 13px;
    }
  }

  .buttons-visibility {
    display: flex;
    gap: 10px;
    position: relative;
    button {
      width: 20px;
      height: 20px;
      padding: 3px;
    }
    .icon {
      font-size: 15px;
    }
    .wishlist-button {
      position: relative;
      z-index: 1;
    }
    .wishlist-container {
      position: absolute;
      top: 100%;
      left: 60%;
      transform: translateX(0%) translateY(10px);
      z-index: 999;
      opacity: 0;
      transition: all 0.3s ease-in-out;
      pointer-events: none;

      .create-wishlist{
        width: auto;
        height: 20px;
        padding: 3px;
      }
    }
    
    .wishlist-container.hover {
      opacity: 1;
      pointer-events: all;
    }    
  }
`;