import React from 'react';
import NavbarItem from './NavbarItem';
import { Navbar } from './styles';

const NavbarMenu = ({ items, history, onMenuClick, showMenuText }) => (
  <Navbar showMenuText={showMenuText}>
    {items.map(item => (
      <NavbarItem
        {...item}
        history={history}
        onMenuClick={onMenuClick}
        showMenuText={showMenuText}
      />
    ))}
  </Navbar>
);

export default NavbarMenu;
