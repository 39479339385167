import { Container } from "./styles";
import { TabsMenu } from "../../molecules/TabsMenu/index";
import { StatusAsignationInfo } from "../../molecules/StatusAsignationInfo/index";
import { useState } from "react";

export const FullTabsMenu = ({
  tabsSections,
  status = "",
  activeTab,
  setActiveTab,
  setImageLayout,
  assig,
  setAssignation,
  isRetailer,
  downloadImages,
  askToDeleteImages,
  onClickSave,
  showSaveButton,
  canAssign,
  version,
  setShowVersionSelector,
  images,
  updatedDescriptions,
  updatedDatasheets,
  selectedImages,
  setSelectedImages,
  setUpdatedDescriptions,
  setUpdatedDatasheets,
  setImages,
  user,
}) => {
  const [imagesSection, setImagesSection] = useState(false);

  return (
    <Container>
      <TabsMenu
        tabsSections={tabsSections}
        setImagesSection={setImagesSection}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        images={images}
        updatedDescriptions={updatedDescriptions}
        updatedDatasheets={updatedDatasheets}
        selectedImages={selectedImages}
        setUpdatedDescriptions={setUpdatedDescriptions}
        setUpdatedDatasheets={setUpdatedDatasheets}
        setImages={setImages}
        setSelectedImages={setSelectedImages}
      />
      <StatusAsignationInfo
        canAssign={canAssign}
        status={status}
        activeTab={activeTab}
        setImageLayout={setImageLayout}
        imagesSection={imagesSection}
        assignationsImages={assig}
        setAssignation={setAssignation}
        isRetailer={isRetailer}
        downloadImages={downloadImages}
        onClickSave={onClickSave}
        askToDeleteImages={askToDeleteImages}
        showSaveButton={showSaveButton}
        version={version}
        setShowVersionSelector={setShowVersionSelector}
        user={user}
      />
    </Container>
  );
};
