import styled from "styled-components";

export const MainContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${(props) => (props.width ? props.width : "auto")};
  height: 25px;
  min-width: ${(props) => (props.width ? props.width : "120px")};
  max-width: ${(props) => (props.width ? props.width : "200px")};
  border: 1px solid ${(props) => (props.selected ? "#E33AA9" : "#D4D1D7")};
  border-radius: 7px;
  box-sizing: border-box;
  background: var(--white-contentoh);
  padding: 0 10px 0 6px;
  > div {
    display: flex;
    align-items: center;
    width: 60%;
  }
`;

export const Label = styled.label`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: var(--gray-contentoh);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space:nowrap;
`;

export const Input = styled.input`
  box-sizing: border-box;
  border-width: 0;
  width: 100%;
  font-size: 12px;
  padding-left: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #817393;
  text-align: right;
`;

export const Icon = styled.figure`
  display: flex;
  align-items: center;
  width: 13px;
  height: 100%;
  cursor: pointer;
  > img {
    width: 6px;
    margin: auto;
  }
`;

export const ListItems = styled.div`
  position: absolute;
  top: 100%;
  z-index: 5;
  width: max-content;
  min-width: 100%;
  max-height: 100px;
  background-color: var(--blue-light);
  border: 1px solid #d4d1d7;
  border-radius: 7px;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  ${({ right, left }) =>
    right &&
    !left &&
    `
    right: 0;
  `};
  ${({ right, left }) =>
    left &&
    !right &&
    `
    left: 0;
  `};
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  height: 20px;
  padding: 0 10px 0 17px;
  box-sizing: border-box;
  &:hover {
    background-color: #ddd;
  }
  > p {
    width: 100%;
    text-align: right;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    color: var(--gray-contentoh);
  }
`;

export const ItemIcon = styled.figure`
  display: flex;
  align-items: center;
  width: ${(props) => (props.countryCode ? "30px" : "15px")};
  height: 100%;
  margin-right: 5px;
  > img {
    width: 100%;
  }
`;