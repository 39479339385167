import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InputBase, TextAreaBase } from './styles';
import { useStore } from '../../../stores/useStore';

const CustomInput = ({
  id,
  type = 'text',
  placeholder,
  value,
  defaultValue,
  onChange,
  onBlur,
  variant = 'gray',
  disabled = false,
  isError = false,
  id_attribute,
}) => {
  const {getSingleObj, setDataObjValue} = useStore();
  const obj = getSingleObj(id_attribute);

  const [selectedValue, setSelectedValue] = useState(obj?.value);
  useEffect(() => {
    setSelectedValue(obj?.value);
  }, [value, obj]);

  if (type === 'textarea') {
    return (
      <TextAreaBase
        id={id}
        placeholder={placeholder}
        value={selectedValue}
        defaultValue={defaultValue}
        onChange={(e) => setDataObjValue(id_attribute, e.target.value)}
        onBlur={onBlur}
        variant={variant}
        disabled={disabled}
        isError={isError}
      />
    );
  }

  return (
    <InputBase
      id={id}
      type={type}
      placeholder={placeholder}
      value={selectedValue}
      defaultValue={defaultValue}
      onChange={(e) => setDataObjValue(id_attribute, e.target.value)}
      onBlur={onBlur}
      variant={variant}
      disabled={disabled}
      isError={isError}
    />
  );
};

CustomInput.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'email', 'password', 'number', 'tel', 'textarea']), 
  placeholder: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['gray', 'graylight']),
  disabled: PropTypes.bool,
  isError: PropTypes.bool,
};

export default CustomInput;
