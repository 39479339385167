import axios from "axios";
export const getLists = async (setIsLoading, setOriginalLists) => {
    setIsLoading(true);

    const paramsQuery = {
      nameList: "",
    };
    const paramsHeaders = {
      Authorization: sessionStorage.getItem("jwt"),
    };
    const respHTTP = await axios.get(
      process.env.REACT_APP_LIST_MERCHANTS_GRID_ENDPOINT,
      paramsQuery,
      paramsHeaders
    );

    if (!respHTTP.body) {
      setOriginalLists(undefined);
      setIsLoading(false);
      return;
    }
    let lists = [];
    lists = respHTTP.body.lists ?? [];
    lists = lists.sort((listA, listB) => {
      if (listA.name.toLowerCase() < listB.name.toLowerCase()) return -1;
      else return 1;
    });
    setIsLoading(false);
    setOriginalLists(lists);
  };

export const isSelectedList = (listId, selectedLists) => {
    if (selectedLists.findIndex((id) => id === listId) === -1) return false;
    else return true;
  };

  export const fetchLists = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_LIST_MERCHANTS_ENDPOINT, {
        headers: { Authorization: sessionStorage.getItem("jwt") },
      });
      const data = await response;
      console.log("wishlist",data)
      return { success: true, data };
    } catch (error) {
      return { success: false, errorMessage: "Error fetching lists" };
    }
  };
  
  export const createList = async (name) => {
    // try {
    //   const response = await fetch(process.env.REACT_APP_LIST_MERCHANTS_ENDPOINT, {
    //     method: "POST",
    //     headers: { Authorization: sessionStorage.getItem("jwt"), "Content-Type": "application/json" },
    //     body: JSON.stringify({ nameList: name }),
    //   });
    //   const data = await response.json();
    //   if (response.ok) return { success: true, data: data.body };
    //   return { success: false, errorMessage: data.message || "Error creating list" };
    // } catch (error) {
    //   return { success: false, errorMessage: "Error creating list" };
    // }
  };
  
  export const updateListName = async (listId, newName) => {
    // try {
    //   const response = await fetch(process.env.REACT_APP_LIST_MERCHANTS_ENDPOINT, {
    //     method: "PUT",
    //     headers: { Authorization: sessionStorage.getItem("jwt"), "Content-Type": "application/json" },
    //     body: JSON.stringify({ listId, newNameList: newName }),
    //   });
    //   const data = await response.json();
    //   if (response.ok) return { success: true, data: data.body };
    //   return { success: false, errorMessage: data.message || "Error updating list" };
    // } catch (error) {
    //   return { success: false, errorMessage: "Error updating list" };
    // }
  };
  
  export const deleteList = async (listId) => {
    // try {
    //   const response = await fetch(process.env.REACT_APP_LIST_MERCHANTS_ENDPOINT, {
    //     method: "DELETE",
    //     headers: { Authorization: sessionStorage.getItem("jwt"), "Content-Type": "application/json" },
    //     body: JSON.stringify({ listsId: [listId] }),
    //   });
    //   const data = await response.json();
    //   if (response.ok) return { success: true, data: data.body };
    //   return { success: false, errorMessage: data.message || "Error deleting list" };
    // } catch (error) {
    //   return { success: false, errorMessage: "Error deleting list" };
    // }
  };
  