import { Container } from "./styles";
import { ScreenHeader } from "../../atoms/ScreenHeader/index";
import { Button } from "../../atoms/GeneralButton/index";
import { useEffect, useRef } from "react";
import { Chat } from "../../organisms/Chat";
import auditIcon from "../../../../src/assets/images/generalButton/Icono AB.svg";
import { useState } from "react";
import { render } from "@testing-library/react";

export const HeaderTop = ({
  setHeaderTop,
  withChat,
  chatType,
  productSelected,
  token,
  auditableVersion,
  setCompare,
  isAuditor,
  activeRetailer,
}) => {
  const headerTop = useRef();
  const [chat, setChat] = useState(null);
  const [chatData, setChatData] = useState(null);

  useEffect(() => {
    if (activeRetailer.id && productSelected && token.length) {
      setChatData({
        id: productSelected?.article?.id_article || productSelected?.id_article,
        version: productSelected?.version,
        retailerId: activeRetailer?.id,
        status: productSelected?.status,
        userToken: token,
      });
    }
  }, [activeRetailer, productSelected, token]);

  useEffect(() => {
    if (chatType) renderChat(chatType);
  }, [chatData, chatType]);

  useEffect(() => {
    setHeaderTop && setHeaderTop(headerTop.current.clientHeight);
  }, []);

  const renderChat = (chatType) => {
    switch (chatType) {
      case "product_status":
        chatData &&
          setChat(
            <Chat
              chatType="product_status"
              chatContainerType="popUp"
              chatData={{
                id: chatData?.id,
                version: chatData?.version,
                retailerId: chatData?.retailerId,
                status: chatData?.status,
                userToken: chatData?.userToken,
              }}
              size={18}
              activeRetailer={activeRetailer}
            />
          );
        break;
      default:
        setChat(
          <Chat
            chatType="merchant_product"
            chatContainerType="popUp"
            chatData={{
              userToken: token,
              id: productSelected.id_article,
              version: productSelected.version,
            }}
            size={18}
            activeRetailer={activeRetailer}
          />
        );
        break;
    }
  };

  return (
    <Container ref={headerTop}>
      <ScreenHeader text="" />
      <div className="buttons-container">
        {withChat && chat}
        {isAuditor && auditableVersion && (
          <Button
            buttonType="general-white-button circular-button"
            onClick={() => {
              setCompare((current) => !current);
            }}
            image={auditIcon}
          />
        )}
        <Button
          buttonType="general-white-button close-button"
          onClick={() => {
            window.location.href = `/products`;
          }}
        />
      </div>
    </Container>
  );
};
