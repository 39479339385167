import { Container } from "./styles";
import { ProductNameHeader } from "../../molecules/ProductNameHeader";
import { FeaturesBar } from "../../molecules/FeaturesBar";
import { AvatarAndValidation } from "../../molecules/AvatarAndValidation";
import { useState } from "react";
import { useEffect } from "react";

export const FullProductNameHeader = ({
  headerData,
  percent,
  activeRetailer,
  setActiveRetailer,
  showValidationButtons,
  approve,
  reject,
  approveAll,
  rejectAll,
  servicesData,
  showApproveRejectAll,
  productObservation,
  isObservationVisible,
  toggleObservation,
  // handleClickOutside,
   hideObservation
}) => {
  const [retailers, setRetailers] = useState([]);

  useEffect(() => {
    const rtls = headerData?.retailers || headerData?.retailersAvailable;
    servicesData &&
      rtls?.forEach((rt) => {
        const element = [];
        servicesData.forEach((sd) => {
          if (sd.id_retailer === rt.id) {
            element.push(sd.status ? sd.status : "R");
          } else element.push("NA");
          rt["services"] = element;
        });
      });
    setRetailers(rtls);
  }, [servicesData]);

  const getTime = (date) => {
    if (!date) return "";
    return new Date(date).toLocaleDateString();
  };

  return (
    <Container>
      <ProductNameHeader
        productName={headerData?.article?.name || headerData?.name}
        statusType={
          headerData?.version_status ||
          headerData?.status ||
          headerData?.article_status
        }
        percentRequired={percent}
        percent={percent}
        priority={headerData?.prio}
        productObservation={productObservation}
        date={getTime(headerData?.article?.timestamp || headerData?.timestamp)}
        dataProduct={headerData}
        isObservationVisible ={isObservationVisible}
        toggleObservation = {toggleObservation}
        // handleClickOutside ={handleClickOutside}
         hideObservation={hideObservation}

      />
      <div className="features-bar-container">
        <FeaturesBar
          features={[
            {
              feature: "Categoría",
              value: headerData?.article?.category || headerData?.categoryName,
            },
            {
              feature: "Prov",
              value: headerData?.article?.company_name,
            },
            {
              feature: "SKU",
              value: headerData?.article?.upc || headerData?.upc,
            },
          ]}
        />
        <AvatarAndValidation
          retailers={retailers}
          avatarData={headerData?.avatarData}
          validation={headerData?.validation}
          activeRetailer={activeRetailer}
          setActiveRetailer={setActiveRetailer}
          showValidationButtons={showValidationButtons}
          showApproveRejectAll={showApproveRejectAll}
          approve={approve}
          reject={reject}
          approveAll={approveAll}
          rejectAll={rejectAll}
        />
      </div>
    </Container>
  );
};
