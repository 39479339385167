import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useState, useRef } from "react";
import { Container } from "./styles";

export const InputFormatter = ({
  mainValue = "",
  inputId,
  articleId,
  updatedDescriptions,
  setUpdatedDescriptions,
  isRequired,
  maxChar,
  disabled,
}) => {
  const [inputValue, setInputValue] = useState(mainValue);
  const [selection, setSelection] = useState(false);
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const [charsCounter, setCharsCounter] = useState(0);
  const quillRef = useRef(null); // Usamos useRef para acceder al editor
  const maxLength = maxChar ? maxChar : 999;

  const getValue = (value) => {
    let temp = value
      .replace(/\n/gm, "<br>")
      .replace(/\&nbsp;/gm, " ")
      .replace(/\&amp;/gm, "&");
    return temp;
  };

  const valueFormater = (value) => {
    return value
      .replace(/<\/p><p>/gm, "\n")
      .replace(/<\/?br>|<\/?p>/gm, "")
      .replace(/\&nbsp;/gm, " ")
      .replace(/\&amp;/gm, "&");
  };

  const onChange = (valueInput, quill) => {
    let value = "";
    try {
      if (quill.getLength() - 1 <= maxLength) {
        let unformattedText = valueInput
          .replace(/<li.*?>/gm, "   - ")
          .replace(/<\/li>/gm, "\n")
          .replace(/<\/p><p>/gm, "\n")
          .replaceAll(/<\/?(?!\/?strong).*?>/gm, "")
          .replace(/\&nbsp;/gm, " ")
          .replace(/\&amp;/gm, "&");
        setInputValue(valueInput);
        value = unformattedText;
      } else {
        quillRef.current.getEditor().deleteText(maxLength, quill.getLength());
        setInputValue(quill.getText());
        value = quill.getText();
      }
    } catch (error) {
      console.log(error);
    }
    setCharsCounter(quill.getLength() - 1);
    value = valueFormater(value);

    if (updatedDescriptions) {
      let idInput = inputId;
      let dataSave = updatedDescriptions?.slice();
      if (dataSave?.length > 0) {
        const index = dataSave.findIndex((e) => e.attributeId === idInput);
        if (index !== -1) {
          if (value !== mainValue) dataSave[index].value = value;
          else dataSave.splice(index, 1);
        } else {
          dataSave.push({
            articleId: articleId,
            attributeId: idInput,
            value: value,
          });
        }
      } else {
        if (value !== mainValue) {
          dataSave.push({
            articleId: articleId,
            attributeId: idInput,
            value: value,
          });
        }
      }
      setUpdatedDescriptions(dataSave);
    }
  };

  const getSelection = (range, source, editor) => {
    const selected = editor.getSelection();
    const bounds = editor.getBounds(selected?.index || 0, selected?.length || 0);
    if (source === "user") {
      setPosition({ left: bounds.left, top: bounds.top });
      setSelection(selected?.length > 0);
    }
  };

  return (
    <Container
      isRequired={
        isRequired &&
        (inputValue.replace(/<.*?>/gm, "").length === 0 || !inputValue)
      }
      selection={selection}
      position={position}
    >
      <ReactQuill
        id={inputId + ""}
        ref={quillRef}
        value={getValue(inputValue)}
        readOnly={disabled}
        modules={{ toolbar: ["bold"] }}
        onKeyPress={(e) => {
          if (charsCounter >= maxLength && e.key !== "Backspace") {
            e.preventDefault();
          }
        }}
        onKeyDown={(e) => {
          let keysArray = [
            "Backspace",
            "Meta",
            "ArrowLeft",
            "ArrowRight",
            "ArrowUp",
            "ArrowDown",
          ];
          if (
            charsCounter >= maxLength &&
            keysArray.every((key) => e.key !== key)
          ) {
            e.preventDefault();
          }
        }}
        onChange={(valueInput, delta, source, editor) => onChange(valueInput, editor)}
        onChangeSelection={getSelection}
      />
      <p className="description-limit">
        {charsCounter}/{maxLength}
      </p>
    </Container>
  );
};